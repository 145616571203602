import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/order/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    console.log(result, 'sssss');
                return {
                    result: result,
                }
            })
        }
    },
    'get-products': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/orders/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField ? query.sortField == 'out' ? 'quantityOut' : query.sortField : null,
                    selectedYear: localStorage && localStorage.year ? localStorage.year : null,
                    break: query && query.lastUpdate ? false : true,
                    additionalFilters: {
                        barcode: query && query.barcode,
                        purchasePrice: query && query.purchasePrice,
                        sellPrice: query && query.sellPrice,
                        manufacturer: query && query.manufacturer,
                        subgroup: query && query.subgroup,
                        groupFrom: query && query.groupFrom,
                        groupTo: query && query.groupTo,
                        articleFrom: query && query.articleFrom,
                        articleTo: query && query.articleTo,
                        date: query && query.date,
                        document: query && query.document,
                        salesFrom: query && query.salesFrom,
                        salesTo: query && query.salesTo,
                        reservation: query && query.reservation
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

    'get-output': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/output/' + query.documentNumber, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/orders/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    items: query.items
                })
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        printHtml: result.html,
                    }
            })
        }
    },
    'export': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/orders/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    items: query.items
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'insert-new': {
        onLoad: false,
        action: (data) => {
            console.log(data)
            return fetch(env.API_URL + '/data/order/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'update': {
        onLoad: false,
        action: (data) => {
            console.log(data.id, 'data');
            return fetch(env.API_URL + '/data/order/update/' + data.id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'delete': {
        onLoad: false,
        action: (data) => {
            console.log(data.id, 'data, data, data');
            return fetch(env.API_URL + '/data/order/delete' + data.id + '/' + data.productCode, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

}