import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            clients: [],
            businessUnits: [],
            items: [

            ],
            userLevels: []
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (JSON.stringify(prevState.businessUnits) != JSON.stringify(this.state.businessUnits)) {
            this.setState({ filterBusinessUnitCode: null })
        }

        if (this.state.filterBusinessUnitCode && !this.registerGetValue('clientCode')) {
            this.setState({ filterBusinessUnitCode: null, businessUnits: [], clients: [] })
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Korisnici - sajt</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Da li želite obrisati korisnika <strong>{this.state.deleteModal ? this.state.deleteModal.username : ''}</strong>?
                    </DeleteModal>
                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                        error={this.state.error}
                    />
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                fieldsToTrack={['clientCode', 'businessUnitCode']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'clientCode') {
                                        if (value) {
                                            let clients = await this._apiAsync('get-clients', { filter: value })
                                            if (clients && clients.items) {
                                                this.setState({ clients: clients && clients.items }, () => {
                                                    if (this.state.clients.filter(item => item.code == value).length == 1) {

                                                        if (this.registerGetValue('clientName') != this.state.clients.filter(item => item.code == value)[0].name) {
                                                            let clientId = this.state.clients.filter(item => item.code == value)[0]._id;
                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                            this.remoteOnChange('clientId', clientId)
                                                            this._apiAsync('check-business-units', { filter: clientId }).then((res) => {
                                                                if (res && res.length) {

                                                                    this.setState({ businessUnits: res }, () => {
                                                                        if (this.registerGetValue('businessUnitCode')) {
                                                                            this.remoteOnChange('businessUnitCode', this.registerGetValue('businessUnitCode'))
                                                                        }
                                                                    })
                                                                } else {
                                                                    this.setState({ businessUnits: [] }, () => {
                                                                        if (this.registerGetValue('businessUnitCode')) {
                                                                            this.remoteOnChange('businessUnitCode', this.registerGetValue('businessUnitCode'))
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }


                                                        // 
                                                    } else {
                                                        if (this.registerGetValue('clientName')) {
                                                            this.remoteOnChange('clientName', '')
                                                        }
                                                        if (this.registerGetValue('clientId')) {
                                                            this.remoteOnChange('clientId', '')
                                                        }

                                                    }
                                                })
                                            }

                                        } else {

                                            if (this.registerGetValue('clientName')) {
                                                this.remoteOnChange('clientName', '')
                                            }
                                            if (this.registerGetValue('clientId')) {
                                                this.remoteOnChange('clientId', '')
                                            }

                                        }
                                    }
                                    if (name == 'businessUnitCode') {
                                        if (value) {
                                            if (this.state.filterBusinessUnitCode != value) {
                                                this.setState({ filterBusinessUnitCode: value })
                                            }

                                            if (this.state.businessUnits.filter(item => String(item.code) == String(value)).length == 1) {

                                                if (value != this.state.businessUnits.filter(item => String(item.code) == String(value))[0].name) {
                                                    let businessUnitId = this.state.businessUnits.filter(item => String(item.code) == String(value))[0]._id;
                                                    this.remoteOnChange('businessUnitName', this.state.businessUnits.filter(item => String(item.code) == String(value))[0].name)
                                                    this.remoteOnChange('businessUnitId', businessUnitId)

                                                }


                                                // 
                                            } else {
                                                if (this.registerGetValue('businessUnitName')) {
                                                    this.remoteOnChange('businessUnitName', '')
                                                }
                                                if (this.registerGetValue('businessUnitId')) {
                                                    this.remoteOnChange('businessUnitId', '')
                                                }


                                            }
                                        } else {
                                            this.setState({ filterBusinessUnitCode: null })
                                            if (this.registerGetValue('businessUnitName')) {
                                                this.remoteOnChange('businessUnitName', '')
                                            }
                                            if (this.registerGetValue('businessUnitId')) {
                                                this.remoteOnChange('businessUnitId', '')
                                            }

                                        }
                                    }
                                }}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    console.log(data)
                                    if (this.state.form && this.state.form._id) {
                                        data._id = this.state.form._id;
                                        this._apiAsync('check-duplicate', {
                                            client: data.clientId ? data.clientId : null,
                                            businessUnit: data.businessUnitId ? data.businessUnitId : null,
                                            id: this.state.form._id
                                        }).then((res) => {
                                            if (res && res.status == 200) {

                                                this._api('api4', { data: data }, () => {
                                                    this.setState({ form: undefined }, () => { this._onLoad(); })
                                                });
                                            } else {
                                                let error;
                                                if (data.businessUnitId) {
                                                    error = 'Korisnik vec postoji za izabranog komitenta i poslovnu jedinicu.'
                                                } else {
                                                    error = 'Korisnik vec postoji za izabranog komitenta.'
                                                }
                                                this.setState({ error })
                                            }

                                        })

                                    } else {
                                        this._apiAsync('check-duplicate', {
                                            client: data.clientId ? data.clientId : null,
                                            businessUnit: data.businessUnitId ? data.businessUnitId : null,
                                            id: null
                                        }).then((res) => {
                                            if (res && res.status == 200) {
                                                this._api('api2', { data: data }, () => {
                                                    this.setState({ clientForm: {} }, () => { this._onLoad() })
                                                });
                                            } else {
                                                let error;
                                                if (data.businessUnitId) {
                                                    error = 'Korisnik vec postoji za izabranog komitenta i poslovnu jedinicu.'
                                                } else {
                                                    error = 'Korisnik vec postoji za izabranog komitenta.'
                                                }
                                                this.setState({ error })
                                            }

                                        })


                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj korisnika'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'username',
                                                                        next: 'password',
                                                                        label: 'Username',
                                                                        // asyncValidation: true,
                                                                        validate: [
                                                                            required('Username mora biti unesen!'),
                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        let res = await this._apiAsync('validate', { value: value, id: this.state.form ? this.state.form._id : null });
                                                                                        if (res.status == 400) {
                                                                                            return 'Username je zauzet'
                                                                                        }
                                                                                        return undefined;
                                                                                    }

                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'password',
                                                                        label: 'Password',
                                                                        // asyncValidation: true,
                                                                        validate: [
                                                                            required('Password mora biti unesen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                className: 'form-builder-merged-two-fields',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'selectWithSearch',
                                                                        name: 'clientCode',
                                                                        label: 'Šifra kom.',
                                                                        values: [...this.state.clients.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item.code
                                                                            }
                                                                            return obj
                                                                        })],
                                                                        finishField: true,

                                                                        // asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra komitenta je obavezna!'),

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'clientName',
                                                                        label: 'Naziv komitenta',
                                                                        readOnly: true,
                                                                        finishField: true,
                                                                        // asyncValidation: true,
                                                                    },
                                                                ]
                                                            },
                                                            this.state.businessUnits.length ?
                                                                {
                                                                    type: 'col',
                                                                    className: 'form-builder-merged-two-fields',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'selectWithSearch',
                                                                            name: 'businessUnitCode',
                                                                            label: 'Šifra poslovne jedinice',
                                                                            values: [...this.state.businessUnits.filter(el => !this.state.filterBusinessUnitCode || (this.state.filterBusinessUnitCode && ((String(el.code) == this.state.filterBusinessUnitCode) || (el.name.toLowerCase().indexOf(this.state.filterBusinessUnitCode) != -1)))).map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,

                                                                            // asyncValidation: true,
                                                                            validate: [
                                                                                required('Šifra poslovne jedinice je obavezna!'),

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessUnitName',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            // asyncValidation: true,
                                                                        },
                                                                    ]
                                                                }
                                                                :
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: []
                                                                },


                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista korisnika'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'username', label: 'USERNAME'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'businessUnitCode', label: 'P.J.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }, () => {
                                                    if (item && item.username) {
                                                        this.remoteOnChange('username', item.username)
                                                    }
                                                    if (item && item.clientCode) {
                                                        this.remoteOnChange('clientCode', item.clientCode)
                                                    }
                                                    if (item && item.businessUnitCode) {
                                                        this.remoteOnChange('businessUnitCode', item.businessUnitCode)
                                                    }
                                                }))
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Screen);
