import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-series-deadlines': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series/deadlines', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries:null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    numberOfDays: query.numberOfDays

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

    'get-output': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },


    'get-article': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/product', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    // query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },

    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-price': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/facture-change/read/price', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                   data
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        price: result,
                        total: result.total,
                    }
            })
        }
    },

    'get-series': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 100,
                    article: query.article,
                    filter: query.filter,
                    query: query.query,
                    withoutSeries : query.withoutSeries,
                    deadline : query.deadline,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },


    'nextItemNumber': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },


    'getKO': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/KO', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },


    'new-ko': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/new-ko', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },

    'update-ko': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/update-ko', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    // page: null,
                    // entries:null,
                    // filter: query.filter,
                    // sortType: query.sortType,
                    // sortField: query.sortField,
                    // numberOfDays: query.numberOfDays
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },

    'new-kt': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/new-kt', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },

    'update-output': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/facture-change/update-output', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                  
                    query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result
                    }
            })
        }
    },

    'ariclesSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/products/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) =>{
                return {
                    articlesSearch : response.result.items
                }
            })
        }
    },

     

}