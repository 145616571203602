import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import env from "react-dotenv";
import moment from 'moment';
import PrintHelper from '../../components/printHelper';
import DescriptionMOdal from '../../components/descriptionModal';
import { Player } from "@lottiefiles/react-lottie-player";

import {
    Container, Label, Input, Button
} from 'reactstrap';
import ErrorModal from "../../components/errorModal";

class WriteOffPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            clientList: true,
            clientForm: false,
            entryOut: true,
            buttonActive: 2,
            clients: [],
            products: [],
            supplierReturnForm: null,
            listCustomFilter: true,
            accountNumberDisabled: true,
            entrace: [],
            articles: [],
            series: [],
            supplierReturnItems: [],
            dateNow: moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY'),
            lockModal: false,
            loadingPage: false,
            disableArticleEdit: false,
            error: null
        }
    }

    componentDidMount() {


        if (this.props[0].location && this.props[0].location.search) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') !== -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }

            }

        } else {
            this._onLoad();

            (async () => {
                let res = await this._apiAsync('slobodanBroj', {});
                this.remoteOnChange('documentNumber', Number(res.result.prviSlBroj))

            })()
        }

        this.metodaClient('')
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }

    }

    calculatePrice = (value) => {
        if (value !== '') {
            let entrace = this.state.entrace.filter(item => item.accountNumber === this.registerGetValue('accountNumber'))[0]
            if (entrace && entrace.items) {
                for (let i = 0; i < entrace.items.length; i++) {
                    if (entrace.items[i] && entrace.items[i].article === this.registerGetValue('item.article')) {
                        if (entrace.items[i].ammount < value) {
                            this.setState({
                                ammoutnError: `Prekoračili ste količinu sa fakture koja iznosi ${entrace.items[i].ammount}.`,
                                maxAmmoutn: entrace.items[i].ammount
                            })
                        } else {
                            this.remoteOnChange('item.price', entrace.items[i].sellPrice)
                            this.remoteOnChange('item.discount', entrace.items[i].discount)
                            if (entrace.items[i].discount) {
                                this.remoteOnChange('item.total', entrace.items[i].sellPrice * value * (1 - entrace.items[i].discount / 100))
                            } else {
                                this.remoteOnChange('item.total', entrace.items[i].sellPrice * value)
                            }
                        }

                    }
                }
            }

        } else {
            this.remoteOnChange('item.price', '')
            this.remoteOnChange('item.discount', '')
            this.remoteOnChange('item.total', '')
        }

    }
    goToFormTab = () => {
        this._onLoad()
        let supplierReturnForm = this.state.supplierReturnForm;
        if (!supplierReturnForm) {
            supplierReturnForm = {
                returnDate: this.state.dateNow
            }
        }
        (async () => {
            let res = await this._apiAsync('slobodanBroj', {});
            this.remoteOnChange('documentNumber', Number(res.result.prviSlBroj))

        })()
        if (!supplierReturnForm.item) {
            supplierReturnForm.item = { oridnalNumber: 1 }
        }
        this.setState({ supplierReturnForm: null }, () => {
            this.setState({ supplierReturnForm })
        })
    }
    printOutput = async (data) => {
        let print = await this._apiAsync('pringPovartDobavljaca', { documentNumber: data.documentNumber })
        if (print) {
            this.setState({
                loadingExport: false
            })
        }
    }

    calculatePriceAndPdv = () => {
        let total = 0
        for (let i = 0; i < this.state.items.length; i++) {
            total += this.state.items[i].total

        }
        this.setState({
            basicPrice: this.props.roundToTwoDecimals(total - total * 0.17, true, true),
            pricePDV: this.props.roundToTwoDecimals(total * 0.17, true, true),
            basicPriceWithPDV: this.props.roundToTwoDecimals(total, true, true)
        })
    }

    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }
    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }

    metoda = async (value) => {
        let articleList = {
            articlesSearch: []
        }
        if (this.state.entrace && this.state.entrace.length && this.state.entrace.filter(item => item.accountNumber && item.accountNumber.toLowerCase().indexOf(this.registerGetValue && this.registerGetValue('accountNumber').toLowerCase()) !== -1).length) {
            articleList = await this._apiAsync('ariclesSearch', { value: value, article: [...this.state.entrace.filter(item => item.accountNumber.toLowerCase().indexOf(this.registerGetValue && this.registerGetValue('accountNumber').toLowerCase()) !== -1)[0].items.map(item => { return item.article })] })
        }
        console.log('articleList', articleList);

        if (this.registerGetValue && value === this.registerGetValue('item.article')) {
            this.setState({ articlesSearch: articleList.articlesSearch })
        } else if (!value) {
            this.setState({ articlesSearch: articleList.articlesSearch })
        }

    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('clientCode')) {
            this.setState({ clientsSearch: clientList.clientsSearch })
        } else if (!value) {
            this.setState({ clientsSearch: clientList.clientsSearch })
        }

    }

    render() {
        let params = this._getParams();

        return (
            <div>
                {/* <Loader loading={this.state.loadingPage} /> */}
                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive === 1 ?
                                <>Lista povrata dobavljača</>
                                :
                                <>Povrat dobavljača</>
                        }
                    </h1>
                    {
                        this.state.buttonActive === 1 ?
                            <div className="title-options-extrabutton">
                                <div className="title-options-group">
                                    {/* <button>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Snimi</span>
                                    </button> */}
                                    {/* <button onClick={() => this.setState({ printHtml: null }, this.print)}>

                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button> */}
                                    <button onClick={() => this.export()}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                    {/* <button>
                                        <div className="option red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <span>Otkaži</span>
                                    </button> */}
                                    {
                                        this.state.printHtml ?
                                            <PrintHelper html={this.state.printHtml} />
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            :
                            null
                    }

                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive === 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1, printHtml: null }, () => this._onLoad())}>
                            Lista povrata dobavljača
                        </button>
                        <button className={this.state.buttonActive === 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive !== 2) {
                                this.setState({ buttonActive: 2, supplierReturnForm: null, disableArticleEdit: false }, () => this.goToFormTab())
                            }
                        }}>
                            Povrat dobavljača
                        </button>
                    </div>
                    {
                        this.state.buttonActive === 1 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" onChange={(e) => {
                                    this.updateParams('notFinished', e.target.checked)
                                }} />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    this.state.buttonActive === 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'clientCode', 'accountNumber', 'item.ammount', 'item.article', 'item.oridnalNumber', 'item.serialNumber']}
                                    fieldTracker={async (name, value) => {
                                        if (name === 'item.serialNumber' && value) {
                                            if (this.state.series && this.state.series.length) {
                                                for (let i = 0; i < this.state.series.length; i++) {
                                                    if (value === this.state.series[i].serialNumber && this.state.series[i].expirationDate) {
                                                        if (this.state.series[i].ammount > 0) {
                                                            this.remoteOnChange('item.deadline', this.state.series[i].expirationDate)
                                                            break;
                                                        } else {
                                                            break;
                                                        }

                                                    } else {
                                                        this.remoteOnChange('item.deadline', '')
                                                    }

                                                }
                                            }
                                        }
                                        if (name === 'item.oridnalNumber' && value) {
                                            this.calculatePriceAndPdv()

                                        }
                                        if (name === 'item.article' && value) {
                                            if (this.state.entrace && this.state.entrace.length && this.state.entrace.filter(ent => ent.accountNumber === this.registerGetValue('accountNumber')).length) {
                                                let item = this.state.entrace.filter(ent => ent.accountNumber === this.registerGetValue('accountNumber'))[0].items.filter(ent => ent.article === value)[0]
                                                if (item && item.serialNumber && item.serialNumber !== '') {
                                                    const series = await this._apiAsync('get-series', { article: item.article })
                                                    if (series && series.seriesItems) {
                                                        this.setState({
                                                            series: series && series.seriesItems
                                                        }, () => {
                                                            if (this.state.series.length === 1) {
                                                                this.remoteOnChange('item.serialNumber', this.state.series[0].serialNumber)
                                                                this.remoteOnChange('item.deadline', this.state.series[0].expirationDate)

                                                            }
                                                        })
                                                    }
                                                }
                                            }
                                            this.remoteOnChange('item.ammount', '')
                                            this.remoteOnChange('item.price', '')
                                            this.remoteOnChange('item.discount', '')
                                            this.remoteOnChange('item.total', '')
                                        }

                                        if (name === 'item.ammount' && value) {
                                            this.calculatePrice(value)

                                        }

                                        if (name === 'accountNumber' && (value || value === '')) {

                                            const entrace = await this._apiAsync('get-entrace', { client: this.registerGetValue('clientName'), filter: value })
                                            if (entrace && entrace.entranceItems) {
                                                this.setState({ entrace: entrace && entrace.entranceItems }, async () => {
                                                    if (this.state.entrace && this.state.entrace.length && this.state.entrace.filter(item => item.accountNumber === value).length) {
                                                        const articles = await this._apiAsync('get-articles', { article: [...this.state.entrace.filter(item => item.accountNumber === value)[0].items.map(item => { return item.article })] })
                                                        if (articles && articles.articleItems) {
                                                            let articlesList = articles && articles.articleItems;
                                                            if (articlesList && articlesList.length) {
                                                                for (let i = 0; i < articlesList.length; i++) {
                                                                    let entranceData = this.state.entrace.filter(item => item.accountNumber === value)[0].items?.filter(item => item.article === articlesList[i].code).map(item => { return { facturePrice: Number(item.facturePrice), factureValue: Number(item.factureValue), article: item.article, serialNumber: item.serialNumber } })
                                                                    if (entranceData && entranceData.length) {
                                                                        articlesList[i].factureValue = entranceData[0].factureValue;
                                                                        articlesList[i].facturePrice = entranceData[0].facturePrice;
                                                                        articlesList[i].serialNumber = entranceData[0].serialNumber;

                                                                    }

                                                                }
                                                            }
                                                            this.setState({ articles: articlesList })
                                                            // this.setState({ articles: articles && articles.articleItems }, () => {

                                                            // })
                                                        }
                                                    }

                                                })
                                            }

                                            this.metoda('')

                                        }
                                        if (name === 'clientCode' && (value || value === '')) {
                                            const clients = await this._apiAsync('get-clients', { filter: value })
                                            if (clients && clients.clientsItems) {
                                                this.setState({ clients: clients && clients.clientsItems }, () => {
                                                    if (this.state.prevClientCode !== value) {
                                                        this.remoteOnChange('accountNumber', '')
                                                        this.setState({
                                                            items: [],
                                                            entrace: []
                                                        })
                                                    }
                                                    this.setState({
                                                        prevClientCode: value
                                                    })
                                                    if (this.state.clients.filter(item => item.code === value).length) {
                                                        this.remoteOnChange('clientName', this.state.clients.filter(item => item.code === value)[0].name)

                                                        this.setState({
                                                            entrace: [],
                                                            articles: [],
                                                            accountNumberDisabled: false,

                                                        })
                                                    } else {
                                                        this.remoteOnChange('clientName', '')
                                                        this.setState({
                                                            entrace: [],
                                                            articles: [],
                                                            accountNumberDisabled: true
                                                        })
                                                    }
                                                })
                                            }

                                        }

                                        if (name === 'documentNumber' && value) {
                                            this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                                if (res && res.result && res.result._id) {

                                                    if (res.result && res.result.clientCode) {
                                                        this.metodaClient(res.result.clientCode)
                                                    }

                                                    this.setState({ supplierReturnForm: null }, () => {
                                                        this.setState({ supplierReturnForm: res.result, }, async () => {
                                                            if (this.state.supplierReturnForm && this.state.supplierReturnForm.accountNumber && this.state.supplierReturnForm.clientName) {
                                                                const entrace = await this._apiAsync('get-entrace', { client: this.state.supplierReturnForm.clientName, filter: this.state.supplierReturnForm.accountNumber })
                                                                if (entrace && entrace.entranceItems) {
                                                                    this.setState({ entrace: entrace && entrace.entranceItems, accountNumberDisabled: false, }, async () => {
                                                                        if (this.state.entrace && this.state.entrace.length && this.state.entrace.filter(item => item.accountNumber === this.state.supplierReturnForm.accountNumber).length) {
                                                                            const articles = await this._apiAsync('get-articles', { article: [...this.state.entrace.filter(item => item.accountNumber === this.state.supplierReturnForm.accountNumber)[0].items.map(item => { return item.article })] })
                                                                            if (articles && articles.articleItems) {
                                                                                let articlesList = articles && articles.articleItems;
                                                                                if (articlesList && articlesList.length) {
                                                                                    for (let i = 0; i < articlesList.length; i++) {
                                                                                        let entranceData = this.state.entrace.filter(item => item.accountNumber === value)[0]?.items.filter(item => item.article === articlesList[i].code).map(item => { return { facturePrice: Number(item.facturePrice), factureValue: Number(item.factureValue), article: item.article, serialNumber: item.serialNumber } })
                                                                                        if (entranceData && entranceData.length) {
                                                                                            articlesList[i].factureValue = entranceData[0].factureValue;
                                                                                            articlesList[i].facturePrice = entranceData[0].facturePrice;
                                                                                            articlesList[i].serialNumber = entranceData[0].serialNumber;
                                                                                        }

                                                                                    }
                                                                                }
                                                                                this.setState({ articles: articlesList })
                                                                                // this.setState({ articles: articles && articles.articleItems }, () => {

                                                                                // })
                                                                            }
                                                                        }

                                                                    })
                                                                }
                                                            }
                                                            if (res.result.items && res.result.items.length) {
                                                                this.setState({
                                                                    items: res.result.items,
                                                                }, () => {
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.oridnalNumber', Number(res.firstFreeItemNumber))
                                                                            this.remoteOnChange('returnDate', moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY'))
                                                                        }

                                                                    })
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    items: [],
                                                                }, () => {
                                                                    this.remoteOnChange('item.oridnalNumber', 1)
                                                                    this.remoteOnChange('returnDate', moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY'))
                                                                })
                                                            }

                                                        })
                                                    })

                                                } else if (this.state.prevDocumentNumber !== value) {
                                                    this.setState({ supplierReturnForm: null }, () => {
                                                        this.setState({ supplierReturnForm: { documentNumber: value } }, () => {

                                                            this.setState({ items: [], supplierReturnForm: {} }, () => {
                                                                this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                    if (res && res.firstFreeItemNumber) {
                                                                        this.remoteOnChange('item.oridnalNumber', Number(res.firstFreeItemNumber))
                                                                        this.remoteOnChange('returnDate', moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY'))
                                                                    }

                                                                })
                                                            })

                                                        })
                                                    })

                                                }

                                                this.setState({ prevDocumentNumber: value })
                                            })

                                        }

                                    }
                                    }
                                    onSubmit={(data, notFinished) => {
                                        if (data.item && data.item.article) {
                                            data.item.article = Number(data.item.article)
                                        }
                                        // if (data.item && data.item.serialNumber) {
                                        //     data.item.serialNumber = Number(data.item.serialNumber)
                                        // }
                                        if (this.state.supplierReturnForm && this.state.supplierReturnForm.description) {
                                            data.description = this.state.supplierReturnForm.description
                                        }
                                        if (data.item && data.item.total) {
                                            data.item.vat = 17
                                            data.item.totalVat = (Number(data.item.total) * (data.item.vat / 100)).toFixed(2)
                                        }
                                        if (this.state.articles && this.state.articles.filter(item => item.code === data.item.article && ((!data.item.serialNumber && !item.serialNumber) || (data.item.serialNumber === item.serialNumber))).length) {
                                            let article = this.state.articles.filter(item => item.code === data.item.article && ((!data.item.serialNumber && !item.serialNumber) || (data.item.serialNumber === item.serialNumber)))[0];
                                            data.item.facturePrice = article.facturePrice ? article.facturePrice : null;
                                            data.item.factureValue = article.facturePrice ? article.facturePrice * Number(data.item.ammount) : null;
                                        }

                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                let item = JSON.parse(JSON.stringify(data.item));
                                                if (item && item.oridnalNumber) {
                                                    delete item.oridnalNumber;
                                                }
                                                if (item && Object.keys(item).length === 0) {
                                                    delete data.item;
                                                }
                                            }
                                        }
                                        if (data._id) {
                                            this._apiAsync('update-document', data).then((res) => {
                                                if (res.result && res.result.id) {
                                                    this.setState({ supplierReturnForm: null }, () => {
                                                        this.setState({
                                                            supplierReturnForm: {
                                                                _id: res.result.id,
                                                                ...res.result.result
                                                            }, items: res.result.result.items && res.result.result.items.length ? res.result.result.items : []
                                                        }, () => {

                                                        })
                                                    })
                                                    if (res.result.items && res.result.items.length) {
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.result.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.oridnalNumber', Number(res.firstFreeItemNumber))
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.oridnalNumber', 1)
                                                    }

                                                }
                                            })
                                        } else {
                                            this._apiAsync('insert-new', data).then((res) => {
                                                if (res.result && res.result.id) {
                                                    this.setState({ supplierReturnForm: null }, () => {
                                                        this.setState({
                                                            supplierReturnForm: {
                                                                _id: res.result.id,
                                                                ...res.result.result
                                                            }, items: res.result.result.items && res.result.result.items.length ? res.result.result.items : []
                                                        })
                                                    })
                                                    if (res.result.result.items && res.result.result.items.length) {
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.result.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.oridnalNumber', Number(res.firstFreeItemNumber))
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.oridnalNumber', 1)
                                                    }

                                                }
                                            })
                                        }

                                        this.setState({
                                            disableArticleEdit: false
                                        })

                                    }}
                                    initialValues={this.state.supplierReturnForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        },
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            className: 'lock-icon-container',
                                                                            children: <div className="lock-icon">
                                                                                <Isvg src={lockIcon} onClick={() => {
                                                                                    this.setState({
                                                                                        lockModal: !this.state.lockModal
                                                                                    })
                                                                                }} />
                                                                                {
                                                                                    this.state.lockModal ?
                                                                                        <div className='lock-modal'>
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <Label>Unesite kod</Label>
                                                                                                <Input type='text' />
                                                                                            </div>

                                                                                            <button onClick={() => {
                                                                                                this.setState({
                                                                                                    lockModal: !this.state.lockModal
                                                                                                })
                                                                                            }}><Isvg src={lockIcon} /> Otključaj</button>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>,
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom-supplier-return',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Proknjiži</span></div>,
                                                                                            className: 'options-button',
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput(this.state.supplierReturnForm).then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })

                                                                                                })
                                                                                            },
                                                                                            children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                            className: 'options-button'
                                                                                        },

                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row custom-input-row-start reservation-row-1",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            integer: true,
                                                            next: 'returnDate',
                                                            label: 'Broj dokumenta',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value === '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'returnDate',
                                                            next: 'clientCode',
                                                            label: 'Datum povrata',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value === '') return "Datum fakturisanja mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: 'accountNumber',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            label: 'Šifra komitenta',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value === '') return "Šifra komitenta mora biti unesena!"
                                                                                    if (this.state.clientsSearch.length === 0 && this.state.clients.filter(item => item.code === value).length === 0 && this.state.clients.filter(item => item.name === value).length === 0) {
                                                                                        return "Neispravan unos!"
                                                                                    }
                                                                                    if (this.state.clientsSearch.length !== 0 && this.state.clientsSearch.filter(item => item.code === value).length === 0 && this.state.clientsSearch.filter(item => item.name === value).length === 0) {
                                                                                        return "Neispravan unos!!"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value === '') return "Naziv komitenta mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'accountNumber',
                                                            next: 'item.article',
                                                            readOnly: this.state.accountNumberDisabled ? true : false,
                                                            values: [...this.state.entrace.map(item => {
                                                                let obj = {
                                                                    name: item.accountNumber,
                                                                    value: item.accountNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Broj računa',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value === '') return "Broj računa mora biti unesen!" }
                                                            ]
                                                        },

                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.oridnalNumber',
                                                            label: 'Redni broj',
                                                            finishField: true,
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value === '') return "Redni broj mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            showName: true,
                                                            values: [{ name: '', value: -1 }, ...this.state.articles.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Artikal',
                                                            readOnly: this.state.disableArticleEdit,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {

                                                                    let item = this.registerGetValue('item')
                                                                    let checkValidation = true;
                                                                    if (item && Object.keys(item).length <= 1 && this.state.supplierReturnForm?._id) {
                                                                        checkValidation = false;
                                                                    }
                                                                    if ((!value || value === '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"

                                                                    if (this.state.articlesSearch.length === 0 && this.state.products.filter(item => item.code === value).length === 0 && this.state.products.filter(item => item.name === value).length === 0 && checkValidation) {
                                                                        return "Neispravan unos!"
                                                                    }
                                                                    if (this.state.articlesSearch.length !== 0 && this.state.articlesSearch.filter(item => item.code === value).length === 0 && this.state.articlesSearch.filter(item => item.name === value).length === 0 && checkValidation) {
                                                                        return "Neispravan unos!!"
                                                                    }


                                                                    this.setState({ numberOfSeries: this.state.articlesSearch?.filter(item => item.code === value)[0]?.series?.length })
                                                                }

                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'item.serialNumber',
                                                            next: 'item.ammount',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            readOnly: this.state.disableArticleEdit,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    let item = this.registerGetValue('item')
                                                                    if (item && item.serialNumber)
                                                                        for (let i = 0; i < this.state.series.length; i++) {
                                                                            if (item.serialNumber === this.state.series[i].serialNumber && this.state.series[i].ammount < 1) {

                                                                                return 'Artikla po toj seriji nema na stanju!'
                                                                            }

                                                                        }
                                                                    let checkValidation = true;
                                                                    if (item && Object.keys(item).length <= 1 && this.state.supplierReturnForm?._id) {
                                                                        checkValidation = false;
                                                                    }
                                                                    if (this.state.series.length !== 0 && !value && checkValidation) {
                                                                        return 'Serija mora biti unešena!!'
                                                                    }
                                                                    if (this.state.series.length === 0 && this.state.numberOfSeries !== 0 && checkValidation) {
                                                                        return 'Neispravan unos!!'
                                                                    }
                                                                    if (this.state.numberOfSeries === 0 && value && checkValidation) {
                                                                        return 'Neispravan unos!'
                                                                    }

                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'item.deadline',
                                                            label: 'Rok',
                                                            finishField: true,
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.ammount',
                                                            next: 'submitForm',
                                                            integer: true,
                                                            label: 'Količina',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    let item = this.registerGetValue('item')
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 1) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value === '') && checkValidation) {
                                                                        return "Količina mora biti unesena!"
                                                                    } else if (value > this.state.maxAmmoutn && checkValidation)
                                                                        return this.state.ammoutnError
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'item.price',
                                                            label: 'Cijena',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [
                                                                (value) => {
                                                                    let item = this.registerGetValue('item')
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 1) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value === '') && checkValidation)
                                                                        return "Cijena mora biti unesena!"
                                                                }

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'item.discount',
                                                            label: 'Rabat',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [
                                                                (value) => {
                                                                    let item = this.registerGetValue('item')
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 1) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value === '') && checkValidation)
                                                                        return "Ukupna cijena mora biti unesena!"
                                                                }
                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'oridnalNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} />,
                                                onClick: (item) => {
                                                    let supplierReturnForm = this.state.supplierReturnForm;
                                                    supplierReturnForm.item = item
                                                    this.setState({ supplierReturnForm: null }, () => {
                                                        this.setState({ supplierReturnForm })
                                                    })

                                                    //disable serial number, article and deadline for edit item
                                                    this.setState({
                                                        disableArticleEdit: true
                                                    })
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {

                                    this._apiAsync('remove-item', { _id: this.state.supplierReturnForm._id, oridnalNumber: this.state.deleteModal.oridnalNumber }).then((res) => {
                                        console.log(res.result)
                                        if (res.result && res.result._id) {
                                            console.log('test')
                                            this.setState({ supplierReturnForm: null, deleteModal: null }, () => {
                                                this.setState({ supplierReturnForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                                            })
                                            if (res.result.items && res.result.items.length) {
                                                this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                    if (res && res.firstFreeItemNumber) {
                                                        this.remoteOnChange('item.oridnalNumber', Number(res.firstFreeItemNumber))
                                                    }

                                                })
                                            } else {
                                                this.remoteOnChange('item.oridnalNumber', 1)
                                            }

                                        }
                                    })
                                }} >
                                Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.oridnalNumber : ''}</strong> ?
                            </DeleteModal>
                            <DeleteModal
                                isOpen={this.state.deleteDocumentModal}
                                toggle={() => this.setState({ deleteDocumentModal: null })}
                                handler={() => {
                                    if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                        this.setState({ deleteDocumentModal: null })
                                        this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                        return;
                                    } else {
                                        this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                            if (res.status === 405) {
                                                this.setState({ deleteDocumentModal: null })
                                                this.setState({ error: res.error })
                                            } else {
                                                this.setState({ deleteDocumentModal: null, supplierReturnForm: null, items: null, firstFreeNumber: null }, () => {
                                                    this.setState({ supplierReturnForm: {} }, () => this._onLoad())
                                                })
                                            }
                                        })
                                    }
                                }} >
                                Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                            </DeleteModal>
                            <ErrorModal
                                isOpen={this.state.error}
                                toggle={() => this.setState({ error: null })}
                                error={this.state.error}
                            />
                            <DescriptionMOdal
                                isOpen={this.state.opisModal}
                                toggle={() => this.setState({ opisModal: null })}
                                value={this.state.supplierReturnForm && this.state.supplierReturnForm.description ? this.state.supplierReturnForm.description : ''}
                                onChange={(description) => {
                                    this.setState({
                                        supplierReturnForm: {
                                            description: description
                                        },
                                    })
                                }}
                            />
                            <div className="footer">
                                <div className="footer-left">
                                    <button className="blue-button" onClick={() => {
                                        this.setState({
                                            opisModal: true
                                        })
                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>OPIS</p>
                                    </button>
                                    <button onClick={() => {
                                        this.setState({
                                            deleteDocumentModal: this.state.supplierReturnForm
                                        })
                                    }}>
                                        <div className="box box-red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <p>BRISANJE DOKUMENTA</p>
                                    </button>
                                </div>
                                <div className="footer-right">

                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>{this.state.basicPrice} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>{this.state.pricePDV} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>{this.state.basicPriceWithPDV} KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterAccountNumber} onChange={(e) => this.setState({ filterAccountNumber: e.target.value }, () => {
                                                    this.updateParams('accountNumber', this.state.filterAccountNumber)
                                                })} placeholder={'Broj računa'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterArticle} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                    this.updateParams('article', this.state.filterArticle)
                                                })} placeholder={'Artikal'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} placeholder={'Korisnik'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterAccountNumber: '',
                                                filterClient: '',
                                                filterArticle: '',
                                                filterUser: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'article', value: null }, { name: 'accountNumber', value: null }, { name: 'client', value: null }, { name: 'user', value: null }])

                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'returnDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'price', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}
                                items={this.state.supplierReturnItems.map((item, idx) => {
                                    let total = 0
                                    if (item.items.length)
                                        for (let i = 0; i < item.items.length; i++) {
                                            total += item.items[i].total

                                        }
                                    return {
                                        ...item,
                                        time: item.tsCreated ? this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm') : '',
                                        price: total.toFixed(2)
                                    }
                                })}
                                actions={
                                    [
                                        {
                                            component: <Button style={{ background: '#138DB9' }}><Isvg src={printIcon} /></Button>,
                                            onClick: (item) => {
                                                this.setState({ printHtml: null, loadingExport: true }, () => this.printOutput(item))
                                                // return 'open-modal';
                                            }
                                        }
                                    ]
                                }


                                rawItems={this.state.supplierReturnItems}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                        </>
                }


                {
                    this.state.loadingExport || this.state.loadingPage ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/loader.json"
                                style={{ height: typeof window !== 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window !== 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }
            </div>
        )
    }
}

export default Page(WriteOffPage);
