import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import moment from "moment";

moment.locale('sr')

class DateField extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let selectedYear;
        if (typeof window != 'undefined' && localStorage.year) {
            selectedYear = localStorage.year;
        }

        let lastDateInYear = selectedYear + "-12-31";
        let firstDateInYear = selectedYear + "-01-01";

        let splittedValue = this.props.value ? this.props.value.split('.') : [];
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName} input-type-text`}>
                <Label>{this.props.label}</Label>
                

                <input className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={this.props.textType ? this.props.textType : "text"}
                    id={this.props.id ? this.props.id : ''}
                    ref={(node) => {
                        if (this.props.setFieldRef)
                            this.props.setFieldRef(node)
                    }}
                    onKeyUp={(e) => {
                        if (e.key == this.props.finishKey) {
                            this.props.focusNext();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (this.props.onKeyDown) {
                            this.props.onKeyDown(e)
                        }
                    }}
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^\d.]/g, '')
    
                        let date = e.target.value;
                        let value = this.props.value;
                        if (!date || (date && date.length < 11)) {
                            if (date && value && (value.length != 3 && value.length != 6) && (date.length == 2 || date.length == 5) && date.split('.').length < 3) {
                                date += '.'
                                if (date && date.length == 6 && !this.props.autoCompleteYearOff) {
                                    let checkYear = date.substr(3, 4) + date.substr(0, 2) + '.' + moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY');
                                    if (new Date(checkYear).getTime() / 1000 > new Date().getTime() / 1000) {
                                        date += selectedYear ? selectedYear : moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY')
                                    } else {
                                        date += selectedYear ? selectedYear : String(Number(moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY')) + 1)
                                    }

                                }
                            }

                            if (date && date.length > 2 && date[2] != '.' && date.split('.').length < 3) {
                                date = date.substr(0, 2) + '.' + date.substr(2);
                            }
                            if (date && date.length > 5 && date[5] != '.' && date.split('.').length < 3) {
                                date = date.substr(0, 5) + '.' + date.substr(5);
                            }


                            let splitted = date.split('.');

                            if (parseInt(splitted[1]) > 12) {
                                splitted[1] = '12';
                            }
                            if (parseInt(splitted[0]) > 31) {
                                splitted[0] = '31';
                            }


                            let dt = new Date(splitted[2], splitted[1], 1);
                            let month = dt.getMonth();
                            let year = dt.getFullYear();
                            let daysInMonth = new Date(year, month, 0).getDate();
                            if (parseInt(splitted[0]) > daysInMonth) {
                                splitted[0] = daysInMonth;
                            }

                            date = splitted.join('.')


                           

                            this.props.onChange(date)
                        }


                    }}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    disabled={this.props.readOnly}
                    value={this.props.value ? this.props.value : ''}
                    placeholder={this.props.placeholder}
                ></input>
                {this.props.error && this.props.touched ?
                    <div className="invalid-tooltip-wrap-date">
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    </div>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default DateField;
