
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class DisplayContolsPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            crateDutieModal: false,
            total: 0,
            products: [],
            items: [
                {
                    _id: 1,
                    articleCode: 12312,
                    barCode: 1212132131,
                    name: 'test',
                    serialNumber: '213',
                    dateString: '21.12.2021',
                    businessUnit: 123,
                    controledBy: 'Pero Peric',
                    time: '14:15'
                }


            ],
            series: [],
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Zaduženja gajbica</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                    <div className="field-strap-wrap" style={{ width: '50%' }}>
                                        <Input type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                            this.updateParams('client', this.state.filterClient)
                                        })} placeholder={'Komitent'} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: '50%' }}>
                                        <Input type='text' value={this.state.filterBusiness != null ? this.state.filterBusiness : params.business} onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                            this.updateParams('business', this.state.filterBusiness)
                                        })} placeholder={'Poslovna'} />
                                    </div>
                                    {/* <div className="clear-filters" style={{ top: 0, marginLeft: 25 }} onClick={() => {

                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterBusiness: '',
                                            filterTypeOfChange: -1,
                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'change', value: null }])

                                        })

                                    }}>
                                        <a><span>&times;</span> Ukloni filter</a>
                                    </div> */}

                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                maxHeight={'70vh'}
                                fields={[
                                    { type: 'text', name: 'clientCode', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: true, width: '10%' },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: '75%' },
                                    { type: 'text', name: 'amount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true, width: '10%' },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={arrowDown} className="delete-icon" />,
                                            onClick: (item) => {
                                                let obj = { ...item }
                                                if (!obj.duties) {
                                                    obj.duties = []
                                                }
                                                this.setState({ crateDutieModal: obj })
                                            }
                                        },
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>

                    </Row>
                    {
                        this.state.crateDutieModal ?
                            <Modal isOpen={this.state.crateDutieModal} toggle={() => this.setState({ crateDutieModal: false })} size='lg' className="modal-wrap-cold-chain-preview">
                                <ModalHeader>
                                    <div className='title'>{this.state.crateDutieModal.clientName}</div>
                                    <div className='close-button' onClick={() => this.setState({ crateDutieModal: null })}>&times;</div>
                                </ModalHeader>
                                <ModalBody style={{ textAlign: 'left' }}>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        maxHeight={'70vh'}
                                        total={0}
                                        hidePagination={true}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'businessName', label: 'IME POSLOVNE'.translate(this.props.lang), multilang: false, allowSort: false, width: '60%' },
                                            { type: 'text', name: 'crateCodes', label: 'ŠIFRA GAJBICE'.translate(this.props.lang), multilang: false, allowSort: false, width: '30%' },
                                            { type: 'text', name: 'amount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },


                                        ]}
                                        items={this.state.crateDutieModal.duties.map((item, idx) => {
                                            return {
                                                ...item,
                                                business: item.business != 'others' && item.businessName ? item.businessName : '',
                                                amount: item.crateCodes && item.crateCodes.length ? item.crateCodes.length : 0,
                                                crateCodes: item.crateCodes && item.crateCodes.length && item.crateCodes.map((code, idx) => {
                                                    let text = code + (((idx + 1) != item.crateCodes.length) ? ', ' : '')
                                                    return text;
                                                })
                                            }
                                        })}
                                        rawItems={this.state.crateDutieModal}
                                        actions={
                                            [
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ form: item })
                                                //     //onClick: (item) => console.log(item)
                                                // },
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ deleteModal: item })
                                                // },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>

                            </Modal>
                            :
                            null
                    }


                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(DisplayContolsPage);
