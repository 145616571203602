import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import PrintHelper from '../../components/printHelper';

import DateField from '../../form-builder/fields/date';

import Loader from '../../components/loader';
import env from "react-dotenv";

import moment from 'moment';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Button,
    Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import ErrorModal from "../../components/errorModal";


function numberWithCommas(x) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '0'
        }

    } else {
        return '0'
    }

}

class EntrancePage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            clientList: true,
            clientForm: false,
            entryOut: true,
            buttonActive: 2,
            entranceForm: null,
            clients: [],
            products: [],
            listCustomFilter: true,
            items: [],
            dependentCosts: [],
            expenses: [],
            entranceItems: [],
            series: [],
            stock: null,
            courses: [],
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            knjiz: true,
            articlesSearch: [],
            clientsSearch: [],
            disableEdit: false,
            kssFieldDisabled: true,
            error: null,

        }
    }

    componentDidMount() {
        // let params = this._getParams();
        this._onLoad();
        // this._api('get-entrace', params)

        let params = this._getParams();
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.documentNumber) {
            let checkDocNumber = this.props[0].location.state.documentNumber;
            this.props[0].history.push({
                state: { documentNumber: null }
            })
            if (this.remoteOnChange)
                this.remoteOnChange('documentNumber', Number(checkDocNumber))

        }

        if (this.props[0].location && this.props[0].location.search) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') != -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }

            }

        }

        this.metoda()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (!prevState.firstFreeNumber && this.state.firstFreeNumber) {
            let entranceForm = this.state.entranceForm;
            if (!entranceForm || Object.keys(entranceForm).length == 0) {
                entranceForm = {
                    entraceDate: this.state.dateNow
                }
            }
            entranceForm.documentNumber = this.state.firstFreeNumber;
            if (!entranceForm.item) {
                entranceForm.item = { itemNumber: 1 }
            }
            this.setState({ entranceForm: null }, () => {
                this.setState({ entranceForm })
            })
        }
        if (this.props.selectedYear != prevProps.selectedYear) {
            this._onLoad();
        }
        if (JSON.stringify(prevState.stock) != JSON.stringify(this.state.stock)) {
            this.checkArticleType()
        }
        if (!this.registerGetValue('item.article') && !this.state.kssFieldDisabled) {
            this.setState({ kssFieldDisabled: true })
        }
    }
    checkArticleType = () => {
        let kssFieldDisabled = this.state.kssFieldDisabled;
        let arr = [
            'Lijekovi sa kontrolisanim uslovima čuvanja na sobn',
            'Narkotici',
            'Psihotropni',
            'Lijekovi iz ljudske krvi i plazme',
            'Imunološki lijekovi',
            'Radiofarmaceutici',
            'Medicinski gasovi',
            'Hladni lanac',
            'Lijekovi za klinička ispitivanja'
        ]
        if (this.state.stock) {
            if (this.state.stock.articleType && this.state.stock.articleType.name) {
                let checkName = this.state.stock.articleType.name;
                if (arr.indexOf(checkName) !== -1) {
                    kssFieldDisabled = false;
                } else {
                    kssFieldDisabled = true;
                }
            } else {
                kssFieldDisabled = true;
            }
        } else {
            kssFieldDisabled = true;
        }

        if (kssFieldDisabled != this.state.kssFieldDisabled) {
            this.setState({ kssFieldDisabled })
        }
    }

    goToFormTab = () => {
        this._onLoad()
        let entranceForm = this.state.entranceForm;
        if (!entranceForm) {
            entranceForm = {
                entraceDate: this.state.dateNow
            }
        }
        entranceForm.documentNumber = this.state.firstFreeNumber;
        if (!entranceForm.item) {
            entranceForm.item = { itemNumber: 1 }
        }
        this.setState({ entranceForm: null }, () => {
            this.setState({ entranceForm })
        })
    }
    insertOrUpdate = (data, doNivelation = false) => {
        if (data && data.item && data.item.article) {
            data.item.article = Number(data.item.article)
        }
        if (data && data.clientCode) {
            data.clientCode = Number(data.clientCode)
        }

        if (data && data.item && data.item.kss && data.item.kss == -1) {
            delete data.item.kss;
        }

        if (doNivelation) {
            data.doNivelation = true;
        }
        if (data._id) {
            this._apiAsync('update-document', data).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ entranceForm: null }, () => {
                        if (this.remoteOnChange) {
                            this.remoteOnChange('item.article', '')
                        }
                        this.setState({ entranceForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [], products: [] }, () => {
                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                this.metodaClient(this.state.entranceForm.clientCode)
                            }
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        } else {
            this._apiAsync('insert-new', data).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ entranceForm: null }, () => {
                        if (this.remoteOnChange) {
                            this.remoteOnChange('item.article', '')
                        }
                        this.setState({ entranceForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] }, () => {
                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                this.metodaClient(this.state.entranceForm.clientCode)
                            }
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        }
    }
    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }
    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }

    printEntrace = async () => {
        this.setState({ loadingPage: true }, async () => {
            const printHtml = await this._apiAsync('printEntranceDocument', { documentNumber: this.state.entranceForm.documentNumber })
            this.setState({ loadingPage: false })
            if (printHtml && printHtml.printHtml) {
                this.setState({ printHtml: printHtml.printHtml })
            }
        })

    }


    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        if (value == this.registerGetValue('item.article'))
            this.setState({ articlesSearch: articleList.articlesSearch })
    }

    metodaClient = async (value) => {
        let clientList = []
        if (!value && this.registerGetValue && this.registerGetValue('clientCode')) {
            value = this.registerGetValue('clientCode');
        }
        clientList = await this._apiAsync('clientsSearch', { value: value })

        if (this.registerGetValue && this.registerGetValue('clientCode')) {
            if (this.registerGetValue('clientCode') == value) {
                this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
            }
        } else {
            this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
        }

    }

    exportList = () => {
        this.setState({ loadingPage: true }, () => {
            let params = this._getParams();
            this.setState({ printHtml: null }, () => {
                this._apiAsync('export-entrace', { ...params, action: 'export' }).then((res) => {
                    this.setState({ loadingPage: false })
                    if (res && res) {
                        if (res.success) {
                            window.location.href = env.API_URL + `${res.file}`;
                        }
                    }
                })
            })
        })
    }

    printList = async () => {
        let params = this._getParams();
        this.setState({ loadingPage: true }, async () => {
            await this._apiAsync('print-entrace', { ...params, action: 'print' });
            this.setState({ loadingPage: false })


        })
    }



    render() {
        // console.log('products', this.state.products)
        // console.log('clients', this.state.clients)

        let params = this._getParams();
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                }
                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive == 1 ?
                                <>Lista ulaza</>
                                :
                                <>Ulaz</>
                        }
                    </h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-group">
                                <button onClick={() => this.setState({ printHtml: null }, this.printList)}>

                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={() => this.exportList()}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                                {
                                    this.state.printHtml ?
                                        <PrintHelper html={this.state.printHtml} />
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                    }
                    {/*
                    <div className="title-options-group">
                        <button>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option red">
                                <Isvg src={deleteIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>
                    */}
                </div>

                {
                    this.state.entryOut ?
                        <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1, printHtml: null }, () => this._onLoad())}>
                                    Lista ulaza
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => {
                                    if (this.state.buttonActive != 2) {
                                        this.setState({ buttonActive: 2, entranceForm: null, disableEdit: false, items: [] }, () => this.goToFormTab())
                                    }
                                }}>
                                    Unos ulaza
                                </button>
                            </div>
                            {
                                this.state.buttonActive == 1 ?
                                    <div className="show-unfinished" onClick={() => {
                                        this.setState({ showUnfinished: !this.state.showUnfinished }, () => {
                                            this.updateParams('unfinished', this.state.showUnfinished)
                                        })
                                    }}>
                                        <Input type="checkbox" checked={this.state.showUnfinished}
                                        // onChange={(e) => {
                                        //     this.setState({ showUnfinished: e.target.checked }, () => {
                                        //         this.updateParams('unfinished', this.state.showUnfinished)
                                        //     })
                                        // }}
                                        />
                                        <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                                    </div>
                                    :
                                    this.state.buttonActive == 2 && this.state.entranceForm && this.state.entranceForm._id ?
                                        <div className="status">
                                            <b>Status:</b>&nbsp;
                                            {
                                                this.state.entranceForm && this.state.entranceForm.notFinished ?
                                                    'U pripremi'
                                                    :
                                                    'Proknjižen'
                                            }
                                        </div>
                                        :
                                        null
                            }
                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'factureDate', 'clientCode', 'course', 'item.deadline', 'item.serialNumber', 'item.article', 'item.facturePrice', 'item.discount', 'item.discountValue', 'item.margin', 'item.sellPrice', 'currency', 'item.ammount']}
                                    fieldTracker={async (name, value) => {
                                        // let focusedField = '';
                                        // if(this.registerFocusedField()){
                                        //     focusedField = this.registerFocusedField();
                                        // }

                                        if (name == 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, entranceForm: null }, () => {
                                                this.setState({ entranceForm: { entraceDate: this.state.dateNow } })
                                            })
                                        }
                                        // if (name == 'course' && !value) {
                                        //     this.remoteOnChange('course', null)
                                        // }
                                        if (name == 'clientCode') {
                                            if (value) {
                                                let clients = await this._apiAsync('get-clients', { filter: value })
                                                if (clients && clients.items) {
                                                    this.setState({ clients: clients && clients.items }, () => {
                                                        if (this.state.clients.filter(item => item.code == value).length == 1) {
                                                            if (this.state.clients.filter(item => item.code == value)[0] && this.state.clients.filter(item => item.code == value)[0].countryName) {
                                                                let countryName = this.state.clients.filter(item => item.code == value)[0].countryName;
                                                                if (countryName == 'BIH' || countryName == 'DISTRIKT BRČKO' || countryName == 'FEDERACIJA BIH') {
                                                                    if (this.state.jciRequired) {
                                                                        this.setState({ jciRequired: false }, () => {
                                                                            if (this.registerGetValue('jci'))
                                                                                this.remoteOnChange('jci', '')
                                                                            if (this.registerGetValue('course'))
                                                                                this.remoteOnChange('course', '')
                                                                        })
                                                                    }

                                                                } else {
                                                                    if (!this.state.jciRequired) {
                                                                        this.setState({ jciRequired: true })
                                                                    }
                                                                }
                                                            }
                                                            console.log('IMA VRIJEDNOST.....', this.state.jciRequired)
                                                            if (this.registerGetValue('clientName') != this.state.clients.filter(item => item.code == value)[0].name)
                                                                this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                        } else {
                                                            if (this.state.jciRequired) {
                                                                this.setState({ jciRequired: false }, () => {
                                                                    if (this.registerGetValue('jci'))
                                                                        this.remoteOnChange('jci', '')
                                                                    if (this.registerGetValue('course'))
                                                                        this.remoteOnChange('course', '')
                                                                })
                                                            }
                                                            if (this.registerGetValue('clientName'))
                                                                this.remoteOnChange('clientName', '')
                                                        }
                                                    })
                                                }

                                            } else {
                                                if (this.state.jciRequired) {
                                                    this.setState({ jciRequired: false }, () => {
                                                        if (this.registerGetValue('jci'))
                                                            this.remoteOnChange('jci', '')
                                                        if (this.registerGetValue('course'))
                                                            this.remoteOnChange('course', '')
                                                    })
                                                }
                                                if (this.registerGetValue('clientName'))
                                                    this.remoteOnChange('clientName', '')
                                            }
                                        }
                                        if (name == 'currency') {
                                            if (value) {
                                                let factureDate = this.registerGetValue('factureDate');
                                                if (factureDate && this.props.checkDateValid(factureDate)) {
                                                    let currencyDate = moment.unix(Math.floor(new Date(this.props.getOriginalDateFormat(factureDate)).getTime() / 1000) + Number(value) * 60 * 60 * 24).format('DD.MM.YYYY');
                                                    if (currencyDate != this.registerGetValue('currencyDate')) {
                                                        this.remoteOnChange('currencyDate', currencyDate)
                                                    }
                                                } else {
                                                    this.remoteOnChange('currencyDate', '')
                                                }
                                            } else {
                                                if (this.registerGetValue('currencyDate')) {
                                                    this.remoteOnChange('currencyDate', '')
                                                }

                                            }
                                        }
                                        if (name == 'factureDate') {
                                            if (value) {
                                                if (name == 'factureDate' && this.props.checkDateValid(value)) {
                                                    if (this.registerGetValue('currency')) {
                                                        let currencyDate = moment.unix(Math.floor(new Date(this.props.getOriginalDateFormat(value)).getTime() / 1000) + Number(this.registerGetValue('currency')) * 60 * 60 * 24).format('DD.MM.YYYY');
                                                        if (currencyDate != this.registerGetValue('currencyDate')) {
                                                            this.remoteOnChange('currencyDate', currencyDate)
                                                        }

                                                    }

                                                } else {
                                                    this.remoteOnChange('currencyDate', '')
                                                }
                                            } else {
                                                if (this.registerGetValue('currencyDate')) {
                                                    this.remoteOnChange('currencyDate', '')
                                                }
                                            }



                                        }


                                        if (name == 'documentNumber' && value) {
                                            this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                                if (res && res.result && res.result._id) {
                                                    this.setState({ entranceForm: null }, () => {
                                                        this.setState({ entranceForm: res.result }, () => {
                                                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                                                this.metodaClient(this.state.entranceForm.clientCode)
                                                            }

                                                            if (res.result.items && res.result.items.length) {
                                                                this.setState({
                                                                    items: res.result.items,
                                                                }, () => {
                                                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                        }

                                                                    })
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    items: [],
                                                                }, () => {
                                                                    this.remoteOnChange('item.itemNumber', 1)
                                                                })
                                                            }

                                                        })
                                                    })




                                                } else if (this.state.prevDocumentNumber != value /*&& this.state.entranceForm._id*/) {
                                                    this.setState({ entranceForm: null }, () => {
                                                        this.setState({ entranceForm: { documentNumber: value, entraceDate: this.state.dateNow } }, () => {

                                                            this.setState({ items: [], entranceForm: {} }, () => {
                                                                // this.remoteOnChange('item.itemNumber', 1)
                                                                this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                    if (res && res.firstFreeItemNumber) {
                                                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                    }

                                                                })
                                                            })

                                                        })
                                                    })



                                                }

                                                this.setState({ prevDocumentNumber: value })
                                            })

                                        }
                                        if (name == 'item.article') {
                                            if (value) {
                                                this.remoteOnChange('item.serialNumber', '')
                                                this.remoteOnChange('item.kss', '')
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.ammount', '')
                                                this.remoteOnChange('item.facturePrice', '')
                                                this.remoteOnChange('item.factureValue', '')
                                                this.remoteOnChange('item.discount', '')
                                                this.remoteOnChange('item.discountValue', '')
                                                this.remoteOnChange('item.margin', '')
                                                this.remoteOnChange('item.marginValue', '')
                                                this.remoteOnChange('item.sellPrice', '')

                                                let products = await this._apiAsync('get-articles', { filter: value })

                                                if (products && products.items) {
                                                    this.setState({ products: products && products.items })
                                                }
                                                let series = await this._apiAsync('get-series', { article: value, query: { $ne: { withoutSeries: true } } });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items })
                                                } else {
                                                    this.setState({ series: [] })
                                                }
                                                let stock = await this._apiAsync('get-stock', { article: value });
                                                if (stock) {
                                                    this.setState({ stock })
                                                    if (this.registerGetValue('item.sellPrice') != stock.sellPrice) {
                                                        this.remoteOnChange('item.sellPrice', stock.sellPrice)
                                                    }
                                                } else {
                                                    this.setState({ stock: null })
                                                }
                                                if (this.state.products && this.state.products.filter(item => item.code == value).length) {
                                                    let product = this.state.products.filter(item => item.code == value)[0];
                                                    if (product.articleTypeObj && product.articleTypeObj.maxProfit && this.state.maxMargin != Number(product.articleTypeObj.maxProfit)) {
                                                        this.setState({ maxMargin: Number(product.articleTypeObj.maxProfit) })
                                                    } else if (this.state.maxMargin) {
                                                        this.setState({ maxMargin: null })
                                                    }

                                                } else if (this.state.maxMargin) {
                                                    this.setState({ maxMargin: null })
                                                }
                                            } else {
                                                this.setState({ series: [] })
                                                if (this.state.maxMargin) {
                                                    this.setState({ maxMargin: null })
                                                }
                                            }

                                        }
                                        if (name == 'item.serialNumber') {
                                            if (value) {
                                                let article = this.registerGetValue('item.article');
                                                let series = await this._apiAsync('get-series', { article: article, filter: value });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items }, () => {
                                                        if (this.state.series && this.state.series.length) {
                                                            if (this.state.series.filter(item => item.serialNumber == value).length == 1) {
                                                                let item = this.state.series.filter(item => item.serialNumber == value)[0];
                                                                if (item && item.newExpirationDate) {
                                                                    if (this.registerGetValue('item.deadline') != item.newExpirationDate) {
                                                                        this.remoteOnChange('item.deadline', item.newExpirationDate)
                                                                    }
                                                                } else if (item && item.expirationDate) {
                                                                    if (this.registerGetValue('item.deadline') != item.expirationDate) {
                                                                        this.remoteOnChange('item.deadline', item.expirationDate)
                                                                    }
                                                                }

                                                            } else {
                                                                if (this.registerGetValue('item.deadline')) {
                                                                    this.remoteOnChange('item.deadline', '')
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    if (this.registerGetValue('item.deadline')) {
                                                        this.remoteOnChange('item.deadline', '')
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.deadline')) {
                                                    this.remoteOnChange('item.deadline', '')
                                                }
                                            }
                                        }
                                        if (name == 'item.ammount') {
                                            if (value && this.registerGetValue('item.facturePrice')) {
                                                let factureValue = this.props.roundToTwoDecimals((Number(value) * Number(this.registerGetValue('item.facturePrice'))))
                                                if (this.registerGetValue('item.factureValue') != factureValue)
                                                    this.remoteOnChange('item.factureValue', factureValue)
                                            } else {
                                                if (this.registerGetValue('item.factureValue') != 0)
                                                    this.remoteOnChange('item.factureValue', 0)
                                            }
                                            if (this.registerGetValue('item.margin')) {
                                                this.remoteOnChange('item.margin', this.registerGetValue('item.margin'))
                                            }
                                        }
                                        if (name == 'item.facturePrice') {
                                            if (String(value) && this.registerGetValue('item.ammount')) {
                                                let factureValue = this.props.roundToTwoDecimals((Number(value) * Number(this.registerGetValue('item.ammount'))))
                                                if (this.registerGetValue('item.factureValue') != String(factureValue)) {
                                                    this.remoteOnChange('item.factureValue', String(factureValue))
                                                    if (this.registerGetValue('item.sellPrice')) {
                                                        this.remoteOnChange('item.sellPrice', this.registerGetValue('item.sellPrice'))
                                                    } else if (this.registerGetValue('item.margin')) {
                                                        this.remoteOnChange('item.margin', this.registerGetValue('item.margin'))
                                                    }
                                                }
                                            } else {
                                                if (this.registerGetValue('item.factureValue') != 0)
                                                    this.remoteOnChange('item.factureValue', 0)
                                            }
                                        }
                                        if (name == 'item.discount') {
                                            if (value && this.registerGetValue('item.factureValue') && this.registerGetValue('item.discountValue') != (Number(value) * Number(this.registerGetValue('item.factureValue') / 100))) {
                                                let discountValue = this.props.roundToTwoDecimals(Number(value) * Number(this.registerGetValue('item.factureValue') / 100))
                                                if (this.registerGetValue('item.discountValue') != discountValue && this.registerFocusedField(name))
                                                    this.remoteOnChange('item.discountValue', discountValue)
                                            } else if (!(value && this.registerGetValue('item.factureValue')) && this.registerGetValue('item.discountValue') != (Number(value) * Number(this.registerGetValue('item.factureValue') / 100))) {
                                                if (this.registerGetValue('item.discountValue') != 0 && this.registerFocusedField(name))
                                                    this.remoteOnChange('item.discountValue', 0)
                                            }
                                            if (this.registerGetValue('item.margin')) {
                                                this.remoteOnChange('item.margin', this.registerGetValue('item.margin'))
                                            } else if (this.registerGetValue('item.sellPrice')) {
                                                this.remoteOnChange('item.sellPrice', this.registerGetValue('item.sellPrice'))
                                            }
                                        }
                                        if (name == 'item.discountValue') {

                                            if (value && this.registerGetValue('item.factureValue') && this.registerGetValue('item.discount') != Number(value) * 100 / Number(this.registerGetValue('item.factureValue'))) {
                                                let discount = this.props.roundToTwoDecimals(Number(value) * 100 / Number(this.registerGetValue('item.factureValue')))
                                                if (this.registerGetValue('item.discount') != discount && this.registerFocusedField(name))
                                                    this.remoteOnChange('item.discount', discount)
                                            } else if (!(value && this.registerGetValue('item.factureValue')) && this.registerGetValue('item.discount') != Number(value) * 100 / Number(this.registerGetValue('item.factureValue'))) {
                                                if (this.registerGetValue('item.discount') != 0 && this.registerFocusedField(name))
                                                    this.remoteOnChange('item.discount', 0)
                                            }
                                            if (this.registerGetValue('item.margin')) {
                                                this.remoteOnChange('item.margin', this.registerGetValue('item.margin'))
                                            } else if (this.registerGetValue('item.sellPrice')) {
                                                this.remoteOnChange('item.sellPrice', this.registerGetValue('item.sellPrice'))
                                            }
                                        }
                                        if (name == 'item.margin') {
                                            if (value) {
                                                let margin = 0;
                                                let sellPrice = 0;
                                                let marginValue = 0;
                                                if (value && this.registerGetValue('item.factureValue')) {
                                                    margin = Number(this.registerGetValue('item.factureValue'));
                                                    if (this.registerGetValue('item.discountValue')) {
                                                        margin -= Number(this.registerGetValue('item.discountValue'))
                                                    }
                                                    marginValue = this.props.roundToTwoDecimals(Number(value) * margin / 100);
                                                }
                                                if (this.registerGetValue('item.ammount')) {
                                                    sellPrice = this.props.roundToTwoDecimals((Number(marginValue) + Number(margin)) / Number(this.registerGetValue('item.ammount')))

                                                    if (this.registerGetValue('item.sellPrice') != sellPrice && (this.registerFocusedField(name) || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue') || this.registerFocusedField('item.ammount'))) {
                                                        this.remoteOnChange('item.sellPrice', sellPrice)
                                                    }
                                                }
                                                if (this.registerGetValue('item.marginValue') != marginValue && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue') || this.registerFocusedField('item.ammount'))) {
                                                    this.remoteOnChange('item.marginValue', marginValue)
                                                }
                                            } else {
                                                if (this.registerGetValue('item.marginValue') != 0 && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue') || this.registerFocusedField('item.ammount'))) {
                                                    this.remoteOnChange('item.marginValue', 0)
                                                }
                                                if (this.registerGetValue('item.sellPrice') != 0 && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue') || this.registerFocusedField('item.ammount'))) {
                                                    this.remoteOnChange('item.sellPrice', 0)
                                                }

                                            }
                                        }
                                        if (name == 'item.sellPrice') {
                                            if (value) {
                                                // if (this.registerGetValue('item.sellPrice') != value && this.state.skipNivaletionModal) {
                                                //     this.setState({ skipNivaletionModal: false })
                                                // }

                                                if (Number(this.registerGetValue('item.ammount')) && Number(this.registerGetValue('item.factureValue')) && Number(this.registerGetValue('item.facturePrice'))) {

                                                    let marginValue = Number(value) * Number(this.registerGetValue('item.ammount')) - Number(this.registerGetValue('item.factureValue'));
                                                    if (this.registerGetValue('item.discountValue')) {
                                                        marginValue += Number(this.registerGetValue('item.discountValue'))
                                                    }
                                                    marginValue = this.props.roundToTwoDecimals(marginValue);
                                                    let discount = 0;
                                                    if (this.registerGetValue('item.discount')) {
                                                        discount = Number(this.registerGetValue('item.discount'));
                                                    }

                                                    let margin;
                                                    if (this.state.entranceForm && this.state.entranceForm.dependentCosts) {
                                                        margin = 100 / ((Number(this.registerGetValue('item.factureValue')) / marginValue) * (100 - discount) / 100);
                                                        margin = this.props.roundToTwoDecimals(margin);
                                                    } else {
                                                        margin = 100 / ((Number(this.registerGetValue('item.factureValue')) / marginValue) * (100 - discount) / 100);
                                                        margin = this.props.roundToTwoDecimals(margin);
                                                    }

                                                    if (this.registerGetValue('item.marginValue') != marginValue && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue'))) {
                                                        this.remoteOnChange('item.marginValue', marginValue)
                                                    }
                                                    if (this.registerGetValue('item.margin') != margin && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue'))) {
                                                        this.remoteOnChange('item.margin', margin)
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.marginValue') != 0 && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue'))) {
                                                    this.remoteOnChange('item.marginValue', 0)
                                                }
                                                if (this.registerGetValue('item.margin') != 0 && (this.registerFocusedField(name) || this.registerFocusedField('item.facturePrice') || this.registerFocusedField('item.discount') || this.registerFocusedField('item.discountValue'))) {
                                                    this.remoteOnChange('item.margin', 0)
                                                }
                                            }
                                        }

                                    }
                                    }

                                    onSubmit={(data, notFinished) => {
                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                let item = JSON.parse(JSON.stringify(data.item));
                                                if (item && item.itemNumber) {
                                                    delete item.itemNumber;
                                                }
                                                if (item && Object.keys(item).length == 0) {
                                                    delete data.item;
                                                }

                                            }
                                        }
                                        if (data && data.item && data.item.sellPrice && this.state.stock && data.item.sellPrice != this.state.stock.sellPrice) {
                                            this.setState({ nivelationModal: data })
                                            return 'open-modal';
                                        } else {
                                            this.insertOrUpdate(data)
                                        }

                                        this.setState({
                                            disableEdit: false
                                        })
                                        // this._apiAsync('insert-new', data)
                                    }}
                                    initialValues={this.state.entranceForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [

                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.submit()
                                                                                                this.setState({ knjiz: false })
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option blue" ><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                            className: 'options-button',
                                                                                            disabled: this.state.entranceForm && this.state.entranceForm._id ? false : true,
                                                                                            onClick: () => {
                                                                                                // console.log(this.state.jciRequired)
                                                                                                // if (this.state.jciRequired) {
                                                                                                //     this.setState({
                                                                                                //         checkDependentCosts: true
                                                                                                //     })
                                                                                                // }
                                                                                                this.setState({ printHtml: null }, this.printEntrace)
                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },

                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.setState({ entranceForm: null, items: [] }, () => this.goToFormTab())
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            integer: true,
                                                            next: 'entraceDate',
                                                            label: 'Broj dok.',
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'entraceDate',
                                                            next: 'accountNumber',
                                                            label: 'Datum ulaza',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum ulaza mora biti unesen!" },
                                                                validateDate('Datum nije validan')

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'accountNumber',
                                                            next: 'factureDate',
                                                            label: 'Broj računa',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj računa mora biti unesen!" },

                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'factureDate',
                                                            next: 'currency',
                                                            label: 'Datum fakturisanja',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom',
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            // textType: 'number',
                                                                            integer: true,
                                                                            name: 'currency',
                                                                            next: 'clientCode',
                                                                            label: 'Valuta',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Valuta mora biti unesen!" },

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'currencyDate',
                                                                            next: 'jci',
                                                                            label: 'Datum valute',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: this.state.jciRequired ? 'jci' : 'item.article',
                                                                            label: 'Šifra kom.',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            title: 'Pregled komitenata',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            entrance: true,
                                                                            jciRequired: this.state.jciRequired,
                                                                            changeState: (callback) => {
                                                                                this.setState({ jciRequired: !this.state.jciRequired }, () => {
                                                                                    if (callback) {
                                                                                        callback()
                                                                                    }
                                                                                })

                                                                            },
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra komitenta mora biti unesen!" },
                                                                                required('Šifra komitenta mora biti unesen!'),
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            if (this.state.clients && this.state.clients.length && (this.state.clients.filter(el => String(el.code) == String(value)).length || this.state.clientsSearch.filter(el => String(el.code) == String(value)).length)) {
                                                                                                return undefined;
                                                                                            } else {
                                                                                                return 'Unesena šifra komitenta nije ispravna!';
                                                                                            }
                                                                                        } else {
                                                                                            return undefined;
                                                                                        }


                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },


                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            next: 'item.itemNumber',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        this.state.jciRequired ?
                                                            {
                                                                type: 'text',
                                                                name: 'jci',
                                                                next: 'course',
                                                                label: 'JCI',
                                                                // readOnly : !this.state.jciRequired,
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    required('JCI mora biti unesen!'),
                                                                ]
                                                            }
                                                            :
                                                            {
                                                                type: 'text',
                                                                name: 'jci',
                                                                next: 'course',
                                                                label: 'JCI',

                                                                readOnly: true,

                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                ]
                                                            },
                                                        this.state.jciRequired ?
                                                            {
                                                                type: 'select',
                                                                name: 'course',
                                                                next: 'item.article',
                                                                values: [{ name: '', value: -1 }, ...this.state.courses.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item._id
                                                                    }
                                                                    return obj
                                                                })],
                                                                label: 'Kurs',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    required('Kurs mora biti unesen!'),
                                                                    (value) => {
                                                                        let mm = this.state.courses.filter((item) => {
                                                                            return item._id == value
                                                                        })
                                                                        if (mm.length == 0) {
                                                                            return "Neispravan unos!"
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                            :
                                                            {
                                                                type: 'select',
                                                                name: 'course',
                                                                next: 'item.article',
                                                                values: [{ name: '', value: -1 }, ...this.state.courses.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item._id
                                                                    }
                                                                    return obj
                                                                })],
                                                                label: 'Kurs',
                                                                finishField: true,
                                                                asyncValidation: true,

                                                                validate: [
                                                                    (value) => {
                                                                        let mm = this.state.courses.filter((item) => {
                                                                            return item._id == value
                                                                        })
                                                                        if (mm.length == 0) {
                                                                            this.remoteOnChange('course', '')
                                                                        }
                                                                    }
                                                                ],

                                                                readOnly: true,
                                                            },


                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            textType: 'number',
                                                            label: 'R. br.',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,

                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            showName: true,
                                                            label: 'Artikal',
                                                            readOnly: this.state.disableEdit ? true : false,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Artikal mora biti unesen!" },
                                                                (value) => {
                                                                    // let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    // console.log('IITEMI, ITEMI...............', item)
                                                                    // if (item && item.itemNumber) {
                                                                    //     delete item.itemNumber;
                                                                    // }
                                                                    // let checkValidation = false;
                                                                    // if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                    //     checkValidation = true;
                                                                    // }
                                                                    // if ((!value || value == '') && checkValidation)
                                                                    //     return "Artikal mora biti unesen!"


                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    if (item && item.serialNumber == '') {
                                                                        delete item.serialNumber
                                                                    }
                                                                    if (item && item.ammount == '') {
                                                                        delete item.ammount
                                                                    }
                                                                    if (item && item.deadline == '') {
                                                                        delete item.deadline
                                                                    }
                                                                    if (item && item.price == '') {
                                                                        delete item.price
                                                                    }
                                                                    if (item && item.stock == '') {
                                                                        delete item.stock
                                                                    }
                                                                    if (item && item.total == '') {
                                                                        delete item.total
                                                                    }
                                                                    let checkValidation = false;

                                                                    if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if (!(item && Object.keys(item).length > 0 || !this.state.entranceForm._id) && this.state.entranceForm._id && this.state.knjiz) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"
                                                                    this.setState({ knjiz: true })
                                                                },
                                                                (value) => {
                                                                    let checkValidation = false;
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0 && checkValidation) {
                                                                        return "Neispravan unos!"
                                                                    }

                                                                    if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0 && checkValidation) {
                                                                        return "Neispravan unos!!"
                                                                    }

                                                                    // this.setState({numberOfSeries : this.state.products.filter(item => item.code == value)[0].series.length})
                                                                }

                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'item.serialNumber',
                                                            next: this.registerGetValue && this.registerGetValue('item.serialNumber') && this.state.series && this.state.series.findIndex(item => item.serialNumber === this.registerGetValue('item.serialNumber')) !== -1 ? 'item.ammount' : this.state.kssFieldDisabled ? 'item.deadline' : 'item.kss',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            readOnly: this.state.disableEdit ? true : false,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                async (value) => {
                                                                    try {
                                                                        if (value && this.registerGetValue('item.article') && value != 'NEMA SERIJE' ) {
                                                                            let res = await this._apiAsync('validate-serial-number', { value: value, code: this.registerGetValue('item.article') });
                                                                            if (res.status == 400) {
                                                                                return 'Šifra je zauzeta'
                                                                            }
                                                                            return undefined;
                                                                        }

                                                                    } catch (e) {
                                                                        return undefined;
                                                                    }
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'select',
                                                            name: 'item.kss',
                                                            next: 'item.deadline',
                                                            values: [{ name: '', value: -1 }, { name: 'Na KSS', value: 'kss' }],
                                                            label: 'KSS',
                                                            finishField: true,
                                                            asyncValidation: false,
                                                            readOnly: this.state.kssFieldDisabled,
                                                            validate: [],
                                                            onFinishKey: () => {
                                                                let value = this.registerGetValue('item.kss');
                                                                if (!value) {
                                                                    this.remoteOnChange('item.kss', 'kss')
                                                                }
                                                            }
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            // values: [{ name: '', value: -1 }],
                                                            label: 'Rok',
                                                            autoCompleteYearOff: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: this.state.disableEdit ? true : this.registerGetValue && this.registerGetValue('item.serialNumber') && this.state.series && this.state.series.findIndex(item => item.serialNumber === this.registerGetValue('item.serialNumber')) !== -1 ? true : false,
                                                            validate: [
                                                                validateDate('Datum nije validan'),
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));

                                                                    if (item && item.serialNumber && (!value || value === ''))
                                                                        return 'Rok mora biti une unešen!'
                                                                }

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'item.ammount',
                                                            next: 'item.facturePrice',
                                                            integer: true,
                                                            label: 'Količina',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Količina mora biti unesena!"
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.facturePrice',
                                                                            next: 'item.discount',
                                                                            label: 'Fakt. cijena',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Fakt. cijena  mora biti unesen!" },
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    let checkValidation = false;
                                                                                    if (item && Object.keys(item).length > 0) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Fakt. cijena mora biti unesena!"
                                                                                },

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.factureValue',
                                                                            next: 'item.discount',
                                                                            label: 'Fakt. iznos',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,

                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.discount',
                                                                            next: 'item.discountValue',
                                                                            label: 'Rabat',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            // validate: [
                                                                            //     (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },

                                                                            // ]
                                                                        },
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.discountValue',
                                                                            next: 'item.margin',
                                                                            label: 'Rabat iznos',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            // validate: [
                                                                            //     (value) => { if (!value || value == '') return "Rabat iznos mora biti unesen!" },

                                                                            // ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.margin',
                                                                            next: 'item.sellPrice',
                                                                            label: 'Marža',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Marža mora biti unesen!" },
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    let checkValidation = false;
                                                                                    if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Marža mora biti unesena!"
                                                                                },
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value && this.state.maxMargin) {
                                                                                            if (Number(this.state.maxMargin) < Number(value)) {
                                                                                                return `Maksimalna marža je ${this.state.maxMargin}`
                                                                                            }
                                                                                            return undefined;
                                                                                        }

                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            textType: 'number',
                                                                            name: 'item.marginValue',
                                                                            next: 'item.sellPrice',
                                                                            readOnly: true,
                                                                            label: 'Marža iznos',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.sellPrice',
                                                            next: 'submitForm',
                                                            afterSubmitFocus: 'item.article',
                                                            label: 'Prod. cijena',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Prod. cijena mora biti unesen!" },
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 || !this.state.entranceForm._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Prod. cijena mora biti unesena!"
                                                                },

                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.totalItems}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'facturePrice', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        { type: 'text', name: 'facturePriceWithPdv', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', numberWithCommas: true },

                                    ]}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} />,
                                                onClick: async (item) => {
                                                    let entranceForm = this.state.entranceForm;
                                                    entranceForm.item = item;
                                                    if (entranceForm.item && entranceForm.item.article) {
                                                        let value = entranceForm.item.article;
                                                        let products = await this._apiAsync('get-articles', { filter: value })
                                                        if (products && products.items) {
                                                            this.setState({ products: products && products.items })
                                                        }
                                                        let series = await this._apiAsync('get-series', { article: value });
                                                        if (series && series.items) {
                                                            this.setState({ series: series.items })
                                                        } else {
                                                            this.setState({ series: [] })
                                                        }
                                                        let stock = await this._apiAsync('get-stock', { article: value });
                                                        if (stock) {
                                                            this.setState({ stock })
                                                            // if (this.registerGetValue('item.sellPrice') != stock.sellPrice) {
                                                            //     this.remoteOnChange('item.sellPrice', stock.sellPrice)
                                                            // }
                                                        } else {
                                                            this.setState({ stock: null })
                                                        }
                                                        if (this.state.products && this.state.products.filter(item => item.code == value).length) {
                                                            let product = this.state.products.filter(item => item.code == value)[0];
                                                            if (product.articleTypeObj && product.articleTypeObj.maxProfit && this.state.maxMargin != Number(product.articleTypeObj.maxProfit)) {
                                                                this.setState({ maxMargin: Number(product.articleTypeObj.maxProfit) })
                                                            } else if (this.state.maxMargin) {
                                                                this.setState({ maxMargin: null })
                                                            }

                                                        } else if (this.state.maxMargin) {
                                                            this.setState({ maxMargin: null })
                                                        }

                                                        //disable serial number, article and deadline for edit item
                                                        this.setState({
                                                            disableEdit: true
                                                        })
                                                    }


                                                    this.setState({ entranceForm: null }, () => {
                                                        this.setState({ entranceForm }, () => {
                                                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                                                this.metodaClient(this.state.entranceForm.clientCode)
                                                            }
                                                        })
                                                    })
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            facturePriceWithPdv: item.facturePrice && Number(item.ammount) ? (Number(item.ammount) * Number(item.facturePrice)) : 0

                                        }
                                    })}


                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-item', { _id: this.state.entranceForm._id, itemNumber: this.state.deleteModal.itemNumber }).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ entranceForm: null, deleteModal: null }, () => {
                                                    this.setState({ entranceForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] }, () => {
                                                        if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                                            this.metodaClient(this.state.entranceForm.clientCode)
                                                        }
                                                    })
                                                })
                                                if (res.result.items && res.result.items.length) {
                                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }

                                            }
                                        })
                                    }} >
                                    Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                                </DeleteModal>
                                <DeleteModal
                                    isOpen={this.state.deleteDocumentModal}
                                    toggle={() => this.setState({ deleteDocumentModal: null })}
                                    handler={() => {
                                        if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                            this.setState({ deleteDocumentModal: null })
                                            this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                            return;
                                        } else {
                                            this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                                if (res.status === 405) {
                                                    this.setState({ deleteDocumentModal: null })
                                                    this.setState({ error: res.error })
                                                } else {
                                                    this.setState({ deleteDocumentModal: null, entranceForm: null, items: [], firstFreeNumber: null }, () => {
                                                        this.setState({ entranceForm: {} }, () => this._onLoad())
                                                    })
                                                }
                                            })
                                        }

                                    }} >
                                    Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                                </DeleteModal>
                                <ErrorModal
                                    isOpen={this.state.error}
                                    toggle={() => this.setState({ error: null })}
                                    error={this.state.error}
                                />
                                <InfoModal
                                    isOpen={this.state.checkDependentCosts}
                                    toggle={() => this.setState({ checkDependentCosts: null })}
                                    title={'Obavještenje'}
                                    info={'Niste unijeli zavisne troškove, da li želite da nastavite?'}
                                    buttons={[
                                        {
                                            text: 'Da',
                                            onClick: () => this.setState({ checkDependentCosts: null })
                                        },
                                        {
                                            text: 'Ne',
                                            onClick: () => this.setState({ checkDependentCosts: null })
                                        }
                                    ]}
                                >
                                </InfoModal>
                                <InfoModal
                                    isOpen={this.state.nivelationModal}
                                    disableKeyPress={true}
                                    zIndex={9999}
                                    toggle={() => { }}
                                    closeModal={() => { this.setState({ nivelationModal: null }) }}
                                    title={'Obavještenje'}
                                    info={'Došlo je do promjene cijene i izvršiće se nivelacija. Da li želite da uradite NIVELACIJU KALKULACIJE ili NIVELACIJU LAGERA'}
                                    buttons={[
                                        {
                                            text: 'NIVELACIJA KALKULACIJE',
                                            onClick: () => {
                                                let entranceForm = this.state.nivelationModal;
                                                if (entranceForm && entranceForm.item && this.state.stock && this.state.stock.sellPrice) {
                                                    entranceForm.item.sellPrice = this.state.stock.sellPrice;

                                                    if (entranceForm.item && entranceForm.item.ammount && entranceForm.item.factureValue && entranceForm.item.facturePrice) {
                                                        let value = this.state.stock.sellPrice;
                                                        let marginValue = Number(value) * Number(entranceForm.item.ammount) - Number(entranceForm.item.factureValue);
                                                        if (entranceForm.item.discountValue) {
                                                            marginValue += Number(entranceForm.item.discountValue)
                                                        }
                                                        marginValue = this.props.roundToTwoDecimals(marginValue);
                                                        let discount = 0;
                                                        if (entranceForm.item.discount) {
                                                            discount = Number(entranceForm.item.discount);
                                                        }


                                                        let margin = 100 / ((Number(entranceForm.item.factureValue) / marginValue) * (100 - discount) / 100);
                                                        margin = this.props.roundToTwoDecimals(margin);

                                                        entranceForm.item.marginValue = marginValue;
                                                        entranceForm.item.margin = margin
                                                    }
                                                }

                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(entranceForm, true))
                                            }
                                        },
                                        {
                                            text: 'NIVELACIJA LAGERA',
                                            onClick: () => {
                                                let entranceForm = this.state.nivelationModal;
                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(entranceForm, true))
                                            }
                                        },



                                    ]}
                                >
                                </InfoModal>
                                {
                                    this.state.descriptionModal ?
                                        <Modal isOpen={this.state.descriptionModal} size='lg' className="modal-wrap">
                                            <ModalHeader>
                                                <h4>Opis</h4>
                                                <div className='close-button' onClick={() => this.setState({ descriptionModal: false, description: '' })}>&times;</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="dependent-costs-wrap">

                                                    <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <Label>Unesite opis</Label>
                                                        <Input type='textarea' style={{ height: 100 }} value={this.state.description} onChange={(e) => {
                                                            let value = e.target.value;

                                                            this.setState({ description: value })
                                                        }}>

                                                        </Input>
                                                    </FormGroup>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>


                                                <Button
                                                    onClick={() => {
                                                        if (this.state.entranceForm && this.state.entranceForm._id) {
                                                            this._apiAsync('update-document', { description: this.state.description, _id: this.state.entranceForm._id }).then((res) => {
                                                                if (res.result && res.result._id) {
                                                                    this.setState({ entranceForm: null }, () => {
                                                                        this.setState({ entranceForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [], products: [] }, () => {
                                                                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                                                                this.metodaClient(this.state.entranceForm.clientCode)
                                                                            }
                                                                        })
                                                                    })
                                                                    if (res.result.items && res.result.items.length) {
                                                                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    } else {
                                                                        this.remoteOnChange('item.itemNumber', 1)
                                                                    }

                                                                }
                                                            })

                                                        }
                                                        this.setState({ descriptionModal: null })

                                                    }}>Snimi</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null

                                }

                                {
                                    this.state.dependentCostsModal ?
                                        <Modal isOpen={this.state.dependentCostsModal} size='lg' className="modal-wrap">
                                            <ModalHeader>
                                                <h4>Zavisni troškovi</h4>
                                                <div className='close-button' onClick={() => this.setState({ dependentCostsModal: false, dependentCosts: [] })}>&times;</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="dependent-costs-wrap">
                                                    {
                                                        this.state.dependentCosts && this.state.dependentCosts.map((item, idx) => {
                                                            return (
                                                                <div className="dependent-cost">
                                                                    <FormGroup>
                                                                        <Label>Trosak</Label>
                                                                        <Input type='select' value={item.type} onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (value == -1) {
                                                                                value = null;
                                                                            }
                                                                            let dependentCosts = this.state.dependentCosts;
                                                                            dependentCosts[idx].type = value;
                                                                            this.setState({ dependentCosts })
                                                                        }}>
                                                                            <option value={-1}>{'Izaberite'}</option>
                                                                            {
                                                                                this.state.expenses && this.state.expenses.map((expense, idx) => {
                                                                                    return (
                                                                                        <option value={expense._id}>{expense.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>

                                                                    <FormGroup>
                                                                        <Label>Iznos</Label>
                                                                        <Input type="text" value={item.coast} onChange={(e) => {
                                                                            let dependentCosts = this.state.dependentCosts;
                                                                            dependentCosts[idx].coast = e.target.value;
                                                                            this.setState({ dependentCosts })
                                                                        }}></Input>
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>Iznos 1</Label>
                                                                        <Input type="text" value={item.coast1} onChange={(e) => {
                                                                            let dependentCosts = this.state.dependentCosts;
                                                                            dependentCosts[idx].coast1 = e.target.value;
                                                                            this.setState({ dependentCosts })
                                                                        }}></Input>
                                                                    </FormGroup>
                                                                    <div className="remove-item-button">
                                                                        <Button color="danger" onClick={() => {
                                                                            let dependentCosts = this.state.dependentCosts;
                                                                            dependentCosts.splice(idx, 1);
                                                                            this.setState({ dependentCosts })
                                                                        }}>&times;</Button>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button onClick={() => {
                                                    let dependentCosts = this.state.dependentCosts;
                                                    dependentCosts.push({
                                                        type: null,
                                                        coast: '0',
                                                        coast1: '0'
                                                    })
                                                    this.setState({ dependentCosts })
                                                }}>Dodaj</Button>

                                                <Button
                                                    disabled={this.state.dependentCosts && this.state.dependentCosts.length && this.state.dependentCosts.filter(dependentCost => dependentCost.type).length == this.state.dependentCosts.length ? false : true}
                                                    onClick={() => {
                                                        if (this.state.entranceForm && this.state.entranceForm._id) {
                                                            this._apiAsync('update-document', { dependentCosts: this.state.dependentCosts, _id: this.state.entranceForm._id }).then((res) => {
                                                                if (res.result && res.result._id) {
                                                                    this.setState({ entranceForm: null }, () => {
                                                                        this.setState({ entranceForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [], products: [] }, () => {
                                                                            if (this.state.entranceForm && this.state.entranceForm.clientCode) {
                                                                                this.metodaClient(this.state.entranceForm.clientCode)
                                                                            }
                                                                        })
                                                                    })
                                                                    if (res.result.items && res.result.items.length) {
                                                                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    } else {
                                                                        this.remoteOnChange('item.itemNumber', 1)
                                                                    }

                                                                }
                                                            })

                                                        } else {
                                                            let entranceForm = this.state.entranceForm;
                                                            entranceForm.dependentCosts = this.state.dependentCosts;
                                                            this.setState({ entranceForm })
                                                        }
                                                        this.setState({ dependentCostsModal: null })

                                                    }}>Snimi</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }

                            </div>

                            <div className="footer">
                                <div className="footer-left">
                                    {
                                        this.state.entranceForm && this.state.entranceForm._id ?
                                            <button className="blue-button" onClick={() => this.setState({ descriptionModal: true, description: this.state.entranceForm.description ? String(this.state.entranceForm.description) : '' })}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>OPIS</p>
                                            </button>
                                            :
                                            null
                                    }
                                    {
                                        this.state.entranceForm && this.state.entranceForm._id ?
                                            <button className="blue-button" onClick={() => this.setState({ dependentCostsModal: true, dependentCosts: this.state.entranceForm && this.state.entranceForm.dependentCosts ? this.state.entranceForm.dependentCosts : [] })}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>ZAVISNI TROŠKOVI</p>
                                            </button>
                                            :
                                            null
                                    }

                                    {
                                        this.state.entranceForm && this.state.entranceForm._id && this.state.entranceForm.canDeleteDocument ?
                                            <button onClick={() => this.setState({ deleteDocumentModal: this.state.entranceForm })}>
                                                <div className="box box-red">
                                                    <Isvg src={garbageOpionIcon} />
                                                </div>
                                                <p>BRISANJE DOKUMENTA</p>
                                            </button>
                                            :
                                            null
                                    }

                                </div>
                                <div className="footer-right">

                                    {
                                        this.state.entranceForm && this.state.entranceForm._id && this.state.items && this.state.items.length ?
                                            <div className="info-colored">
                                                <div className="col-info">
                                                    <p>Osnovica</p>
                                                    {/* <span>{numberWithCommas(this.state.entranceForm.amount, true, true)} KM</span> */}
                                                    <span>
                                                        {
                                                            this.state.items.length != 0 ?
                                                                numberWithCommas(this.state.items.reduce((sum, current) => {
                                                                    let res;
                                                                    if (current && current.dependentCost) {
                                                                        res = sum + (Number(current.dependentCost.value1) + Number(current.factureValue));
                                                                    } else {
                                                                        res = sum + Number(current.factureValue);
                                                                    }
                                                                    return res
                                                                }, 0), true, true)
                                                                :
                                                                '00,00'
                                                        }
                                                        &nbsp;KM
                                                    </span>
                                                </div>
                                                <div className="col-info">
                                                    <p>PDV</p>
                                                    {/* <span>{numberWithCommas((this.state.entranceForm.amount * 0.17), true, true)} KM</span> */}
                                                    <span>
                                                        {
                                                            this.state.items.length != 0 ?
                                                                numberWithCommas((0.17 * (this.state.items.reduce((sum, current) => {
                                                                    let res;
                                                                    if (current && current.dependentCost) {
                                                                        res = sum + (Number(current.dependentCost.value1) + Number(current.factureValue));
                                                                    } else {
                                                                        res = sum + Number(current.factureValue);
                                                                    }
                                                                    return res
                                                                }, 0))), true, true)
                                                                :
                                                                '00,00'
                                                        }
                                                        &nbsp;KM
                                                    </span>
                                                </div>
                                                <div className="col-info">
                                                    <p>Za plaćanje</p>
                                                    {/* <span>{numberWithCommas((this.state.entranceForm.amount * 1.17), true, true)} KM</span> */}
                                                    <span>
                                                        {
                                                            this.state.items.length != 0 ?
                                                                numberWithCommas((1.17 * (this.state.items.reduce((sum, current) => {
                                                                    let res;
                                                                    if (current && current.dependentCost) {
                                                                        res = sum + (Number(current.dependentCost.value1) + Number(current.factureValue));
                                                                    } else {
                                                                        res = sum + Number(current.factureValue);
                                                                    }
                                                                    return res
                                                                }, 0))), true, true)
                                                                :
                                                                '00,00'
                                                        }
                                                        &nbsp;KM
                                                    </span>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters" style={{ alignItems: 'flex-end', marginTop: -25 }}>

                                            <div className="field-strap-wrap">
                                                <Input type='date'
                                                    value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                    onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                        this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date'
                                                    value={this.state.filterDateTo !== null ? this.state.filterDateTo : params.dateTo}
                                                    onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                        this.updateParams('dateTo', this.state.filterDateTo)
                                                    })} />
                                            </div>
                                            <div className="field-strap-wrap" style={{ width: 200 }}>
                                                {/* <Label>Komitent</Label> */}
                                                <Input style={{ width: 200 }} placeholder="Komitent" type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap" style={{ width: 200 }}>
                                                {/* <Label>Korisnik</Label> */}
                                                <Input style={{ width: 200 }} placeholder="Korisnik" type='text' value={this.state.filterUser != null ? this.state.filterUser : params.user} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} />
                                            </div>

                                        </div>
                                        <div className="clear-filters" onClick={() => {

                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterUser: '',
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'user', value: null }])

                                            })

                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>

                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                maxHeight={'63vh'}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'entraceDateTs', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    // { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'amount', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', numberWithCommas: true, fieldStyle: { textAlign: 'right' } },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { textAlign: 'right' } },


                                ]}
                                items={this.state.entranceItems.map((item, idx) => {
                                    return {
                                        ...item,
                                        entraceDateTs: this.props.getDateStringFromTs(item.entraceDateTs, 'DD.MM.YYYY')
                                        // time: this.props.getDateStringFromTs(item.entraceDateTs, 'HH:mm')
                                    }
                                })}
                                rawItems={this.state.entranceItems}
                                onClick={(item) => {
                                    if (item && item.documentNumber) {
                                        this.setState({ entranceForm: null, buttonActive: 2, disableEdit: false }, () => {
                                            this.setState({ entranceForm: { documentNumber: item.documentNumber } }, () => {
                                                this.remoteOnChange('documentNumber', item.documentNumber)
                                            })
                                        })
                                    }

                                }}

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                        </>
                }



            </div>
        )
    }
}

export default Page(EntrancePage);
