import React, { Component } from 'react';

class RadioGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
       
        return (
            <div>
                {
                    this.props.items.map(item => {
                        return (
                            <form>
                                <div>
                                    <input type='radio' name='medicament'
                                        checked={this.props.value == item.code}
                                        value={item.code}
                                        onChange={(e) => { this.props.onChange(e.target.value) }}
                                        ref={(node) => this.props.setFieldRef(node)}
                                    /> {item.name}
                                </div>
                            </form>
                        )
                    }

                    )
                }
            </div>
        )
    }

}

export default RadioGroup;