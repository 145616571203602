
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import checklistIcon from '../../assets/checklist.svg';
import moment from 'moment';

import { debounce } from "lodash";




class FactureChangePage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            // articlesList: true,
            factureChangeForm: false,
            factureChangeForm2: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            step1: 1,
            step2Items: [],
            items: [

            ],
            series: [],
            seriesDeadline: [],
            nextFreeNumber: 0,
            ko: {},
            items1: [],
            items2: [],
            knjiznaStara: {},
            faktura: {},
            statusAmmount: 'false',
            kopija: [],
            obrisano: '',
            obri: [],
            step3Items: [],
            realizovaniDokumenti: [],
            articleName: '',
            series: [],
            readOnlyField: true,
            articleAmmount: 0,
            seriesSum: 0,


        }
    }

    componentDidMount() {
        this._onLoad();
        this.metoda()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    articleSearchMethod = debounce(async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        // this.setState({ articlesSearch: articleList.articlesSearch })
        if (this.registerGetValue && value === this.registerGetValue('article'))
            this.setState({ articlesSearch: articleList.articlesSearch })
    }, 400)


    metoda = async (value) => {

        this.articleSearchMethod(value)

    }


    // metoda = async (value) => {
    //     let articleList = []
    //     articleList = await this._apiAsync('ariclesSearch', { value: value })
    //     this.setState({ articlesSearch: articleList.articlesSearch })
    // }

    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.registerGetValue('item.serialNumber') ? this.registerGetValue('item.serialNumber') : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            withoutSeries: true,
            serialNumber: value,
        })
        if (resultSeries && resultSeries.items.length > 0) {
            this.setState({ series: resultSeries.items })
        }
    }, 400);

    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }

    render() {
        let params = this._getParams();


        return (
            <div>
                <div className="section-title title-options">
                    <h1>Izmjena fakture</h1>


                </div>
                <InfoModal
                    title={'Obavjestenje!'}
                    info={"Faktura sa upisanim brojem ne postoji!"}
                    isOpen={this.state.infoModal}
                    toggle={() => {
                        this.setState({ infoModal: false })
                    }}
                    buttons={[
                        {
                            text: 'OK',
                            onClick: () => this.setState({ infoModal: false })
                        },

                    ]}
                >
                </InfoModal>

                <InfoModal
                    title={'Obavjestenje!'}
                    info={"Faktura sa upisanim brojem je napravljena u prošlom mjesecu!"}
                    isOpen={this.state.infoModalLastMounth}
                    toggle={() => {
                        this.setState({ infoModalLastMounth: false })
                    }}
                    buttons={[
                        {
                            text: 'OK',
                            onClick: () => this.setState({ infoModalLastMounth: false })
                        },

                    ]}
                >
                </InfoModal>

                <Container fluid>

                    <div className='line-container'>
                        <div className='progress-line'>
                            <div className='progress' style={this.state.step1 == 1 ? { width: '0' } : this.state.step1 == 2 ? { width: '20%' } : this.state.step1 == 3 ? { width: '40%' } : this.state.step1 == 4 ? { width: '50%' } : this.state.step1 == 5 ? { width: '60%' } : this.state.step1 == 6 ? { width: '80%' } : this.state.step1 == 7 ? { width: '100%' } : {}}>

                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 1 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 2 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 3 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 4 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 5 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 6 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                            <div className='status'>
                                <div className={this.state.step1 >= 7 ? 'dot completed' : 'dot'}>

                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.step1 == 1 ?

                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container entrance-custom-col"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['documentNumber']}
                                fieldTracker={async (name, value) => {

                                    if (name = 'documentNumber' && value) {
                                        let result = await this._apiAsync('get-output', { documentNumber: value })

                                        //TREBA UBACITI PROVJERU I ZA GODINU....
                                        if (result && result.items && result.items.length != 0) {


                                            let mjesecSaFakture = new Date(result.items[0].factureDateTs * 1000).getMonth() + 1
                                            // let godinaSaFakture = new Date(result.items[0].factureDateTs * 1000).getFullYear()
                                            let trenutniMjesec = new Date().getMonth() + 1
                                            // let trenutnaGodina = new Date().getFullYear()



                                            if (trenutniMjesec == mjesecSaFakture) {

                                                this.setState({ faktura: JSON.parse(JSON.stringify(result.items[0])) })


                                                if (result && result.items.length != 0) {
                                                    this.setState({ factureChangeForm: result.items[0] })
                                                    this.remoteOnChange('factureDate', result.items[0].factureDate)
                                                    this.remoteOnChange('client', result.items[0].clientName)

                                                    if (result && result.items[0].businessName) {
                                                        this.remoteOnChange('business', result.items[0].businessName)
                                                    } else {

                                                        this.remoteOnChange('business', '')
                                                    }
                                                    this._apiAsync('nextItemNumber', { numberDocument: result.items[0].documentNumber }).then((result) => {
                                                        this.setState({ nextFreeNumber: result.items.firstFreeNumber })
                                                    })

                                                    this._apiAsync('getKO', { documentNumber: result.items[0].documentNumber }).then((res) => {
                                                        this.setState({ ko: res.items })
                                                        if (this.state.ko && this.state.ko._id) {
                                                            this.setState({ knjiznaStara: this.state.ko })
                                                        } else {
                                                            this.setState({ knjiznaStara: {} })

                                                        }
                                                    })

                                                }

                                            }

                                        } else {
                                            this.setState({ infoModal: true })
                                        }

                                    }
                                    if (name = 'documentNumber' && !value) {
                                        this.setState({ factureChangeForm: {} })
                                        this.remoteOnChange('factureDate', '')
                                        this.remoteOnChange('client', '')
                                        this.remoteOnChange('business', '')

                                    }


                                }}

                                onSubmit={(data, notFinished) => {

                                    // this._apiAsync('insert-new', data)
                                }}
                                initialValues={this.state.factureChangeForm}
                                fields={[

                                    {
                                        type: 'col',
                                        width: { lg: 7, sm: 12, xs: 12 },
                                        offset: { lg: 2, sm: 0, xs: 0 },
                                        style: { marginTop: 'calc(50vh - 400px)' },
                                        children: [
                                            {
                                                type: 'block',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'documentNumber',
                                                                textType: 'number',
                                                                next: 'factureDate',
                                                                textType: 'number',
                                                                label: 'Broj fakture',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    required("Broj dokumenta mora biti unešen!"),
                                                                ]
                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'factureDate',

                                                                next: 'status',
                                                                readOnly: true,
                                                                label: 'Datum fakture',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    required("Broj dokumenta mora biti unešen!"),
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'client',
                                                                next: 'business',
                                                                readOnly: true,
                                                                label: 'Komitent',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    required("Broj dokumenta mora biti unešen!"),
                                                                ]
                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'business',
                                                                finishField: true,
                                                                readOnly: true,
                                                                label: 'Poslovna',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    // required("Broj dokumenta mora biti unešen!"),
                                                                ]
                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 3, sm: 3, xs: 3 },
                                                        style: { display: 'flex', justifyContent: 'flex-end' },
                                                        children: [


                                                            {
                                                                type: 'button',
                                                                children: <button className="blue-button next-button" onClick={() => {

                                                                    if (this.state.factureChangeForm && this.state.factureChangeForm.items) {

                                                                        this.setState({ step1: 2 })
                                                                        this.setState({ step1Items: [...this.state.factureChangeForm.items] }, () => {
                                                                            this.state.step1Items.sort((a, b) => { return a.itemNumber - b.itemNumber })
                                                                        })
                                                                        this.setState({ step2Items: this.state.factureChangeForm.items }, () => {
                                                                            this.state.step2Items.map((item) => {
                                                                                item.deleted = false
                                                                            })
                                                                            this.setState({ step2Items: this.state.step2Items }, () => {
                                                                                this.state.step2Items.sort((a, b) => { return a.itemNumber - b.itemNumber })
                                                                            })
                                                                        })
                                                                        this.setState({ step2Obj: this.state.factureChangeForm })
                                                                        this.state.realizovaniDokumenti.push({ documentNumber: this.state.factureChangeForm.documentNumber, documentName: 'Izlaz' })
                                                                        this.setState({ realizovaniDokumenti: this.state.realizovaniDokumenti })

                                                                    } else {
                                                                        this.setState({ infoModalLastMounth: true })
                                                                    }

                                                                }}>
                                                                    <p>DALJE</p>
                                                                </button>,
                                                                className: 'options-button width-100',
                                                            },
                                                        ]

                                                    },
                                                ]
                                            },

                                        ]

                                    },


                                ]}
                            ></FormBuilder>


                            :

                            null

                    }
                    {
                        this.state.step1 == 2 ?

                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container buyer-return"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['article', 'serialNumber', 'deadline', 'ammount', 'price', 'discount']}
                                fieldTracker={async (name, value) => {


                                    if (name == 'article' && value) {
                                        if (value) {

                                            this._apiAsync('get-articles', { filter: value }).then((products) => {

                                                if (products && products.items && products.items.length != 0) {
                                                    this.setState({ products: products && products.items }, () => {
                                                        this._apiAsync('get-price', { article: value })
                                                            .then((res) => {
                                                                if (res && res.price) {
                                                                    this.setState({ articleName: res.price.productName })
                                                                    this.remoteOnChange('price', res.price.sellPrice)
                                                                    this.remoteOnChange('supply', res.price.quantity)
                                                                    this.setState({ articleAmmount: Number(res.price.quantity) })
                                                                }
                                                            })

                                                        this._apiAsync('get-series', { article: value, withoutSeries: true }).then((res) => {
                                                            if (res && res.items) {
                                                                this.setState({ series: res.items }, () => {
                                                                    this.setState({
                                                                        seriesSum: this.state.series.reduce((sum, currentValue) => {
                                                                            return sum + currentValue.ammount
                                                                        }, 0)
                                                                    })
                                                                })
                                                            }

                                                        })

                                                    })
                                                }
                                            })
                                        }
                                    }

                                    if (name == 'article' && !value) {
                                        this.remoteOnChange('serialNumber', '')
                                        this.remoteOnChange('deadline', '')
                                        this.remoteOnChange('supply', '')
                                        this.remoteOnChange('price', '')
                                    }

                                    if (name == 'serialNumber' && value) {
                                        this.remoteOnChange('deadline', this.state.series.filter(item => {
                                            if (value === item.serialNumber) {
                                                return item
                                            }
                                        })[0].expirationDate)
                                    }

                                    if (name == 'ammount' && value) {
                                        if (value) {
                                            let kolicina = parseFloat(value)
                                            let cijena = parseFloat(this.registerGetValue('price'))
                                            let rabat = parseFloat(this.registerGetValue('discount'))
                                            if (isNaN(rabat)) {
                                                rabat = 0
                                            }
                                            let total = kolicina * cijena - rabat
                                            this.remoteOnChange('total', total)

                                        } else {
                                            let kolicina = parseFloat(this.registerGetValue('ammount'))
                                            let cijena = parseFloat(this.registerGetValue('price'))
                                            // let rabat = parseFloat(value)
                                            let total = kolicina * cijena
                                            this.remoteOnChange('item.total', total)
                                        }
                                    }

                                }}


                                onSubmit={(data, notFinished) => {


                                    let niz = this.state.step2Items.map((item) => {
                                        return item.itemNumber
                                    })

                                    //PROJERITI  DA LI itemNumber postoji u nizu
                                    if (niz.includes(data.itemNumber)) {

                                        //uraditi update niza
                                        let updateStep2Items = this.state.step2Items.map((item) => {
                                            if (item.itemNumber == data.itemNumber) {
                                                item = data

                                            }
                                            return item
                                        })
                                        this.setState({ step2Items: updateStep2Items })

                                    } else {

                                        data.articleName = this.state.articleName
                                        //upisati  novi element niza
                                        this.state.step2Items.push(data)
                                        this.setState({ step2Items: this.state.step2Items }, () => { this.setState({ readOnlyField: true }) })

                                        niz = this.state.step2Items.map((item) => {
                                            return item.itemNumber
                                        })
                                        let broj = Math.max(...niz)

                                        this.setState({ nextFreeNumber: broj + 1 })

                                    }

                                    this.remoteOnChange('itemNumber', this.state.nextFreeNumber)


                                }}


                                initialValues={this.state.factureChangeForm2}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                className: "custom-input-width",
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'itemNumber',
                                                        textType: 'number',
                                                        next: 'article',
                                                        label: 'Redni broj.',
                                                        asyncValidation: true,
                                                        readOnly: this.state.readOnlyField,
                                                        validate: [
                                                            required("Broj stavke mora biti unešen!"),
                                                        ]
                                                    },
                                                    {
                                                        type: 'article',
                                                        name: 'article',
                                                        next: 'serialNumber',
                                                        label: 'Artikal',
                                                        textType: 'number',
                                                        metoda: (value) => { this.metoda(value) },
                                                        dataModal: this.state.articlesSearch,
                                                        title: 'Pregled artikala',
                                                        readOnly: this.state.readOnlyField,
                                                        values: [...this.state.products.map(item => {
                                                            let obj = {
                                                                name: item.name,
                                                                value: item.code
                                                            }
                                                            return obj
                                                        })],
                                                        finishField: true,
                                                        asyncValidation: true,
                                                        validate: [
                                                            // (value) => { if (!value || value == '') return "Datum ulaza mora biti unesen!" },
                                                            required("Šifra artikla mora biti unešena!"),
                                                            (value) => {

                                                                if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0) {
                                                                    return "Neispravan unos!"
                                                                }
                                                                if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                    return "Neispravan unos!!"
                                                                }

                                                                this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                            }


                                                        ]
                                                    },
                                                    {
                                                        type: 'series',
                                                        name: 'serialNumber',
                                                        next: 'deadline',
                                                        label: 'Serijski broj',
                                                        metoda: (value) => { this.metodaSeries(value) },
                                                        dataModal: this.state.series,
                                                        title: 'Pregled serija',
                                                        finishField: true,
                                                        readOnly: this.state.readOnlyField,
                                                        asyncValidation: true,
                                                        values: [...this.state.series.map(item => {
                                                            let obj = {
                                                                name: item.serialNumber,
                                                                value: item.serialNumber
                                                            }
                                                            return obj
                                                        })],
                                                        validate: [
                                                            // (value) => { if (!value || value == '') return "Broj računa mora biti unesen!" },
                                                            (value) => {

                                                                if (this.state.series.length != 0 && !value && this.state.articleAmmount == this.state.seriesSum) {
                                                                    return 'Serija mora biti unešena!!'
                                                                }

                                                                if (this.state.series.length == 0 && this.state.numberOfSeries != 0) {
                                                                    return 'Neispravan unos!!'
                                                                }

                                                                if (this.state.numberOfSeries == 0 && value) {
                                                                    return 'Neispravan unos!'
                                                                }

                                                            },

                                                            async () => {
                                                                this.setState({ seriesDeadline: [] })
                                                                if (!(this.registerGetValue('serialNumber') && this.registerGetValue('serialNumber') == 'NEMA SERIJE')) {
                                                                    let series = await this._apiAsync('get-series', { article: this.registerGetValue('article'), deadline: true });
                                                                    if (series && series.items) {
                                                                        this.setState({ seriesDeadline: series.items })
                                                                    } else {
                                                                        this.setState({ seriesDeadline: [] })
                                                                    }
                                                                }

                                                            }

                                                        ]
                                                    },
                                                    {
                                                        type: 'select',
                                                        name: 'deadline',
                                                        next: 'ammount',
                                                        label: 'Rok',
                                                        values: [...this.state.seriesDeadline.map((element) => {
                                                            let obj = {
                                                                name: element.expirationDate,
                                                                value: element.expirationDate
                                                            }
                                                            return obj
                                                        })],
                                                        finishField: true,
                                                        asyncValidation: true,
                                                        readOnly: this.state.readOnlyField,
                                                        validate: [
                                                            // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" },
                                                            // validateDate('Datum nije validan')
                                                        ]
                                                    },
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                className: 'input-group-custom',
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        textType: 'number',
                                                                        name: 'ammount',
                                                                        next: 'price',
                                                                        label: 'Količina',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        readOnly: this.state.readOnlyField,
                                                                        validate: [
                                                                            required("Količina mora biti unešena!"),
                                                                            (value) => {
                                                                                if (Number(value) > Number(this.registerGetValue('supply'))) {
                                                                                    return "Nema dovoljno količine na lageru!"
                                                                                }

                                                                            }

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'supply',
                                                                        next: 'price',
                                                                        label: 'Zaliha',
                                                                        textType: 'number',
                                                                        readOnly: true,
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                className: 'input-group-custom',
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        textType: 'number',
                                                                        name: 'price',
                                                                        next: 'discount',
                                                                        label: 'Cijena',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        readOnly: this.state.readOnlyField,
                                                                        validate: [
                                                                            (value) => { if (!value || value == '') return "Valuta mora biti unesen!" },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'fondPrice',
                                                                        next: 'discount',
                                                                        label: 'Fond cijena',
                                                                        readOnly: true,
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'decimal',
                                                        name: 'discount',
                                                        next: 'submitForm',
                                                        label: 'Rabat',
                                                        readOnly: this.state.readOnlyField,
                                                        finishField: true,
                                                        asyncValidation: true,
                                                        validate: [
                                                            // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" },
                                                            // validateDate('Datum nije validan')
                                                        ]
                                                    },
                                                    {
                                                        type: 'decimal',
                                                        name: 'total',
                                                        finishField: true,
                                                        label: 'Ukupno',
                                                        finishField: true,
                                                        asyncValidation: true,
                                                        readOnly: true,
                                                        validate: [
                                                            // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" },
                                                            // validateDate('Datum nije validan')
                                                        ]
                                                    },
                                                ]

                                            },
                                        ]
                                    },
                                ]}
                            ></FormBuilder>



                            :

                            null
                    }
                </Container>

                {
                    this.state.step1 == 2 ?

                        <div className="v-scroll mt-15">
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.totalItems}
                                showNumeration={false}
                                hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false },

                                ]}

                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: async (item) => {
                                                this.setState({ klinknutoNa: item.itemNumber })
                                                this.setState({ factureChangeForm2: null }, () => {
                                                    this.setState({ factureChangeForm2: item }, () => {

                                                        if (this.state.factureChangeForm2 && this.state.factureChangeForm2.article) {

                                                            this._apiAsync('get-articles', { filter: this.state.factureChangeForm2.article }).then((products) => {

                                                                if (products && products.items && products.items.length != 0) {
                                                                    this.setState({ products: products && products.items }, () => {
                                                                        this._apiAsync('get-price', { article: this.state.factureChangeForm2.article })
                                                                            .then((res) => {
                                                                                if (res && res.price) {
                                                                                    this.setState({ articleName: res.price.productName })
                                                                                    this.remoteOnChange('price', res.price.sellPrice)
                                                                                    this.remoteOnChange('supply', res.price.quantity)
                                                                                    this.setState({ articleAmmount: Number(res.price.quantity) })
                                                                                }
                                                                            })

                                                                        this._apiAsync('get-series', { article: this.state.factureChangeForm2.article, withoutSeries: true }).then((res) => {
                                                                            if (res && res.items) {
                                                                                this.setState({ series: res.items }, () => {
                                                                                    this.setState({
                                                                                        seriesSum: this.state.series.reduce((sum, currentValue) => {
                                                                                            return sum + currentValue.ammount
                                                                                        }, 0)
                                                                                    })
                                                                                })
                                                                            }

                                                                        })

                                                                    })
                                                                }
                                                            })

                                                            this.metoda(this.state.factureChangeForm2.article)
                                                        }

                                                        this.setState({ readOnlyField: false })
                                                    })
                                                })
                                            },
                                            condition: (item) => {
                                                if (!item.deleted) {
                                                    return true
                                                } else {
                                                    return false
                                                }
                                            }

                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => {
                                                this.setState({ klinknutoNa: item.itemNumber })

                                                this.setState({ deleteModal: item })

                                            },
                                            condition: (item) => {
                                                if (!item.deleted) {
                                                    return true
                                                } else {
                                                    return false
                                                }
                                            }
                                        },
                                    ]
                                }

                                items={this.state.step2Items.map((item, idx) => {

                                    return {
                                        ...item,
                                        backgroundClassname: item.deleted ? 'red-row' : ''
                                    }
                                }).sort((a, b) => { return a.itemNumber - b.itemNumber })}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    let updateArray = this.state.step2Items.map((item, idx) => {
                                        if (item.itemNumber == this.state.deleteModal.itemNumber) {

                                            item.deleted = true
                                        } else {
                                            if (item.deleted) {
                                                item.deleted = true

                                            } else {
                                                item.deleted = false
                                            }

                                        }
                                        return item
                                    })

                                    this.setState({ step2Items: updateArray })
                                    this.setState({ deleteModal: null })
                                }} >
                                Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                            </DeleteModal>
                        </div>

                        :

                        null

                }
                {
                    this.state.step1 == 2 ?

                        <div>
                            <div className="footer">
                                <div className="footer-left">

                                </div>
                                <div className="footer-right">
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>
                                                {
                                                    this.state.step2Items.length != 0 ?
                                                        this.props.roundToTwoDecimals(this.state.step2Items.reduce((sum, current) => {
                                                            // let res = 0
                                                            if (!current.deleted) {
                                                                sum = sum + current.total
                                                            }

                                                            return sum
                                                        }, 0), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>
                                                {
                                                    this.state.step2Items.length != 0 ?
                                                        this.props.roundToTwoDecimals((0.17 * (this.state.step2Items.reduce((sum, current) => {
                                                            if (!current.deleted) {
                                                                sum = sum + current.total
                                                            }
                                                            return sum
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>
                                                {
                                                    this.state.step2Items.length != 0 ?
                                                        this.props.roundToTwoDecimals((1.17 * (this.state.step2Items.reduce((sum, current) => {
                                                            if (!current.deleted) {
                                                                sum = sum + current.total
                                                            }

                                                            return sum
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <button className="next-button next-button-right next-button-arrow" onClick={() => {

                                this.remoteOnChange('article', '')
                                this.remoteOnChange('serialNumber', '')
                                this.remoteOnChange('deadline', '')
                                this.remoteOnChange('ammount', '')
                                this.remoteOnChange('supplay', '')
                                this.remoteOnChange('price', '')
                                this.remoteOnChange('fondPrice', '')
                                this.remoteOnChange('discount', '')
                                this.remoteOnChange('total', '')
                                this.remoteOnChange('itemNumber', this.state.nextFreeNumber)
                                if (this.state.readOnlyField) {
                                    this.setState({ readOnlyField: false })
                                }
                            }}>Dodati artikal</button>

                            <button className="next-button next-button-right" onClick={() => {
                                this.state.step2Obj.items = this.state.step2Items


                                //UPDATE-OVATI FAKTURU SA NOVIM ILI OBRISANIM STAVKAMA
                                //========================================================================================================

                                //napraviti  novu KO ukoliko postoje uslovi za to
                                //PROVJERA DA LI POSTOJI ko ILI NE POSTOJI
                                if (this.state.ko && this.state.ko.nemaKo) {

                                    //napraviti ko ukoliko su ispunjeni uslovi za to.
                                    //PROSLIJEDITI OBJEKAT KOJI JE MODIFIKOVAN U STEPU 2
                                    this._apiAsync('new-ko', { ...this.state.step2Obj, date: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY') })
                                        .then((result) => {
                                            if (result.items.napravljenaKO) {
                                                //napraviti niz koji ce se proslijeditit listBuilderu
                                                if (result.items.proknjizeno) {
                                                    this.setState({ items1: result.items.items })
                                                } else {
                                                    this.setState({ items2: result.items.items })
                                                }
                                                this.state.realizovaniDokumenti.push({ documentNumber: result.items.koNumber, documentName: 'KO' })
                                                this.setState({ realizovaniDokumenti: this.state.realizovaniDokumenti })

                                            }
                                        })
                                }


                                this.setState({ step2Obj: this.state.step2Obj })
                                //provjera da li je doslo do promjene stavki u fakturi, ukoliko jeste pravi se KT ili se mijenja KO
                                if (!(JSON.stringify(this.state.faktura.items) == JSON.stringify(this.state.step2Obj.items))) {

                                    //update-ovati izlaznu fakturu
                                    this._apiAsync('update-output', this.state.step2Obj)
                                        .then((result) => {

                                            if (this.state.knjiznaStara.proknjizeno) {

                                                let sum1 = 0
                                                for (let i = 0; i < this.state.faktura.items.length; i++) {

                                                    sum1 = sum1 + this.state.faktura.items[i].total
                                                }

                                                let sum2 = 0
                                                for (let i = 0; i < this.state.step2Obj.items.length; i++) {

                                                    sum2 = sum2 + this.state.step2Obj.items[i].total

                                                }

                                                let sumaKo = 0
                                                for (let i = 0; i < this.state.ko.items.length; i++) {

                                                    sumaKo = sumaKo + this.state.ko.items[i].ammountKo

                                                }

                                                this._apiAsync('new-kt', {
                                                    ...this.state.step2Obj, vrijednostKo: sumaKo, date: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
                                                }).then((result) => {
                                                    this.setState({ items1: result.items.lisatKO })

                                                    this.state.realizovaniDokumenti.push({ documentNumber: result.items.ktNumber, documentName: 'KT' })
                                                    this.setState({ realizovaniDokumenti: this.state.realizovaniDokumenti })

                                                })


                                            } else {

                                                this._apiAsync('update-ko', { ...this.state.step2Obj, koNumber: this.state.knjiznaStara.koNumber })
                                                    .then((result) => {
                                                        if (result.items && result.items.items) {
                                                            this.setState({ items2: result.items.items })
                                                        } else {
                                                            this.setState({ items2: [] })
                                                        }

                                                    })
                                            }


                                        })



                                } else {

                                    if (this.state.knjiznaStara.proknjizeno) {
                                        if (this.state.knjiznaStara && this.state.knjiznaStara.items) {
                                            this.setState({ items1: this.state.knjiznaStara.items })
                                        } else {
                                            this.setState({ items1: [] })
                                        }


                                    } else {
                                        if (this.state.knjiznaStara && this.state.knjiznaStara.items) {
                                            this.setState({ items2: this.state.knjiznaStara.items })
                                        } else {
                                            this.setState({ items2: [] })
                                        }


                                    }

                                }


                                //==========================================================================================================

                                this.state.step3Items = JSON.parse(JSON.stringify(this.state.step2Items))
                                this.setState({ step3Items: this.state.step3Items }, () => {
                                    this.state.step2Items.map((item) => {
                                        item.deleted = false
                                    })
                                    this.setState({ step2Items: this.state.step2Items })
                                })


                                this.setState({ step1: 3 })
                            }}>Dalje</button>
                        </div>

                        :


                        null
                }



                {
                    this.state.step1 == 3 ?
                        <Row>

                            <Col lg="12" style={{ marginBottom: 15 }} className="list-builder-left">
                                <h6>Staro stanje</h6>
                                <div>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false },
                                        ]}

                                        items={

                                            this.state.step1Items
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>
                            </Col>


                            <Col lg="12" className="list-builder-right">
                                <h6>Novo stanje</h6>
                                <div>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false },
                                        ]}


                                        coloredCells={[
                                            // { name: 'itemNumber', backgroundColor: '#FBF55F' },
                                            // { name: 'article', backgroundColor: '#FBF55F' },
                                            // { name: 'articleName', backgroundColor: '#FBF55F' },
                                            // { name: 'serialNumber', backgroundColor: '#FBF55F' },
                                            // { name: 'deadline', backgroundColor: '#FBF55F' },
                                            { name: 'ammount', backgroundColor: '#A3DBA3', condition: 'changedAmmountUp' },
                                            { name: 'ammount', backgroundColor: '#FD5C70', color: '#fff', condition: 'changedAmmountDown' },
                                            // { name: 'price', backgroundColor: '#FBF55F' },
                                            // { name: 'discount', backgroundColor: '#FBF55F' },
                                            // { name: 'total', backgroundColor: '#FBF55F' },
                                        ]}


                                        items={this.state.step3Items.map((item, idx) => {

                                            return {
                                                ...item,
                                                changedAmmountUp: this.state.step1Items.findIndex((el) => { return el.article == item.article }) == -1 || item.deleted ? '' : (item.ammount > this.state.step1Items[idx].ammount) ? true : false,
                                                changedAmmountDown: this.state.step1Items.findIndex((el) => { return el.article == item.article }) == -1 || item.deleted ? '' : (item.ammount < this.state.step1Items[idx].ammount) ? true : false,
                                                // backgroundClassname: this.state.step2Items.indexOf(this.state.step1Items[idx]) == -1 ? 'red-row' : ''
                                                backgroundClassname: this.state.step1Items.findIndex((el) => { return el.article == item.article }) == -1 ? 'green-row' : item.deleted ? 'red-row' : ''

                                            }
                                        })}
                                        rawItems={this.state.step2Items}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>

                            </Col>

                            <div className="next-button-container">
                                <button className="next-button" onClick={() => {
                                    this.setState({
                                        step1: this.state.step1 + 1
                                    })


                                    //napraviti novu listu sa uracunatim modifikacijama (dodatim, obrisanim i modifikovanim stavkama)

                                    let p = this.state.step3Items.filter((item) => {
                                        return !item.deleted
                                    })

                                    p.map((element, idx) => {
                                        element.itemNumber = idx + 1
                                    })

                                    this.state.step2Obj.items = p
                                    this.setState({ step2Obj: this.state.step2Obj }, () => {
                                        //ovaj niz upisati u output (reservation)
                                        this._apiAsync('update-output', this.state.step2Obj)
                                    })


                                }}>Dalje</button>
                            </div>

                        </Row>
                        :
                        null
                }






                {
                    this.state.step1 == 4 ?
                        <Row className="two-form-block">

                            <Col lg="6" className="list-builder-left">
                                <h6>Neproknjiženi KO</h6>
                                <div className="unbook-ko-list">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'number', label: 'KO BR:'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'kind', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'subkind', label: 'PODVRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'beforeAmount', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'newAmount', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammountKo', label: 'RAZLIKA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        ]}
                                        items={this.state.items2.map((item) => {
                                            return { ...item, number: this.state.knjiznaStara.koNumber }
                                        })}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>
                            </Col>


                            <Col lg="6" className="list-builder-right">

                                <h6>Proknjiženi KO</h6>
                                <div className="unbook-ko-list">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'number', label: 'KO BR:'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'kind', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'subkind', label: 'PODVRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'beforeAmount', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'newAmount', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammountKo', label: 'RAZLIKA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        ]}
                                        items={this.state.items1.map((item) => {
                                            return { ...item, number: this.state.knjiznaStara.koNumber }
                                        })}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>

                            </Col>

                            <div className="next-button-container">
                                <button className="next-button" onClick={() => {
                                    this.setState({
                                        step1: this.state.step1 + 1
                                    })
                                }}>Dalje</button>
                            </div>

                        </Row>
                        :
                        null
                }

                {
                    this.state.step1 == 5 ?
                        <Col lg="7" style={{ margin: 'auto' }}>
                            <Row className="one-form-block natural-dicount">
                                <Col lg="8">
                                    <h6>Naturalni rabat</h6>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'actionName', label: 'NAZIV AKCIJE'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'article', label: 'ARTIKAL'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammount', label: 'KOL.'.translate(this.props.lang), multilang: false, allowSort: true },
                                        ]}

                                        items={this.state.items}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </Col>
                                <Col lg="12">
                                    <div className="next-button-container two-buttons">
                                        <button className="next-button" onClick={() => {
                                            this.setState({
                                                step1: this.state.step1 + 1
                                            })
                                        }}>KNJIŽNO TEREĆENJE</button>
                                        <button className="next-button" onClick={() => {
                                            this.setState({
                                                step1: this.state.step1 + 1
                                            })
                                        }}>POVRAT NATURALNOG RABATA</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        :
                        null
                }

                {
                    this.state.step1 == 6 ?
                        <Col lg="7" style={{ margin: 'auto' }}>
                            <Row className="one-form-block collection-order">
                                <Col lg="8">
                                    <h6>Nalog za preuzimanje robe</h6>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'documentNumber', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'actionName', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'article', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammount', label: 'SERIJA.'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA.'.translate(this.props.lang), multilang: false, allowSort: true },
                                        ]}

                                        items={this.state.items}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </Col>
                                <Col lg="12">
                                    <div className="next-button-container two-buttons">
                                        <button className="next-button" onClick={() => {
                                            this.setState({
                                                step1: this.state.step1 + 1
                                            })
                                        }}>Dalje</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        :
                        null
                }

                {
                    this.state.step1 == 7 ?
                        <Col lg="4" style={{ margin: 'auto' }}>
                            <Row className="one-form-block collection-order">
                                <div className="approval-document">
                                    <div className="approval-document-header">
                                        <Isvg src={checklistIcon} />
                                        <h6>Izmjena fakture br. {this.state.factureChangeForm.documentNumber} je uspiješno izvršena.</h6>
                                    </div>
                                    <hr style={{ marginTop: 35 }} />
                                    <div className="approval-document-body">
                                        <h6>Realizovani dokumenti</h6>
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            hideFilters={true}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            hidePagination={true}
                                            fields={[
                                                { type: 'text', name: 'documentNumber', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'documentName', label: 'NAZIV DOKUMENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            ]}
                                            // items={this.state.items}
                                            items={this.state.realizovaniDokumenti}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    </div>
                                </div>
                            </Row>
                        </Col>

                        :
                        null
                }

            </div >
        )
    }
}

export default Page(FactureChangePage);
