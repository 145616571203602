
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';

import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

import env from "react-dotenv";
import PrintHelper from '../../components/printHelper';

class Place extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            items: [],
            countries: [],
            pomocna: false,
            pomocna1: true,
            pomocna2: false,
            pomocna5: false,
            error: '',
            printTable: false,
            exportTable: false,

        }
    }

    componentDidMount() {
        this._onLoad();
        // this._api('api5', {})
        (async () => {
            let broj = await this._apiAsync('checkNextDocumentNumber', {})
            if (broj && broj.firstFreeNumber) {
                this.remoteOnChange('code', broj.firstFreeNumber)
            }
        })()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }

    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }
    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }


    render() {
        let params = this._getParams();


        return (
            <div>
                <div className="section-title title-options">
                    <h1>Mjesto</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={() => this.setState({ printTable: true }, this.print)}>

                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => {/*this.export()*/ this.setState({exportTable: true})}}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        {
                            this.state.printHtml ?
                                <PrintHelper html={this.state.printHtml} />
                                :
                                null
                        }

                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                if (data.result && data.result.error) {
                                    this.setState({
                                        error: data.result.error
                                    })
                                } else {
                                    this.setState({ groupsForm: {} }, () => {
                                        this._onLoad(); this.setState({ deleteModal: null });
                                        (async () => {
                                            let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                            if (broj && broj.firstFreeNumber) {
                                                this.remoteOnChange('code', broj.firstFreeNumber)
                                            }
                                        })()
                                    })
                                }

                            });
                        }} >
                        Obrisati mjesto <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(getValue) => { this.getValue = getValue }}
                                pomocnaVarijabla={this.state.pomocna}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}

                                fieldsToTrack={['code', 'country']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'code' && value) {
                                        let res = await this._apiAsync('validate1', { value: value });

                                        if (res.status == 210) {
                                            this.setState({ form: null })
                                            this.setState({ pomocna: false })
                                            this.setState({ pomocna1: true })
                                            this.setState({ pomocna2: false })
                                            this.remoteOnChange('name', '')
                                            this.remoteOnChange('country', '')
                                            this.remoteOnChange('entity', '')
                                            this.remoteOnChange('canton', '')

                                        }
                                        if (res.status == 200) {
                                            console.log('Procitano mjesto......', res.result)
                                            this.setState({ form: null }, () => {
                                                this.setState({ form: res.result })
                                            })


                                            if (res.result && res.result.entity) {
                                                this.setState({ pomocna: true })
                                                if (res.result.entity == 'FBiH') {
                                                    this.setState({ pomocna1: false })
                                                    this.setState({ pomocna2: false })
                                                } else {
                                                    this.setState({ pomocna1: true })
                                                    this.setState({ pomocna2: false })
                                                }
                                            }

                                            if (res.result && !res.result.entity) {
                                                this.setState({ pomocna: false })
                                                this.setState({ pomocna1: true })
                                                this.setState({ pomocna2: false })
                                            }


                                            // this.remoteOnChange('name', res.result.name)
                                            this.remoteOnChange('country', res.result.country)
                                            // this.remoteOnChange('entity', res.result.entity)
                                            if (res.result && res.result.canton) {
                                                this.remoteOnChange('canton', res.result.canton)
                                            }

                                        }
                                    }

                                    if (name == 'code' && !value) {
                                        this.setState({ pomocna: false })
                                        this.remoteOnChange('name', '')
                                        this.remoteOnChange('country', '')
                                        this.remoteOnChange('entity', '')
                                        this.remoteOnChange('canton', '')

                                    }


                                }}



                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, async () => {
                                                this._onLoad()
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    } else {

                                        //u slucaju da je prvo odabrata BiH pa tek onda neka druga zemlja
                                        if (!this.state.pomocna) {
                                            delete data.entity
                                            delete data.canton
                                        }

                                        if (data._id) {
                                            delete data._id
                                        }

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ form: {} }, async () => {
                                                this._onLoad()
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj mjesto'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        next: 'name',
                                                                        label: 'Šifra',
                                                                        // textType: 'number',
                                                                        integer : true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra mora biti unešena!'),
                                                                            async (value) => {

                                                                            },
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 9, sm: 9, xs: 9 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'country',
                                                                        label: 'Naziv',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv mora biti unešen!'),
                                                                            async (value) => {

                                                                                let placeId = ''
                                                                                if (this.state.form && this.state.form._id) {
                                                                                    placeId = this.state.form._id
                                                                                }

                                                                                let provjera = await this._apiAsync('checkName', { value: value.trim(), id: placeId })
                                                                                console.log('provjera prov...', provjera)

                                                                                if (provjera && provjera.result) return 'Mjesto pod tim nazivom već postoji!'
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'country',
                                                                        next: 'entity',
                                                                        label: 'Država',
                                                                        showName: true,
                                                                        asyncValidation: true,
                                                                        values: [{ name: 'Select country', value: -1 }, ...this.state.countries.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        validate: [
                                                                            required('Država mora biti unešena!'),
                                                                            (value) => {
                                                                                console.log('u validaciji komponente')
                                                                                //let countryID = this.getValue('country')
                                                                                let readNameCountry = ''
                                                                                let niz = ['BIH', 'BiH', 'Bosna i Hercegovina']
                                                                                this.state.countries.forEach((element) => {
                                                                                    if (element._id == value) {
                                                                                        readNameCountry = element.name
                                                                                    }
                                                                                })
                                                                                if (niz.indexOf(readNameCountry) != -1) {
                                                                                    this.setState({ pomocna: true })
                                                                                } else {
                                                                                    this.setState({ pomocna: false })
                                                                                    this.remoteOnChange('entity', '')
                                                                                    this.remoteOnChange('canton', '')
                                                                                    this.setState({ pomocna1: true })
                                                                                    this.setState({ pomocna2: false })
                                                                                }

                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'entity',
                                                                        label: 'Entitet',
                                                                        next: 'canton',
                                                                        asyncValidation: true,
                                                                        values: this.state.pomocna ? [{ name: 'Izaberite entitet', value: -1 }, { name: 'RS', value: 'RS' }, { name: 'FBiH', value: 'FBiH' }, { name: 'DB', value: 'DB' }] : [{}],
                                                                        readOnly: !this.state.pomocna,
                                                                        validate: [
                                                                            async (value) => {

                                                                                if (value == 'RS') {

                                                                                    this.setState({ pomocna1: true })
                                                                                    this.setState({ pomocna2: false })
                                                                                }
                                                                                if (value == 'DB') {

                                                                                    this.setState({ pomocna2: true })
                                                                                    this.setState({ pomocna1: false })
                                                                                }

                                                                                if (value == 'FBiH') {
                                                                                    this.setState({ pomocna1: false })
                                                                                    this.setState({ pomocna2: false })
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'canton',
                                                                        label: 'Kanton',
                                                                        asyncValidation: true,
                                                                        readOnly: this.state.pomocna1 && !this.state.pomocna2,
                                                                        //values: this.state.pomocna  ? [{ name: 'Izaberi kanton', value: -1 }, { name: 'Republika Srpska', value: 'RS' }, { name: 'Brčko Distrikt', value: 'DB' }, { name: 'Hercegovacko-Neretvanski', value: 'HN' }, { name: 'Tuzlanski', value: 'TK' }, { name: 'Sarajevski', value: 'SK' }] : [{}],
                                                                        values: this.state.pomocna && this.state.pomocna1 ? [{ name: 'Republika Srpska', value: 'RS' }] : this.state.pomocna && this.state.pomocna2 ? [{ name: 'Brčko Distrikt' }] : this.state.pomocna ? [{ name: 'Izaberi kanton', value: -1 }, { name: 'Hercegovacko-Neretvanski', value: 'HN' }, { name: 'Tuzlanski', value: 'TK' }, { name: 'Sarajevski', value: 'SK' }] : [{}],
                                                                        validate: [
                                                                            (value) => {


                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista mjesta'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'countryName', label: 'DRŽAVA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'entity', label: 'ENTITET'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => {

                                                    if (item && item.entity) {
                                                        this.setState({ pomocna: true })
                                                        if (item.entity == 'FBiH') {
                                                            this.setState({ pomocna1: false })
                                                            this.setState({ pomocna2: false })
                                                        } else {
                                                            this.setState({ pomocna1: true })
                                                            this.setState({ pomocna2: false })
                                                        }
                                                    }
                                                    if (item && !item.entity) {
                                                        this.setState({ pomocna: false })
                                                        this.setState({ pomocna1: true })
                                                        this.setState({ pomocna2: false })
                                                    }

                                                    let land = this.state.countries.filter((country) => {
                                                        if (country._id == item.country) {
                                                            return country
                                                        }
                                                    })

                                                    this.setState({ form: { ...item, country: land[0]._id } }, () => {

                                                    })

                                                })

                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                    printTable={this.state.printTable}
                                    exportTable={this.state.exportTable}
                                    genericPrint={this.props.genericPrint}
                                    genericExport={this.props.genericExport}
                                    changeState={this.changeState}
                                    genericData={{ url: 'data/places', title: 'Mjesto' }}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    this.state.error && this.state.error != '' ?
                        <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: '', deleteModal: null })} error={this.state.error}>

                        </ErrorModal>
                        :
                        null
                }
            </div>
        )
    }
}

export default Page(Place);
