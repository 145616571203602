import React, { Component } from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';


class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <FormGroup className='switch'>
                <div className={this.props.value ? 'toogle-switch toogle-switch-active' : 'toogle-switch'} >
                    <div onClick={() => this.props.onChange(!this.props.value)}>
                        <div></div>
                    </div>
                    <span>{this.props.label}</span>
                </div>

                {this.props.error && this.props.touched ?

                    <FormFeedback className='invalid-tooltip-switch' invalid tooltip >{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        );
    }
}

export default Check;