import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-banners': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/banners/get', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        banner: result,
                    }
            })
        }
    },
    'save-banners': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/banners/update', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },




}