
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form, FormFeedback, Button
} from 'reactstrap';

import exportIcon from '../../assets/export.svg';
import env from "react-dotenv";
import axios from 'axios';
import Isvg from 'react-inlinesvg';

import image from '../../assets/picture.svg';


import { Player, Controls } from "@lottiefiles/react-lottie-player";


function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {


            let formData = new FormData();
            formData.append('file', input.files[0]);

            this.setState({ _loading: '0%' })

            axios.post(env.API_URL + this.props.endpoint, formData, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                onUploadProgress: (progressEvent) => {
                    this.setState({ _loading: Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%' })

                }
            }).then((response) => {
                console.log(response)
                if (response.status != 200) {
                    this.setState({ error: response.data, _loading: null })
                } else {
                    this.props.onChange(response.data);
                    this.setState({
                        _loading: null
                    })
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status != 200)
                        this.setState({ error: error.response.data, _loading: null })

                }
            })



        }
    }

    render() {
        return (
            // <FormGroup className={`file-upload-wrap ${this.props.className}`}>
            //     <input type="file" onChange={this.selectFile} />
            //     <Button color="primary"><div className="icon"><Isvg src={exportIcon} /></div> {this.props.value ? this.props.value.split('/').pop() : <span>{this.state._loading ? this.state._loading : this.props.label }</span> }</Button>
            //     {this.state.error  ?
            //         <p className="error">{this.state.error}</p>
            //         :

            //         null
            //     }


            // </FormGroup>
            <FormGroup className="image-picker-wrap">
                {
                    this.props.label ?
                        <Label>{this.props.label}</Label>
                        :
                        null
                }

                <div className="image-picker" style={{ position: 'relative' }}>
                    <input type="file" accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif" title={"No file chosen".translate(this.props.lang)} onChange={this.selectFile} key={this.props.value} />
                    {this.props.value ?
                        <img crossOrigin="anonymous" onLoad={() => {
                            if (this.state.showCropNextCycle) {

                                let img = this.imgRef;
                                var canvas = document.createElement("canvas");
                                canvas.width = img.naturalWidth;
                                canvas.height = img.naturalHeight;
                                var ctx = canvas.getContext("2d");
                                ctx.drawImage(img, 0, 0);
                                var dataURL = canvas.toDataURL("image/png");
                                // console.log(dataURL)
                                this.setState({ imageToCrop: dataURL, _loading: null })

                            }
                        }} ref={(node) => this.imgRef = node} src={this.props.value.indexOf('data:image') !== -1 ? this.props.value : env.API_URL + this.props.value} />
                        :
                        null
                    }


                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }

                    <div className="bottom-content">
                        <Isvg src={image} />
                        <p> <span>{'Upload a file'.translate(this.props.lang && this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>


                </div>
                {/* <div>{this.state.error}</div> */}


                {/* 
                {
                    this.state._loading ?
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>

                        :
                        null
                } */}
            </FormGroup>


        );
    }
}

export default Image;