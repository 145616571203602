
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import Checkbox from '../../form-builder/fields/checkbox';
import garbageOpionIcon from '../../assets/garbage-option.svg';

import DateField from '../../form-builder/fields/date';
import env from "react-dotenv";



import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            controled: true,
            totalByGroups: false
        }
    }
    get = () => {
        if (this.state.filterDateFrom && this.state.filterDateTo) {
            let dateFrom = this.props.getTsFromDateString(this.state.filterDateFrom)
            let dateTo = this.props.getTsFromDateString(this.state.filterDateTo)

            if (!dateFrom) {
                this.setState({ error: 'Početni datum je neispravan' })
            } else if (!dateTo) {
                this.setState({ error: 'Krajnji datum je neispravan' })
            } else if (dateTo > dateFrom) {
                let totalByGroups = this.state.totalByGroups ? 'true' : null
                this.updateMultipleParams([
                    { name: 'client', value: this.state.filterClientCode },
                    { name: 'dateFrom', value: this.state.filterDateFrom },
                    { name: 'dateTo', value: this.state.filterDateTo },
                    { name: 'groupFrom', value: this.state.filterGroupFrom },
                    { name: 'groupTo', value: this.state.filterGroupTo },
                    { name: 'subgroupFrom', value: this.state.filterSubgroupFrom },
                    { name: 'subgroupTo', value: this.state.filterSubgroupTo },
                    { name: 'articleFrom', value: this.state.filterArticleFrom },
                    { name: 'articleTo', value: this.state.filterArticleTo },
                    { name: 'change', value: this.state.filterTypeOfChange },
                    { name: 'totalByGroups', value: totalByGroups },
                    { name: 'lastUpdate', value: Math.floor(new Date().getTime() / 1000) },
                    { name: 'entries', value: 50 },

                ])
            } else {
                this.setState({ error: 'Početni datum mora biti manji od krajnjeg datuma' })
            }

        } else {
            this.setState({ error: 'Datum je obavezan' })
        }








    }

    print = () => {

        if (this.state.filterDateFrom && this.state.filterDateTo) {
            let dateFrom = this.props.getTsFromDateString(this.state.filterDateFrom)
            let dateTo = this.props.getTsFromDateString(this.state.filterDateTo)

            if (!dateFrom) {
                this.setState({ error: 'Početni datum je neispravan' })
            } else if (!dateTo) {
                this.setState({ error: 'Krajnji datum je neispravan' })
            } else if (dateTo > dateFrom) {
                let totalByGroups = this.state.totalByGroups ? 'true' : null
                this.setState({
                    loading: true
                }, () => {
                    this._apiAsync('print', {
                        'client': this.state.filterClientCode,
                        'dateFrom': this.state.filterDateFrom,
                        'dateTo': this.state.filterDateTo,
                        'groupFrom': this.state.filterGroupFrom,
                        'groupTo': this.state.filterGroupTo,
                        'subgroupFrom': this.state.filterSubgroupFrom,
                        'subgroupTo': this.state.filterSubgroupTo,
                        'articleFrom': this.state.filterArticleFrom,
                        'articleTo': this.state.filterArticleTo,
                        'change': this.state.filterTypeOfChange,
                        'totalByGroups': this.state.totalByGroups,
                        'lastUpdate': Math.floor(new Date().getTime() / 1000),
                        'entries': 50,

                    }).then((response) => {
                        if (typeof window != 'undefined') {
                            window.open(env.API_URL + response.link)
                            this.setState({ loading: false })
                        }

                    })

                })
            } else {
                this.setState({ error: 'Početni datum mora biti manji od krajnjeg datuma' })
            }

        } else {
            this.setState({ error: 'Datum je obavezan' })
        }



    }

    componentDidMount() {
        // this._onLoad();

        // this.get()

        this.checkParams()



    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (prevState.key != this.state.key) {
            localStorage.setItem('searchKey', this.state.key)
        }


    }
    checkParams = (get = false) => {
        let obj = {}
        let params = this._getParams()
        if (!this.state.filterDateFrom && params.dateFrom) {
            obj.filterDateFrom = params.dateFrom;
        }
        if (!this.state.filterDateTo && params.dateTo) {
            obj.filterDateTo = params.dateTo;
        }

        if (!this.state.filterClientCode && params.client) {
            obj.filterClientCode = params.client;
        }
        if (!this.state.filterGroupFrom && params.groupFrom) {
            obj.filterGroupFrom = params.groupFrom;
        }
        if (!this.state.filterGroupTo && params.groupTo) {
            obj.filterGroupTo = params.groupTo;
        }
        if (!this.state.filterSubgroupFrom && params.subgroupFrom) {
            obj.filterSubgroupFrom = params.subgroupFrom;
        }
        if (!this.state.filterSubgroupTo && params.subgroupTo) {
            obj.filterSubgroupTo = params.subgroupTo;
        }
        if (!this.state.filterArticleFrom && params.articleFrom) {
            obj.filterArticleFrom = params.articleFrom;
        }
        if (!this.state.filterArticleTo && params.articleTo) {
            obj.filterArticleTo = params.articleTo;
        }
        if (!this.state.filterTypeOfChange && params.change) {
            obj.filterTypeOfChange = params.change;
        }
        this.setState({ ...obj }, () => {
            if (get)
                this.get()
            if (this.state.filterClientCode) {
                this._apiAsync('get-client', { code: this.state.filterClientCode }).then((res) => {
                    if (res && res.name) {
                        this.setState({ clientName: res.name })
                    } else {
                        this.setState({ clientName: '' })
                    }
                })
            } else {
                this.setState({ clientName: '' })
            }
        })

    }
    clearFilters = () => {
        this.setState({
            filterClientCode: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterGroupFrom: '',
            filterGroupTo: '',
            filterSubgroupFrom: '',
            filterSubgroupTo: '',
            filterArticleFrom: '',
            filterArticleTo: '',
            filterTypeOfChange: '',
            clientName: '',


        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'client', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'groupFrom', value: null },
                    { name: 'groupTo', value: null },
                    { name: 'subgroupFrom', value: null },
                    { name: 'subgroupTo', value: null },
                    { name: 'articleFrom', value: null },
                    { name: 'articleTo', value: null },
                    { name: 'change', value: null },
                    { name: 'lastUpdate', value: null },

                ])

        })
    }

    render() {
        let params = this._getParams();
        let typeOfChange = this.state.filterTypeOfChange ? Number(this.state.filterTypeOfChange) : null;
        if (typeOfChange === -1) {
            typeOfChange = null;
        }
        return (
            <div>
                <ErrorModal
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                    error={this.state.error}
                />

                <div className="section-title title-options">
                    <h1>Promet po grupama</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button onClick={this.print}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Šifra komitenta</Label>
                                            <div className='merged-fields'>
                                                <Input style={{ width: 130 }} type='text' value={this.state.filterClientCode} onChange={(e) => this.setState({ filterClientCode: e.target.value, filteredClient: null }, () => {
                                                    if (this.state.filterClientCode) {
                                                        this._apiAsync('get-client', { code: this.state.filterClientCode }).then((res) => {
                                                            if (res && res.name) {
                                                                this.setState({ clientName: res.name })
                                                            } else {
                                                                this.setState({ clientName: '' })
                                                            }
                                                        })
                                                    } else {
                                                        this.setState({ clientName: '' })
                                                    }

                                                    //this.updateParams('client', this.state.filterClientCode)
                                                })} onKeyDown={(e) => {
                                                    if (e.keyCode == 13) {
                                                        const element = document.getElementById('dateFrom');
                                                        if (element) {
                                                            element.focus();
                                                        }
                                                    }
                                                }} />
                                                <Input style={{ width: 230 }} type='text' value={this.state.clientName} disabled />
                                            </div>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                id={'dateFrom'}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode == 13) {
                                                        const element = document.getElementById('dateTo');
                                                        if (element) {
                                                            element.focus();
                                                        }
                                                    }
                                                }}
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                    //this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })}
                                                value={this.state.filterDateFrom}
                                                label={'Datum od'}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                id={'dateTo'}
                                                onKeyDown={(e) => {
                                                    if (e.keyCode == 13) {
                                                        const element = document.getElementById('grupaOd');
                                                        if (element) {
                                                            element.focus();
                                                        }
                                                    }
                                                }}
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                    //this.updateParams('dateTo', this.state.filterDateTo)
                                                })}
                                                value={this.state.filterDateTo}
                                                label={'Datum do'}
                                            ></DateField>
                                        </div>
                                    </div>
                                    <div>
                                        <button className={this.state.totalByGroups ? "blue-button" : "blue-button-outline"} style={{ width: 200 }}
                                            onClick={() => {
                                                this.setState({ totalByGroups: true },
                                                    () => {
                                                        this.get()

                                                    })
                                            }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>PROMET PO GRUPAMA</p>
                                        </button>
                                    </div>

                                </div>
                                <div className='turnover-groups-list-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters turnover-groups-list" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>



                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Grupa od</Label>
                                            <Input id={'grupaOd'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('grupaDo');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterGroupFrom} onChange={(e) => this.setState({ filterGroupFrom: e.target.value }, () => {
                                                //this.updateParams('groupFrom', this.state.filterGroupFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Grupa do</Label>
                                            <Input id={'grupaDo'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('podgrupaOd');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterGroupTo} onChange={(e) => this.setState({ filterGroupTo: e.target.value }, () => {
                                                //this.updateParams('groupTo', this.state.filterGroupTo)
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Podgrupa od</Label>
                                            <Input id={'podgrupaOd'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('podgrupaDo');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterSubgroupFrom} onChange={(e) => this.setState({ filterSubgroupFrom: e.target.value }, () => {
                                                //this.updateParams('subgroupFrom', this.state.filterSubgroupFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Podgrupa do</Label>
                                            <Input id={'podgrupaDo'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('artikalOd');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterSubgroupTo} onChange={(e) => this.setState({ filterSubgroupTo: e.target.value }, () => {
                                                //this.updateParams('subgroupTo', this.state.filterSubgroupTo)
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Artikal od</Label>
                                            <Input id={'artikalOd'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('artikalDo');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterArticleFrom} onChange={(e) => this.setState({ filterArticleFrom: e.target.value }, () => {
                                                //this.updateParams('articleFrom', this.state.filterArticleFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 130 }}>
                                            <Label>Artikal do</Label>
                                            <Input id={'artikalDo'} onKeyDown={(e) => {
                                                if (e.keyCode == 13) {
                                                    const element = document.getElementById('vrstaPromjene');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 130 }} type='text' value={this.state.filterArticleTo} onChange={(e) => this.setState({ filterArticleTo: e.target.value }, () => {
                                                //this.updateParams('articleTo', this.state.filterArticleTo)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>

                                            <Label>Vrsta promjene</Label>
                                            <Input id={'vrstaPromjene'} type='select' style={{ width: 170 }} value={this.state.filterTypeOfChange} onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                                //this.updateParams('change', this.state.filterTypeOfChange != -1 ? this.state.filterTypeOfChange : null)
                                            })}>
                                                <option value={-1}></option>
                                                <option value={1}>Ulaz</option>
                                                <option value={2}>Izlaz</option>
                                                <option value={3}>Nivelacija</option>
                                                <option value={4}>Povrat kupca</option>
                                                <option value={5}>Povrat dobavljača</option>
                                                <option value={6}>Inventura</option>
                                                <option value={7}>Naturalni rabat</option>
                                                <option value={8}>Otpis</option>
                                                <option value={9}>Manjak</option>
                                            </Input>
                                        </div>
                                    </div>
                                    <div>
                                        <button className={!this.state.totalByGroups ? "blue-button" : "blue-button-outline"} style={{ width: 200 }}
                                            onClick={() => {
                                                this.setState({ totalByGroups: false },
                                                    () => {
                                                        this.get()

                                                    })
                                            }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>TOTALI SA UL/IZ</p>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        {
                            this.state.totalByGroups ?
                                <>
                                    <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            noWrap={true}
                                            maxHeight={'46vh'}
                                            hideFilters={true}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'groupCode', label: 'GRUPA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'subgroupCode', label: 'PODGRUPA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'article', label: 'ŠIFRA ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 30 },
                                                { type: 'text', name: 'clientCode', label: 'ŠIFRA KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'documentNumber', label: 'BR.DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'type', label: 'VRSTA.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                                                { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },


                                            ]}
                                            items={this.state.items.map((item, idx) => {
                                                return {
                                                    ...item,
                                                    documentNumber:
                                                        item.type == 'Ulaz' || item.type == 'Izlaz' || item.type == 'Nivelacija' ?
                                                            <div className='list-builder-item-href' onClick={() => {
                                                                if (typeof window != 'undefined') {
                                                                    if (item && item.documentNumber && item.type == 'Ulaz') {
                                                                        window.open(`/entrance?redirect=${item.documentNumber}`, "_blank");
                                                                    } else if (item && item.documentNumber && item.type == 'Izlaz') {
                                                                        window.open(`/output?redirect=${item.documentNumber}`, "_blank");
                                                                    } else if (item && item.documentNumber && item.type == 'Nivelacija') {
                                                                        window.open(`/nivelation?redirect=${item.documentNumber}`, "_blank");
                                                                    }


                                                                }
                                                            }}>
                                                                <p> {item.documentNumber}</p>
                                                            </div>
                                                            :
                                                            item.documentNumber

                                                }
                                            })}

                                            rawItems={this.state.items}
                                            activeSubitem={this.state.activeSubitem}
                                            subItems={true}

                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    </Col>
                                    <Col lg={{ size: 4, offset: 8 }}>
                                        <div className='blue-box-info-text-wrap'>
                                            <div className='blue-box-info-text'>
                                                <h6>Ukupno ulaz</h6>
                                                <h4>
                                                    {this.state.sumIn ? numberWithCommas(this.state.sumIn) : '0'}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                            <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                                <h6>Ukupno izlaz</h6>
                                                <h4>
                                                    {this.state.sumOut ? numberWithCommas(this.state.sumOut) : '0'}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                            <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                                <h6>Razlika</h6>
                                                <h4>
                                                    {numberWithCommas(Math.abs(Math.round(((this.state.sumIn ? this.state.sumIn : 0) - (this.state.sumOut ? this.state.sumOut : 0)) * 100) / 100))}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                        </div>


                                    </Col>
                                </>
                                :
                                <>
                                    <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            maxHeight={'46vh'}
                                            noWrap={true}
                                            hideFilters={true}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            fields={[
                                                // dictionary[i].amountNivelation = amountNivelation;
                                                // dictionary[i].amountBuyerReturns = amountBuyerReturns;
                                                // dictionary[i].amountSupplierReturns = amountSupplierReturns;

                                                { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                                { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'name', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 30 },
                                                { type: 'text', name: 'groupCode', label: 'GRUPA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'subgroupCode', label: 'PODGRUPA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'amountIn', label: 'KOLIČINA ULAZ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 1 ? false : true },
                                                { type: 'text', name: 'amountOut', label: 'KOLIČINA IZLAZ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 2 ? false : true },
                                                { type: 'text', name: 'amountNivelation', label: 'KOL. NIV.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 3 ? false : true },
                                                { type: 'text', name: 'amountBuyerReturns', label: 'KOL. P. KUP.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 4 ? false : true },
                                                { type: 'text', name: 'amountSupplierReturns', label: 'KOL. P. DOB.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 5 ? false : true },
                                                { type: 'text', name: 'ammountInventory', label: 'KOL. INV.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 6 ? false : true },
                                                { type: 'text', name: 'amountNaturalniRabat', label: 'KOL. NAT. R.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 7 ? false : true },
                                                { type: 'text', name: 'amountWriteOff', label: 'KOL. OTPIS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 8 ? false : true },
                                                { type: 'text', name: 'amountDeficit', label: 'KOL. MANJAK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 9 ? false : true },

                                                { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset', hideColumn: !typeOfChange || typeOfChange === 1 || typeOfChange === 2 ? false : true },
                                                { type: 'text', name: 'priceIn', label: 'IZNOS ULAZ'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                                                { type: 'text', name: 'priceOut', label: 'IZNOS IZLAZ'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },


                                            ]}
                                            items={this.state.items.map((item, idx) => {
                                                return {
                                                    ...item,
                                                    ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                                    // purchasePrice: Math.floor(((item.priceIn + item.priceOut) / (item.amountIn + item.amountOut)) * 1000) / 1000
                                                }
                                            })}
                                            rawItems={this.state.items}
                                            actions={
                                                [
                                                    {
                                                        component: <Isvg src={arrowDown} className="delete-icon" />,
                                                        onClick: (item) => {
                                                            if ((this.state.dropdownActive && this.state.dropdownActive._id != item._id) || !this.state.dropdownActive) {
                                                                this.setState({ dropdownActive: item })
                                                            } else {
                                                                this.setState({ dropdownActive: null })
                                                            }

                                                        },
                                                        dropdownShowCondition: (item) => {
                                                            if (this.state.dropdownActive && item._id == this.state.dropdownActive._id)
                                                                return true
                                                            else
                                                                return false
                                                        },
                                                        condition: (item) => {
                                                            if (item.packer || item.collector)
                                                                return true
                                                            else
                                                                return false
                                                        },
                                                        dropdownShow: <div className="drop-down-wrap">
                                                            <table>
                                                                <tr>
                                                                    <th>SAKUPLJAČ</th>
                                                                    <th>PAKER</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>{this.state.dropdownActive && this.state.dropdownActive.collectorUsername}</td>
                                                                    <td>{this.state.dropdownActive && this.state.dropdownActive.packerUsername}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    },
                                                    // {
                                                    //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                    //     onClick: (item) => this.setState({ deleteModal: item })
                                                    // },
                                                ]
                                            }
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    </Col>
                                    <Col lg={{ size: 4, offset: 8 }}>
                                        <div className='blue-box-info-text-wrap'>
                                            <div className='blue-box-info-text'>
                                                <h6>Ukupno ulaz</h6>
                                                <h4>
                                                    {this.state.sumIn ? numberWithCommas(this.state.sumIn) : '0'}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                            <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                                <h6>Ukupno izlaz</h6>
                                                <h4>
                                                    {this.state.sumOut ? numberWithCommas(this.state.sumOut) : '0'}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                            <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                                <h6>Razlika</h6>
                                                <h4>
                                                    {numberWithCommas(Math.abs(Math.round(((this.state.sumIn ? this.state.sumIn : 0) - (this.state.sumOut ? this.state.sumOut : 0)) * 100) / 100))}
                                                    &nbsp;KM
                                                </h4>
                                            </div>
                                        </div>


                                    </Col>

                                </>
                        }



                    </Row>

                    {
                        this.state.preview ?
                            <Modal isOpen={this.state.preview} toggle={() => this.setState({ preview: false })} size='lg' className="modal-wrap-cold-chain-preview">
                                <ModalHeader>
                                    <div className='title'>Pregled</div>
                                    {/* <Button className="print-button"><div className="svg-box"><Isvg src={printIcon} /></div>Štampaj</Button> */}
                                </ModalHeader>
                                <ModalBody className="modal-body-list-builder-wrap">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        // total={this.state.total}
                                        hidePagination={true}
                                        showNumeration={false}
                                        fields={[

                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'type', label: 'Tip'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'documentNumber', label: 'BR.DOK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },

                                        ]}
                                        items={this.state.preview && this.state.preview.listOfItems.map((item, idx) => {
                                            return {
                                                ...item,
                                                // ordinalNumber: idx + 1
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.preview && this.state.preview.listOfItems ? this.state.preview.listOfItems : []}

                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>
                                <ModalFooter></ModalFooter>

                            </Modal>
                            :
                            null
                    }

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);
