import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {


    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/subgroups/validate/' + data.value + '/' + data.id , {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    // =============================================================================================================================

    'checkDocumentNumber' : {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/bookNotice/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'checkNextDocumentNumber': {
        onLoad: true,
        action: (data) => {
            console.log('data sa data.....', data);
            return fetch(env.API_URL + '/data/bookNotice/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },

    'insert-new': {
        onLoad: false,
        action: (data) => {
            console.log('Podaci za upis...', data)
            return fetch(env.API_URL + '/data/bookNotice/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log('resultat posle upisa...', result)
                if (status >= 200 && status < 300)
                    return {
                        result: result
                    }
            })
        }
    },

    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-facture': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/bookNotice/factureNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                console.log('VRACENA FAKTURA API, API, API....',result)
                if (status >= 200 && status < 300){
                    return {
                        items: result.items,
                    }
                }else{
                    return{
                        items:false
                    }
                }

            })
        }
    },

    'get-ko': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/bookNotice', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        koNumber: query && query.koNumber,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        class: query && query.class,
                        subClass: query && query.subClass,
                        basic: query && query.basic,
                        proknjizeno: query && query.proknjizeno == 1  ? false : true
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },



    'get-factureClient': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/bookNotice/factureNumberClient', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300){
                    return {
                        items: result,
                    }
                }else{
                    return{
                        items:false
                    }
                }

            })
        }
    },

    'updateKo': {
        onLoad: false,
        action: (data) => {
            console.log('Prosledjen broj KO...', data)
            return fetch(env.API_URL + '/data/bookNotice/updateKo', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log('resultat citanja KO...', result)
                if (status >= 200 && status < 300)
                    return {
                        result: result
                    }
            })
        }
    },

    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },


}
