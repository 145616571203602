
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import garbageIcon from '../../assets/garbage.svg';
import editIcon from '../../assets/edit-icon.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, date } from '../../form-builder/validation';

class DiscountPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            conditions: [],
            manufacturers: [],
            infoModal: false,
            result: {},
            textZaModal : '',
            printTable: false,
            exportTable: false,
        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();
        this._api('api5', {})
        this._api('api6', {})
        //    this._api('api2', {data: {name: 'demo collection'}}, (data) => {console.log(data)})
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();


        return (
            <div>
                <div className="section-title title-options">
                    <h1>Rabati</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={ async ()=>{this.setState({printTable: true})}}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={ async ()=>{this.setState({exportTable: true})}}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati rabat <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <InfoModal
                        isOpen={this.state.infoModal}
                        title="UPOZORENJE!!!"
                        info={this.state.textZaModal}
                        buttons={[
                            {
                                text: "OK",
                                onClick: () => { this.setState({ infoModal: false }) }
                            }
                        ]}
                    >

                    </InfoModal>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, (response) => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                            if (response.status == 250) {
                                                this.setState({ infoModal: true })
                                                this.setState({ textZaModal : response.result.response})
                                            }
                                        });
                                    } else {

                                        this._api('api2', { data: data }, (response) => {
                                            this.setState({ clientForm: {} }, () => { this._onLoad() })
                                            if (response.status == 250) {
                                                this.setState({ infoModal: true })
                                                this.setState({ textZaModal : response.response})
                                            }
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj rabat'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'condition',
                                                                        next: 'manufacturer',
                                                                        label: 'Izaberi uslove',
                                                                        values: [{ name: 'Izaberi uslove', value: -1 }, ...this.state.conditions.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Uslov mora biti unešen!'),
                                                                            async (value) => {
                                                                                if (value) {
                                                                                    let red = this.state.conditions.map(item => {
                                                                                        return item._id
                                                                                    })
                                                                                    if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                }

                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 4, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'manufacturer',
                                                                        next: 'dateFrom',
                                                                        label: 'Proizvođač',
                                                                        asyncValidation: true,
                                                                        values: [{ name: 'Izaberi proizvođača', value: -1 }, ...this.state.manufacturers.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        validate: [
                                                                            required('Proizvođač mora biti unešen!'),
                                                                            async (value) => {
                                                                                if (value) {
                                                                                    let red = this.state.manufacturers.map(item => {
                                                                                        return item._id
                                                                                    })
                                                                                    if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                }

                                                                            },
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'dateFrom',
                                                                        label: 'Datum od',
                                                                        next: 'dateTo',
                                                                        asyncValidation: true,
                                                                        values: [],
                                                                        validate: [
                                                                            required('Datum mora biti unešen!'),
                                                                            date
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'dateTo',
                                                                        label: 'Datum do',
                                                                        next: 'percent',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required('Procenat mora biti unešen!'),
                                                                            date
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'percent',
                                                                        label: 'Procenat',
                                                                        next: 'submitForm',
                                                                        integer: true,
                                                                        finishField: true,
                                                                        textType: 'number',
                                                                        asyncValidation: true,
                                                                        values: [],
                                                                        validate: [
                                                                            required('Procenat mora biti unešen!'),
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                header={'Lista rabata'}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'conditionName', label: 'USLOV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'name', label: 'NAZIV USLOVA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'dateFrom', label: 'DATUM OD'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'dateTo', label: 'DATUM DO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'percent', label: 'PROCENAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}
                                items={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ form: null }, () => {
                                                this.setState({ form: item })
                                            })
                                            //onClick: (item) => console.log(item)
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={ {url: 'data/discounts', title:'Rabati'}}
                            ></ListBuilder>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(DiscountPage);
