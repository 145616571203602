import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import PrintHelper from '../../components/printHelper';
import SelectWithSearch from '../../form-builder/fields/selectWithSearch';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
// import ReactTags from 'react-tag-input-custom-search'
import env from "react-dotenv";
import SearchModal from '../../components/fondSifreModal';
import Checkbox from '../../form-builder/fields/checkbox';
import ErrorModal from '../../components/errorModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import moment from 'moment';


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articleState: false,
            articleList: true,
            articleForm: false,
            buttonActive: 2,
            total: 20,
            fondPasswordModal: false,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            suggestions: [],
            fundList: {},
            kutKom: [{ name: 'KUTIJA', value: 'kutija' }, { name: 'KOMAD', value: 'komad' }],
            firstFreeNumber: 0,
            almbih: false,
            pharmacy: [{
                number: 1,
                name: 'ICM',
                serialNumber: 'Sve serije',
                expirationDate: 'Svi rokovi',
                items: []
            }],
            products: [],
            error: '',
            aktivniCheck: false,
            selectedArticle: null

        }
    }

    componentDidMount() {
        this._onLoad();
        (async () => {
            let broj = await this._apiAsync('checkNextDocumentNumber', {})
            if (broj && broj.firstFreeNumber) {
                this.setState({ firstFreeNumber: broj.firstFreeNumber })

                // this.remoteOnChange('code', broj.firstFreeNumber)
            }
        })()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (prevState.buttonActive != this.state.buttonActive) {
            this.setState({ printHtml: null })
        }
    }
    // print = async () => {
    //     let params = this._getParams();
    //     let printHtml = await this._apiAsync('print', params)
    //     if (printHtml && printHtml.printHtml) {
    //         this.setState({ printHtml: printHtml.printHtml })
    //     }
    // }
    export = async () => {
        this.setState({
            loadingExport: true
        })
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
                this.setState({
                    loadingExport: false
                })
            }
        }
    }

    deleteFondCode = (data) => {
        this._api('api4', { data: data }, () => {
            this.setState({ articleForm: undefined }, async () => {
                this._onLoad();
                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                if (broj && broj.firstFreeNumber) {
                    // console.log('citanje slobodnog broja.......', broj.firstFreeNumber)
                    this.remoteOnChange('code', broj.firstFreeNumber)
                }

            })
        });
    }

    render() {
        console.log('this.state.almbih', this.state.almbih, this.state.almbih && this.state.almbih.articleType);
        let params = this._getParams();
        return (
            <div>


                <div className="section-title title-options">
                    <h1>Artikli</h1>

                    {
                        this.state.buttonActive == 2 ?
                            <div className="title-options-group">
                                {/* <button onClick={() => this.setState({ printHtml: null }, this.print)}>

                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button> */}
                                <button onClick={() => this.export()}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                                {
                                    this.state.printHtml ?
                                        <PrintHelper html={this.state.printHtml} />
                                        :
                                        null
                                }

                            </div>
                            :
                            <div className="design-helper" style={{ height: 52, opacity: 0 }}></div>
                    }

                </div>

                {
                    this.state.buttonActive == 1 || this.state.buttonActive == 2 || this.state.buttonActive == 3 ?
                        <div className="table-tabs tabs-two-buttons">
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ articleState: true, articleList: false, articleForm: false, buttonActive: 1 })}>
                                    Stanje artikala
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ articleList: true, articleState: false, articleForm: false, buttonActive: 2 })}>
                                    Lista artikala
                                </button>
                                <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={async () => {
                                    this.setState({ articleForm: true, articleList: false, articleState: false, buttonActive: 3 })
                                    let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                    if (broj && broj.firstFreeNumber) {
                                        // this.setState({ firstFreeNumber: broj.firstFreeNumber })

                                        this.remoteOnChange('code', broj.firstFreeNumber)
                                    }

                                }

                                }>
                                    Dodaj artikal
                                </button>
                            </div>

                        </div>
                        :
                        null
                }


                {
                    this.state.buttonActive == 1 ?

                        <div>
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container add-form-container-transparent"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['articleName']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'articleName' && value) {
                                        this.setState({ selectedArticle: value })
                                    }
                                    // if (name == 'articleName' && value) {
                                    //     const products = await this._apiAsync('article-state', { filter: value, page: 0, entries: 50 })
                                    //     if (products && products.productsItems) {
                                    //         this.setState({ products: products && products.productsItems }, () => {
                                    //         })
                                    //     }

                                    // }
                                }}
                                onSubmit={async (data) => {


                                    let article = await this._apiAsync('article-read-code', { articleCode: this.state.selectedArticle })
                                    // console.log('artikallllllll.....', article);
                                    if (article && article.result && article.result.result && article.result.result.product && article.result.result.productAdditionallyData) {
                                        this.setState({ form: null }, () => {
                                            // console.log(article);
                                            this.setState({
                                                filterdArticle: article.result.result.product,
                                                form: {
                                                    ...article.result.result.product,
                                                    ...article.result.result.productAdditionallyData,
                                                    barcode: article.result.result.product.barcode[0],

                                                },

                                            }, () => {
                                                if (this.state.form) {
                                                    let arr = [...this.state.pharmacy]
                                                    arr[0] = {
                                                        ...this.state.pharmacy[0],
                                                        _id: this.state.filterdArticle._id,
                                                        items: [
                                                            ...article.result.result.productAdditionallyData.series.map((serija, idx) =>
                                                            ({
                                                                ...serija,
                                                                buyPrice: article.result.result.productAdditionallyData && article.result.result.productAdditionallyData.buyPrice,
                                                                sellPrice: article.result.result.productAdditionallyData && article.result.result.productAdditionallyData.sellPrice
                                                            })
                                                            ).filter((item) => { return item.ammount > 0 })
                                                        ],
                                                        ammount: article.result.result.productAdditionallyData && article.result.result.productAdditionallyData.quantity,
                                                        buyPrice: article.result.result.productAdditionallyData && article.result.result.productAdditionallyData.buyPrice,
                                                        sellPrice: article.result.result.productAdditionallyData && article.result.result.productAdditionallyData.sellPrice
                                                    }
                                                    this.setState({
                                                        pharmacy: arr
                                                    })
                                                }
                                            })
                                        })

                                        // this.remoteOnChange('articleName', '')
                                        // this.setState({
                                        //     products: []
                                        // })
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'barcode',
                                                                        label: 'Barkod',
                                                                        readOnly: true,
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        label: 'Šifra',
                                                                        readOnly: true,

                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'article',
                                                                metoda: async (value) => {
                                                                    const products = await this._apiAsync('ariclesSearch', { value: value })
                                                                    if (products && products.products && this.registerGetValue && value === this.registerGetValue('articleName')) {
                                                                        this.setState({ products: products && products.products }, () => {
                                                                        })
                                                                    }

                                                                },
                                                                dataModal: this.state.products,
                                                                label: 'Artikal',
                                                                finishField: true,
                                                                readOnly: this.state.disableEdit,
                                                                asyncValidation: true,

                                                                name: 'articleName',
                                                                filterOnlyByName: false,
                                                                next: 'submitForm',
                                                                values: [...this.state.products.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item.code
                                                                    }
                                                                    return obj
                                                                })],

                                                                label: 'Šifra / Barkod / Naziv artikla',
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        name: 'showArticle',
                                                                        value: 'test',
                                                                        children: <div onClick={() => {
                                                                            this.submit()
                                                                        }}>Prikaži artikal</div>,
                                                                        className: 'show-article-button',
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 1, xs: 1 },
                                                                children: [
                                                                    {

                                                                    },
                                                                ]
                                                            },
                                                            this.state.form ?
                                                                {
                                                                    type: 'col',
                                                                    className: 'flex-end',
                                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                                    children: [
                                                                        {
                                                                            type: 'button',
                                                                            name: 'entry',
                                                                            value: 'test',
                                                                            children: <div onClick={() => {
                                                                                if (typeof window != 'undefined' && this.state.filterdArticle && this.state.filterdArticle.code) {
                                                                                    window.open(`/total-articles?entries=10&dateFrom=${'01.01.' + (new Date().getFullYear() - 1)}&dateTo=${moment(new Date()).format('DD.MM.YYYY')}&articleFrom=${this.state.filterdArticle.code}`, "_blank");
                                                                                }
                                                                            }}>Kartica artikal</div>,
                                                                            className: 'show-article-button button-dark',
                                                                        },
                                                                    ]
                                                                } : null,
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>

                            <div className="square-info-container">
                                <div className="square-info">
                                    <span className="square-info-title">Info</span>
                                    <div className="square-info-content">
                                        <h4>{this.state.form && this.state.form.name}</h4>
                                        <h6>Bar kod: <span>{this.state.form && this.state.form.barcode}</span></h6>
                                        <h6>Proizvođač: <span> {this.state.form && this.state.form.manufacturerName}</span></h6>
                                        <h6>Vrsta:<span> {this.state.form && this.state.form.articleType}</span></h6>
                                        <h6>Lokacija:<span> {this.state.form && this.state.form.position}</span></h6>
                                    </div>
                                </div>
                                <div className="square-info">
                                    <span className="square-info-title">FZO RS</span>
                                    <div className="square-info-content">
                                        <h6>Šifra: <span> {this.state.form && this.state.form.fundsLink && this.state.form.fundsLink['atc-sifra']}</span></h6>
                                        <h6>Cijena: <span> {this.state.articleForm && this.state.articleForm.fundsLink ? this.state.articleForm.fundsLink['cijena'] + ' KM' : ''} </span></h6>
                                        <h6>Lista:<span> {this.state.form && this.state.form.fundsLink && this.state.form.fundsLink['lista-id']}</span></h6>
                                        <h6>INN:<span> {this.state.form && this.state.form.fundsLink && this.state.form.fundsLink['naziv-atc5-nivo']}</span></h6>
                                        {/* <h6>Grupa:<span> {this.state.form && this.state.form.groupName}</span></h6> */}
                                    </div>
                                </div>
                                <div className="square-info">
                                    <span className="square-info-title">ICM</span>
                                    <div className="square-info-content-vertical">
                                        <div className="vertical-row">
                                            <div className="vertical-row-box">
                                                <h6>GRUPA</h6>
                                                <span>{this.state.form && this.state.form.groupName}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>PODGRUPA</h6>
                                                <span>{this.state.form && this.state.form.subgroupName}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>JEDINICA MJERE</h6>
                                                <span>{this.state.form && this.state.form.unit}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>KSS</h6>
                                                <span>{this.state.form && this.state.form.kss}</span>
                                            </div>
                                        </div>
                                        <div className="vertical-row">
                                            <div className="vertical-row-box">
                                                <h6>STANJE</h6>
                                                <span>{this.state.form && this.state.form.quantity}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>ONLINE REZERVACIJA</h6>
                                                <span>{this.state.form && this.state.form.onlineDemand}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>NA REZERVACIJI</h6>
                                                <span>{this.state.form && this.state.form.quantityReserved}</span>
                                            </div>
                                            <div className="vertical-row-box">
                                                <h6>UKUPNO</h6>
                                                <span>{this.state.form ? this.state.form.quantityReserved + this.state.form.onlineDemand + this.state.form.quantity : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div style={{ marginTop: 25 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={1}
                                    showNumeration={false}
                                    childrenField={'items'}
                                    fields={[
                                        { type: 'text', name: 'number', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'expirationDate', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'STANJE'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'sellPrice', label: 'VP CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'buyPrice', label: 'MP CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    ]}
                                    items={this.state.pharmacy}

                                    actions={
                                        []
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive === 3 ?



                        <div>
                            {
                                this.state.fondPasswordModal ?

                                    <SearchModal
                                        isOpen={this.state.fondPasswordModal}
                                        title={'Fond Šifra'}
                                        label={'pretraga'}
                                        toggle={() => {
                                            this.setState({
                                                fondPasswordModal: false,
                                                fundList: {},
                                                fundFilter: null,
                                                selectWithSearchItems: []
                                            })
                                        }}
                                        items={this.state.selectWithSearchItems}
                                        metoda={async (val) => {
                                            const fundList = await this._apiAsync('fund-list', { filter: val })
                                            if (fundList && fundList.fundList) {
                                                this.setState({ fundList: fundList && fundList.fundList, selectWithSearchItems: fundList && fundList.selectWithSearchItems }, () => {
                                                    // console.log(this.state.selectWithSearchItems);
                                                })
                                            }
                                        }}
                                        handler={(item) => {
                                            // this.setState({ valueText: item.name }, () => {
                                            //     this.props.onChange(item.code)
                                            //     this.setState({ isOpen: false }, () => {
                                            //         document.getElementById(this.state.inputID).focus();
                                            //         this.setState({ isOpen: false })
                                            //     })
                                            // })
                                            this.setState({
                                                fondPasswordModal: false,
                                            }, () => {
                                                let articleForm = this.state.articleForm
                                                articleForm.fundsLink = {}
                                                // articleForm.fundsLink['61927a1764c3364ef07a45ed'] = item
                                                articleForm.fundsLink = item

                                                this.setState({

                                                    fundList: {},
                                                    fundFilter: null,
                                                    articleForm,
                                                    selectWithSearchItems: []
                                                })
                                            })

                                        }}
                                    ></SearchModal>

                                    :
                                    null
                            }

                            < div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}

                                    fieldsToTrack={['code', 'inn', 'articleType', 'unit']}
                                    fieldTracker={async (name, value) => {

                                        if (name == 'code' && !value) {

                                            // this.remoteOnChange('code', this.state.firstFreeNumber)
                                            this.setState({
                                                articleForm: null
                                            }, () => {
                                                this.setState({
                                                    articleForm: {}
                                                })
                                            })

                                            this.remoteOnChange('name', '')
                                            this.remoteOnChange('group', '')
                                            this.remoteOnChange('subgroup', '')
                                            this.remoteOnChange('manufacturer', '')
                                            this.remoteOnChange('barcode', '')
                                            this.remoteOnChange('excode2', '')
                                            this.remoteOnChange('sarajevo', '')
                                            this.remoteOnChange('unit', '')
                                            this.remoteOnChange('articleType', '')
                                            this.remoteOnChange('numberInBox', '')
                                        }

                                        if (name == 'code' && value) {
                                            // console.log(value);
                                            let article = await this._apiAsync('article-read', { articleCode: value })
                                            console.log('PROCITANO SA SERVERA, POSLATO SA SERVERA.....', article)

                                            if (article && article.result && article.result.result && article.result.result.product) {


                                                this.remoteOnChange('name', article.result.result.product.name)
                                                this.remoteOnChange('group', article.result.result.product.group)
                                                this.remoteOnChange('subgroup', article.result.result.product.subgroup)
                                                this.remoteOnChange('manufacturer', article.result.result.product.manufacturer)
                                                this.remoteOnChange('barcode', article.result.result.product.barcode)
                                                this.remoteOnChange('excode2', article.result.result.product.excode2)
                                                this.remoteOnChange('sarajevo', article.result.result.product.sarajevo)
                                                this.remoteOnChange('unit', article.result.result.product.unit)
                                                // this.remoteOnChange('articleType', article.result.result.product && article.result.result.product.articleTypeObj && article.result.result.product.articleTypeObj._id ? article.result.result.product.articleTypeObj._id : '' )
                                                this.remoteOnChange('articleType', article.result.result.product.articleTypeId)
                                                this.remoteOnChange('numberInBox', article.result.result.product.numberInBox)
                                                this.remoteOnChange('fond', article.result.result.product.fond)
                                                this.remoteOnChange('active', article.result.result.product.active)

                                                this.remoteOnChange('atc', article.result.result.product.atc)
                                                this.remoteOnChange('inn', article.result.result.product.inn)
                                                this.remoteOnChange('shape', article.result.result.product.shape)
                                                this.remoteOnChange('dose', article.result.result.product.dose)
                                                this.remoteOnChange('packaging', article.result.result.product.packaging)
                                                this.remoteOnChange('storage-mode', article.result.result.product['storage-mode'])
                                                this.remoteOnChange('jidl', article.result.result.product.jidl)


                                                if (this.registerGetValue('unit') == 'kutija') {
                                                    this.setState({ pomocna: true })
                                                } else {
                                                    this.setState({ pomocna: false })
                                                }

                                                this.setState({ articleForm: null }, () => {
                                                    this.setState({ articleForm: article.result.result.product })
                                                })
                                            } else {

                                                this.remoteOnChange('name', '')
                                                this.remoteOnChange('group', '')
                                                this.remoteOnChange('subgroup', '')
                                                this.remoteOnChange('manufacturer', '')
                                                this.remoteOnChange('barcode', '')
                                                this.remoteOnChange('excode2', '')
                                                this.remoteOnChange('sarajevo', '')
                                                this.remoteOnChange('unit', '')
                                                this.remoteOnChange('articleType', '')
                                                this.remoteOnChange('numberInBox', '')
                                                this.remoteOnChange('fond', '')

                                                this.setState({ articleForm: null }, () => {
                                                    this.setState({ articleForm: {} })
                                                })
                                            }



                                        }

                                        if (name == 'articleType' && value) {
                                            // console.log('value', value);
                                            // console.log('articleType', this.state.articleTypes);

                                            // this.setState({
                                            //     almbih: null
                                            // }, () => {
                                            //     let portionArticleType = this.state.articleTypes
                                            //     let idList = portionArticleType.map((item) => {
                                            //         return item._id
                                            //     })
                                            //     this.setState({ almbih: idList.includes(value) })

                                            // })
                                            let portionArticleType = this.state.articleTypes;
                                            let selectedArticleType = portionArticleType.find(item => item._id.toString() === value.toString());
                                            this.setState({ almbih: selectedArticleType })

                                        }


                                    }}


                                    onSubmit={(data) => {

                                        if (this.state.articleForm.fundsLink) {
                                            data.fundsLink = this.state.articleForm.fundsLink
                                        }

                                        if (!data._id && this.state.articleForm != null) {
                                            data = { ...data, _id: this.state.articleForm._id }
                                        }


                                        if (this.state.articleForm && this.state.articleForm._id) {

                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, async () => {
                                                    this._onLoad();
                                                    let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                    if (broj && broj.firstFreeNumber) {
                                                        // console.log('citanje slobodnog broja.......', broj.firstFreeNumber)
                                                        this.remoteOnChange('code', broj.firstFreeNumber)
                                                    }

                                                })
                                            });
                                        } else {

                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, async () => {
                                                    this._onLoad()
                                                    let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                    if (broj && broj.firstFreeNumber) {

                                                        this.remoteOnChange('code', broj.firstFreeNumber)
                                                    }

                                                })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Artikal'.translate(this.props.lang),
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'code',
                                                                            next: 'name',
                                                                            label: 'Šifra',
                                                                            integer: true,
                                                                            // textType: 'number',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Šifra mora biti unešena!'),
                                                                                // async (value) => {
                                                                                //     try {
                                                                                //         if (value) {
                                                                                //             let res = await this._apiAsync('validate', { value: value, id: this.state.articleForm._id ? this.state.articleForm._id : null });
                                                                                //             if (res.status == 400) {
                                                                                //                 return 'Greska'
                                                                                //             }
                                                                                //             return undefined;
                                                                                //         }

                                                                                //     } catch (e) {
                                                                                //         return undefined;
                                                                                //     }
                                                                                // },
                                                                                number('Mora biti broj')
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'unit',
                                                                            label: 'Naziv',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // ne smije zarez
                                                                                required('Naziv artikla mora biti unešen!'),
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                async (value) => {

                                                                                    let articleId = ''
                                                                                    if (this.state.articleForm && this.state.articleForm._id) {
                                                                                        articleId = this.state.articleForm._id
                                                                                    }

                                                                                    let provjera = await this._apiAsync('checkName', { value: value.trim(), id: articleId })
                                                                                    console.log('provjera prov...', provjera)

                                                                                    if (provjera && provjera.result) return 'Artikal pod tim nazivom već postoji!'
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                                    children: [
                                                                        {
                                                                            type: 'selectWithSearch',
                                                                            name: 'unit',
                                                                            next: this.state.pomocna && !this.state.prevPomocna ? 'articleType' : 'numberInBox',
                                                                            label: 'Jedinica mjere',
                                                                            values: this.state.kutKom,
                                                                            asyncValidation: true,
                                                                            showName: true,
                                                                            validate: [

                                                                                required('Jedinica mjere mora biti unešena!'),

                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.kutKom.map(item => {
                                                                                            return item.value
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },

                                                                                async (value) => {

                                                                                    if (value == 'komad') {
                                                                                        this.setState({ pomocna: false }, () => {

                                                                                        })
                                                                                    }
                                                                                    if (value == 'kutija') {
                                                                                        this.setState({ pomocna: true }, () => {
                                                                                            this.remoteOnChange('numberInBox', '')

                                                                                        })
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'numberInBox',
                                                                            next: 'articleType',
                                                                            label: 'Kom. u kutiji',
                                                                            textType: 'number',
                                                                            asyncValidation: true,
                                                                            readOnly: this.state.pomocna,

                                                                            validate: [
                                                                                (value) => {
                                                                                    if (value) {
                                                                                        if (this.state.pomocna) {
                                                                                            return 'Morate unijeti broj komada u kutiji!'
                                                                                        }
                                                                                    }
                                                                                }

                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'articleType',
                                                                            next: 'group',
                                                                            label: 'Vrsta artikla',
                                                                            showName: true,
                                                                            // dataModal: this.state.articleTypes,
                                                                            // title: 'Pregled tipa artikla',
                                                                            values: [{ name: '', value: -1 }, ...this.state.articleTypes.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Vrsta artikla mora biti unešena!'),
                                                                                (value) => {
                                                                                    this.setState({ pomocna1: value })
                                                                                },

                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.articleTypes.map(item => {
                                                                                            return item._id
                                                                                        })

                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },

                                                                                (value) => {
                                                                                    let portionArticleType = this.state.articleTypes
                                                                                    let idList = portionArticleType.map((item) => {
                                                                                        return item._id
                                                                                    })
                                                                                    // this.setState({ almbih: idList.includes(value) })
                                                                                },


                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 4, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'position',
                                                                //             next: 'group',
                                                                //             label: 'Lokacija',
                                                                //             // showName: true,
                                                                //             // dataModal: this.state.articleTypes,
                                                                //             // title: 'Pregled tipa artikla',
                                                                //             // values: [{ name: '', value: -1 }, ...this.state.articleTypes.map(item => {
                                                                //             //     let obj = {
                                                                //             //         name: item.name,
                                                                //             //         value: item._id
                                                                //             //     }
                                                                //             //     return obj;
                                                                //             // })],
                                                                //             // asyncValidation: true,
                                                                //             // validate: [
                                                                //             //     (value) => {
                                                                //             //         this.setState({ pomocna1: value })
                                                                //             //     },

                                                                //             //     async (value) => {
                                                                //             //         if (value) {
                                                                //             //             let red = this.state.articleTypes.map(item => {
                                                                //             //                 return item._id
                                                                //             //             })
                                                                //             //             if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                //             //         }

                                                                //             //     },

                                                                //             //     (value) => {
                                                                //             //         let portionArticleType = this.state.articleTypes
                                                                //             //         let idList = portionArticleType.map((item) => {
                                                                //             //             return item._id
                                                                //             //         })
                                                                //             //         this.setState({ almbih: idList.includes(value) })
                                                                //             //     },


                                                                //             // ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'group',
                                                                            next: 'subgroup',
                                                                            label: 'Grupa',
                                                                            showName: true,
                                                                            dataModal: this.state.groups,
                                                                            title: 'Pregled grupe artikla',
                                                                            values: [{ name: '', value: -1 }, ...this.state.groups.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Grupa mora biti unešena!'),
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.groups.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'subgroup',
                                                                            next: 'manufacturer',
                                                                            label: 'Podgrupa',
                                                                            showName: true,
                                                                            dataModal: this.state.subgroups,
                                                                            title: 'Pregled podgrupe artikla',
                                                                            values: [{ name: '', value: -1 }, ...this.state.subgroups.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // required('Podgrupa mora biti unešena!'),

                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.subgroups.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },

                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'manufacturer',
                                                                            next: 'barcode',
                                                                            label: 'Proizvođač',
                                                                            showName: true,
                                                                            dataModal: this.state.manufacturers,
                                                                            title: 'Pregled proizvodjaca artikla',
                                                                            values: [{ name: '', value: -1 }, ...this.state.manufacturers.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { },
                                                                                required('Proizvođač mora biti unešen!'),
                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.manufacturers.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'list',
                                                                            name: 'barcode',
                                                                            height: 302,
                                                                            next: 'fond',
                                                                            label: 'Bar kod',
                                                                            validateApi: '/data/products/barcode-check',
                                                                            // asyncValidation: true,
                                                                            // validate: [
                                                                            //     async (value) => {
                                                                            //         if (value) {
                                                                            //             let product = await this._apiAsync('barcode-check', {barcode: value})
                                                                            //             if ((product && !this.state.articleForm._id) || (product && this.state.articleForm._id && this.state.articleForm._id == product._id)) return 'Barkod vec postoji!'
                                                                            //         }

                                                                            //     },
                                                                            // ],
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            children: [

                                                                                {
                                                                                    type: 'block',
                                                                                    style: { background: 'transparent', boxShadow: 'none', paddingTop: 25 },
                                                                                    header: 'Eksterne šifre',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'list',
                                                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                                                    name: 'excode2',
                                                                                                    height: 231,
                                                                                                    next: 'sarajevo',
                                                                                                    label: 'Galenika',
                                                                                                    asyncValidation: true,
                                                                                                    validate: [
                                                                                                        // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                                    ]
                                                                                                },
                                                                                            ]
                                                                                        },
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'list',
                                                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                                                    name: 'sarajevo',
                                                                                                    height: 231,
                                                                                                    label: 'Apoteke Sarajevo',
                                                                                                    asyncValidation: true,
                                                                                                    validate: [
                                                                                                        // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                                    ]
                                                                                                },
                                                                                            ]
                                                                                        },
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-max',
                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'active',
                                                                            next: 'stock',
                                                                            label: 'Aktivan',
                                                                            className: 'test123',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom',
                                                                    width: { lg: 9, sm: 9, xs: 9 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                        },
                                                                                        // {
                                                                                        //     type: 'button',
                                                                                        //     width: { lg: 4, sm: 6, xs: 6 },
                                                                                        //     action: 'submit',
                                                                                        //     children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                        //     className: 'options-button'
                                                                                        // },
                                                                                        // {
                                                                                        //     type: 'button',
                                                                                        //     width: { lg: 4, sm: 6, xs: 6 },
                                                                                        //     action: 'submit',
                                                                                        //     children: <div className="option-buttons-container"><div className="option blue"><Isvg src={exportIcon} /></div> <span>Export</span></div>,
                                                                                        //     className: 'options-button'
                                                                                        // },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {

                                                                                                // console.log('okokokokokokokokoko.....', Object.keys(this.state.articleForm).length)
                                                                                                if (Object.keys(this.state.articleForm).length != 0) {

                                                                                                    this.setState({ deleteModal: this.state.articleForm })

                                                                                                } else {

                                                                                                    this.remoteOnChange('name', '')
                                                                                                    this.remoteOnChange('group', '')
                                                                                                    this.remoteOnChange('subgroup', '')
                                                                                                    this.remoteOnChange('manufacturer', '')
                                                                                                    this.remoteOnChange('barcode', '')
                                                                                                    this.remoteOnChange('excode2', '')
                                                                                                    this.remoteOnChange('sarajevo', '')
                                                                                                    this.remoteOnChange('unit', '')
                                                                                                    this.remoteOnChange('articleType', '')
                                                                                                    this.remoteOnChange('numberInBox', '')
                                                                                                    this.remoteOnChange('fond', '')

                                                                                                    this.setState({ articleForm: null }, () => {
                                                                                                        this.setState({ articleForm: {} })
                                                                                                    })
                                                                                                }

                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'block',
                                                            header: 'ALMBIH'.translate(this.props.lang),
                                                            style: { marginLeft: 15, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'atc',
                                                                            next: 'inn',
                                                                            label: 'ATC',
                                                                            readOnly: !this.state.almbih || (this.state.almbih && this.state.almbih.articleType !== 1),
                                                                            values: [{ name: 'Izaberite atc', value: -1 }, ...this.state.atc.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" },
                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.atc.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'tagsSearch',
                                                                            name: 'inn',
                                                                            next: 'shape',
                                                                            label: 'INN',
                                                                            suggestions: this.state.suggestions,
                                                                            asyncValidation: true,
                                                                            readOnly: !this.state.almbih || (this.state.almbih && this.state.almbih.articleType !== 1),
                                                                            validate: [
                                                                                (value) => {
                                                                                    console.log('value', value);
                                                                                    if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(this.state.pomocna1) != -1) {
                                                                                        if (!value || (value && !value.length)) {
                                                                                            return "INN mora biti unesena!"
                                                                                        }
                                                                                    }


                                                                                }

                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'shape',
                                                                            next: 'dose',
                                                                            label: 'Oblik',
                                                                            readOnly: !this.state.almbih || (this.state.almbih && this.state.almbih.articleType !== 1),
                                                                            values: [{ name: 'Izaberite oblik', value: -1 }, ...this.state.shapes.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.shapes.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },

                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'dose',
                                                                            next: 'packaging',
                                                                            label: 'Jačina (doza)',
                                                                            asyncValidation: true,
                                                                            readOnly: !this.state.almbih || (this.state.almbih && this.state.almbih.articleType !== 1),
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'packaging',
                                                                            next: 'storage-mode',
                                                                            label: 'Pakovanje',
                                                                            asyncValidation: true,
                                                                            readOnly: !this.state.almbih,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'storage-mode',
                                                                            next: 'jidl',
                                                                            label: 'Režim čuvanja',
                                                                            readOnly: !this.state.almbih,
                                                                            values: [{ name: '', value: -1 }, ...this.state.modes.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item._id
                                                                                }
                                                                                return obj;
                                                                            })],
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // required('Režim čuvanja je obavezno unijeti!')
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                                async (value) => {
                                                                                    if (value) {
                                                                                        let red = this.state.modes.map(item => {
                                                                                            return item._id
                                                                                        })
                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                    }

                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'jidl',
                                                                            label: 'JIDL',
                                                                            asyncValidation: true,
                                                                            readOnly: !this.state.almbih || (this.state.almbih && this.state.almbih.articleType !== 1),
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },

                                                    ]
                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {
                                        this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                            if (data.result && data.result.error) {
                                                this.setState({
                                                    error: data.result.error
                                                })
                                            } else {
                                                this.setState({ articleForm: {} }, () => {
                                                    this._onLoad();
                                                    this.setState({ deleteModal: null })
                                                })
                                                this.remoteOnChange('name', '')
                                                this.remoteOnChange('group', '')
                                                this.remoteOnChange('subgroup', '')
                                                this.remoteOnChange('manufacturer', '')
                                                this.remoteOnChange('barcode', '')
                                                this.remoteOnChange('excode2', '')
                                                this.remoteOnChange('sarajevo', '')
                                                this.remoteOnChange('unit', '')
                                                this.remoteOnChange('articleType', '')
                                                this.remoteOnChange('numberInBox', '')
                                                this.remoteOnChange('fond', '')
                                            }

                                        });
                                    }} >
                                    Obrisati artikal <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                                </DeleteModal>
                                {
                                    this.state.articleForm && this.state.articleForm.fundsLink ?
                                        <div className='fond-password'>
                                            <div className='fond-password-container' style={{ display: 'block' }}>
                                                <h6>Šifra: <span> {this.state.articleForm && this.state.articleForm.fundsLink && this.state.articleForm.fundsLink['atc-sifra']}</span></h6>
                                                <h6>Cijena: <span> {this.state.articleForm && this.state.articleForm.fundsLink ? this.state.articleForm.fundsLink['cijena'] + ' KM' : ''} </span></h6>
                                                <h6>Lista:<span> {this.state.articleForm && this.state.articleForm.fundsLink && this.state.articleForm.fundsLink['lista-id']}</span></h6>
                                                <h6>INN:<span>  {this.state.articleForm && this.state.articleForm.fundsLink && this.state.articleForm.fundsLink['naziv-atc5-nivo']}</span></h6>
                                                <div className='delete-fond-code'><button onClick={() => { this.deleteFondCode({ ...this.state.articleForm, fundsLink: null }) }} className='button-delete-fond-code'>Obrisati</button></div>
                                            </div>

                                        </div>
                                        :
                                        null
                                }

                                <div className='fond-password'>
                                    <div className='fond-password-container'>
                                        <a onClick={() => {
                                            this.setState({
                                                fondPasswordModal: true
                                            })
                                        }}>FOND ŠIFRE</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        :

                        null
                }
                {
                    this.state.buttonActive == 2 ?
                        <div className='active-check-button'>
                            <Checkbox
                                key={1}
                                onChange={
                                    () => {
                                        this.setState({ aktivniCheck: !this.state.aktivniCheck }, () => {
                                            this.updateParams('active', this.state.aktivniCheck)
                                        })
                                    }
                                }
                                checked={this.state.aktivniCheck}
                                label={"Neaktivni"}
                            ></Checkbox>
                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive == 2 ?
                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                        if (data.result && data.result.error) {
                                            this.setState({
                                                error: data.result.error
                                            })
                                        } else {
                                            this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })

                                        }
                                    });
                                }} >
                                Obrisati artikal <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                            </DeleteModal>
                            <div style={{ marginTop: 90 }} className="list-builder-no-buttons">
                                <ListBuilder
                                    tabs={false}
                                    sort={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'unit', label: 'JEDNICA MJERE'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'groupName', label: 'GRUPA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'subgroupName', label: 'PODGRUPA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            backgroundClassname: !item.active ? 'orange-row' : '',
                                        }
                                    })}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} onClick={() => {
                                                    this.setState({
                                                        buttonActive: 3
                                                    })
                                                }} />,
                                                onClick: (item) => {
                                                    console.log('KLIKNUTO NA ITEM.....', item)

                                                    if (item.unit == 'kutija') {
                                                        this.setState({ pomocna: true })
                                                    } else if (item.unit == 'komad') {
                                                        this.setState({ pomocna: false })
                                                    }


                                                    this.setState({ articleForm: item })
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                            </div>
                        </div>
                        :
                        null
                }

                {
                    this.state.loadingExport ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/loader.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }
                {
                    this.state.error && this.state.error != '' ?
                        <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: '', deleteModal: null })} error={this.state.error}>

                        </ErrorModal>
                        :
                        null
                }
            </div >
        )
    }
}

export default Page(Home);

/*

*/
