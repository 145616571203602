import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import printJS from 'print-js'


export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-entrace': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/entrance', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    unfinished: query.unfinished,
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        entranceItems: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'export-entrace': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/entrance', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    unfinished: query.unfinished,
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    action: 'export',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'print-entrace': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/entrance', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    unfinished: query.unfinished,
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    action: 'print',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                    }
                })
            }).then((response) => {
                if (response.ok) { // checks if the response is with status 200 (successful)
                    return response.blob().then(blob => {
                        let pdfFile = new Blob([blob], {
                            type: "application/pdf"
                        });
                        let pdfUrl = URL.createObjectURL(pdfFile);
                        // window.open(pdfUrl);
                        printJS(pdfUrl);
                    });
                }
            })

        }
    },
    'get-expenses': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/expenses', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'code',
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        expenses: result,
                        // total: result.total,
                    }
            })
        }
    },

    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/entrance/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    unfinished: query.unfinished,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        printHtml: result.html,
                    }
            })
        }
    },
    'export': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/entrance/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    unfinished: query.unfinished,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'insert-new': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-item': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/remove/item/' + data._id + '/' + data.itemNumber, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/remove/document/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    return {
                        result: result,
                    }
                }
                if(status === 405) {
                    return {
                        error: result.error,
                        status
                    }
                }
            })
        }
    },



    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-series': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 100,
                    article: query.article,
                    filter: query.filter,
                    query: query.query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-stock': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/stock/' + data.article, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                    }
            })
        }
    },



    'validate-currency-date': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/validate-currency-date', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/entrance/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'checkNextDocumentNumber': {
        onLoad: true,
        action: (data) => {

            return fetch(env.API_URL + '/data/entrance/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'checkNextItemNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/entrance/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'get-courses': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/course', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        courses: result,

                    }
            })
        }
    },



    'validate': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/validate/' + data.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'validate-serial-number': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series/validate/' + data.value + '/' + data.code, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'printEntranceDocument': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/pdf', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                        });
                    }
                })

        }
    },


    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) => {
                return {
                    articlesSearch: response.result.items
                }
            })
        }
    },

    'clientsSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/clients/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) => {
                return {
                    clientsSearch: response.result.items
                }
            })
        }
    }




}
