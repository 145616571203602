import ClientPage from './client';
import LoginPage from './login';
import ArticlesPage from './articles';
import ExpensePage from './expense';
import PdvPage from './register-pdv';
import WarehousePage from './warehouse';
import ReportPage from './reports';
import CountryPage from './country';
import ConditionPage from './conditions';
import GroupsPage from './group';
import SubgroupsPage from './subgroup';
import ManufacturerPage from './manufacturer';
import ArticleTypePage from './article-type';
import ATCPage from './atc';
import InnPage from './inn';
import ShapePage from './shape';
import DiscountPage from './discount';
import StorageMode from './storageMode';
import BusinessUnit from './business-unit';
import PlacePage from './place';
import VehiclePage from './register-vehicle';
import NoteRegister from './register-note';
import EntryPage from './entry';
import NivelationPage from './nivelation';
import CoursePage from './course';
import EntrancePage from './entrance';
import OutputPage from './output';
import UsersPage from './users';
import UserLevelsPage from './userLevels';
import ReservationPage from './reservation';
import EstimationPage from './estimation';
import WriteOffPage from './write-off';
import DeficitPage from './deficit';
import SupplierReturnPage from './supplier-return';
import OnlineRequisitionPage from './online-requisition';
import SeriesPage from './series';
import KSSPage from './kss';
import CheckoutFondPage from './checkout-fond';
import BookNoticePage from './book-notice';
import BookDebitPage1 from './book-debit';
import NaturalDiscountPage from './natural-discount';
import ModulesPage from './modules';
import FundsPage from './funds';
import FundsXMLPage from './fundsXML';
import DeadlinesPage from './deadlines';
import PackingPage from './packing';
import ColdChainPage from './coldChain';
import ReportCategoriesAndManufacturers from './reportCategoriesAndManufacturers';
import ControlReportPage from './controlReport';
import ReturningCratesPage from './returningCrates';
import DisplayControlsPage from './displayControls';
import CrateDutiesPage from './crateDuties';
import BuyerReturn from './buyerReturn';
import FactureChange from './facture-change';
import LocationPage from './location';
import WriteOffPage1 from './writeOff'
import InstitutionType from "./institutionType";
import ReportSupplierManufacturers from './reportsSupplierManufacturer';
import DiscountSupplier from './discountSupplier';
import PricelistPage from "./pricelist";
import StockPage from "./stock";
import OrderPage from "./order";
import TotalArticles from './reportTotalArticles'
import ReportGalenika from './reportGalenika'
import ReportPliva from './reportPliva'
import ReportEsensa from './reportEsensa'
import InventoryList from "./inventoryList";
import TurnoverByGroups from "./turnoverByGroups";
import ReportKO from './reportKO'
import TradeBook from './tradeBook'
import SeriesReport from './seriesReport'
import HomePage from './home';
import ProfilePage from './profile';
import ReportIcm from './reportICM'
import ClientUsers from './clientUsers';
import ReportJUAS from './reportJUAS';


import reportsCustomersAndItems from './reportsCustomersAndItems';
import FundPrices from './reportsFundPrices'
import Analysis from './analysis';
import ReportClientArticle from "./reportClientArticle";
import NaturalniRabat from './naturalniRabat'
import FiscalCashQueue from './fiscal-cash-queue';
import OnlineDemand from './online-demand';
import BannersPage from './banners';
import AccountNumbers from './accountNumbers';
import linkFields from './linkFields';
import links from './links';
import financialAccounts from './financialAccounts';
import AnalyticsByClientsAndAccounts from './analyticsByClientsAndAccounts';
import AnalyticalCardByAccounts from './analyticalCardByAccounts';
import FinancialAccountsReports from './financialAccountsReports';
import FinancialDifferences from './financialDiff';
import Bookkeeping from './bookkeeping';
import postingOfStatements from './postingOfStatements';

export const routes = [
    HomePage,
    ClientPage,
    LoginPage,
    ArticlesPage,
    ExpensePage,
    WarehousePage,
    ReportPage,
    CountryPage,
    ConditionPage,
    GroupsPage,
    SubgroupsPage,
    ManufacturerPage,
    ArticleTypePage,
    ATCPage,
    InnPage,
    ShapePage,
    DiscountPage,
    StorageMode,
    BusinessUnit,
    PlacePage,
    VehiclePage,
    NoteRegister,
    EntryPage,
    NivelationPage,
    CoursePage,
    EntrancePage,
    OutputPage,
    UsersPage,
    UserLevelsPage,
    ModulesPage,
    ReservationPage,
    EstimationPage,
    WriteOffPage1,
    DeficitPage,
    SupplierReturnPage,
    OnlineRequisitionPage,
    KSSPage,
    SeriesPage,
    CheckoutFondPage,
    NaturalDiscountPage,
    BookNoticePage,
    // BookDebitPage,
    BookDebitPage1,
    FundsPage,
    FundsXMLPage,
    DeadlinesPage,
    PackingPage,
    ColdChainPage,
    ReportCategoriesAndManufacturers,
    ControlReportPage,
    ReturningCratesPage,
    DisplayControlsPage,
    CrateDutiesPage,
    BuyerReturn,
    FactureChange,
    LocationPage,
    InstitutionType,
    ReportSupplierManufacturers,
    DiscountSupplier,
    PricelistPage,
    OrderPage,
    StockPage,
    reportsCustomersAndItems,
    FundPrices,
    TotalArticles,

    ReportGalenika,
    ReportPliva,
    ReportEsensa,
    ReportIcm,
    ReportJUAS,

    InventoryList,
    TurnoverByGroups,
    Analysis,
    ReportKO,
    TradeBook,
    NaturalniRabat,
    ReportClientArticle,
    ProfilePage,
    PdvPage,
    ClientUsers,
    FiscalCashQueue,
    OnlineDemand,
    BannersPage,
    AccountNumbers,
    linkFields,
    links,
    financialAccounts,
    AnalyticsByClientsAndAccounts,
    AnalyticalCardByAccounts,
    SeriesReport,
    FinancialAccountsReports,
    FinancialDifferences,
    Bookkeeping,
    postingOfStatements

]

