
import React, { Component } from 'react';
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import ReactTags from 'react-tag-input-custom-search'
/**
* Tags component
* @author   Milan Stanojevic
*/
class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: this.props.value ? this.props.value : [],
            suggestions: this.props.suggestions ? this.props.suggestions : [],
            readOnly: this.props.readOnly ? this.props.readOnly : false
        };
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags }, () => {
            this.props.onChange(this.state.tags)
        })
    }

    handleAddition(tag) {
        console.log(this.state.tags, tag)
        if (this.state.tags.filter((item) => item.name == tag.name).length > 0) {
            return;
        }
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags }, () => {
            this.props.onChange(this.state.tags)
        })
    }


    render() {
        // console.log(this.props.setFieldRef, 'this.props.setFieldRef');
        // console.log('...............', this.props)
        return (
            <FormGroup id={`react-tags-${this.props.name}`}>
                {this.props.label ? <Label size="sm">{this.props.label}</Label> : null}

                {
                    this.props.readOnly ?
                        <Input disabled={true} />
                        :
                        <ReactTags
                            ref={(node) => {
                                //if (this.props.setFieldRef)
                                this.props.setFieldRef(node)
                            }}
                            onFocus={this.props.onFocus}
                            tags={this.props.value}
                            suggestionsFilter={(item, query) => {
                                if (item.name.toLowerCase().indexOf(String(this.state.query).toLowerCase()) == 0) {
                                    return true;
                                }
                                return false;
                            }}
                            suggestions={this.state.suggestions}
                            minQueryLength={1}
                            disableMarkIt={false}
                            handleDelete={this.handleDelete.bind(this)}
                            placeholder=""
                            handleAddition={this.handleAddition.bind(this)}
                            handleInputChange={(query) => {
                                this.setState({ query })
                            }}
                            autofocus={false}
                        />
                }


                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }


            </FormGroup>
        );
    }
}

export default Tags;