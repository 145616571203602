import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { uuid } from 'uuidv4'
// import DeleteModal from '../../components/deleteModal';
import SearchModal from '../../components/searchModal';

const substr = 17;


class Text extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen: false,
            valueText: null,
            inputID: uuid(),
            selectedValue: null,
            selectedIndex: 0,
            pretraga: false,
            search: ''
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.value != prevProps.value && !this.props.value) {
            this.setState({ isOpen: false, valueText: '', search: '' })
        }

        if (prevProps.loading != this.props.loading && !this.props.loading && this.whenLoadingFinishesCallback) {
            this.whenLoadingFinishesCallback();
            this.whenLoadingFinishesCallback = null;
        }

        if (prevState.selectedValue != this.state.selectedValue && this.state.selectedValue) {
            if (this.props.dataModal && this.props.dataModal.length && this.tableRef && this.tableRef.children[1]) {
                let prevIndex;
                if (prevState.selectedValue)
                    prevIndex = this.props.dataModal.findIndex(el => el.code == prevState.selectedValue)

                let index = this.props.dataModal.findIndex(el => el.code == this.state.selectedValue)
                let refInfo = this.tableRef.children[1].getBoundingClientRect();
                if (index < 0) {
                    return;
                }
                // console.log(index)
                // console.log(this.tableRef.children[1].children[index])
                let elementInfo = this.tableRef.children[1].children[index].getBoundingClientRect()
                let minPosition = 0;
                let maxPosition = 0;
                minPosition = refInfo.top;
                maxPosition = refInfo.top + refInfo.height - 43;
                let elementPosition = 0;
                elementPosition = elementInfo.top;
                let maxElements = (maxPosition - minPosition) / 43 - 1;

                if (prevIndex && prevIndex < index) {
                    if (maxElements < index) {
                        this.tableRef.children[1].scrollTop = 43 * (index - maxElements);
                    }
                } else if (prevIndex && prevIndex > index) {
                    if (minPosition > elementPosition)
                        this.tableRef.children[1].scrollTop -= 43;
                }

            }
        }

        if (this.props.value != prevProps.value) {
            this.setState({
                search: this.props.value
            })
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.value) {
            this.props.metoda(this.props.value)

            this.setState({
                search: this.props.value
            })
        } else {
            this.props.metoda('')

        }

    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {
        // console.log('POSLATE VRIJEDNOSTI ZA ARTIKLE....', this.props.dataModal)
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName} articles-field`} >
                <div ref={this.wrapperRef}>
                    <Label>{this.props.label}</Label>

                    <input autoComplete="off" id={this.state.inputID} className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control bg-arrow`} type={this.props.textType ? this.props.textType : "text"}
                        ref={(node) => {
                            if (this.props.setFieldRef) {
                                this.props.setFieldRef(node)
                            }
                        }}

                        onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                                e.preventDefault()

                                this.setState({ isOpen: true })

                            }
                        }}



                        onKeyUp={(e) => {
                            if (e.key == this.props.finishKey) {
                                // console.log('loading', this.props.loading)
                                this.setState({ isOpen: false })
                                if (this.state.selectedValue) {
                                    if (this.props.loading) {
                                        this.whenLoadingFinishesCallback = () => {
                                            this.props.onChange(this.state.selectedValue, () => {
                                                if (this.props.focusNext)
                                                    this.props.focusNext();

                                            })

                                        }
                                    } else {
                                        this.props.onChange(this.state.selectedValue, () => {
                                            if (this.props.focusNext)
                                                this.props.focusNext();

                                        })

                                    }
                                } else {
                                    if (this.props.loading) {
                                        this.whenLoadingFinishesCallback = () => {

                                            this.props.onChange(this.props.value, () => {
                                                if (this.props.focusNext)
                                                    this.props.focusNext();
                                            })
                                        }

                                    } else {
                                        this.props.onChange(this.props.value, () => {
                                            if (this.props.focusNext)
                                                this.props.focusNext();
                                        })

                                    }
                                    this.props.onChange(this.props.value, () => {
                                        if (this.props.focusNext)
                                            this.props.focusNext();
                                    })

                                }
                            }
                            if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {

                                let value;
                                if (this.props.dataModal && this.props.dataModal.length) {
                                    if (this.state.selectedValue) {
                                        let index = this.props.dataModal.findIndex(el => el.code == this.state.selectedValue)
                                        if (e.key == 'ArrowUp') {
                                            if (index > 0) {

                                                value = this.props.dataModal[index - 1].code
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                        } else if (e.key == 'ArrowDown') {

                                            if (index < this.props.dataModal.length - 1) {
                                                value = this.props.dataModal[index + 1].code
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                        }
                                    } else {
                                        value = this.props.dataModal[0].code;
                                    }

                                }
                                this.setState({
                                    selectedValue: value
                                }, () => {

                                })
                            } else {
                                if (this.state.selectedValue) {
                                    this.setState({ selectedValue: null })
                                }
                            }
                        }}
                        onChange={(e) => {
                            if (this.props.metoda && e.target.value && e.target.value.trim()) {
                                this.props.metoda(e.target.value)
                            }

                            if (Number(e.target.value) != NaN)
                                this.props.onChange(e.target.value);

                            this.setState({ /*isOpen: true,*/ search: e.target.value })
                        }}
                        //onFocus={() => this.setState({ isOpen: true })}
                        disabled={this.props.readOnly}
                        value={this.state.search}
                        placeholder={this.props.placeholder}
                    ></input>
                    {this.props.error && this.props.touched ?
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                        :

                        null
                    }


                    {

                        this.props.dataModal && this.props.dataModal.length && this.state.isOpen ?
                            <div className="articles-list">
                                <table ref={(node) => this.tableRef = node}>
                                    <thead>
                                        <tr>
                                            <th>ŠIFRA</th>
                                            <th>NAZIV</th>
                                            <th>STANJE </th>
                                            <th>REZ </th>

                                            <th>CIJENA </th>
                                            <th>FOND</th>

                                            <th>PROIZVOĐAČ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.dataModal.map((item, idx) => {
                                                return (
                                                    <tr className={this.state.selectedValue == item.code ? 'active' : ''} onClick={() => {
                                                        this.setState({
                                                            isOpen: false
                                                        })
                                                        this.props.onChange(item.code, () => {
                                                            if (this.props.focusNext)
                                                                this.props.focusNext();
                                                        })
                                                    }}>
                                                        <td>{item.code}</td>
                                                        <td>
                                                            {
                                                                substr && item.name && item.name.length > substr ?
                                                                    <div className={idx == 0 ? 'tooltip-div-bottom' : 'tooltip-div'}>
                                                                        {item.name.substr(0, substr) + '...'}
                                                                        <div className='tooltip-text'>
                                                                            {item.name}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    item.name
                                                            }
                                                        </td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.quantityReserved}</td>
                                                        <td>{item.sellPrice}</td>
                                                        <td>{item.fundPrice}</td>
                                                        <td>
                                                            {/* {item.manufacturerName} */}
                                                            {
                                                                substr && item.manufacturerName && item.manufacturerName.length > substr ?
                                                                    <div className={idx == 0 ? 'tooltip-div-left-bottom' : 'tooltip-div-left'} >
                                                                        {item.manufacturerName.substr(0, substr) + '...'}
                                                                        <div className='tooltip-text'>
                                                                            {item.manufacturerName}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    item.manufacturerName
                                                            }
                                                        </td>
                                                    </tr>

                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            :
                            null
                    }

                </div>


            </FormGroup>
        )
    }
}

export default Text;
