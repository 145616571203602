import React, { Component } from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import DateField from '../../form-builder/fields/date';

import moment from 'moment';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            items: [],
            
        }
    }

    componentDidMount() {
        // this._onLoad();
    }

    totalArticles = async () => {

        this.updateMultipleParams([
            { name: 'sellPrice', value: this.state.filterSellPrice },
            { name: 'purchasePrice', value: this.state.filetPurchasePrice },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'serialNumber', value: this.state.filterSerialNumber },
            { name: 'changeType', value: this.state.filterTypeOfChange },
            { name: 'timestamp', value: new Date().getTime() },
            { name: 'page', value: 0 },
        ])

        this.setState({
            reservation: false
        })
    }

    clearFilters = () => {
        this.setState({
            filterSellPrice: '',
            filetPurchasePrice: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterArticleFrom: '',
            filterSerialNumber: '',
            filterTypeOfChange: '',

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'sellPrice', value: null },
                    { name: 'purchasePrice', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'articleFrom', value: null },
                    { name: 'serialNumber', value: null },
                    { name: 'changeType', value: null },

                ])
            setTimeout(() => {
                this.totalArticles()
            }, 300);
        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Report JUAS</h1>
                    
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        
                                        <div className="field-strap-wrap" style={{ width: 200 }}>

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                })}
                                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom : null}
                                                label={'Datum'}
                                            ></DateField>
                                        </div>
                                        
                                    </div>
                                    <div>
                                        <button className={!this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => this.totalArticles()}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Inport</p>
                                        </button>
                                    </div>

                                </div>
                                

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                total={this.state.total}
                                loading={this.state.loading}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'date', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'type', label: 'JM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'serialNumber', label: 'PAKOVANJE'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'quantityIn', label: 'PRO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'quantityOut', label: 'BARKOD'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'quantityAverage', label: 'SIFRA PROIZVODA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'nivelationAmmount', label: 'NAZIV PROIZVODA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'price', label: 'JM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'valueIn', label: 'PROIZVODJAC'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },
                                    { type: 'text', name: 'valueOut', label: 'BARKOD'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },
                                    { type: 'text', name: 'valueOut', label: 'ATC'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },
                                    { type: 'text', name: 'valueOut', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },
                                    { type: 'text', name: 'valueOut', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },

                                ]}
                                items={this.state.items.map(item => {
                                    return {
                                        ...item,
                                        documentNumber: <div onClick={() => {
                                            if(typeof window != 'undefined'){
                                                window.open(`/${item ? item.deliveryDate ? 'output' : item.nivelationDate ? 'nivelation' : item.returnDate ? 'supplier-return' : item.entraceDate ? 'entrance' : item.factureDate ? 'buyer-return' : '' : ''}?redirect=${item && item.documentNumber}`, "_blank");
                                            }
                                        }}>{item && item.documentNumber}</div>,
                                        date: moment.unix(item.sortDate).format('DD.MM.YYYY'),
                                        
                                        quantityAverage: item.average ? item.average : '',
                                        nivelationAmmount: item && item.nivelationDate && item.article ? item.article.ammount : 0,
                                        price: item.sellPrice ? item.sellPrice : item.buyPrice ? item.buyPrice : item && item.entraceDate ? item.article.sellPrice : item.article.price,
                                        valueIn: item && item.entraceDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.sellPrice) * 100) / 100 : item && item.factureDate && !item.deliveryDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : 0,
                                        valueOut: item && item.deliveryDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : item && item.returnDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : 0,

                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg='6'>
                            <div className='blue-box-info-text-wrap'>
                                <div className='blue-box-info-text'>
                                    <h6>Export</h6>
                                    
                                </div>
                                
                            </div>



                        </Col>
                        
                    </Row>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);