
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import { required, validateDate } from '../../form-builder/validation';
import moment from 'moment';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class FinancialReports extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            financialItems: [],
            manufacturers: [],
            financialItemType: [],
            financialAccountNumber: [],
            total: [],
            loading: false,
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            totalAsking: [],
            totalOwes: [],
            checkDocumentDateTs: true

        }
    }

    componentDidMount() {
        this._onLoad();
        (async () => {
        })()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    print = (data) => {
        this.setState({ loadingPage: true }, async () => {
            await this._apiAsync('print', data);
            this.setState({ loadingPage: false })


        })
    }



    render() {
        console.log(this.state.form);
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Finansijski izvještaj</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.print(this.state.form)}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" style={{ marginTop: 10 }}>
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                afterSubmitFocusField="itemNumber"
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['documentDateTs', 'accountDateTs', 'itemType', 'accountNumberCode']}
                                fieldTracker={async (name, value) => {

                                    if (name === 'documentDateTs' && value) {
                                        this.remoteOnChange('accountDateTs', false)

                                    }

                                    if (name === 'accountDateTs' && value) {
                                        this.remoteOnChange('documentDateTs', false)

                                    }

                                    if (name === 'itemType' && value) {

                                        if (value) {
                                            const financialItemType = await this._apiAsync('get-financial-item-type', { filter: value })
                                            if (financialItemType && financialItemType.items) {
                                                this.setState({ financialItemType: financialItemType && financialItemType.items }, () => {
                                                    if (this.state.financialItemType.filter(item => item.code == value).length) {
                                                        this.remoteOnChange('itemTypeName', this.state.financialItemType.filter(item => item.code == value)[0].name)
                                                    } else {
                                                        this.remoteOnChange('itemTypeName', '')
                                                    }
                                                })
                                            }
                                        }
                                    }

                                    if (name === 'accountNumberCode' && value) {
                                        if (value) {
                                            const financialAccountNumber = await this._apiAsync('get-financial-accounts-numbers', { filter: value })
                                            if (financialAccountNumber && financialAccountNumber.items) {
                                                this.setState({ financialAccountNumber: financialAccountNumber && financialAccountNumber.items }, () => {
                                                    if (this.state.financialAccountNumber.filter(item => item.code == value).length) {
                                                        this.remoteOnChange('accountNumberCodeName', this.state.financialAccountNumber.filter(item => item.code == value)[0].name)
                                                    } else {
                                                        this.remoteOnChange('accountNumberCodeName', '')
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }}
                                initialValues={{ ...this.state.form, documentDateTs: true }}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data, typeButton) => {

                                    if (typeButton == 'prikaz') {
                                        this._api('financial-reports', data, (result) => {
                                            this.setState({ items: result.items, total: result.total, totalAsking: result.totalAsking, totalOwes: result.totalOwes, sum: result.sum }, () => {
                                                this._onLoad()

                                            })

                                        });
                                    }
                                    if (typeButton == 'stampa') {

                                        this._api('financial-print', data, (result) => {
                                            if (typeof window != 'undefined') {
                                                window.open(env.API_URL + result.link)
                                                this.setState({ loading: false })
                                            }
                                        });

                                       
                                    }

                                    return "open-modal"
                                }}

                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix input-mb-small full-width-list",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 3, sm: 10, xs: 10 },
                                                        className: "checkbox-list",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'reports',
                                                                                next: 'place',
                                                                                label: 'Izvjestaj',
                                                                                values: [
                                                                                    { value: 'dnevnikKnjizenja', name: 'Dnevnik knjizenja' },
                                                                                    { value: 'totalNalogaPoKontu', name: 'Total naloga po kontu' },
                                                                                    { value: 'glavnaKnjigaSaDonosomPoDatumu', name: 'Glavna knjiga sa donosom po datumu' },
                                                                                    { value: 'glavnaKnjigaSaDonosomPoNalogu', name: 'Glavna knjiga sa donosom po nalogu' },
                                                                                    { value: 'kumulativKontaPoNalozima', name: 'Kumulativ konta po nalozima' },
                                                                                    { value: 'kumulativNaloga', name: 'Kumulativ naloga' },
                                                                                    { value: 'kumulativKlasa', name: 'Kumulativ klasa' },
                                                                                    { value: 'brutoBilans', name: 'Bruto bilans' },
                                                                                    { value: 'zakljucniList', name: 'Zaključni list' }
                                                                                ],
                                                                                asyncValidation: true,

                                                                                validate: [
                                                                                    (value) => { if (!value || value === '') return "Izvjestaj mora biti izabran!" },
                                                                                    required()
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'documentDateTs',
                                                                                label: 'Datum dokumenta',
                                                                            },


                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'checkbox',
                                                                                name: 'accountDateTs',
                                                                                label: 'Datum naloga',
                                                                            },

                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 7, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'accountFrom',
                                                                                next: 'accountTo',
                                                                                label: 'Nalog od',
                                                                                textType: 'number',
                                                                                asyncValidation: true,

                                                                                finishField: true,
                                                                                validate: [

                                                                                ]

                                                                            },
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'itemType',
                                                                                next: 'accountNumberCode',
                                                                                label: 'Vrsta knjiženja',
                                                                                asyncValidation: true,
                                                                                values: [...this.state.financialItemType.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Vrsta knjiženja mora biti unesen!" },
                                                                                    // required('Vrsta knjiženja mora biti unešen!'),
                                                                                ]


                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20 },
                                                                        children: [

                                                                            {
                                                                                type: 'text',
                                                                                name: 'accountTo',
                                                                                next: 'itemType',
                                                                                textType: 'number',
                                                                                label: 'Nalog do',
                                                                                readOnly: this.state.readOnlyBusiness,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'accountNumberCode',
                                                                                next: 'dateFrom',
                                                                                label: 'Konto',
                                                                                asyncValidation: true,
                                                                                values: [...this.state.financialAccountNumber.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                validate: [



                                                                                    // required('Konto mora biti unešen!'),
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [

                                                                            {
                                                                                type: 'date',
                                                                                name: 'dateFrom',
                                                                                next: 'dateTo',
                                                                                label: 'Datum od',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                                    validateDate('Datum nije validan')
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 5, sm: 12, xs: 12 },
                                                                        style: { paddingLeft: 20, marginBottom: 0 },
                                                                        children: [

                                                                            {
                                                                                type: 'date',
                                                                                name: 'dateTo',
                                                                                next: 'submit',
                                                                                label: 'Datum do',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                                    validateDate('Datum nije validan')
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [

                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 10 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        ammount: true
                                                                                    })
                                                                                    this.submit('prikaz')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>PRIKAŽI</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        ammount: true
                                                                                    })
                                                                                    this.submit('stampa')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>Stampa</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },


                                                                        ]
                                                                    },

                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 10 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'RED'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'itemType', label: 'VRSTA KNJIŽENJA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'documentDate', label: 'DATUM DOKUMENTA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'description', label: 'OPIS'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'kifKuf', label: 'UF/IF'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'billNumber', label: 'RAČUN'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true, numberWithCommas: true },
                                        { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true, numberWithCommas: true },
                                    ]}
                                    items={this.state.items.map((item, idx) => {

                                        return {
                                            ...item,

                                        }
                                    })}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Duguje</p>
                                    <span>
                                        {
                                            this.state.totalOwes && this.state.totalOwes != 0 ?

                                                numberWithCommas(parseFloat(this.state.totalOwes).toFixed(2))

                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Potrazuje</p>
                                    <span>
                                        {
                                            this.state.totalAsking && this.state.totalAsking != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalAsking).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.sum && this.state.sum != 0 ?
                                                numberWithCommas(parseFloat(this.state.sum).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div >
        )
    }
}

export default Page(FinancialReports);
