import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';
import SelectWithSearch from '../../form-builder/fields/selectWithSearch';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DateField from '../../form-builder/fields/date';


import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            clients: [],
            reservation: false,
            totalPrice: 0,
        }
    }

    componentDidMount() {
        // this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    totalArticles = async () => {

        this.updateMultipleParams([
            { name: 'clientCode', value: this.state.filterClientCode },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'group', value: this.state.filterGroup },
            { name: 'timestamp', value: new Date().getTime() },
            { name: 'page', value: 0 },
        ])

        this.setState({
            reservation: false
        })
    }

    clearFilters = () => {
        this.setState({
            filterClientCode: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterArticleFrom: '',
            filterGroup: '',

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'clientCode', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'articleFrom', value: null },
                    { name: 'group', value: null },

                ])
            setTimeout(() => {
                this.totalArticles()
            }, 300);
        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Export podataka Pliva</h1>
                    <div className="title-options-group">
                        {/* <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button> */}
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>

                                        <div className="field-strap-wrap" style={{ display: 'flex' }}>
                                            <div>
                                                <SelectWithSearch label="Šifra komitenta" value={this.state.filterClientCode != null ? this.state.filterClientCode : params.clientCode} onChange={(e) => this.setState({ filterClientCode: e }, async () => {

                                                    const clients = await this._apiAsync('get-clients', { filter: this.state.filterClientCode })
                                                    if (clients && clients.itemsClients) {
                                                        this.setState({ clients: clients && clients.itemsClients }, () => {
                                                            if (this.state.clients.filter(item => item.code == this.state.filterClientCode).length) {
                                                                this.setState({
                                                                    filterClientName: this.state.clients.filter(item => item.code == this.state.filterClientCode)[0].name
                                                                })

                                                            } else {
                                                                this.setState({
                                                                    filterClientName: ''
                                                                })
                                                            }
                                                        })
                                                    }
                                                })} values={[...this.state.clients.map(item => {
                                                    let obj = {
                                                        name: item.name,
                                                        value: item.code
                                                    }
                                                    return obj
                                                })]}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            const element = document.getElementById('datumOd');
                                                            if (element) {
                                                                element.focus();
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <Label>Naziv komitenta</Label>
                                                <Input disabled style={{ width: 300, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} type='text' value={this.state.filterClientName != null ? this.state.filterClientName : ''} />
                                            </div>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <DateField
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                })}
                                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom : null}
                                                label={'Datum od'}
                                                id={'datumOd'}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const element = document.getElementById('datumDo');
                                                        if (element) {
                                                            element.focus();
                                                        }
                                                    }
                                                }}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                })}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : null}
                                                label={'Datum do'}
                                                id={'datumDo'}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        const element = document.getElementById('artikal');
                                                        if (element) {
                                                            element.focus();
                                                        }
                                                    }
                                                }}
                                            ></DateField>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className={!this.state.reservation ? "blue-button" : "blue-button-outline"}
                                            style={{ width: 200 }} onClick={() => this.totalArticles()}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Pregled</p>
                                        </button>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal</Label>
                                            <Input id={'artikal'} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    const element = document.getElementById('grupa');
                                                    if (element) {
                                                        element.focus();
                                                    }
                                                }
                                            }} style={{ width: 150 }} type='text' value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : null} onChange={(e) => this.setState({ filterArticleFrom: e.target.value, articleError: null }, () => {
                                            })} />

                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Grupa</Label>
                                            <Input id={'grupa'} style={{ width: 200 }} type='text' value={this.state.filterGroup != null ? this.state.filterGroup : params.group} onChange={(e) => this.setState({ filterGroup: e.target.value }, () => {
                                            })} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'year', label: 'godina'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'month', label: 'mijesec'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'article', label: 'artikal'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'articleName', label: 'naziv'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'group', label: 'grupa'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'groupName', label: 'naziv grupe'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientCode', label: 'komt'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'komitent'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'IDnumber', label: 'pdv/id broj'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'postNumber', label: 'postanski broj'.translate(this.props.lang), multilang: false, allowSort: true, },
                                    { type: 'text', name: 'place', label: 'mijesto'.translate(this.props.lang), multilang: false, allowSort: true, },
                                    { type: 'text', name: 'address', label: 'adresa'.translate(this.props.lang), multilang: false, allowSort: true, },
                                    { type: 'text', name: 'region', label: 'regija'.translate(this.props.lang), multilang: false, allowSort: true, },
                                    { type: 'text', name: 'ammount', label: 'kolicina'.translate(this.props.lang), multilang: false, allowSort: true, },
                                    { type: 'text', name: 'value', label: 'iznos'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true },
                                ]}
                                items={this.state.items.map(item => {
                                    return {
                                        ...item,
                                        // documentNumber: <div onClick={() => {
                                        //     if(typeof window != 'undefined'){
                                        //         window.open(`/${item ? item.deliveryDate ? 'output' : item.nivelationDate ? 'nivelation' : item.returnDate ? 'supplier-return' : item.entraceDate ? 'entrance' : item.factureDate ? 'buyer-return' : '' : ''}?redirect=${item && item.documentNumber}`, "_blank");
                                        //     }
                                        // }}>{item && item.documentNumber}</div>,
                                        // date: moment.unix(item.sortDate).format('DD.MM.YYYY'),

                                        // quantityAverage: item.average ? item.average : '',
                                        // nivelationAmmount: item && item.nivelationDate && item.article ? item.article.ammount : 0,
                                        // price: item.sellPrice ? item.sellPrice : item.buyPrice ? item.buyPrice : item && item.entraceDate ? item.article.sellPrice : item.article.price,
                                        // valueIn: item && item.entraceDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.sellPrice) * 100) / 100 : item && item.factureDate && !item.deliveryDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : 0,
                                        // valueOut: item && item.deliveryDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : item && item.returnDate && item.article ? Math.round(Number(item.article.ammount) * Number(item.article.price) * 100) / 100 : 0,

                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>

                        <Col lg='12'>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno</h6>
                                    <h4>
                                        {numberWithCommas(Math.round((this.state.totalPrice ? this.state.totalPrice : 0) * 100) / 100)}
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);