
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import Loader from '../../components/loader';


import Checkbox from '../../form-builder/fields/checkbox';
import garbageOpionIcon from '../../assets/garbage-option.svg';

import DateField from '../../form-builder/fields/date';

import PrintHelper from '../../components/printHelper';


import env from "react-dotenv";

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

moment.locale('sr')


function numberWithCommas(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}

function getMonth(month) {
    let months = {
        '1': 'Januar', '2': 'Februar', '3': 'Mart', '4': 'April', '5': 'Maj', '6': 'Jun', '7': 'Jul', '8': 'Avgust', '9': 'Septembar', '10': 'Oktobar', '11': 'Novembar', '12': 'Decembar'
    };
    let monthName = '';
    month = String(month);
    if (months && months[month]) {
        monthName = months[month];
    }
    // if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(el => el == month).length) {
    //     month--;
    //     let date = new Date('01-01-2022');
    //     date.setMonth(month);
    //     if (date) {
    //         monthName = moment.unix(new Date(date).getTime() / 1000).format('MMMM')
    //     }
    // }


    return monthName;
}

class AnalyticsByClientsAndAccounts extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            activeButton: null,
            controled: true,
            totalByGroups: false,
            currency: 'km',
            params: {},
            fields: [],

        }
    }
    get = () => {

        if (this.state.activeButton) {

            this.checkFilters().then((res) => {
                let filters = res;
                let prevFilters = this.state.filters;
                if (JSON.stringify(prevFilters) != JSON.stringify(filters)) {
                    this.updateParams('page', 0)
                }
                this.setState({ filters })
                let params = this._getParams();
                let sortField = params.sortField;
                if (sortField == 'documentDate') {
                    sortField = 'documentDateTs';
                }
                if (sortField == 'valueDate') {
                    sortField = 'valueDateTs';
                }
                let obj = {
                    page: params.page,
                    entries: params.entries,
                    report: this.state.activeButton,
                    sortField: sortField,
                    sortType: params.sortType,
                    ...filters
                }



                this.setState({ loading: true }, () => {
                    this._apiAsync('get-items', obj).then((result) => {
                        if (result) {
                            this.setState({ ...result, loading: false })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                })
            })

        }




    }


    componentDidMount() {
        // this._onLoad();

        // this.get()

        // this.checkParams()
        this.setFields()


    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.props[0].location.search != prevProps[0].location.search) {
        //     this._onLoad();
        // }
        // if (prevState.key != this.state.key) {
        //     localStorage.setItem('searchKey', this.state.key)
        // }
        let params = this._getParams();
        if (JSON.stringify(params) != JSON.stringify(this.state.params)) {
            this.setState({ params }, this.get())
        }
        if (prevState.activeButton != this.state.activeButton) {
            this.setFields()
            this.updateParams('page', 0)
        }

    }
    checkFilters = async () => {
        let obj = {}
        if (this.state.filterDateFrom) {
            obj.dateFrom = this.state.filterDateFrom;
        }
        if (this.state.filterDateTo) {
            obj.dateTo = this.state.filterDateTo;
        }
        if (this.state.filterAmountFrom) {
            obj.amountFrom = this.state.filterAmountFrom;
        }
        if (this.state.filterAmountTo) {
            obj.amountTo = this.state.filterAmountTo;
        }
        if (this.state.filterPoPdv) {
            obj.popdv = this.state.filterPoPdv;
        }
        if (this.state.filterAccountFrom) {
            obj.accountFrom = this.state.filterAccountFrom;
        }
        if (this.state.filterAccountTo) {
            obj.accountTo = this.state.filterAccountTo;
        }
        if (this.state.filterMonth) {
            obj.month = this.state.filterMonth;
        }


        return obj;
    }
    checkParams = (get = false) => {
        let obj = {}
        let params = this._getParams()
        if (!this.state.filterDateFrom && params.dateFrom) {
            obj.filterDateFrom = params.dateFrom;
        }
        if (!this.state.filterDateTo && params.dateTo) {
            obj.filterDateTo = params.dateTo;
        }
        if (!this.state.filterAmountFrom && params.amountFrom) {
            obj.filterAmountFrom = params.amountFrom;
        }
        if (!this.state.filterAmountTo && params.amountTo) {
            obj.filterAmountTo = params.amountTo;
        }
        if (!this.state.filterMonth && params.month) {
            obj.filterMonth = params.month;
        }
        if (!this.state.filterPoPdv && params.popdv) {
            obj.filterPoPdv = params.popdv;
        }
        if (!this.state.filterAccountFrom && params.accountFrom) {
            obj.filterAccountFrom = params.accountFrom;
        }
        if (!this.state.filterAccountTo && params.accountTo) {
            obj.filterAccountTo = params.accountTo;
        }






        // this.setState({ ...obj }, () => {
        //     if (get)
        //         this.get()
        //     if (this.state.filterClientCode) {
        //         this._apiAsync('get-client', { code: this.state.filterClientCode }).then((res) => {
        //             if (res && res.name) {
        //                 this.setState({ clientName: res.name })
        //             } else {
        //                 this.setState({ clientName: '' })
        //             }
        //         })
        //     } else {
        //         this.setState({ clientName: '' })
        //     }
        // })

    }
    clearFilters = () => {
        this.setState({
            filterClientCode: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterAmountFrom: '',
            filterAmountTo: '',
            filterAccountFrom: '',
            filterAccountTo: '',
            filterMonth: '',
            filterPoPdv: '',
            currency: 'km',
            monthName: '',
            items: [],
            total: 0,
            activeButton: null,
            sumObj: null

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'popdv', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'amountFrom', value: null },
                    { name: 'amountTo', value: null },
                    { name: 'accountFrom', value: null },
                    { name: 'accountTo', value: null },
                    { name: 'month', value: null },


                ])

        })
    }

    checkAccountNumbers = () => {
        let data = {}

        if (this.state.filterAccount) {
            data.filterAccount = this.state.filterAccount;
        }

        this._apiAsync('get-account-numbers', data).then((result) => {
            let accountName = '';
            if (result && result.length) {
                accountName = result.join(', ')
            }
            this.setState({ accountName })
        })
    }
    onKeyDown = (e) => {
        if (e && e.key == 'Enter' && e.target && e.target.id) {
            let splitId = e.target.id.split('filter-input')
            if (splitId && splitId[1]) {
                let id = Number(splitId[1]);
                let nextId = `filter-input${id + 1}`
                let check = document.getElementById(nextId);
                if (check) {
                    document.getElementById(nextId).focus()
                } else {
                    document.getElementById(e.target.id).blur()
                    let activeButton = this.state.activeButton;
                    if (!activeButton) {
                        activeButton = 1;
                    }
                    this.setState({ activeButton }, this.get)

                }

            }
        }
    }

    setFields = () => {
        let fields = [];

        if (this.state.activeButton == 1 || this.state.activeButton == 6) {
            fields = [
                { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'financialAccount.documentNumber', label: 'NALOG'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                { type: 'text', name: 'itemNumber', label: 'RED'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'itemType', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'documentDate', label: 'DAT. NAL.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 30 },
                { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                // { type: 'text', name: 'documentDate', label: 'DAT. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                // { type: 'text', name: 'valueDate', label: 'VALUTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                // { type: 'text', name: 'billNumber', label: 'RAČUN'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'description', label: 'OPIS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 20 },
                { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'cumulativelyOwes', label: 'KUM. DUGUJE'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'cumulativelyAsking', label: 'KUM. POTRAŽUJE'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'balance', label: 'SALDO'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true, width: 'unset' },
            ];

        } else if (this.state.activeButton == 2 || this.state.activeButton == 3 || this.state.activeButton == 7) {
            fields = [
                { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'accountNumberName', label: 'NAZIV KONTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'sum', label: 'SALDO'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
            ];

        } else if (this.state.activeButton == 4 || this.state.activeButton == 5) {
            fields = [
                { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'accountNumberName', label: 'NAZIV KONTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 40 },
                { type: 'text', name: 'owesPs', label: 'DUGUJE PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'askingPs', label: 'POTRAŽUJE PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'owesNPs', label: 'DUGUJE BEZ PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'askingNPs', label: 'POTRAŽUJE BEZ PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'owes', label: 'DUGUJE SA PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'asking', label: 'POTRAŽUJE SA PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'sum', label: 'SALDO'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
            ];

        } else if (this.state.activeButton == 8) {
            fields = [
                { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                { type: 'text', name: 'clientName', label: 'NAZIV KOM.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', substr: 40 },
                { type: 'text', name: 'owesPs', label: 'DUGUJE PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'askingPs', label: 'POTRAŽUJE PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'owesNPs', label: 'DUGUJE BEZ PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'askingNPs', label: 'POTRAŽUJE BEZ PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'owes', label: 'DUGUJE SA PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'asking', label: 'POTRAŽUJE SA PS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                { type: 'text', name: 'sum', label: 'SALDO'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
            ];

        }

        this.setState({ fields })
    }

    export = () => {

        if (this.state.activeButton) {

            this.checkFilters().then((res) => {
                let filters = res;

                let params = this._getParams();
                let sortField = params.sortField;
                if (sortField == 'documentDate') {
                    sortField = 'documentDateTs';
                }
                if (sortField == 'valueDate') {
                    sortField = 'valueDateTs';
                }
                let obj = {
                    page: params.page,
                    entries: params.entries,
                    report: this.state.activeButton,
                    sortField: sortField,
                    sortType: params.sortType,
                    export: true,
                    currency: this.state.currency,
                    ...filters
                }



                this.setState({ loadingPage: true }, () => {
                    this._apiAsync('export-items', obj).then((result) => {
                        this.setState({ loadingPage: false })
                        if (result && result.success && result.file) {
                            window.location.href = env.API_URL + `${result.file}`;
                        }
                    })
                })
            })

        }
    }

    print = async () => {

        if (this.state.activeButton) {

            this.checkFilters().then((res) => {
                let filters = res;

                let params = this._getParams();
                let sortField = params.sortField;
                if (sortField == 'documentDate') {
                    sortField = 'documentDateTs';
                }
                if (sortField == 'valueDate') {
                    sortField = 'valueDateTs';
                }
                let obj = {
                    page: params.page,
                    entries: params.entries,
                    report: this.state.activeButton,
                    sortField: sortField,
                    sortType: params.sortType,
                    print: true,
                    currency: this.state.currency,
                    ...filters
                }



                this.setState({ loadingPage: true }, async () => {
                    await this._apiAsync('print-items', obj);
                    this.setState({ loadingPage: false })

                    // let printHtml = await this._apiAsync('print-items', obj);
                    // console.log(printHtml, printHtml && printHtml.printHtml)
                    // if (printHtml && printHtml.printHtml) {
                    //     console.log('if')
                    //     this.setState({ printHtml: printHtml.printHtml, loadingPage: false })
                    // } else {
                    //     console.log('else')
                    //     this.setState({ loadingPage: false, error: 'Došlo je do greške!' })
                    // }
                })
            })

        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                {/* {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                } */}
                <Loader loading={this.state.loadingPage} />
                <ErrorModal
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                    error={this.state.error}
                />

                <div className="section-title title-options">
                    <h1>Analitička kartica po kontima</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button onClick={() => this.print()} disabled={this.state.activeButton && this.state.items && this.state.items.length ? false : true} className={!(this.state.activeButton && this.state.items && this.state.items.length) ? 'disabled-button' : ''}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => this.export()} disabled={this.state.activeButton && this.state.items && this.state.items.length ? false : true} className={!(this.state.activeButton && this.state.items && this.state.items.length) ? 'disabled-button' : ''}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound analytics-report-wrap">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div className='filters-wrap'>
                                    <div className='filters-left'>
                                        <div className='filters-row-wrap'>
                                            <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>Mjesec</Label>
                                                    <div className='merged-fields'>
                                                        <Input id="filter-input1" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterMonth} onChange={(e) => this.setState({ filterMonth: e.target.value }, () => {
                                                            if (this.state.filterMonth) {
                                                                this.setState({ monthName: getMonth(this.state.filterMonth) })
                                                            } else {
                                                                this.setState({ monthName: '' })
                                                            }
                                                        })} />
                                                        <Input style={{ width: 230 }} type='text' value={this.state.monthName} disabled />
                                                    </div>
                                                </div>



                                            </div>


                                        </div>
                                        <div className='filters-row-wrap'>
                                            <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>


                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>Konto od</Label>
                                                    <Input id="filter-input2" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterAccountFrom} onChange={(e) => this.setState({ filterAccountFrom: e.target.value })} />
                                                </div>

                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>Konto do</Label>
                                                    <Input id="filter-input3" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterAccountTo} onChange={(e) => this.setState({ filterAccountTo: e.target.value })} />
                                                </div>

                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>POPDV</Label>
                                                    <Input id="filter-input4" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterPoPdv} onChange={(e) => this.setState({ filterPoPdv: e.target.value })} />
                                                </div>


                                            </div>


                                        </div>
                                        <div className='filters-row-wrap'>

                                            <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>

                                                <div className="field-strap-wrap" style={{ width: 150 }}>
                                                    <DateField
                                                        id="filter-input5"
                                                        onKeyDown={this.onKeyDown}
                                                        additionalClassName={'date-input-width-150'}
                                                        onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                            //this.updateParams('dateFrom', this.state.filterDateFrom)
                                                        })}
                                                        value={this.state.filterDateFrom}
                                                        label={'Datum od'}
                                                    ></DateField>
                                                </div>
                                                <div className="field-strap-wrap" style={{ width: 150 }}>
                                                    <DateField
                                                        id="filter-input6"
                                                        onKeyDown={this.onKeyDown}
                                                        additionalClassName={'date-input-width-150'}
                                                        onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                            //this.updateParams('dateTo', this.state.filterDateTo)
                                                        })}
                                                        value={this.state.filterDateTo}
                                                        label={'Datum do'}
                                                    ></DateField>
                                                </div>

                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>Iznos od</Label>
                                                    <Input id="filter-input7" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterAmountFrom} onChange={(e) => this.setState({ filterAmountFrom: e.target.value }, () => {
                                                        //this.updateParams('groupFrom', this.state.filterAmountFrom)
                                                    })} />
                                                </div>
                                                <div className="field-strap-wrap" style={{ width: 130 }}>
                                                    <Label>Iznos do</Label>
                                                    <Input id="filter-input8" onKeyDown={this.onKeyDown} style={{ width: 130 }} type='text' value={this.state.filterAmountTo} onChange={(e) => this.setState({ filterAmountTo: e.target.value }, () => {
                                                        //this.updateParams('groupTo', this.state.filterAmountTo)
                                                    })} />
                                                </div>





                                            </div>

                                        </div>

                                    </div>
                                    <div className='filters-right'>
                                        <div className='buttons-left'>
                                            <button
                                                className={this.state.activeButton == 1 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 1 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Analitika po kontu</p>
                                            </button>

                                            <button
                                                className={this.state.activeButton == 2 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 2 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans</p>
                                            </button>
                                            <button
                                                className={this.state.activeButton == 3 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 3 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans-analitika-sint.</p>
                                            </button>

                                            <button
                                                className={this.state.activeButton == 4 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 4 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans sa poc.st</p>
                                            </button>

                                        </div>
                                        <div className='buttons-right'>

                                            <button
                                                className={this.state.activeButton == 5 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 5 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans sa poc.st(sint)</p>
                                            </button>
                                            <button
                                                className={this.state.activeButton == 6 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 6 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Analitika po kontu - opis</p>
                                            </button>
                                            <button
                                                className={this.state.activeButton == 7 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 7 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans - sintetika</p>
                                            </button>

                                            <button
                                                className={this.state.activeButton == 8 ? "blue-button" : "blue-button-outline"}
                                                onClick={() => { this.setState({ activeButton: 8 }, () => this.get()) }}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>Bruto bilans komitent sa poc.st</p>
                                            </button>

                                        </div>


                                    </div>

                                </div>


                            </div>


                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            {
                                this.state.fields && this.state.fields.length ?
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        noWrap={true}
                                        maxHeight={'46vh'}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={this.state.fields}
                                        items={this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                sum: this.state.currency == 'eur' ? ((item.owes - item.asking) / 1.95) : (item.owes - item.asking),
                                                owes: this.state.currency == 'eur' ? (item.owes / 1.95) : item.owes,
                                                asking: this.state.currency == 'eur' ? (item.asking / 1.95) : item.asking,
                                                balance: this.state.currency == 'eur' ? (item.balance / 1.95) : item.balance,

                                            }
                                        })}

                                        rawItems={this.state.items}
                                        activeSubitem={this.state.activeSubitem}
                                        subItems={true}

                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :
                                    null
                            }

                        </Col>
                        <Col lg={{ size: 4, offset: 8 }}>
                            <div className='blue-box-info-text-wrap'>
                                <div className='blue-box-info-text'>
                                    <h6>Ukupno duguje:</h6>
                                    <h4>
                                        {this.state.sumObj && this.state.sumObj.owes ? numberWithCommas(this.state.currency == 'eur' ? (this.state.sumObj.owes / 1.95) : this.state.sumObj.owes) : '0'}
                                        &nbsp;{this.state.currency == 'eur' ? 'EUR' : 'KM'}
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno potražuje:</h6>
                                    <h4>
                                        {this.state.sumObj && this.state.sumObj.asking ? numberWithCommas(this.state.currency == 'eur' ? (this.state.sumObj.asking / 1.95) : this.state.sumObj.asking) : '0'}
                                        &nbsp;{this.state.currency == 'eur' ? 'EUR' : 'KM'}
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Saldo:</h6>
                                    <h4>
                                        {this.state.sumObj && this.state.sumObj.sum ? numberWithCommas(this.state.currency == 'eur' ? (this.state.sumObj.sum / 1.95) : this.state.sumObj.sum) : '0'}
                                        &nbsp;{this.state.currency == 'eur' ? 'EUR' : 'KM'}
                                    </h4>
                                </div>
                            </div>


                        </Col>



                    </Row>

                    {
                        this.state.preview ?
                            <Modal isOpen={this.state.preview} toggle={() => this.setState({ preview: false })} size='lg' className="modal-wrap-cold-chain-preview">
                                <ModalHeader>
                                    <div className='title'>Pregled</div>
                                    {/* <Button className="print-button"><div className="svg-box"><Isvg src={printIcon} /></div>Štampaj</Button> */}
                                </ModalHeader>
                                <ModalBody className="modal-body-list-builder-wrap">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        // total={this.state.total}
                                        hidePagination={true}
                                        showNumeration={false}
                                        fields={[

                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'type', label: 'Tip'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'documentNumber', label: 'BR.DOK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },

                                        ]}
                                        items={this.state.preview && this.state.preview.listOfItems.map((item, idx) => {
                                            return {
                                                ...item,
                                                // ordinalNumber: idx + 1
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.preview && this.state.preview.listOfItems ? this.state.preview.listOfItems : []}

                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>
                                <ModalFooter></ModalFooter>

                            </Modal>
                            :
                            null
                    }

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(AnalyticsByClientsAndAccounts);
