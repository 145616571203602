import Screen from './screen';
import Login from '../login/screen';
import API from './api';

export default {
    path: '/funds',
    auth: true,
    preAuthComponent: Login,
    component: Screen,
    api: API,
    seo: (data, lang) => {
        return {
            'Ttile': 'ICM d.o.o',
            'description': 'Country...'
        }
    }
}