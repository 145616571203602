
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

import Loader from '../../components/loader';

import env from "react-dotenv";

class KSSPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            kssList: true,
            total: 0,
            buttonActive: 1,
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();

        let params = this._getParams();
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    export = () => {
        this.setState({ loadingPage: true }, () => {
            let params = this._getParams();
            this.setState({ printHtml: null }, () => {
                this._apiAsync('export-kss', { ...params, action: 'export', kssValueExist: this.state.buttonActive == 3 ? true : false }).then((res) => {
                    this.setState({ loadingPage: false })
                    if (res && res) {
                        if (res.success) {
                            window.location.href = env.API_URL + `${res.file}`;
                        }
                    }
                })
            })
        })

    }
    print = async () => {
        let params = this._getParams();
        this.setState({ loadingPage: true }, async () => {
            await this._apiAsync('print-kss', { ...params, action: 'print', kssValueExist: this.state.buttonActive == 3 ? true : false });
            this.setState({ loadingPage: false })


        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <h1>KSS</h1>
                    <div className="title-options-group">
                        {
                            this.state.buttonActive == 2 ?
                                <button onClick={() => this.submit()}>
                                    <div className="option green">
                                        <Isvg src={saveIcon} />
                                    </div>
                                    <span>Snimi</span>
                                </button>
                                :
                                null
                        }

                        {
                            this.state.buttonActive == 1 || this.state.buttonActive == 3 ?
                                <>
                                    <button onClick={this.print}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={this.export}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button></>
                                :
                                null
                        }

                    </div>
                </div>

                {
                    <div className="table-tabs">
                        <div className="table-tabs-small">
                            <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ kssForm: false, kssList: true, kssEntry: false, buttonActive: 1 }, () => {
                                this.setState({
                                    filterDateFrom: '',
                                    filterDateTo: '',
                                    filterDocumentNumber: '',
                                    filterClient: '',
                                    filterUser: '',
                                    filterArticle: '',
                                    filterKSS: ''
                                }, () => {
                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'documentNumber', value: null }, { name: 'article', value: null }, { name: 'kss', value: null }])
                                })
                            })}>
                                Lista KSS
                            </button>
                            <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ kssForm: true, kssList: false, kssEntry: false, buttonActive: 2 }, () => {
                                this.setState({
                                    filterDateFrom: '',
                                    filterDateTo: '',
                                    filterDocumentNumber: '',
                                    filterClient: '',
                                    filterUser: '',
                                    filterArticle: '',
                                    filterKSS: ''
                                }, () => {
                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'documentNumber', value: null }, { name: 'article', value: null }, { name: 'kss', value: null }])
                                })
                            })}>
                                Dodaj KSS
                            </button>
                            <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={() => this.setState({ kssForm: false, kssList: false, kssEntry: true, buttonActive: 3 }, () => {
                                this.setState({
                                    filterDateFrom: '',
                                    filterDateTo: '',
                                    filterDocumentNumber: '',
                                    filterClient: '',
                                    filterUser: '',
                                    filterArticle: '',
                                    filterKSS: ''
                                }, () => {
                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'documentNumber', value: null }, { name: 'article', value: null }, { name: 'kss', value: null }])
                                })
                            })}>
                                Upisani KSS
                            </button>
                        </div>
                    </div>

                }

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12">
                            {
                                this.state.kssForm ?

                                    <FormBuilder
                                        registerSubmitAction={(submit) => this.submit = submit}
                                        classNameForm={"add-form-container"}
                                        // headerForm={"Artikal".translate(this.props.lang)}
                                        addButtonText={'Dodaj'.translate(this.props.lang)}
                                        onSubmit={(data) => {
                                            if (data && data._id) {
                                                this._api('update-kss', data, () => {
                                                    this.setState({ form: null, kssForm: false, buttonActive: 3, kssEntry: true }, () => this._onLoad())
                                                });
                                            }


                                        }}
                                        initialValues={this.state.form}
                                        fields={[
                                            {
                                                type: 'row',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        children: [
                                                            {
                                                                type: 'block',
                                                                header: 'Dodaj KSS'.translate(this.props.lang),
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        className: "custom-input-row kss-row-1",
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'entranceDate',
                                                                                label: 'Datum dokumenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'documentNumber',
                                                                                label: 'Broj dokumenta',
                                                                                textType: 'number',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                label: 'Komitent',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'articleName',
                                                                                label: 'Artikal',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'serialNumber',
                                                                                label: 'Serija',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'deadline',
                                                                                label: 'Rok',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                // textType: 'number',
                                                                                next: 'submitForm',
                                                                                name: 'kssValue',
                                                                                label: 'Unesi KSS',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "KSS mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]

                                                    },
                                                ]
                                            },


                                        ]}
                                    ></FormBuilder>

                                    :

                                    null

                            }
                        </Col>

                        <Col lg="12" style={{ paddingLeft: 0 }}>
                            {this.state.kssList ?
                                <>
                                    <div className="list-builder-filters-wrap-output">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterDocumentNumber != null ? this.state.filterDocumentNumber : params.documentNumber} onChange={(e) => this.setState({ filterDocumentNumber: e.target.value }, () => {
                                                    this.updateParams('documentNumber', this.state.filterDocumentNumber)
                                                })} placeholder={'Broj dokumenta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterArticle != null ? this.state.filterArticle : params.article} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                    this.updateParams('article', this.state.filterArticle)
                                                })} placeholder={'Artikal'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterDocumentNumber: '',
                                                filterClient: '',
                                                filterUser: '',
                                                filterArticle: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'documentNumber', value: null }, { name: 'article', value: null }, { name: 'kss', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>

                                    </div>
                                    <div className="mt-15">
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            hideFilters={true}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            // hidePagination={true}
                                            fields={[
                                                { type: 'text', name: 'ordinalNumber', label: 'R. BR.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'entranceDate', label: 'DATUM DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'articleName', label: 'ARTIKAL'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                                { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },


                                            ]}
                                            actions={
                                                [
                                                    {
                                                        component: <Isvg src={editIcon} />,
                                                        onClick: async (item) => {
                                                            console.log(item)
                                                            this.setState({ form: null, kssForm: true, kssList: false, kssEntry: false, buttonActive: 2 }, () => {
                                                                this.setState({ form: item })
                                                            })
                                                        }
                                                    },

                                                ]
                                            }


                                            items={this.state.items}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    </div>

                                </>
                                :
                                null
                            }
                        </Col>

                        <Col lg="12" style={{ paddingLeft: 0 }}>
                            {
                                this.state.kssEntry ?
                                    <>
                                        <div className="list-builder-filters-wrap-output">
                                            <div className="list-builder-filters">
                                                <div className="field-strap-wrap">
                                                    <Input type='date' value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                        this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='date' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                        this.updateParams('dateTo', this.state.filterDateTo)
                                                    })} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterDocumentNumber != null ? this.state.filterDocumentNumber : params.documentNumber} onChange={(e) => this.setState({ filterDocumentNumber: e.target.value }, () => {
                                                        this.updateParams('documentNumber', this.state.filterDocumentNumber)
                                                    })} placeholder={'Broj dokumenta'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                        this.updateParams('client', this.state.filterClient)
                                                    })} placeholder={'Komitent'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterArticle != null ? this.state.filterArticle : params.article} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                        this.updateParams('article', this.state.filterArticle)
                                                    })} placeholder={'Artikal'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterKSS != null ? this.state.filterKSS : params.kss} onChange={(e) => this.setState({ filterKSS: e.target.value }, () => {
                                                        this.updateParams('kss', this.state.filterKSS)
                                                    })} placeholder={'KSS'} />

                                                </div>
                                            </div>

                                            <div className="clear-filters" onClick={() => {
                                                this.setState({
                                                    filterDateFrom: '',
                                                    filterDateTo: '',
                                                    filterDocumentNumber: '',
                                                    filterClient: '',
                                                    filterUser: '',
                                                    filterArticle: '',
                                                    filterKSS: ''
                                                }, () => {
                                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'documentNumber', value: null }, { name: 'article', value: null }, { name: 'kss', value: null }])
                                                })
                                            }}>
                                                <a><span>&times;</span> Ukloni filter</a>
                                            </div>

                                        </div>
                                        <div className="mt-15">
                                            <ListBuilder
                                                tabs={false}
                                                sort={false}
                                                hideFilters={true}
                                                lang={this.props.lang}
                                                loading={this.state.loading}
                                                total={this.state.totalEntry}
                                                showNumeration={false}
                                                // hidePagination={true}
                                                fields={[
                                                    { type: 'text', name: 'ordinalNumber', label: 'R. BR.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'entranceDate', label: 'DATUM DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'articleName', label: 'ARTIKAL'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'kssValue', label: 'KSS'.translate(this.props.lang), multilang: false, allowSort: true },

                                                ]}


                                                items={this.state.itemsEntry}
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            ></ListBuilder>
                                        </div>

                                    </>
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(KSSPage);
