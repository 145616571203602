import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType ? query.sortType : '1',
                    sortField: query.sortField ? query.sortField : 'article',
                    onlyEdited: query.allSeries ? false : true

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'print-export-series': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    action: query.action

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'update-series': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                // if (status >= 200 && status < 300)
                //     return {
                //         result: result,
                //     }
            })
        }
    },
    'remove-series': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                // if (status >= 200 && status < 300)
                //     return {
                //         result: result,
                //     }
            })
        }
    },

    'validate-serial-number': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series/validate/serialNumber/' + data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'api2': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/subgroups/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/subgroups/validate/' + data.value + '/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'ariclesSearch':{
        onLoad : false,
        action : (query)=>{
            return fetch(env.API_URL + '/data/products/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.value,
                    institutionType: query && query.institutionType ? query.institutionType : null,
                    sortField: "code",
                    sortType: "1",
                    withStock : true
                })
            }).then(parseJSON).then((response) =>{
                return {
                    articlesSearch : response.result.items,
                    __filter: response.result.filter
                }
            })
        }
    },

}