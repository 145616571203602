
import React, { Component } from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DateField from '../../form-builder/fields/date';
import moment from 'moment';
import File from '../../form-builder/fields/file';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PostingOfStatements extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            items: [],
            statement: '',
            accountNumber: '',
            date: '',
            filterType: 'prokredit'
        }
    }
    get = () => {
       
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this.checkData();
        }

    }


    clearFilters = () => {
        this.setState({
            importedFile: null,
            statement: '',
            accountNumber: '',
            date: '',
            filterType: 'prokredit',
            items: [],
            total: 0
        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'sortField', value: null },
                    { name: 'sortType', value: null },
                    { name: 'entries', value: null },
                    { name: 'page', value: null }
                ])

        })
    }
    handleEnter = (event) => {
        if (event.key.toLowerCase() == 'enter') {
            const currentField = Number(document.activeElement.id.split('-')[1])

            const nextField = currentField + 1
            document.getElementById(document.activeElement.id.split('-')[0] + '-' + nextField.toString()).focus()
        }
    }

    toPost = () => {

        const items = this.state.items;

        if (items?.length) {
            this.setState({ toPostLoading: true }, () => {
                this._apiAsync('to-post-data', { items }).then((response) => {

                    this.setState({ toPostLoading: false })
                    console.log(response)
                })
            })

        }
    }


    checkData = () => {
        if (this.state.importedFile) {
            let params = this._getParams();
            let data = {
                file: this.state.importedFile,
                type: this.state.filterType
            }
            if (this.state.statement) data.statement = this.state.statement;
            if (this.state.accountNumber) data.accountNumber = this.state.accountNumber;
            if (this.state.date) data.date = this.state.date;
            if (params.sortField) data.sortField = params.sortField;
            if (params.sortType) data.sortType = params.sortType;

            this._apiAsync('check-uploaded-data', data).then((response) => {
                const items = response?.result?.items ?? [];
                const total = response?.result?.total ?? 0;

                this.setState({ items, total })
            })

        } else {
            this.setState({ items: [], total: 0 })
        }
    }

    render() {

        let params = this._getParams();

        let toPostButtonDisabled = true;

        if (this.state.items?.length && !this.state.toPostLoading) {
            toPostButtonDisabled = false;
        }
        const showFilters = this.state.importedFile ? true : false;
        return (
            <div>
                <ErrorModal
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                    error={this.state.error}
                />

                <div className="section-title title-options">
                    <h1>Knjiženje izvoda</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>

                                            <Label>Tip</Label>
                                            <Input
                                                type='select'
                                                id='bookkeeping-3'
                                                style={{ width: 170 }}
                                                value={this.state.filterType}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    if (value !== 'novabanka') {
                                                        value = 'prokredit';
                                                    }
                                                    this.setState({ filterType: value }, () => {
                                                        this.checkData()
                                                    })
                                                }}>
                                                <option value={'prokredit'}>PROKREDIT</option>
                                                <option value={'novabanka'}>IZVODI NOVA BANKA</option>

                                            </Input>
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 150 }}>

                                            <Label>Import</Label>
                                            <File
                                                onChange={(value) => {
                                                    this.setState({ importedFile: value }, () => {
                                                        this.checkData()
                                                    })
                                                }}
                                                endpoint={'/data/postingOfStatements/upload'}
                                                value={this.state.importedFile}
                                                label={'UPLOAD'}
                                            ></File>
                                        </div>

                                        {showFilters ?
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <Label>Izvod</Label>
                                                <Input
                                                    type='text'
                                                    id='field-2'
                                                    style={{ width: 170 }}
                                                    value={this.state.statement}
                                                    onKeyDown={this.handleEnter}
                                                    onChange={(e) => this.setState({ statement: e.target.value }, () => {
                                                        this.checkData()
                                                    })}>

                                                </Input>
                                            </div>
                                            : null
                                        }
                                        {showFilters ?
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <Label>Nalog</Label>
                                                <Input
                                                    type='text'
                                                    id='field-3'
                                                    style={{ width: 170 }}
                                                    value={this.state.accountNumber}
                                                    onKeyDown={this.handleEnter}
                                                    onChange={(e) => this.setState({ accountNumber: e.target.value }, () => {
                                                        this.checkData()
                                                    })}>

                                                </Input>
                                            </div>
                                            : null
                                        }
                                        {showFilters ?
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <DateField
                                                    id='field-4'
                                                    additionalClassName={'date-input-width-150'}
                                                    onChange={(value) => this.setState({ date: value }, () => {
                                                        this.checkData()
                                                    })}
                                                    // onKeyDown={this.handleEnter}
                                                    value={this.state.date}
                                                    label={'Datum'}
                                                ></DateField>

                                            </div>
                                            : null
                                        }

                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <button
                                            disabled={viewButtonDisabled}
                                            className={viewButtonDisabled ? "blue-button blue-button-disabled" : "blue-button"}
                                            onClick={() => {
                                                if (!viewButtonDisabled) {
                                                    this.get()
                                                }
                                            }}
                                        >
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>PRIKAŽI</p>
                                        </button> */}
                                        <button
                                            disabled={toPostButtonDisabled}
                                            className={toPostButtonDisabled ? "blue-button blue-button-disabled" : "blue-button"}
                                            onClick={() => {
                                                if (!toPostButtonDisabled) {
                                                    this.toPost()
                                                }
                                            }}
                                        >
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>PROKNJIŽI</p>
                                        </button>
                                    </div>

                                </div>


                            </div>

                        </Col>
                        <>
                            <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    noWrap={true}
                                    maxHeight={'65vh'}
                                    hideFilters={true}
                                    hidePagination={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'clientName', label: 'KLIJENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'bankAccount', label: 'BROJ RAČUNA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'description', label: 'OPIS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item

                                        }
                                    })}

                                    rawItems={this.state.items}
                                    activeSubitem={this.state.activeSubitem}
                                    subItems={true}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </Col>
                            
                        </>
                    </Row>

                </Container>

            </div >
        )
    }
}

export default Page(PostingOfStatements);
