
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class ReportCategoriesAndManufacturers extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            outputItems: [],
            manufacturers: [],
            clientsSearch: [],
            total: [],
            loading: false

        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
            this._apiAsync('get-reports', { ...this.state.form, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }) }).then((response) => {
                this.setState({ outputItems: response.outputItems, total: response.total })
                // this.setState({ total: response.total })
            })
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        if (!value && this.registerGetValue && this.registerGetValue('clientCode')) {
            value = this.registerGetValue('clientCode');
        }
        clientList = await this._apiAsync('clientsSearch', { value: value })

        if (this.registerGetValue && this.registerGetValue('clientCode')) {
            if (this.registerGetValue('clientCode') == value) {
                this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
            }
        } else {
            this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
        }

    }

    metodaBusiness = async (value) => {

        const query = { client: this.registerGetValue && this.registerGetValue('clientCode') };
        const businessUnits = await this._apiAsync('get-business-units', { filter: value, clientCode: query.client })

        if (this.registerGetValue && value === this.registerGetValue('businessUnit'))
            this.setState({ businessSearch: businessUnits && businessUnits.items, businessUnits: businessUnits && businessUnits.items })
    }

    print = () => {
        this.setState({ loading: true })
        this._apiAsync('print', this.state.form).then((response) => {
            if (typeof window != 'undefined') {
                window.open(env.API_URL + response.link)
                this.setState({ loading: false })
            }
        })
        return "open-modal"
    }

    export = () => {
        this.setState({ loading: true })
        this._apiAsync('print', this.state.form).then((response) => {
            if (typeof window != 'undefined') {
                window.open(env.API_URL + response.link)
                this.setState({ loading: false })
            }
        })
        return "open-modal"
    }


    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet po kategorijama i proizvođačima</h1>
                    <div className="title-options-group">
                        <button disabled={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? false : true} onClick={this.print}>
                            <div className={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? "option blue" : "option disabled"}>
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button disabled={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? false : true} onClick={this.export}>
                            <div className={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? "option blue" : "option disabled"} onClick={this.export}>
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12" style={{ marginTop: 10 }}>
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article', 'clientCode', 'businessUnit', 'businessName']}
                                fieldTracker={async (name, value) => {
                                    // console.log(name, value)

                                    if (name == 'clientCode' && !value) {

                                        this.setState({ businessUnits: [] })
                                        this.remoteOnChange('clientName', '')
                                        this.setState({ enableEdit: false })
                                        this.remoteOnChange('businessUnit', '')


                                    }

                                    if (name == 'clientCode' && value) {
                                        const clientList = await this._apiAsync('clientsSearch', { value: value })

                                        const clientObject = clientList && clientList.clientsSearch && clientList.clientsSearch.find(item => Number(item.code) === Number(value))
                                        // this.setState({ selectedClient: clientObject })

                                        if (clientObject) {
                                            this.remoteOnChange('clientName', clientObject.name)
                                            const businessUnits = await this._apiAsync('get-business-units', { filter: '', clientCode: clientObject.code })

                                            this.setState({ businessSearch: businessUnits && businessUnits.items, businessUnits: businessUnits && businessUnits.items })
                                        } else {
                                            this.remoteOnChange('clientName', '')
                                        }

                                    }

                                    if (name == 'businessUnit' && value) {

                                        if (this.state.businessUnits.filter(item => item.code == value).length) {
                                            this.remoteOnChange('businessUnitName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                        } else {
                                            this.remoteOnChange('businessUnitName', '')
                                        }


                                    }

                                    if (name == 'businessUnit' && !value) {
                                        this.remoteOnChange('businessUnitName', '')
                                    }


                                }}
                                initialValues={this.state.form}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data, typeButton) => {
                                    this.setState({
                                        form: data
                                    })
                                    if (typeButton) {
                                        data.typeButton = typeButton
                                    }
                                    // console.log(data)

                                    this._apiAsync('get-reports', data).then((response) => {
                                        this.setState({ outputItems: response.outputItems, total: response.total, totalAmount: response.totalAmount, discount: response.discount, loading: false })
                                    })
                                    return "open-modal"

                                }}

                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 10, sm: 10, xs: 10 },
                                                        className: "custom-input-row categories-manufacturers-row",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'client',
                                                                                name: 'clientCode',
                                                                                next: 'businessUnit',
                                                                                label: 'Šifra',
                                                                                metoda: (value) => { this.metodaClient(value) },
                                                                                dataModal: this.state.clientsSearch,
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clients = await this._apiAsync('get-clients', { filter: value })
                                                                                                if (clients && clients.items) {
                                                                                                    this.setState({ clients: clients && clients.items }, () => {
                                                                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('clientName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                    // required('Dobavljač mora biti unešen!'),
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'businessUnit',
                                                                                label: 'Naziv komitenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'businessUnit',
                                                                                name: 'businessUnit',
                                                                                next: 'dateFrom',
                                                                                textType: 'number',
                                                                                metoda: (value) => { this.metodaBusiness(value) },
                                                                                dataModal: this.state.businessSearch,
                                                                                values: [...this.state.businessUnits.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                label: 'Poslovna ',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                // readOnly: this.state.enableEdit ? false : true,
                                                                                // readOnly: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                    (value) => {
                                                                                        if (this.state.businessUnits.length != 0) {
                                                                                            if (!value) {
                                                                                                return "Unesite poslovnu jedinicu!"
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    (value) => {
                                                                                        if (value) {
                                                                                            if (this.state.businessSearch.length == 0 && this.state.businessUnits.filter(item => item.code == value).length == 0 && this.state.businessUnits.filter(item => item.name == value).length == 0) {
                                                                                                return "Neispravan unos!"
                                                                                            }
                                                                                            if (this.state.businessSearch.length != 0 && this.state.businessSearch.filter(item => item.code == value).length == 0 && this.state.businessSearch.filter(item => item.name == value).length == 0) {
                                                                                                return "Neispravan unos!!"
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'businessUnitName',
                                                                                next: 'dateFrom',
                                                                                label: 'Naziv poslovne jedinice',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: 'dateTo',
                                                                label: 'Datum od',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateTo',
                                                                next: 'manufacturer',
                                                                label: 'Datum do',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 4, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'manufacturer',
                                                                        // next: 'percent',
                                                                        label: 'Proizvođač',
                                                                        asyncValidation: true,
                                                                        values: [{ name: 'Izaberite proizvođača', value: -1 }, ...this.state.manufacturers.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        validate: [
                                                                            // required('Proizvođač mora biti unešen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true
                                                                                    })
                                                                                    this.submit('client')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>IZVJEŠTAJ</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true
                                                                                    })
                                                                                    this.submit('business')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>IZVJEŠTAJ SA POSLOVNIM</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 10 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'manufacturerCode', label: 'ŠIFRA PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'manufacturerName', label: 'NAZIV PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsDateFrom', label: 'DATUM OD'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsDateTo', label: 'DATUM DO'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsPercent', label: 'PROCENAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'conditionName', label: 'KATEGORIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'conditionsNumberOfDays', label: 'BROJ DANA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'totalFormat', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountValue', label: 'UMANJENJE'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'totalAll', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.outputItems.map((item, idx) => {
                                        console.log(item.discountValue)
                                        return {
                                            ...item,
                                            totalFormat: item && item.total ? numberWithCommas(item.total.toFixed(2)) : 0,
                                            discountValue: item && item.discountValue ? numberWithCommas(item.discountValue) : 0,
                                            totalAll: item && item.total && item.discountValue ? numberWithCommas((item.total - item.discountValue).toFixed(2)) : numberWithCommas(item.total.toFixed(2))

                                        }
                                    })}

                                    // items={this.state.outputItems}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Iznos</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount != 0 ?

                                                numberWithCommas(parseFloat(this.state.totalAmount).toFixed(2))

                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Umanjenje</p>
                                    <span>
                                        {
                                            this.state.discount && this.state.discount != 0 ?
                                                numberWithCommas(parseFloat(this.state.discount).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalAmount - (this.state.discount ? this.state.discount : 0)).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(ReportCategoriesAndManufacturers);
