import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: false,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/subgroups', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     'api2': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/subgroups/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/subgroups/validate/' + data.value + '/' + data.id , {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    // =============================================================================================================================

    'checkDocumentNumber' : {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/bookDebit/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'checkNextDocumentNumber': {
        onLoad: true,
        action: (data) => {
            console.log('data sa data.....', data);
            return fetch(env.API_URL + '/data/bookDebit/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },

    'insert-new': {
        onLoad: false,
        action: (data) => {
            console.log('Podaci za upis...', data)
            return fetch(env.API_URL + '/data/bookDebit/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log('resultat posle upisa...', result)
                if (status >= 200 && status < 300)
                    return {
                        result: result
                    }
            })
        }
    },

    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-facture': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/bookDebit/factureNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                console.log('VRACENA FAKTURA API, API, API....',result)
                if (status >= 200 && status < 300){
                    return {
                        items: result.items,
                    }
                }else{
                    return{
                        items:false
                    }
                }

            })
        }
    },

    'get-ko': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/bookDebit', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        koNumber: query && query.koNumber,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        class: query && query.class,
                        subClass: query && query.subClass,
                        basic: query && query.basic
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },



    'get-factureClient': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/bookDebit/factureNumberClient', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                console.log('VRACENA FAKTURA API, API, API....',result)
                if (status >= 200 && status < 300){
                    return {
                        items: result,
                    }
                }else{
                    return{
                        items:false
                    }
                }

            })
        }
    },

    'updateKt': {
        onLoad: false,
        action: (data) => {
            console.log('Prosledjen broj KT...', data)
            return fetch(env.API_URL + '/data/bookDebit/updateKt', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log('resultat citanja KT...', result)
                if (status >= 200 && status < 300)
                    return {
                        result: result
                    }
            })
        }
    },

    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 5,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },
    'generic-export': {
        onLoad: false,
        action: (request) => {
            return fetch(env.API_URL + '/data/generic/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(request)
            }).then(parseJSON).then(({ result }) => {
                return result;
            })
        }
    },
    'generic-print': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/generic/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let  iframe =  document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function() {
                                setTimeout(function() {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                        });
                    }
                })
        }
    },


}
