
import React, { Component } from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DateField from '../../form-builder/fields/date';
import moment from 'moment';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Bookkeeping extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            controled: true,
            totalByGroups: false,
            accountNumber: [],
            sumOwes: 0,
            sumAsking: 0,
            clientCodeLinks: false,
            date: moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
        }
    }
    get = () => {
        if (this.state.filterDateFrom && this.state.filterDateTo) {
            let dateFrom = this.props.getTsFromDateString(this.state.filterDateFrom)
            let dateTo = this.props.getTsFromDateString(this.state.filterDateTo)

            if (!dateFrom) {
                this.setState({ error: 'Početni datum je neispravan' })
            } else if (!dateTo) {
                this.setState({ error: 'Krajnji datum je neispravan' })
            } else if (dateTo > dateFrom || this.state.filterDateFrom === this.state.filterDateTo) {
                this.updateMultipleParams([
                    { name: 'dateFrom', value: this.state.filterDateFrom },
                    { name: 'dateTo', value: this.state.filterDateTo },
                    { name: 'change', value: this.state.filterTypeOfChange },
                    { name: 'ts', value: new Date().getTime() }
                ])
            } else {
                this.setState({ error: 'Početni datum mora biti manji od krajnjeg datuma' })
            }

        } else {
            this.setState({ error: 'Datum je obavezan' })
        }
    }

    stampa = () => {
        console.log('parametri.....', this._getParams());
        let params = this._getParams()
        console.log('PARAAMETRI......', params);
        let title = "Promet po grupama"
        this._apiAsync('stampaApi', { title: title, params: params }).then((response) => {
            const file = new Blob([response], {
                type: "application/pdf"
            })

            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)

        })
    }

    componentDidMount() {
        // this._onLoad();

        // this.get()

        this.checkParams()



    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (prevState.key != this.state.key) {
            localStorage.setItem('searchKey', this.state.key)
        }
    }

    checkParams = (get = false) => {
        let obj = {}
        let params = this._getParams()
        if (!this.state.filterDateFrom && params.dateFrom) {
            obj.filterDateFrom = params.dateFrom;
        }
        if (!this.state.filterDateTo && params.dateTo) {
            obj.filterDateTo = params.dateTo;
        }

        if (!this.state.filterTypeOfChange && params.change) {
            obj.filterTypeOfChange = params.change;
        }
        this.setState({ ...obj }, () => {
            if (get)
                this.get()

        })

    }

    clearFilters = () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',
            filterTypeOfChange: '',
        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'change', value: null }
                ])

        })
    }
    handleEnter = (event) => {

        if (event.key.toLowerCase() == 'enter') {
            const currentField = Number(document.activeElement.id.split('-')[1])

            const nextField = currentField + 1
            document.getElementById(document.activeElement.id.split('-')[0] + '-' + nextField.toString()).focus()
        }
    }

    probook = () => {

        let data = {
            accountNumber:  this.state.accountNumber, 
            dateAccount: this.state.date,
            items: this.state.items
        }

        this._api('new-account-probook', { data }, () => {
          this._onLoad()
        });
    }

    render() {

        let params = this._getParams();

        let viewButtonDisabled = true;
        let bookkeepingButtonDisabled = true;

        if (this.state.filterTypeOfChange > 0 && this.state.filterDateFrom && this.state.filterDateTo) {
            viewButtonDisabled = false;
        }

        if (this.state.items?.length) {
            bookkeepingButtonDisabled = false;
        }
        return (
            <div>
                <ErrorModal
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}
                    error={this.state.error}
                />

                <div className="section-title title-options">
                    <h1>Knjiženje</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>

                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterDateFrom: value })}
                                                value={this.state.filterDateFrom}
                                                label={'Datum od'}
                                                id='bookkeeping-1'
                                                onKeyDown={this.handleEnter}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterDateTo: value })}
                                                value={this.state.filterDateTo}
                                                label={'Datum do'}
                                                id='bookkeeping-2'
                                                onKeyDown={this.handleEnter}
                                            ></DateField>
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 150 }}>

                                            <Label>Vrsta promjene</Label>
                                            <Input
                                                type='select'
                                                id='bookkeeping-3'
                                                style={{ width: 170 }}
                                                value={this.state.filterTypeOfChange}
                                                onChange={(e) => this.setState({ filterTypeOfChange: e.target.value })}>
                                                <option value={-1}></option>
                                                <option value={1}>Ulaz</option>
                                                <option value={2}>Izlaz</option>
                                                <option value={3}>Nivelacija</option>
                                                <option value={4}>Povrat kupca</option>
                                                <option value={5}>Povrat dobavljača</option>
                                                <option value={6}>Inventura</option>
                                                <option value={7}>Naturalni rabat</option>
                                            </Input>
                                        </div>
                                        {this.state.items && this.state.items.length ?
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <Label>Nalog</Label>
                                                <Input
                                                    type='text'
                                                    id='bookkeeping-4'
                                                    style={{ width: 170 }}
                                                    value={this.state.accountNumber}
                                                    onChange={(e) => this.setState({ accountNumber: e.target.value })}>

                                                </Input>
                                            </div>
                                            : null
                                        }
                                        {this.state.items && this.state.items.length ?
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <Label>Datum</Label>
                                                <Input
                                                    type='text'
                                                    id='bookkeeping-4'
                                                    style={{ width: 170 }}
                                                    value={this.state.date}
                                                    onChange={(e) => this.setState({ date: e.target.value })}>

                                                </Input>
                                            </div>
                                            : null
                                        }

                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button
                                            disabled={viewButtonDisabled}
                                            className={viewButtonDisabled ? "blue-button blue-button-disabled" : "blue-button"}
                                            onClick={() => {
                                                if (!viewButtonDisabled) {
                                                    this.get()
                                                }
                                            }}
                                        >
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>PRIKAŽI</p>
                                        </button>
                                        <button
                                            disabled={bookkeepingButtonDisabled}
                                            className={bookkeepingButtonDisabled ? "blue-button blue-button-disabled" : "blue-button"}
                                            onClick={() => {
                                                if (!bookkeepingButtonDisabled) {
                                                    this.probook()
                                                }
                                            }}
                                        >
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>PROKNJIŽI</p>
                                        </button>
                                    </div>

                                </div>


                            </div>

                        </Col>
                        <>
                            <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    noWrap={true}
                                    maxHeight={'65vh'}
                                    hideFilters={true}
                                    hidePagination={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'subgroupCode', label: 'NALOG'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'rbr', label: 'RBR'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'typeBookkeeping', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'clientCode', label: 'ŠIFRA KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'description', label: 'OPIS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'konto', label: 'KONTO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset'  },
                                        { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            clientCode: item.clientLinks ? item.clientCode : '',
                                            subgroupCode: this.state.accountNumber,
                                        }
                                    })}

                                    rawItems={this.state.items}
                                    activeSubitem={this.state.activeSubitem}
                                    subItems={true}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </Col>
                            <Col lg={{ size: 4, offset: 8 }}>
                                <div className='blue-box-info-text-wrap'>
                                    <div className='blue-box-info-text'>
                                        <h6>Ukupno</h6>
                                        <h4>
                                            {numberWithCommas(Math.abs(Math.round(((this.state.sumOwes ? this.state.sumOwes : 0) - (this.state.sumAsking ? this.state.sumAsking : 0)) * 100) / 100))}

                                            &nbsp;
                                        </h4>
                                    </div>
                                    <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                        <h6>Ukupno duguje</h6>
                                        <h4>
                                            {this.state.sumOwes ? numberWithCommas(this.state.sumOwes) : 0}
                                            &nbsp;
                                        </h4>
                                    </div>
                                    <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                        <h6>Ukupno potražuje</h6>
                                        <h4>
                                            {this.state.sumAsking ? numberWithCommas(this.state.sumAsking) : 0}
                                            &nbsp;
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                        </>
                    </Row>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal> */}
                </Container>

            </div >
        )
    }
}

export default Page(Bookkeeping);
