import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';

class NaturalDiscountPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clientList: true,
            clients: [],
            products: [],
            clientForm: false,
            entryOut: true,
            buttonActive: 2,
            items: [
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
            ]

        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.props)

        return (
            <div>

                <div className="section-title title-options">
                    <h1>Lista naturalnog rabata</h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-group">
                                <button>
                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                            </div>
                            :
                            null
                    }
                    {/*
                    <div className="title-options-group">
                        <button>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option red">
                                <Isvg src={deleteIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>         
                    */}
                </div>

                {
                    this.state.entryOut ?
                        <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}> 
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ articleState: true, articleList: false, articleForm: false, buttonActive: 1 })}>
                                    Lista naturalnih rabata
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ articleList: true, articleState: false, articleForm: false, buttonActive: 2 })}>
                                    Dodaj naturalni rabat
                                </button>
                            </div>

                            {
                                this.state.buttonActive == 1 ?

                                <div className="show-unfinished">
                                    <Input type="checkbox" />
                                    <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                                </div>
                                :
                                null
                            }

                        </div>
                        
                        :
                        null
                }
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    classNameForm={"add-form-container natural-discount-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    onSubmit={(data) => {
                                        console.log('data', data);
                                    }}
                                    initialValues={this.state.articleForm ? this.state.articleForm : { /*firstName:'Milan' */ }}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        },
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            className: 'lock-icon-container',
                                                                            children: <div className="lock-icon">
                                                                                <Isvg src={lockIcon} />
                                                                            </div>,
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                            className: 'options-button'
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Export</span></div>,
                                                                                            className: 'options-button'
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                            className: 'options-button'
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-4",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'entraceDate',
                                                            label: 'Broj dok.',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'factureDate',
                                                            next: 'currency',
                                                            label: 'Datum fakturisanja',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'deliveryDate',
                                                            next: 'currency',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum isporuke mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'selectWithSearch',
                                                                            name: 'clientCode',
                                                                            next: 'business',
                                                                            label: 'Šifra kom.',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            const clients = await this._apiAsync('get-clients', {filter: value})
                                                                                            if (clients && clients.items) {
                                                                                                this.setState({clients: clients && clients.items}, () => {
                                                                                                    if (this.state.clients.filter(item => item.code == value).length) {
                                                                                                        this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                                                                    } else {
                                                                                                        this.remoteOnChange('clientName', '')
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    }catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            // next: 'business',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'business',
                                                                            // next: 'facture',
                                                                            label: 'Poslovna',
                                                                            finishField: true,
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessName',
                                                                            // next: 'facture',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'facture',
                                                            next: 'currency',
                                                            label: 'Faktura',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum isporuke mora biti unesen!" }
                                                            ]
                                                        },



                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-3",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'oridnalNumber',
                                                            next: 'article',
                                                            label: 'Redni broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Redni broj mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'article',
                                                            next: 'serialNumber',
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Artikal mora biti unesen!" },
                                                                async (value) => {
                                                                    try {
                                                                        if (value) {
                                                                            let products = await this._apiAsync('get-articles', { filter: value })
                                                                            if (products && products.items) {
                                                                                this.setState({ products: products && products.items })
                                                                            }

                                                                            return undefined;
                                                                        }

                                                                    } catch (e) {
                                                                        return undefined;
                                                                    }
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'select',
                                                            name: 'serialNumber',
                                                            next: 'kss',
                                                            values: [{ name: '', value: -1 }],
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            asyncValidation: false,
                                                        },

                                                        {
                                                            type: 'select',
                                                            name: 'deadline',
                                                            next: 'ammount',
                                                            values: [{ name: '', value: -1 }],
                                                            label: 'Rok',
                                                            finishField: true,
                                                            asyncValidation: false,

                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'ammount',
                                                                            next: 'facturePrice',
                                                                            label: 'Količina',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Količina mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'stock',
                                                                            next: 'discount',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'price',
                                                                            next: 'discount',
                                                                            label: 'Cijena',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Cijena mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'stockPrice',
                                                                            next: 'discount',
                                                                            label: 'Lager c.',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,

                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'fundPrice',
                                                                            next: 'discount',
                                                                            label: 'Fond cijena',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'discount',
                                                            next: 'discountValue',
                                                            label: 'Rabat',
                                                            finishField: true,

                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            readOnly: true,
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                            <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'number', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'series', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            <div className="footer">
                                <div className="footer-left">
                                    <button className="blue-button">
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>OPIS</p>
                                    </button>
                                    <button >
                                        <div className="box box-red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <p>BRISANJE DOKUMENTA</p>
                                    </button>
                                    <button className="dark-blue-button">
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>ANALITIKA</p>
                                    </button>
                                    <div className="modified-div-wrap">
                                        <h6>Poslednja izmjena:</h6>
                                        <p>Tanja Novak</p>
                                        <p>24.08.2021. - 14:28h</p>
                                    </div>
                                </div>
                                <div className="footer-right">
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>505,00 KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>42,00 KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>543,00 KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        this.state.buttonActive == 1 ?
                            <>
                                <div className="list-builder-filters-wrap-output">
                                    <div className="list-builder-filters">
                                        <div className="field-strap-wrap">
                                            <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value })} placeholder={'Komitent'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text' value={this.state.filterBusiness} onChange={(e) => this.setState({ filterBusiness: e.target.value })} placeholder={'Poslovna'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text' value={this.state.filterFacture} onChange={(e) => this.setState({ filterFacture: e.target.value })} placeholder={'Faktura'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value })} placeholder={'Korisnik'} />
                                        </div>
                                    </div>
                                    <div className="clear-filters" onClick={() => {
                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterUser: '',
                                            filterBusiness: '',
                                            filterFacture: ''
                                        })
                                    }}>
                                        <span>&times;</span> Ukloni filter
                                    </div>

                                </div>
                                <div className="mt-15">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        // hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'number', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'code', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'name', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'series', label: 'POSLOVNA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'deadline', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammount', label: 'KO'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ammount', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'discount', label: 'POPUST'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                        ]}
                                        items={this.state.items}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>

                            </>
                            :
                            <>
                            </>
                }



            </div>
        )
    }
}

export default Page(NaturalDiscountPage);
