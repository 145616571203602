import React, { Component } from 'react';
import Page from '../../containers/page';
import Switch from '../../form-builder/fields/switch';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import PrintHelper from '../../components/printHelper';
import AlmbihModal from '../../components/almbihModal';
import Checkbox from '../../form-builder/fields/checkbox';
import ErrorModal from '../../components/errorModal';
// import garbageIcon from '../../assets/garbage.svg';
import {
    Container, Row, Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalHeader
} from 'reactstrap';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { throwStatement } from '@babel/types';
import env from "react-dotenv";

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            overlayWhole: false,
            clientList: true,
            clientForm: false,
            modal: false,
            modalLastChange: false,
            items: [],
            countries: [],
            places: [],
            places1: [],
            conditions: [],
            buttonActive: 1,
            articleTypes: [],
            almbihData: {},
            aktivniCheck: false,
            tempCountryId: '',
            countryLabel: false,
            userName: '',
            userNameModified: '',
            deleteDocumentModal: false,
            timeModified: {},
            userString: '',
            iqvias: [],
            institutionTypes: [],
            printTable: false,
            exportTable: false,
            error: '',

        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }
    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }


    render() {
        let params = this._getParams();
        // console.log(this.state.clientForm && this.state.clientForm.country, this.state.clientForm && this.state.clientForm.institutionType);
        // console.log(this.state.clientForm && this.state.clientForm.country && this.state.countries && this.state.countries.filter(item => item._id == this.state.clientForm.country)[0] && this.state.countries.filter(item => item._id == this.state.clientForm.country)[0].name == ('BIH' || 'FEDERACIJA BIH' || 'DISTRIKT BRČKO') &&
        //     this.state.clientForm.institutionType && this.state.institutionTypes && this.state.institutionTypes.filter(item => item._id == this.state.clientForm.institutionType)[0] && this.state.institutionTypes.filter(item => item._id == this.state.clientForm.institutionType)[0].name == 'Veleprodaja');
        return (
            <div>


                <div className="section-title title-options">
                    <h1>Komintenti</h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-group">
                                <button onClick={async () => { this.setState({ printTable: true }) }}>

                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={async () => { this.setState({ exportTable: true }) }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                                {
                                    this.state.printHtml ?
                                        <PrintHelper html={this.state.printHtml} />
                                        :
                                        null
                                }

                            </div>
                            :
                            <div className="design-helper" style={{ height: 52, opacity: 0 }}></div>
                    }
                </div>

                {
                    this.state.buttonActive == 1 || this.state.buttonActive == 2 ?
                        <div className="table-tabs tabs-two-buttons">
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => {
                                    this.setState({ clientList: true, clientForm: false, buttonActive: 1 })
                                    this._onLoad()
                                }}>
                                    Lista komitenata
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={async () => {
                                    this.setState({ clientForm: true, clientList: false, articleForm: false, buttonActive: 2 })
                                    let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                    if (broj && broj.firstFreeNumber) {
                                        // this.setState({ firstFreeNumber: broj.firstFreeNumber })
                                        this.remoteOnChange('code', broj.firstFreeNumber)
                                    }
                                }

                                }>
                                    Dodaj komitenta
                                </button>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive == 1 ?
                        <div className='active-check-button'>
                            <Checkbox
                                key={1}
                                onChange={
                                    () => {
                                        this.setState({ aktivniCheck: !this.state.aktivniCheck }, () => {
                                            this.updateParams('active', this.state.aktivniCheck)
                                        })
                                    }
                                }
                                checked={this.state.aktivniCheck}
                                label={"Neaktivni"}
                            ></Checkbox>
                        </div>
                        :
                        null
                }

                {/* {this.state.clientForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ clientForm: false })}>
                            Lista komintenata
                        </button>
                        <button className="active" onClick={() => this.setState({ clientForm: true })}>
                            Dodaj komintenata
                        </button>
                    </div>
                    :
                    null
                } */}

                {
                    this.state.buttonActive == 2 ?

                        <div>
                            <Container fluid>
                                <Row>
                                    <Col lg="12">
                                        <FormBuilder
                                            registerSubmitAction={(submit) => this.submit = submit}
                                            registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                            registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                            registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                            classNameForm={"add-form-container"}
                                            // headerForm={"Artikal".translate(this.props.lang)}
                                            addButtonText={'Dodaj'.translate(this.props.lang)}
                                            fieldsToTrack={['code', 'country']}
                                            fieldTracker={async (name, value) => {


                                                if (name == 'code' && !value) {

                                                    // this.remoteOnChange('code', this.state.firstFreeNumber)

                                                    this.remoteOnChange('name', '')
                                                    this.remoteOnChange('naturalDiscount', '')
                                                    this.remoteOnChange('country', '')
                                                    this.remoteOnChange('place', '')
                                                    this.remoteOnChange('address', '')
                                                    this.remoteOnChange('phone', '')
                                                    this.remoteOnChange('email', '')
                                                    this.remoteOnChange('ko', '')
                                                    this.remoteOnChange('jib', '')
                                                    this.remoteOnChange('pdv', '')
                                                    this.remoteOnChange('conditions', '')
                                                    this.remoteOnChange('finance', '')
                                                    this.remoteOnChange('bankAccount', '')
                                                    this.remoteOnChange('iqvia', '')
                                                    this.remoteOnChange('institutionType', '')
                                                    this.remoteOnChange('conditions1', '')
                                                    this.remoteOnChange('contract', '')
                                                    this.remoteOnChange('valueInLimit', '')
                                                    this.remoteOnChange('valueOutLimit', '')
                                                    this.remoteOnChange('description', '')

                                                    this.setState({ userNameModified: '' })
                                                    this.setState({ timeModified: null })
                                                }

                                                if (name == 'code' && value) {
                                                    let client = await this._apiAsync('client-read', { clientCode: value })


                                                    if (client.result.result != null) {

                                                        this.setState({ places1: this.state.places })

                                                        this.remoteOnChange('name', client.result.result.name)
                                                        this.remoteOnChange('naturalDiscount', client.result.result.naturalDiscount)
                                                        this.remoteOnChange('country', client.result.result.country)
                                                        this.remoteOnChange('place', client.result.result.place)
                                                        this.remoteOnChange('address', client.result.result.address)
                                                        this.remoteOnChange('phone', client.result.result.phone)
                                                        this.remoteOnChange('email', client.result.result.email)
                                                        this.remoteOnChange('ko', client.result.result.ko)
                                                        this.remoteOnChange('jib', client.result.result.jib)
                                                        this.remoteOnChange('pdv', client.result.result.pdv)
                                                        this.remoteOnChange('conditions', client.result.result.conditions)
                                                        this.remoteOnChange('finance', client.result.result.finance)
                                                        this.remoteOnChange('bankAccount', client.result.result.bankAccount)
                                                        this.remoteOnChange('iqvia', client.result.result.iqvia)
                                                        this.remoteOnChange('institutionType', client.result.result.institutionType ? client.result.result.institutionType.toString() : '')
                                                        this.remoteOnChange('conditions1', client.result.result.conditions1)
                                                        this.remoteOnChange('contract', client.result.result.contract)
                                                        this.remoteOnChange('valueInLimit', client.result.result.valueInLimit)
                                                        this.remoteOnChange('valueOutLimit', client.result.result.valueOutLimit)
                                                        this.remoteOnChange('description', client.result.result.description)
                                                        this.remoteOnChange('fond', client.result.result.fond)

                                                        this.setState({ userName: client.result.result.createdByUsername })

                                                        if (client.result.result.modifiedByUsername) {
                                                            this.setState({ userNameModified: client.result.result.modifiedByUsername })
                                                        }

                                                        if (client.result.result.tsModified) {

                                                            let dan = new Date(client.result.result.tsModified * 1000).getDate()
                                                            let mjesec = new Date(client.result.result.tsModified * 1000).getMonth() + 1
                                                            let godina = new Date(client.result.result.tsModified * 1000).getFullYear()
                                                            let sati = new Date(client.result.result.tsModified * 1000).getHours()
                                                            let minuti = new Date(client.result.result.tsModified * 1000).getMinutes()

                                                            if (dan < 10) {
                                                                dan = '0' + dan
                                                            }

                                                            if (mjesec < 10) {
                                                                mjesec = '0' + mjesec
                                                            }

                                                            if (sati < 10) {
                                                                sati = '0' + sati
                                                            }

                                                            if (minuti < 10) {
                                                                minuti = '0' + minuti
                                                            }

                                                            this.setState({ timeModified: { dan: dan, mjesec: mjesec, godina: godina, sati: sati, minuti: minuti } }, () => {
                                                                this.setState({ userString: this.state.timeModified.dan + '.' + this.state.timeModified.mjesec + '.' + this.state.timeModified.godina + ' - ' + this.state.timeModified.sati + ':' + this.state.timeModified.minuti })
                                                            })


                                                        }


                                                        this.setState({ tempId: client.result.result._id })
                                                        this.setState({ clientForm: client.result.result })


                                                    } else {

                                                        this.remoteOnChange('name', '')
                                                        this.remoteOnChange('naturalDiscount', '')
                                                        this.remoteOnChange('country', '')
                                                        this.remoteOnChange('place', '')
                                                        this.remoteOnChange('address', '')
                                                        this.remoteOnChange('phone', '')
                                                        this.remoteOnChange('email', '')
                                                        this.remoteOnChange('ko', '')
                                                        this.remoteOnChange('jib', '')
                                                        this.remoteOnChange('pdv', '')
                                                        this.remoteOnChange('conditions', '')
                                                        this.remoteOnChange('finance', '')
                                                        this.remoteOnChange('bankAccount', '')
                                                        this.remoteOnChange('iqvia', '')
                                                        this.remoteOnChange('institutionType', '')
                                                        this.remoteOnChange('conditions1', '')
                                                        this.remoteOnChange('contract', '')
                                                        this.remoteOnChange('valueInLimit', '')
                                                        this.remoteOnChange('valueOutLimit', '')
                                                        this.remoteOnChange('description', '')


                                                        this.setState({ userNameModified: '' })
                                                        this.setState({ timeModified: null })


                                                        // this.setState({ clientForm: null }, () => {
                                                        this.setState({ clientForm: client.result.result })
                                                        // })
                                                    }
                                                }


                                                if (name == 'country' && value) {
                                                    console.log('upisana vrijednost......', value)
                                                    this.remoteOnChange('place', '')

                                                    let countryLab = this.state.countries.filter((item) => {
                                                        if (item._id == value) {
                                                            return item
                                                        }
                                                    })

                                                    if (countryLab && countryLab.length != 0) {
                                                        if (countryLab[0].name == 'BIH' || countryLab[0].name == 'Bosna i Hercegovina' || countryLab[0].name == 'BiH' || countryLab[0].name == 'FEDERACIJA BiH' || countryLab[0].name == 'DISTRIKT BRČKO') {
                                                            this.setState({ countryLabel: true })
                                                        } else {
                                                            this.setState({ countryLabel: false })
                                                        }
                                                    }

                                                }

                                            }}

                                            onSubmit={(data) => {

                                                if (!data._id && this.state.clientForm != null) {
                                                    data = { ...data, _id: this.state.clientForm._id }
                                                }
                                                console.log('kkkk....', data)
                                                data = { ...data, ...this.state.almbihData }
                                                data.jib = data.jib.toString()
                                                data.pdv = data.pdv.toString()

                                                console.log('provjera update.....', data)
                                                if (data && data.institutionType) {
                                                    data.institutionType = Number(data.institutionType)
                                                }

                                                console.log(this.state.clientForm);
                                                if (this.state.clientForm && this.state.clientForm._id) {
                                                    this._api('api4', { data: data }, () => {
                                                        this.setState({ clientForm: undefined }, async () => {
                                                            const nextNumber = await this._apiAsync('checkNextDocumentNumber', {})
                                                            if (nextNumber && nextNumber.firstFreeNumber) {
                                                                this.remoteOnChange('code', nextNumber.firstFreeNumber)
                                                            }
                                                        })
                                                    });
                                                } else {
                                                    this._api('api2', { data: data }, () => {
                                                        this.setState({ clientForm: {} }, async () => {
                                                            const nextNumber = await this._apiAsync('checkNextDocumentNumber', {})
                                                            if (nextNumber && nextNumber.firstFreeNumber) {
                                                                this.remoteOnChange('code', nextNumber.firstFreeNumber)
                                                            }
                                                        })
                                                    });
                                                }
                                            }}
                                            initialValues={this.state.clientForm && this.state.clientForm._id ? this.state.clientForm : {}}
                                            fields={[
                                                {
                                                    type: 'row',
                                                    children: [
                                                        {
                                                            type: 'col',
                                                            className: 'col-up',
                                                            width: { lg: 7, sm: 7, xs: 7 },
                                                            children: [

                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            className: 'col-up col-up-right col-up-comitent buttons-position',
                                                            width: { lg: 5, sm: 5, xs: 5 },
                                                            children: [
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 4, sm: 4, xs: 4 },
                                                                            children: [
                                                                                {
                                                                                    type: 'switch',
                                                                                    name: 'fond',
                                                                                    next: 'stock',
                                                                                    label: 'Aktivan',
                                                                                    className: 'test123',
                                                                                    asyncValidation: true,
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 8, sm: 8, xs: 8 },
                                                                            children: [
                                                                                {
                                                                                    type: 'row',
                                                                                    className: 'option-header',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'block',
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'button',
                                                                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                                                                    action: 'submit',
                                                                                                    children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                                    className: 'options-button',
                                                                                                },
                                                                                                // {
                                                                                                //     type: 'button',
                                                                                                //     width: { lg: 4, sm: 6, xs: 6 },
                                                                                                //     action: 'submit',
                                                                                                //     children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                                //     className: 'options-button'
                                                                                                // },
                                                                                                // {
                                                                                                //     type: 'button',
                                                                                                //     width: { lg: 4, sm: 6, xs: 6 },
                                                                                                //     action: 'submit',
                                                                                                //     children: <div className="option-buttons-container"><div className="option blue"><Isvg src={exportIcon} /></div> <span>Export</span></div>,
                                                                                                //     className: 'options-button'
                                                                                                // },
                                                                                                {
                                                                                                    type: 'button',
                                                                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                                                                    action: 'custom',
                                                                                                    children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                                    className: 'options-button',
                                                                                                    onClick: () => {


                                                                                                        if (this.state.clientForm != null) {
                                                                                                            console.log('provjera klijent forma.....kldkflkfdlkfdlkdlk')
                                                                                                            this.setState({ deleteDocumentModal: this.state.clientForm })

                                                                                                        } else {
                                                                                                            this.remoteOnChange('name', '')
                                                                                                            this.remoteOnChange('naturalDiscount', '')
                                                                                                            this.remoteOnChange('country', '')
                                                                                                            this.remoteOnChange('place', '')
                                                                                                            this.remoteOnChange('address', '')
                                                                                                            this.remoteOnChange('phone', '')
                                                                                                            this.remoteOnChange('email', '')
                                                                                                            this.remoteOnChange('ko', '')
                                                                                                            this.remoteOnChange('jib', '')
                                                                                                            this.remoteOnChange('pdv', '')
                                                                                                            this.remoteOnChange('conditions', '')
                                                                                                            this.remoteOnChange('finance', '')
                                                                                                            this.remoteOnChange('bankAccount', '')
                                                                                                            this.remoteOnChange('iqvia', '')
                                                                                                            this.remoteOnChange('institutionType', '')
                                                                                                            this.remoteOnChange('conditions1', '')
                                                                                                            this.remoteOnChange('contract', '')
                                                                                                            this.remoteOnChange('valueInLimit', '')
                                                                                                            this.remoteOnChange('valueOutLimit', '')
                                                                                                            this.remoteOnChange('description', '')
                                                                                                        }




                                                                                                    }
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'block',
                                                                    // header: 'Osnovni podaci'.translate(this.props.lang),
                                                                    referent: true,
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                            children: [
                                                                                {
                                                                                    type: 'h4',
                                                                                    text: 'Osnovni podaci'
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                            children: [
                                                                                {
                                                                                    type: 'referentLabel',
                                                                                    name: 'referent',
                                                                                    className: 'flex-end',
                                                                                    label: 'Referent:',
                                                                                    content: this.state.userName ? this.state.userName : this.state.userNameModified ? this.state.userNameModified : '',
                                                                                    values: [
                                                                                        // { value: 'user', name: this.state.userName },
                                                                                        // { value: 'period', name: 'Po periodu' },
                                                                                        // { value: 'bezKo', name: 'Bez KO' },
                                                                                    ],
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 2, xs: 2 },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'code',
                                                                                    next: 'name',
                                                                                    label: 'Šifra',
                                                                                    textType: 'number',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        required('Šifra mora biti unešena!'),
                                                                                        number('Mora biti broj')
                                                                                    ]
                                                                                },

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'name',
                                                                                    next: 'country',
                                                                                    label: 'Naziv',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        required('Naziv mora biti unešen!'),
                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                        async (value) => {

                                                                                            let clientId = ''
                                                                                            if (this.state.clientForm && this.state.clientForm._id) {
                                                                                                clientId = this.state.clientForm._id
                                                                                            }

                                                                                            let provjera = await this._apiAsync('checkName', { value: value, id: clientId })
                                                                                            console.log('provjera prov...', provjera)

                                                                                            if (provjera && provjera.result) return 'Komitent pod tim nazivom već postoji!'
                                                                                        }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },

                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 4, xs: 4 },
                                                                            children: [
                                                                                {
                                                                                    type: 'switch',
                                                                                    name: 'naturalDiscount',
                                                                                    next: 'country',
                                                                                    label: 'Naturalni rabat',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        // required('Naziv mora biti unešen!'),
                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 4, xs: 4 },
                                                                            children: [
                                                                                {
                                                                                    type: 'switch',
                                                                                    name: 'linkedLegalEntity',
                                                                                    next: 'country',
                                                                                    label: 'Povezano pravno lice',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        // required('Naziv mora biti unešen!'),
                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },

                                                                        

                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 2, xs: 2 },
                                                                            children: [
                                                                                {
                                                                                    type: 'select',
                                                                                    name: 'country',
                                                                                    next: 'place',
                                                                                    label: 'Država',
                                                                                    values: [{ name: 'Izaberite državu ', value: -1 }, ...this.state.countries.map(item => {
                                                                                        let obj = {
                                                                                            name: item.name,
                                                                                            value: item._id
                                                                                        }
                                                                                        return obj;
                                                                                    }).sort((a, b) => {
                                                                                        if (a.name < b.name) {
                                                                                            return -1
                                                                                        }
                                                                                        if (a.name > b.name) {
                                                                                            return 1
                                                                                        }
                                                                                        return 0
                                                                                    })],
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        required('Država mora biti unešena!'),
                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                        async (value) => {
                                                                                            if (value) {
                                                                                                let red = this.state.countries.map(item => {
                                                                                                    return item._id
                                                                                                })

                                                                                                if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                                this.setState({ tempCountryId: value })
                                                                                                this.setState({
                                                                                                    places1: this.state.places.filter((item) => {
                                                                                                        if (item.country == this.state.tempCountryId) {
                                                                                                            return item
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            }

                                                                                        },

                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 2, xs: 2 },
                                                                            children: [
                                                                                {
                                                                                    type: 'select',
                                                                                    name: 'place',
                                                                                    next: 'address',
                                                                                    label: 'Mjesto',
                                                                                    values: [{ name: 'Izaberite mjesto ', value: -1 }, ...this.state.places1.map(item => {
                                                                                        let obj = {}
                                                                                        obj = {
                                                                                            name: item.name,
                                                                                            value: item._id,
                                                                                            // country: item.country
                                                                                        }
                                                                                        return obj;
                                                                                    })
                                                                                    ],


                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        required('Mjesto mora biti unešeno!'),
                                                                                        // (value) => { if (!value || value == '') return "Država mora biti uneseno!" }
                                                                                        async (value) => {
                                                                                            let red = this.state.places.map(item => {
                                                                                                return item._id
                                                                                            })
                                                                                            if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                        },
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 2, xs: 2 },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'address',
                                                                                    next: 'phone',
                                                                                    label: 'Adresa',
                                                                                    values: [{ name: 'test', value: 1 }, { name: 'test2', value: 2 }],
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        required('Adresa mora biti unešena!'),
                                                                                        // (value) => { if (!value || value == '') return "Mjesto mora biti uneseno!" }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 2, sm: 2, xs: 2 },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'phone',
                                                                                    next: 'email',
                                                                                    label: 'Telefon',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 4, sm: 4, xs: 4 },

                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'email',
                                                                                    label: 'E-mail',
                                                                                    next: 'jib',
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        email
                                                                                        // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                    ]
                                                                                },
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'block',
                                                                    // header: 'Poreski podaci'.translate(this.props.lang),
                                                                    // bookNotification: true,
                                                                    // customHeading: true,
                                                                    style: { paddingLeft: '30px', paddingRIght: '30px', marginTop: 30 },
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                            children: [
                                                                                {
                                                                                    type: 'h4',
                                                                                    text: 'Poreski podaci'
                                                                                },
                                                                            ]
                                                                        },

                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 6, sm: 6, xs: 6 },
                                                                            children: [
                                                                                {
                                                                                    type: 'dropdown',
                                                                                    name: 'ko',
                                                                                    className: 'flex-end',
                                                                                    label: 'Knjižna obavijest',
                                                                                    values: [
                                                                                        { value: 'racun', name: 'Po računu' },
                                                                                        { value: 'period', name: 'Po periodu' },
                                                                                        { value: 'bezKo', name: 'Bez KO' },
                                                                                    ],

                                                                                },
                                                                            ]
                                                                        },


                                                                        {
                                                                            type: 'row',
                                                                            style: { width: '100%' },
                                                                            children: [
                                                                                {
                                                                                    type: 'col',
                                                                                    width: { lg: 8, sm: 8, xs: 12 },
                                                                                    children: [
                                                                                        {
                                                                                            type: 'row',
                                                                                            children: [

                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            integer: true,
                                                                                                            name: 'jib',
                                                                                                            next: 'pdv',
                                                                                                            label: 'JIB',
                                                                                                            // textType: 'number',
                                                                                                            asyncValidation: true,
                                                                                                            validate: [
                                                                                                                required('JIB mora biti unešen!'),
                                                                                                                // (value) => {

                                                                                                                //     if (!(/^\d*$/).test(value.toString())) {
                                                                                                                //         return 'Neispravan format JIB broja!'
                                                                                                                //     }
                                                                                                                // },

                                                                                                                (value) => {


                                                                                                                    let drzava = this.state.countries.filter((item) => {
                                                                                                                        if (item._id == this.registerGetValue('country')) {
                                                                                                                            return item
                                                                                                                        }
                                                                                                                    })

                                                                                                                    if (drzava && drzava.length != 0) {
                                                                                                                        if (drzava[0].name == 'BIH' || drzava[0].name == 'FEDERACIJA BIH' || drzava[0].name == 'DISTRIKT BRČKO') {
                                                                                                                            if (value && value.toString().length != 13) {
                                                                                                                                return "Broja karaktera mora biti 13!!"
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            // if (value && value.toString().length > 13) {
                                                                                                                            //     return "Broja karaktera mora biti 13!"
                                                                                                                            // }
                                                                                                                            return undefined;
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return "Morate popuniti predhodna polja!"
                                                                                                                    }

                                                                                                                },

                                                                                                                async (value) => {


                                                                                                                    let clientId = ''
                                                                                                                    if (this.state.clientForm && this.state.clientForm._id) {
                                                                                                                        clientId = this.state.clientForm._id
                                                                                                                    }

                                                                                                                    let jibNumber = await this._apiAsync('checkJibNumber', { value: value, id: clientId })
                                                                                                                    // console.log('provjera lib number....', jibNumber)
                                                                                                                    if (jibNumber.result) return 'Upisan JIB vec postoji.'
                                                                                                                }
                                                                                                            ]
                                                                                                        },

                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            name: 'pdv',
                                                                                                            next: !this.state.countryLabel ? 'finance' : 'conditions',
                                                                                                            label: 'PDV',
                                                                                                            integer: true,
                                                                                                            asyncValidation: true,
                                                                                                            // textType: 'number',
                                                                                                            validate: [
                                                                                                                required('PDV mora biti unešen!'),

                                                                                                                // (value) => {

                                                                                                                //     if (!(/^\d*$/).test(value.toString())) {
                                                                                                                //         return 'Neispravan format PDV broja!'
                                                                                                                //     }
                                                                                                                // },

                                                                                                                (value) => {
                                                                                                                    let drzava = this.state.countries.filter((item) => {
                                                                                                                        if (item._id == this.registerGetValue('country')) {
                                                                                                                            return item
                                                                                                                        }
                                                                                                                    })
                                                                                                                    if (drzava && drzava.length != 0) {
                                                                                                                        if (drzava[0].name == 'BIH' || drzava[0].name == 'FEDERACIJA BIH' || drzava[0].name == 'DISTRIKT BRČKO') {
                                                                                                                            if (value && value.toString().length != 12) {
                                                                                                                                return "Broja karaktera mora biti 12!!"
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            // if (value && value.toString().length > 12) {
                                                                                                                            //     return "Broja karaktera mora biti 12!"
                                                                                                                            // }
                                                                                                                            return undefined;
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return "Morate popuniti predhodna polja!"
                                                                                                                    }

                                                                                                                },

                                                                                                            ]
                                                                                                        },
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'select',
                                                                                                            name: 'conditions',
                                                                                                            next: 'finance',
                                                                                                            label: 'Uslovi',
                                                                                                            asyncValidation: false,
                                                                                                            readOnly: !this.state.countryLabel,
                                                                                                            values: [{ name: 'Izaberite uslove ', value: -1 }, ...this.state.conditions.map(item => {
                                                                                                                let obj = {
                                                                                                                    name: item.name,
                                                                                                                    value: item._id
                                                                                                                }
                                                                                                                return obj;
                                                                                                            })],
                                                                                                            validate: [
                                                                                                                async (value) => {
                                                                                                                    if (value) {
                                                                                                                        let red = this.state.conditions.map(item => {
                                                                                                                            return item._id
                                                                                                                        })
                                                                                                                        if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                                                    }

                                                                                                                },
                                                                                                                // (value) => {
                                                                                                                //     let countryID = this.registerGetValue('country')
                                                                                                                //     let readNameCountry = ''
                                                                                                                //     let value1 = value ? value : ''
                                                                                                                //     let niz = ['BIH', 'BiH', 'Bosna i Hercegovina']
                                                                                                                //     this.state.countries.forEach((element) => {
                                                                                                                //         if (element._id == countryID) {
                                                                                                                //             readNameCountry = element.name
                                                                                                                //         }
                                                                                                                //     })

                                                                                                                //     if (niz.indexOf(readNameCountry) != -1 && value1.length == 0) {
                                                                                                                //         return "Uslovi moraju biti unešeni."
                                                                                                                //     }

                                                                                                                // }
                                                                                                            ]
                                                                                                        },
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            name: 'finance',
                                                                                                            label: 'Finansije E-maila',
                                                                                                            next: 'bankAccount',
                                                                                                            asyncValidation: true,
                                                                                                            validate: [
                                                                                                                email
                                                                                                                // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                            ]
                                                                                                        },
                                                                                                    ]
                                                                                                },
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    type: 'col',
                                                                                    width: { lg: 4, sm: 4, xs: 12 },
                                                                                    children: [
                                                                                        {
                                                                                            type: 'list',
                                                                                            height: 145,
                                                                                            name: 'bankAccount',
                                                                                            label: 'Žiroračun',
                                                                                            // textType: 'number',
                                                                                            bih: this.state.countryLabel,
                                                                                            asyncValidation: true,
                                                                                            validateApi: '/data/clients/bankAccount-check',
                                                                                            validate: [
                                                                                                (value) => {
                                                                                                    console.log('VRIJEDNOST ZIRORACUNA......', value)
                                                                                                }

                                                                                            ]
                                                                                        },
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                            children: [
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 4, sm: 4, xs: 12 },
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    header: 'Ostali podaci'.translate(this.props.lang),
                                                                                    style: { marginTop: 30, marginRight: 5, paddingBottom: 75 },
                                                                                    children: [
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'select',
                                                                                                    name: 'iqvia',
                                                                                                    next: 'institutionType',
                                                                                                    label: 'IQVIA',
                                                                                                    asyncValidation: true,
                                                                                                    values: [{ name: '', value: -1 }, ...this.state.iqvias.map(item => {
                                                                                                        let obj = {
                                                                                                            name: item.name,
                                                                                                            value: item._id
                                                                                                        }
                                                                                                        return obj;
                                                                                                    })],
                                                                                                    validate: [
                                                                                                        (value) => {
                                                                                                            let drzava = this.state.countries.filter((item) => {
                                                                                                                if (item._id == this.registerGetValue('country')) {
                                                                                                                    return item
                                                                                                                }
                                                                                                            })
                                                                                                            if (!value && drzava[0] && (drzava[0].name == 'BIH' || drzava[0].name == 'FEDERACIJA BIH' || drzava[0].name == 'DISTRIKT BRČKO')) {
                                                                                                                return 'IQVIA mora biti unesena!'
                                                                                                            }
                                                                                                        },
                                                                                                        (value) => {
                                                                                                            if (value) {
                                                                                                                let red = this.state.iqvias.map((item) => {

                                                                                                                    return item._id
                                                                                                                })

                                                                                                                if (!red.includes(value.toString())) return 'Neispravan unos!'
                                                                                                            }

                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        },
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'select',
                                                                                                    name: 'institutionType',
                                                                                                    label: 'Vrsta ustanove',
                                                                                                    next: 'conditions1',
                                                                                                    asyncValidation: true,
                                                                                                    // showName: true,
                                                                                                    values: [{ name: '', value: -1 }, ...this.state.institutionTypes.map(item => {
                                                                                                        let obj = {
                                                                                                            name: item.name,
                                                                                                            value: item.code.toString()
                                                                                                        }
                                                                                                        return obj;
                                                                                                    })],
                                                                                                    validate: [
                                                                                                        (value) => {
                                                                                                            let drzava = this.state.countries.filter((item) => {
                                                                                                                if (item._id == this.registerGetValue('country')) {
                                                                                                                    return item
                                                                                                                }
                                                                                                            })
                                                                                                            if (!value && drzava[0] && (drzava[0].name == 'BIH' || drzava[0].name == 'FEDERACIJA BIH' || drzava[0].name == 'DISTRIKT BRČKO')) {
                                                                                                                return 'Vrsta ustanove mora biti unesena!'
                                                                                                            }
                                                                                                            if (value) {
                                                                                                                let niz = this.state.institutionTypes.map((item) => {
                                                                                                                    return item.code.toString()
                                                                                                                })
                                                                                                                console.log(niz)
                                                                                                                console.log(value)
                                                                                                                if (!niz.includes(value)) return 'Neispravana unos!'
                                                                                                            }

                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 4, sm: 4, xs: 4 },
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    header: 'Ugovor'.translate(this.props.lang),
                                                                                    style: { marginTop: 30, paddingBottom: 75 },
                                                                                    children: [
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'text',
                                                                                                    name: 'conditions1',
                                                                                                    next: 'contract',
                                                                                                    label: 'Broj ugovora',
                                                                                                    asyncValidation: true,
                                                                                                    validate: [
                                                                                                        // async (value) => {
                                                                                                        //     if (value) {
                                                                                                        //         let red = this.state.conditions.map(item => {
                                                                                                        //             return item._id
                                                                                                        //         })
                                                                                                        //         if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                                        //     }

                                                                                                        // },
                                                                                                        // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        },
                                                                                        {
                                                                                            type: 'col',
                                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'date',
                                                                                                    name: 'contract',
                                                                                                    label: 'Ugovor važi do',
                                                                                                    next: 'valueInLimit',
                                                                                                    asyncValidation: true,
                                                                                                    // values: [{ name: 'test', value: 1 }, { name: 'test2', value: 2 }],
                                                                                                    validate: [
                                                                                                        validateDate('Datum nije validan.')
                                                                                                        // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 4, sm: 4, xs: 12 },
                                                                            children: [
                                                                                {
                                                                                    type: 'row',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'block',
                                                                                            header: 'Limit'.translate(this.props.lang),
                                                                                            style: { marginTop: 30, marginLeft: 20, marginRight: 2 },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            name: 'valueInLimit',
                                                                                                            next: 'valueOutLimit',
                                                                                                            textType: 'number',
                                                                                                            label: 'Limit u valuti',
                                                                                                            asyncValidation: true,
                                                                                                            validate: [
                                                                                                                // number('Morate unijeti broj!')
                                                                                                                // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                            ]
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 6, sm: 6, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            name: 'valueOutLimit',
                                                                                                            next: 'description',
                                                                                                            textType: 'number',
                                                                                                            label: 'Limit van valute',
                                                                                                            asyncValidation: true,
                                                                                                            validate: [
                                                                                                                // number('Morate unijeti broj!')
                                                                                                                // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                            ]
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        },
                                                                                        {
                                                                                            type: 'block',
                                                                                            style: { marginTop: 30, marginLeft: 20, marginRight: 2, display: 'block', width: '100%' },
                                                                                            children: [
                                                                                                {
                                                                                                    type: 'col',
                                                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                                                    children: [
                                                                                                        {
                                                                                                            type: 'text',
                                                                                                            name: 'description',
                                                                                                            label: 'Opis',
                                                                                                            asyncValidation: true,
                                                                                                            validate: [
                                                                                                                // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                            ]
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }
                                                    ]
                                                },


                                            ]}
                                        ></FormBuilder>
                                        <DeleteModal
                                            isOpen={this.state.deleteDocumentModal}
                                            toggle={() => this.setState({ deleteDocumentModal: null })}
                                            handler={() => {

                                                this._api('api3', { data: this.state.deleteDocumentModal._id }, (data) => {
                                                    if (data.result && data.result.error) {
                                                        this.setState({
                                                            error: data.result.error
                                                        })
                                                    } else {
                                                        this.setState({ clientForm: {} }, () => {
                                                            this._onLoad();
                                                            this.setState({ deleteDocumentModal: null })
                                                            this.remoteOnChange('name', '')
                                                            this.remoteOnChange('naturalDiscount', '')
                                                            this.remoteOnChange('country', '')
                                                            this.remoteOnChange('place', '')
                                                            this.remoteOnChange('address', '')
                                                            this.remoteOnChange('phone', '')
                                                            this.remoteOnChange('email', '')
                                                            this.remoteOnChange('ko', '')
                                                            this.remoteOnChange('jib', '')
                                                            this.remoteOnChange('pdv', '')
                                                            this.remoteOnChange('conditions', '')
                                                            this.remoteOnChange('finance', '')
                                                            this.remoteOnChange('bankAccount', '')
                                                            this.remoteOnChange('iqvia', '')
                                                            this.remoteOnChange('institutionType', '')
                                                            this.remoteOnChange('conditions1', '')
                                                            this.remoteOnChange('contract', '')
                                                            this.remoteOnChange('valueInLimit', '')
                                                            this.remoteOnChange('valueOutLimit', '')
                                                            this.remoteOnChange('description', '')
                                                        })
                                                    }

                                                })
                                            }
                                            } >
                                            Obrisati komitenta <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.name : ''}</strong> ?
                                        </DeleteModal>
                                        <AlmbihModal
                                            isOpen={this.state.modal}
                                            toggle={() => { this.setState({ modal: null }) }}
                                            title={"ALMBIH"}
                                            items={this.state.articleTypes}
                                            data={this.state.clientForm}
                                            saveData={(data) => {
                                                this.setState({ almbihData: { ...this.state.clientForm, data } }, () => {
                                                    console.log('komitent almBih....', this.state.almbihData)
                                                })
                                            }}

                                        ></AlmbihModal>
                                        <div className="last-change-footer">
                                            {
                                                this.state.clientForm && this.state.clientForm._id ?
                                                    <p className="last-change"><span onClick={() => {
                                                        this.props.getLastChange({
                                                            itemId: this.state.clientForm && this.state.clientForm._id,
                                                            collection: 'clients'
                                                        })
                                                    }}>Poslednja izmjena</span></p>

                                                    : null

                                            }

                                            {
                                                this.registerGetValue && this.state.countries.filter(item => item._id == this.registerGetValue('country'))[0] && this.state.countries.filter(item => item._id == this.registerGetValue('country'))[0].name.indexOf(['BIH', 'FEDERACIJA BIH', 'DISTRIKT BRČKO']) && this.state.institutionTypes.filter(item => item._id == this.registerGetValue('institutionType'))[0] && this.state.institutionTypes.filter(item => item._id == this.registerGetValue('institutionType'))[0].name == 'Veleprodaja' ?
                                                    <button className="almbih" onClick={() => {
                                                        this.setState({
                                                            modal: true
                                                        })
                                                    }}>ALMBIH</button>
                                                    :
                                                    null
                                            }
                                            {/* <button className="almbih" onClick={() => {
                                                this.setState({
                                                    modal: true
                                                })
                                            }}>ALMBIH</button> */}
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </div>


                        :
                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                        if (data.result && data.result.error) {
                                            this.setState({
                                                error: data.result.error
                                            })
                                        } else {
                                            this.setState({ clientList: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })

                                        }
                                    });
                                }} >
                                Obrisati komitenta <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={false}
                                sort={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                childrenField={'units'}
                                childrenFieldSortField={'code'}
                                fields={[
                                    { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'address', label: 'ADRESA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'placeName', label: 'MJESTO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'countryName', label: 'DRŽAVA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'phone', label: 'TELEFON'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}
                                items={this.state.items}

                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} className="delete-icon" />,
                                            onClick: (item) => {

                                                console.log('PROVJERA KORISNIKA I DATUMA UPISA....', item)
                                                if (item && item.institutionType) {
                                                    item.institutionType = item.institutionType.toString()
                                                }

                                                if (item.tsModified) {

                                                    if (item.modifiedByUsername) {
                                                        this.setState({ userNameModified: item.modifiedByUsername })
                                                    }


                                                    let dan = new Date(item.tsModified * 1000).getDate()
                                                    let mjesec = new Date(item.tsModified * 1000).getMonth() + 1
                                                    let godina = new Date(item.tsModified * 1000).getFullYear()
                                                    let sati = new Date(item.tsModified * 1000).getHours()
                                                    let minuti = new Date(item.tsModified * 1000).getMinutes()

                                                    if (dan < 10) {
                                                        dan = '0' + dan
                                                    }

                                                    if (mjesec < 10) {
                                                        mjesec = '0' + mjesec
                                                    }

                                                    if (sati < 10) {
                                                        sati = '0' + sati
                                                    }

                                                    if (minuti < 10) {
                                                        minuti = '0' + minuti
                                                    }

                                                    this.setState({ timeModified: { dan: dan, mjesec: mjesec, godina: godina, sati: sati, minuti: minuti } }, () => {
                                                        this.setState({ userString: this.state.timeModified.dan + '.' + this.state.timeModified.mjesec + '.' + this.state.timeModified.godina + ' - ' + this.state.timeModified.sati + ':' + this.state.timeModified.minuti })
                                                    })


                                                } else {
                                                    this.setState({ timeModified: { dan: '', mjesec: '', godina: '', sati: '', minuti: '' } })
                                                }

                                                if (item && item.countryName && (item.countryName == 'BIH' || item.countryName == 'FEDERACIJA BIH' || item.countryName == 'DISTRIKT BRČKO')) {
                                                    this.setState({ countryLabel: true })
                                                } else {
                                                    this.setState({ countryLabel: false })
                                                }




                                                if (this.state.places1.length == 0) {
                                                    this.setState({ places1: this.state.places })
                                                }

                                                this.setState({ clientForm: item })
                                                this.setState({ buttonActive: 2 })
                                            }
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/clients', title: 'Komitenti' }}
                            ></ListBuilder>
                        </div>
                }

                {
                    this.state.error && this.state.error != '' ?
                        <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: '', deleteModal: null, deleteDocumentModal: null })} error={this.state.error}>

                        </ErrorModal>
                        :
                        null
                }
            </div>
        )
    }
}

export default Page(Home);
