import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     'api2': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/clients/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/demo/' + data.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            
            return fetch(env.API_URL + '/data/clients/validate/' + data.value , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    }

}