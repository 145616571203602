import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import reportIcon from '../../assets/report.svg';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {      
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [
                {
                    password: '1',
                    name: 'nova media. s.p. Bijeljina',
                    conto: '3278'
                },
                {
                    password: '2',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
                {
                    password: '3',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
                {
                    password: '4',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
                {
                    password: '5',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
                {
                    password: '6',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
                {
                    password: '7',
                    name: 'nova media. s.p. Bijeljina',
                    adress: 'Mese Selimovica 23b/21',
                    conto: '3278'
                },
            ]
        }
    }

    componentDidMount() {
       this._onLoad();
       this._api('api2', {data: {name: 'demo collection'}}, (data) => {console.log(data)})
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props[0].location.search != prevProps[0].location.search){
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title">
                    <h1>Izvještaji</h1>
                </div>

                <Container fluid>
                    <Row>
                        <Col lg="6">                            
                            <FormBuilder
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                    onSubmit={(data) => {
                                        console.log(data);
                                    }}
                                    initialValues={this.state.articleForm ? this.state.articleForm : { /*firstName:'Milan' */ }}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Opcije'.translate(this.props.lang),
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'stock',
                                                                            next: 'place',
                                                                            label: 'Skladište',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                        
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 9, sm: 9, xs: 9 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'place',
                                                                            next: 'dateFrom',
                                                                            label: 'Naziv',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'dateFrom',
                                                                            next: 'dateTo',
                                                                            label: 'Datum od',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'dateTo',
                                                                            label: 'Datum do',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Konto mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]
                                        },
                                        
                                        
                                    ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="expense-list">
                                <h6>Izvještaji</h6>
                                <div className="report-row">
                                    <Isvg src={reportIcon} />
                                    <h6>KRATKI Z - DNEVNI IZVJEŠTAJ</h6>
                                </div>
                                <div className="report-row">
                                    <Isvg src={reportIcon} />
                                    <h6>MJESEČNI IZVJEŠTAJ</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Home);
