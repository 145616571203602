import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import FormBuilder from './form-builder';
import Routes from './routes';

import env from "react-dotenv";
import moment from 'moment';
import { parseJSON } from "./helpers/json";
import Loader from "./components/loader";
import ListBuilder from './list-builder';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  //console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {

         },
         'en': {

         }
       });
     }

     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;

     localStorage.translate = JSON.stringify(obj);

     return this;*/

    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    //console.log(Object.keys(langs), lang)
    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.appInitialData,
      userVerificationInProgress: true,
      uData: null,
      modulesTree: [],
      availablePaths: [],
      selectedYear: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY'),
      loadingPage: false,
      lastChange: [],
      page: 0,
      entries: 10,
      sidebarShort: true
    }
  }

  selectYear = (year) => {
    this.setState({ selectedYear: year }, () => {
      if (typeof window != 'undefined') {
        localStorage.setItem('year', this.state.selectedYear);
      }
    })
  }
  updateMeta = () => {

  }
  getLastChange = (data) => {
    fetch(env.API_URL + '/data/last-change', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({ data })
    }).then(res => {
      return res.json()
    }).then((result) => {
      this.setState({
        lastChange: result,
        openLastChangeModal: true
      })
    });

  }
  genericPrint = (request) => {
    this.setState({ loadingPage: true });
    fetch(env.API_URL + '/data/generic/print', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      responseType: 'blob',
      body: JSON.stringify(request)

    })
      .then((response) => {
        if (response.ok) { // checks if the response is with status 200 (successful)
          return response.blob().then(blob => {
            let blobURL = URL.createObjectURL(blob);

            let iframe = document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = blobURL;
            iframe.onload = function () {
              setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
          });
        }
      }).finally(() => {
        this.setState({ loadingPage: false });
      })

  }
  genericExport = (request) => {
    this.setState({ loadingPage: true });
    fetch(env.API_URL + '/data/generic/export', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify(request)
    }).then(parseJSON).then(({ result }) => {
      if (result) {
        if (result.success) {
          window.location.href = env.API_URL + `${result.file}`;
        }
      }
    }).finally(() => {
      this.setState({ loadingPage: false });
    })
  }
  verifyUser = (callback) => {
    if (!localStorage.getItem('authToken')) {
      this.setState({
        userVerificationInProgress: false
      })
      return;
    }

    fetch(env.API_URL + "/users/verify", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result)
        if (result.error) {
          this.setState({
            error: result.error
          })
        } else {

          this.setState({ uData: result }, () => {
            if (callback) {
              callback()
            }
            fetch(env.API_URL + '/users/modules/tree', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
            }).then(res => {
              return res.json()
            }).then((result) => {
              if (!localStorage.getItem('authToken')) {
                this.setState({
                  userVerificationInProgress: false
                })
                return;
              }


              // if (result[0] && this.props.location.pathname == '/') {

              //   if (result[0].modules && result[0].modules[0]) {
              //     this.props.history.push(result[0].modules[0].link);
              //   } else {
              //     this.props.history.push(result[0].link);
              //   }
              // };

              // this.generateModulesMeta(result);
              if (result && result.length) {
                this.setState({
                  modulesTree: result
                })
              }
            });

            fetch(env.API_URL + '/users/modules/available-paths', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
            }).then(res => {
              return res.json()
            }).then((result) => {
              if (!localStorage.getItem('authToken')) {
                this.setState({
                  userVerificationInProgress: false
                })
                return;
              }
              // console.log(result)

              this.setState({
                availablePaths: result
              })
            });
          })



        }
      });
  }
  getOriginalDateFormat = (date) => {
    let returnValue = '';
    let dateSplit = date.split('.');
    returnValue = dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2];

    return returnValue;
  }
  checkDateValid(date) {
    let dateValid = true;
    let dateSplit = date.split('.');
    if (dateSplit && dateSplit.length == 3) {
      if ((Number(dateSplit[0]) <= 31 && Number(dateSplit[0]) > 0) && (Number(dateSplit[1]) <= 12 && Number(dateSplit[1]) > 0) && (Number(dateSplit[2]) <= 2100 && Number(dateSplit[2]) > 1970)) {
        dateValid = true;
      } else {
        dateValid = false;
      }
    } else {
      dateValid = false;
    }
    return dateValid;
  }
  getDateStringFromTs(timestamp, format = 'DD.MM.YYYY') {
    let returnValue = moment.unix(timestamp).format(format);
    return returnValue;
  }
  getTsFromDateString(date) {
    let returnValue = date;
    let split = date.split('.');
    if (split && split.length == 3) {
      let englishDate = '';
      englishDate = split[1] + '-' + split[0] + '-' + split[2]
      returnValue = Math.floor(new Date(englishDate).getTime() / 1000);
    }
    return returnValue;
  }
  roundToTwoDecimals = (number, round = true, decimals = false) => {
    let result = Math.round(Number(number) * 100) / 100;
    if (round) {
      if (decimals) {
        let splitNumber = String(result).split('.');
        result = splitNumber[0] + ',';
        if (splitNumber[1]) {
          if (Number(splitNumber[1]) < 10) {
            result += splitNumber[1] + '0'
          } else {
            result += splitNumber[1]
          }
        } else {
          result += '00';
        }

      }
      return result;
    } else {
      return number;
    }

  }
  componentDidMount() {
    this.verifyUser()

    if (typeof window != 'undefined') {
      if (localStorage.year) {
        this.setState({ selectedYear: localStorage.year })
      } else if (this.state.selectedYear) {
        localStorage.setItem('year', this.state.selectedYear)
      }

    }

  }
  signOut = (callback) => {
    if (typeof window != 'undefined') {
      localStorage.removeItem('authToken');
      this.setState({ uData: null }, () => {
        if (callback) {
          callback()
        }
      })
    }
  }
  sidebarChange = () => {
    this.setState({
      sidebarShort: !this.state.sidebarShort
    })
  }
  render() {
    return (
      <div className="App">
        <Loader loading={this.state.loadingPage} />
        <Routes
          updateMeta={this.updateMeta}
          verifyUser={this.verifyUser}
          genericPrint={this.genericPrint}
          genericExport={this.genericExport}
          signOut={this.signOut}
          getOriginalDateFormat={this.getOriginalDateFormat}
          roundToTwoDecimals={this.roundToTwoDecimals}
          getDateStringFromTs={this.getDateStringFromTs}
          getTsFromDateString={this.getTsFromDateString}
          checkDateValid={this.checkDateValid}
          selectYear={this.selectYear}
          getLastChange={this.getLastChange}
          {...this.props}
          {...this.state}
          sidebarChange={this.sidebarChange}
        ></Routes>

        {
          this.state.openLastChangeModal ?
            <Modal className='modalLastChange' isOpen={this.state.openLastChangeModal} centered size='sm'>
              <ModalHeader>Poslednje izmjene</ModalHeader>
              <ModalBody>
                <Table className='last-change-table'>
                  <tr>
                    <th>
                      KORISNIK
                    </th>
                    <th>
                      DATUM
                    </th>
                    <th>
                      VRIJEME
                    </th>
                    <th>
                      POLJE
                    </th>
                    <th>
                      STARA VRIJEDNOST
                    </th>
                    <th>
                      NOVA VRIJEDNOST
                    </th>
                  </tr>
                  {this.state.lastChange && this.state.lastChange.length ? this.state.lastChange.map(item => {
                    return (
                      <tr>
                        <td>
                          {item.username}
                        </td>
                        <td>
                          {moment.unix(item.timestamp).format('DD-MM-YYYY')}
                        </td>
                        <td>
                          {moment.unix(item.timestamp).format('HH:mm')}
                        </td>
                        <td>
                          {item.key}
                        </td>
                        <td>
                          {item.oldValue}
                        </td>
                        <td>
                          {item.newValue}
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan='6'>
                        Nema izmjena
                      </td>
                    </tr>
                  }
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => {
                  this.setState({
                    lastChange: [],
                    openLastChangeModal: false
                  })
                }} >Zatvori</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }
      </div >
    )
  }
}

export default App;
