import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-fiscal-period': {
        onLoad: false,
        action: (lang, match, query) => {
            console.log(query);
            return fetch(env.API_URL + '/data/fiscal/period', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                console.log(result)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'get-fiscal': {
        onLoad: true,
        action: (lang, match, query) => {
            console.log(query,"fiscal");
            return fetch(env.API_URL + '/data/fiscal', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                console.log(result,"aaaaaa")
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'printFiscalDocument': {
        onLoad: false,
        action: (data) => {
            console.log(data);
            return fetch(env.API_URL + '/data/fiscal-cash/report/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            })
        }
    },

}