import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-cold-chain': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/coldChain', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries:query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        documentNumber: query && query.documentNumber,
                        coldChainNumber: query && query.coldChainNumber,
                        client: query && query.client,
                        business: query && query.business,
                        type: query && query.type,
                        change: query && query.change
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     

}