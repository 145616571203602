import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-stock': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/get/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        barcode: query && query.barcode,
                        purchasePrice: query && query.purchasePrice,
                        sellPrice: query && query.sellPrice,
                        series: query && query.series,
                        deadlines: query && query.deadlines,
                        manufacturer: query && query.manufacturer,
                        articleFrom: query && query.articleFrom,
                        articleTo: query && query.articleTo,
                        reservation: query && query.reservation,
                        groupFrom: query && query.groupFrom,
                        groupTo: query && query.groupTo,
                        date: query && query.date
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                        stockSum: result.sum ? result.sum : 0
                    }
            })
        }
    },

    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/stock/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        barcode: query && query.barcode,
                        purchasePrice: query && query.purchasePrice,
                        sellPrice: query && query.sellPrice,
                        series: query && query.series,
                        deadlines: query && query.deadlines,
                        manufacturer: query && query.manufacturer,
                        articleFrom: query && query.articleFrom,
                        articleTo: query && query.articleTo,
                        reservation: query && query.reservation,
                        groupFrom: query && query.groupFrom,
                        groupTo: query && query.groupTo,
                        date: query && query.date
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        link: result.link,

                    }
            })
        }
    },
}