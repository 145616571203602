import React, { Component } from 'react';


import { Player, Controls } from "@lottiefiles/react-lottie-player";


class Loader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingText: 'Učitavanje...'
        }
    }

    render() {
        if(!this.props.loading){
            return null
        }
        // console.log('U LOADERUUUUUUU......',this.props.loading)
        return (

            <div className="loader-wrap" >
                <Player
                    autoplay={true}
                    loop={true}
                    src="/loader.json"
                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                ></Player>
                {/* <div className='loader-text'>
                    {this.state.loadingText}
                </div> */}

            </div>
        )
    }
}

export default Loader;