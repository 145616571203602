import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: false,
        action: (lang, match, query) => {
          
            return fetch(env.API_URL + '/data/output', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

    'api2': {
        onLoad: false,
        action: (data) => {
           
            return fetch(env.API_URL + '/data/output/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
               
                return { result, status };
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
           
            return fetch(env.API_URL + '/data/output/' + data.val + "/" + data.id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            console.log('IZ APIJA 4........',data)
          let obj = {}
          Object.assign(obj, data.data)
          
            return fetch(env.API_URL + '/data/output/' + data.data.id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(obj)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api5': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/output', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result,
                        total: result.total,
                    }
            })
        }
    },

    'citanjeDokumenta': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/output/'+ data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return { result,status}
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/warehouses/validate/' + data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validateStorage': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/warehouses/validationStorage/' + data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validateArticle': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/products/validationArticle/' + data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validateClient': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/validationClient/' + data.value, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validateDoc': {
        onLoad: false,
        action: () => {

            return fetch(env.API_URL + '/data/output/validationDocument/', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                
                return { result, status };
            })
        }
    },


    // 'validateDocNumber': {
    //     onLoad: false,
    //     action: (data) => {
    //         return fetch(env.API_URL + '/data/output/validationDocument/' + data.value, {
    //             method: 'GET',
    //             headers: {
    //                 'content-type': 'application/json',
                    // 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //         }).then(parseJSON).then(({ result, status }) => {
    //             console.log(result)
    //             return {result, status};
    //         })
    //     }
    // },



}