import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },


    'getFactureForClient': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/factureForClient', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result,
                        status: status
                    }
            })
        }
    },



    // 'get-business-units': {
    //     onLoad: false,
    //     action: (query) => {
    //         return fetch(env.API_URL + '/data/business-units', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify({
    //                 page: 0,
    //                 entries: 20,
    //                 filter: query.filter,
    //                 query: query.query

    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     items: result.items,
    //                 }
    //         })
    //     }
    // },
    // 'check-client-exists-in-business-units': {
    //     onLoad: false,
    //     action: (query) => {
    //         return fetch(env.API_URL + '/data/business-units/check/' + query.clientId, {
    //             method: 'GET',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //         }).then(parseJSON).then(({ result, status }) => {
    //             return { result, status };
    //         })
    //     }
    // },
    // 'get-manufactures': {
    //     onLoad: false,
    //     action: (query) => {
    //         return fetch(env.API_URL + '/data/manufacturs', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify({
    //                 page: 0,
    //                 entries: 20,
    //                 filter: query.filter,

    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     items: result.items,
    //                 }
    //         })
    //     }
    // },
    // 'get-reports': {
    //     onLoad: false,
    //     action: (query) => {
    //         return fetch(env.API_URL + '/data/reports/categories/manufectures', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify({
    //                 page: query && query.page,
    //                 entries: query && query.entries,
    //                 filter: query && query.filter,
    //                 sortType: query && query.sortType ? query.sortType : '1',
    //                 sortField: query && query.sortField ? query.sortField : 'documentNumber',
    //                 additionalFilters: {
    //                     dateFrom: query && query.dateFrom,
    //                     dateTo: query && query.dateTo,
    //                     clientCode: query && query.clientCode,
    //                     business: query && query.business,
    //                     manufacturer: query && query.manufacturer,
    //                     buttonType: query && query.typeButton
    //                 },

    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return result
    //         })
    //     }
    // },
    'getKO': {
        onLoad: false,
        action: (query) => {
            
            return fetch(env.API_URL + '/data/reports/reportKO', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        clientCode: query && query.clientCode,
                        documentNumber: query && query.documentNumber,
                        // manufacturer: query && query.manufacturer,
                        buttonType: query && query.typeButton
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return result
            })
        }
    },

    // 'getTotal': {
    //     onLoad: false,
    //     action: (query) => {
            
    //         return fetch(env.API_URL + '/data/reports/reportKoTotal', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify({
    //                 page: query && query.page,
    //                 entries: query && query.entries,
    //                 filter: query && query.filter,
    //                 sortType: query && query.sortType ? query.sortType : '1',
    //                 sortField: query && query.sortField ? query.sortField : 'documentNumber',
    //                 additionalFilters: {
    //                     dateFrom: query && query.dateFrom,
    //                     dateTo: query && query.dateTo,
    //                     clientCode: query && query.clientCode,
    //                     documentNumber: query && query.documentNumber,
    //                     // manufacturer: query && query.manufacturer,
    //                     buttonType: query && query.typeButton
    //                 },

    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return result
    //         })
    //     }
    // },

    
    // 'get-manufactures': {
    //     onLoad: true,
    //     action: () => {
    //         return fetch(env.API_URL + '/data/manufacturs', {
    //             method: 'GET',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //         }).then(parseJSON).then(({ result, status }) => {
               
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     manufacturers: result,
    //                     // total: result.total,
    //                 }
    //         })
    //     }
    // },

}