import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'discount-supplier': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/discounts/supplier', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

     'new-discount-supplier': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/discounts/supplier/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log(result)
                return {result, status};
            })
        }
    },


    'delete-discount-supplier': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/discounts/supplier/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'update-discount-supplier': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/discounts/supplier/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'get-discount-supplier': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/conditions', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                // console.log(result)
                if (status >= 200 && status < 300)
                    return {
                        conditions: result,
                        // total: result.total,
                    }
            })
        }
    },
    'get-manufactures': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/manufacturs', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        manufacturers: result,
                        // total: result.total,
                    }
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            
            return fetch(env.API_URL + '/data/discounts/validate/' + data.value , {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },


}