import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {

    'get-fund': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/funds', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        funds: result,
                    }
            })
        }
    },

    'get-checkout-fund': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/checkout/fund', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        checkOut: result.items,
                        total: result.total
                    }
            })
        }
    },

    'get-fund-articles': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/check/checkout/fund/article', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        fundsItems: result,
                    }
            })
        }
    },

    'get-articles': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/checkout/fund/article', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result,
                    }
            })
        }
    },


    'get-articles-filter': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },


    'get-stock': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/checkout/fund/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                    }
            })
        }
    },

    'insert-new': {
        onLoad: false,
        action: (data) => {
            // console.log(data)
            return fetch(env.API_URL + '/data/checkout/fund/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },


    'delete': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/checkout/fund/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'update': {
        onLoad: false,
        action: (data) => {
            // console.log(data)
            return fetch(env.API_URL + '/data/checkout/fund/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'validate': {
        onLoad: false,
        action: (data) => {
            // console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/checkout/validate/' + data.value + '/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'ariclesSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/products/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) =>{
                return {
                    articlesSearch : response.result.items
                }
            })
        }
    },

}