import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import PrintHelper from "../../components/printHelper";
import InfoModal from '../../components/infoModal'
import { debounce } from "lodash";


import {
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';


import moment from 'moment';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import Loader from "../../components/loader";
import env from "react-dotenv";

class EstimationPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            estimationList: true,
            estimationForm: false,
            entryOut: true,
            buttonActive: 2,
            clients: [],
            businessUnits: [],
            products: [],
            listCustomFilter: true,
            items: [

            ],
            itemList: [],
            idDokumenta: '',
            pomocnaDokument: '',
            pomocnaStavka: '',
            dokumentZaStavku: '',
            brojStavke: '',
            estimationItems: [],
            series: [],
            seriesTemp: [],
            seriesDeadline: [],
            pomocnaFakturnaCijena: 0,
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            enableEdit: false,
            clientId: '',
            loadingPage: false,
            printTable: false,
            exportTable: false,
            printHtml: null,
            descriptionModal: false,
            infoModal: false,
            knjiz: true,
            articleAmmount: 0,
            seriesSum: 0,
            clientsSearch: [],
            articlesSearch: [],

        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();

        let params = this._getParams();
        this.updateParams('sortType', -1)
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }

        if (params.business && params.business != this.state.filterBusiness) {
            this.setState({ filterBusiness: params.business })
        }

        this.metoda()


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (!prevState.firstFreeNumber && this.state.firstFreeNumber) {
            let estimationForm = this.state.estimationForm;
            if (!estimationForm) {
                estimationForm = { estimationDate: this.state.dateNow }
            }
            estimationForm.documentNumber = this.state.firstFreeNumber;
            if (!estimationForm.item) {
                estimationForm.item = { itemNumber: 1 }
            }
            this.setState({ estimationForm: null }, () => {
                this.setState({ estimationForm })
            })
        }
    }

    goToFormTab = () => {
        this._onLoad()
        let estimationForm = this.state.estimationForm;
        if (!estimationForm) {
            estimationForm = {
                estimationDate: this.state.dateNow
            }
        }
        estimationForm.documentNumber = this.state.firstFreeNumber;
        if (!estimationForm.item) {
            estimationForm.item = { itemNumber: 1 }
        }
        this.setState({ estimationForm: null }, () => {
            this.setState({ estimationForm })
        })
    }

    articleSearchMethod = debounce(async (value) => {
        let articleList = await this._apiAsync('ariclesSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('item.article') && articleList)
            this.setState({ articlesSearch: articleList.articlesSearch })
    }, 400)


    metoda = async (value) => {

        this.articleSearchMethod(value)

    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('clientCode')) {
            this.setState({ clientsSearch: clientList.clientsSearch })
        }

    }

    metodaBusiness = async (value) => {
        let businessList = []
        businessList = await this._apiAsync('businessSearch', { value: value, clientCode: this.registerGetValue ? this.registerGetValue('clientCode') : '' })
        if (this.registerGetValue && value === this.registerGetValue('businessUnit'))
            this.setState({ businessSearch: businessList.businessSearch })
    }

    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.registerGetValue('item.article') ? this.registerGetValue('item.article') : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            withoutSeries: true,
            serialNumber: value,
            deadline: true
        })
        if (resultSeries && resultSeries.items.length > 0 && !this.registerGetValue('item.serialNumber')) {
            this.setState({ series: resultSeries.items })
        }
    }, 400);

    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }

    printOutput = async () => {
        const printHtml = await this._apiAsync('printOutputDocument', { documentNumber: this.state.estimationForm.documentNumber })
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }

    readClientAndBusinessUnits = async (value) => {
        this.remoteOnChange('businessUnit', '')
        this.setState({ businessUnits: [] })


        this._apiAsync('get-clients', { filter: value })
            .then((clients) => {

                if (clients && clients.items) {
                    this.setState({ clients: clients && clients.items }, () => {
                        if (this.state.clients.filter(item => item.code == value).length == 1) {
                            if (this.state.clients.filter(item => item.code == value)[0].name) {
                                if (this.registerGetValue('clientName') != this.state.clients.filter(item => item.code == value)[0].name) {
                                    this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                }
                            }

                            this._apiAsync('check-businessUnits', { filter: this.state.clients.filter(item => item.code == value)[0]._id })
                                .then((result) => {

                                    if (result.items.businesUnits) {

                                        this.setState({ enableEdit: true }, () => {

                                            this._apiAsync('get-businessUnits', { filter: Number(this.registerGetValue('clientCode')) }).then((result) => {
                                                if (result && result.items) {
                                                    this.setState({ businessUnits: result && result.items })
                                                    this.setState({ businessSearch: result && result.items })
                                                }
                                            })
                                        })
                                    } else {

                                        this.setState({ enableEdit: false })
                                    }
                                })

                            //potrebno je provjeriti rabat u uslovima i uporediti ga sa upisanom vrijednosti rabata
                            //ukoliko je veci upisan nego po uslovima, tada izlazi obavjestenje
                            let chosenClient = this.state.clients.filter(item => item.code == Number(this.registerGetValue('clientCode')))[0]
                            if (chosenClient && chosenClient._id && chosenClient.conditions) {
                                //citanje uslova
                                this.setState({ chosenClientCode: chosenClient.conditions })
                            }

                            //provjera da li je ukljucena knjizna obavjihest u registru komitenata
                            if (chosenClient && chosenClient._id && chosenClient.ko) {
                                this.setState({ chosenClientCodeKO: chosenClient.ko })
                            }

                        } else {
                            if (this.registerGetValue('clientName')) {
                                this.remoteOnChange('clientName', '')
                            }
                        }

                    })
                }

            })

    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive == 1 ?
                                <>Lista predračuna</>
                                :
                                <>Predračun</>
                        }
                    </h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-extrabutton">
                                <div className="title-options-group">
                                    <button onClick={async () => {
                                        // this.setState({ loadingPage: true })
                                        // const params = {}
                                        // for (const [key, value] of Object.entries(this._getParams())) {
                                        //     if (key === 'entries' || key === 'page') {
                                        //         continue;
                                        //     }
                                        //     params[key] = value;
                                        // }
                                        // const request = {
                                        //     filter: {
                                        //         entries: 10000,
                                        //         page: 0,
                                        //         additionalFilters: params
                                        //     },
                                        //     methods: 'post',
                                        //     url: 'data/estimation',
                                        //     fields: [
                                        //         { key: 'documentNumber', label: 'BROJ' },
                                        //         { key: 'estimationDate', label: 'DATUM' },
                                        //         { key: 'clientName', label: 'KOMITENT' },
                                        //         { key: 'amount', label: 'IZNOS' },
                                        //         { key: 'createdByUsername', label: 'KORISNIK' },
                                        //     ],
                                        //     title: "Predračun"
                                        // }
                                        // await this._apiAsync('generic-print', request)
                                        // this.setState({ loadingPage: false })
                                        this.setState({ printTable: true })

                                    }}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={async () => {
                                        // this.setState({ loadingPage: true })
                                        // const params = {}
                                        // for (const [key, value] of Object.entries(this._getParams())) {
                                        //     if (key === 'entries' || key === 'page') {
                                        //         continue;
                                        //     }
                                        //     params[key] = value;
                                        // }
                                        // const request = {
                                        //     filter: {
                                        //         entries: 10000,
                                        //         page: 0,
                                        //         additionalFilters: params
                                        //     },
                                        //     methods: 'post',
                                        //     url: 'data/estimation',
                                        //     fields: [
                                        //         { key: 'documentNumber', label: 'BROJ' },
                                        //         { key: 'estimationDate', label: 'DATUM' },
                                        //         { key: 'clientName', label: 'KOMITENT' },
                                        //         { key: 'amount', label: 'IZNOS' },
                                        //         { key: 'createdByUsername', label: 'KORISNIK' },
                                        //     ],
                                        //     title: "Predračun"
                                        // }
                                        // await this._apiAsync('generic-export', request)
                                        // this.setState({ loadingPage: false })
                                        // const exportResult = await this._apiAsync('generic-export', request)
                                        // if (exportResult && exportResult) {
                                        //     if (exportResult.success) {
                                        //         window.location.href = env.API_URL + `${exportResult.file}`;
                                        //     }
                                        // }
                                        this.setState({ exportTable: true })

                                    }}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                </div>
                            </div>

                            :
                            null
                    }

                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1 }, () => { this._onLoad() })}>
                            Lista predračuna
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive != 2) {
                                this.setState({ buttonActive: 2, estimationForm: null, items: [] }, () => this.goToFormTab())
                            }
                        }}>
                            Predračun
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder

                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}


                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'estimationDate', 'item.amount', 'item.facturePrice', 'item.discount', 'item.article', 'businessUnit', 'businessUnitName', 'clientCode', 'item.serialNumber']}
                                    fieldTracker={async (name, value) => {
                                        if (name == 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, estimationForm: null }, () => {
                                                this.remoteOnChange('businessUnit', '')
                                                this.remoteOnChange('clientCode', '')
                                                this.remoteOnChange('deliveryDate', '')
                                                this.remoteOnChange('reservationDate', '')
                                                this.remoteOnChange('item.itemNumber', '')
                                                this.remoteOnChange('item.article', '')
                                                this.remoteOnChange('item.serialNumber', '')
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.amount', '')
                                                this.remoteOnChange('item.stock', '')
                                                this.remoteOnChange('item.price', '')
                                                this.remoteOnChange('item.total', '')
                                                this.setState({ estimationForm: {} })
                                            })
                                        }

                                        if (name == 'documentNumber' && value) {
                                            if (value) { //!= this.state.prevDocumentNumber
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                                    //ukoliko postoji rezultat citanja dokumenta
                                                    if (res && res.result && res.result._id) {

                                                        this.setState({ estimationForm: null }, () => {
                                                            this.setState({ estimationForm: res.result }, () => {

                                                                //poziva se da bi validacija ispravno radila
                                                                this.metodaClient(Number(this.state.estimationForm.clientCode)).then(() => {
                                                                    this.readClientAndBusinessUnits(Number(this.state.estimationForm.clientCode)).then(() => {

                                                                        this.remoteOnChange('businessUnit', this.state.estimationForm.businessUnit)
                                                                        this.remoteOnChange('businessUnitName', this.state.estimationForm.businessUnitName)
                                                                    })
                                                                })

                                                                if (this.state.estimationForm && this.state.estimationForm.businessUnit) {
                                                                    this.setState({ enableEdit: true })
                                                                } else {
                                                                    this.setState({ enableEdit: false })
                                                                }

                                                                if (res.result.items && res.result.items.length) {
                                                                    this.setState({
                                                                        items: res.result.items,

                                                                    }, () => {
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        items: [],
                                                                    }, () => {
                                                                        this.remoteOnChange('item.itemNumber', this.state.itemNumber)
                                                                    })
                                                                }

                                                            })
                                                        })

                                                    } else if (this.state.prevDocumentNumber != value /*&& this.state.entranceForm._id*/) {
                                                        this.setState({ estimationForm: null }, () => {
                                                            this.setState({ estimationForm: { documentNumber: value } }, () => {

                                                                this.setState({ items: [], estimationForm: {} }, () => {
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            this.remoteOnChange('estimationDate', this.state.dateNow)
                                                                        }

                                                                    })
                                                                })

                                                            })
                                                        })

                                                    }

                                                    this.setState({ prevDocumentNumber: value })
                                                })

                                            }
                                        }

                                        if (name == 'item.amount' && value) {
                                            if (value) {

                                                let kolicina = parseFloat(value)
                                                let cijena = parseFloat(this.registerGetValue('item.facturePrice'))
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                            }

                                        }

                                        if (name == 'item.facturePrice') {
                                            if (this.state.pomocnaFakturnaCijena != value) {
                                                let kolicina = parseFloat(this.registerGetValue('item.amount'))
                                                let cijena = parseFloat(value)
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                                this.setState({ pomocnaFakturnaCijena: value })
                                            }

                                        }

                                        if (name == 'item.article' && value) {

                                            if (value) {

                                                let artikli = await this._apiAsync('get-articles', { filter: value })
                                                if (artikli && artikli.items) {
                                                    this.setState({ products: artikli && artikli.items })
                                                }

                                                if (this.state.products && this.state.products.filter(item => item.code == value).length != 0) {
                                                    this.setState({ chosenManufacturerCode: this.state.products.filter(item => item.code == value)[0].manufacturer })
                                                    this._apiAsync('get-price', { article: this.state.products.filter(item => item.code == value)[0]._id }).then((res) => {
                                                        this.remoteOnChange('item.facturePrice', res.price.sellPrice)
                                                        this.remoteOnChange('item.stock', (res.price.quantity))
                                                        this.setState({ articleAmmount: Number(res.price.quantity) })
                                                    })
                                                }


                                                let series = await this._apiAsync('get-series', { article: value, withoutSeries: true, deadline: true });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items.filter((item) => { return item.ammount > 0 }) }, () => {
                                                        this.setState({
                                                            seriesSum: this.state.series.filter(item => !item.deadline).reduce((sum, currentValue) => {
                                                                return sum + currentValue.ammount
                                                            }, 0)
                                                        })
                                                    })
                                                } else {
                                                    this.setState({ series: [] })
                                                }

                                            }
                                        }

                                        if (name == 'item.serialNumber') {
                                            if (value) {
                                                let article = this.registerGetValue('item.article');
                                                let series = await this._apiAsync('get-series', { article: article, filter: value });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items.filter(item => item.ammount > 0) }, () => {
                                                        if (this.state.series && this.state.series.length) {
                                                            if (this.state.series.filter(item => item.serialNumber == value).length == 1) {
                                                                let item = this.state.series.filter(item => item.serialNumber == value)[0];
                                                                if (item && item.newExpirationDate) {
                                                                    // if (this.registerGetValue('item.deadline') != item.newExpirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.newExpirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                    // }
                                                                } else if (item && item.expirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.expirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                }

                                                            } else {
                                                                if (this.registerGetValue('item.deadline')) {
                                                                    this.remoteOnChange('item.deadline', '')
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    if (this.registerGetValue('item.deadline')) {
                                                        this.remoteOnChange('item.deadline', '')
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.deadline')) {
                                                    this.remoteOnChange('item.deadline', '')
                                                }
                                            }
                                        }

                                        if (name == 'item.serialNumber' && !value) {
                                            let series = await this._apiAsync('get-series', { article: Number(this.registerGetValue('item.article')), withoutSeries: true, deadline: true });
                                            if (series && series.items) {
                                                this.setState({ series: series.items })
                                            } else {
                                                this.setState({ series: [] })
                                            }
                                        }


                                        if (name == 'item.discount' && value) {
                                            if (value) {
                                                let kolicina = parseFloat(this.registerGetValue('item.amount'))
                                                let cijena = parseFloat(this.registerGetValue('item.facturePrice'))
                                                let rabat = parseFloat(value)
                                                let total = kolicina * cijena - rabat
                                                this.remoteOnChange('item.total', total)
                                            } else {
                                                let kolicina = parseFloat(this.registerGetValue('item.amount'))
                                                let cijena = parseFloat(this.registerGetValue('item.facturePrice'))
                                                // let rabat = parseFloat(value)
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                            }
                                        }

                                        if (name == 'clientCode' && !value) {
                                            this.setState({ businessUnits: [] })
                                            this.remoteOnChange('clientName', '')
                                            this.setState({ enableEdit: false })
                                            this.remoteOnChange('businessUnit', '')
                                        }

                                        if (name == 'clientCode' && value) {
                                            this.readClientAndBusinessUnits(value)

                                        }

                                        if (name == 'businessUnit' && !value) {
                                            this.remoteOnChange('businessUnitName', '')
                                        }


                                        if (name == 'businessUnit' && value) {

                                            this._apiAsync('get-businessUnits', { filter: Number(this.registerGetValue('clientCode')) }).then((result) => {
                                                if (result && result.items) {
                                                    this.setState({ businessUnits: result && result.items }, () => {
                                                        if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                            this.remoteOnChange('businessUnitName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                                        } else {
                                                            this.remoteOnChange('businessUnitName', '')
                                                        }
                                                    })
                                                }
                                            })
                                            // if (value) {

                                            //     if (this.state.businessUnits.filter(item => item.code == value).length) {
                                            //         this.remoteOnChange('businessUnitName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                            //     } else {
                                            //         this.remoteOnChange('businessUnitName', '')
                                            //     }
                                            // }
                                        }

                                    }}
                                    onSubmit={(data, notFinished) => {
                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                delete data.item;
                                            }
                                        }


                                        if (data._id) {
                                            this._apiAsync('update-document', data).then((res) => {

                                                this.setState({ estimationForm: null }, () => this.setState({
                                                    estimationForm: res.res
                                                }, () => { this.setState({ items: res.res.items }) }))

                                                if (res.res.items && res.res.items.length) {
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                            this.remoteOnChange('item.article', '')
                                                            this.remoteOnChange('item.serialNumber', '')
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }
                                            })


                                        } else {
                                            this._apiAsync('insert-new', data).then((res) => {
                                                if (res.result && res.result._id) {
                                                    this.setState({ estimationForm: null }, () => this.setState({ estimationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] }))
                                                    if (res.result.items && res.result.items.length) {
                                                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                this.remoteOnChange('item.article', '')
                                                                this.remoteOnChange('item.serialNumber', '')
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.itemNumber', 1)
                                                    }

                                                }
                                            })



                                        }

                                    }}
                                    initialValues={this.state.estimationForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand estimatiom-ltc-fix',
                                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                                    children: [this.state.estimationForm && this.state.estimationForm._id && !this.state.estimationForm.notFinished ?
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            children: <span>Proknjiži</span>,
                                                                            className: 'posting-button',
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                            disabled: false,
                                                                            action: 'custom',
                                                                            onClick: () => {
                                                                                if (this.state.estimationForm && this.state.estimationForm._id && !this.state.estimationForm.notFinished) {
                                                                                    this.setState({ infoModal: true })
                                                                                }
                                                                                // if (this.state.estimationForm && this.state.estimationForm._id && !this.state.estimationForm.notFinished) {
                                                                                //     let temp = this.state.estimationForm

                                                                                //     //izbrisati ID predracuna  i broj predracuna
                                                                                //     temp.factureDate = temp.estimationDate
                                                                                //     temp.factureDateTs = temp.estimationDateTs
                                                                                //     temp.subClass = 'Predračun'

                                                                                //     for (let i = 0; i < temp.items.length; i++) {
                                                                                //         temp.items[i].price = temp.items[i].facturePrice
                                                                                //         delete temp.items[i].facturePrice
                                                                                //     }


                                                                                //     delete temp.documentNumber
                                                                                //     delete temp.estimationDate
                                                                                //     delete temp.estimationDateTs

                                                                                //     this._apiAsync('insert-new-output', { temp }).then((result) => {
                                                                                //         console.log('VRACENI PODACI....', result.result)
                                                                                //         if (result) {
                                                                                //             //ocistiti polja
                                                                                //             this.setState({ estimationForm: null }, () => {
                                                                                //                 this.setState({ estimationForm: {} })
                                                                                //                 this.setState({ items: [] }, () => {
                                                                                //                     this.remoteOnChange('documentNumber', result.result.freeDocumentNumber)
                                                                                //                     this.remoteOnChange('item.itemNumber', 1)
                                                                                //                 })
                                                                                //             })
                                                                                //             //procitati novi slobodan broj dokumenta
                                                                                //         }
                                                                                //     })


                                                                                // } else {
                                                                                //     console.log('POCETAK PROVJERA...u else...', this.state.estimationForm)
                                                                                // }

                                                                            }
                                                                        } : {}
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom estimation-buttons-fix',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.submit()
                                                                                                this.setState({ knjiz: false })
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.estimationForm && this.state.estimationForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput().then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })
                                                                                                }
                                                                                                )

                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },
                                                                                        // {
                                                                                        //     type: 'button',
                                                                                        //     width: { lg: 4, sm: 6, xs: 6 },
                                                                                        //     action: 'submit',
                                                                                        //     children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                        //     className: 'options-button'
                                                                                        // }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-1",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'estimationDate',
                                                            label: 'Broj dokumenta',
                                                            // textType: 'number',
                                                            integer: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'estimationDate',
                                                            next: 'deliveryDate',
                                                            label: 'Datum predračuna',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Datum nivelacije mora biti unešen!"),
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'deliveryDate',
                                                            next: 'clientCode',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Datum isporuke mora biti unesen!"

                                                                    if (moment(value, 'DD.MM.YYYY').unix() < moment(this.registerGetValue('estimationDate'), 'DD.MM.YYYY').unix()) {
                                                                        return "Mora biti veći ili jednak od datuma fakturisanja";
                                                                    }
                                                                },
                                                                validateDate('Datum isporuke nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: this.state.enableEdit ? 'businessUnit' : 'item.article',
                                                                            label: 'Šifra komitenta',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            title: 'Pregled komitenata',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            entrance: true,
                                                                            control: true,
                                                                            enableEdit: this.state.enableEdit,
                                                                            changeState: (callback) => {
                                                                                this.setState({ enableEdit: !this.state.enableEdit }, () => {
                                                                                    if (callback) {
                                                                                        callback()
                                                                                    }
                                                                                })

                                                                            },
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Šifra komitenta mora biti unesen!'),

                                                                                (value) => {

                                                                                    this.metodaClient(value).then(() => {
                                                                                        if (this.state.clientsSearch != 0 && this.state.clientsSearch.filter(item => item.code == value).length == 0 && this.state.clientsSearch.filter(item => item.name == value).length == 0) {
                                                                                            return 'Neispravan unos!!!!'
                                                                                        }
                                                                                    })

                                                                                    if (this.state.clientsSearch.length == 0 && this.state.clients.filter(item => item.code == value).length == 0 && this.state.clients.filter(item => item.name == value).length == 0) {
                                                                                        return "Neispravan unos!"
                                                                                    }
                                                                                    // if (this.state.clientsSearch.length != 0 && this.state.clientsSearch.filter(item => item.code == Number(value)).length == 0 && this.state.clientsSearch.filter(item => item.name == value).length == 0) {
                                                                                    //     return "Neispravan unos!!"
                                                                                    // }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            next: 'businessUnit',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [


                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-2-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'businessUnit',
                                                                            name: 'businessUnit',
                                                                            next: 'item.article',
                                                                            label: 'Poslovna jedinica',
                                                                            textType: 'number',
                                                                            metoda: (value) => { this.metodaBusiness(value) },
                                                                            dataModal: this.state.businessSearch,
                                                                            title: 'Pregled poslovnih jedinica',
                                                                            values: [...this.state.businessUnits.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            readOnly: this.state.enableEdit ? false : true,
                                                                            asyncValidation: true,
                                                                            validate: [

                                                                                (value) => {
                                                                                    if (value) {
                                                                                        if (this.state.businessSearch.length == 0 && this.state.businessUnits.filter(item => item.code == value).length == 0 && this.state.businessUnits.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.businessSearch.length != 0 && this.state.businessSearch.filter(item => item.code == value).length == 0 && this.state.businessSearch.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }

                                                                                }

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessUnitName',
                                                                            next: 'item.ordinalNumber',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [

                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.article',
                                                            label: 'Redni broj',
                                                            // textType: 'number',
                                                            integer: true,
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Redni broj mora biti unešen!"),
                                                            ]
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            asyncValidation: true,
                                                            validate: [
                                                                // required("Artikal mora biti unešen!"),
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;

                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    // if (!(item && Object.keys(item).length > 0 || !this.state.estimationForm._id) && this.state.estimationForm._id) {
                                                                    //     checkValidation = true;
                                                                    // }
                                                                    // if ((!value || value == '') && checkValidation)
                                                                    //     return "Artikal mora biti unesena!"
                                                                    if (!(item && Object.keys(item).length > 0 || !this.state.estimationForm._id) && this.state.estimationForm._id && this.state.knjiz) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"
                                                                    this.setState({ knjiz: true })
                                                                },

                                                                async (value) => {

                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;

                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if (checkValidation) {
                                                                        let series = await this._apiAsync('get-series', { article: Number(value), withoutSeries: true, deadline: true });
                                                                        if (series && series.items) {
                                                                            this.setState({ series: series.items })
                                                                        } else {
                                                                            this.setState({ series: [] })
                                                                        }

                                                                        if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!"
                                                                        }
                                                                        if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!!"
                                                                        }

                                                                        this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                                    }


                                                                }

                                                            ]
                                                        },
                                                        {
                                                            type: 'series',
                                                            name: 'item.serialNumber',
                                                            next: 'item.amount',
                                                            metoda: (value) => { this.metodaSeries(value) },
                                                            dataModal: this.state.series,
                                                            title: 'Pregled serija',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                // async (value) => {
                                                                //     try {
                                                                //         if (value && this.registerGetValue('item.article')) {
                                                                //             let res = await this._apiAsync('validate-serial-number', { value: value, code: this.registerGetValue('item.article') });
                                                                //             if (res.status == 400) {
                                                                //                 return 'Šifra je zauzeta'
                                                                //             }
                                                                //             return undefined;
                                                                //         }

                                                                //     } catch (e) {
                                                                //         return undefined;
                                                                //     }
                                                                // },
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if (checkValidation) {
                                                                        if (this.state.series.length != 0 && !value && this.state.articleAmmount == this.state.seriesSum) {
                                                                            return 'Serija mora biti unešena!!'
                                                                        }

                                                                        if (this.state.series.length == 0 && this.state.numberOfSeries != 0) {
                                                                            return 'Neispravan unos!!'
                                                                        }

                                                                        if (this.state.numberOfSeries == 0 && value) {
                                                                            return 'Neispravan unos!'
                                                                        }
                                                                    }


                                                                },

                                                                () => {

                                                                    if (this.state.series && this.state.series.length != 0) {


                                                                        this.remoteOnChange('item.stock', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.ammount)

                                                                        this.remoteOnChange('item.deadline', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.expirationDate)

                                                                    }

                                                                }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.deadline',
                                                            next: 'item.amount',
                                                            // values: [...this.state.seriesDeadline.map((element) => {
                                                            //     let obj = {
                                                            //         name: element.expirationDate,
                                                            //         value: element.expirationDate
                                                            //     }
                                                            //     return obj
                                                            // })],
                                                            readOnly: true,
                                                            label: 'Rok',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Cijena mora biti unesena!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.amount',
                                                                            next: 'item.facturePrice',
                                                                            label: 'Količina',
                                                                            // textType: 'number',
                                                                            integer: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // required('Unesite kolicinu!'),
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    for (var key in item) {
                                                                                        if (!item[key]) {
                                                                                            delete item[key];
                                                                                        }
                                                                                    }
                                                                                    let checkValidation = false;
                                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Količina mora biti unesena!"
                                                                                },


                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            next: 'item.discount',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.facturePrice',
                                                                            next: 'item.discount',
                                                                            label: 'Cijena',
                                                                            // textType: 'number',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    for (var key in item) {
                                                                                        if (!item[key]) {
                                                                                            delete item[key];
                                                                                        }
                                                                                    }
                                                                                    let checkValidation = false;
                                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Cijena mora biti unesena!"
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.fundPrice',
                                                                            next: 'item.discount',
                                                                            label: 'Fond cijena',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }

                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.discount',
                                                            next: 'submitForm',
                                                            afterSubmitFocus: 'item.article',
                                                            label: 'Rabat',
                                                            // textType: 'number',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [


                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [

                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }


                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                                <InfoModal
                                    isOpen={this.state.infoModal}
                                    title="UPOZORENJE!"
                                    info={'Jeste li sigurni da želi proknjižiti predračun?'}
                                    buttons={[
                                        {
                                            text: "OK",
                                            onClick: () => {
                                                if (this.state.estimationForm && this.state.estimationForm._id && !this.state.estimationForm.notFinished) {
                                                    let temp = this.state.estimationForm

                                                    //izbrisati ID predracuna  i broj predracuna
                                                    temp.factureDate = temp.reservationDate
                                                    temp.factureDateTs = temp.reservationDateTs
                                                    temp.subClass = 'Rezervacija'

                                                    for (let i = 0; i < temp.items.length; i++) {
                                                        temp.items[i].price = temp.items[i].facturePrice
                                                        delete temp.items[i].facturePrice
                                                    }


                                                    delete temp.documentNumber
                                                    delete temp.estimationDate
                                                    delete temp.estimationDateTs

                                                    this._apiAsync('insert-new-output', { temp }).then((result) => {
                                                        if (result) {
                                                            //ocistiti polja
                                                            this.setState({ estimationForm: null }, () => {
                                                                this.setState({ estimationForm: {} })
                                                                this.setState({ items: [] }, () => {
                                                                    this.remoteOnChange('documentNumber', result.result.freeDocumentNumber)
                                                                    this.remoteOnChange('deliveryDate', '')
                                                                    this.remoteOnChange('clientCode', '')
                                                                    this.remoteOnChange('businessUnit', '')
                                                                    this.remoteOnChange('item.itemNumber', 1)
                                                                })
                                                            })

                                                        }
                                                        this.props[0].history.push({
                                                            pathname: '/output',
                                                            search: '?entries=10&status=1,2,4',

                                                        })
                                                    })

                                                }

                                            }
                                        },
                                        {
                                            text: "Izlaz",
                                            onClick: () => { this.setState({ infoModal: false }) }
                                        }
                                    ]}
                                >

                                </InfoModal>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'amount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'facturePrice', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {
                                                    this.metoda(Number(item.article)).then(async () => {
                                                        let series = await this._apiAsync('get-series', { article: item.article });
                                                        if (series && series.items) {
                                                            this.setState({ series: series.items })
                                                        } else {
                                                            this.setState({ series: [] })
                                                        }
                                                    })

                                                    let estimationForm = this.state.estimationForm;
                                                    estimationForm.item = item;
                                                    this.setState({ estimationForm: null }, () => {
                                                        this.setState({ estimationForm })
                                                    })

                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => { this.setState({ deleteModal: item }) }
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-item', { _id: this.state.estimationForm._id, itemNumber: this.state.deleteModal.itemNumber }).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ estimationForm: null, deleteModal: null }, () => {
                                                    this.setState({ estimationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                                                })
                                                if (res.result.items && res.result.items.length) {
                                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }

                                            }
                                        })
                                    }} >
                                    Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                                </DeleteModal>




                                <DeleteModal
                                    isOpen={this.state.deleteDocumentModal}
                                    toggle={() => this.setState({ deleteDocumentModal: null })}
                                    handler={() => {
                                        if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                            this.setState({ deleteDocumentModal: null })
                                            this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                            return;
                                        } else {
                                            this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                                this.setState({ deleteDocumentModal: null, estimationForm: null, items: [], firstFreeNumber: null }, () => {
                                                    this.setState({ estimationForm: {} }, () => this._onLoad())
                                                    // this._onLoad()
                                                })

                                            })
                                        }
                                    }} >
                                    Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                                </DeleteModal>
                            </div>

                            {
                                this.state.descriptionModal ?
                                    <Modal isOpen={this.state.descriptionModal} size='lg' className="modal-wrap">
                                        <ModalHeader>
                                            <h4>Opis</h4>
                                            <div className='close-button' onClick={() => this.setState({ descriptionModal: false, description: '' })}>&times;</div>
                                        </ModalHeader>
                                        <ModalBody>
                                            <div className="dependent-costs-wrap">

                                                <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Label>Unesite opis</Label>
                                                    <Input type='textarea' style={{ height: 100 }} value={this.state.description} onChange={(e) => {
                                                        let value = e.target.value;

                                                        this.setState({ description: value })
                                                    }}>

                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>


                                            <Button
                                                onClick={() => {
                                                    if (this.state.estimationForm && this.state.estimationForm._id) {
                                                        this._apiAsync('update-document', { description: this.state.description, _id: this.state.estimationForm._id }).then((res) => {

                                                            if (res.res && res.res._id) {
                                                                this.setState({ estimationForm: null }, () => {

                                                                    this.setState({ estimationForm: res.res, items: res.res.items && res.res.items.length ? res.res.items : [], products: [] })
                                                                })
                                                                if (res.res.items && res.res.items.length) {
                                                                    // this.remoteOnChange('item.itemNumber', (res.res.items.length + 1))
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                        }

                                                                    })
                                                                } else {
                                                                    this.remoteOnChange('item.itemNumber', 1)
                                                                }

                                                            }
                                                        })

                                                    }
                                                    this.setState({ descriptionModal: null })

                                                }}>Snimi</Button>
                                        </ModalFooter>
                                    </Modal>
                                    :
                                    null

                            }

                            <div className="footer">
                                <div className="footer-left">
                                    {this.state.estimationForm && this.state.estimationForm._id ?
                                        <button className="blue-button" onClick={() => this.setState({ descriptionModal: true, description: this.state.estimationForm.description ? String(this.state.estimationForm.description) : '' })}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>OPIS</p>
                                        </button>
                                        :
                                        null
                                    }
                                    {
                                        this.state.estimationForm && this.state.estimationForm._id ?
                                            <button onClick={() => this.setState({ deleteDocumentModal: this.state.estimationForm })}>
                                                <div className="box box-red">
                                                    <Isvg src={garbageOpionIcon} />
                                                </div>
                                                <p>BRISANJE DOKUMENTA</p>
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                                <div className="footer-right">
                                    {/*
                            <div className="info-transparent">
                                <div className="col-info">
                                    <p>Vrijednost</p>
                                    <span>543,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    <span>45,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>RUC</p>
                                    <span>45,00 KM</span>
                                </div>
                            </div>
                            */}
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterBusiness} onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                    this.updateParams('business', this.state.filterBusiness)
                                                })} placeholder={'Poslovna'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} placeholder={'Korisnik'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterUser: '',
                                                filterBusiness: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null },
                                                { name: 'client', value: null }, { name: 'business', value: null }, { name: 'user', value: null }
                                                ])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'estimationDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'total', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}

                                onClick={(item) => {
                                    if (item && item.documentNumber) {
                                        this.setState({ estimationForm: null, buttonActive: 2 }, () => {
                                            this.setState({ estimationForm: { documentNumber: item.documentNumber } }, () => {
                                                this.remoteOnChange('documentNumber', item.documentNumber)
                                            })
                                        })
                                    }
                                }}

                                items={this.state.estimationItems}
                                rawItems={this.state.estimationItems}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/estimation', title: 'Predračun' }}
                            ></ListBuilder>

                        </>
                }



            </div>
        )
    }
}

export default Page(EstimationPage);
