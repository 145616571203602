
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

import DateField from '../../form-builder/fields/date';


class ColdChainPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [
                {
                    _id: 1,
                    articleCode: 12312,
                    barCode: 1212132131,
                    name: 'test',
                    serialNumber: '213',
                    dateString: '21.12.2021',
                    businessUnit: 123,
                    controledBy: 'Pero Peric',
                    time: '14:15'
                }


            ],
            series: [],
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (this.props.selectedYear != prevProps.selectedYear) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Izvještaj kontrolisanja</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound" style={{ padding: 0 }}>
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignItems: 'center', padding: '15px 20px' }}>
                                <div className="list-builder-filters" style={{ marginBottom: 0, alignItems: 'flex-end' }}>
                                    <div className="field-strap-wrap" style={{ width: 150 }}>
                                        <DateField
                                            additionalClassName={'date-input-width-150'}
                                            onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                this.updateParams('dateFrom', this.state.filterDateFrom)
                                            })}
                                            value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                            placeholder={'Datum od'}
                                        ></DateField>
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 150 }}>
                                        <DateField
                                            additionalClassName={'date-input-width-150'}
                                            onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                this.updateParams('dateTo', this.state.filterDateTo)
                                            })}
                                            value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo}
                                            placeholder={'Datum do'}
                                        ></DateField>
                                    </div>


                                    {/* <div className="field-strap-wrap" style={{ width: 200 }}>
                                        <Input style={{ width: 200 }} type='date' value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                            this.updateParams('dateFrom', this.state.filterDateFrom)
                                        })} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 200 }}>
                                        <Input style={{ width: 200 }} type='date' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                            this.updateParams('dateTo', this.state.filterDateTo)
                                        })}>

                                        </Input>
                                    </div> */}
                                    <div className="field-strap-wrap" style={{ width: 300 }}>
                                        <Input style={{ width: 300 }} type='text' value={this.state.filterUser != null ? this.state.filterUser : params.user} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                            this.updateParams('user', this.state.filterUser)
                                        })} placeholder={'Korisnik'} />
                                    </div>

                                    <div className="clear-filters" style={{ top: 0, marginLeft: 20 }} onClick={() => {

                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterUser: '',

                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'user', value: null }])

                                        })

                                    }}>
                                        <a><span>&times;</span> Ukloni filter</a>
                                    </div>

                                </div>
                                <div>
                                    <button className="blue-button width-100" onClick={() => { }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>PREGLED</p>
                                    </button>
                                </div>


                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                maxHeight={'80vh'}
                                fields={[
                                    { type: 'text', name: 'username', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'documentsCollected', label: 'SAKUPIO DOKUMENATA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'ariclesCollected', label: 'SAKUPIO ARTIKLE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'documentsPacked', label: 'PAKOVAO DOKUMENATA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'ariclesPacked', label: 'PAKOVAO ARTIKLE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'documentsControlled', label: 'KONTROLISAO DOKUMENATA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'ariclesControlled', label: 'KONTROLISAO ARTIKLE'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },

                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        // {
                                        //     component: <Isvg src={arrowDown} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ coldChainPreview: item })
                                        // },
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>

                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(ColdChainPage);
