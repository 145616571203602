import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';

import ListBuilder from '../list-builder/index.js';
import editIcon from '../assets/edit-icon.svg';
import garbageIcon from '../assets/garbage.svg';
import Isvg from 'react-inlinesvg';
// import saveIcon from '../../assets/save.svg';

import error from '../assets/error.png';

class SearchModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.childRef = React.createRef()
    }

    changeFocus = (e, index, item) => {

        //citanje svih redova iz tabele(ukljucujuci i red u kome se nalaze nazivi kolona)
        let rows = document.getElementsByClassName('table table-hover')[1].rows

        if (e.key == 'ArrowDown') {

            if ((index + 2) < rows.length) {
                rows[index + 2].focus()
                document.getElementById('textFieldSearch').value = rows[index + 2].childNodes[1].innerText
            } else {
                rows[1].focus()
                document.getElementById('textFieldSearch').value = rows[1].childNodes[1].innerText
            }
        }

        if (e.key == 'ArrowUp') {

            if (index == 0) {
                rows[rows.length - 1].focus()
                document.getElementById('textFieldSearch').value = rows[rows.length - 1].childNodes[1].innerText
            } else {
                rows[index].focus()
                document.getElementById('textFieldSearch').value = rows[index].childNodes[1].innerText
            }
        }

        if (e.key == 'Enter') {
            this.props.handler(item)
            this.props.toggle()
        }
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} style={{ width: 1200 }}>
                {
                    this.props.title ?
                        <ModalHeader>{this.props.title}</ModalHeader>
                        :
                        null
                }

                <ModalBody className="info-modal">
                    {/* <img src={error} /> */}
                    <div className='list-builder-search-modal'>
                        <Input
                            id='textFieldSearch'
                            onChange={(e) => {
                                this.props.metoda(e.target.value)
                            }}

                            autoFocus={true}
                            placeholder={this.props.label}
                            onKeyUp={(e) => {

                                if (e.key == 'ArrowDown') {
                                    let rows = document.getElementsByClassName('table table-hover')[1].rows
                                    rows[1].focus()
                                    document.getElementById('textFieldSearch').value = rows[1].childNodes[1].innerText

                                }
                            }}
                        ></Input>
                    </div>
                    <div className="message">
                        <ListBuilder
                            tabs={false}
                            sort={false}
                            hideFilters={true}
                            lang={this.props.lang}
                            loading={this.state.loading}
                            maxHeight={'50vh'}
                            total={this.state.total}
                            showNumeration={false}
                            hidePagination={true}
                            changeFocus={this.changeFocus}
                            forModal={1}
                            fields={[
                                { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'quantity', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'sellPrice', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                            ]}
                            items={this.props.items}

                            onClick={(item) => {
                                this.props.handler(item)
                                this.props.toggle()

                            }}

                            params={{ entries: 10, page: 0 }}
                            // sortField={'code'}
                            // sortType={'1'}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}
                        >

                        </ListBuilder>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <Button className='info-modal-button' color='primary' onClick={this.props.toggle}>Zatvori</Button>



                </ModalFooter>
            </Modal>
        )
    }
}

export default SearchModal;