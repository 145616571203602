import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        itemsClients: result.items,
                    }
            })
        }
    },

    'get-fund-prices': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/reports/return/fund/prices', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        clientCode: query && query.clientCode,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        manufacturer: query && query.manufacturer,
                        articleFrom: query && query.articleFrom,

                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    return {
                        items: result.items,
                        total: result.total,
                        saldo: result.saldo
                    }
                } else {
                    return {
                        items: result.items,
                        total: result.total,
                        saldo: result.saldo
                    }
                }

            })
        }
    },
    'get-fund-saldo': {
        onLoad: false,
        action: (query) => {
            console.log(query);
            return fetch(env.API_URL + '/data/reports/return/fund/prices/saldo', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        clientCode: query && query.clientCode,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        manufacturer: query && query.manufacturer,
                        articleFrom: query && query.articleFrom,

                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    return {
                        saldo: result.saldo
                    }
                } else {
                    return {
                        saldo: result.saldo
                    }
                }

            })
        }
    },

    'clientsSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/clients/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 50,
                    filter: data.value
                })
            }).then(parseJSON).then((response) => {
                return {
                    clientsSearch: response.result.items
                }
            })
        }
    },

    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 50,
                    filter: data.value,
                    withStock: true
                })
            }).then(parseJSON).then((response) => {
                return {
                    articlesSearch: response.result.items
                }
            })
        }
    },


    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/print/return/fund/prices', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        link: result.link,

                    }
            })
        }
    },

}