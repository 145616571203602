// import { Component } from "react";

// import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
// import Isvg from 'react-inlinesvg';
// import arrow from '../../assets/arrow-down.svg';

// class Select extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {}
//     }

//     render() {
//         return (
//             <FormGroup className="position-relative">
//                 <Label>{this.props.label}</Label>
//                 <div className="select-field">
//                     <select
//                         className={`form-control`}
//                         // className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`}
//                         disabled={this.props.disableValue}
//                         ref={(node) => this.props.setFieldRef(node)}
//                         onChange={(e) => this.props.onChange(e.target.value)}
//                         value={this.props.value ? this.props.value : ''}
//                         placeholder={this.props.placeholder}

//                         onKeyUp={(e) => {
//                             if (e.key == this.props.finishKey) {
//                                 this.props.focusNext();
//                             }
//                         }}

//                     >
//                         {
//                             this.props.values.map((item, idx) => {
//                                 return (
//                                     <option value={item.value}>{item.name}</option>
//                                 )
//                             })
//                         }
//                     </select>
//                     <Isvg src={arrow} />
//                 </div>
//                 {this.props.error && this.props.touched ?
//                     <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
//                     :

//                     null
//                 }

//             </FormGroup>
//         )
//     }
// }

// export default Select;


import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

import { uuid } from 'uuidv4'

class Text extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen: false,
            valueText: null,
            inputID: uuid(),
            selectedValue: null
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // if (this.props.value != prevProps.value && !this.props.value) {
        //     this.setState({ isOpen: false })
        // }
        // console.log('VRIJEDNOST VALUE POSLAT SA INDEXA.....', (prevProps.value != this.props.value && this.props.value))
        if (prevProps.value != this.props.value && this.props.value) {
            let value = this.props.value;
            let values = this.props.values;

            if (values && values.filter(item => item.value == value).length && values.filter(item => item.value == value)[0].name && this.state.valueText != values.filter(item => item.value == value)[0].name) {
                this.setState({ valueText: values.filter(item => item.value == value)[0].name })
            } else {
                this.setState({ valueText: this.props.value })
            }
        } else if (this.state.valueText) {
            // console.log('VRIJEDNOST VALUE POSLAT SA INDEXA.....',this.props.label)
            this.setState({ valueText: null })
        }

        // if(!this.props.value){
        //     this.setState({ valueText})
        // }
        if (prevState.selectedValue != this.state.selectedValue && this.state.selectedValue) {
            let values = [];
            if (this.props.values && this.props.values.length) {
                if (!this.props.value) {
                    values = this.props.values.filter(el => el.name && el.value != -1)
                } else {
                    values = this.props.values.filter(el => el.name && el.value != -1 && (String(el.name).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1 || (!this.props.filterOnlyByName && String(el.value).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1)))
                }

            }
            if (values && values.length && this.refDropdown) {
                let prevIndex;
                if (prevState.selectedValue)
                    prevIndex = values.findIndex(el => el.value == prevState.selectedValue)

                let index = values.findIndex(el => el.value == this.state.selectedValue)
                let elementId = `select-item-${index}`
                if (this.refDropdown.getBoundingClientRect && document.getElementById(elementId) && document.getElementById(elementId).getBoundingClientRect) {
                    let refInfo = this.refDropdown.getBoundingClientRect();
                    let elementInfo = document.getElementById(elementId).getBoundingClientRect()
                    let minPosition = 0;
                    let maxPosition = 0;
                    minPosition = refInfo.top;
                    maxPosition = refInfo.top + refInfo.height - 24;
                    let elementPosition = 0;
                    elementPosition = elementInfo.top;
                    let maxElements = (maxPosition - minPosition) / 24 - 1;


                    if (prevIndex && prevIndex < index) {
                        if (maxElements < index) {
                            this.refDropdown.scrollTop = 24 * (index - maxElements);
                        }
                    } else if (prevIndex && prevIndex > index) {
                        if (minPosition > elementPosition)
                            this.refDropdown.scrollTop -= 24;
                    }
                }



            }
        }

    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        if (this.props.value) {
            let value = this.props.value;
            let values = this.props.values;
            if (values && values.filter(item => item.value == value).length && values.filter(item => item.value == value)[0].name && this.state.valueText != values.filter(item => item.value == value)[0].name) {
                this.setState({ valueText: values.filter(item => item.value == value)[0].name })
            } else {
                this.setState({ valueText: this.props.value })
            }
        } else if (this.state.valueText) {
            this.setState({ valueText: null })
        }

    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {
        // console.log(this.props.values)
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName}`} >
                <div ref={this.wrapperRef} className={this.props.arrow ? 'field-strap-wrap field-strap-wrap-select' : "field-strap-wrap"}>
                    <Label>{this.props.label}</Label>

                    <input autoComplete="off" id={this.props.inputID ? this.props.inputID : this.state.inputID} className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control bg-arrow`} type={this.props.textType ? this.props.textType : "text"}   //(this.props.touched && this.props.error) || (this.props.value && this.props.values && this.props.values.filter(item => item.value == this.props.value).length == 0)
                        ref={(node) => {
                            if (this.props.setFieldRef)
                                this.props.setFieldRef(node)
                        }}
                        onKeyUp={(e) => {
                            if (e.key == this.props.finishKey) {
                                this.setState({ isOpen: false })
                                // console.log(this.state.selectedValue)
                                if (this.state.selectedValue) {
                                    this.props.onChange(this.state.selectedValue, () => {
                                        if (this.props.onFinishKey) {
                                            this.props.onFinishKey()
                                        }
                                        if (this.props.focusNext && !this.state.isOpen)
                                            this.props.focusNext();
                                    })
                                } else {
                                    this.props.onChange(this.props.value, () => {
                                        if (this.props.onFinishKey) {
                                            this.props.onFinishKey()
                                        }
                                        if (this.props.focusNext)
                                            this.props.focusNext();
                                    })


                                }
                            }
                            if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {

                                let value;
                                let values = [];
                                if (this.props.values && this.props.values.length) {
                                    if (!this.props.value) {
                                        values = this.props.values.filter(el => el.name && el.value != -1)
                                    } else {
                                        values = this.props.values.filter(el => el.name && el.value != -1 && (String(el.name).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1 || (!this.props.filterOnlyByName && String(el.value).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1)))
                                    }

                                }
                                if (values && values.length) {
                                    if (this.state.selectedValue) {
                                        let index = values.findIndex(el => el.value == this.state.selectedValue)
                                        if (e.key == 'ArrowUp') {
                                            if (index > 0) {
                                                value = values[index - 1].value
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                            // else {
                                            //     value = values[values.length - 1].value
                                            // }
                                        } else if (e.key == 'ArrowDown') {
                                            if (index < values.length - 1) {
                                                value = values[index + 1].value
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                            // else {
                                            //     value = values[0].value
                                            // }
                                        }
                                    } else {
                                        value = values[0].value;
                                    }

                                }
                                this.setState({
                                    selectedValue: value
                                })
                            } else {
                                if (this.state.selectedValue) {
                                    this.setState({ selectedValue: null })
                                }
                            }
                        }}
                        onChange={(e) => {
                            if (e.target.value) {
                                this.props.onChange(e.target.value)
                            } else {
                                this.props.onChange(null)
                            }

                            this.setState({ isOpen: true })
                        }}
                        onFocus={() => this.setState({ isOpen: true })}
                        disabled={this.props.readOnly}
                        value={this.props.value ? this.state.valueText : ''}
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.values && this.props.values.filter(item => item.value == -1).length ? this.props.values.filter(item => item.value == -1)[0].name : null}
                    ></input>
                    {
                        this.props.values && this.props.values.length && this.state.isOpen ?
                            <div className="select-with-search-list-wrap" ref={node => this.refDropdown = node}>
                                {
                                    this.props.values.filter(el => el && el.value != -1 && (!this.props.value || (this.props.value && (String(el.name).toLowerCase().indexOf(String(this.props.value)?.toLowerCase()) != -1 || (!this.props.filterOnlyByName && String(el.value).toLowerCase().indexOf(String(this.props.value)?.toLowerCase()) != -1))))).map((item, idx) => {
                                        return (
                                            <div
                                                // id={`${this.state.inputID}-${idx}`}
                                                id={`select-item-${idx}`}
                                                className={
                                                    "select-with-search-list-item " +
                                                    (item.disabled ? "select-with-search-list-item-disabled" : "")
                                                    +
                                                    (item.value == this.state.selectedValue && this.state.selectedValue ? 'selected-item' : '')
                                                }

                                                onClick={() => {
                                                    if (item.disabled) {
                                                        return;
                                                    }
                                                    this.props.onChange(item.value, () => {
                                                        if (this.props.onFinishKey) {
                                                            this.props.onFinishKey()
                                                        }
                                                        if (this.props.focusNext)
                                                            this.props.focusNext();

                                                    })
                                                    // this.props.focusNext();
                                                    // if (this.props.focusNext)
                                                    //     this.props.focusNext();
                                                    this.setState({ isOpen: false }, () => {
                                                        document.getElementById(this.state.inputID).focus();
                                                        this.setState({ isOpen: false })

                                                    })
                                                }}

                                            >
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            null
                    }
                    {this.props.error && this.props.touched ?
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                        :

                        null
                    }

                </div>

            </FormGroup>
        )
    }
}

export default Text;
