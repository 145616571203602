import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-turnover': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/get/turnover/by-groups', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    key: typeof window != 'undefined' && localStorage.searchKey ? localStorage.searchKey : null,
                    additionalFilters: {
                        client: query && query.client,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        groupFrom: query && query.groupFrom,
                        groupTo: query && query.groupTo,
                        subgroupFrom: query && query.subgroupFrom,
                        subgroupTo: query && query.subgroupTo,
                        articleFrom: query && query.articleFrom,
                        articleTo: query && query.articleTo,
                        change: query && query.change,

                    },
                    totalByGroups: query && query.totalByGroups

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                        sumIn: result.sumIn ? result.sumIn : 0,
                        sumOut: result.sumOut ? result.sumOut : 0,
                        key: result.key ? result.key : null
                    }
            })
        }
    },
    'get-client': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + `/data/clients/validate/${query.code}/null`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },


    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/get/turnover/by-groups', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    print: true,
                    additionalFilters: {
                        client: query && query.client,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        groupFrom: query && query.groupFrom,
                        groupTo: query && query.groupTo,
                        subgroupFrom: query && query.subgroupFrom,
                        subgroupTo: query && query.subgroupTo,
                        articleFrom: query && query.articleFrom,
                        articleTo: query && query.articleTo,
                        change: query && query.change,

                    },
                    totalByGroups: query && query.totalByGroups

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        link: result.link
                    }
            })
        }

    },


}