import React, { Component, useEffect } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';

import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import { number, required, validateDate } from '../../form-builder/validation';
import {
    Button,
    Container, FormGroup,
    Input, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import moment from "moment";
import { value } from "loadsh/seq";
import DeleteModal from "../../components/deleteModal";
import InfoModal from "../../components/infoModal";
import { has } from "loadsh/object";
import { FAKTURA, OTPREMNICA, PROKNJIZENA_OTPREMNICA, PAKOVANJE, STATUS_LABEL } from "../../helpers/constants";
import PrintHelper from "../../components/printHelper";
import Checkbox from "../../form-builder/fields/checkbox";
import env from "react-dotenv";
import { debounce } from "lodash";
import ErrorModal from "../../components/errorModal";

const TAB_FAKTURA = 1;
const TAB_OTPREMNICA = 2;
const TAB_UNOS = 3;

class OutputPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {

            total: 20,
            clientList: true,
            outputForm: null,
            clients: [],
            // articleSearch: [],
            series: [],
            businessUnits: [],
            clientForm: false,
            entryOut: true,
            buttonActive: null,
            readOnlyBusiness: true,
            items: [],
            outputItems: [],
            postedItems: [],
            item: { stock: null },
            stock: null,
            selectedClient: null,
            selectedProduct: null,
            disableEdit: false,
            discountModal: null,
            nivelationModal: null,
            deleteModal: null,
            deleteDocumentModal: null,
            postedModal: null,
            printHtml: null,
            exportModal: false,
            selectedSeries: null,
            exportFieldList: [
                {
                    name: 'documentNumber',
                    label: 'BR. DOK:'
                },
                {
                    name: 'factureDate',
                    label: 'DATUM'
                },
                {
                    name: 'clientName',
                    label: 'KOMITENT'
                },
                {
                    name: 'businessName',
                    label: 'POSLOVNA'
                },
                {
                    name: 'businessName',
                    label: 'NAZIV PJ'
                },
                {
                    name: 'item.name',
                    label: 'NAZIV ARTIKLA'
                },
                {
                    name: 'item.seriesName',
                    label: 'SERIJA'
                },
                {
                    name: 'item.deadline',
                    label: 'ROK'
                },
                {
                    name: 'ammount',
                    label: 'KOLIČINA'
                },
                {
                    name: 'price',
                    label: 'CIJENA'
                },
                {
                    name: 'discount',
                    label: 'RABAT'
                },
                {
                    name: 'total',
                    label: 'TOTAL'
                },
            ],
            fields: [
                {
                    type: 'text',
                    name: 'documentNumber',
                    label: 'BR. DOK:'.translate(this.props.lang),
                    multilang: false,
                    allowSort: true
                },
                {
                    type: 'text',
                    name: 'factureDate',
                    label: 'DATUM'.translate(this.props.lang),
                    multilang: false,
                    allowSort: true
                },
                {
                    type: 'text',
                    name: 'clientName',
                    label: 'KOMITENT'.translate(this.props.lang),
                    multilang: false,
                    allowSort: true
                },
                {
                    type: 'text',
                    name: 'businessName',
                    label: 'POSLOVNA'.translate(this.props.lang),
                    multilang: false,
                    allowSort: true
                },
                {
                    type: 'text',
                    name: 'createdByUsername',
                    label: 'KORISNIK'.translate(this.props.lang),
                    multilang: false,
                    allowSort: true
                },

            ],
            exportFields: {},
            exportType: {
                xls: false,
                pdf: false
            },
            fromConditionDiscount: false,
            deadlines: [],
            articlesSearch: [],
            error: null,
        }
    }



    setDocumentNumber() {

        this._apiAsync('checkNextDocumentNumber').then((response) => {
            let outputForm = this.state.outputForm;
            if (!outputForm) {
                outputForm = {}
            }
            outputForm.factureDate = moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
            this.setState({ outputForm: null }, () => {
                this.setState({ outputForm })
            })
            outputForm.documentNumber = response.firstFreeNumber;
            if (!outputForm.item) {
                outputForm.item = { itemNumber: 1 }
            }
            this.setState({ outputForm: null }, () => {
                this.setState({ outputForm })
            })
            this.setState({ items: [] })
        })
    }

    prepareRequest(data) {
        let newObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (key === 'items' || key === 'totalDiscount' || key === 'totalDiscountKo') {
                continue;
            }
            newObject[key] = value;
        }
        if (has(newObject, 'item')) {
            delete newObject.item.stock;
            delete newObject.item.stockPrice;
            delete newObject.item.fundPrice;
            delete newObject.item.foundPrice;
            if (this.state.selectedProduct) {
                if (this.state.selectedProduct.manufacturer) {
                    newObject.item.manufacturer = this.state.selectedProduct.manufacturer;
                }
                if (this.state.selectedProduct.barcode) {
                    newObject.item.barcode = this.state.selectedProduct.barcode;
                }
            }
        }
        return newObject;
    }

    printOutput = async () => {
        if (this.state.outputForm.status === FAKTURA) {
            await this._apiAsync('printFaktura', { documentNumber: this.state.outputForm.documentNumber })
        } else {
            await this._apiAsync('printOtrpemnica', { documentNumber: this.state.outputForm.documentNumber })
        }
    }
    articleSearchMethod = debounce(async (value) => {
        this.setState({
            _articlesLoading: true
        }, async () => {
            let articleList = await this._apiAsync('ariclesSearch', { value: value })
            if (articleList && articleList.__filter !== value) {
                this.setState({
                    _articlesLoading: false
                });
                return;
            }

            if (this.registerGetValue && value === this.registerGetValue('item.article') && articleList)
                this.setState({ articlesSearch: articleList.articlesSearch, _articlesLoading: false })

        });


    }, 400)



    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.state.selectedProduct ? this.state.selectedProduct.code : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            serialNumber: value,
            withoutSeries: true,
        })
        if (resultSeries && resultSeries.items.length > 0) {
            this.setState({ series: resultSeries.items })
        }
        if (resultSeries && resultSeries.items.length === 1) {
        }
    }, 400);
    printFiscal = () => {
        this._apiAsync('printFiscalDocument', { documentNumber: this.state.outputForm.documentNumber, type: 'bill' })
    }
    discountToFacture = (type) => {
        this._apiAsync('discountToFacture', { documentNumber: this.state.outputForm.documentNumber, type }).then(() => {
            this.checkDocument();
        })
    }

    reloadArticle = async (item) => {
        const value = item && item.article ? item.article : null
        if (value) {
            let requestArticle = { value };
            if (this.state.selectedClient && this.state.selectedClient.institutionType) {
                requestArticle = { ...requestArticle, ...{ institutionType: this.state.selectedClient.institutionType } };
            }
            const products = await this._apiAsync('ariclesSearch', requestArticle)
            if (products && products.articlesSearch.length > 0) {
                await this.setState({ articlesSearch: products && products.articlesSearch })
                const productObject = products.articlesSearch.find(el => Number(el.code) === Number(item.article))
                this.setState({ selectedProduct: productObject })
                const serialObject = productObject && productObject.series && productObject.series.length > 0 ? productObject.series.find(el => el.serialNumber == item.serialNumber) : null;
                const quantity = has(productObject, 'stock.quantity') ? productObject.stock.quantity : null;
                const stockPrice = has(productObject, 'stock.sellPrice') ? productObject.stock.sellPrice : null;
                const fundPrice = has(productObject, 'fundPrice') ? productObject.fundPrice : null;
                if (serialObject) {
                    this.remoteOnChange('item.stock', serialObject.ammount);
                } else {
                    this.remoteOnChange('item.stock', quantity);
                }
                this.remoteOnChange('item.stockPrice', stockPrice);
                this.remoteOnChange('item.foundPrice', fundPrice);
            }

        }
    }
    insertOrUpdate = async (data, doNivelation = false) => {
        const request = await this.prepareRequest(data);
        if (doNivelation) {
            request.doNivelation = doNivelation
        }
        if (request._id) {
            this._apiAsync('update-document', request).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ outputForm: null }, () => {
                        this.setState({
                            outputForm: res.result,
                            items: res.result.items && res.result.items.length ? res.result.items : [],
                            articlesSearch: []
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        } else {
            this._apiAsync('insert-new', request).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ outputForm: null }, () => {
                        this.setState({
                            outputForm: res.result,
                            items: res.result.items && res.result.items.length ? res.result.items : []
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        }
        this.resetItem()

    }
    resetFilter = async () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',
            filterClient: '',
            filterUser: '',
            filterBusiness: '',
            filterType: '',
            filterDocument: ''
        });
    }
    resetItem = async () => {
        this.remoteOnChange('item.article', '')
        this.remoteOnChange('item.serialNumber', '')
        this.remoteOnChange('item.deadline', null)
        this.remoteOnChange('item.stock', null);
        this.remoteOnChange('item.price', 0);
        this.remoteOnChange('item.stockPrice', null);
        this.remoteOnChange('item.fundPrice', null);
        this.remoteOnChange('item.ammount', null);
    }
    changeTabNavigation = async (value) => {
        if (value === 1) {
            await this.resetFilter();
            await this.updateMultipleParams([{ name: 'status', value: FAKTURA }, { name: 'entries', value: 10 }, { name: 'page', value: 0 }, { name: 'dateTo' }, { name: 'dateFrom' }, { name: 'client' }, { name: 'business' }, { name: 'document' }], true);
        } else if (value === 2) {
            await this.resetFilter();
            const statusParams = OTPREMNICA + ',' + PROKNJIZENA_OTPREMNICA + ',' + PAKOVANJE
            await this.updateMultipleParams([{ name: 'status', value: statusParams }, { name: 'entries', value: 10 }, { name: 'page', value: 0 }, { name: 'dateTo' }, { name: 'dateFrom' }, { name: 'client' }, { name: 'business' }, { name: 'document' }], true);
        } else {
            this.setState({ disableEdit: false })
            this.props[0].history.push({})
            this.setDocumentNumber();
        }

        this.setState({ buttonActive: value })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (this.props[0].location && this.props[0].location.search && this.remoteOnChange) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') != -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }
            }
        }
        if (this.state.readOnlyBusiness != prevState.readOnlyBusiness && this.state.readOnlyBusiness == false) {
            this.metodaBusiness('')
        }

    }

    componentDidMount() {
        let params = this._getParams();
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }
        if (params.business && params.business != this.state.filterBusiness) {
            this.setState({ filterBusiness: params.business })
        }
        if (params.type && params.type != this.state.filterType) {
            this.setState({ filterType: params.type })
        }
        if (params.document && params.document != this.state.filterDocument) {
            this.setState({ filterDocument: params.document })
        }
        if (params.status && params.status.length > 1) {
            const statuses = params.status.split(",");
            if (statuses.length > 0) {
                for (const status of statuses) {
                    if ([OTPREMNICA, PROKNJIZENA_OTPREMNICA, PAKOVANJE].includes(Number(status))) {
                        this._onLoad();
                        this.setState({ buttonActive: TAB_OTPREMNICA });
                        break;
                    }
                }
            }
        } else {
            if (params.status && Number(params.status) === FAKTURA) {
                this._onLoad();
                this.setState({ buttonActive: TAB_FAKTURA });
            } else {
                this.setDocumentNumber();
                this.setState({ buttonActive: TAB_UNOS });
            }
        }

    }


    metoda = async (value) => {
        this.articleSearchMethod(value)
    }

    metodaClient = async (value) => {

        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('clientCode'))
            this.setState({ clientsSearch: clientList.clientsSearch })
    }

    metodaBusiness = async (value) => {
        if (!(this.state.selectedClient && this.state.selectedClient._id)) {
            return;
        }
        const query = { client: this.state.selectedClient._id };
        const businessUnits = await this._apiAsync('get-business-units', { filter: value, query })
        if (this.registerGetValue && value === this.registerGetValue('business'))
            this.setState({ businessUnits: businessUnits && businessUnits.items })
    }
    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }

    checkDocument() {
        this._apiAsync('checkDocumentNumber', { documentNumber: this.state.outputForm.documentNumber }).then((res) => {
            if (this.registerGetValue('documentNumber') == res.result.documentNumber) {
                if (res && res.result && res.result._id) {
                    this.setState({ selectedClient: res.result.clientInfo })
                    this.setState({ outputForm: null }, () => {
                        this.setState({ outputForm: res.result }, () => {

                            if (res.result.items && res.result.items.length) {
                                const fromConditionDiscount = res.result.items.find((item) => item.fromConditionDiscount);
                                this.setState({ fromConditionDiscount: !!fromConditionDiscount });
                                this.setState({
                                    items: res.result.items,
                                }, () => {
                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                    this._apiAsync('checkNextItemNumber', { documentNumber: this.state.outputForm.documentNumber }).then((res) => {
                                        if (res && res.firstFreeItemNumber) {
                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                        }
                                    })
                                })
                            } else {
                                this.setState({
                                    items: [],
                                }, () => {
                                    this.remoteOnChange('item.itemNumber', 1)
                                })
                            }
                            if (this.state.outputForm && this.state.outputForm.status == FAKTURA) {
                                this.setState({ disableEdit: true })
                            } else {
                                this.setState({ disableEdit: false })
                            }
                        })
                    })
                }
            }
        })
    }
    render() {
        let params = this._getParams();
        return (
            <div>
                {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                }
                <div className="section-title title-options">
                    <h1>Izlaz</h1>
                    {
                        this.state.buttonActive == TAB_FAKTURA || this.state.buttonActive == TAB_OTPREMNICA ?
                            <div className="title-options-group">
                                <button onClick={async () => {
                                    const params = {}
                                    for (const [key, value] of Object.entries(this._getParams())) {
                                        if (key === 'entries' || key === 'page') {
                                            continue;
                                        }
                                        params[key] = value;
                                    }
                                    const request = {
                                        filter: {
                                            entries: 10000,
                                            page: 0,
                                            additionalFilters: params
                                        },
                                        methods: 'post',
                                        url: 'data/output',
                                        fields: [
                                            { key: 'documentNumber', label: 'BROJ' },
                                            { key: 'factureDate', label: 'DATUM' },
                                            { key: 'clientName', label: 'KOMITENT' },
                                            { key: 'businessUnitName', label: 'POSLOVNA' },
                                            { key: 'createdByUsername', label: 'KORISNIK' },
                                        ],
                                        title: "Izlaz"
                                    }
                                    await this._apiAsync('generic-print', request)

                                }}>
                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={() => {
                                    this.setState({ exportModal: true })
                                    let obj = {}
                                    this.state.exportFieldList.map((item, idx) => {
                                        obj[item.name] = true
                                    })
                                    this.setState({ exportFields: obj })

                                }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    this.state.entryOut ?
                        <div className="table-tabs">
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == TAB_FAKTURA ? 'active' : ''}
                                    onClick={() => this.changeTabNavigation(TAB_FAKTURA)}>
                                    Faktura izlaznog prometa
                                </button>
                                <button className={this.state.buttonActive == TAB_OTPREMNICA ? 'active' : ''}
                                    onClick={() => this.changeTabNavigation(TAB_OTPREMNICA)}>
                                    Nedovršene fakture
                                </button>
                                <button className={this.state.buttonActive == TAB_UNOS ? 'active' : ''}
                                    onClick={() => this.changeTabNavigation(TAB_UNOS)}>
                                    Unos izlaza
                                </button>
                            </div>
                            {/*        {this.state.outputForm && this.state.outputForm.status ?
                                <div className="status">
                                    <b>Status:</b> {STATUS_LABEL[Number(this.state.outputForm.status)]}
                                </div>
                                : null
                            }*/}

                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive == TAB_UNOS ?
                        <>

                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'item.article', 'item.serialNumber', 'item.ammount', 'item.deadline', 'item.price', 'item.discount', 'clientCode', 'business']}
                                    fieldTracker={async (name, value) => {
                                        if (name === 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, outputForm: null }, () => {
                                                this.setState({ outputForm: {} })
                                            })
                                        }
                                        if (name === 'documentNumber') {
                                            if (value) {
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {

                                                    if (res && res.result && this.registerGetValue('documentNumber') == res.result.documentNumber) {
                                                        if (res && res.result && res.result._id) {
                                                            this.setState({ selectedClient: res.result.clientInfo }, () => {
                                                                this.metodaClient(this.state.selectedClient.code)
                                                                this.remoteOnChange('clientCode', this.state.selectedClient.code)

                                                                if (res.result.business) {

                                                                    this.metodaBusiness(res.result.business)
                                                                    this.remoteOnChange('business', res.result.business)
                                                                }
                                                            })


                                                            this.setState({ outputForm: null }, () => {
                                                                this.setState({ outputForm: res.result }, () => {

                                                                    if (res.result.items && res.result.items.length) {
                                                                        const fromConditionDiscount = res.result.items.find((item) => item.fromConditionDiscount);
                                                                        this.setState({ fromConditionDiscount: !!fromConditionDiscount });
                                                                        this.setState({
                                                                            items: res.result.items,
                                                                        }, () => {
                                                                            // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                            this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                                if (res && res.firstFreeItemNumber) {
                                                                                    this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            items: [],
                                                                        }, () => {
                                                                            this.remoteOnChange('item.itemNumber', 1)
                                                                        })
                                                                    }
                                                                    if (this.state.outputForm && this.state.outputForm.status == FAKTURA) {
                                                                        this.setState({ disableEdit: true })
                                                                    } else {
                                                                        this.setState({ disableEdit: false })
                                                                    }
                                                                })
                                                            })
                                                        } else if (this.state.prevDocumentNumber != value && this.state.outputForm._id) {
                                                            this.setState({ disableEdit: false })
                                                            this.setState({ outputForm: null }, () => {
                                                                this.setState({ outputForm: { documentNumber: value } }, () => {

                                                                    this.setState({ items: [], outputForm: {} }, () => {
                                                                        // this.remoteOnChange('item.itemNumber', 1)
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    })

                                                                })
                                                            })

                                                        }
                                                        this.setState({ prevDocumentNumber: value })
                                                    } else {
                                                        this.setState({ outputForm: null });
                                                        this.setState({ items: null });
                                                        this.setState({ selectedClient: null });
                                                    }
                                                })
                                            }


                                        }
                                        if (name === 'item.serialNumber' && value) {
                                            const seriesObject = this.state.series.find(el => el.serialNumber === value)
                                            if (seriesObject) {
                                                this.remoteOnChange('item.deadline', seriesObject.expirationDate ? seriesObject.expirationDate : null);
                                                this.remoteOnChange('item.stock', seriesObject.ammount);
                                                this.setState({ selectedSeries: seriesObject })
                                            }
                                        }
                                        if (name === 'item.article') {
                                            this.setState({ notExistSeries: false });
                                            if (value) {
                                                this.remoteOnChange('item.serialNumber', '')
                                                const productObject = this.state.articlesSearch.find(el => el.code == this.registerGetValue('item.article'))
                                                if (productObject) {
                                                    this.setState({ selectedProduct: productObject });
                                                    const quantity = productObject.stock && productObject.stock.quantity ? productObject.stock.quantity : 0;
                                                    const price = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                    const stockPrice = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                    const fundPrice = has(productObject, 'fundPrice') ? productObject.fundPrice : null;


                                                    this.remoteOnChange('item.price', fundPrice ? fundPrice : price);
                                                    this.remoteOnChange('item.stockPrice', stockPrice);
                                                    this.remoteOnChange('item.fundPrice', fundPrice);
                                                    this.remoteOnChange('item.stock', quantity);
                                                    this.remoteOnChange('item.serialNumber', '');


                                                    const resultSeries = await this._apiAsync('get-series', {
                                                        article: productObject.code,
                                                        sortField: 'expirationDateTs',
                                                        sortType: 1,
                                                        withoutSeries: true,
                                                        deadline: true,
                                                    })
                                                    if (resultSeries && resultSeries.items.length > 0) {
                                                        if (resultSeries.items.length === 1) {
                                                            this.remoteOnChange('item.serialNumber', resultSeries.items[0].serialNumber)
                                                        }
                                                        await this.setState({ series: resultSeries.items })
                                                    } else {
                                                        this.setState({ notExistSeries: true });
                                                        this.remoteOnChange('item.stock', quantity);
                                                        await this.setState({ series: [] })
                                                        this.remoteOnChange('item.serialNumber', '')
                                                        this.remoteOnChange('item.deadline', '')
                                                    }

                                                } else {
                                                    this.remoteOnChange('item.stock', null);
                                                    this.remoteOnChange('item.stockPrice', null);
                                                    this.remoteOnChange('item.price', null);
                                                    this.remoteOnChange('item.serialNumber', '')
                                                    this.remoteOnChange('item.deadline', '')
                                                    this.remoteOnChange('item.fundPrice', null);
                                                }
                                            } else {
                                                this.remoteOnChange('item.stock', null);
                                                this.remoteOnChange('item.stockPrice', null);
                                                this.remoteOnChange('item.price', null);
                                                this.remoteOnChange('item.serialNumber', '')
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.fundPrice', null);
                                            }

                                        }
                                        if (name === 'item.price' && value) {
                                            let total = this.registerGetValue('item.ammount') && value ? this.registerGetValue('item.ammount') * value : null
                                            const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.ammount' && value) {
                                            let total = this.registerGetValue('item.price') && value ? this.registerGetValue('item.price') * value : null
                                            const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.discount' && value) {
                                            let total = this.registerGetValue('item.price') && this.registerGetValue('item.ammount') ? this.registerGetValue('item.price') * this.registerGetValue('item.ammount') : null;
                                            const discountValue = total && value ? Number(total) / 100 * value : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.discount' && value) {
                                            if (this.state.selectedClient && this.state.selectedProduct) {
                                                await this._apiAsync('check-product-discount', {
                                                    productId: this.state.selectedProduct._id,
                                                    clientId: this.state.selectedClient._id
                                                }).then((response) => {
                                                    if (response && response.result && response.result.percent && Number(value) > Number(response.result.percent)) {
                                                        this.setState({ discountModal: response.result });
                                                    }
                                                })
                                            }
                                        }
                                        if (name === 'clientCode' && value) {
                                            const clients = await this._apiAsync('get-clients', { filter: value })
                                            if (clients && clients.items) {
                                                if (this.state.selectedClient && this.state.selectedClient.code !== value) {
                                                    this.remoteOnChange('business', '')
                                                    this.remoteOnChange('businessName', '')
                                                }
                                                this.setState({ clients: clients && clients.items }, async () => {
                                                    const clientObject = this.state.clients.find(item => Number(item.code) === Number(value))
                                                    this.setState({ selectedClient: clientObject })
                                                    if (clientObject) {
                                                        this.remoteOnChange('clientName', clientObject.name)

                                                        this.setState({
                                                            clientLimits: null
                                                        }, async () => {
                                                            const clientLimits = await this._apiAsync('client-limits', { clientCode: clientObject.code });
                                                            console.log(clientLimits)
                                                            this.setState({
                                                                clientLimits
                                                            })
    
                                                        })

                                                        const checkBusinessUnitForClient = await this._apiAsync('check-client-exists-in-business-units', { clientId: clientObject._id });
                                                        this.setState({ readOnlyBusiness: checkBusinessUnitForClient.status !== 200 })
                                                    } else {
                                                        this.remoteOnChange('clientName', '')
                                                    }
                                                })
                                            }
                                        }
                                        if (name === 'business' && value) {
                                            const businessUnits = await this._apiAsync('get-business-units', {
                                                filter: value,
                                                query: { client: this.state.selectedClient._id }
                                            })
                                            if (businessUnits && businessUnits.items) {
                                                this.setState({ businessUnits: businessUnits && businessUnits.items }, () => {
                                                    if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                        this.remoteOnChange('businessName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                                    } else {
                                                        this.remoteOnChange('businessName', '')
                                                    }
                                                })
                                            }
                                        }

                                    }
                                    }
                                    onSubmit={(data) => {
                                        if (data.item.article) {
                                            data.item.article = Number(data.item.article)
                                        }
                                        data.status = OTPREMNICA;
                                        this.insertOrUpdate(data);
                                        /*           if (data && data.item && data.item.price && data.item.stockPrice && Number(data.item.price) !== Number(data.item.stockPrice)) {
                                                       this.setState({ nivelationModal: data })
                                                       return 'open-modal';
                                                   } else {
                                                       this.insertOrUpdate(data);
                                                   }*/



                                    }}
                                    initialValues={this.state.outputForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: []
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom-sm list-buttons-custom-sm-width',
                                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'row',
                                                                                    className: this.state.outputForm && this.state.outputForm.status == OTPREMNICA ? 'list-toggle-custom-2 list-toggle-custom-switch' : 'list-toggle-custom-3 list-toggle-custom-switch',
                                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                                    children: [
                                                                                        this.state.outputForm && this.state.outputForm.status == OTPREMNICA ?
                                                                                            {
                                                                                                type: 'button',
                                                                                                children: <span>Za pakovanje</span>,
                                                                                                action: 'custom',
                                                                                                className: 'ready-for-package',
                                                                                                onClick: () => {
                                                                                                    const request = { ...this.state.outputForm, ...{ status: PROKNJIZENA_OTPREMNICA } }
                                                                                                    this.setState({ postedModal: request })
                                                                                                    return 'open-modal';
                                                                                                }
                                                                                            } : {},
                                                                                        {
                                                                                            type: 'switch',
                                                                                            name: 'compensation',
                                                                                            changeOnParent: true,
                                                                                            label: 'Kompenzacija',
                                                                                            onChange: (name, value) => {
                                                                                                if (this.state.outputForm._id) {
                                                                                                    const data = { ...this.state.outputForm, ...{ compensation: value } }
                                                                                                    this.insertOrUpdate(data)
                                                                                                }
                                                                                            }
                                                                                        },
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [

                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.outputForm && this.state.outputForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Fiskalna</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {
                                                                                                this.printFiscal()
                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.outputForm && this.state.outputForm._id && this.state.outputForm.status !== OTPREMNICA ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {
                                                                                                this.setState({ printHtml: null }, this.printOutput)
                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },

                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.setState({ outputForm: null, items: [] })
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-4",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            integer: true,
                                                            name: 'documentNumber',
                                                            next: 'factureDate',
                                                            label: 'Broj dok.',
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'factureDate',
                                                            next: 'deliveryDate',
                                                            label: 'Datum fakturisanja',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Datum ulaza mora biti unesen!"
                                                                },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'deliveryDate',
                                                            next: 'clientCode',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Datum isporuke mora biti unesen!"

                                                                    if (moment(value, 'DD.MM.YYYY').unix() < moment(this.registerGetValue('factureDate'), 'DD.MM.YYYY').unix()) {
                                                                        return "Mora biti veći ili jednak od datuma fakturisanja";
                                                                    }
                                                                },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: this.state.readOnlyBusiness ? 'item.article' : 'business',
                                                                            textType: 'number',
                                                                            label: 'Šifra kom.',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            title: 'Pregled komitenata',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            entrance: true,
                                                                            control: true,
                                                                            enableEdit: !this.state.readOnlyBusiness,
                                                                            changeState: (callback) => {
                                                                                this.setState({ readOnlyBusiness: !this.state.readOnlyBusiness }, () => {
                                                                                    if (callback) {
                                                                                        callback()
                                                                                    }
                                                                                })

                                                                            },
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Šifra komitenta mora biti unesen!'),
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            if (this.state.clients && this.state.clients.length && (this.state.clients.filter(el => String(el.code) == String(value)).length || this.state.clientsSearch.filter(el => String(el.code) == String(value)).length)) {
                                                                                                return undefined;
                                                                                            } else {
                                                                                                return 'Unesena šifra komitenta nije ispravna!';
                                                                                            }
                                                                                        } else {
                                                                                            return undefined;
                                                                                        }


                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            next: 'ordinalNumber',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'businessUnit',
                                                                            name: 'business',
                                                                            next: 'item.article',
                                                                            label: 'Poslovna',
                                                                            readOnly: this.state.readOnlyBusiness || this.state.disableEdit,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            metoda: (value) => { this.metodaBusiness(value) },
                                                                            dataModal: this.state.businessUnits,
                                                                            title: 'Pregled poslovnih jedinica',
                                                                            values: [...this.state.businessUnits.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            validate: [
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            if (this.state.businessUnits && this.state.businessUnits.length && (this.state.businessUnits.filter(el => String(el.code) == String(value)).length || this.state.businessUnits.filter(el => String(el.code) == String(value)).length)) {
                                                                                                return undefined;
                                                                                            } else {
                                                                                                return 'Unesena šifra poslovne nije ispravna!';
                                                                                            }
                                                                                        } else {
                                                                                            return undefined;
                                                                                        }


                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessName',
                                                                            next: 'jci',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'block',
                                                            style: {
                                                                background: '#F8F9FA',

                                                                width: '18%',
                                                                height: '110%',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomRightRadius: 0,
                                                                boxShadow: 'none',
                                                                margin: 0,
                                                                position: 'absolute',
                                                                top: -25,
                                                                right: -15
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'div',
                                                                    children: <div className="limits-info-div">
                                                                        <div className="limit-info-item">
                                                                            <h6>LIMIT U VALUTI</h6>
                                                                            {
                                                                                this.state.selectedClient && this.state.selectedClient.valueInLimit ?
                                                                                    <p>{this.state.selectedClient.valueInLimit}</p>
                                                                                    : <p>0</p>
                                                                            }


                                                                        </div>
                                                                        <div className="limit-info-item">
                                                                            <h6>LIMIT VAN VALUTE</h6>
                                                                            {
                                                                                this.state.selectedClient && this.state.selectedClient.valueDays ?
                                                                                    <p>{this.state.selectedClient.valueDays}</p>
                                                                                    : <p>0</p>
                                                                            }
                                                                        </div>
                                                                        <div className="limit-info-item">
                                                                            <h6>STANJE U VALUTI</h6>
                                                                            <p>{this.state.clientLimits ? Number(this.state.clientLimits.onTime).toFixed(2)   : null}</p>
                                                                        </div>
                                                                        <div className="limit-info-item">
                                                                            <h6>STANJE VAN VALUTE</h6>
                                                                            <p>{this.state.clientLimits ? Number(this.state.clientLimits.offTime).toFixed(2)   : null}</p>
                                                                        </div>
                                                                    </div>,
                                                                    label: 'LIMIT U VALUTI',
                                                                }

                                                            ]
                                                        }


                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-3",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.article',
                                                            label: 'R. br.',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            loading: this.state._articlesLoading,
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            values: this.state.articlesSearch ? [...this.state.articlesSearch.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code,
                                                                    disabled: item.disabled ? true : false,
                                                                }
                                                                return obj
                                                            })] : [],
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Artikal mora biti unesen!"
                                                                    if (this.state.notExistSeries) {
                                                                        return "Artikal nema seriju sa količinom"
                                                                    }
                                                                },
                                                                (value) => {

                                                                    if (this.state.articlesSearch && this.state.articlesSearch.length == 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                        return "Neispravan unos!"
                                                                    }
                                                                    if (this.state.articlesSearch && this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                        return "Neispravan unos!!"
                                                                    }
                                                                    this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'series',
                                                            name: 'item.serialNumber',
                                                            next: 'item.ammount',
                                                            label: `Serijski broj`,
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit || !this.state.series.length,
                                                            asyncValidation: true,
                                                            metoda: (value) => { this.metodaSeries(value) },
                                                            dataModal: this.state.series,
                                                            title: 'Pregled serija',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Serija mora biti unesena!";
                                                                    if (this.state.items && this.state.items.length > 0 && this.state.items.find((item) => item.serialNumber === value)) {
                                                                        return 'Nije moguce dodati istu seriju vise puta u jednom dokumentu'
                                                                    }
                                                                }
                                                            ]

                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            label: 'Rok',
                                                            finishField: true,
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            // textType: 'number',
                                                                            integer: true,
                                                                            name: 'item.ammount',
                                                                            next: 'item.price',
                                                                            label: 'Količina',
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value == '') return "Količina mora biti unesena!";
                                                                                    if (value > this.registerGetValue('item.stock')) {
                                                                                        return "Količina mora biti manja od stanja zaliha";
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            next: 'item.discount',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.price',
                                                                            next: 'item.discount',
                                                                            label: 'Cijena',
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value == '') return "Cijena mora biti unesena!"
                                                                                    const buyPrice = this.state.selectedProduct && this.state.selectedProduct.stock ? this.state.selectedProduct.stock.buyPrice : 0
                                                                                    if (value < buyPrice) {
                                                                                        return `Cijena ne može biti manja od nabavne cijene (${buyPrice.toFixed(2)})`;
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stockPrice',
                                                                            label: 'Lager c.',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,

                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.fundPrice',
                                                                            label: 'Fond cijena',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.discount',
                                                            // textType: 'number',
                                                            label: 'Rabat',
                                                            readOnly: this.state.disableEdit,
                                                            next: 'submitForm',
                                                            afterSubmitFocus: 'item.article',
                                                            finishField: false,

                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            readOnly: true,
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                />
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        {
                                            type: 'text',
                                            name: 'itemNumber',
                                            label: 'BROJ'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'articleName',
                                            label: 'NAZIV ARTIKLA'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'serialNumber',
                                            label: 'SERIJA'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'deadline',
                                            label: 'ROK'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'ammount',
                                            label: 'KOLIČINA'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'price',
                                            label: 'CIJENA'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'discount',
                                            label: 'RABAT'.translate(this.props.lang),
                                            multilang: false,
                                        },
                                        {
                                            type: 'text',
                                            name: 'total',
                                            label: 'UKUPNO'.translate(this.props.lang),
                                            multilang: false,
                                        },

                                    ]}
                                    actions={
                                        this.state.disableEdit == false ?
                                            [
                                                {
                                                    component: <Isvg src={editIcon} />,
                                                    onClick: (item) => {
                                                        let outputForm = this.state.outputForm;
                                                        outputForm.item = item;
                                                        this.setState({ outputForm: null }, () => {
                                                            this.setState({ outputForm })
                                                        })

                                                        this.reloadArticle(item)
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={garbageIcon} />,
                                                    onClick: (item) => this.setState({ deleteModal: item })
                                                }
                                            ]
                                            : []
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-item', {
                                            _id: this.state.outputForm._id,
                                            itemNumber: this.state.deleteModal.itemNumber
                                        }).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ outputForm: null, deleteModal: null }, () => {
                                                    this.setState({
                                                        outputForm: res.result,
                                                        items: res.result.items && res.result.items.length ? res.result.items : []
                                                    })
                                                })
                                                if (res.result.items && res.result.items.length) {
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }

                                            }
                                        })
                                    }}>
                                    Obrisati
                                    stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                                </DeleteModal>
                                <DeleteModal
                                    isOpen={this.state.deleteDocumentModal}
                                    toggle={() => this.setState({ deleteDocumentModal: null })}
                                    handler={() => {
                                        if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                            this.setState({ deleteDocumentModal: null })
                                            this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                            return;
                                        } else {
                                            this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                                if (res.status === 405) {
                                                    this.setState({ deleteDocumentModal: null })
                                                    this.setState({ error: res.error })
                                                } else {
                                                    this.setState({
                                                        deleteDocumentModal: null,
                                                        outputForm: null,
                                                        items: null,
                                                        firstFreeNumber: null
                                                    }, () => {
                                                        this.setState({ outputForm: {} }, () => this._onLoad())
                                                    })
                                                }
                                            })
                                        }

                                    }}>
                                    Obrisati
                                    dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                                </DeleteModal>
                                <ErrorModal
                                    isOpen={this.state.error}
                                    toggle={() => this.setState({ error: null })}
                                    error={this.state.error}
                                />
                                <InfoModal
                                    isOpen={this.state.postedModal}
                                    toggle={() => this.setState({ postedModal: null })}
                                    title={'Obavještenje'}
                                    info={'Da li ste sigurni da želite proknjižiti ovaj dokument?'}
                                    buttons={[
                                        {
                                            text: 'DA',
                                            onClick: () => {
                                                const requestPosted = {
                                                    _id: this.state.postedModal._id,
                                                    status: PROKNJIZENA_OTPREMNICA
                                                };
                                                this.setState({ postedModal: null }, () => this.insertOrUpdate(requestPosted))
                                            }
                                        },
                                        {
                                            text: 'NE',
                                            onClick: () => {
                                                this.setState({ postedModal: null })
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                                <InfoModal
                                    isOpen={this.state.discountModal}
                                    disableKeyPress={true}
                                    toggle={() => this.setState({ discountModal: null })}
                                    title={'Obavještenje'}
                                    info={'Unijeli ste veći rabat nego na uslovima. Da li želite da nastavite ?'}
                                    buttons={[
                                        {
                                            text: 'DA',
                                            onClick: () => {

                                                this.setState({ discountModal: null })
                                            }
                                        },
                                        {
                                            text: 'NE',
                                            onClick: () => {
                                                this.remoteOnChange('item.discount', null)
                                                this.setState({ discountModal: null });
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                                {
                                    this.state.descriptionModal ?
                                        <Modal isOpen={this.state.descriptionModal} size='lg' className="modal-wrap">
                                            <ModalHeader>
                                                <h4>Opis</h4>
                                                <div className='close-button' onClick={() => this.setState({ descriptionModal: false, description: '' })}>&times;</div>
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="dependent-costs-wrap">

                                                    <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <Label>Unesite opis</Label>
                                                        <Input type='textarea' style={{ height: 100 }} value={this.state.description} onChange={(e) => {
                                                            let value = e.target.value;

                                                            this.setState({ description: value })
                                                        }}>

                                                        </Input>
                                                    </FormGroup>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>


                                                <Button
                                                    onClick={() => {
                                                        if (this.state.outputForm && this.state.outputForm._id) {
                                                            this._apiAsync('update-document', { description: this.state.description, _id: this.state.outputForm._id }).then((res) => {
                                                                if (res.result && res.result._id) {
                                                                    this.setState({ outputForm: null }, () => {
                                                                        this.setState({ outputForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [], articlesSearch: [] })
                                                                    })
                                                                    if (res.result.items && res.result.items.length) {
                                                                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    } else {
                                                                        this.remoteOnChange('item.itemNumber', 1)
                                                                    }

                                                                }
                                                            })

                                                        }
                                                        this.setState({ descriptionModal: null })

                                                    }}>Snimi</Button>
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null

                                }
                            </div>
                            {this.state.outputForm && this.state.outputForm._id ?
                                <div className="footer">
                                    <div className="footer-left">
                                        <button className="blue-button" disabled={this.state.disableEdit} onClick={() => this.setState({ descriptionModal: true, description: this.state.outputForm.description ? String(this.state.outputForm.description) : '' })}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>OPIS</p>
                                        </button>

                                        <button disabled={this.state.disableEdit}
                                            onClick={() => this.setState({ deleteDocumentModal: this.state.outputForm })}>
                                            <div className="box box-red">
                                                <Isvg src={garbageOpionIcon} />
                                            </div>
                                            <p>BRISANJE DOKUMENTA</p>
                                        </button>


                                        <button className="gray-button" style={{ marginLeft: 20 }} disabled={this.state.disableEdit}
                                            onClick={() => {
                                                if (this.state.outputForm) {
                                                    const type = this.state.fromConditionDiscount ? 'revert' : 'discountToFacture'
                                                    this.discountToFacture(type)
                                                }
                                            }}
                                        >
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            {this.state.fromConditionDiscount ?
                                                <p> Poništi rabat na fakturu </p>
                                                :
                                                <p>Rabat na fakturu</p>
                                            }

                                        </button>

                                        <div className="modified-div-wrap">
                                            {this.state.outputForm && this.state.outputForm.createdByUsername ?
                                                <h6>Poslednja izmjena:</h6>
                                                : null}
                                            <p>{this.state.outputForm && this.state.outputForm.createdByUsername ? this.state.outputForm.createdByUsername : null}</p>
                                            <p>{this.state.outputForm && this.state.outputForm.tsModified ? moment.unix(this.state.outputForm.tsModified).format('DD.MM.YYYY HH:MM') : null}</p>

                                        </div>
                                        <div className="discount-wrap">
                                            <div>
                                                <h6>K.O.</h6>
                                                <p>{this.state.outputForm && this.state.outputForm.totalDiscountKo ? this.state.outputForm.totalDiscountKo : 0}</p>
                                            </div>
                                            <div>
                                                <h6>RABAT</h6>
                                                <p>{this.state.outputForm && this.state.outputForm.totalDiscount ? this.state.outputForm.totalDiscount : 0} KM</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="footer-right">
                                        <div className="info-colored">
                                            <div className="col-info">
                                                <p>Osnovica</p>
                                                <span>{this.state.outputForm && this.state.outputForm.total ? Number(this.state.outputForm.total).toFixed(2) : 0} KM</span>
                                            </div>
                                            <div className="col-info">
                                                <p>PDV</p>
                                                <span>{this.state.outputForm && this.state.outputForm.pdv ? this.state.outputForm.pdv : 0} KM</span>
                                            </div>
                                            <div className="col-info">
                                                <p>Za plaćanje</p>
                                                <span>{this.state.outputForm && this.state.outputForm.totalWithPdv ? Number(this.state.outputForm.totalWithPdv).toFixed(2) : 0} KM</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </>
                        :
                        this.state.buttonActive === TAB_FAKTURA ?
                            <>
                                <div className="list-builder-filters-wrap-output">
                                    <div className="list-builder-filters">
                                        <div className="field-strap-wrap">
                                            <Input type='date'
                                                value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='date'
                                                value={this.state.filterDateTo !== null ? this.state.filterDateTo : params.dateTo}
                                                onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterClient !== null ? this.state.filterClient : params.client}
                                                onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterBusiness !== null ? this.state.filterBusiness : params.business}
                                                onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                    this.updateParams('business', this.state.filterBusiness)
                                                })} placeholder={'Poslovna'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterUser !== null ? this.state.filterUser : params.user}
                                                onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} placeholder={'Korisnik'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterType !== null ? this.state.filterType : params.type}
                                                onChange={(e) => this.setState({ filterType: e.target.value }, () => {
                                                    this.updateParams('type', this.state.filterType)
                                                })} placeholder={'Vrsta'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterDocument !== null ? this.state.filterDocument : params.document}
                                                onChange={(e) => this.setState({ filterDocument: e.target.value }, () => {
                                                    this.updateParams('document', this.state.filterDocument)
                                                })} placeholder={'Dokument'} />
                                        </div>
                                    </div>
                                    <div className="clear-filters" onClick={() => {
                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterUser: '',
                                            filterBusiness: '',
                                            filterType: '',
                                            filterDocument: '',
                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, {
                                                name: 'dateTo',
                                                value: null
                                            }, { name: 'client', value: null }, {
                                                name: 'user',
                                                value: null
                                            }, { name: 'business', value: null }, {
                                                name: 'type',
                                                value: null
                                            }, { name: 'document', value: null }])
                                        })
                                    }}>
                                        <span>&times;</span> Ukloni filter
                                    </div>

                                </div>
                                <div className="mt-15">
                                    <ListBuilder
                                        // showCheckBox={true}
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={this.state.fields}
                                        items={this.state.outputItems}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    />
                                </div>
                            </> :
                            this.state.buttonActive === TAB_OTPREMNICA ?
                                <>
                                    <div className="list-builder-filters-wrap-output">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='date'
                                                    value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                    onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                        this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date'
                                                    value={this.state.filterDateTo !== null ? this.state.filterDateTo : params.dateTo}
                                                    onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                        this.updateParams('dateTo', this.state.filterDateTo)
                                                    })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text'
                                                    value={this.state.filterClient !== null ? this.state.filterClient : params.client}
                                                    onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                        this.updateParams('client', this.state.filterClient)
                                                    })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text'
                                                    value={this.state.filterBusiness !== null ? this.state.filterBusiness : params.business}
                                                    onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                        this.updateParams('business', this.state.filterBusiness)
                                                    })} placeholder={'Poslovna'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text'
                                                    value={this.state.filterUser !== null ? this.state.filterUser : params.user}
                                                    onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                        this.updateParams('user', this.state.filterUser)
                                                    })} placeholder={'Korisnik'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text'
                                                    value={this.state.filterType !== null ? this.state.filterType : params.type}
                                                    onChange={(e) => this.setState({ filterType: e.target.value }, () => {
                                                        this.updateParams('type', this.state.filterType)
                                                    })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text'
                                                    value={this.state.filterDocument !== null ? this.state.filterDocument : params.document}
                                                    onChange={(e) => this.setState({ filterDocument: e.target.value }, () => {
                                                        this.updateParams('document', this.state.filterDocument)
                                                    })} placeholder={'Dokument'} />
                                            </div>
                                            <div className="field-strap-wrap" style={{ width: 200 }}>
                                                <Input type='select' style={{ width: 200 }}
                                                    value={this.state.filterStatus != null ? this.state.filterStatus : params.status}
                                                    onChange={(e) => this.setState({ filterStatus: e.target.value }, () => {
                                                        this.updateParams('status', this.state.filterStatus != -1 ? this.state.filterStatus : null)
                                                    })} placeholder={'Status'}>
                                                    <option
                                                        value={`${OTPREMNICA},${PROKNJIZENA_OTPREMNICA},${PAKOVANJE}`}>Status
                                                    </option>
                                                    <option value={OTPREMNICA}>Otpremnica</option>
                                                    <option value={PROKNJIZENA_OTPREMNICA}>Proknjižena otpremnica
                                                    </option>
                                                    <option value={PAKOVANJE}>Pakovanje</option>
                                                </Input>
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterUser: '',
                                                filterBusiness: '',
                                                filterType: '',
                                                filterDocument: ''
                                            }, () => {
                                                this.updateMultipleParams([{
                                                    name: 'dateFrom',
                                                    value: null
                                                }, { name: 'dateTo', value: null }, {
                                                    name: 'client',
                                                    value: null
                                                }, { name: 'user', value: null }, {
                                                    name: 'business',
                                                    value: null
                                                }, { name: 'type', value: null }, { name: 'document', value: null }])
                                            })
                                        }}>
                                            <span>&times;</span> Ukloni filter
                                        </div>

                                    </div>
                                    <div className="mt-15">
                                        <ListBuilder
                                            // showCheckBox={true}
                                            tabs={false}
                                            sort={false}
                                            hideFilters={true}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.total}
                                            showNumeration={false}
                                            // hidePagination={true}
                                            fields={this.state.fields}

                                            onClick={(item) => {
                                                if (item && item.documentNumber) {
                                                    this.setState({ outputForm: null, buttonActive: 3 }, () => {
                                                        this.setState({ outputForm: { documentNumber: item.documentNumber } }, () => {
                                                            this.remoteOnChange('documentNumber', item.documentNumber)


                                                        })
                                                    })
                                                }

                                            }}
                                            items={this.state.outputItems}
                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                </>
                }
                <InfoModal
                    isOpen={this.state.exportModal}
                    toggle={() => this.setState({ exportModal: false })}
                    title={'Export'}
                    info={
                        <div className={'container'}>
                            <div className="row">
                                {
                                    this.state.exportFieldList.map((item, idx) => {
                                        return (
                                            <div key={idx} className={'col-md-6 mb-2 '}>
                                                <Checkbox checked={this.state.exportFields[item.name]}
                                                    className={'mr-2'} onChange={(e) => {
                                                        const value = !this.state.exportFields[item.name]
                                                        this.setState({
                                                            exportFields: { ...this.state.exportFields, [item.name]: value }
                                                        })
                                                    }} label={item.label} />

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    }

                    buttons={[

                        {
                            text: 'Export',
                            onClick: async () => {
                                let fields = [];
                                for (const [key, value] of Object.entries(this.state.exportFields)) {
                                    if (value) {
                                        const obj = this.state.exportFieldList.find((el) => el.name === key)
                                        fields.push({ key, label: obj.label })
                                    }
                                }
                                const params = {}
                                for (const [key, value] of Object.entries(this._getParams())) {
                                    if (key === 'entries' || key === 'page') {
                                        continue;
                                    }
                                    params[key] = value;
                                }
                                const request = {
                                    filter: {
                                        entries: 10000,
                                        page: 0,
                                        additionalFilters: params
                                    },
                                    methods: 'post',
                                    url: 'data/output',
                                    fields,
                                }
                                const exportResult = await this._apiAsync('generic-export', request)
                                if (exportResult && exportResult) {
                                    if (exportResult.success) {
                                        window.location.href = env.API_URL + `${exportResult.file}`;
                                    }
                                }
                            }
                        }
                    ]}

                >
                </InfoModal>
                <iframe name="my-frame" id="my-frame" title="my-frame" style={{ display: 'none' }} ></iframe>
            </div>
        )
    }
}

export default Page(OutputPage);
