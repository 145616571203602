import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-reports': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/client', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    dateFrom: query.dateFrom,
                    dateTo: query.dateTo,
                    clientCode: query.clientCode,
                    business: query.business,
                    article: query.articleName,
                    page: query.page ? Number(query.page) : 0,
                    entries: query.entries ? Number(query.entries) : 10,
                    type: Number(query.type)

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.response.items,
                        total: result.response.total,
                        totalAmount: result.response.totalAmount
                    }
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    institutionType: query && query.institutionType ? query.institutionType : null,
                    withStock: true,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-business-units': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    query: query.query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'stampaApi': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then((res) => res.blob()).then((response) => {

                return response
            })
        }
    },
    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },
    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) => {
                return {
                    products: response.result.items
                }
            })
        }
    },
    'businessSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/business-units', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 5,
                    filter : data.value,
                    clientCode : data.clientCode
                })
            }).then(parseJSON).then((response) =>{
                return {
                    businessSearch : response.result.items
                }
            })
        }
    },
    
}