
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

import env from "react-dotenv";
import PrintHelper from '../../components/printHelper';


class LinkFields extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            items: [

            ],
            collection: 'Ulaz'
        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }


    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }
    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }


    render() {
        let params = this._getParams();
        console.log(this.state.form ? this.state.form.collection : '')

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Podešavanje za knjiženje</h1>
                    <div className="title-options-group">

                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={() => this.setState({ printHtml: null }, this.print)}>

                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => this.export()}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        {
                            this.state.printHtml ?
                                <PrintHelper html={this.state.printHtml} />
                                :
                                null
                        }

                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete-links', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="9">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}

                                fieldsToTrack={['code', 'collection']}
                                fieldTracker={async (name, value) => {
                                    if (value === 'output') {
                                        this.setState({
                                            collection: 'Izlaz'
                                        })
                                    } else if (value === 'entrance') {
                                        this.setState({
                                            collection: 'Ulaz'
                                        })
                                    } else if (value === 'naturalniRabat') {
                                        this.setState({
                                            collection: 'Naturalni rabat'
                                        })
                                    } else if (value === 'bookNotice') {
                                        this.setState({
                                            collection: 'KO'
                                        })
                                    } else if (value === 'supplier-returns') {
                                        this.setState({
                                            collection: 'Povrat Dobavljaču'
                                        })
                                    } else if (value === 'buyerReturns') {
                                        this.setState({
                                            collection: 'Povrat kupca'
                                        })
                                    } else if (value === 'nivelation') {
                                        this.setState({
                                            collection: 'Nivelacija'
                                        })
                                    }


                                }}

                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {

                                        this._api('edit-links', { data: data }, () => {
                                            this.setState({ form: undefined }, async () => {
                                                this._onLoad()
                                            })
                                        });
                                    } else {
                                        if (data._id) {
                                            delete data._id
                                        }
                                        this._api('new-links', { data: data }, () => {
                                            this.setState({ clientForm: {} }, async () => {
                                                this._onLoad()
                                            })
                                        });
                                    }

                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 4, sm: 12, xs: 12 },
                                                                style: {},
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        arrow: true,
                                                                        name: 'collection',
                                                                        label: 'Vrsta',
                                                                        next: 'client',
                                                                        values: [{ value: 'output', name: 'Izlaz' }, { value: 'entrance', name: 'Ulaz' }, { value: 'nivelation', name: 'Nivelacija' }, { value: 'supplier-returns', name: 'Povrat dobavljaču' }, { value: 'buyerReturns', name: 'Povrat kupcu' }, { value: 'naturalniRabat', name: 'Naturalni rabat' }, { value: 'bookNotice', name: 'Ko' }],
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        arrow: true,
                                                                        name: 'client',
                                                                        label: 'Komitent',
                                                                        next: 'entity',
                                                                        values: [{ value: 'false', name: 'Ne' }, { value: 'true', name: 'Da' }],
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        arrow: true,
                                                                        name: 'entity',
                                                                        label: 'Entitet',
                                                                        next: 'taxPayer',
                                                                        values: [{ value: 'RS', name: 'RS' }, { value: 'FBiH', name: 'FBiH' }, { value: 'BD', name: 'BD' }, { value: 'INO', name: 'INO' }],
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        arrow: true,
                                                                        name: 'taxPayer',
                                                                        label: 'Pdv',
                                                                        next: 'linkedLegalEntity',
                                                                        values: [{ value: 'false', name: 'Ne' }, { value: 'true', name: 'Da' }],
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        arrow: true,
                                                                        name: 'linkedLegalEntity',
                                                                        label: 'Ppl',
                                                                        next: 'description',
                                                                        values: [{ value: 'false', name: 'Ne' }, { value: 'true', name: 'Da' }],
                                                                        validate: []
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'description',
                                                                        label: 'Opis',
                                                                        next: 'owes',
                                                                        asyncValidation: true,
                                                                        validate: [

                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'owes',
                                                                        label: 'Duguje',
                                                                        next: 'asking',
                                                                        asyncValidation: true,
                                                                        validate: [

                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'asking',
                                                                        label: 'Potražuje',
                                                                        asyncValidation: true,
                                                                        validate: [

                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                        ]
                                                    },


                                                ]
                                            },

                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="3">
                            <div className="square-info-container">
                                <div className="square-info">
                                    <span className="square-info-title">{this.state.collection}</span>
                                    <div className="square-info-content-vertical">
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Prodajni iznos:</h6>
                                                    <p>totalSellPrice</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Izlaz' || this.state.collection == 'Naturalni rabat' || this.state.collection == 'Povrat Dobavljaču'  || this.state.collection == 'Povrat kupca' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Prodajni iznos:</h6>
                                                    <p>total</p>
                                                </div>
                                                : null}
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Ulazni PDV na trosak:</h6>
                                                    <p>totalPDVCost</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' || this.state.collection == 'Izlaz' || this.state.collection == 'Naturalni rabat' || this.state.collection == 'KO' || this.state.collection == 'Povrat Dobavljaču' || this.state.collection == 'Povrat kupca' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>{this.state.collection == 'Izlaz' || this.state.collection == 'Naturalni rabat' || this.state.collection == 'Povrat kupca' || this.state.collection == 'KO' ? 'Izlazni PDV:' : 'Ulazni PDV:'}</h6>
                                                    <p>totalPDV</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Fakturni iznos:</h6>
                                                    <p>factureValue </p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Nabavni iznos:</h6>
                                                    <p>purchasePriceValue </p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' || this.state.collection == 'Izlaz' || this.state.collection == 'Povrat kupca' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>{this.state.collection == 'Izlaz' || this.state.collection == 'Povrat kupca' ? 'Izlazni rabat:' : 'Ulazni rabat:'}</h6>
                                                    <p>totalDiscount</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Iznos marze:</h6>
                                                    <p>marginValue</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Iznos troska:</h6>
                                                    <p>totalCost</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'Ulaz' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Iznos troska 1:</h6>
                                                    <p>totalCost1</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'KO' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Stari iznos:</h6>
                                                    <p>beforeAmount</p>
                                                </div>
                                                : null
                                        }
                                        {
                                            this.state.collection == 'KO' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Novi iznos:</h6>
                                                    <p>newAmount</p>
                                                </div>
                                                : null
                                        }
                                         {
                                            this.state.collection == 'Nivelacija' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Stari iznos:</h6>
                                                    <p>oldPrice</p>
                                                </div>
                                                : null
                                        }
                                         {
                                            this.state.collection == 'Nivelacija' ?
                                                <div className="vertical-row-box vertical-row-box-flex links">
                                                    <h6>Novi iznos:</h6>
                                                    <p>newPrice</p>
                                                </div>
                                                : null
                                        }

                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div style={{ margin: '15px -15px 0 -15px' }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'collection', label: 'Vrsta'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'client', label: 'Komitent'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'entity', label: 'Entitet'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'taxPayer', label: 'PDV'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'linkedLegalEntity', label: 'PPL'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'description', label: 'Opis'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'itemNumber', label: 'Rbr'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'owes', label: 'Duguje'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'asking', label: 'Potražuje'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            collection: item && item.collection && item.collection == 'entrance' ? 'Ulaz' : item.collection == 'output' ? 'Izlaz' : item.collection == 'bookNotice' ? 'Ko' : item.collection == 'nivelation' ? 'Nivelacija' : item.collection == 'buyerReturns' ? 'Povrat kupcu' : item.collection == 'supplier-returns' ? 'Povrat dobavljaču' : item.collection == 'naturalniRabat' ? 'Naturalni rabat' : '',
                                            client: item.client ? 'Da' : 'Ne',
                                            linkedLegalEntity: item.linkedLegalEntity ? 'Da' : 'Ne',
                                            taxPayer: item.taxPayer ? 'Da' : 'Ne',

                                        }
                                    })}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => {
                                                    this.setState({ form: item })
                                                })
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(LinkFields);
