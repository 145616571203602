import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import error from '../assets/error.png';

class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    keypressHandler = (event) => {
        if (!this.props.isOpen){
            return;
        }

        if (event.key == 'Enter' && this.props.buttons[0] && this.props.buttons[0].onClick){
            this.props.buttons[0].onClick();
        }

        if (event.key == 'Escape' && this.props.buttons[1] && this.props.buttons[1].onClick){
            this.props.buttons[1].onClick();
        }

    }


    componentDidMount(){
        if(this.props.disableKeyPress) {
            return;
        }
        document.addEventListener('keyup', this.keypressHandler, false);
    }

    componentWillUnmount(){
        document.removeEventListener('keyup', this.keypressHandler);
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} zIndex={this.props.zIndex}>
                <ModalHeader className='info-modal-header-wrap'>
                    {
                        this.props.title ?
                            this.props.title
                            :
                            null
                    }
                    {
                        this.props.closeModal ?
                            <div className='close-modal-x' onClick={this.props.closeModal}>&times;</div>
                            :
                            null
                    }
                </ModalHeader>

                <ModalBody className="info-modal">
                    {/* <img src={error} /> */}
                    <div className="message">
                        {this.props.info}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {
                        this.props.buttons && this.props.buttons.map((item, idx) => {
                            return (
                                <Button className={item.className ? item.className : 'info-modal-button'} color={item.color ? item.color : 'primary'} onClick={item.onClick}>{item.text}</Button>
                            )
                        })
                    }

                </ModalFooter>
            </Modal >
        )
    }
}

export default InfoModal;
