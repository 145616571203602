
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

class Condition extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            printTable: false,
            exportTable: false,
            error: '',
        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();
        (async () => {
            let broj = await this._apiAsync('checkNextDocumentNumber', {})
            if (broj && broj.firstFreeNumber) {
                this.remoteOnChange('code', broj.firstFreeNumber)
            }
        })()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Uslovi</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={async () => { this.setState({ printTable: true }) }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={async () => { this.setState({ exportTable: true }) }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                if (data.result && data.result.error) {
                                    this.setState({
                                        error: data.result.error
                                    })
                                } else {
                                    this.setState({ groupsForm: {} }, () => {
                                        this._onLoad(); this.setState({ deleteModal: null }); (async () => {
                                            let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                            if (broj && broj.firstFreeNumber) {
                                                this.remoteOnChange('code', broj.firstFreeNumber)
                                            }
                                        })()
                                    })
                                }

                            });
                        }} >
                        Obrisati uslove <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['code']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'code' && value) {
                                        let res = await this._apiAsync('validate1', { value: value });

                                        if (res.status == 210) {
                                            this.setState({ form: null })
                                            this.remoteOnChange('name', '')
                                            this.remoteOnChange('numberOfDays', '')
                                        }

                                        if (res.status == 200) {
                                            this.setState({ form: null }, () => {
                                                this.setState({ form: res.result })
                                            })
                                        }

                                    }

                                    if (name == 'code' && !value) {
                                        this.remoteOnChange('name', '')
                                        this.remoteOnChange('numberOfDays', '')
                                        this.setState({ form: {} })

                                    }
                                }}




                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, async () => {
                                                this._onLoad();
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    } else {
                                        if (data._id) {
                                            delete data._id
                                        }
                                        this._api('api2', { data: data }, () => {
                                            this.setState({ form: {} }, async () => {
                                                this._onLoad()
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj uslov'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        next: 'name',
                                                                        label: 'Šifra',
                                                                        // textType: 'number',
                                                                        integer : true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra mora biti unešena!'),
                                                                            // async (value) => {
                                                                            //     try {
                                                                            //         if (value) {
                                                                            //             let res = await this._apiAsync('validate', { value: value, id: this.state.form ? this.state.form._id : null  });
                                                                            //             if (res.status == 400) {
                                                                            //                 return 'Šifra je zauzeta'
                                                                            //             }
                                                                            //             return undefined;
                                                                            //         }

                                                                            //     } catch (e) {
                                                                            //         return undefined;
                                                                            //     }
                                                                            // },
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 9, sm: 9, xs: 9 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'numberOfDays',
                                                                        label: 'Naziv',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv mora biti unešen!'),
                                                                            async (value) => {

                                                                                let conditionId = ''
                                                                                if (this.state.form && this.state.form._id) {
                                                                                    conditionId = this.state.form._id
                                                                                }

                                                                                let provjera = await this._apiAsync('checkName', { value: value.trim(), id: conditionId })
                                                                                console.log('provjera prov...', provjera)

                                                                                if (provjera && provjera.result) return 'Uslov pod tim nazivom već postoji!'
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'numberOfDays',
                                                                        label: 'Broj dana',
                                                                        asyncValidation: true,
                                                                        integer: true,
                                                                        next: 'submitForm',
                                                                        validate: [
                                                                            required('Broj dana mora biti unešen!'),
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista uslova'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'numberOfDays', label: 'BROJ DANA'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => {
                                                    this.setState({ form: item })
                                                })
                                                //onClick: (item) => console.log(item)
                                            },

                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                    printTable={this.state.printTable}
                                    exportTable={this.state.exportTable}
                                    genericPrint={this.props.genericPrint}
                                    genericExport={this.props.genericExport}
                                    changeState={this.changeState}
                                    genericData={{ url: 'data/conditions', title: 'Uslovi' }}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {
                    this.state.error && this.state.error != '' ?
                        <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: '', deleteModal: null })} error={this.state.error}>

                        </ErrorModal>
                        :
                        null
                }
            </div>
        )
    }
}

export default Page(Condition);
