import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-articles': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        article: query && query.article,
                        location: query && query.location,
                        withoutLocation: query && query.withoutLocation
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'update-postion': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/stock/update/position', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                       ...result
                    }
            })
        }
    },
    'update-postions': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/stock/update/positions', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                       ...result
                    }
            })
        }
    },
    

    

}