
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import Checkbox from '../../form-builder/fields/checkbox';
import Select from '../../form-builder/fields/select';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import DateField from '../../form-builder/fields/date';
import env from "react-dotenv";
import { parseJSON } from '../../helpers/json';
import InfoModal from '../../components/infoModal'


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class TradeBook extends RouteComponent {
    constructor(props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this)
        this.state = {
            products: [],
            groups: [],
            subgroups: [],
            series: {},
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            outputItems: [],
            manufacturers: [],
            total: [],

            filterWareHouse: '',
            filterTypeOfChange: '',
            pom: false,
            pom1: true,
            odgovor: [],
            btn_1: false,
            btn_2: false,
            btn_3: false,
            btn_4: false,
            btn_5: false,
            filterSellPrice: false,
            filterEstimation: false,
            filterTraffic: false,
            filterNotBooked: false,
            filterBooked: false,
            filterAll: false,
            filterPurchasePrice: false,
            filterArticleService: false,
            infoModal: false,
            group: '',
            subgroup: '',
            rokSerije: '',
            currentProducts: []

        }
    }

    componentDidMount() {
        this._onLoad();
        this.setState({ filterDateFrom: '01.01.' + this.props.selectedYear })
        this.setState({ filterDateTo: '31.12.' + this.props.selectedYear })
        this.setState({ filterArticleFrom: "" })
        this.setState({ filterArticleTo: "" })
        this.setState({ group: "" })
        this.setState({ subgroup: "" })
        document.getElementById('tradeBook-1').focus()

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
            this.getItems()

        }
    }



    getItems = async () => {
        this.setState({ pom: true }, async () => {
            let parametri = this._getParams()
            let niz1 = []
            let niz2 = []

            if (parametri && parametri.group) {
                niz1 = this.state.products.filter((item) => {
                    return item.groupCode == Number(parametri.group)
                })
            }

            if (parametri && parametri.subgroup) {
                if (niz1.length == 0) {
                    niz1 = this.state.products.filter((item) => {
                        return item.subgroupCode == Number(parametri.subgroup)
                    })
                } else {
                    niz1 = niz1.filter((item) => {
                        return item.subgroupCode == Number(parametri.subgroup)
                    })
                }

            }


            if (parametri.articleFrom && !parametri.articleTo) {
                if (niz1.length == 0) {
                    niz1 = this.state.products.filter((item) => {
                        return item.code >= Number(parametri.articleFrom)
                    })
                }else{
                    niz1 = niz1.filter((item) => {
                        return item.code >= Number(parametri.articleFrom)
                    })
                }
                
            }


            if (!parametri.articleFrom && parametri.articleTo) {
                if (niz1.length == 0) {
                    niz1 = this.state.products.filter((item) => {
                        return item.code  <= Number(parametri.articleTo)
                    })
                }else{
                    niz1 = niz1.filter((item) => {
                        return item.code  <= Number(parametri.articleTo)
                    })
                }
                
            }



            if (parametri.articleFrom && parametri.articleTo) {
                if (niz1.length == 0) {
                    niz1 = this.state.products.filter((item) => {
                        return item.code >= Number(parametri.articleFrom) && item.code <= Number(parametri.articleTo)
                    })
                }else{
                    niz1 = niz1.filter((item) => {
                        return item.code >= Number(parametri.articleFrom) && item.code <= Number(parametri.articleTo)
                    })
                }
                
            }


            if(parametri.articleFrom && parametri.articleTo && (parametri.articleFrom == parametri.articleTo)){
                if (niz1.length == 0) {
                    niz1 = this.state.products.filter((item) => {
                        return item.code == Number(parametri.articleFrom)
                    })
                }else{
                    niz1 = niz1.filter((item) => {
                        return item.code == Number(parametri.articleFrom)
                    })
                }
            }
           

            //filtracija za seriju
            if(parametri.serie){
                let seriesFilter = this.state.series.items.filter((item)=>{
                    return item.serialNumber == parametri.serie
                });


                if(seriesFilter.length != 0){
                   
                    if(seriesFilter.length == 1){
                       
                        if(niz1.length == 0){
                            niz1 = this.state.products.filter((item)=>{
                               return item.code == seriesFilter[0].article
                            })
                        }else{
                            niz1 = niz1.filter((item)=>{
                                return item.code == seriesFilter[0].article
                            })
                        }
                    }

                    if(seriesFilter.length > 1 && niz1.length == 1){
                        niz1 = seriesFilter.filter(item => item.article == niz1[0].code)

                    }
                   
                }
            }


            if (Object.keys(this._getParams()).indexOf('change') == -1 || this._getParams().change == '-1') {
                let podaciAll = await this._apiAsync('getDataAll', {...parametri, niz1})
                this.setState({ outputItems: podaciAll.items, total: podaciAll.total }, () => {
                    this.setState({ pom: false })

                })

            } else {
                let parametri = this._getParams()
                let podaci = await this._apiAsync('getData', {...parametri, niz1})
                this.setState({ outputItems: podaci.items, total: podaci.total }, () => {
                    this.setState({ pom: false })

                })
            }
        })

    }

    provjeraParametara = () => {
        let p1 = this._getParams()

        this.updateMultipleParams([
            { name: 'sellPrice', value: this.state.filterSellPrice },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'articleTo', value: this.state.filterArticleTo },
            { name: 'group', value: this.state.group },
            { name: 'subgroup', value: this.state.subgroup },
            { name: 'estimation', value: this.state.filterEstimation },
            { name: 'traffic', value: this.state.filterTraffic },
            { name: 'notBooked', value: this.state.filterNotBooked },
            { name: 'change', value: this.state.filterTypeOfChange },
            { name: 'booked', value: this.state.filterBooked },
            { name: 'all', value: this.state.filterAll },
            { name: 'purchasePrice', value: this.state.filterPurchasePrice },
            { name: 'articleservice', value: this.state.filterArticleService },
            { name: 'articleservice', value: this.state.filterArticleService },
            { name: 'serie', value: this.state.seria },

        ])

        this.setState({ pom2: true }, () => {
            let p2 = this._getParams()
            if ((JSON.stringify(p1) === JSON.stringify(p2))) {
                this.getItems()
            }
        })
    }

    stampa = () => {
        let vrstaPromjene = this._getParams().change
        let datumOd = this._getParams().dateFrom
        let datumDo = this._getParams().dateTo
        let naslov = this.state.btn_1 ? 'Trgovacka knjiga  VP' : this.state.btn_2 ? 'Trgovacka knjiga VP donos' : this.state.btn_3 ? 'Usluge' : this.state.btn_4 ? 'Totali po dokumentima' : ''




        if (this.state.btn_5) {
            this._apiAsync('stampaApiRazlika', { datumOd: datumOd, datumDo: datumDo }).then((response) => {
                const file = new Blob([response], {
                    type: "application/pdf"
                })

                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)

            })


        } else {
            this._apiAsync('stampaApi', { change: vrstaPromjene, naslov: naslov, datumOd: datumOd, datumDo: datumDo }).then((response) => {
                const file = new Blob([response], {
                    type: "application/pdf"
                })

                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)

            })
        }

    }

    handleEnter = (event) => {

        if (event.key.toLowerCase() == 'enter') {


            const currentField = Number(document.activeElement.id.split('-')[1])

            if (currentField == 2 || currentField == 3 || currentField == 4 || currentField == 5 || currentField == 6) {

                //za grupu
                if (currentField == 2) {
                    let naziv = ''
                    if (this.state.groups.filter(item => item.code == Number(document.activeElement.value)).length != 0) {
                        naziv = this.state.groups.filter(item => item.code == Number(document.activeElement.value))[0].name
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivGrupe: naziv })

                    } else {
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = ''
                        this.setState({ nazivGrupe: naziv })
                    }

                }


                //za podgrupu
                if (currentField == 3) {
                    let naziv = ''
                    if (this.state.subgroups.filter(item => item.code == Number(document.activeElement.value)).length != 0) {
                        naziv = this.state.subgroups.filter(item => item.code == Number(document.activeElement.value))[0].name
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivPodgrupe: naziv })

                      

                        this.setState({
                            currentProducts: this.state.currentProducts.filter((item) => {
                                return item.subgroupCode ? item.subgroupCode == Number(document.activeElement.value) : {}
                            }, () => { console.log('artikli pod grupe....', this.state.currentProducts); })
                        })

                    } else {
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivPodgrupe: naziv })
                    }
                }


                //za artikal
                if (currentField == 4) {
                    let naziv = ''
                    if (this.state.products.filter(item => item.code == Number(document.activeElement.value)).length != 0) {
                        naziv = this.state.products.filter(item => item.code == Number(document.activeElement.value))[0].name
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivArtikla: naziv })
                    } else {
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivArtikla: naziv })
                    }
                }

                if (currentField == 5) {
                    let naziv = ''
                    if (this.state.products.filter(item => item.code == Number(document.activeElement.value)).length != 0) {
                        naziv = this.state.products.filter(item => item.code == Number(document.activeElement.value))[0].name
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = naziv
                        this.setState({ nazivArtikla1: naziv })
                    } else {
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = ''
                        this.setState({ nazivArtikla1: naziv })
                    }
                }

                if (currentField == 6) {
                    let rok = ''
                    if (this.state.series.items.filter(item => item.serialNumber == document.activeElement.value).length != 0) {
                        rok = this.state.series.items.filter(item => item.serialNumber == document.activeElement.value)[0].expirationDate
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = rok
                        this.setState({ rokSerije: rok })
                    } else {
                        document.getElementById(document.activeElement.id + '-' + 'name').textContent = ''
                        this.setState({ rokSerije: rok })
                    }
                }
            }
            const nextField = currentField + 1
            document.getElementById(document.activeElement.id.split('-')[0] + '-' + nextField.toString()).focus()
        }
    }

    render() {
        let params = this._getParams();


        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet serija</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button onClick={this.stampa}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>
                <InfoModal
                    isOpen={this.state.infoModal}
                    toggle={() => this.setState({ infoModal: null })}
                    title="Upozorenje!"
                    info="Pogrešno ukucana šifra."
                    buttons={[
                        {
                            text: "Izlaz",
                            onClick: () => { this.setState({ infoModal: false }) }
                        }
                    ]}
                >
                </InfoModal>

                <Container fluid style={{ paddingRight: 0 }}>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row className="panel-backgound" style={{ paddingBottom: 20 }}>
                        <Col lg="3">
                            {/* <div className="analysis-col-1">
                                <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                    <div className="field-strap-wrap">
                                        <div className="check-box-wrap">
                                            <Row className="checkbox-list">
                                                <Col lg="12">
                                                    <Checkbox name="csellprice" checked={this.state.filterSellPrice}
                                                        onChange={(e) => {
                                                            this.setState({ filterSellPrice: !this.state.filterSellPrice }, () => {

                                                            })
                                                        }} label={'Prodajne'} />
                                                </Col>
                                                <Col lg="12">
                                                    <Checkbox name="cpurchaseprice" checked={this.state.filterPurchasePrice}
                                                        onChange={(e) => {
                                                            this.setState({ filterPurchasePrice: !this.state.filterPurchasePrice }, () => {

                                                            })
                                                        }} label={'Nabavne'} />
                                                </Col>
                                                <Col lg="12">
                                                    <Checkbox name="call" checked={this.state.filterAll}
                                                        onChange={(e) => {
                                                            this.setState({ filterAll: !this.state.filterAll }, () => {
                                                            })
                                                        }} label={'Sve'} />
                                                </Col>
                                                <Col lg="12">
                                                    <Checkbox
                                                        name="cbooked"
                                                        checked={this.state.filterBooked}
                                                        onChange={(e) => {
                                                            this.setState({ filterBooked: !this.state.filterBooked }, () => {

                                                            })
                                                        }}
                                                        label={'Proknjiženo'}
                                                    />
                                                </Col>
                                                <Col lg="12">
                                                    <Checkbox
                                                        name="cnotbooked"
                                                        checked={this.state.filterNotBooked}
                                                        onChange={(e) => {
                                                            this.setState({ filterNotBooked: !this.state.filterNotBooked }, () => {

                                                            })
                                                        }}
                                                        label={'Nije proknjiženo'}
                                                    />
                                                </Col>

                                                <Col lg="12">
                                                    <Checkbox name="ctraffic" checked={this.state.filterTraffic}
                                                        onChange={(e) => {
                                                            this.setState({ filterTraffic: !this.state.filterTraffic }, () => {


                                                            })
                                                        }} label={'Promet'} />
                                                </Col>
                                                <Col lg="12">
                                                    
                                                    <Checkbox name="cestimation" checked={this.state.filterEstimation}
                                                        onChange={(e) => {

                                                            this.setState({ filterEstimation: !this.state.filterEstimation}, () => {
                                                                // this.updateParams('estimation', this.state.filterEstimation)
                                                                // this.setState({ filterTraffic: !this.state.filterEstimation })

                                                            })
                                                        }} label={'Predračun'} />
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </Col>
                        <Col lg="5">
                            <div className="analysis-col-2">
                                <form>
                                    <Row>
                                        <Col lg="3">
                                            <div className="field-strap-wrap">
                                                <Label>Skladište</Label>
                                                <Input type='text' name='skladiste' readOnly value={this.state.filterChangeType != null ? this.state.filterChangeType : params.changeType} onChange={(e) => this.setState({ filterChangeType: e.target.value }, () => {
                                                    // this.updateParams('changeType', this.state.filterChangeType)
                                                })} />
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv skladište</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivSkladista'
                                                    readOnly
                                                    value={this.state.filterWareHouse != null ? this.state.filterChangeType : params.changeType}
                                                    onChange={(e) => this.setState({ filterWareHouse: e.target.value }, () => {
                                                        // this.updateParams('changeType', this.state.filterChangeType)
                                                    })}

                                                />
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="field-strap-wrap select-clasic">

                                                <Label>Vrsta promjene</Label>
                                                <Input
                                                    type='select'
                                                    id='tradeBook-1'
                                                    value={this.state.filterTypeOfChange != null ? this.state.filterTypeOfChange : params.change}
                                                    onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                                        // this.updateParams('change', this.state.filterTypeOfChange)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                >
                                                    <option value={-1}></option>
                                                    <option value={'ul'}>Ulaz</option>
                                                    <option value={'iz'}>Izlaz</option>
                                                    <option value={'pd'}>Povrat dobavljaču</option>
                                                    <option value={'pk'}>Povrat kupcu</option>
                                                    <option value={'ni'}>Nivelacija</option>
                                                    <option value={'in'}>Inventura</option>
                                                    <option value={'rz'}>Rezervacija</option>
                                                    <option value={'pr'}>Predračun</option>
                                                    <option value={'mn'}>Manjak</option>
                                                    <option value={'ot'}>Otpis</option>
                                                    <option value={'nr'}>Naturalni rabat</option>


                                                </Input>
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv vrste promjene</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivVP'
                                                    readOnly
                                                    value={this.state.filterChangeType}

                                                />
                                            </div>
                                        </Col>


                                        <Col lg="3">
                                            <div className="field-strap-wrap">
                                                <Label>Grupa</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-2'
                                                    name='group'
                                                    value={this.state.group != null ? this.state.group : params.group}
                                                    onChange={(e) => this.setState({ group: e.target.value }, () => {
                                                        // this.updateParams('user', this.state.group)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv grupe</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivGrupe'
                                                    id='tradeBook-2-name'
                                                    readOnly
                                                    value={this.state.nazivGrupe}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg="3">
                                            <div className="field-strap-wrap">
                                                <Label>Podgrupa</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-3'
                                                    name='subgroup'
                                                    value={this.state.subgroup != null ? this.state.subgroup : params.subgroup}
                                                    onChange={(e) => this.setState({ subgroup: e.target.value }, () => {
                                                        // this.updateParams('user', this.state.group)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="9">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv podgrupe`</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivKorisnika'
                                                    id='tradeBook-3-name'
                                                    readOnly
                                                    value={this.state.nazivPodgrupe}
                                                />
                                            </div>
                                        </Col>


                                        <Col lg="4">
                                            <div className="field-strap-wrap">
                                                <Label>Artikal od</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-4'
                                                    name='documentFrom'
                                                    value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : params.documentFrom}
                                                    onKeyDown={this.handleEnter}
                                                    onChange={(e) => this.setState({ filterArticleFrom: e.target.value }, () => {
                                                        // this.updateParams('documentFrom', this.state.filterArticleFrom)
                                                    })}

                                                />

                                            </div>
                                        </Col>
                                        <Col lg="8">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv artikla</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivArtikla'
                                                    id='tradeBook-4-name'
                                                    readOnly
                                                    value={this.state.nazivArtikla}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg="4">
                                            <div className="field-strap-wrap">
                                                <Label>Artikal do</Label>
                                                <Input
                                                    type='text'
                                                    name='articleTo'
                                                    id='tradeBook-5'
                                                    value={this.state.filterArticleTo != null ? this.state.filterArticleTo : params.articleTo}
                                                    // onKeyDown={this.handleEnter} 
                                                    onChange={(e) => this.setState({ filterArticleTo: e.target.value }, () => {
                                                        // this.updateParams('documentTo', this.state.filterArticleTo)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="8">
                                            <div className="field-strap-wrap">
                                                <Label>Naziv artikla</Label>
                                                <Input
                                                    type='text'
                                                    name='nazivArtikla'
                                                    id='tradeBook-5-name'
                                                    readOnly
                                                    value={this.state.nazivArtikla1}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                            <div className="field-strap-wrap">
                                                <Label>Serija</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-6'
                                                    name='seria'
                                                    value={this.state.seria != null ? this.state.seria : params.seria}
                                                    onChange={(e) => this.setState({ seria: e.target.value }, () => {
                                                        // this.updateParams('user', this.state.group)
                                                    })}
                                                    onKeyDown={this.handleEnter}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                            <div className="field-strap-wrap">
                                                <Label>Rok</Label>
                                                <Input
                                                    type='text'
                                                    id='tradeBook-6-name'
                                                    name='expirationDate'
                                                    value={this.state.rokSerije}
                                                    readOnly

                                                />
                                            </div>
                                        </Col>

                                        <Col lg="5">
                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-150'}
                                                    onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                        //this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })}
                                                    value={this.state.filterDateFrom}
                                                    label={'Datum od'}
                                                    id='tradeBook-7'
                                                    onKeyDown={this.handleEnter}
                                                ></DateField>
                                            </div>
                                        </Col>
                                        <Col lg="5">

                                            <div className="field-strap-wrap" style={{ width: 150 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-150'}
                                                    onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                        //this.updateParams('dateTo', this.state.filterDateTo)
                                                    })}
                                                    value={this.state.filterDateTo}
                                                    label={'Datum do'}
                                                    id='tradeBook-8'
                                                    onKeyDown={this.handleEnter}
                                                ></DateField>
                                            </div>
                                        </Col>

                                        <Col lg="5">
                                            <div className="field-strap-wrap select-clasic">

                                                <Label>Proizvod/Usluga</Label>
                                                <Input
                                                    type='select'
                                                    id='tradeBook-9'
                                                    value={this.state.filterArticleService != null ? this.state.filterArticleService : params.articleservice}
                                                    onChange={(e) => this.setState({ filterArticleService: e.target.value }, () => {
                                                        // this.updateParams('articleservice', this.state.filterArticleService)
                                                    })}>
                                                    <option value={-1}></option>
                                                    <option value={'usluga'}>Usluga</option>
                                                    <option value={'artikal'}>Artikal</option>
                                                    <option value={'roba'}>Roba</option>
                                                    <option value={'proizvod'}>Proizvod</option>
                                                    onKeyDown={this.handleEnter}
                                                </Input>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="analysis-buttons-container">
                                <div className="analsis-buttons-left">
                                    {/* <h6>TOTALI</h6> */}
                                    <button id='vel1' className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {



                                        this.provjeraParametara()


                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Totali</p>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 35 }}>

                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.pom}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[

                                        { type: 'text', name: 'code', label: 'Artikal'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'Naziv artikla'.translate(this.props.lang), multilang: false, allowSort: true } ,
                                        { type: 'text', name: 'unit', label: 'JM'.translate(this.props.lang), multilang: false, allowSort: true } ,
                                        { type: 'text', name: 'seria', label: 'Serija'.translate(this.props.lang), multilang: false, allowSort: true } ,
                                        { type: 'text', name: 'date', label: 'Rok'.translate(this.props.lang), multilang: false, allowSort: true } ,
                                        { type: 'text', name: 'ammount', label: 'Kolicina'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'Cijena'.translate(this.props.lang), multilang: false, allowSort: true } ,
                                        { type: 'text', name: 'value', label: 'Vrijednost'.translate(this.props.lang), multilang: false, allowSort: true }


                                    ]}

                                    items={this.state.outputItems}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>



                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                {/* <div className="col-info">
                                    <p>Iznos</p>
                                    <span>
                                        {
                                            this.state.outputItems.length != 0 ?
                                                this.props.roundToTwoDecimals(
                                                    this.state.outputItems.reduce(((sum, current) => {
                                                        sum = sum + current.ukupno
                                                        return sum
                                                    }), 0)
                                                )

                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    <span>
                                        {
                                            this.state.discount && this.state.discount != 0 ?
                                                numberWithCommas(parseFloat(this.state.discount).toFixed(2))
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div> */}
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalAmount - (this.state.discount ? this.state.discount : 0)).toFixed(2))
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>

            </div>
        )
    }
}

export default Page(TradeBook);


{/* <div className="info-colored">
                                <div className="col-info">
                                    <p>Osnovica</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>PDV</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Za plaćanje</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div> */}