import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import moment from 'moment';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal'
import Loader from "../../components/loader";
import PrintHelper from "../../components/printHelper";
import { has } from "loadsh/object";


import { debounce } from "lodash";


import {
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate, date } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import env from "react-dotenv";

class ReservationPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clients: [],
            reservationForm: null,
            entryOut: true,
            buttonActive: 1,
            listCustomFilter: true,
            items: [

            ],
            products: [],
            reservationItems: [],
            businessUnits: [],
            series: [],
            seriesDeadline: [],
            koRacun: false,
            koPeriod: false,
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            enableEdit: false,
            knjiz: true,
            articlesSearch: [],
            clientsSearch: [],
            descriptionModal: false,
            userNameModified: '',
            infoModal: false,
            printHtml: null,
            chosenClientCode: '',
            chosenProductCode: '',
            koTrue: false,
            helperVariabla: false,
            articleAmmount: 0,
            seriesSum: 0,



        }
    }

    componentDidMount() {
        this._onLoad();
        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.documentNumber) {
            let checkDocNumber = this.props[0].location.state.documentNumber;
            this.props[0].history.push({
                state: { documentNumber: null }
            })
            this.setState({ doNotCheckNextNumber: true })
            this._apiAsync('checkDocumentNumber', { documentNumber: checkDocNumber }).then((res) => {
                //ukoliko postoji rezultat citanja dokumenta
                if (res && res.result && res.result._id) {

                    this.setState({ reservationForm: null }, () => {
                        this.setState({ reservationForm: res.result }, () => {

                            if (res.result.items && res.result.items.length) {
                                this.setState({
                                    items: res.result.items,

                                }, () => {
                                    this._apiAsync('checkNextItemNumber', { documentNumber: checkDocNumber }).then((res) => {
                                        if (res && res.firstFreeItemNumber) {
                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                        }
                                    })
                                })
                            } else {
                                this.setState({
                                    items: [],

                                }, () => {
                                    this.remoteOnChange('item.itemNumber', this.state.itemNumber)
                                })
                            }

                        })
                    })

                } else if (this.state.prevDocumentNumber != checkDocNumber /*&& this.state.entranceForm._id*/) {
                    this.setState({ reservationForm: null }, () => {
                        this.setState({ reservationForm: { documentNumber: checkDocNumber } }, () => {

                            this.setState({ items: [], reservationForm: {} }, () => {
                                this._apiAsync('checkNextItemNumber', { documentNumber: checkDocNumber }).then((res) => {
                                    if (res && res.firstFreeItemNumber) {
                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                    }

                                })
                            })

                        })
                    })

                }

                this.setState({ prevDocumentNumber: this.props[0].location.state.documentNumber })
            })


        }

        let params = this._getParams();

        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }

        if (params.business && params.business != this.state.filterBusiness) {
            this.setState({ filterBusiness: params.business })
        }
        this.metoda()
    }

    articleSearchMethod = debounce(async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('item.article'))
            this.setState({ articlesSearch: articleList.articlesSearch })
    }, 400)


    metoda = async (value) => {

        this.articleSearchMethod(value)

    }

    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.registerGetValue('item.article') ? this.registerGetValue('item.article') : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            withoutSeries: true,
            serialNumber: value,
            deadline: true
        })
        console.log('PROCITANE SERIJE.....', resultSeries);
        if (resultSeries && resultSeries.items.length > 0 && this.registerGetValue('item.serialNumber')) {
            this.setState({ series: resultSeries.items })
        }
    }, 400);

    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }


    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('clientCode'))
            this.setState({ clientsSearch: clientList.clientsSearch })
    }

    metodaBusiness = async (value) => {
        let businessList = []
        businessList = await this._apiAsync('businessSearch', { value: value, clientCode: this.registerGetValue ? this.registerGetValue('clientCode') : '' })
        // console.log(businessList)
        if (this.registerGetValue && value === this.registerGetValue('businessUnit'))
            this.setState({ businessSearch: businessList.businessSearch })
    }

    prepareRequest(data) {
        let newObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (key === 'items' || key === 'totalDiscount' || key === 'totalDiscountKo') {
                continue;
            }
            newObject[key] = value;
        }
        if (has(newObject, 'item')) {
            delete newObject.item.stock;
            delete newObject.item.stockPrice;
            delete newObject.item.fundPrice;
            delete newObject.item.foundPrice;
            if (this.state.selectedProduct) {
                if (this.state.selectedProduct.manufacturer) {
                    newObject.item.manufacturer = this.state.selectedProduct.manufacturer;
                }
                if (this.state.selectedProduct.barcode) {
                    newObject.item.barcode = this.state.selectedProduct.barcode;
                }
            }
        }
        return newObject;
    }



    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.firstFreeNumber && this.state.firstFreeNumber && !this.state.doNotCheckNextNumber) {
            let reservationForm = this.state.reservationForm;

            if (!reservationForm || Object.keys(reservationForm).length == 0) {
                reservationForm = { reservationDate: this.state.dateNow }
            }
            reservationForm.documentNumber = this.state.firstFreeNumber;
            if (!reservationForm.item) {
                reservationForm.item = { itemNumber: 1 }
            }
            this.setState({ reservationForm: null }, () => {
                this.setState({ reservationForm })
            })
        }


    }


    goToFormTab = () => {
        this._onLoad()
        let reservationForm = this.state.reservationForm;
        if (!reservationForm) {
            reservationForm = {
                reservationDate: this.state.dateNow
            }
        }
        reservationForm.documentNumber = this.state.firstFreeNumber;
        if (!reservationForm.item) {
            reservationForm.item = { itemNumber: 1 }
        }
        this.setState({ reservationForm: null }, () => {
            this.setState({ reservationForm })
        })
    }

    sumiranjeRabata(niz) {
        let sum = 0

        niz.forEach((element) => {
            if (element.discount && !element.koTrue) {
                sum = Number(sum) + Number(element.discount)
            }
        })

        return Number(sum.toFixed(2))
    }

    sumiranjeRabataKo(niz) {
        let sum = 0

        niz.forEach((element) => {
            if (element.discount && element.koTrue) {
                sum = Number(sum) + Number(element.discount)
            }
        })

        return Number(sum.toFixed(2))
    }

    printOutput = async () => {
        const printHtml = await this._apiAsync('printOutputDocument', { documentNumber: this.state.reservationForm.documentNumber })
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }


    readClientAndBusinessUnits = async (value) => {
        this.remoteOnChange('businessUnit', '')
        this.setState({ businessUnits: [] })

        // console.log('KOMITENT-----POSLOVNA JEDINICA.....', value)

        this._apiAsync('get-clients', { filter: value })
            .then((clients) => {

                if (clients && clients.items) {
                    this.setState({ clients: clients && clients.items }, () => {
                        if (this.state.clients.filter(item => item.code == value).length == 1) {
                            if (this.state.clients.filter(item => item.code == value)[0].name) {
                                if (this.registerGetValue('clientName') != this.state.clients.filter(item => item.code == value)[0].name) {
                                    this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                }
                            }

                            this._apiAsync('check-businessUnits', { filter: this.state.clients.filter(item => item.code == value)[0]._id })
                                .then((result) => {

                                    if (result.items.businesUnits) {

                                        this.setState({ enableEdit: true }, () => {

                                            this._apiAsync('get-businessUnits', { filter: Number(this.registerGetValue('clientCode')) }).then((result) => {
                                                console.log('POSLOVNE JEDINICE NISU KAKO TREBA.....', result);
                                                if (result && result.items) {
                                                    this.setState({ businessUnits: result && result.items })
                                                    this.setState({ businessSearch: result && result.items })
                                                }
                                            })
                                        })
                                    } else {

                                        this.setState({ enableEdit: false })
                                    }
                                })

                            //potrebno je provjeriti rabat u uslovima i uporediti ga sa upisanom vrijednosti rabata
                            //ukoliko je veci upisan nego po uslovima, tada izlazi obavjestenje
                            let chosenClient = this.state.clients.filter(item => item.code == Number(this.registerGetValue('clientCode')))[0]
                            if (chosenClient && chosenClient._id && chosenClient.conditions) {
                                //citanje uslova
                                this.setState({ chosenClientCode: chosenClient.conditions })
                            }

                            //provjera da li je ukljucena knjizna obavjihest u registru komitenata
                            if (chosenClient && chosenClient._id && chosenClient.ko) {
                                this.setState({ chosenClientCodeKO: chosenClient.ko })
                            }

                        } else {
                            if (this.registerGetValue('clientName')) {
                                this.remoteOnChange('clientName', '')
                            }
                        }

                    })
                }

            })

    }


    render() {
        let params = this._getParams();

        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                }

                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive == 1
                                ?
                                'Lista rezervacija'
                                :
                                'Rezervacije'
                        }
                    </h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-extrabutton">
                                {/* <button className="posting-button">
                                    <span>Proknjiži</span>
                                </button> */}
                                <div className="title-options-group">
                                    {/* <button>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Snimi</span>
                                    </button> */}
                                    <button onClick={async () => {
                                        const params = {}
                                        for (const [key, value] of Object.entries(this._getParams())) {
                                            if (key === 'entries' || key === 'page') {
                                                continue;
                                            }
                                            params[key] = value;
                                        }
                                        const request = {
                                            filter: {
                                                entries: 10000,
                                                page: 0,
                                                additionalFilters: params
                                            },
                                            methods: 'post',
                                            url: 'data/reservation',
                                            fields: [
                                                { key: 'documentNumber', label: 'BROJ' },
                                                { key: 'reservationDate', label: 'DATUM' },
                                                { key: 'clientName', label: 'KOMITENT' },
                                                { key: 'businessUnitName', label: 'POSLOVNA' },
                                                { key: 'createdByUsername', label: 'KORISNIK' },
                                            ],
                                            title: "Rezervacije"
                                        }
                                        await this._apiAsync('generic-print', request)

                                    }}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={async () => {
                                        const fields = [
                                            { key: 'documentNumber', label: 'BROJ' },
                                            { key: 'reservationDate', label: 'DATUM' },
                                            { key: 'clientName', label: 'KOMITENT' },
                                            { key: 'businessUnitName', label: 'POSLOVNA' },
                                            { key: 'createdByUsername', label: 'KORISNIK' },
                                        ];
                                        const params = {}
                                        for (const [key, value] of Object.entries(this._getParams())) {
                                            if (key === 'entries' || key === 'page') {
                                                continue;
                                            }
                                            params[key] = value;
                                        }
                                        const request = {
                                            filter: {
                                                entries: 10000,
                                                page: 0,
                                                additionalFilters: params
                                            },
                                            methods: 'post',
                                            url: 'data/reservation',
                                            fields,
                                        }
                                        const exportResult = await this._apiAsync('generic-export', request)
                                        if (exportResult && exportResult) {
                                            if (exportResult.success) {
                                                window.location.href = env.API_URL + `${exportResult.file}`;
                                            }
                                        }
                                    }}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                    {/* <button>
                                        <div className="option red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <span>Otkaži</span>
                                    </button> */}
                                </div>
                            </div>

                            :
                            null
                    }
                    { }
                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1 }, () => { this._onLoad() })}>
                            Lista rezervacija
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive != 2) {
                                this.setState({ buttonActive: 2, reservationForm: null, items: [] }, () => this.goToFormTab())
                            }
                        }}>
                            Dodaj rezervaciju
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => { this.registerFocusedField = registerFocusedField }}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'clientCode', 'clientName', 'businessUnit', , 'item.article', 'item.price', 'item.discount', 'item.ammount', 'item.serialNumber', 'item.deadline']}
                                    fieldTracker={async (name, value) => {
                                        if (name == 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, reservationForm: null }, () => {
                                                this.remoteOnChange('businessUnit', '')
                                                this.remoteOnChange('clientCode', '')
                                                this.remoteOnChange('deliveryDate', '')
                                                this.remoteOnChange('reservationDate', '')
                                                this.remoteOnChange('item.itemNumber', '')
                                                this.remoteOnChange('item.article', '')
                                                this.remoteOnChange('item.serialNumber', '')
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.ammount', '')
                                                this.remoteOnChange('item.stock', '')
                                                this.remoteOnChange('item.price', '')
                                                this.remoteOnChange('item.total', '')
                                                this.setState({ reservationForm: {} })
                                            })
                                        }

                                        if (name == 'documentNumber' && value) {
                                            if (value) { //!= this.state.prevDocumentNumber
                                                // console.log('value.....', this.state.reservationForm.documentNumber, '........klijent kode......', this.state.reservationForm.clientCode)
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {

                                                    //ukoliko postoji rezultat citanja dokumenta
                                                    if (res && res.result && res.result._id) {
                                                        // console.log('ewewewewewe.....', res.result._id);
                                                        this.setState({ reservationForm: null }, () => {
                                                            this.setState({ reservationForm: res.result }, () => {

                                                                //poziva se da bi validacija ispravno radila
                                                                this.metodaClient(Number(this.state.reservationForm.clientCode)).then(() => {
                                                                    this.readClientAndBusinessUnits(Number(this.state.reservationForm.clientCode)).then(() => {

                                                                        this.remoteOnChange('businessUnit', this.state.reservationForm.businessUnit)
                                                                        this.remoteOnChange('businessUnitName', this.state.reservationForm.businessUnitName)
                                                                    })
                                                                })



                                                                if (this.state.reservationForm && this.state.reservationForm.businessUnit) {
                                                                    this.setState({ enableEdit: true })
                                                                } else {
                                                                    this.setState({ enableEdit: false })
                                                                }

                                                                if (this.state.reservationForm.tsModified) {

                                                                    let dan = new Date(this.state.reservationForm.tsModified * 1000).getDate()
                                                                    let mjesec = new Date(this.state.reservationForm.tsModified * 1000).getMonth() + 1
                                                                    let godina = new Date(this.state.reservationForm.tsModified * 1000).getFullYear()
                                                                    let sati = new Date(this.state.reservationForm.tsModified * 1000).getHours()
                                                                    let minuti = new Date(this.state.reservationForm.tsModified * 1000).getMinutes()

                                                                    if (dan < 10) {
                                                                        dan = '0' + dan
                                                                    }

                                                                    if (mjesec < 10) {
                                                                        mjesec = '0' + mjesec
                                                                    }

                                                                    if (sati < 10) {
                                                                        sati = '0' + sati
                                                                    }

                                                                    if (minuti < 10) {
                                                                        minuti = '0' + minuti
                                                                    }

                                                                    this.setState({ timeModified: { dan: dan, mjesec: mjesec, godina: godina, sati: sati, minuti: minuti } }, () => {
                                                                        this.setState({ userString: this.state.timeModified.dan + '.' + this.state.timeModified.mjesec + '.' + this.state.timeModified.godina + ' - ' + this.state.timeModified.sati + ':' + this.state.timeModified.minuti })
                                                                    })


                                                                } else {
                                                                    this.setState({ timeModified: { dan: '', mjesec: '', godina: '', sati: '', minuti: '' } })
                                                                }




                                                                if (res.result.items && res.result.items.length) {
                                                                    this.setState({
                                                                        items: res.result.items,

                                                                    }, () => {
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        items: [],

                                                                    }, () => {
                                                                        this.remoteOnChange('item.itemNumber', this.state.itemNumber)
                                                                    })
                                                                }

                                                            })
                                                        })

                                                    } else if (this.state.prevDocumentNumber != value /*&& this.state.entranceForm._id*/) {
                                                        console.log('sasasa....', this.state.reservationForm);
                                                        // this.setState({ reservationForm: null }, () => {
                                                        //     this.setState({ reservationForm: { documentNumber: value } }, () => {
                                                        //         this.remoteOnChange('reservationDate', this.state.dateNow)
                                                        //         this.remoteOnChange('deliveryDate', '')
                                                        //         this.remoteOnChange('clientCode', '')
                                                        //         this.setState({ items: [], reservationForm: {} }, () => {
                                                        //             this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                        //                 if (res && res.firstFreeItemNumber) {
                                                        //                     this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        //                 }

                                                        //             })
                                                        //         })

                                                        //     })
                                                        // })
                                                        this.setState({ reservationForm: null }, () => {
                                                            this.setState({ reservationForm: { documentNumber: value } }, () => {

                                                                this.setState({ items: [], reservationForm: {} }, () => {
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            this.remoteOnChange('reservationDate', this.state.dateNow)
                                                                        }

                                                                    })
                                                                })

                                                            })
                                                        })

                                                    }

                                                    this.setState({ prevDocumentNumber: value })
                                                })
                                            }
                                        }
                                        if (name == 'clientCode' && !value) {

                                            this.setState({ businessUnits: [] })
                                            this.remoteOnChange('clientName', '')
                                            this.setState({ enableEdit: false })
                                            this.remoteOnChange('businessUnit', '')


                                        }

                                        if (name == 'clientCode' && value) {

                                            this.readClientAndBusinessUnits(value)

                                        }

                                        if (name == 'businessUnit' && value) {

                                            if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                this.remoteOnChange('businessUnitName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                            } else {
                                                this.remoteOnChange('businessUnitName', '')
                                            }


                                        }

                                        if (name == 'businessUnit' && !value) {
                                            this.remoteOnChange('businessUnitName', '')
                                        }

                                        if (name == 'item.article' && value) {
                                            this.remoteOnChange('item.serialNumber', '');
                                            if (value) {

                                                let artikli = await this._apiAsync('get-articles', { filter: value })
                                                if (artikli && artikli.items) {
                                                    this.setState({ products: artikli && artikli.items })
                                                }

                                                if (this.state.products && this.state.products.filter(item => item.code == value).length != 0) {
                                                    this.setState({ chosenManufacturerCode: this.state.products.filter(item => item.code == value)[0].manufacturer })
                                                    this._apiAsync('get-price', { article: this.state.products.filter(item => item.code == value)[0]._id }).then((res) => {
                                                        this.remoteOnChange('item.price', res.price.sellPrice)
                                                        this.remoteOnChange('item.stock', (res.price.quantity))
                                                        this.setState({ articleAmmount: Number(res.price.quantity) })
                                                    })
                                                }

                                                let series = await this._apiAsync('get-series', { article: value, withoutSeries: true, deadline: true });
                                                if (series && series.items) {
                                                    if (series.items.length === 1) {
                                                        this.remoteOnChange('item.serialNumber', series.items[0].serialNumber)
                                                    }
                                                    this.setState({ series: series.items.filter((item) => { return item.ammount > 0 }) }, () => {
                                                        this.setState({
                                                            seriesSum: this.state.series.filter(item => !item.deadline).reduce((sum, currentValue) => {
                                                                return sum + currentValue.ammount
                                                            }, 0)
                                                        })
                                                    })
                                                } else {
                                                    this.setState({ series: [] })
                                                }

                                            }

                                        }

                                        if (name == 'item.article' && !value) {
                                            this.setState({ series: [] })
                                            this.remoteOnChange('item.serialNumber', '')
                                            this.remoteOnChange('item.price', '')
                                            this.remoteOnChange('item.ammount', '')
                                            this.remoteOnChange('item.stock', '')
                                            this.remoteOnChange('item.fundPrice', '')
                                            this.remoteOnChange('item.discount', '')
                                            this.remoteOnChange('item.total', '')
                                        }


                                        if (name == 'item.serialNumber' && value) {


                                            if (value) {
                                                let article = this.registerGetValue('item.article');
                                                let series = await this._apiAsync('get-series', { article: article, filter: value });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items }, () => {
                                                        if (this.state.series && this.state.series.length) {
                                                            if (this.state.series.filter(item => item.serialNumber == value).length == 1) {
                                                                let item = this.state.series.filter(item => item.serialNumber == value)[0];
                                                                console.log('PROVJERA SERIJE.....', item)
                                                                if (item && item.newExpirationDate) {
                                                                    // if (this.registerGetValue('item.deadline') != item.newExpirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.newExpirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                    // }
                                                                } else if (item && item.expirationDate) {
                                                                    // if (this.registerGetValue('item.deadline') != item.expirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.expirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                    // }
                                                                }

                                                            } else {
                                                                if (this.registerGetValue('item.deadline')) {
                                                                    this.remoteOnChange('item.deadline', '')
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    if (this.registerGetValue('item.deadline')) {
                                                        this.remoteOnChange('item.deadline', '')
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.deadline')) {
                                                    this.remoteOnChange('item.deadline', '')
                                                }

                                            }
                                        }

                                        if (name == 'item.serialNumber' && !value) {
                                            let series = await this._apiAsync('get-series', { article: Number(this.registerGetValue('item.article')), withoutSeries: true, deadline: true });
                                            if (series && series.items) {
                                                this.setState({ series: series.items })
                                            } else {
                                                this.setState({ series: [] })
                                            }
                                        }

                                        if (name == 'item.deadline' && value) { }

                                        if (name == 'item.price' && value) {
                                            if (value) {
                                                let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                let rabat = parseFloat(this.registerGetValue('item.discount'))
                                                if (isNaN(rabat)) {
                                                    rabat = 0
                                                }

                                                let cijena = parseFloat(value)
                                                let total = kolicina * cijena - rabat
                                                this.remoteOnChange('item.total', total)
                                            }

                                        }

                                        if (name == 'item.ammount' && value) {

                                            // console.log('upisana vrijednost....', value)
                                            // console.log('procitana vrijesnost....', this.registerGetValue('item.stock'))


                                            if (value) {

                                                let kolicina = parseFloat(value)
                                                let cijena = parseFloat(this.registerGetValue('item.price'))
                                                let rabat = parseFloat(this.registerGetValue('item.discount'))
                                                if (isNaN(rabat)) {
                                                    rabat = 0
                                                }
                                                let total = kolicina * cijena - rabat
                                                // console.log('Ukupno total...', total)
                                                this.remoteOnChange('item.total', total)

                                            } else {
                                                let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                let cijena = parseFloat(this.registerGetValue('item.facturePrice'))
                                                // let rabat = parseFloat(value)
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                            }
                                        }

                                        if (name == 'item.discount' && value) {

                                            if (value) {
                                                let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                let cijena = parseFloat(this.registerGetValue('item.price'))
                                                let rabat = parseFloat(value)
                                                let total = kolicina * cijena - rabat
                                                this.remoteOnChange('item.total', total)

                                            } else {
                                                let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                let cijena = parseFloat(this.registerGetValue('item.facturePrice'))
                                                // let rabat = parseFloat(value)
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                            }

                                            // console.log('Uslovi...', this.state.chosenClientCode, '....proizvodjac....', this.state.chosenManufacturerCode)

                                        }

                                    }
                                    }
                                    onSubmit={async (data, notFinished) => {

                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                delete data.item;
                                            }
                                        }

                                        console.log('DATA.FINISHED....', data);
                                        //    console.log('DATA.FINISHED....', data);

                                        if (data._id) {
                                            console.log('Update  podaci...', data)

                                            data.koRacun = this.state.koRacun ? this.state.koRacun : false
                                            data.koPeriod = this.state.koPeriod ? this.state.koPeriod : false
                                            if (data.item) {
                                                data.item.koTrue = this.state.koTrue ? this.state.koTrue : false
                                            }

                                            this._apiAsync('update-document', data).then((res) => {

                                                this.setState({ reservationForm: null }, () => this.setState({
                                                    reservationForm: res.res
                                                }, () => { this.setState({ items: res.res.items }) }))

                                                if (res.res.items && res.res.items.length) {
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                            this.remoteOnChange('item.article', '')
                                                            this.remoteOnChange('item.serialNumber', '')
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }
                                                this.setState({ koTrue: false })
                                            })


                                        } else {

                                            data.koRacun = this.state.koRacun ? this.state.koRacun : false
                                            data.koPeriod = this.state.koPeriod ? this.state.koPeriod : false
                                            data.item.koTrue = this.state.koTrue ? this.state.koTrue : false

                                            const request = await this.prepareRequest(data);

                                            console.log('PRAVLJENJE REQUESTA....', request);


                                            this._apiAsync('insert-new', request).then((res) => {
                                                if (res.result && res.result._id) {
                                                    this.setState({ reservationForm: null }, () => this.setState({ reservationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] }))
                                                    if (res.result.items && res.result.items.length) {
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                this.remoteOnChange('item.article', '')
                                                                this.remoteOnChange('item.serialNumber', '')
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.itemNumber', 1)
                                                    }

                                                }
                                                this.setState({ koTrue: false })
                                            })



                                        }


                                    }}
                                    initialValues={this.state.reservationForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand',
                                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                                    children: [this.state.reservationForm && this.state.reservationForm._id && !this.state.reservationForm.notFinished ?
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            children: <span>Proknjiži</span>,
                                                                            className: 'posting-button',
                                                                            label: 'Kompenzacija',
                                                                            // asyncValidation: true,
                                                                            action: 'custom',
                                                                            onClick: () => {
                                                                                console.log('na dugmetu proknjizeno.....', this.state.reservationForm)
                                                                                if (this.state.reservationForm && this.state.reservationForm._id && !this.state.reservationForm.notFinished) {
                                                                                    this.setState({ infoModal: true })
                                                                                }
                                                                            }
                                                                        } : {}
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom reservation-buttons-fix',
                                                                    width: { lg: 9, sm: 9, xs: 9 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.submit()

                                                                                                // if(this.state.reservationForm && this.state.reservationForm._id && this.state.reservationForm.notFinished){

                                                                                                // }
                                                                                                this.setState({ knjiz: false })

                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.reservationForm && this.state.reservationForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput().then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })
                                                                                                }
                                                                                                )

                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },

                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                console.log('REZERVASSTION FORM.....RF....', this.state.reservationForm)
                                                                                                if (this.state.reservationForm && this.state.reservationForm._id) {
                                                                                                    this.setState({ deleteDocumentModal: this.state.reservationForm })
                                                                                                } else {
                                                                                                    this.setState({ reservationForm: null }, async () => {

                                                                                                        this.setState({
                                                                                                            reservationForm: {
                                                                                                                documentNumber: this.state.firstFreeNumber,
                                                                                                                reservationDate: this.state.dateNow,
                                                                                                                clientCode: '',
                                                                                                                businessUnit: '',
                                                                                                                item: {
                                                                                                                    itemNumber: 1,
                                                                                                                    article: '',
                                                                                                                    price: ''
                                                                                                                }

                                                                                                            }
                                                                                                        })
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-1",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'reservationDate',
                                                            label: 'Broj dokumenta',
                                                            // textType: 'number',
                                                            integer: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'reservationDate',
                                                            next: 'deliveryDate',
                                                            label: 'Datum rezervacije',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'deliveryDate',
                                                            next: 'clientCode',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Datum isporuke mora biti unesen!"

                                                                    if (moment(value, 'DD.MM.YYYY').unix() < moment(this.registerGetValue('reservationDate'), 'DD.MM.YYYY').unix()) {
                                                                        return "Mora biti veći ili jednak od datuma fakturisanja";
                                                                    }
                                                                },
                                                                validateDate('Datum isporuke nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: this.state.enableEdit ? 'businessUnit' : 'item.article',
                                                                            label: 'Šifra komitenta',
                                                                            textType: 'number',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            title: 'Pregled komitenata',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            entrance: true,
                                                                            control: true,
                                                                            enableEdit: this.state.enableEdit,
                                                                            changeState: (callback) => {
                                                                                this.setState({ enableEdit: !this.state.enableEdit }, () => {
                                                                                    if (callback) {
                                                                                        callback()
                                                                                    }
                                                                                })

                                                                            },
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                (value) => {

                                                                                    this.metodaClient(value).then(() => {
                                                                                        if (this.state.clientsSearch != 0 && this.state.clientsSearch.filter(item => item.code == value).length == 0 && this.state.clientsSearch.filter(item => item.name == value).length == 0) {
                                                                                            return 'Neispravan unos!!!!'
                                                                                        }
                                                                                    })

                                                                                    if (this.state.clientsSearch.length == 0 && this.state.clients.filter(item => item.code == value).length == 0 && this.state.clients.filter(item => item.name == value).length == 0) {
                                                                                        return "Neispravan unos!"
                                                                                    }


                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            next: 'businessUnit',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-2-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'businessUnit',
                                                                            name: 'businessUnit',
                                                                            next: 'item.article',
                                                                            textType: 'number',
                                                                            metoda: (value) => { this.metodaBusiness(value) },
                                                                            dataModal: this.state.businessSearch,
                                                                            values: [...this.state.businessUnits.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            label: 'Poslovna jedinica',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            readOnly: this.state.enableEdit ? false : true,
                                                                            // readOnly: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                (value) => {
                                                                                    if (this.state.businessUnits.length != 0) {
                                                                                        if (!value) {
                                                                                            return "Unesite poslovnu jedinicu!"
                                                                                        }
                                                                                    }
                                                                                },
                                                                                (value) => {
                                                                                    if (value) {
                                                                                        if (this.state.businessSearch.length == 0 && this.state.businessUnits.filter(item => item.code == value).length == 0 && this.state.businessUnits.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.businessSearch.length != 0 && this.state.businessSearch.filter(item => item.code == value).length == 0 && this.state.businessSearch.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessUnitName',
                                                                            next: 'jci',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.article',
                                                            label: 'Redni broj',
                                                            textType: 'number',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Redni broj mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            textType: 'number',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    // console.log('.............rabat........', this.registerGetValue('item.discount'))
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;

                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    // if (!(item && Object.keys(item).length > 0 || !this.state.reservationForm._id) && this.state.reservationForm._id && this.state.knjiz) {
                                                                    //     checkValidation = true;
                                                                    // }


                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"
                                                                    this.setState({ knjiz: true })
                                                                },

                                                                async (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }

                                                                    let checkValidation = false;

                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    // if (!(item && Object.keys(item).length > 0 || !this.state.reservationForm._id) && this.state.reservationForm._id && this.state.knjiz) {
                                                                    //     checkValidation = true;
                                                                    // }
                                                                    if (checkValidation) {
                                                                        let series = await this._apiAsync('get-series', { article: Number(value), withoutSeries: true, deadline: true });
                                                                        if (series && series.items) {
                                                                            this.setState({ series: series.items })
                                                                        } else {
                                                                            this.setState({ series: [] })
                                                                        }

                                                                        // let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));

                                                                        if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!"
                                                                        }
                                                                        if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!!"
                                                                        }

                                                                        this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                                    }



                                                                },


                                                            ]
                                                        },
                                                        {
                                                            type: 'series',
                                                            name: 'item.serialNumber',
                                                            next: 'item.ammount',
                                                            metoda: (value) => { this.metodaSeries(value) },
                                                            dataModal: this.state.series,
                                                            title: 'Pregled serija',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {

                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));

                                                                    if (!(Object.keys(item).length >= 1 && Object.keys(item)[0] == 'itemNumber' && this.state.reservationForm._id)) {

                                                                        if (this.state.series.length != 0 && !value && this.state.articleAmmount == this.state.seriesSum) {
                                                                            return 'Serija mora biti unešena!!'
                                                                        }

                                                                        if (this.state.series.length == 0 && this.state.numberOfSeries != 0) {
                                                                            return 'Neispravan unos!!'
                                                                        }

                                                                        if (this.state.numberOfSeries == 0 && value) {
                                                                            return 'Neispravan unos!'
                                                                        }
                                                                    }

                                                                },

                                                                async () => {

                                                                    if (this.state.series && this.state.series.length != 0) {


                                                                        this.remoteOnChange('item.stock', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.ammount)

                                                                        this.remoteOnChange('item.deadline', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.expirationDate)

                                                                    }

                                                                }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            // values: [...this.state.seriesDeadline.map((element) => {
                                                            //     let obj = {
                                                            //         name: element.expirationDate,
                                                            //         value: element.expirationDate
                                                            //     }
                                                            //     return obj
                                                            // })],
                                                            readOnly: true,
                                                            label: 'Rok',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Cijena mora biti unesena!" }

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.ammount',
                                                                            next: 'item.price',
                                                                            label: 'Količina',
                                                                            // textType: 'number',
                                                                            integer: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    for (var key in item) {
                                                                                        if (!item[key]) {
                                                                                            delete item[key];
                                                                                        }
                                                                                    }

                                                                                    let checkValidation = false;

                                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Količina mora biti unesena!"
                                                                                },

                                                                                (value) => {
                                                                                    if (Number(value) > Number(this.registerGetValue('item.stock'))) return "Unijeta je količina veća od dozvoljene!"
                                                                                }

                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            // next: 'discount',
                                                                            label: 'Zaliha',
                                                                            textType: 'number',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.price',
                                                                            next: 'item.discount',
                                                                            label: 'Cijena',
                                                                            // textType: 'number',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    for (var key in item) {
                                                                                        if (!item[key]) {
                                                                                            delete item[key];
                                                                                        }
                                                                                    }

                                                                                    let checkValidation = false;

                                                                                    if (item && Object.keys(item).length > 0 && this.state.reservationForm?._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Cijena mora biti unesena!"
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.fundPrice',
                                                                            // next: 'discount',
                                                                            label: 'Fond cijena',
                                                                            textType: 'number',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.discount',
                                                            next: 'submitForm',
                                                            afterSubmitFocus: 'item.article',
                                                            label: 'Rabat',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            // textType: 'number',
                                                            validate: [
                                                                async (value) => {

                                                                    if (!value) {

                                                                        if (this.state.chosenClientCodeKO == 'racun' || this.state.chosenClientCodeKO == 'period') {
                                                                            if (this.state.chosenClientCode && this.state.chosenManufacturerCode) {
                                                                                let discount = {}

                                                                                discount = await this._apiAsync('get-discount', { condition: this.state.chosenClientCode, manufacturer: this.state.chosenManufacturerCode })
                                                                                console.log('treci...', (discount && discount.result && discount.result.length), '..............')
                                                                                if (discount && discount.result && discount.result.length != 0) {

                                                                                    //treba se izracunati nova vrijednost totalaa.....
                                                                                    let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                                                    let cijena = parseFloat(this.registerGetValue('item.price'))
                                                                                    let rabat = parseFloat(kolicina * cijena * discount.result[0].percent / 100)
                                                                                    let total = kolicina * cijena - rabat
                                                                                    this.remoteOnChange('item.discount', rabat)
                                                                                    this.remoteOnChange('item.total', total)
                                                                                    this.setState({ koTrue: true })

                                                                                }

                                                                            }
                                                                        }

                                                                    }
                                                                },


                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                                <InfoModal
                                    isOpen={this.state.infoModal}
                                    title="UPOZORENJE!"
                                    info={'Jeste li sigurni da želi proknjižiti predračun?'}
                                    buttons={[
                                        {
                                            text: "OK",
                                            onClick: () => {

                                                if (this.state.reservationForm && this.state.reservationForm._id && !this.state.reservationForm.notFinished) {
                                                    let temp = this.state.reservationForm

                                                    //izbrisati ID predracuna  i broj predracuna
                                                    temp.factureDate = temp.reservationDate
                                                    temp.factureDateTs = temp.reservationDateTs
                                                    temp.subClass = 'Rezervacija'

                                                    // for (let i = 0; i < temp.items.length; i++) {
                                                    //     temp.items[i].price = temp.items[i].facturePrice
                                                    //     delete temp.items[i].facturePrice
                                                    // }


                                                    delete temp.documentNumber
                                                    delete temp.estimationDate
                                                    delete temp.estimationDateTs


                                                    this._apiAsync('insert-new-output', { temp }).then((result) => {
                                                        // console.log('VRACENI PODACI....', result.result)
                                                        if (result) {
                                                            //ocistiti polja
                                                            this.setState({ reservationForm: null }, () => {
                                                                this.setState({ reservationForm: {} })
                                                                this.setState({ items: [] }, () => {
                                                                    this.remoteOnChange('documentNumber', result.result.freeDocumentNumber)
                                                                    this.remoteOnChange('deliveryDate', '')
                                                                    this.remoteOnChange('clientCode', '')
                                                                    this.remoteOnChange('businessUnit', '')
                                                                    this.remoteOnChange('item.itemNumber', 1)
                                                                })
                                                            })

                                                        }
                                                        this.props[0].history.push({
                                                            pathname: '/output',
                                                            search: '?entries=10&status=1,2,4',

                                                        })
                                                    })

                                                } else {
                                                    // console.log('POCETAK PROVJERA...u else...', this.state.reservationForm)
                                                }
                                            }
                                        },
                                        {
                                            text: "Izlaz",
                                            onClick: () => { this.setState({ infoModal: false }) }
                                        }
                                    ]}
                                >

                                </InfoModal>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} />,
                                                onClick: (item) => {
                                                    this.metoda(Number(item.article)).then(async () => {
                                                        let series = await this._apiAsync('get-series', { article: item.article });
                                                        if (series && series.items) {
                                                            this.setState({ series: series.items })
                                                        } else {
                                                            this.setState({ series: [] })
                                                        }
                                                    })



                                                    let reservationForm = this.state.reservationForm;
                                                    reservationForm.item = item;
                                                    this.setState({ reservationForm: null }, () => {
                                                        this.setState({ reservationForm })
                                                    })
                                                }
                                            },

                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {

                                    this._apiAsync('remove-item', { _id: this.state.reservationForm._id, itemNumber: this.state.deleteModal.itemNumber }).then((res) => {
                                        if (res.result && res.result._id) {
                                            this.setState({ reservationForm: null, deleteModal: null }, () => {
                                                this.setState({ reservationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                                            })
                                            if (res.result.items && res.result.items.length) {
                                                // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                    if (res && res.firstFreeItemNumber) {
                                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                    }

                                                })
                                            } else {
                                                this.remoteOnChange('item.itemNumber', 1)
                                            }

                                        }
                                    })
                                }} >
                                Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                            </DeleteModal>

                            <DeleteModal
                                isOpen={this.state.deleteDocumentModal}
                                toggle={() => this.setState({ deleteDocumentModal: null })}
                                handler={() => {
                                    if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                        this.setState({ deleteDocumentModal: null })
                                        this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                        return;
                                    } else {
                                        this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                            this.setState({ deleteDocumentModal: null, reservationForm: null, items: [], firstFreeNumber: null }, () => {
                                                this.setState({ reservationForm: {} }, () => {
                                                    this.remoteOnChange('reservationDate', this.state.dateNow)
                                                    console.log(this.state.reservationForm)
                                                    this._onLoad()
                                                })
                                                // this._onLoad()
                                            })

                                        })
                                    }
                                }} >
                                Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                            </DeleteModal>

                            {
                                this.state.descriptionModal ?
                                    <Modal isOpen={this.state.descriptionModal} size='lg' className="modal-wrap">
                                        <ModalHeader>
                                            <h4>Opis</h4>
                                            <div className='close-button' onClick={() => this.setState({ descriptionModal: false, description: '' })}>&times;</div>
                                        </ModalHeader>
                                        <ModalBody>
                                            <div className="dependent-costs-wrap">

                                                <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Label>Unesite opis</Label>
                                                    <Input type='textarea' style={{ height: 100 }} value={this.state.description} onChange={(e) => {
                                                        let value = e.target.value;

                                                        this.setState({ description: value })
                                                    }}>

                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>


                                            <Button
                                                onClick={() => {
                                                    if (this.state.reservationForm && this.state.reservationForm._id) {
                                                        this._apiAsync('update-document', { description: this.state.description, _id: this.state.reservationForm._id }).then((res) => {
                                                            if (res.res && res.res._id) {
                                                                this.setState({ reservationForm: null }, () => {
                                                                    this.setState({ reservationForm: res.res, items: res.res.items && res.res.items.length ? res.res.items : [], products: [] })
                                                                })
                                                                if (res.res.items && res.res.items.length) {
                                                                    // this.remoteOnChange('item.itemNumber', (res.res.items.length + 1))
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                        }

                                                                    })
                                                                } else {
                                                                    this.remoteOnChange('item.itemNumber', 1)
                                                                }

                                                            }
                                                        })

                                                    }
                                                    this.setState({ descriptionModal: null })

                                                }}>Snimi</Button>
                                        </ModalFooter>
                                    </Modal>
                                    :
                                    null

                            }

                            <div className="footer">
                                <div className="footer-left">
                                    {
                                        this.state.reservationForm && this.state.reservationForm._id ?
                                            <button className="blue-button" onClick={() => this.setState({ descriptionModal: true, description: this.state.reservationForm.description ? String(this.state.reservationForm.description) : '' })}>
                                                <div className="box box-blue">
                                                    <Isvg src={editIcon} />
                                                </div>
                                                <p>OPIS</p>
                                            </button>
                                            :
                                            null
                                    }
                                    {
                                        this.state.reservationForm && this.state.reservationForm._id ?
                                            <button onClick={() => this.setState({ deleteDocumentModal: this.state.reservationForm })}>
                                                <div className="box box-red">
                                                    <Isvg src={garbageOpionIcon} />
                                                </div>
                                                <p>BRISANJE DOKUMENTA</p>
                                            </button>
                                            :
                                            null
                                    }
                                    <div className="modified-div-wrap">
                                        <h6>Poslednja izmjena:</h6>
                                        <p>{this.state.reservationForm && this.state.reservationForm.modifiedByUsername ? this.state.reservationForm.modifiedByUsername : ""}</p>
                                        <p>{this.state.userString}</p>
                                    </div>
                                </div>
                                <div className="footer-right">
                                    <div className="discount-wrap">
                                        <div>
                                            <h6>K.O.</h6>
                                            <p>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.sumiranjeRabataKo(this.state.items)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </p>
                                        </div>
                                        <div>
                                            <h6>RABAT</h6>
                                            <p>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.sumiranjeRabata(this.state.items)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </p>
                                        </div>
                                    </div>
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        this.state.buttonActive == 1 ?
                            <>
                                {
                                    this.state.listCustomFilter ?

                                        <div className="list-builder-filters-wrap">
                                            <div className="list-builder-filters list-builder-filters-nivelation">
                                                <div className="field-strap-wrap">
                                                    <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                        this.updateParams('dateFrom', this.state.filterDateFrom, true)
                                                    })} placeholder={'Datum od'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                        this.updateParams('dateTo', this.state.filterDateTo, true)
                                                    })} placeholder={'Datum do'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                        this.updateParams('client', this.state.filterClient, true)
                                                    })} placeholder={'Komitent'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterBusiness} onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                        this.updateParams('business', this.state.filterBusiness, true)
                                                    })} placeholder={'Poslovna'} />
                                                </div>
                                                <div className="field-strap-wrap">
                                                    <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                        this.updateParams('user', this.state.filterUser, true)
                                                    })} placeholder={'Korisnik'} />
                                                </div>
                                            </div>
                                            <div className="clear-filters" onClick={() => {
                                                this.setState({
                                                    filterDateFrom: '',
                                                    filterDateTo: '',
                                                    filterClient: '',
                                                    filterBusiness: '',
                                                    filterUser: '',
                                                }, () => {
                                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'user', value: null }])
                                                })
                                            }}>
                                                <a><span>&times;</span> Ukloni filter</a>
                                            </div>
                                        </div>

                                        :

                                        null
                                }
                                <div className="mt-15">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        // hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'reservationDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'businessUnitName', label: 'POSLOVNA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            // { type: 'text', name: 'item.discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                            // { type: 'text', name: 'ammount', label: 'KO'.translate(this.props.lang), multilang: false, allowSort: true },
                                            // { type: 'text', name: 'ammount', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },
                                            // { type: 'text', name: 'ammount', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            // { type: 'text', name: 'ammount', label: 'VERZIJA'.translate(this.props.lang), multilang: false, allowSort: true },

                                        ]}

                                        onClick={(item) => {
                                            if (item && item.documentNumber) {
                                                this.setState({ reservationForm: null, buttonActive: 2 }, () => {
                                                    this.setState({ reservationForm: { documentNumber: item.documentNumber, clientCode: item.clientCode } }, () => {
                                                        this.remoteOnChange('documentNumber', item.documentNumber)
                                                    })
                                                })
                                            }
                                        }}

                                        items={this.state.reservationItems.map((item, idx) => {
                                            let total = item.items.reduce((sum, current) => {
                                                let res = sum + current.total
                                                return res
                                            }, 0)
                                            return {
                                                ...item,
                                                time: this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm'),
                                                total1: total
                                            }
                                        })}
                                        rawItems={this.state.reservationItems}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>

                            </>
                            :
                            <>
                            </>
                }



            </div>
        )
    }
}

export default Page(ReservationPage);
