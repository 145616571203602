import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';
import SelectWithSearch from '../../form-builder/fields/selectWithSearch';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DateField from '../../form-builder/fields/date';


import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            clients: [],
            reservation: false,
            totalPrice: 0,
            totalStock: 0,
            listFields: [
                { type: 'text', name: 'article', label: 'sifra'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'articleName', label: 'naziv'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'serialNumber', label: 'serija'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'deadline', label: 'rok_trajanja'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'ammount', label: 'lager'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'price', label: 'prodcena'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'value', label: 'vrednost'.translate(this.props.lang), multilang: false, allowSort: true },]
        }
    }

    componentDidMount() {
        // this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    exportStanje = async () => {

        this.updateMultipleParams([
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'type', value: 1 },
            { name: 'timestamp', value: new Date().getTime() },
            { name: 'page', value: 0 },
        ])

        let listFields = [
            { type: 'text', name: 'article', label: 'sifra'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'articleName', label: 'naziv'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'serialNumber', label: 'serija'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'deadline', label: 'rok_trajanja'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'ammount', label: 'lager'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'price', label: 'prodcena'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'value', label: 'vrednost'.translate(this.props.lang), multilang: false, allowSort: true },]
        this.setState({
            reservation: false,
            listFields: listFields
        })
    }

    exportProdaja = async () => {

        this.updateMultipleParams([
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'type', value: 2 },
            { name: 'timestamp', value: new Date().getTime() },
            { name: 'page', value: 0 },
        ])

        let listFields = [
            { type: 'text', name: 'article', label: 'sifra'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'articleName', label: 'naziv artikla'.translate(this.props.lang), multilang: false, allowSort: true },
            { type: 'text', name: 'ammount', label: 'isporuceno'.translate(this.props.lang), multilang: false, allowSort: true, },
            { type: 'text', name: 'price', label: 'cena'.translate(this.props.lang), multilang: false, allowSort: true, },
            { type: 'text', name: 'value', label: 'iznos'.translate(this.props.lang), multilang: false, allowSort: true, },]
        this.setState({
            reservation: false,
            listFields: listFields
        })
    }

    clearFilters = () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',


        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },


                ])
            setTimeout(() => {
                this.exportStanje()
            }, 300);
        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Export podataka Esensa</h1>
                    <div className="title-options-group">

                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <DateField
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                })}
                                                id={'dateFrom'}
                                                onKeyDown={(e) => {
                                                    if(e.keyCode === 13){
                                                        const element = document.getElementById('dateTo');
                                                        if(element){
                                                            element.focus();
                                                        }
                                                    }
                                                }}
                                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom : null}
                                                label={'Datum od'}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                })}
                                                id={'dateTo'}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : null}
                                                label={'Datum do'}
                                            ></DateField>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className={!this.state.reservation ? "blue-button" : "blue-button-outline"}
                                            style={{ width: 200 }} onClick={() => this.exportStanje()}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Pregled podataka stanje</p>
                                        </button>
                                    </div>


                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>

                                    </div>
                                    <div>
                                        <button
                                            className={!this.state.reservation ? "blue-button" : "blue-button-outline"}
                                            style={{ width: 200 }} onClick={() => this.exportProdaja()}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Pregled podataka prodaja</p>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                total={this.state.total}
                                loading={this.state.loading}
                                showNumeration={false}
                                fields={[

                                    ...this.state.listFields
                                ]}
                                items={this.state.items.map(item => {
                                    return {
                                        ...item,

                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg='6'>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Lager</h6>
                                    <h4>
                                        {numberWithCommas(Math.round((this.state.totalStock ? this.state.totalStock : 0) * 100) / 100)}
                                        {/* &nbsp;KM */}
                                    </h4>
                                </div>
                            </div>
                        </Col>
                        <Col lg='6'>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno</h6>
                                    <h4>
                                        {numberWithCommas(Math.round((this.state.totalPrice ? this.state.totalPrice : 0) * 100) / 100)}
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);