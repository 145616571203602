import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
   

    'getData': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBook', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                console.log(result);
                    return {
                        // odgovor: result,
                        // total: result.total,
                        ...result,
                        debt: result.debt,
                        discountSum:  result.discountSum,
                        divorce: result.divorce
                    }
            })
        }
    },

    'getDataAll': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookAll', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                        debt: result.debt,
                        discountSum:  result.discountSum,
                        divorce: result.divorce
                    }
            })
        }
    },

    'getDataDonos': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookDonos', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        odgovor: result,
                        total: result.total,
                        debt: result.debt,
                        discountSum:  result.discountSum,
                        divorce: result.divorce
                    }
            })
        }
    },

    'getDataDonosAll': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookDonosAll', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                        debt: result.debt,
                        discountSum:  result.discountSum,
                        divorce: result.divorce
                    }
            })
        }
    },

    'getDataRazCijena': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookRazlikaCijena', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300){
                    console.log(result);
                    return {
                        ...result,
                        sumPurchaseAmount: result.sumPurchaseAmount,
                        sumSalesAmount: result.sumSalesAmount,
                        sumAmountDiscount: result.sumAmountDiscount,
                        sumOutputVAT: result.sumOutputVAT,
                        sumDifferencePrice: result.sumDifferencePrice,
                        sumSalesAmountVat: result.sumSalesAmountVat
                    }
                }
            })
        }
    },

    'getDataRazCijenaAll': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookRazlikaCijenaAll', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                        debt: result.debt,
                        discountSum:  result.discountSum,
                        divorce: result.divorce
                    }
            })
        }
    },

    'stampaApi': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookStampa', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then((res) => res.blob()).then((response) => {
               
                return response
            })
        }
    },

    'stampaApiRazlika': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/tradeBookStampaRazlikaCijena', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then((res)=>res.blob()).then((response) => {
               
                    return response
            })
        }
    },

}