import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { saveAs } from "file-saver";
import printJS from 'print-js'
export default {
    'get-items': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/get/analytics-by-clients-and-accounts', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    let response = {}
                    if (result && result.response) {
                        response = { ...result.response }
                    }
                    if (result && result.sumObj) {
                        response.sumObj = result.sumObj
                    }

                    return {
                        ...response
                    }
                }

            })
        }
    },
    'get-client': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + `/data/clients/validate/${query.code}/null`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'get-account-numbers': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + `/data/get/account-numbers`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return result
            })
        }
    },

    'export-items': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/get/analytics-by-clients-and-accounts', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    return {
                        ...result
                    }

                }

            })
        }
    },

    'print-items': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/get/analytics-by-clients-and-accounts', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                        });
                    }
                })

        }
    },


}
