import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { uuid } from 'uuidv4'

const substr = 17;

class Text extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen: false,
            valueText: null,
            inputID: uuid(),
            selectedValue: null,
            selectedIndex: 0,
            pretraga: false,
            search: ''
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.value != prevProps.value && !this.props.value) {
            this.setState({ isOpen: false, valueText: '' })
        }

        if (prevState.selectedValue != this.state.selectedValue && this.state.selectedValue) {
            if (this.props.dataModal && this.props.dataModal.length && this.tableRef && this.tableRef.children[1]) {
                let prevIndex;
                if (prevState.selectedValue)
                    prevIndex = this.props.dataModal.findIndex(el => el.serialNumber == prevState.selectedValue)

                let index = this.props.dataModal.findIndex(el => el.serialNumber == this.state.selectedValue)
                let refInfo = this.tableRef.children[1].getBoundingClientRect();
                if (index < 0) {
                    return;
                }
                let elementInfo = this.tableRef.children[1].children[index].getBoundingClientRect()
                let minPosition = 0;
                let maxPosition = 0;
                minPosition = refInfo.top;
                maxPosition = refInfo.top + refInfo.height - 43;
                let elementPosition = 0;
                elementPosition = elementInfo.top;
                let maxElements = (maxPosition - minPosition) / 43 - 1;

                if (prevIndex && prevIndex < index) {
                    if (maxElements < index) {
                        this.tableRef.children[1].scrollTop = 43 * (index - maxElements);
                    }
                } else if (prevIndex && prevIndex > index) {
                    if (minPosition > elementPosition)
                        this.tableRef.children[1].scrollTop -= 43;
                }

            }
        }

        if (this.props.value != prevProps.value) {
            this.setState({
                search: this.props.value
            })
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.value) {
            this.props.metoda(this.props.value)

            this.setState({
                search: this.props.value
            })
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName} articles-field`} >
                <div ref={this.wrapperRef}>
                    <Label>{this.props.label}</Label>

                    <input autoComplete="off" id={this.state.inputID} className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control bg-arrow`} type={this.props.textType ? this.props.textType : "text"}
                           ref={(node) => {
                               if (this.props.setFieldRef) {
                                   this.props.setFieldRef(node)
                               }
                           }}

                           onKeyDown={(e) => {
                               if (e.key == 'Tab') {
                                   e.preventDefault()

                                   this.setState({ isOpen: true })

                               }
                           }}



                           onKeyUp={(e) => {
                               if (e.key == this.props.finishKey) {
                                   this.setState({ isOpen: false })
                                   if (this.state.selectedValue) {
                                       this.props.onChange(this.state.selectedValue, () => {
                                           if (this.props.focusNext)
                                               this.props.focusNext();
                                       })
                                   } else {
                                       this.props.onChange(this.props.value, () => {
                                           if (this.props.focusNext)
                                               this.props.focusNext();
                                       })

                                   }
                               }
                               if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {

                                   let value;
                                   if (this.props.dataModal && this.props.dataModal.length) {
                                       if (this.state.selectedValue) {
                                           let index = this.props.dataModal.findIndex(el => el.serialNumber == this.state.selectedValue)
                                           if (e.key == 'ArrowUp') {
                                               if (index > 0) {

                                                   value = this.props.dataModal[index - 1].serialNumber
                                               } else {
                                                   value = this.state.selectedValue;
                                               }
                                           } else if (e.key == 'ArrowDown') {

                                               if (index < this.props.dataModal.length - 1) {
                                                   value = this.props.dataModal[index + 1].serialNumber
                                               } else {
                                                   value = this.state.selectedValue;
                                               }
                                           }
                                       } else {
                                           value = this.props.dataModal[0].serialNumber;
                                       }

                                   }
                                   this.setState({
                                       selectedValue: value
                                   }, () => {

                                   })
                               } else {
                                   if (this.state.selectedValue) {
                                       this.setState({ selectedValue: null })
                                   }
                               }
                           }}
                           onChange={(e) => {
                               if (this.props.metoda && e.target.value && e.target.value.trim()) {
                                   this.props.metoda(e.target.value)
                               }

                               if (Number(e.target.value) != NaN)
                                   this.props.onChange(e.target.value);


                               this.setState({ /*isOpen: true,*/ search: e.target.value })
                           }}
                        //onFocus={() => this.setState({ isOpen: true })}
                           disabled={this.props.readOnly}
                           value={this.state.search}
                           placeholder={this.props.placeholder}
                    ></input>
                    {this.props.error && this.props.touched ?
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                        :

                        null
                    }


                    {

                        this.props.dataModal && this.props.dataModal.length && this.state.isOpen ?
                            <div className="articles-list">
                                <table ref={(node) => this.tableRef = node}>
                                    <thead>
                                    <tr>
                                        <th>SERIJSKI BROJ</th>
                                        <th>ROK</th>
                                        <th>KOLIČINA </th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.props.dataModal.map((item, idx) => {
                                            return (
                                                <tr className={this.state.selectedValue == item.serialNumber ? 'active' : ''} onClick={() => {
                                                    this.setState({
                                                        isOpen: false
                                                    })
                                                    this.props.onChange(item.serialNumber, () => {
                                                        if (this.props.focusNext)
                                                            this.props.focusNext();
                                                    })
                                                }}>
                                                    <td>
                                                        {
                                                            substr && item.serialNumber && item.serialNumber.length > substr ?
                                                                <div className={idx == 0 ? 'tooltip-div-bottom' : 'tooltip-div'}>
                                                                    {item.serialNumber.substr(0, substr) + '...'}
                                                                    <div className='tooltip-text'>
                                                                        {item.serialNumber}
                                                                    </div>
                                                                </div>
                                                                :
                                                                item.serialNumber
                                                        }
                                                        {/* {item.serialNumber} */}
                                                    </td>
                                                    <td>
                                                        {
                                                            substr && item.expirationDate && item.expirationDate.length > substr ?
                                                                <div className={idx == 0 ? 'tooltip-div-bottom' : 'tooltip-div'}>
                                                                    {item.expirationDate.substr(0, substr) + '...'}
                                                                    <div className='tooltip-text'>
                                                                        {item.expirationDate}
                                                                    </div>
                                                                </div>
                                                                :
                                                                item.expirationDate
                                                        }
                                                        {/* {item.expirationDate} */}
                                                    </td>
                                                    <td>
                                                        {/* {item.ammount} */}
                                                        {
                                                            substr && item.ammount && item.ammount.length > substr ?
                                                                <div className={idx == 0 ? 'tooltip-div-bottom' : 'tooltip-div'}>
                                                                    {item.ammount.substr(0, substr) + '...'}
                                                                    <div className='tooltip-text'>
                                                                        {item.ammount}
                                                                    </div>
                                                                </div>
                                                                :
                                                                item.ammount
                                                        }
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }

                                    </tbody>
                                </table>
                            </div>
                            :
                            null
                    }

                </div>


            </FormGroup>
        )
    }
}

export default Text;
