
import React from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col, Label, Input,
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';
import env from "react-dotenv";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            controled: true,
            exportTable: false,
        }
    }
    get = () => {
        // let params = this._getParams();
        // if (!params.zero && !params.no_zero) {
        //     this.setState({ filterZeros: true }, () => {
        //         this.updateParams('zero', this.state.filterZeros)
        //     })
        // }
    }

    componentDidMount() {
        this._onLoad();

        this.get()



    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }

    }

    print = () => {

        this.setState({ loading: true })
        this._apiAsync('print', this._getParams()).then((response) => {
            if (typeof window != 'undefined' && response && response.link) {
                window.open(env.API_URL + response.link)
                this.setState({ loading: false })
            }
        })
        return "open-modal"
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Cjenovnik</h1>
                    <div className="title-options-group">
                        <button disabled={this.state.items && this.state.items.length ? false : true} onClick={this.print}>
                            <div className={this.state.items && this.state.items.length ? "option blue" : "option disabled"}>
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button on onClick={() => {
                            this.setState({
                                exportTable: true
                            })
                        }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <Row>
                        <Col lg="12" className="panel-backgound">

                            <div className='report-filters-wrap'>
                                <div className='report-filters-left'>
                                    <div className='first-row'>
                                        <div className="field-strap-wrap check-fields-box-wrap">
                                            <Label>Cijene</Label>
                                            <div className="check-box-wrap">
                                                <Checkbox checked={this.state.filterSellPrice != null ? this.state.filterSellPrice ? true : false : params.sellPrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterSellPrice: e.target.checked ? true : null }, () => {
                                                            this.updateParams('sellPrice', this.state.filterSellPrice)
                                                        })
                                                    }} label={'Prodajne'} />

                                                <Checkbox checked={this.state.filetPurchasePrice != null ? this.state.filetPurchasePrice ? true : false : params.purchasePrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filetPurchasePrice: e.target.checked ? true : null }, () => {
                                                            this.updateParams('purchasePrice', this.state.filetPurchasePrice)
                                                        })
                                                    }} label={'Nabavne'} />
                                                <Checkbox checked={this.state.filterBarCod != null ? this.state.filterBarCod ? true : false : params.barcod ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterBarCod: e.target.checked ? true : null }, () => {
                                                            this.updateParams('barcod', this.state.filterBarCod)
                                                        })
                                                    }} label={'Bar kod'} />
                                            </div>
                                        </div>
                                        <div className="field-strap-wrap check-fields-box-wrap">
                                            <Label>Količine</Label>
                                            <div className="check-box-wrap">
                                                <Checkbox checked={this.state.filterZeros != null ? this.state.filterZeros ? true : false : params.zero ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterZeros: e.target.checked ? true : null }, () => {
                                                            this.updateParams('zero', this.state.filterZeros)
                                                            if (this.state.filterZeros) {
                                                                this.setState({ filterNoZeros: null }, () => {
                                                                    this.updateParams('no_zero', this.state.filterNoZeros)
                                                                })
                                                            }
                                                        })
                                                    }} label={'Sa nulama'} />

                                                <Checkbox checked={this.state.filterNoZeros != null ? this.state.filterNoZeros ? true : false : params.no_zero ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterNoZeros: e.target.checked ? true : null }, () => {
                                                            this.updateParams('no_zero', this.state.filterNoZeros)
                                                            if (this.state.filterNoZeros) {
                                                                this.setState({ filterZeros: null }, () => {
                                                                    this.updateParams('zero', this.state.filterZeros)
                                                                })
                                                            }
                                                        })
                                                    }} label={'Bez nula'} />

                                            </div>
                                        </div>
                                    </div>
                                    <div className='second-row'>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : params.articleFrom} onChange={(e) => this.setState({ filterArticleFrom: e.target.value }, () => {
                                                this.updateParams('articleFrom', this.state.filterArticleFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleTo != null ? this.state.filterArticleTo : params.articleTo} onChange={(e) => this.setState({ filterArticleTo: e.target.value }, () => {
                                                this.updateParams('articleTo', this.state.filterArticleTo)
                                            })} />
                                        </div>


                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa od</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupFrom != null ? this.state.filterGroupFrom : params.groupFrom} onChange={(e) => this.setState({ filterGroupFrom: e.target.value }, () => {
                                                this.updateParams('groupFrom', this.state.filterGroupFrom)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Grupa do</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterGroupTo != null ? this.state.filterGroupTo : params.groupTo} onChange={(e) => this.setState({ filterGroupTo: e.target.value }, () => {
                                                this.updateParams('groupTo', this.state.filterGroupTo)
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Proizvođač</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterManufacturer != null ? this.state.filterManufacturer : params.manufacturer} onChange={(e) => this.setState({ filterManufacturer: e.target.value }, () => {
                                                this.updateParams('manufacturer', this.state.filterManufacturer)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Podgrupa</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterSubgroup != null ? this.state.filterSubgroup : params.subgroup} onChange={(e) => this.setState({ filterSubgroup: e.target.value }, () => {
                                                this.updateParams('subgroup', this.state.filterSubgroup)
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className='report-filters-right'>
                                    <div>
                                        <button className={!this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ reservation: false }, () => this.updateParams('reservation', null)) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>CJENOVNIK</p>
                                        </button>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <button className={this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }}
                                            onClick={() => {
                                                this.setState({ reservation: true })
                                            }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>CJENOVNIK 2 KOLONE</p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                maxHeight={'48vh'}
                                total={this.state.total}
                                showNumeration={false}
                                exportTable={this.state.exportTable}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/get/stock/pricelist', title: 'Cjenovnik' }}
                                fields={[
                                    { type: 'text', name: 'ordinalNumber', label: 'R.BR.', multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'productCode', label: 'ŠIFRA', multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'barcodes', label: 'BAR KOD', multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA', multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'series', label: 'SERIJA', multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'deadlines', label: 'ROK', multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'currentQuantity', label: 'KOLIČINA', multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'buyPrice', label: 'NABAVNA', multilang: false, allowSort: true, width: 'unset', hideColumn: !this.state.reservation },
                                    { type: 'text', name: 'sellPrice', label: this.state.reservation ? 'PRODAJNA' : 'CIJENA', multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'sum', label: 'UKUPNO', multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,

                                        // ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                        // barcodes: item.barcode && item.barcode.length ? item.barcode.join(', ') : '',
                                        // series: item.series && item.series.length ? item.series.join(', ') : '',
                                        // deadlines: item.expirationDates && item.expirationDates.length ? item.expirationDates.join(', ') : '',
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={arrowDown} className="delete-icon" />,
                                            onClick: (item) => {
                                                if ((this.state.dropdownActive && this.state.dropdownActive._id !== item._id) || !this.state.dropdownActive) {
                                                    this.setState({ dropdownActive: item })
                                                } else {
                                                    this.setState({ dropdownActive: null })
                                                }

                                            },
                                            dropdownShowCondition: (item) => {
                                                if (this.state.dropdownActive && item._id === this.state.dropdownActive._id)
                                                    return true
                                                else
                                                    return false
                                            },
                                            condition: (item) => {
                                                if (item.packer || item.collector)
                                                    return true
                                                else
                                                    return false
                                            },
                                            dropdownShow: <div className="drop-down-wrap">
                                                <table>
                                                    <tr>
                                                        <th>SAKUPLJAČ</th>
                                                        <th>PAKER</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{this.state.dropdownActive && this.state.dropdownActive.collectorUsername}</td>
                                                        <td>{this.state.dropdownActive && this.state.dropdownActive.packerUsername}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg={{ size: 4, offset: 8 }}>
                            <div className='blue-box-info-text-wrap'>

                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno</h6>
                                    <h4>
                                        {
                                            this.state.stockSum ?
                                                numberWithCommas(this.state.stockSum)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>


                        </Col>

                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);
