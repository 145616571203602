import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import printJS from 'print-js'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'get-output': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        outputsItems: result.items
                    }
            })
        }
    },
    'checkNextItemNumber' : {
        onLoad: false,
        action: (data) => {
           
            return fetch(env.API_URL + '/data/buyer-return/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    article: query && query.article

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        articleItems: result,
                    }
            })
        }
    },
    'get-stock': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    article: query && query.article,
                    

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result
                    }
            })
        }
    },

    'get-fond': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return/fond', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    article: query && query.article,
                    client: query && query.client

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result
                    }
            })
        }
    },
    'insert-new': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/buyer-return/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                return {
                    result: result,
                }
            })
        }
    },
    'get-single-buyer-return': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/get-single-buyer-return', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                return {
                    result,
                }
            })
        }
    },

    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/buyer-return/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'get-book-notice-items': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return/book-notice', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query && query.filter,
                    factureNumber: query && query.factureNumber,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'koNumber',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-naturalni-rabat-items': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/buyer-return/naturalni-rabat', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query && query.filter,
                    facture: query && query.factureNumber,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-buyer-return': {
        onLoad: true,
        action: (lang, match, query) => {
            console.log(query);
            return fetch(env.API_URL + '/data/buyer-returns', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'notFinished',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        documentNumber: query && query.documentNumber,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        accountNumber: query && query.accountNumber,
                        article: query && query.article,
                        notFinished: query && query.notFinished
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        buyerReturnItems: result.items,
                        total: result.total,
                        
                    }
            })
        }
    },

    'printPovartKupca': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/buyer-returns/print-povrat-kupca', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                        });
                    }
                }).then(() => {
                    return true
                })

        }
    },

    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 50,
                    filter: data.value,
                    article: data.article,
                    withStock: true
                })
            }).then(parseJSON).then((response) => {
                return {
                    articlesSearch: response.result.items
                }
            })
        }
    },
}