import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { saveAs } from "file-saver";
import printJS from 'print-js'
export default {
    
    'get-financial-differences': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/financial-differences', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries ? query.entries : 10,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'itemNumber',
                   
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                        stockDiff: result.stockDiff,
                        tradeDiff: result.tradeDiff,
                        stockSum: result.stockSum,
                        tradeSum: result.tradeSum
                       
                    }
            })
        }
    },


    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/print/financial-differences', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                console.log(result, 'result api');
                    return {
                        link: result.link,
                       
                    }
            })
        }
    },

    
    'generic-export': {
        onLoad: false,
        action: (request) => {
            return fetch(env.API_URL + '/data/generic/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(request)
            }).then(parseJSON).then(({ result }) => {
                return result;
            })
        }
    },

  

}
