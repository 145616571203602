import React from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';
import ListBuilder from '../list-builder/index.js';

class SearchModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.childRef = React.createRef()
    }

    changeFocus = (e, index, item) => {
        //citanje svih redova iz tabele(ukljucujuci i red u kome se nalaze nazivi kolona)
        let rows = document.getElementsByClassName('table table-hover')[0].rows

        if (e.key == 'ArrowDown') {

            if ((index + 2) < rows.length) {
                rows[index + 2].focus()
                document.getElementById('textFieldSearch').value = rows[index + 2].childNodes[1].innerText
            } else {
                rows[1].focus()
                document.getElementById('textFieldSearch').value = rows[1].childNodes[1].innerText
            }
        }

        if (e.key == 'ArrowUp') {

            if (index == 0) {
                rows[rows.length - 1].focus()
                document.getElementById('textFieldSearch').value = rows[rows.length - 1].childNodes[1].innerText
            } else {
                rows[index].focus()
                document.getElementById('textFieldSearch').value = rows[index].childNodes[1].innerText
            }
        }

        if (e.key == 'Enter') {
            this.props.handler(item)
            this.props.toggle()
        }
    }

    componentDidMount() {
        // console.log(this.props);
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} style={{ width: 1200 }}>
                {
                    this.props.title ?
                        <ModalHeader>{this.props.title}</ModalHeader>
                        :
                        null
                }

                <ModalBody className="info-modal">
                    <div className='list-builder-search-modal'>
                        <Input
                            id='textFieldSearch'
                            onChange={(e) => {
                                this.props.metoda(e.target.value)
                            }}

                            autoFocus={true}
                            placeholder={this.props.label}
                            onKeyUp={(e) => {

                                if (e.key == 'ArrowDown') {
                                    let rows = document.getElementsByClassName('table table-hover')[0].rows
                                    rows[1].focus()
                                    document.getElementById('textFieldSearch').value = rows[1].childNodes[1].innerText

                                }
                            }}
                        ></Input>
                    </div>
                    <div className="message">
                        <ListBuilder
                            tabs={false}
                            sort={false}
                            hidePagination={true}
                            hideFilters={true}
                            lang={this.props.lang}
                            loading={this.state.loading}
                            maxHeight={'50vh'}
                            total={this.props.items && this.props.items.length ? this.props.items.length : 0}
                            showNumeration={false}
                            changeFocus={this.changeFocus}
                            forModal={this.props.items && this.props.items.length >= 1 ? '0' : false}
                            fields={[
                                { type: 'text', name: 'lijek-id', label: 'lijek-id'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'atc-sifra', label: 'atc-sifra'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'naziv-atc5-nivo', label: 'naziv-atc5-nivo'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'naziv', label: 'naziv'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'brend', label: 'brend'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'cijena', label: 'cijena'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'doza', label: 'doza'.translate(this.props.lang), multilang: false, allowSort: true },
                            ]}
                            items={this.props.items}
                            onClick={(item) => {
                                this.props.handler(item)
                                this.props.toggle()
                            }}
                            // actions={[]}

                            params={{ entries: 10, page: 0 }}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}
                        >

                        </ListBuilder>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <Button className='info-modal-button' color='primary' onClick={this.props.toggle}>Zatvori</Button>

                </ModalFooter>
            </Modal>
        )
    }
}

export default SearchModal;