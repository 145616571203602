import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import garbageIcon from '../../assets/garbage.svg';
import exportIcon from '../../assets/export.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number } from '../../form-builder/validation';

class InstitutionType extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            institutionTypeList: true,
            institutionTypeForm: false,
            total: 20,
            items: [],
            articleTypes: [],
            printTable : false,
            exportTable: false,
        }
    }

    changeState = (key) => {
        this.state[key] = false;
    }

    componentDidMount() {
        this._onLoad();
        (async () => {
            let broj = await this._apiAsync('checkNextDocumentNumber', {})
            if (broj && broj.firstFreeNumber) {
                this.remoteOnChange('code', broj.firstFreeNumber)
            }

        })()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }
    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Vrsta ustanove</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={async () => { this.setState({ printTable: true }) }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={ async ()=>{this.setState({exportTable: true})}}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => {
                                    this._onLoad();
                                    this.setState({ deleteModal: null })
                                });
                                (async () => {
                                    let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                    if (broj && broj.firstFreeNumber) {
                                        this.remoteOnChange('code', broj.firstFreeNumber)
                                    }
                                })()
                            });
                        }}>
                        Obrisati vrstu
                        ustanove <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                classNameForm={"add-form-container toogle-top-0 row"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['code']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'code' && value) {
                                        let res = await this._apiAsync('validate', { value: value });
                                        console.log('VRACENO REZULTAT......', res)
                                        if (res.status == 210) {
                                            this.setState({ form: null })
                                            this.remoteOnChange('name', '')
                                        }

                                        if (res.status == 200) {
                                            this.setState({ form: null }, () => {
                                                this.setState({ form: res.result })
                                            })
                                        }

                                    }

                                    if (name == 'code' && !value) {
                                        this.remoteOnChange('name', '')
                                    }

                                }}
                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {
                                        this._api('update', { data: data }, () => {
                                            this.setState({ institutionTypeForm: undefined }, async () => {
                                                this._onLoad();
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    } else {

                                        this._api('insert-new', { data: data }, () => {
                                            this.setState({ institutionTypeForm: {} }, async () => {
                                                this._onLoad()
                                                let broj = await this._apiAsync('checkNextDocumentNumber', {})
                                                if (broj && broj.firstFreeNumber) {
                                                    this.remoteOnChange('code', broj.firstFreeNumber)
                                                }
                                            })
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj vrstu ustanove'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        next: 'name',
                                                                        label: 'Šifra',
                                                                        // textType: 'number',
                                                                        integer: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra mora biti unešena!'),
                                                                            // async (value) => {
                                                                            //     try {
                                                                            //         if (value) {
                                                                            //             let res = await this._apiAsync('validate', {
                                                                            //                 value: value,
                                                                            //                 id: this.state.form ? this.state.form._id : null
                                                                            //             });
                                                                            //             if (res.status == 400) {
                                                                            //                 return 'Šifra je zauzeta'
                                                                            //             }
                                                                            //             return undefined;
                                                                            //         }

                                                                            //     } catch (e) {
                                                                            //         return undefined;
                                                                            //     }
                                                                            // },
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 9, sm: 9, xs: 9 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'maxProfit',
                                                                        label: 'Naziv',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv mora biti unešen!'),
                                                                            async (value) => {

                                                                                let institutionId = ''
                                                                                if (this.state.form && this.state.form._id) {
                                                                                    institutionId = this.state.form._id
                                                                                }

                                                                                let provjera = await this._apiAsync('checkName', { value: value.trim(), id: institutionId })

                                                                                if (provjera && provjera.result) return 'Vrsta ustanove pod tim nazivom već postoji!'
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            ...this.state.articleTypes.map((item) => {
                                                                return {

                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'allowedArticleType.' + item._id,
                                                                            label: <>{item.code}) {item.name}</>,
                                                                            key: item.code

                                                                        }
                                                                    ]



                                                                }
                                                            })

                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            />
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista vrsta'}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        {
                                            type: 'text',
                                            name: 'code',
                                            label: 'ŠIFRA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true
                                        },
                                        {
                                            type: 'text',
                                            name: 'name',
                                            label: 'NAZIV'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true
                                        },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }))
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    printTable={this.state.printTable}
                                    exportTable={this.state.exportTable}
                                    genericPrint={this.props.genericPrint}
                                    genericExport={this.props.genericExport}
                                    changeState={this.changeState}
                                    genericData={ {url: 'data/institution-type', title:'Registar vrsta ustanove'}}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(InstitutionType);
