import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import printJS from 'print-js'


export default {
    'kss-list': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/kss', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    kssValueExist: false,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        kss: query && query.kss,
                        documentNumber: query && query.documentNumber,
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'kss-entry': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/kss', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    kssValueExist: true,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        kss: query && query.kss,
                        documentNumber: query && query.documentNumber,
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        itemsEntry: result.items,
                        totalEntry: result.total,
                    }
            })
        }
    },
    'export-kss': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/kss', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    kssValueExist: query.kssValueExist,
                    action: query.action,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        kss: query && query.kss,
                        documentNumber: query && query.documentNumber,
                    },
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'print-kss': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/kss', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    kssValueExist: query.kssValueExist,
                    action: query.action,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        kss: query && query.kss,
                        documentNumber: query && query.documentNumber,
                    },
                })
            }).then((response) => {
                console.log(response)
                if (response.ok) { // checks if the response is with status 200 (successful)
                    return response.blob().then(blob => {
                        let pdfFile = new Blob([blob], {
                            type: "application/pdf"
                        });
                        let pdfUrl = URL.createObjectURL(pdfFile);
                        // window.open(pdfUrl);
                        printJS(pdfUrl);
                    });
                }
            })

        }
    },


    'update-kss': {
        onLoad: false,
        action: (data) => {
            console.log(data)
            return fetch(env.API_URL + '/data/kss/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/subgroups/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/subgroups/validate/' + data.value + '/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    }

}