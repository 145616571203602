import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-reports': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/controls/report', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        user: query && query.user,
                       
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },



}