import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/conditions', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     'api2': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/conditions/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/conditions/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/conditions/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },


    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/conditions/validate/' + data.value + '/' + data.id , {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'validate1': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(env.API_URL + '/data/conditions/validate/' + data.value  , {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'checkNextDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/conditions/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },

    'checkName': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/conditions/check/name', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                       result: result.info
                    }
            })
        }
    },

}