import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { saveAs } from "file-saver";

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/naturalni/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-business-units': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    query: query.query

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'check-client-exists-in-business-units': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units/check/' + query.clientId, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    institutionType: query && query.institutionType ? query.institutionType : null,
                    withStock: true,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'insert-new': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-item': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/remove/item/' + data._id + '/' + data.itemNumber, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/remove/document/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'get-output': {
        onLoad: true,
        action: (lang, match, query) => {
            console.log(query);
            return fetch(env.API_URL + '/data/naturalni-rabat', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        business: query && query.business,
                        type: query && query.type,
                        document: query && query.document,
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        outputItems: result.items,
                        total: result.total,
                        outputForm: null
                    }
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/validate/' + data.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'get-series-mapping': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series/mapping/search', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-series': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    productId: data.productId,
                    sortField: data.sortField,
                    sortType: data.sortType,
                    hasAmmount: true

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        oldestSeries: result.oldestSeries
                    }
            })
        }
    },
    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/naturalni-rabat/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'checkNextDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/naturalni-rabat/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'checkNextItemNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/naturalni-rabat/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'check-product-discount': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/products/check/discount', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'printOtrpemnica': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/print-otpremnica', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                           let  iframe =  document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function() {
                                setTimeout(function() {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                          
                        });
                    }
                })

        }
    },
    'printFaktura': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                           let  iframe =  document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function() {
                                setTimeout(function() {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                          
                        });
                    }
                })

        }
    },
    'generic-export': {
        onLoad: false,
        action: (request) => {
            return fetch(env.API_URL + '/data/generic/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(request)
            }).then(parseJSON).then(({ result }) => {
                return result;
            })
        }
    },
    'generic-print': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/generic/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let  iframe =  document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function() {
                                setTimeout(function() {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                        });
                    }
                }).then(() => {
                    return true
                })

        }
    },

    'checkDocumentNumberKO': {
        onLoad: false,
        action: (data) => {
            console.log('CKODE, CKODE, CKODE......', data)
            return fetch(env.API_URL + '/data/naturalni-rabat/checkKO/documentNumberKO', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    console.log('VRACENO OD CKODE. CKODE.....', result)
                return {
                    result: result,
                }
            })
        }
    },

    'ariclesSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/products/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) =>{
                return {
                    articlesSearch : response.result.items
                }
            })
        }
    },

    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            
            return fetch(env.API_URL + '/data/naturalni/clients', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },

    'businessSearch':{
        onLoad : false,
        action : (data)=>{
            // console.log('PODACI U APIJU....', data)
            return fetch(env.API_URL + '/data/business-units/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 5,
                    filter : data.value,
                    clientCode : data.clientCode
                })
            }).then(parseJSON).then((response) =>{
                return {
                    businessSearch : response.result.items
                }
            })
        }
    },


}