
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class DeadlinesPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 0,
            products: [],
            items: [],
            series: []
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Rokovi</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    if (data && data.numberOfDays) {
                                        this._apiAsync('get-series', { ...data, ...params }).then((res) => {
                                            this.setState({ ...res })
                                        })
                                    }


                                    return 'open-modal';
                                }}
                                fieldsToTrack={[]}
                                fieldTracker={async (name, value) => {

                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        // header: 'Izmjena serije'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        textType: 'number',
                                                                        name: 'numberOfDays',
                                                                        next: 'submitForm',
                                                                        label: 'Broj dana',
                                                                        asyncValidation: true,

                                                                        validate: [
                                                                            required('Broj dana mora biti unešen!'),

                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 7, sm: 0, xs: 0 },
                                                                children: [


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 12, xs: 12 },
                                                                className: 'flex-end',
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                        action: 'submit',
                                                                        children: <button className="blue-button" onClick={() => this.setState({ dependentCostsModal: true, dependentCosts: this.state.entranceForm && this.state.entranceForm.dependentCosts ? this.state.entranceForm.dependentCosts : [] })}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>PREGLED</p>
                                                                        </button>,
                                                                        className: 'options-button',
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 12, xs: 12 },
                                                                className: "custom-input-width-5",
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                className: 'input-group-custom',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'groupFrom',
                                                                                        // next: 'jci',
                                                                                        label: 'Grupa od',

                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                        validate: [
                                                                                            // required('Šifra komitenta mora biti unesen!'),

                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'groupFromName',
                                                                                        // next: 'item.itemNumber',
                                                                                        label: '',
                                                                                        readOnly: true,
                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                    },
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 12, xs: 12 },
                                                                className: "custom-input-width-5",
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                className: 'input-group-custom',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'groupTo',
                                                                                        // next: 'jci',
                                                                                        label: 'Grupa do',

                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                        validate: [
                                                                                            // required('Šifra komitenta mora biti unesen!'),

                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'groupToName',
                                                                                        // next: 'item.itemNumber',
                                                                                        label: '',
                                                                                        readOnly: true,
                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                    },
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 12, xs: 12 },
                                                                className: "custom-input-width-5",
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                className: 'input-group-custom',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'articleFrom',
                                                                                        // next: 'jci',
                                                                                        label: 'Artikal od',

                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                        validate: [
                                                                                            // required('Šifra komitenta mora biti unesen!'),

                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'articleFromName',
                                                                                        // next: 'item.itemNumber',
                                                                                        label: '',
                                                                                        readOnly: true,
                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                    },
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },


                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 12, xs: 12 },
                                                                className: "custom-input-width-5",
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                className: 'input-group-custom',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'articleTo',
                                                                                        // next: 'jci',
                                                                                        label: 'Artikal do',

                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                        validate: [
                                                                                            // required('Šifra komitenta mora biti unesen!'),

                                                                                        ]
                                                                                    },
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'articleToName',
                                                                                        // next: 'item.itemNumber',
                                                                                        label: '',
                                                                                        readOnly: true,
                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                    },
                                                                                ]
                                                                            },

                                                                        ]
                                                                    },


                                                                ]
                                                            },

                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'articleName', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'expirationDate', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'ammount', label: 'KOL.'.translate(this.props.lang), multilang: false, allowSort: false },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        date: this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm'),
                                        serialNumber: item.newSeries ? item.newSeries : item.serialNumber,
                                        expirationDate: item.newExpirationDate ? item.newExpirationDate : item.expirationDate,
                                        ammount: item.newAmmount ? item.newAmmount : item.ammount
                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ form: item })
                                        //     //onClick: (item) => console.log(item)
                                        // },
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                    </Row>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(DeadlinesPage);
