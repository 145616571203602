
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import Switch from "../../form-builder/fields/switch";

import env from "react-dotenv";

import Loader from '../../components/loader';


class SeriesPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            articlesSearch: [],
            allSeries: false
        }
    }

    componentDidMount() {
        this._onLoad();

        let params = this._getParams();
        if (params && params.allSeries && params.allSeries === 'true') {
            this.setState({ allSeries: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        if (value == this.registerGetValue('articleCode'))
            this.setState({ articlesSearch: articleList.articlesSearch })
    }

    export = () => {
        this.setState({ loadingPage: true }, () => {
            let params = this._getParams();
            this.setState({ printHtml: null }, () => {
                this._apiAsync('print-export-series', { ...params, action: 'export' }).then((res) => {
                    this.setState({ loadingPage: false })
                    if (res && res) {
                        if (res.success) {
                            window.location.href = env.API_URL + `${res.file}`;
                        }
                    }
                })
            })
        })

    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <h1>Serije</h1>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 20 }}>
                            <Switch
                                onChange={(value) =>
                                    this.setState({ allSeries: !this.state.allSeries }, () => {
                                        this.updateMultipleParams([{ name: 'allSeries', value: this.state.allSeries }, { name: 'page', value: '0' }])
                                    })
                                }
                                value={this.state.allSeries}
                                label={"Sve serije"}
                            ></Switch>
                        </div>
                        <div className="title-options-group">

                            <button onClick={() => this.submit()}>
                                <div className="option green">
                                    <Isvg src={saveIcon} />
                                </div>
                                <span>Snimi</span>
                            </button>
                            {/* <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button> */}
                            <button onClick={() => this.export()}>
                                <div className="option blue">
                                    <Isvg src={exportIcon} />
                                </div>
                                <span>Export</span>
                            </button>
                        </div>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    console.log(data)
                                    if (data.series) {
                                        let obj = {
                                            _id: data.series,
                                            newSeries: data.newSeries,
                                            newAmmount: data.newAmmount,
                                            newExpirationDate: data.newExpirationDate
                                        }
                                        this._apiAsync('update-series', obj).then((res) => {
                                            this._onLoad()
                                        })
                                    }

                                    // if (this.state.form && this.state.form._id) {

                                    //     this._api('api4', { data: data }, () => {
                                    //         this.setState({ form: undefined }, () => { this._onLoad(); })
                                    //     });
                                    // } else {

                                    //     this._api('api2', { data: data }, () => {
                                    //         this.setState({ clientForm: {} }, () => { this._onLoad() })
                                    //     });
                                    // }
                                }}
                                fieldsToTrack={['articleCode', 'articleName', 'series', 'newSeries', 'newExpirationDate', 'newAmmount']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'articleCode') {
                                        if (value) {
                                            this.setState({ loadingPage: true }, async () => {
                                                let products = await this._apiAsync('get-articles', { filter: value })
                                                if (value == this.registerGetValue('articleCode')) {
                                                    if (products && products.items) {
                                                        this.setState({ products: products.items }, () => {
                                                            if (this.state.products.filter(item => item.code == value).length === 1) {
                                                                if (this.registerGetValue('articleName') != this.state.products.filter(item => item.code == value)[0].name)
                                                                    this.remoteOnChange('articleName', this.state.products.filter(item => item.code == value)[0].name)
                                                                if (this.state.series != this.state.products.filter(item => item.code == value)[0].series)
                                                                    this.setState({ series: this.state.products.filter(item => item.code == value)[0].series, loadingPage: false })
                                                                else
                                                                    this.setState({ loadingPage: false })
                                                            } else {
                                                                this.setState({ series: [], loadingPage: false })
                                                                if (this.registerGetValue('articleName'))
                                                                    this.remoteOnChange('articleName', '')
                                                                if (this.registerGetValue('series'))
                                                                    this.remoteOnChange('series', '')
                                                            }
                                                        })
                                                    } else {
                                                        this.setState({ series: [], loadingPage: false })
                                                        if (this.registerGetValue('articleName'))
                                                            this.remoteOnChange('articleName', '')
                                                        if (this.registerGetValue('series'))
                                                            this.remoteOnChange('series', '')
                                                    }
                                                }
                                            })


                                        } else {
                                            this.setState({ products: [], loadingPage: false })
                                            if (this.registerGetValue('articleName'))
                                                this.remoteOnChange('articleName', '')
                                        }
                                    }
                                    if (name == 'series') {
                                        if (value) {
                                            if (this.state.series && this.state.series.filter(item => item._id == value).length) {
                                                let series = this.state.series && this.state.series.filter(item => item._id == value)[0];

                                                if (this.registerGetValue('expirationDate') != series.expirationDate)
                                                    this.remoteOnChange('expirationDate', series.expirationDate)
                                                if (this.registerGetValue('ammount') != series.ammount)
                                                    this.remoteOnChange('ammount', series.ammount)
                                                if (this.registerGetValue('newSeries') != series.newSeries)
                                                    this.remoteOnChange('newSeries', series.newSeries)
                                                if (this.registerGetValue('newExpirationDate') != series.newExpirationDate)
                                                    this.remoteOnChange('newExpirationDate', series.newExpirationDate)
                                                if (this.registerGetValue('newAmmount') != series.newAmmount)
                                                    this.remoteOnChange('newAmmount', series.newAmmount)
                                            } else {
                                                if (this.registerGetValue('expirationDate'))
                                                    this.remoteOnChange('expirationDate', '')
                                                if (this.registerGetValue('ammount'))
                                                    this.remoteOnChange('ammount', '')
                                                if (this.registerGetValue('newSeries'))
                                                    this.remoteOnChange('newSeries', '')
                                                if (this.registerGetValue('newExpirationDate'))
                                                    this.remoteOnChange('newExpirationDate', '')
                                                if (this.registerGetValue('newAmmount'))
                                                    this.remoteOnChange('newAmmount', '')
                                            }
                                        } else {
                                            if (this.registerGetValue('expirationDate'))
                                                this.remoteOnChange('expirationDate', '')
                                            if (this.registerGetValue('ammount'))
                                                this.remoteOnChange('ammount', '')
                                            if (this.registerGetValue('newSeries'))
                                                this.remoteOnChange('newSeries', '')
                                            if (this.registerGetValue('newExpirationDate'))
                                                this.remoteOnChange('newExpirationDate', '')
                                            if (this.registerGetValue('newAmmount'))
                                                this.remoteOnChange('newAmmount', '')
                                        }
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Izmjena serije'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {

                                                                        type: 'article',
                                                                        name: 'articleCode',
                                                                        next: 'series',
                                                                        label: 'Šifra artika',
                                                                        finishField: true,
                                                                        readOnly: this.state.disableEdit,
                                                                        asyncValidation: true,
                                                                        loading: this.state._articlesLoading,
                                                                        metoda: (value) => { this.metoda(value) },
                                                                        dataModal: this.state.articlesSearch,
                                                                        title: 'Pregled artikala',
                                                                        values: this.state.articlesSearch ? [...this.state.articlesSearch.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item.code,
                                                                                disabled: item.disabled ? true : false,
                                                                            }
                                                                            return obj
                                                                        })] : [],
                                                                        validate: [
                                                                            (value) => {
                                                                                if (!value || value == '') return "Artikal mora biti unesen!"
                                                                                if (this.state.notExistSeries) {
                                                                                    return "Artikal nema seriju sa količinom"
                                                                                }
                                                                            },
                                                                            (value) => {
                                                                                if (this.state.articlesSearch.length == 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                                    return "Neispravan unos!"
                                                                                }
                                                                                if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                                    return "Neispravan unos!!"
                                                                                }
                                                                                this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                                            },
                                                                        ]

                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'articleName',
                                                                        nxet: 'series',
                                                                        label: 'Naziv artikla',
                                                                        readOnly: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv artikla mora biti unešena!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'series',
                                                                        label: 'Serija',
                                                                        next: 'newSeries',
                                                                        values: [{ name: '', value: -1 }, ...this.state.series.map(item => {
                                                                            let obj = {
                                                                                name: item.serialNumber,
                                                                                value: item._id
                                                                            }
                                                                            return obj
                                                                        })],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Serija mora biti izbrana!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'expirationDate',
                                                                        next: 'ammount',
                                                                        label: 'Rok',
                                                                        readOnly: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required('Šifra mora biti unešena!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'ammount',
                                                                        // textType: 'number',
                                                                        next: 'newSeries',
                                                                        label: 'Količina',
                                                                        integer: true,
                                                                        readOnly: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required('Šifra mora biti unešena!')

                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 5, sm: 5, xs: 5 },
                                                                children: [

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'newSeries',
                                                                        label: 'Nova serija',
                                                                        next: 'newExpirationDate',
                                                                        values: [{ name: '', value: -1 }],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Nova serija mora biti unešena!'),
                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        let res = await this._apiAsync('validate-serial-number', { value: value });
                                                                                        if (res.status == 400) {
                                                                                            return 'Serijski broj je zauzet'
                                                                                        }
                                                                                        return undefined;
                                                                                    }

                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'newExpirationDate',
                                                                        next: 'newAmmount',
                                                                        label: 'Rok',
                                                                        asyncValidation: true,
                                                                        autoCompleteYearOff: true,
                                                                        validate: [
                                                                            required('Rok mora biti unešena!'),
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'newAmmount',
                                                                        // textType: 'number',
                                                                        next: 'submitForm',
                                                                        label: 'Količina',
                                                                        integer: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Količina mora biti unešena!'),
                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        let ammount = this.registerGetValue('ammount')
                                                                                        if (ammount && ammount < value) {
                                                                                            return 'Stara količina mora biti veća od nove'
                                                                                        }
                                                                                        return undefined;
                                                                                    }

                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                maxHeight={'60vh'}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    // { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'articleName', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    // { type: 'text', name: 'newSeries', label: 'NOVA SERIJA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'expirationDate', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    // { type: 'text', name: 'newExpirationDate', label: 'NOVI ROK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'ammount', label: 'KOL.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    // { type: 'text', name: 'newAmmount', label: 'NOVA KOL.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    // { type: 'text', name: 'modifiedByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        // date: this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm'),
                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ form: item })
                                        //     //onClick: (item) => console.log(item)
                                        // },
                                        {
                                            component: <Isvg src={garbageIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                    </Row>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(SeriesPage);
