import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { saveAs } from "file-saver";
import printJS from 'print-js'
export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    institutionType: query && query.institutionType ? query.institutionType : null,
                    withStock: true,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'insert-new': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-item': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/remove/item/' + data._id + '/' + data.itemNumber, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/remove/document/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'get-financial-accounts-items': {
        onLoad: false,
        action: (query) => {
            console.log(query, "query");
            let status = null;
            if (query && query.status) {
                if (query.status.split(",").length > 1) {
                    status = query.status.split(",").map(Number)
                } else {
                    status = query.status
                }
            }
            return fetch(env.API_URL + '/data/financial-accounts', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries ? query.entries : 10,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'itemNumber',
                    financialAccount: query && query.financialAccount ? query.financialAccount : '',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        business: query && query.business,
                        type: query && query.type,
                        document: query && query.document,
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        financialItems: result.items,
                        total: result.total,
                        financialForm: null,
                        totalAsking: result.totalAsking,
                        totalOwes: result.totalOwes
                    }
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/validate/' + data.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/financial-accounts/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result.result,
                        total: result.total
                    }
            })
        }
    },
    'checkNextDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/financial-accounts/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'checkNextItemNumber': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'get-financial-item-type': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/financial-item-type', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-financial-accounts-numbers': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/account-numbers', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'itemNumberItems': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/financial-accounts/item/number/items', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.response,
                    }
            })
        }
    },


    'printFaktura': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/financial-accounts/print-faktura', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                        });
                    }
                })

        }
    },
    'generic-export': {
        onLoad: false,
        action: (request) => {
            return fetch(env.API_URL + '/data/generic/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(request)
            }).then(parseJSON).then(({ result }) => {
                return result;
            })
        }
    },

    'clientsSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value
                })
            }).then(parseJSON).then((response) =>{
                return {
                    clientsSearch : response.result.items
                }
            })
        }
    },


}
