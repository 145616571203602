import React from 'react';
import Page from '../../containers/page';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col, Label, Input,
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import Checkbox from '../../form-builder/fields/checkbox';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DateField from '../../form-builder/fields/date';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            reservation: false,
            totalPriceIn: 0,
            totalPriceOut: 0,
            totalAmmountIn: 0,
            totalAmmountOut: 0,
            redirect: true,
            exportTable: false,
            filterSellPrice: true
        }
    }

    componentDidMount() {
    }
    
    changeState = (key) => {
        this.state[key] = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }

        if (this.props[0].location && this.props[0].location.search && this.state.redirect) {
            let params = this._getParams();
            this.updateMultipleParams([
                { name: 'dateFrom', value: params.dateFrom },
                { name: 'dateTo', value: params.dateTo },
                { name: 'articleFrom', value: params.articleFrom },
                { name: 'timestamp', value: new Date().getTime() },
                { name: 'page', value: 0 },
            ])

            this.setState({
                redirect: false,
                filterDateFrom: params.dateFrom,
                filterDateTo: params.dateTo,
                filterArticleFrom: params.articleFrom
            })
        }
    }

    totalArticles = async () => {

        this.updateMultipleParams([
            { name: 'sellPrice', value: this.state.filterSellPrice },
            { name: 'purchasePrice', value: this.state.filetPurchasePrice },
            { name: 'dateFrom', value: this.state.filterDateFrom },
            { name: 'dateTo', value: this.state.filterDateTo },
            { name: 'articleFrom', value: this.state.filterArticleFrom },
            { name: 'serialNumber', value: this.state.filterSerialNumber },
            { name: 'changeType', value: this.state.filterTypeOfChange },
            { name: 'timestamp', value: new Date().getTime() },
            { name: 'page', value: 0 },
        ])

        this.setState({
            reservation: false
        })
    }

    clearFilters = () => {
        this.setState({
            filterSellPrice: '',
            filetPurchasePrice: '',
            filterDateFrom: '',
            filterDateTo: '',
            filterArticleFrom: '',
            filterSerialNumber: '',
            filterTypeOfChange: '',

        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'sellPrice', value: null },
                    { name: 'purchasePrice', value: null },
                    { name: 'dateFrom', value: null },
                    { name: 'dateTo', value: null },
                    { name: 'articleFrom', value: null },
                    { name: 'serialNumber', value: null },
                    { name: 'changeType', value: null },

                ])
            setTimeout(() => {
                this.totalArticles()
            }, 300);
        })
    }

    print = () => {

        this.setState({ loading: true })
        this._apiAsync('print', this._getParams()).then((response) => {
            if (typeof window != 'undefined' && response && response.link) {
                window.open(env.API_URL + response.link)
                this.setState({ loading: false })
            }
        })
        return "open-modal"
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Kartica artikal</h1>
                    <div className="title-options-group">

                        <button disabled={this.state.items && this.state.items.length ? false : true} onClick={this.print}>
                            <div className={this.state.items && this.state.items.length ? "option blue" : "option disabled"}>
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => {
                            this.setState({
                                exportTable: true
                            })
                        }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters field-wrap-width-unset" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap">
                                            <Label>Cijene</Label>
                                            <div style={{ height: 42 }} className="check-box-wrap">
                                                <Checkbox checked={this.state.filterSellPrice != null && this.state.filterSellPrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filterSellPrice: e.target.checked ? true : null, filetPurchasePrice: false }, () => {
                                                        })
                                                    }} label={'Prodajne'} />

                                                <Checkbox checked={this.state.filetPurchasePrice != null && this.state.filetPurchasePrice ? true : false}
                                                    onChange={(e) => {

                                                        this.setState({ filetPurchasePrice: e.target.checked ? true : null, filterSellPrice: false }, () => {
                                                        })
                                                    }} label={'Nabavne'} />

                                            </div>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                })}
                                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom : null}
                                                label={'Datum od'}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>

                                            <DateField
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                })}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : null}
                                                label={'Datum do'}
                                            ></DateField>
                                        </div>
                                    </div>
                                    <div>
                                        <button className={!this.state.reservation ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => this.totalArticles()}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>Promet po artiklu</p>
                                        </button>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <Label>Artikal</Label>
                                            <Input style={{ width: 150 }} type='text' value={this.state.filterArticleFrom != null ? this.state.filterArticleFrom : null} onChange={(e) => this.setState({ filterArticleFrom: e.target.value, articleError: null }, () => {
                                            })} />

                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Serijski broj</Label>
                                            <Input style={{ width: 200 }} type='text' value={this.state.filterSerialNumber != null ? this.state.filterSerialNumber : params.serialNumber} onChange={(e) => this.setState({ filterSerialNumber: e.target.value }, () => {
                                            })} />
                                        </div>

                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Label>Vrsta promjene</Label>
                                            <Input type='select' style={{ width: 150 }} value={this.state.filterTypeOfChange != null ? this.state.filterTypeOfChange : params.change} onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                            })}>
                                                <option value={-1}></option>
                                                <option value={'ul'}>Ulaz</option>
                                                <option value={'iz'}>Izlaz</option>
                                                <option value={'pd'}>Povrat dobavljaču</option>
                                                <option value={'pk'}>Povrat kupcu</option>
                                                <option value={'ni'}>Nivelacija</option>
                                            </Input>
                                        </div>


                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                total={this.state.total}
                                loading={this.state.loading}
                                showNumeration={false}

                                exportTable={this.state.exportTable}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/reports/return/total/articles', title: 'Kartica artikla' }}
                                fields={[
                                    { type: 'text', name: 'itemNumber', label: 'REDNI BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'documentNumber', label: 'DOKUMENT'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'type', label: 'VRSTA PROMJENE'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'quantityIn', label: 'KOLICINA ULAZ'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'quantityOut', label: 'KOLICINA IZLAZ'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'quantityAverage', label: 'SALDO'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'nivelationAmmount', label: 'KOLICINA NIVELACIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'valueIn', label: 'VRIJEDNOST ULAZ'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true },
                                    { type: 'text', name: 'valueOut', label: 'VRIJEDNOST IZLAZ'.translate(this.props.lang), multilang: false, allowSort: false, numberWithCommas: true },
                                ]}
                                items={this.state.items.map(item => {
                                    return {
                                        ...item,
                                        documentNumber: <div onClick={() => {
                                            if (typeof window != 'undefined') {
                                                window.open(`/${item ? item.deliveryDate ? 'output' : item.nivelationDate ? 'nivelation' : item.returnDate ? 'supplier-return' : item.entraceDate ? 'entrance' : item.factureDate ? 'buyer-return' : '' : ''}?redirect=${item && item.documentNumber}`, "_blank");
                                            }
                                        }}>{item && item.documentNumber}</div>
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg='6'>
                            <div className='blue-box-info-text-wrap'>
                                <div className='blue-box-info-text'>
                                    <h6>Ukupno ulaz</h6>
                                    <h4>
                                        {this.state.totalAmmountIn ? numberWithCommas(this.state.totalAmmountIn) : '0'}
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno izlaz</h6>
                                    <h4>
                                        {this.state.totalAmmountOut ? numberWithCommas(this.state.totalAmmountOut) : '0'}
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Razlika</h6>
                                    <h4>
                                        {numberWithCommas(Math.abs(Math.round(((this.state.totalAmmountIn ? this.state.totalAmmountIn : 0) - (this.state.totalAmmountOut ? this.state.totalAmmountOut : 0)) * 100) / 100))}
                                    </h4>
                                </div>
                            </div>



                        </Col>
                        <Col lg='6'>
                            <div className='blue-box-info-text-wrap'>
                                <div className='blue-box-info-text'>
                                    <h6>Ukupno ulaz</h6>
                                    <h4>
                                        {this.state.totalPriceIn ? numberWithCommas(Math.round(this.state.totalPriceIn * 100) / 100) : '0'}
                                        &nbsp;KM
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Ukupno izlaz</h6>
                                    <h4>
                                        {this.state.totalPriceOut ? numberWithCommas(Math.round(this.state.totalPriceOut * 100) / 100) : '0'}
                                        &nbsp;KM
                                    </h4>
                                </div>
                                <div className='blue-box-info-text' style={{ marginLeft: 10 }}>
                                    <h6>Razlika</h6>
                                    <h4>
                                        {numberWithCommas(Math.abs(Math.round(((this.state.totalPriceIn ? this.state.totalPriceIn : 0) - (this.state.totalPriceOut ? this.state.totalPriceOut : 0)) * 100) / 100))}
                                        &nbsp;KM
                                    </h4>
                                </div>
                            </div>



                        </Col>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default Page(StockPage);