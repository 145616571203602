
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import lockIcon from '../../assets/lock.svg';
import Checkbox from '../../form-builder/fields/checkbox';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import moment from 'moment';
import {FAKTURA, OTPREMNICA, PAKOVANJE, PROKNJIZENA_OTPREMNICA} from "../../helpers/constants";
const TAB_NEPROKNJIZENO = 1;
const TAB_PROKNJIZENO = 2;
class BookNoticePage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            nootBooked: true,
            specification: false,
            listCustomFilter: true,
            total: 20,
            buttonActive: 1,
            items: [
            ],
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            clients: [],
            checkedItem: false,
            checkedItem1: false,
            clientFacture: [],
            itemsProknjizeno: [],
            itemsNeproknjizeno: [],
            checkBox: [],
            clientsSearch : [],
            printTable: false,
            exportTable: false,
            exportTableP: false,
            printTableP: false,
            filterProknjizeno: 1

        }
    }
    changeState = (key) => {
        const newKey = this.state.filterProknjizeno == TAB_PROKNJIZENO ? `${key}P` : key
        console.log(newKey, 'New key')
        this.state[newKey] = false;
    }
    setParamsStatus = async () => {
        await this.updateMultipleParams([{ name: 'proknjizeno', value: 1 }], true);
    }

    componentDidMount() {
        this.setParamsStatus();
        this._onLoad();

        let params = this._getParams();
        if (params.proknjizeno && params.proknjizeno != this.state.filterProknjizeno) {
            this.setState({ filterProknjizeno: params.proknjizeno })
        }
        if (params.koNumber && params.koNumber != this.state.filterKoNumber) {
            this.setState({ filterKoNumber: params.koNumber })
        }
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }

        if (params.class && params.class != this.state.filterClass) {
            this.setState({ filterClass: params.class })
        }
        if (params.subClass && params.subClass != this.state.filterSubClass) {
            this.setState({ filterSubClass: params.subClass })
        }
        if (params.basic && params.basic != this.state.filterBasic) {
            this.setState({ filterBasic: params.basic })
        }

    }
    changeTabNavigation = async (value) => {
        this.setState({filterProknjizeno: value})
        await this.updateMultipleParams([{ name: 'proknjizeno', value }], true);
        this.setState({ buttonActive: value })

    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.buttonActive != this.state.buttonActive) {
            this.changeTabNavigation(this.state.buttonActive)
        }

        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.firstFreeNumber && this.state.firstFreeNumber) {
            let form = this.state.form;
            if (!form) {
                form = {
                    date: this.state.dateNow
                }
            }

            this.setState({ form: null }, () => {
                this.setState({ form })
            })
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    render() {
        // this.state.checkBox = ({ checkBox: new Array(this.state.items.length).fill(false) })
        // console.log('za check boxove.....za check boxove.....', this.state.checkBox)
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Knjižna obavijest</h1>
                    <div className="title-options-extrabutton">
                        {
                            this.state.booked || this.state.addKo || this.state.specification ?
                                null
                                :
                                this.state.checkBox.length != 0 && this.state.checkBox.filter(item=> item==true).length !=0 ?
                                <button className="posting-button" onClick={() => {
                                    this.state.items.map((element) => {
                                        if (element.checked && element.proknjizeno == false) {
                                            element.proknjizeno = true
                                            delete element.checked
                                            //update u bazi
                                            this._apiAsync('updateKo', element).then((result) => {
                                                this.setState({ items: result.result })
                                            })
                                        }
                                    })

                                    this.setState({ nootBooked: false })
                                    this.setState({ booked: true })
                                    this.setState({ buttonActive: 2 },()=>{
                                        this.setState({checkBox : []})

                                    })

                                }}>
                                    <span>Proknjiži</span>
                                </button>
                                :
                                null
                        }
                        {
                            this.state.addKo ?
                                null
                                :
                                <div className="title-options-group">
                                    <button onClick={ async ()=>{ this.state.filterProknjizeno == TAB_PROKNJIZENO ? this.setState({printTableP: true}) : this.setState({printTable: true})}}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={ async ()=>{ this.state.filterProknjizeno == TAB_PROKNJIZENO ? this.setState({exportTableP: true}) : this.setState({exportTable: true})}}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                </div>
                        }
                    </div>
                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ nootBooked: true, addKo: false, booked: false, specification: false, buttonActive: 1 })}>
                            Neproknjiženo
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ booked: true, addKo: false, nootBooked: false, specification: false, buttonActive: 2 })}>
                            Proknjiženo
                        </button>
                        <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={() => this.setState({ addKo: true, booked: false, nootBooked: false, specification: false, buttonActive: 3 }, () => {
                            this._apiAsync('checkNextDocumentNumber').then((res) => {
                                this.remoteOnChange('koNumber', res.firstFreeNumber)
                            })
                        })}>
                            Dodaj KO
                        </button>
                        <button className={this.state.buttonActive == 4 ? 'active' : ''} onClick={() => this.setState({ nootBooked: false, addKo: false, booked: false, specification: true, buttonActive: 4 })}>
                            Specifikacija
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 || this.state.buttonActive == 2 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    this.state.nootBooked ?
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKoNumber} onChange={(e) => this.setState({ filterKoNumber: e.target.value }, () => {
                                                    this.updateParams('koNumber', this.state.filterKoNumber)
                                                })} placeholder={'KO broj'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClass} onChange={(e) => this.setState({ filterClass: e.target.value }, () => {
                                                    this.updateParams('class', this.state.filterClass)
                                                })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterSubClass} onChange={(e) => this.setState({ filterSubClass: e.target.value }, () => {
                                                    this.updateParams('subClass', this.state.filterSubClass)
                                                })} placeholder={'Podvsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterBaisc} onChange={(e) => this.setState({ filterBaisc: e.target.value }, () => {
                                                    this.updateParams('basic', this.state.filterBaisc)
                                                })} placeholder={'Osnov'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterKoNumber: '',
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterSubClass: '',
                                                filterClass: '',
                                                filterBaisc: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'koNumber', value: null }, { name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'class', value: null }, { name: 'subClass', value: null }, { name: 'basic', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'koNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'factureNumber', label: 'BR. FAKTURE'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'koNum', label: 'Specifikacija'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'component', label: ''.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'kom', label: 'Nesto'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}


                                actions={
                                    [
                                        {
                                        }
                                    ]
                                }
                                items={this.state.items.filter((element) => {
                                    return element.proknjizeno == false
                                }).map((item, idx) => {
                                    return {
                                        ...item,
                                        ammountKo: <div style={{ cursor: 'pointer' }} onClick={() => {
                                            this.props[0].history.push({
                                                pathname: '/output',
                                                state: { documentNumber: item.factureNumber }
                                            })
                                        }}>{item.factureNumber}</div>,
                                        koNum: <div style={{ cursor: 'pointer' }} onClick={() => {
                                            this.setState({ nootBooked: false })
                                            this.setState({ specification: true })
                                            this.setState({ buttonActive: 4 })
                                            this.setState({
                                                zaSpec: this.state.items.filter((element) => {
                                                    return element.koNumber == item.koNumber
                                                })
                                            })
                                        }}>Specif.</div>,
                                        kom: <Input type="checkbox" checked={this.state.checkBox[idx]} onChange={() => {

                                            let kliknuto = this.state.items.filter((element) => {
                                                return element.koNumber == item.koNumber
                                            })

                                            this.state.checkBox[idx] = !this.state.checkBox[idx]

                                            kliknuto[0].checked = this.state.checkBox[idx]
                                            this.state.items[idx] = kliknuto[0]

                                            if(this.state.items.filter((item)=>{if(item.checked && item.checked == true){return item}}).length !=0){
                                                this.setState({checkBox : this.state.checkBox})
                                             
                                            }else{
                                                this.setState({checkBox : this.state.checkBox})
                                            }

                                        }} />,

                                    }
                                })}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={ {url: 'data/bookNotice', title:`Knjižna obavijest - neproknjiženo`}}
                            ></ListBuilder>
                        </>

                        :

                        null

                }

                {
                    this.state.booked ?
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKoNumber} onChange={(e) => this.setState({ filterKoNumber: e.target.value }, () => {
                                                    this.updateParams('koNumber', this.state.filterKoNumber)
                                                })} placeholder={'KO broj'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClass} onChange={(e) => this.setState({ filterClass: e.target.value }, () => {
                                                    this.updateParams('class', this.state.filterClass)
                                                })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterSubClass} onChange={(e) => this.setState({ filterSubClass: e.target.value }, () => {
                                                    this.updateParams('subClass', this.state.filterSubClass)
                                                })} placeholder={'Podvsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterBaisc} onChange={(e) => this.setState({ filterBaisc: e.target.value }, () => {
                                                    this.updateParams('basic', this.state.filterBaisc)
                                                })} placeholder={'Osnov'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterKoNumber: '',
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterSubClass: '',
                                                filterClass: '',
                                                filterBaisc: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'koNumber', value: null }, { name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'class', value: null }, { name: 'subClass', value: null }, { name: 'basic', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'koNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'name', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'totalAmmountKo', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'koNum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}


                                actions={
                                    [
                                    ]
                                }


                                items={this.state.items.filter((element) => {
                                    return element.proknjizeno == true
                                }).map((item, idx) => {
                                    return {
                                        ...item,
                                        koNum: <div style={{ cursor: 'pointer' }} onClick={() => {
                                            this.setState({ booked: false })
                                            this.setState({ nootBooked: false })
                                            this.setState({ specification: true })
                                            this.setState({ buttonActive: 4 })
                                            this.setState({
                                                zaSpec: this.state.items.filter((element) => {
                                                    return element.koNumber == item.koNumber
                                                })
                                            })
                                        }}>Specif.</div>,
                                    }
                                })}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTableP}
                                exportTable={this.state.exportTableP}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={ {url: 'data/bookNotice', title:`Knjižna obavijest - proknjiženo`}}
                            ></ListBuilder>
                        </>

                        :

                        null

                }

                {
                    this.state.addKo ?

                        <div>
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['koNumber', 'clientCode', 'factureNumber', 'item.discount', 'item.discountAmount', 'item.ammountKo']}
                                fieldTracker={(name, value) => {


                                    if (name == 'koNumber' && value) {


                                    }

                                    if (name == 'clientCode' && value) {

                                        if (value) {
                                            this._apiAsync('get-clients', { filter: value }).then((clients) => {
                                                if (clients && clients.items) {
                                                    this.setState({ clients: clients && clients.items }, () => {
                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                            this._apiAsync('get-factureClient', { filter: this.registerGetValue('clientCode') }).then((result) => {
                                                                if (result.items.length != 0) {
                                                                    this.setState({ clientFacture: result.items })
                                                                } else {
                                                                    this.setState({ clientFacture: [] })
                                                                    this.remoteOnChange('factureNumber', 0)
                                                                    this.remoteOnChange('item.beforeAmount', 0)
                                                                    this.remoteOnChange('item.discount', 0)
                                                                    this.remoteOnChange('item.discountAmount', 0)
                                                                    this.remoteOnChange('item.newAmount', 0)
                                                                    this.remoteOnChange('item.ammountKo', 0)

                                                                }
                                                            })
                                                        } else {
                                                            this.remoteOnChange('clientName', '')
                                                        }
                                                    })

                                                }
                                            })

                                        }
                                    }



                                    if (name == 'factureNumber' && !value) {
                                        this.remoteOnChange('item.beforeAmount', 0)
                                        this.remoteOnChange('item.discount', 0)
                                        this.remoteOnChange('item.discountAmount', 0)
                                        this.remoteOnChange('item.newAmount', 0)
                                        this.remoteOnChange('item.ammountKo', 0)


                                    }

                                    if (name == 'factureNumber' && value) {
                                        if (this.state.clientFacture.length == 0) {
                                            alert('Komitent nema izlaznih faktura....')
                                            this.remoteOnChange('factureNumber', 0)
                                        }
                                        if (value) {
                                            let total = 0

                                            this._apiAsync('get-facture', { filter: value }).then((result) => {

                                                if (!result.items == false) {
                                                    total = result.items.reduce((sum, current) => {
                                                        sum = sum + current.total
                                                        return sum
                                                    }, 0)
                                                    this.remoteOnChange('item.beforeAmount', Number(total))
                                                }
                                                this.remoteOnChange('item.beforeAmount', Number(total))

                                            })
                                        }
                                    }

                                    if (name == 'item.discount' && value) {

                                        if (value && this.registerFocusedField('item.discount') && this.registerGetValue('factureNumber')) {

                                            let iznosSaRabatom = this.props.roundToTwoDecimals(this.registerGetValue('item.beforeAmount') - (this.registerGetValue('item.beforeAmount') * value) / 100)
                                            let iznosRabata = this.props.roundToTwoDecimals((this.registerGetValue('item.beforeAmount') * value) / 100)
                                            this.remoteOnChange('item.newAmount', Number(iznosSaRabatom.toFixed(2)))
                                            this.remoteOnChange('item.discountAmount', Number(iznosRabata.toFixed(2)))
                                            this.remoteOnChange('item.ammountKo', Number(iznosRabata.toFixed(2)))
                                        }

                                    }

                                    if (name == 'item.discountAmount' && value) {

                                        if (value && this.registerFocusedField('item.discountAmount') && this.registerGetValue('factureNumber')) {
                                            let iznosSaRabatom = this.registerGetValue('item.beforeAmount') - value
                                            let procenatRabata = this.props.roundToTwoDecimals((value * 100) / this.registerGetValue('item.beforeAmount'))
                                            let ko = value

                                            this.remoteOnChange('item.newAmount', Number(iznosSaRabatom.toFixed(2)))
                                            this.remoteOnChange('item.discount', Number(procenatRabata.toFixed(2)))
                                            this.remoteOnChange('item.ammountKo', Number(ko))
                                        }

                                    }

                                    if (name == 'item.ammountKo' && value) {
                                        if (value && this.registerFocusedField('item.ammountKo') && !this.registerGetValue('factureNumber')) {
                                            this.remoteOnChange('item.discount', 0)
                                            this.remoteOnChange('item.discountAmount', Number(value))

                                        }

                                    }



                                }}
                                onSubmit={(data) => {
                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._apiAsync('insert-new', data).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ form: null }, () => {
                                                    this.remoteOnChange('date', this.state.dateNow)
                                                    this.remoteOnChange('clientCode', '')
                                                    this._apiAsync('checkNextDocumentNumber').then((res1) => {
                                                        this.remoteOnChange('koNumber', res1.firstFreeNumber)
                                                    })
                                                })
                                                // if (res.result.items && res.result.items.length) {
                                                //     this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                //         if (res && res.firstFreeItemNumber) {
                                                //             this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                //         }

                                                //     })
                                                // } else {
                                                //     this.remoteOnChange('item.itemNumber', 1)
                                                // }

                                            }
                                        })

                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        header: 'Artikli',
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up',
                                                width: { lg: 7, sm: 7, xs: 7 },
                                                children: [

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                className: 'col-up col-up-right-top col-up-right-top-max-2 flex-end',
                                                width: { lg: 5, sm: 5, xs: 5 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 1, xs: 1 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        name: 'fond',
                                                                        next: 'stock',
                                                                        className: 'lock-icon-container',
                                                                        children: <div className="lock-icon">
                                                                            <Isvg src={lockIcon} onClick={() => {
                                                                                this.setState({
                                                                                    lockModal: true
                                                                                })
                                                                            }} />
                                                                        </div>,
                                                                        label: 'Kompenzacija',
                                                                        asyncValidation: true,
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 11, sm: 11, xs: 11 },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        className: 'option-header',
                                                                        children: [
                                                                            {
                                                                                type: 'block',
                                                                                children: [
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                        className: 'options-button',
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                        className: 'options-button'
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                        className: 'options-button'
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'row',
                                                children: [

                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        className: "custom-input-row book-notice-row-1",
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'koNumber',
                                                                next: 'date',
                                                                label: 'KO broj',
                                                                // textType: 'number',
                                                                integer: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'date',
                                                                next: 'clientCode',
                                                                label: 'Datum',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }
                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-2-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        className: 'input-group-custom input-group-custom-2',
                                                                        children: [
                                                                            {
                                                                                type: 'client',
                                                                                name: 'clientCode',
                                                                                next: 'factureNumber',
                                                                                label: 'Šifra komitenta',
                                                                                textType: 'number',
                                                                                finishField: true,
                                                                                metoda: (value) => { this.metodaClient(value) },
                                                                                dataModal: this.state.clientsSearch,
                                                                                title: 'Pregled komitenata',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Šifra komitenta mora biti unesena!" },
                                                                                    (value) => {
                                                                                        if (this.state.clientsSearch.length == 0 && this.state.clients.filter(item => item.code == value).length == 0 && this.state.clients.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.clientsSearch.length != 0 && this.state.clientsSearch.filter(item => item.code == value).length == 0 && this.state.clientsSearch.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'factureNumber',
                                                                                label: 'Komitent',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'selectWithSearch',
                                                                name: 'factureNumber',
                                                                next: 'item.discount',
                                                                label: 'Faktura broj',
                                                                textType: 'number',
                                                                asyncValidation: true,
                                                                values: [...this.state.clientFacture.map(item => {
                                                                    let obj = {
                                                                        name: item.documentNumber + ' - ' + item.factureDate, //item.factureDate
                                                                        value: item.documentNumber
                                                                    }
                                                                    return obj
                                                                })],
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Broj fakture mora biti unesen!" }

                                                                ]
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'item.beforeAmount',
                                                                next: 'item.discount',
                                                                label: 'Raniji iznos',
                                                                textType: 'number',
                                                                readOnly: true,
                                                                asyncValidation: true,
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-2-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        className: 'input-group-custom input-group-custom-2',
                                                                        children: [
                                                                            {
                                                                                type: 'decimal',
                                                                                name: 'item.discount',
                                                                                next: 'item.discountAmount',
                                                                                // textType: 'number',
                                                                                label: 'Rabat',
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Rabat mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'item.discountAmount',
                                                                                next: 'item.ammountKo',
                                                                                label: 'Rabat iznos',
                                                                                textType: 'number',
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value == '') return "Rabat mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'item.newAmount',
                                                                next: 'item.ammountKo',
                                                                label: 'Novi iznos',
                                                                textType: 'number',
                                                                readOnly: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }
                                                                ]
                                                            },
                                                            {
                                                                type: 'decimal',
                                                                name: 'item.ammountKo',
                                                                label: 'Iznos KO',
                                                                // textType: 'number',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "IZnos KO mora biti unesen!" }
                                                                ]
                                                            },
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </div>

                        :

                        null
                }

                {
                    this.state.specification ?
                        <>
                            <div className="list-builder-filters-wrap">
                                <div className="list-builder-filters">
                                    <div className="field-strap-wrap">
                                        <Label>Broj fakture</Label>
                                        <Input type='text' value={this.state.zaSpec ? this.state.zaSpec[0].factureNumber : ''} placeholder={'Broj fakture'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Label>Šifra komitenta</Label>
                                        <Input type='text' value={this.state.zaSpec ? this.state.zaSpec[0].clientCode : ''} placeholder={'Komitent'} />
                                    </div>
                                </div>
                            </div>




                            Specifikacija
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'article', label: 'ŠIFRA ARTIKLA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammount', label: 'KOLIČINA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'price', label: 'CIJENA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'beforeAmount', label: 'STARA VRIJEDNOST'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'newAmount', label: 'NOVA VRIJEDNOST'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammountKo', label: 'IZNOS KO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}
                                items={this.state.zaSpec ? this.state.zaSpec[0].items.map((item, idx) => {
                                    return {
                                        ...item,
                                    }
                                })
                                    : []}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                        </>
                        :
                        null

                }

            </div>
        )
    }
}

export default Page(BookNoticePage);
