import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import printIcon from '../../assets/print.svg';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import garbageIcon from '../../assets/garbage.svg';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import exportIcon from "../../assets/export.svg";
import PrintHelper from "../../components/printHelper";

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            warehousesList: true,
            warehousesForm: false,
            total: 20,
            items: [

            ],
            printTable: false,
            exportTable: false,
        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state)

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Skladište</h1>
                    { !this.state.warehousesForm ?

                            <div className="title-options-group">
                                <button onClick={ async ()=>{this.setState({printTable: true})}}>

                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={ async ()=>{this.setState({exportTable: true})}}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>

                            </div>
                    : ''
                    }
                </div>
                {this.state. warehousesForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({  warehousesForm: false })}>
                            Lista skladišta
                        </button>
                        <button className="active" onClick={() => this.setState({  warehousesForm: {} })}>
                            Dodaj skladište
                        </button>
                    </div>
                    : null}

                {
                    this.state.warehousesForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    onSubmit={(data) => {
                                        this._api('api2', { data: data }, () => {
                                            this.setState({ warehousesForm: false }, () => { this._onLoad() })
                                        });
                                    }}
                                    initialValues={this.state.warehousesForm}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Skladište'.translate(this.props.lang),
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'code',
                                                                            next: 'name',
                                                                            label: 'Šifra',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Šifra mora biti unešena!'),
                                                                                async (value) => {
                                                                                    console.log(value)
                                                                                    try {
                                                                                        console.log(value)
                                                                                        if (value) {
                                                                                            let res = await this._apiAsync('validate', { value: value });
                                                                                            console.log(res.status)
                                                                                            if (res.status == 400) {
                                                                                                return 'Greska'
                                                                                            }
                                                                                            return undefined;
                                                                                        }

                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                                number('Mora biti broj.')
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 10, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'type',
                                                                            label: 'Naziv',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Naziv skladišta mora biti unešen!'),
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'type',
                                                                            //next: 'barcode',
                                                                            label: 'Vrsta skladišta',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Vrsta skladišta mora biti unešena!'),
                                                                                // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 2, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'unit',
                                                                //             next: 'barcode',
                                                                //             label: 'Jedinica mjere',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 required('Jedinica mjere mora biti unešen!'),
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 5, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'barcode',
                                                                //             next: 'tax',
                                                                //             label: 'Bar kod',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 required('Barkod mora biti unešen!'),
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 5, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'tax',
                                                                //             next: 'group',
                                                                //             label: 'Porez',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 required('Porez mora biti unešen!'),
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 2, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'group',
                                                                //             next: 'subgroup',
                                                                //             label: 'Grupa',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 5, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'subgroup',
                                                                //             next: 'manufacturer',
                                                                //             label: 'Podgrupa',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 5, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'manufacturer',
                                                                //             next: 'description',
                                                                //             label: 'Proizvođač',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 8, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'description',
                                                                //             next: 'fond',
                                                                //             label: 'Opis',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 4, sm: 4, xs: 4 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'fond',
                                                                //             next: 'stock',
                                                                //             label: 'Fond',
                                                                //             asyncValidation: true,
                                                                //             validate: [
                                                                //                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                //             ]
                                                                //         },
                                                                //     ]
                                                                // },

                                                            ]
                                                        },

                                                    ]
                                                },
                                                // {
                                                //     type: 'col',
                                                //     width: { lg: 4, sm: 6, xs: 6 },
                                                //     children: [
                                                //         {
                                                //             type: 'block',
                                                //             header: 'Skladište'.translate(this.props.lang),
                                                //             style: { marginLeft: 15 },
                                                //             children: [
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 3, sm: 6, xs: 6 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'stock',
                                                //                             next: 'stockName',
                                                //                             label: 'Skladište',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },

                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 9, sm: 6, xs: 6 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'stockName',
                                                //                             next: 'firstPrice',
                                                //                             label: 'Naziv skladišta',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 6, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'firstPrice',
                                                //                             next: 'secondPrice',
                                                //                             label: 'Nabavna cijena',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 6, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'secondPrice',
                                                //                             next: 'minSupplies',
                                                //                             label: 'Prodajna cijena',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 6, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'minSupplies',
                                                //                             next: 'maxSupplies',
                                                //                             label: 'Minimalna zaliha',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 6, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'maxSupplies',
                                                //                             next: 'location',
                                                //                             label: 'Maksimalna zaliha',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //                 {
                                                //                     type: 'col',
                                                //                     width: { lg: 12, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: 'location',
                                                //                             label: 'Lokacija',
                                                //                             asyncValidation: true,
                                                //                             validate: [
                                                //                                 // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                //                             ]
                                                //                         },
                                                //                     ]
                                                //                 },
                                                //             ]
                                                //         },

                                                //     ]
                                                // },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'col',
                                                            width: { lg: 12, sm: 4, xs: 4 },
                                                            children: [
                                                                {
                                                                    type: 'button',
                                                                    action: 'submit',
                                                                    children: 'DODAJ',
                                                                    className: 'submit-button'
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                }
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :


                        <ListBuilder
                            tabs={true}
                            sort={true}
                            tab1Name={'Lista skladišta'.translate(this.props.lang)}
                            tab2Name={"Dodaj skladište".translate(this.props.lang)}
                            onClickTab1={() => {
                                this.setState({
                                    warehousesList: true,
                                    warehousesForm: false,
                                })
                            }}
                            onClickTab2={() => {
                                this.setState({
                                    warehousesForm: {},
                                    warehousesList: false
                                })
                            }}
                            lang={this.props.lang}
                            loading={this.state.loading}
                            total={this.state.total}
                            showNumeration={false}
                            fields={[
                                { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                { type: 'text', name: 'type', label: 'VRSTA SKLADIŠTA'.translate(this.props.lang), multilang: false, allowSort: true },

                            ]}
                            items={this.state.items}
                            /*
                            actions={
                                [
                                    {
                                        component: <Isvg src={editIcon} />,
                                        onClick: (item) => this.setState({ articleForm: item })
                                    },
                                    {
                                        component: <Isvg src={garbageIcon} />,
                                        onClick: (item) => this.setState({ deleteModal: item })
                                    },
                                ]
                            }
                            */
                            params={params}
                            sortField={params.sortField}
                            sortType={params.sortType}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}

                            printTable={this.state.printTable}
                            exportTable={this.state.exportTable}
                            genericPrint={this.props.genericPrint}
                            genericExport={this.props.genericExport}
                            changeState={this.changeState}
                            genericData={ {url: 'data/warehouses', title:'Skladište'}}
                        ></ListBuilder>
                }


            </div>
        )
    }
}

export default Page(Home);
