import React, { Component } from 'react';

import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Isvg from 'react-inlinesvg';
import sortDown from '../assets/sortdown.svg';
import sortUp from '../assets/sortup.svg';
import { OTPREMNICA, PAKOVANJE } from "../helpers/constants";
import Checkbox from "../form-builder/fields/checkbox";

import Loader from "../components/loader";


function numberWithCommas(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}
/**
* Generate data list with pagination
* @author   Milan Stanojevic
*/
class ListBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: '',
        };
    }
    prepareRequest = async () => {
        const params = {}
        for (const [key, value] of Object.entries(this.props.params)) {
            if (key === 'entries' || key === 'page') {
                continue;
            }
            if (key === 'proknjizeno') {
                params[key] = value == 1 ? false : true
                continue;
            }
            params[key] = value;
        }
        const fields = this.props.fields.map((el) => {
            return { key: el.name, label: el.label }
        })
        return {
            filter: {
                // entries: 10000,
                // page: 0,
                additionalFilters: params
            },
            methods: 'post',
            url: this.props.genericData.url,
            fields: fields,
            title: this.props.genericData.title
        }
    }
    printTableData = async () => {
        const request = await this.prepareRequest();
        await this.props.genericPrint(request)
        this.props.changeState('printTable');
    }
    exportTableData = async () => {
        const request = await this.prepareRequest();
        await this.props.genericExport(request)
        this.props.changeState('exportTable');
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.printTable !== this.props.printTable && this.props.printTable) {
            this.printTableData()
        }
        if (prevProps.exportTable !== this.props.exportTable && this.props.exportTable) {
            this.exportTableData();
        }

        
    }

    componentWillUpdate(newProps) {

    }

    checkFilterDelay = () => {
        if (this.lastChangeFilter && this.props.filterDelay) {
            let delay = new Date().getTime() - this.lastChangeFilter;
            let delayTime = 100;
            if (this.props.filterDelay > 100) {
                delayTime = this.props.filterDelay - 100;
            }
            if (delay > delayTime) {
                this.props.updateMultipleParams([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                this.lastChangeFilter = null;
            }
        }
    }

    render() {
        let params = this.props.params;
        return (
            <Row className="list-builder-wrap">
                <Col lg="6">

                    {/*
                                this.props.tabs3 ?
                                    <div className="table-tabs tabs-three-buttons">
                                        <button className="" onClick={() => this.props.onClickTab0()}>{this.props.tab0Name}</button>
                                        <button className="active" onClick={() => this.props.onClickTab1()}>{this.props.tab1Name}</button>
                                        <button className="" onClick={() => this.props.onClickTab2()}>{this.props.tab2Name}</button>
                                    </div>
                                :
                                null
                            */}
                    {
                        this.props.tabs ?
                            <div className="table-tabs tabs-two-buttons">
                                <button className="active" onClick={() => this.props.onClickTab1()}>{this.props.tab1Name}</button>
                                <button className="" onClick={() => this.props.onClickTab2()}>{this.props.tab2Name}</button>
                                <button className="" onClick={() => this.props.onClickTab3()}>{this.props.tab3Name}</button>
                            </div>
                            :
                            null
                    }
                </Col>


                {
                    this.props.sort ?

                        <Col lg="6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <FormGroup className="table-filter">
                                {
                                    !this.props.hideResults ?
                                        <Label>
                                            <Input type="select" className="entries" value={params.entries} onChange={(e) => this.props.updateMultipleParams([{ name: 'entries', value: e.target.value }, { name: 'page', value: 0 }], true)}>
                                                <option value={10}>10</option>
                                                <option value={25}>25</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </Input>
                                            {'rezultata po stranici'.translate(this.props.lang)}
                                        </Label>
                                        :
                                        null
                                }



                                {
                                    !this.props.hideFilters ?

                                        <div className="filter-results">
                                            <FormGroup>
                                                <Label> <Input type="text" placeholder="Pretraga..." style={{ marginRight: 0 }} value={params.filter ? params.filter : ''} onChange={(e) => this.props.updateMultipleParams([{ name: 'filter', value: e.target.value }, { name: 'page', value: 0 }], true)} ></Input></Label>
                                            </FormGroup>
                                        </div>
                                        :
                                        null
                                }
                            </FormGroup>
                        </Col>

                        :
                        !this.props.hideFilters ?
                            <Col lg="12">
                                <div className="list-header">
                                    <Label>
                                        {this.props.header}
                                    </Label>


                                    <div className="filter-results">
                                        <FormGroup>
                                            <Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Pretraga"
                                                    style={{ marginRight: 0 }}
                                                    // value={params.filter ? params.filter : ''}
                                                    value={this.state.filter}
                                                    onChange={(e) => {
                                                        if (this.props.filterDelay && e.target.value) {
                                                            this.setState({ filter: e.target.value }, () => {
                                                                this.lastChangeFilter = new Date().getTime();
                                                                setTimeout(() => {
                                                                    this.checkFilterDelay()
                                                                }, this.props.filterDelay);
                                                            })
                                                        } else {
                                                            this.setState({ filter: e.target.value }, () => {
                                                                this.props.updateMultipleParams([{ name: 'filter', value: this.state.filter }, { name: 'page', value: 0 }], true)
                                                                // this.props.updateMultipleParams([{ name: 'filter', value: e.target.value }, { name: 'page', value: 0 }], true)
                                                            })
                                                        }
            
                                                        
                                                    }}
                                                >
                                                </Input>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                            </Col>
                            :
                            null
                }




                <Col lg="12" className="table-container" >
                    <div className="table-container-scroll" style={{ maxHeight: this.props.maxHeight ? this.props.maxHeight : '100%' }} >
                        <Table responsive hover className={!this.props.actions ? 'cursor-row' : ''}>
                            <thead>
                                <tr style={{ whiteSpace: 'nowrap' }}>
                                    {this.props.showNumeration ? <th>#</th> : null}

                                    {
                                        this.props.fields.filter(el => !el.hideColumn).map((item, idx) => {
                                            let fieldStyle = {};
                                            if (item.fieldStyle) {
                                                fieldStyle = { ...item.fieldStyle };

                                                if (fieldStyle.fontSize) {
                                                    delete fieldStyle.fontSize;
                                                }
                                                if (fieldStyle.fontWeight) {
                                                    delete fieldStyle.fontWeight;
                                                }
                                                if (fieldStyle.color) {
                                                    delete fieldStyle.color;
                                                }
                                            }
                                            return (
                                                <th className={item.allowSort ? 'sort-enabled' : 'sort-enabled'} onClick={() => {
                                                    if (item.allowSort) {
                                                        this.props.updateSort(item.name, !this.props.sortField ? 1 : this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? -1 : 1)
                                                    }
                                                }} key={idx} style={{ ...fieldStyle }}>{item.label} {item.allowSort ? <span className={this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? `sort sort-asc` : this.props.sortField == item.name && parseInt(this.props.sortType) == -1 ? 'sort sort-desc' : item.allowSort ? 'sort' : ''}><Isvg src={sortUp} className="sort-up-icon" /><Isvg src={sortDown} className="sort-down-icon" /></span> : null}</th>
                                            )
                                        })
                                    }
                                    {this.props.actions ?
                                        <th className="action-td">{/*{this.props.actionLabel ? this.props.actionLabel : 'Action'.translate(this.props.lang)}*/}</th>
                                        :
                                        null}
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    this.props.items && this.props.items.length ? this.props.items.map((item, idx) => {
                                        if (this.props.childrenField && this.props.childrenFieldSortField) {
                                            item[this.props.childrenField].sort((a, b) => { return a.code - b.code })
                                        }
                                        return (
                                            <>
                                                <tr key={idx} onClick={() => {
                                                    if (!this.props.actions && this.props.onClick) {
                                                        if (this.props.rawItems)
                                                            this.props.onClick(this.props.rawItems[idx])
                                                        else
                                                            this.props.onClick(item)
                                                    }
                                                }}
                                                    tabIndex={idx}
                                                    onFocus={(e) => {
                                                        if (this.props.forModal) {

                                                            let rows = document.getElementsByClassName('table table-hover')[this.props.forModal].rows
                                                            rows[idx + 1].style.backgroundColor = '#D2D6DA'
                                                        }


                                                    }}
                                                    onBlur={(e) => {
                                                        if (this.props.forModal) {
                                                            let rows = document.getElementsByClassName('table table-hover')[this.props.forModal].rows
                                                            rows[idx + 1].style.backgroundColor = '#fff'

                                                        }

                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (this.props.forModal) {
                                                            this.props.changeFocus(e, idx, item)
                                                        }

                                                    }}

                                                    className={item.notFinished || item.status === OTPREMNICA ? !this.props.actions && this.props.onClick ? `row-not-finished-document cursor-pointer ${item.backgroundClassname ? item.backgroundClassname : ''}` : `row-not-finished-document ${item.backgroundClassname ? item.backgroundClassname : ''}` : !this.props.actions && this.props.onClick ? `cursor-pointer ${item.backgroundClassname ? item.backgroundClassname : ''} ${item.status === PAKOVANJE ? 'orange-row' : ''}` : `${item.backgroundClassname ? item.backgroundClassname : ''} `}

                                                >
                                                    {this.props.showCheckBox ? <th><Checkbox /></th> : null}
                                                    {this.props.showNumeration ? <th scope="row">{params.page * params.entries + idx + 1}</th> : null}
                                                    {
                                                        this.props.fields.filter(el => !el.hideColumn).map((field, fidx) => {
                                                            if (field.type == 'two-rows-text') {
                                                                return (
                                                                    <td key={fidx}>
                                                                        {
                                                                            field.strong && field.strong(item) ?
                                                                                <>
                                                                                    <strong> {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''}  {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)}</strong> <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''} {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)} <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>

                                                                                </>
                                                                        }

                                                                    </td>
                                                                )
                                                            } else if (field.type == 'text') {
                                                                let colorObj = {};
                                                                if (this.props.coloredCells && this.props.coloredCells.filter(cell => cell.name == field.name).length) {
                                                                    let cell = this.props.coloredCells.filter(cell => cell.name == field.name);
                                                                    if (cell && cell.length) {
                                                                        for (let i = 0; i < cell.length; i++) {
                                                                            if (cell[i].condition) {
                                                                                if (this.props.items) {

                                                                                    if (this.props.items.filter((el, eidx) => el[cell[i].condition] === true && eidx == idx).length) {
                                                                                        colorObj = {};
                                                                                        if (cell[i].backgroundColor) { colorObj.backgroundColor = cell[i].backgroundColor; }
                                                                                        if (cell[i].color) { colorObj.color = cell[i].color; }
                                                                                    }
                                                                                }

                                                                            } else {
                                                                                colorObj = {};
                                                                                if (cell[i].backgroundColor) { colorObj.backgroundColor = cell[i].backgroundColor; }
                                                                                if (cell[i].color) { colorObj.color = cell[i].color; }
                                                                            }
                                                                        }
                                                                    }

                                                                }
                                                                let fieldStyle = {};
                                                                if (field.fieldStyle) {
                                                                    fieldStyle = field.fieldStyle;
                                                                }
                                                                return (
                                                                    <td key={fidx} style={{ width: field.width, maxWidth: field.maxWidth, overflow: field.maxWidth ? 'hidden' : 'unset', wordBreak: field.maxWidth ? 'break-all' : 'unset', ...colorObj, whiteSpace: this.props.noWrap ? 'nowrap' : 'unset', ...fieldStyle }}>
                                                                        {
                                                                            field.multilang ?
                                                                                Object.get(item, field.name) && Object.get(item, field.name[this.props.lang])
                                                                                :
                                                                                field.numberWithCommas ?
                                                                                    numberWithCommas(Object.get(item, field.name))
                                                                                    :
                                                                                    field.substr && Object.get(item, field.name) && Object.get(item, field.name).length > field.substr ?
                                                                                        <div className='tooltip-div'>
                                                                                            {Object.get(item, field.name).substr(0, field.substr) + '...'}
                                                                                            <div className='tooltip-text'>
                                                                                                {Object.get(item, field.name)}
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        Object.get(item, field.name)
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                         /*else if (field.type == 'checkbox') {
                                                            return (
                                                                <td key={fidx}>
                                                                    <div>
                                                                        <Checkbox checked={item[field.name]} label={field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]} />
                                                                    </div>
                                                                </td>
                                                            )
                                                        } */else if (field.type == 'list') {
                                                                return (
                                                                    <td key={fidx}>
                                                                        {
                                                                            item[field.name] ? item[field.name].map((fitem, fitemidx) => {
                                                                                return (
                                                                                    <div className="list-item">
                                                                                        {fitem[field.itemName]}

                                                                                        {
                                                                                            field.actions.map((action, aidx) => {
                                                                                                return (
                                                                                                    <button key={aidx} onClick={() => action.onClick(item, fitemidx)}>{action.component}</button>
                                                                                                )
                                                                                            })

                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                                :
                                                                                null
                                                                        }
                                                                    </td>
                                                                )

                                                            }
                                                        })
                                                    }
                                                    {this.props.actions ?

                                                        <td className="action-td">
                                                            {
                                                                this.props.actions && this.props.actions.length ?
                                                                    this.props.actions.map((action, aidx) => {
                                                                        if (!action.condition || (action.condition && action.condition(item))) {
                                                                            return (
                                                                                <button key={aidx} onClick={() => {
                                                                                    if (this.props.rawItems) {
                                                                                        action.onClick(this.props.rawItems[idx]);
                                                                                    }
                                                                                    else {
                                                                                        action.onClick(item, idx)
                                                                                    }
                                                                                }}
                                                                                    className={action.dropdownShowCondition && action.dropdownShowCondition(item) && action.dropdownShow ? 'list-builder-drop-down-wrap' : ''}
                                                                                >
                                                                                    {action.component}
                                                                                    {action.dropdownShowCondition && action.dropdownShowCondition(item) && action.dropdownShow ? action.dropdownShow : null}

                                                                                </button>
                                                                            )
                                                                        }

                                                                    })
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                this.props.childrenField && item[this.props.childrenField] && item[this.props.childrenField].length ?
                                                                    <button className="expand-dropdown" onClick={() => {
                                                                        this.setState({
                                                                            expandItem: this.state.expandItem == item._id ? null : item._id
                                                                        })
                                                                    }}></button>
                                                                    :
                                                                    null
                                                            }
                                                        </td>

                                                        :
                                                        null
                                                    }



                                                </tr>

                                                {
                                                    this.props.subItems && idx == this.props.activeSubitem && item && item.listOfItems && item.listOfItems.length ?
                                                        <tr key={idx} className='list-builder-sub-items-wrap'>
                                                            <td colSpan={this.props.fields.length + (this.props.actions ? 1 : 0)}>
                                                                <table>
                                                                    <thead>
                                                                        <th>ŠIFRA</th>
                                                                        <th>NAZIV ARTIKLA</th>
                                                                        <th>Tip</th>
                                                                        <th>BR.DOK</th>
                                                                        <th>BROJ</th>
                                                                        <th>DATUM</th>
                                                                        <th>KOMITENT</th>
                                                                        <th>KOLIČINA</th>
                                                                    </thead>

                                                                    {
                                                                        item.listOfItems.map((subitem, sidx) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{subitem.article}</td>
                                                                                    <td>{subitem.articleName}</td>
                                                                                    <td>{subitem.type}</td>
                                                                                    <td>{subitem.documentNumber}</td>
                                                                                    <td>{subitem.itemNumber}</td>
                                                                                    <td>{subitem.date}</td>
                                                                                    <td>{subitem.clientCode}</td>
                                                                                    <td>{subitem.ammount}</td>
                                                                                </tr>


                                                                            )
                                                                        })
                                                                    }

                                                                </table>

                                                            </td>
                                                        </tr>
                                                        :
                                                        null
                                                }



                                                {
                                                    this.props.childrenField && item[this.props.childrenField] && item[this.props.childrenField].length && this.state.expandItem == item._id ?
                                                        item[this.props.childrenField].map((item) => {
                                                            return (
                                                                <tr className="children" key={idx} onClick={() => {
                                                                    if (!this.props.actions && this.props.onClick) {
                                                                        if (this.props.rawItems)
                                                                            this.props.onClick(this.props.rawItems[idx])
                                                                        else
                                                                            this.props.onClick(item)
                                                                    }
                                                                }}>
                                                                    {this.props.showCheckBox ? <th><Checkbox /></th> : null}
                                                                    {this.props.showNumeration ? <th scope="row">{params.page * params.entries + idx + 1}</th> : null}
                                                                    {
                                                                        this.props.fields.map((field, fidx) => {
                                                                            if (field.type == 'two-rows-text') {
                                                                                return (
                                                                                    <td key={fidx}>
                                                                                        {
                                                                                            field.strong && field.strong(item) ?
                                                                                                <>
                                                                                                    <strong> {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''}  {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)}</strong> <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''} {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)} <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>

                                                                                                </>
                                                                                        }

                                                                                    </td>
                                                                                )
                                                                            } else if (field.type == 'text') {
                                                                                return (
                                                                                    <td key={fidx} style={{ maxWidth: field.maxWidth, overflow: field.maxWidth ? 'hidden' : 'unset', wordBreak: field.maxWidth ? 'break-all' : 'unset' }}>{field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]}</td>
                                                                                )
                                                                            }
                                                                     /*else if (field.type == 'checkbox') {
                                                                        return (
                                                                            <td key={fidx}>
                                                                                <div>
                                                                                    <Checkbox checked={item[field.name]} label={field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]} />
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    } */else if (field.type == 'list') {
                                                                                return (
                                                                                    <td key={fidx}>
                                                                                        {
                                                                                            item[field.name] ? item[field.name].map((fitem, fitemidx) => {
                                                                                                return (
                                                                                                    <div className="list-item">
                                                                                                        {fitem[field.itemName]}

                                                                                                        {
                                                                                                            field.actions.map((action, aidx) => {
                                                                                                                return (
                                                                                                                    <button key={aidx} onClick={() => action.onClick(item, fitemidx)}>{action.component}</button>
                                                                                                                )
                                                                                                            })

                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </td>
                                                                                )

                                                                            }
                                                                        })
                                                                    }
                                                                    {this.props.actions ?

                                                                        <td className="action-td">
                                                                            {
                                                                                // this.props.actions && this.props.actions.length ?
                                                                                //     this.props.actions.map((action, aidx) => {
                                                                                //         if (!action.condition || (action.condition && action.condition(item)))
                                                                                //             return (
                                                                                //                 <button key={aidx} onClick={() => {
                                                                                //                     if (this.props.rawItems) {
                                                                                //                         action.onClick(this.props.rawItems[idx]);
                                                                                //                     }
                                                                                //                     else {
                                                                                //                         action.onClick(item, idx)
                                                                                //                     }
                                                                                //                 }}>{action.component}</button>
                                                                                //             )
                                                                                //     })
                                                                                //     :
                                                                                //     null
                                                                            }
                                                                        </td>

                                                                        :
                                                                        null
                                                                    }



                                                                </tr>
                                                            )
                                                        })

                                                        :

                                                        null
                                                }
                                            </>

                                        )
                                    })
                                        :
                                        null

                                }
                            </tbody>
                        </Table>
                    </div>


                </Col>
                {
                    this.props.hidePagination ?

                        null

                        :

                        <>
                            <Col lg="6">
                                {
                                    this.props.total ?
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            breakClassName={'page-item disabled'}
                                            breakLinkClassName={'page-link disabled'}
                                            pageCount={this.props.total / params.entries}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={(page) => { this.props.updateParams('page', page.selected) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item-next'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item-next'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            forcePage={parseInt(params.page)}
                                        />
                                        :
                                        null
                                }
                            </Col>
                            <Col lg="6">
                                <span className="news-counter" >
                                    {'Prikazano '.translate(this.props.lang)}
                                    <span className="colored-green">
                                        {
                                            this.props.total ?
                                                numberWithCommas(params.page * params.entries + 1, true)
                                                :
                                                0
                                        }
                                    </span>
                                    {' do '.translate(this.props.lang)}
                                    <span className="colored-green">
                                        {
                                            this.props.total < (params.page * params.entries + params.entries * 1) ?
                                                numberWithCommas(this.props.total, true)
                                                :
                                                numberWithCommas(params.page * params.entries + params.entries * 1, true)
                                        }
                                    </span>
                                    {' od '.translate(this.props.lang)}
                                    <span className="colored-blue">
                                        {numberWithCommas(this.props.total, true)}
                                    </span>
                                    {''.translate(this.props.lang)}
                                </span>
                            </Col>
                        </>
                }
                <Loader loading={this.props.loading} />

            </Row>

        );
    }
}

export default ListBuilder;
