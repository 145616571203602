import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import lockIcon from '../../assets/lock.svg';
import moment from 'moment';
import DeleteModal from '../../components/deleteModal';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import env from "react-dotenv";
class Entry extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            nivelationList: true,
            nivelationForm: false,
            buttonActive: 1,
            // nivelationList: true,
            nivelationAdd: false,
            lockModal: false,
            listCustomFilter: true,
            items: [

            ],
            itemsList: [],
            idDokumenta: '',
            pomocnaDokument: '',
            pomocnaStavka: '',
            dokumentZaStavku: '',
            brojStavke: '',
            products: [],
            nivelationItems: [],
            series: [],
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            articlesSearch: [],
            printTable: false,
            exportTable: false,
            printHtml: null,
        }

    }

    componentDidMount() {
        this._onLoad();


        let params = this._getParams();

        if (params.nivelationNumber && params.nivelationNumber != this.state.filterNivelationNumber) {
            this.setState({ filterNivelationNumber: params.nivelationNumber })
        }


        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.kind && params.kind != this.state.filterKind) {
            this.setState({ filterKind: params.kind })
        }

        if (params.documentNumber && params.documentNumber != this.state.filterDocNumber) {
            this.setState({ filterDocNumber: params.documentNumber })
        }

        if (params.subKind && params.subKind != this.state.filterSubKind) {
            this.setState({ filterSubKind: params.subKind })
        }

        if (params.type && params.type != this.state.filterUser) {
            this.setState({ filterUser: params.type })
        }

        if (params.user && params.user != this.state.filterType) {
            this.setState({ filterType: params.user })
        }

        if (params.article && params.article != this.state.filterArticle) {
            this.setState({ filterArticle: params.article })
        }



        if (this.props[0].location && this.props[0].location.search) {
            this.setState({ redirect: true })
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') != -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }

            }

        }

    }

    goToFormTab = () => {
        this._onLoad()
        let nivelationForm = this.state.nivelationForm;
        if (!nivelationForm) {
            nivelationForm = {
                nivelationDate: this.state.dateNow
            }
        }
        nivelationForm.documentNumber = this.state.firstFreeNumber;
        if (!nivelationForm.item) {
            nivelationForm.item = { itemNumber: 1 }
        }
        this.setState({ nivelationForm: null }, () => {
            this.setState({ nivelationForm })
        })
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.firstFreeNumber && this.state.firstFreeNumber && !this.state.redirect) {
            let nivelationForm = this.state.nivelationForm;
            if (!nivelationForm) {
                nivelationForm = {
                    nivelationDate: this.state.dateNow
                }
            }
            nivelationForm.documentNumber = this.state.firstFreeNumber;
            if (!nivelationForm.item) {
                nivelationForm.item = { itemNumber: 1 }
            }
            this.setState({ nivelationForm: null }, () => {
                this.setState({ nivelationForm })
            })
        }
    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        this.setState({ articlesSearch: articleList.articlesSearch })
    }

    printOutput = async () => {
        const printHtml = await this._apiAsync('printOutputDocument', { documentNumber: this.state.nivelationForm.documentNumber })
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }

    render() {

        // console.log('nivelaccije.....', this.state.nivelationItems)
        let params = this._getParams();


        return (
            <div>

                <div className="section-title title-options">
                    <h1>Nivelacija</h1>

                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-group">
                                {/* <button>
                                    <div className="option green">
                                        <Isvg src={saveIcon} />
                                    </div>
                                    <span>Snimi</span>
                                </button> */}
                                <button onClick={async () => { this.setState({ printTable: true }) }}>
                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={async () => { this.setState({ exportTable: true }) }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                            </div>
                            :
                            null
                    }
                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ nivelationList: false, nivelationAdd: true, buttonActive: 1 }, () => { this._onLoad() })}>
                            Lista nivelacija
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive != 2) {
                                this.setState({ buttonActive: 2, nivelationForm: null, items: [] }, () => this.goToFormTab())
                            }
                        }}>
                            Dodaj nivelaciju
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 ?

                            <div className="show-unfinished" onClick={() => {
                                this.setState({ showUnfinished: !this.state.showUnfinished }, () => {
                                    this.updateParams('unfinished', this.state.showUnfinished)
                                })
                            }}>
                                <Input type="checkbox" checked={this.state.showUnfinished}
                                // onChange={(e) => {
                                //     this.setState({ showUnfinished: e.target.checked }, () => {
                                //         this.updateParams('unfinished', this.state.showUnfinished)
                                //     })
                                // }}
                                />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }

                </div>

                {
                    this.state.buttonActive == 2 ?

                        <div>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}

                                    classNameForm={"add-form-container"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'nivelationDate', 'item.article', 'item.serialNumber']}
                                    fieldTracker={async (name, value) => {
                                        if (name == 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, nivelationForm: null }, () => {
                                                this.setState({ nivelationForm: {} })
                                                // this.setState({nivelationDate: this.props.dateNow})
                                            })
                                        }

                                        if (name == 'documentNumber' && value) {
                                            if (value) { //!= this.state.prevDocumentNumber
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                                    if (value == res.result.documentNumber) {
                                                        console.log('OK......OK.....OK')
                                                    }
                                                    //ukoliko postoji rezultat citanja dokumenta
                                                    if (res && res.result && res.result._id) {

                                                        this.setState({ nivelationForm: null }, () => {
                                                            this.setState({ nivelationForm: res.result }, () => {

                                                                if (res.result.items && res.result.items.length) {
                                                                    this.setState({
                                                                        items: res.result.items,

                                                                    }, () => {
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        items: [],
                                                                    }, () => {
                                                                        this.remoteOnChange('item.itemNumber', this.state.itemNumber)
                                                                    })
                                                                }

                                                            })
                                                        })
                                                    } else if (this.state.prevDocumentNumber != value /*&& this.state.entranceForm._id*/) {
                                                        this.setState({ nivelationForm: null }, () => {
                                                            this.setState({ nivelationForm: { documentNumber: value } }, () => {
                                                                this.remoteOnChange('nivelationDate', this.state.dateNow)
                                                                this.setState({ itemNumber: '1', items: [], totalItems: 0, nivelationForm: {} }, () => {
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                        }

                                                                    })
                                                                })

                                                            })
                                                        })

                                                    }

                                                    this.setState({ prevDocumentNumber: value })
                                                })



                                            }
                                        }


                                        if (name == 'item.article' && value) {
                                            if (value) {
                                                this._apiAsync('get-articles', { filter: value }).then((products) => {
                                                    if (products && products.items) {
                                                        this.setState({ products: products && products.items }, () => {
                                                            this._apiAsync('get-price', { article: value }).then((res) => {

                                                                this.remoteOnChange('item.oldPrice', res.price.sellPrice)
                                                                this.remoteOnChange('item.supply', (res.price.quantity - res.price.quantityReserved))
                                                                this.remoteOnChange('item.ammount', (res.price.quantity - res.price.quantityReserved))
                                                            })
                                                        })

                                                    }
                                                })

                                                let series = await this._apiAsync('get-series', { article: value });
                                                if (series && series.items && series.items.length) {
                                                    console.log('SA SERIJE.......SA SERIJE.....')
                                                    this.setState({ series: series.items })
                                                } else {
                                                    this.setState({ series: [] }, () => { this.remoteOnChange('item.serialNumber') })

                                                }
                                            }
                                        }

                                        if (name == 'item.article' && !value) {
                                            this.remoteOnChange('item.serialNumber', '')
                                        }

                                        if (name == 'item.serialNumber') {
                                            if (value) {
                                                let article = this.registerGetValue('item.article');
                                                let series = await this._apiAsync('get-series', { article: article, filter: value });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items }, () => {
                                                        if (this.state.series && this.state.series.length) {
                                                            if (this.state.series.filter(item => item.serialNumber == value).length == 1) {
                                                                let item = this.state.series.filter(item => item.serialNumber == value)[0];
                                                                if (item && item.newExpirationDate) {
                                                                    // if (this.registerGetValue('item.expirationDate') != item.newExpirationDate) {
                                                                    this.remoteOnChange('item.expirationDate', item.newExpirationDate)
                                                                    this.remoteOnChange('item.supply', item.ammount)
                                                                    // }
                                                                } else if (item && item.expirationDate) {
                                                                    // if (this.registerGetValue('item.expirationDate') != item.expirationDate) {
                                                                    this.remoteOnChange('item.expirationDate', item.expirationDate)
                                                                    this.remoteOnChange('item.supply', item.ammount)
                                                                    // }
                                                                }

                                                            } else {
                                                                if (this.registerGetValue('item.expirationDate')) {
                                                                    this.remoteOnChange('item.expirationDate', '')
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    if (this.registerGetValue('item.expirationDate')) {

                                                        this.remoteOnChange('item.expirationDate', '')
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.expirationDate')) {
                                                    this.remoteOnChange('item.expirationDate', '')
                                                }
                                            }
                                        }


                                    }}
                                    onSubmit={(data, notFinished) => {

                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                delete data.item;
                                            }
                                        }

                                        console.log('data', data);
                                        if (data._id) {
                                            console.log('Update  podaci...', data)
                                            this._apiAsync('update-document', data).then((res) => {

                                                this.setState({ nivelationForm: null }, () => {

                                                    this.setState({
                                                        nivelationForm: res.res
                                                    }, () => { this.setState({ items: res.res.items }) })
                                                })

                                                if (res.res.items && res.res.items.length) {
                                                    this.remoteOnChange('item.itemNumber', String(res.res.items.length + 1))
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }
                                            })


                                        } else {
                                            data.class = 'manuelna'
                                            // console.log('ZA UPIS NIVELACIJE....', data)
                                            this._apiAsync('insert-new', data).then((res) => {
                                                if (res.result && res.result._id) {
                                                    console.log('VRACENO SA UPISA.....', res.result)
                                                    this.setState({ nivelationForm: null }, () => {

                                                        this.setState({ nivelationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] }, () => {

                                                        })
                                                    })
                                                    if (res.result.items && res.result.items.length) {
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.itemNumber', 1)
                                                    }

                                                }
                                            })



                                        }


                                    }}
                                    initialValues={this.state.nivelationForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                                    children: [
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            className: 'lock-icon-container',
                                                                            children: <div className="lock-icon">
                                                                                <Isvg src={lockIcon} onClick={() => {
                                                                                    this.setState({
                                                                                        lockModal: true
                                                                                    })
                                                                                }} />
                                                                            </div>,
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 11, sm: 11, xs: 11 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.nivelationForm && this.state.nivelationForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput().then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })
                                                                                                }
                                                                                                )

                                                                                                return 'open-modal';
                                                                                            }

                                                                                        },

                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {

                                                                                                // da li treba brisanje dokumenta na ovo dugme jer ima dugme brisanje dokumenta ispod forme ??

                                                                                                // if(this.state.nivelationForm && this.state.nivelationForm._id){
                                                                                                //     this.setState({ deleteDocumentModal: this.state.nivelationForm })
                                                                                                // }else {
                                                                                                this.setState({ buttonActive: 2, nivelationForm: null, items: [], series: [], }, () => this.goToFormTab())
                                                                                                this.remoteOnChange('item.article', '')

                                                                                                // }

                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'nivelationDate',
                                                            label: 'Broj dokumenta',
                                                            // textType: 'number',
                                                            integer: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                    children: [
                                                        {
                                                            type: 'date',
                                                            name: 'nivelationDate',
                                                            next: 'item.article',
                                                            label: 'Datum nivelacije',
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Datum nivelacije mora biti unešen!"),
                                                                validateDate('Datum nije validan')
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'class',
                                                            //next: 'basic',
                                                            label: 'Vrsta',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'basic',
                                                            //next: 'subClass',
                                                            label: 'Osnov',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'subClass',
                                                            //next: 'type',
                                                            label: 'Podvrsta',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'type',
                                                            //next: 'number',
                                                            label: 'Tip',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.article',
                                                            label: 'Redni broj',
                                                            integer: true,
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Redni broj mora biti unešen!"),

                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                    children: [
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            label: 'Artikal',
                                                            textType: 'number',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            asyncValidation: true,
                                                            validate: [
                                                                // required("Artikal mora biti unešen!"),
                                                                async (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }

                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 || !this.state.nivelationForm._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"

                                                                    if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0 && checkValidation) {
                                                                        return "Neispravan unos!"
                                                                    }
                                                                    if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0 && checkValidation) {
                                                                        return "Neispravan unos!!"
                                                                    }

                                                                    this.setState({ numberOfSeries: this.state.articlesSearch?.filter(item => item.code == value)[0]?.series?.length })

                                                                }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'item.serialNumber',
                                                            next: 'item.expirationDate',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                // async (value) => {
                                                                //     try {
                                                                //         if (value && this.registerGetValue('item.article')) {
                                                                //             let res = await this._apiAsync('validate-serial-number', { value: value, code: this.registerGetValue('item.article') });
                                                                //             if (res.status == 400) {
                                                                //                 return 'Šifra je zauzeta'
                                                                //             }
                                                                //             return undefined;
                                                                //         }

                                                                //     } catch (e) {
                                                                //         return undefined;
                                                                //     }
                                                                // },
                                                                (value) => {

                                                                    // if (this.state.series.length != 0 && !value) {
                                                                    //     return 'Serija mora biti unešena!!'
                                                                    // }
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }

                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 || !this.state.nivelationForm._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if (this.state.series.length == 0 && this.state.numberOfSeries != 0 && checkValidation) {
                                                                        return 'Neispravan unos!!'
                                                                    }

                                                                    if (this.state.numberOfSeries == 0 && value && checkValidation) {
                                                                        return 'Neispravan unos!'
                                                                    }

                                                                }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.expirationDate',
                                                            next: 'item.newPrice',
                                                            values: [{ name: '', value: -1 }],
                                                            label: 'Rok',
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.supply',
                                                            // next: 'ammount',
                                                            label: 'Zaliha',
                                                            readOnly: true,
                                                            textType: 'number',
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.ammount',
                                                            next: 'item.newPrice',
                                                            label: 'Količina',
                                                            textType: 'number',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // required("Količina mora biti unešena!")
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.oldPrice',
                                                            next: 'item.newPrice',
                                                            label: 'Stara cijena',
                                                            textType: 'number',
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // required("Stara cijena mora biti unešena!")
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                    children: [
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.newPrice',
                                                            label: 'Nova cijena',
                                                            next: 'submitForm',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            // textType: 'number',
                                                            validate: [
                                                                // required("Nova cijena biti unešena!"),
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }

                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 || !this.state.nivelationForm._id) {
                                                                        checkValidation = true;
                                                                    }
                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Nova cijena mora biti unesena!"

                                                                }

                                                            ]
                                                        },
                                                    ]
                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'expirationDate', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'oldPrice', label: 'STARA CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'newPrice', label: 'NOVA CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    ]}
                                    items={this.state.items.map((item) => {
                                        return {
                                            ...item,
                                            total: Math.round((((item.newPrice - item.oldPrice) * item.ammount) + Number.EPSILON) * 100) / 100
                                        }
                                    })}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => {
                                                    let nivelationForm = this.state.nivelationForm;
                                                    nivelationForm.item = item;
                                                    this.setState({ nivelationForm: null }, () => {
                                                        this.setState({ nivelationForm })
                                                    })


                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => { this.setState({ deleteModal: item }) }
                                            },
                                        ]
                                    }


                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            <div className="footer">
                                <div className="footer-left">
                                    <button className="blue-button" onClick={() => this.setState({ descriptionModal: true, description: this.state.nivelationForm.description ? String(this.state.nivelationForm.description) : '' })}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Opis</p>
                                    </button>
                                    {
                                        this.state.nivelationForm && this.state.nivelationForm._id ?
                                            <button onClick={() => this.setState({ deleteDocumentModal: this.state.nivelationForm })}>
                                                <div className="box box-red">
                                                    <Isvg src={garbageOpionIcon} />
                                                </div>
                                                <p>BRISANJE DOKUMENTA</p>
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                                <div className="footer-right">
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Iznos</p>
                                            <span>  {
                                                this.state.items.length != 0 ?
                                                    this.props.roundToTwoDecimals(((this.state.items.reduce((sum, current) => {
                                                        let res = sum + (current.newPrice - current.oldPrice) * current.ammount
                                                        return res
                                                    }, 0))), true, true)

                                                    :
                                                    '00,00'
                                            }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters list-builder-filters-nivelation">
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterNivelationNumber} onChange={(e) => this.setState({ filterNivelationNumber: e.target.value }, () => {
                                                    this.updateParams('nivelationNumber', this.state.filterNivelationNumber)
                                                })} placeholder={'Broj nivelacije'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} placeholder={'Datum od'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} placeholder={'Datum do'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKind} onChange={(e) => this.setState({ filterKind: e.target.value }, () => {
                                                    this.updateParams('kind', this.state.filterKind)
                                                })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterDocNumber} onChange={(e) => this.setState({ filterDocNumber: e.target.value }, () => {
                                                    this.updateParams('documentNumber', this.state.filterDocNumber)
                                                })} placeholder={'Broj dokumenta izlaza/ulaza'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterSubKind} onChange={(e) => this.setState({ filterSubKind: e.target.value }, () => {
                                                    this.updateParams('subKind', this.state.filterSubKind)
                                                })} placeholder={'Podvrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterType} onChange={(e) => this.setState({ filterType: e.target.value }, () => {
                                                    this.updateParams('type', this.state.filterType)
                                                })} placeholder={'Tip'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterArticle} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                    this.updateParams('article', this.state.filterArticle)
                                                })} placeholder={'Artikal'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterNivelationNumber: '',
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterKind: '',
                                                filterDocNumber: '',
                                                filterSubKind: '',
                                                filterType: '',
                                                filterArticle: '',
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'nivelationNumber', value: null }, { name: 'dateFrom', value: null }, { name: 'dateTo', value: null },
                                                { name: 'kind', value: null }, { name: 'documentNumber', value: null }, { name: 'subKind', value: null }, { name: 'type', value: null },
                                                { name: 'article', value: null },
                                                ])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }



                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/nivelation', title: 'Nivelacija' }}

                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'nivelationDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'class', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'base', label: 'OSNOV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'subClass', label: 'PODVRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'type', label: 'TIP'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammount', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}

                                onClick={(item) => {
                                    if (item && item.documentNumber) {
                                        this.setState({ nivelationForm: null, buttonActive: 2 }, () => {
                                            this.setState({ nivelationForm: { documentNumber: item.documentNumber } }, () => {
                                                this.remoteOnChange('documentNumber', item.documentNumber)
                                            })
                                        })
                                    }
                                }}

                                items={this.state.nivelationItems.map((item, idx) => {
                                    // let total = item.items.reduce((sum, current)=>{
                                    //     let res = sum + current.total
                                    //     return res
                                    // },0)
                                    return {
                                        ...item,
                                        time: this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm'),
                                        // total1:total
                                    }
                                })}
                                rawItems={this.state.nivelationItems}

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </>
                }

                {
                    this.state.descriptionModal ?
                        <Modal isOpen={this.state.descriptionModal} size='lg' className="modal-wrap">
                            <ModalHeader>
                                <h4>Opis</h4>
                                <div className='close-button' onClick={() => this.setState({ descriptionModal: false, description: '' })}>&times;</div>
                            </ModalHeader>
                            <ModalBody>
                                <div className="dependent-costs-wrap">

                                    <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Label>Unesite opis</Label>
                                        <Input type='textarea' style={{ height: 100 }} value={this.state.description} onChange={(e) => {
                                            let value = e.target.value;

                                            this.setState({ description: value })
                                        }}>

                                        </Input>
                                    </FormGroup>
                                </div>
                            </ModalBody>
                            <ModalFooter>


                                <Button
                                    onClick={() => {
                                        if (this.state.nivelationForm && this.state.nivelationForm._id) {
                                            this._apiAsync('update-document', { description: this.state.description, _id: this.state.nivelationForm._id }).then((res) => {

                                                if (res.res && res.res._id) {
                                                    this.setState({ nivelationForm: null }, () => {

                                                        this.setState({ nivelationForm: res.res, items: res.res.items && res.res.items.length ? res.res.items : [], products: [] })
                                                    })
                                                    if (res.res.items && res.res.items.length) {
                                                        // this.remoteOnChange('item.itemNumber', (res.res.items.length + 1))
                                                        this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                                                            if (res && res.firstFreeItemNumber) {
                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                            }

                                                        })
                                                    } else {
                                                        this.remoteOnChange('item.itemNumber', 1)
                                                    }

                                                }
                                            })

                                        }
                                        this.setState({ descriptionModal: null })

                                    }}>Snimi</Button>
                            </ModalFooter>
                        </Modal>
                        :
                        null

                }



                <DeleteModal
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => {

                        this._apiAsync('remove-item', { _id: this.state.nivelationForm._id, itemNumber: this.state.deleteModal.itemNumber }).then((res) => {
                            if (res.result && res.result._id) {
                                this.setState({ nivelationForm: null, deleteModal: null }, () => {
                                    this.setState({ nivelationForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                                })
                                if (res.result.items && res.result.items.length) {
                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                        if (res && res.firstFreeItemNumber) {
                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                        }

                                    })
                                } else {
                                    this.remoteOnChange('item.itemNumber', 1)
                                }

                            }
                        })
                    }} >
                    Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                </DeleteModal>




                <DeleteModal
                    isOpen={this.state.deleteDocumentModal}
                    toggle={() => this.setState({ deleteDocumentModal: null })}
                    handler={() => {
                        if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                            this.setState({ deleteDocumentModal: null })
                            this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                            return;
                        } else {
                            this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                this.setState({ deleteDocumentModal: null, nivelationForm: null, items: [], firstFreeNumber: null }, () => {
                                    this.setState({ nivelationForm: {} }, () => this._onLoad())
                                })

                            })
                        }
                    }} >
                    Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                </DeleteModal>

            </div>
        )
    }
}

export default Page(Entry);
