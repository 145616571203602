import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col, Input, Button
} from 'reactstrap';
import printIcon from '../../assets/print.svg';
import { Player } from "@lottiefiles/react-lottie-player";
import checklistIcon from '../../assets/checklist.svg';
class BuyerReturnPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            step1: 1,
            items: [],
            documents: [],
            outputsItems: [],
            buyerReturnForm: {},
            articles: [],
            series: [],
            bookNoticeItems: [],
            buttonActive: 2,
            buyerReturnItems: [],
            rabats: []
        }
    }

    componentDidMount() {
        // this._onLoad();

        if (this.props[0].location && this.props[0].location.search) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') !== -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('factureNumber', Number(checkDocNumber))
                }

            }

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }

        if (this.state.buyerReturnForm && prevState.buyerReturnForm && this.state.buyerReturnForm.returningReason && !prevState.buyerReturnForm.returningReason) {
            this._apiAsync('checkNextItemNumber', { factureNumber: this.state.buyerReturnForm.factureNumber }).then((res) => {
                if (res && res.firstFreeItemNumber) {
                    this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                }

            })
        }
    }

    printOutput = async (data) => {
        let print = await this._apiAsync('printPovartKupca', { documentNumber: data.documentNumber })
        if (print) {
            this.setState({
                loadingExport: false
            })
        }
    }

    calculatePrice = (value) => {
        let outputs = this.state.outputs.filter(item => item.documentNumber === this.state.prevOutputs)[0]
        if (outputs && outputs.items) {
            for (let i = 0; i < outputs.items.length; i++) {
                if (outputs.items[i] && outputs.items[i].article === this.registerGetValue('item.article')) {
                    if (Number(outputs.items[i].ammount) < Number(value)) {
                        this.setState({
                            ammoutnError: `Prekoračili ste količinu sa fakture koja iznosi ${outputs.items[i].ammount}.`,
                            maxAmmoutn: outputs.items[i].ammount
                        })
                        this.remoteOnChange('item.price', '')
                        this.remoteOnChange('item.discount', '')
                        this.remoteOnChange('item.total', '')
                    } else {
                        this.remoteOnChange('item.price', outputs.items[i].price)
                        this.remoteOnChange('item.discount', outputs.items[i].discount)
                        if (outputs.items[i].discount) {
                            this.remoteOnChange('item.total', outputs.items[i].price * value * (1 - outputs.items[i].discount / 100))
                        } else {
                            this.remoteOnChange('item.total', outputs.items[i].price * value)
                        }
                    }

                }
            }
        }



    }

    getBuyerReturn = async (number = false) => {
        let outputs = false
        let value = false
        if (number) {
            outputs = await this._apiAsync('get-output', { filter: number })
            value = outputs?.outputsItems[0]?.documentNumber
        } else {
            value = this.registerGetValue('factureNumber')
            outputs = await this._apiAsync('get-output', { filter: value })
        }
        if (outputs && outputs.outputsItems && value) {

            this.setState({ outputs: outputs && outputs.outputsItems }, async () => {

                if (this.state.outputs && this.state.outputs.length && this.state.outputs.filter(item => item.documentNumber === value).length) {
                    const articles = await this._apiAsync('get-articles', { article: [...this.state.outputs.filter(item => item.documentNumber === value)[0].items.map(item => { return item.article })] })
                    if (articles && articles.articleItems) {
                        this.setState({ articles: articles && articles.articleItems })
                    }
                }

                const singleBuyerReturn = await this._apiAsync('get-single-buyer-return', { factureNumber: Number(value) })
                if (singleBuyerReturn && singleBuyerReturn.result) {
                    this.setState({ singleBuyerReturn: singleBuyerReturn && singleBuyerReturn.result })
                }
                if (this.state.singleBuyerReturn && this.state.singleBuyerReturn.items) {

                    const articles = await this._apiAsync('get-articles', { article: this.state.singleBuyerReturn.items.map(item => { return item.article }) })
                    if (articles && articles.articleItems) {
                        this.setState({ articles: articles && articles.articleItems }, () => {

                        })
                    }

                    this._apiAsync('checkNextItemNumber', { factureNumber: Number(value) }).then((res) => {
                        if (res && res.firstFreeItemNumber) {
                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                        }

                    })

                    this.setState({
                        buyerReturnForm: null
                    }, () => {
                        this.setState({
                            buyerReturnForm: this.state.singleBuyerReturn,
                            items: this.state.singleBuyerReturn.items
                        })
                    })
                } else {
                    if (this.state.outputs.filter(item => item.documentNumber === value).length) {

                        this.setState({
                            buyerReturnForm: null
                        }, () => {
                            this.setState({
                                buyerReturnForm: {
                                    business: this.state.outputs.filter(item => item.documentNumber === value)[0].business,
                                    businessName: this.state.outputs.filter(item => item.documentNumber === value)[0].businessName,
                                    clientCode: this.state.outputs.filter(item => item.documentNumber === value)[0].clientCode,
                                    clientName: this.state.outputs.filter(item => item.documentNumber === value)[0].clientName,
                                    factureDate: this.state.outputs.filter(item => item.documentNumber === value)[0].factureDate,
                                    outputID: this.state.outputs.filter(item => item.documentNumber === value)[0]._id,
                                    // items: this.state.outputs.filter(item => item.documentNumber === value)[0].items?.map((item) => {
                                    //     return {
                                    //         article: item.article,
                                    //         deadline: item.deadline,
                                    //         serialNumber: item.serialNumber
                                    //     }
                                    // }),
                                    factureNumber: Number(this.state.outputs.filter(item => item.documentNumber === value)[0].documentNumber),
                                },
                            })
                        })


                    } else {
                        this.setState({
                            buyerReturnForm: null
                        }, () => {
                            this.setState({
                                buyerReturnForm: {}
                            })
                        })
                    }
                }

                this.setState({
                    prevOutputs: value
                })

            })
        }
    }

    metoda = async (value) => {
        let articleList = {
            articlesSearch: []
        }
        if (this.state.outputs && this.state.outputs.length && this.state.outputs.filter(item => item.documentNumber === this.state.buyerReturnForm.factureNumber).length) {            // console.log('aaaaaaaaaaaaa');
            articleList = await this._apiAsync('ariclesSearch', { value: value, article: [...this.state.outputs.filter(item => item.documentNumber === this.state.buyerReturnForm.factureNumber)[0].items.map(item => { return item.article })] })

        }
        this.setState({ articlesSearch: articleList.articlesSearch })
    }

    onClickBuyerReturn = async (item) => {
        this.setState({
            buyerReturnForm: null
        }, () => {
            this.setState({
                buyerReturnForm: item,
                items: item.items,
                step1: 2,
                buttonActive: 2
            }, () => {
                // console.log(this.state.buyerReturnForm);
                this.getBuyerReturn(this.state.buyerReturnForm.factureNumber)
            })
        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Povrat kupca</h1>

                </div>
                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive === 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1 }, () => this._onLoad())}>
                            Lista povrata kupca
                        </button>
                        <button className={this.state.buttonActive === 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive !== 2) {
                                this.setState({ buttonActive: 2, step1: 1 })
                            }
                        }}>
                            Povrat kupca
                        </button>
                    </div>

                </div>

                {
                    this.state.buttonActive === 1 ?
                        <>


                            <div className="list-builder-filters-wrap">
                                <div className="list-builder-filters">
                                    <div className="field-strap-wrap">
                                        <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                            this.updateParams('dateFrom', this.state.filterDateFrom)
                                        })} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                            this.updateParams('dateTo', this.state.filterDateTo)
                                        })} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.documentNumber} onChange={(e) => this.setState({ documentNumber: e.target.value }, () => {
                                            this.updateParams('documentNumber', this.state.documentNumber)
                                        })} placeholder={'Broj dokumenta'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterAccountNumber} onChange={(e) => this.setState({ filterAccountNumber: e.target.value }, () => {
                                            this.updateParams('accountNumber', this.state.filterAccountNumber)
                                        })} placeholder={'Broj računa'} />
                                    </div>

                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                            this.updateParams('client', this.state.filterClient)
                                        })} placeholder={'Komitent'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterArticle} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                            this.updateParams('article', this.state.filterArticle)
                                        })} placeholder={'Artikal'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                            this.updateParams('user', this.state.filterUser)
                                        })} placeholder={'Korisnik'} />
                                    </div>
                                </div>
                                <div className="clear-filters" onClick={() => {
                                    this.setState({
                                        filterDateFrom: '',
                                        filterDateTo: '',
                                        filterAccountNumber: '',
                                        filterClient: '',
                                        filterArticle: '',
                                        filterUser: '',
                                        documentNumber: ''
                                    }, () => {
                                        this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'documentNumber', value: null }, { name: 'dateTo', value: null }, { name: 'article', value: null }, { name: 'accountNumber', value: null }, { name: 'client', value: null }, { name: 'user', value: null }])

                                    })
                                }}>
                                    <a><span>&times;</span> Ukloni filter</a>
                                </div>
                            </div>

                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'factureDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'price', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}
                                items={this.state.buyerReturnItems.map(item => {
                                    let value = 0
                                    if (item.items.length)
                                        for (let i = 0; i < item.items.length; i++) {
                                            value += item.items[i].total

                                        }
                                    return {
                                        ...item,
                                        documentNumber: <div onClick={() => this.onClickBuyerReturn(item)}>{item.documentNumber}</div>,
                                        time: item.tsCreated ? this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY. HH:mm') : '',
                                        price: value && value.toFixed ? value.toFixed(2) : value
                                    }
                                })}
                                actions={
                                    [
                                        {
                                            component: <Button style={{ background: '#138DB9' }}><Isvg src={printIcon} /></Button>,
                                            onClick: (item) => {
                                                this.setState({ printHtml: null, loadingExport: true }, () => this.printOutput(item))
                                            }
                                        }
                                    ]
                                }

                                rawItems={this.state.buyerReturnItems}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                        </>
                        :
                        <div>
                            <Container fluid>

                                <div className='line-container'>
                                    <div className='progress-line'>
                                        <div className='progress' style={this.state.step1 === 1 ? { width: '0' } : this.state.step1 === 2 ? { width: '20%' } : this.state.step1 === 3 ? { width: '40%' } : this.state.step1 === 4 ? { width: '60%' } : this.state.step1 === 5 ? { width: '80%' } : {}}>

                                        </div>
                                        <div className='status'>
                                            <div className={this.state.step1 >= 1 ? 'dot completed' : 'dot'}>

                                            </div>
                                        </div>
                                        <div className='status'>
                                            <div className={this.state.step1 >= 2 ? 'dot completed' : 'dot'}>

                                            </div>
                                        </div>
                                        <div className='status'>
                                            <div className={this.state.step1 >= 3 ? 'dot completed' : 'dot'}>

                                            </div>
                                        </div>
                                        <div className='status'>
                                            <div className={this.state.step1 >= 6 ? 'dot completed' : 'dot'}>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.step1 === 1 ?

                                        <FormBuilder
                                            registerSubmitAction={(submit) => this.submit = submit}
                                            registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                            registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                            registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                            classNameForm={"add-form-container entrance-custom-col"}
                                            addButtonText={'Dodaj'.translate(this.props.lang)}
                                            fieldsToTrack={['factureNumber']}
                                            fieldTracker={async (name, value) => {

                                                if (name === 'factureNumber' && !value) {
                                                    this.setState({
                                                        buyerReturnForm: null
                                                    }, () => {
                                                        this.setState({
                                                            buyerReturnForm: {}
                                                        })
                                                    })
                                                }
                                            }}

                                            onSubmit={(data, notFinished) => {
                                                this.setState({
                                                    step1: this.state.step1 + 1,
                                                    buyerReturnForm: data
                                                }, () => {
                                                    this._apiAsync('checkNextItemNumber', { factureNumber: this.state.buyerReturnForm.factureNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        }

                                                    })
                                                })

                                            }}
                                            initialValues={this.state.buyerReturnForm}
                                            fields={[

                                                {
                                                    type: 'col',
                                                    width: { lg: 7, sm: 12, xs: 12 },
                                                    offset: { lg: 2, sm: 0, xs: 0 },
                                                    style: { marginTop: 'calc(50vh - 400px)' },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'factureNumber',
                                                                            textType: 'number',
                                                                            enterCalFunction: true,
                                                                            enterCall: () => this.getBuyerReturn(),
                                                                            next: 'returningReason',
                                                                            label: 'Broj fakture',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value === '') return "Broj dokumenta mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]

                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'factureDate',
                                                                            readOnly: true,
                                                                            label: 'Datum fakture',
                                                                        },
                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            readOnly: true,
                                                                            label: 'Komitent',
                                                                        },
                                                                    ]

                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessName',
                                                                            readOnly: true,
                                                                            label: 'Poslovna',
                                                                            asyncValidation: true,

                                                                        },
                                                                    ]

                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'returningReason',
                                                                            finishField: true,
                                                                            next: this.state.buyerReturnForm?.factureNumber ? 'submitForm' : '',
                                                                            label: 'Razlog povrata',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value === '') return "Razlog povrata mora biti unešen!" }
                                                                            ]

                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 9, sm: 9, xs: 9 },
                                                                    children: [

                                                                    ]
                                                                },
                                                                this.state.buyerReturnForm?.factureNumber ?
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 3, sm: 3, xs: 3 },
                                                                        style: { display: 'flex', justifyContent: 'flex-end' },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                children: <div className="blue-button next-button" onClick={() => {
                                                                                    this.submit()
                                                                                }}>
                                                                                    <p>Dalje</p>
                                                                                </div>,
                                                                                className: 'options-button width-100',
                                                                            },
                                                                        ]

                                                                    }
                                                                    : null,
                                                            ]
                                                        },

                                                    ]

                                                },


                                            ]}
                                        ></FormBuilder>

                                        :

                                        null

                                }
                                {
                                    this.state.step1 === 2 ?

                                        <FormBuilder
                                            registerSubmitAction={(submit) => this.submit = submit}
                                            classNameForm={"add-form-container buyer-return"}
                                            registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                            registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                            registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                            addButtonText={'Dodaj'.translate(this.props.lang)}
                                            fieldsToTrack={['item.article', 'item.ammount']}
                                            fieldTracker={async (name, value) => {
                                                if (name === 'item.ammount') {
                                                    if (value) {
                                                        this.calculatePrice(value)
                                                    } else {
                                                        this.remoteOnChange('item.price', '')
                                                        this.remoteOnChange('item.discount', '')
                                                        this.remoteOnChange('item.total', '')
                                                    }
                                                }
                                                if (name === 'item.article' && value) {
                                                    const stock = await this._apiAsync('get-stock', { article: value })
                                                    if (stock && stock.result) {
                                                        this.setState({
                                                            stock: stock && stock.result
                                                        }, () => {
                                                            this.remoteOnChange('item.supply', `${this.state.stock.quantity}`)

                                                        })
                                                    }

                                                    const fond = await this._apiAsync('get-fond', { article: value, client: this.state.buyerReturnForm && this.state.buyerReturnForm.clientCode })
                                                    if (fond && fond.result) {
                                                        this.setState({
                                                            fond: fond && fond.result
                                                        }, () => {
                                                            this.remoteOnChange('item.fondPrice', `${this.state.fond.price}`)

                                                        })
                                                    }


                                                    if (this.state.outputs && this.state.outputs.length && this.state.outputs.filter(ent => ent.documentNumber === this.state.prevOutputs).length) {
                                                        let item = this.state.outputs.filter(ent => ent.documentNumber === this.registerGetValue('factureNumber'))[0].items.filter(prod => Number(prod.article) === Number(value))[0]

                                                        if (item && item.serialNumber) {
                                                            this.remoteOnChange('item.serialNumber', item.serialNumber)
                                                        }
                                                        if (item && item.deadline) {
                                                            this.remoteOnChange('item.deadline', item.deadline)
                                                        }
                                                        if (item && item.buyPrice) {
                                                            this.remoteOnChange('item.facturePrice', item.buyPrice)
                                                        }

                                                    }
                                                    this.remoteOnChange('item.ammount', '')
                                                    this.remoteOnChange('item.price', '')
                                                    this.remoteOnChange('item.discount', '')
                                                    this.remoteOnChange('item.total', '')
                                                }

                                            }}
                                            onSubmit={(data) => {
                                                if (data.item && data.item.article) {
                                                    data.item.article = Number(data.item.article)
                                                }
                                                if (data.item && data.item.discount) {
                                                    data.item.discount = Number(data.item.discount)
                                                }
                                                if (data.item && data.item.supply) {
                                                    data.item.supply = Number(data.item.supply)
                                                }
                                                if (data.item && data.item.ammount) {
                                                    data.item.ammount = Number(data.item.ammount)
                                                }
                                                if (data.item && data.item.total) {
                                                    data.item.vat = 17
                                                    data.item.totalVat = Number(data.item.total) * (data.item.vat / 100)
                                                }

                                                if (data._id) {
                                                    this._apiAsync('update-document', data).then((res) => {
                                                        if (res.result && res.result.id) {
                                                            this.setState({ buyerReturnForm: null }, () => {
                                                                this.setState({
                                                                    buyerReturnForm: {
                                                                        _id: res.result.id,
                                                                        ...res.result.result
                                                                    }, items: res.result.result.items && res.result.result.items.length ? res.result.result.items : []
                                                                }, () => {

                                                                })
                                                            })
                                                            if (res.result.result.items && res.result.result.items.length) {
                                                                this._apiAsync('checkNextItemNumber', { factureNumber: res.result.result.factureNumber }).then((res) => {
                                                                    if (res && res.firstFreeItemNumber) {
                                                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                    }

                                                                })
                                                            } else {
                                                                this.remoteOnChange('item.itemNumber', 1)
                                                            }

                                                        }
                                                    })
                                                } else {
                                                    this._apiAsync('insert-new', data).then((res) => {
                                                        if (res.result && res.result.id) {
                                                            this.setState({ buyerReturnForm: null }, () => {
                                                                this.setState({
                                                                    buyerReturnForm: {
                                                                        _id: res.result.id,
                                                                        ...res.result.result
                                                                    }, items: res.result.result.items && res.result.result.items.length ? res.result.result.items : []
                                                                })
                                                            })
                                                            if (res.result.result.items && res.result.result.items.length) {
                                                                this._apiAsync('checkNextItemNumber', { documentNumber: res.result.result.documentNumber }).then((res) => {
                                                                    if (res && res.firstFreeItemNumber) {
                                                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                    }

                                                                })
                                                            } else {
                                                                this.remoteOnChange('item.itemNumber', 1)
                                                            }

                                                        }
                                                    })
                                                }
                                            }}
                                            initialValues={this.state.buyerReturnForm}
                                            fields={[
                                                {
                                                    type: 'block',
                                                    children: [
                                                        {
                                                            type: 'col',
                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                            className: "custom-input-width buyer-return-row",
                                                            children: [
                                                                {
                                                                    type: 'text',
                                                                    name: 'item.itemNumber',
                                                                    textType: 'number',
                                                                    readOnly: true,
                                                                    label: 'Redni broj.',
                                                                    asyncValidation: true,
                                                                    validate: [(value) => {
                                                                        if (!value || value === '')
                                                                            return "Redni broj mora biti unesen!"
                                                                    }]
                                                                },
                                                                {
                                                                    type: 'article',
                                                                    name: 'item.article',
                                                                    next: 'item.ammount',
                                                                    metoda: (value) => { this.metoda(value) },
                                                                    dataModal: this.state.articlesSearch,
                                                                    showName: true,
                                                                    values: [{ name: '', value: -1 }, ...this.state.articles.map(item => {
                                                                        let obj = {
                                                                            name: item.name,
                                                                            value: item.code
                                                                        }
                                                                        return obj
                                                                    })],
                                                                    label: 'Artikal',
                                                                    finishField: true,
                                                                    asyncValidation: true,
                                                                    validate: [
                                                                        (value) => {
                                                                            let item = this.registerGetValue('item')
                                                                            let checkValidation = false;
                                                                            if (item && Object.keys(item).length > 1) {
                                                                                checkValidation = true;
                                                                            }
                                                                            if ((!value || value === '') && checkValidation)
                                                                                return "Artikal mora biti unesen!"
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'text',
                                                                    name: 'item.serialNumber',
                                                                    readOnly: true,
                                                                    label: 'Serijski broj',
                                                                    finishField: true,
                                                                    asyncValidation: true,
                                                                },
                                                                {
                                                                    type: 'text',
                                                                    name: 'item.deadline',
                                                                    readOnly: true,
                                                                    label: 'Rok',
                                                                    finishField: true,
                                                                    asyncValidation: true,
                                                                },
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                            className: 'input-group-custom',
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'item.ammount',
                                                                                    textType: 'number',
                                                                                    next: 'submitForm',
                                                                                    label: 'Količina',
                                                                                    finishField: true,
                                                                                    asyncValidation: true,
                                                                                    validate: [
                                                                                        (value) => {

                                                                                            if (!value || value === '') {
                                                                                                return "Količina mora biti unesena!"
                                                                                            } else if (Number(value) > Number(this.state.maxAmmoutn))
                                                                                                return this.state.ammoutnError
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'item.supply',
                                                                                    label: 'Zaliha',
                                                                                    readOnly: true,
                                                                                    finishField: true,
                                                                                    asyncValidation: true,
                                                                                },
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: { lg: 12, sm: 12, xs: 12 },
                                                                            className: 'input-group-custom',
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'item.price',
                                                                                    readOnly: true,
                                                                                    label: 'Cijena',
                                                                                    finishField: true,
                                                                                    asyncValidation: true,
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'item.fondPrice',
                                                                                    label: 'Fond cijena',
                                                                                    readOnly: true,
                                                                                    finishField: true,
                                                                                    asyncValidation: true,
                                                                                },
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'text',
                                                                    name: 'item.discount',
                                                                    label: 'Rabat',
                                                                    readOnly: true,
                                                                    finishField: true,
                                                                    asyncValidation: true,
                                                                },
                                                                {
                                                                    type: 'text',
                                                                    name: 'item.total',
                                                                    finishField: true,
                                                                    readOnly: true,
                                                                    label: 'Ukupno',
                                                                    asyncValidation: true,
                                                                },
                                                            ]

                                                        },
                                                    ]
                                                },
                                            ]}
                                        ></FormBuilder>

                                        :

                                        null
                                }
                            </Container>

                            {this.state.step1 === 2 ?
                                <div className="v-scroll mt-15">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        total={this.state.totalItems}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false },

                                        ]}

                                        actions={[]}

                                        items={this.state.items.map(item => {
                                            return {
                                                ...item,
                                                total: this.props.roundToTwoDecimals(item.total)
                                            }
                                        })}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>
                                :
                                null
                            }
                            {this.state.step1 === 2 ?
                                <div className="footer">
                                    <div className="footer-left">

                                    </div>
                                    <div className="footer-right">
                                        <div className="info-colored">
                                            <div className="col-info">
                                                <p>Osnovica</p>
                                                <span>
                                                    {
                                                        this.state.items.length !== 0 ?
                                                            this.props.roundToTwoDecimals(0.83 * (this.state.items.reduce((sum, current) => {
                                                                let res = sum + current.total
                                                                return res
                                                            }, 0)), true, true)
                                                            :
                                                            '00,00'
                                                    }
                                                    &nbsp;KM
                                                </span>
                                            </div>
                                            <div className="col-info">
                                                <p>PDV</p>
                                                <span>
                                                    {
                                                        this.state.items.length !== 0 ?
                                                            this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                                let res = sum + current.total
                                                                return res
                                                            }, 0))), true, true)
                                                            :
                                                            '00,00'
                                                    }
                                                    &nbsp;KM
                                                </span>
                                            </div>
                                            <div className="col-info">
                                                <p>Za plaćanje</p>
                                                <span>
                                                    {
                                                        this.state.items.length !== 0 ?
                                                            this.props.roundToTwoDecimals((this.state.items.reduce((sum, current) => {
                                                                let res = sum + current.total
                                                                return res
                                                            }, 0)), true, true)
                                                            :
                                                            '00,00'
                                                    }
                                                    &nbsp;KM
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {this.state.step1 === 2 ?
                                <div className='prev-next-buttons'>
                                    <button onClick={() => {
                                        this.setState({
                                            step1: this.state.step1 - 1
                                        })
                                    }} className="next-button">Nazad</button>

                                    <button disabled={this.state.items.length ? false : true} onClick={() => {
                                        this.setState({
                                            step1: this.state.step1 + 1
                                        }, async () => {
                                            const bookNoticeItems = await this._apiAsync('get-book-notice-items', { factureNumber: this.registerGetValue('factureNumber') })
                                            if (bookNoticeItems && bookNoticeItems.items) {
                                                let documents = this.state.documents;
                                                documents.push({
                                                    documentNumber: this.registerGetValue('factureNumber'),
                                                    name: 'Izlazna faktura'
                                                })
                                                this.setState({
                                                    bookNoticeItems: bookNoticeItems && bookNoticeItems.items,
                                                    documents
                                                }, () => {
                                                    if (this.state.bookNoticeItems) {

                                                        for (let i = 0; i < this.state.bookNoticeItems.length; i++) {
                                                            let documents = this.state.documents;
                                                            documents.push({
                                                                documentNumber: this.state.bookNoticeItems[i].koNumber,
                                                                name: 'Knjizna obavjest'
                                                            })
                                                            this.setState({
                                                                documents
                                                            })
                                                        }

                                                    }
                                                })
                                            }
                                        })
                                    }} className="next-button next-button-right">Dalje</button>


                                </div>
                                :
                                null
                            }

                            {
                                this.state.step1 === 3 ?
                                    <Row className="two-form-block">
                                        <Col lg="6" className="list-builder-left">
                                            <h6>Neproknjiženi KO</h6>
                                            <ListBuilder
                                                tabs={false}
                                                sort={false}
                                                hideFilters={true}
                                                lang={this.props.lang}
                                                total={this.state.total}
                                                showNumeration={false}
                                                hidePagination={true}
                                                fields={[
                                                    { type: 'text', name: 'koNumber', label: 'KO BR:'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'kind', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'subkind', label: 'PODVRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'beforeAmmount', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'newAmmount', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    { type: 'text', name: 'discountAmount', label: 'RAZLIKA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                ]}
                                                items={this.state.bookNoticeItems && this.state.bookNoticeItems.filter(val => val.proknjizeno === false).map(item => {
                                                    let beforeAmmount = 0
                                                    let newAmmount = 0;
                                                    let discountAmount = 0;

                                                    item.items.map(product => {
                                                        beforeAmmount += Number(product.beforeAmount);
                                                        newAmmount += Number(product.newAmount);
                                                        discountAmount += Number(product.discountAmount);
                                                    })
                                                    return {
                                                        ...item,
                                                        beforeAmmount: beforeAmmount,
                                                        newAmmount: newAmmount,
                                                        discountAmount: discountAmount
                                                    }
                                                })}
                                                params={params}
                                                sortField={params.sortField}
                                                sortType={params.sortType}
                                                updateSort={this.updateSort}
                                                updateParams={this.updateParams}
                                                updateMultipleParams={this.updateMultipleParams}
                                            ></ListBuilder>
                                        </Col>

                                        <Col lg="6" className="list-builder-right">
                                            <div>
                                                <h6>Proknjiženi KO</h6>
                                                <ListBuilder
                                                    tabs={false}
                                                    sort={false}
                                                    hideFilters={true}
                                                    lang={this.props.lang}
                                                    total={this.state.total}
                                                    showNumeration={false}
                                                    hidePagination={true}
                                                    fields={[
                                                        { type: 'text', name: 'koNumber', label: 'KO BR:'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        { type: 'text', name: 'kind', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        { type: 'text', name: 'subkind', label: 'PODVRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        { type: 'text', name: 'beforeAmmount', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        { type: 'text', name: 'newAmmount', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        { type: 'text', name: 'discountAmount', label: 'RAZLIKA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                    ]}
                                                    items={this.state.bookNoticeItems && this.state.bookNoticeItems.filter(val => val.proknjizeno === true).map(item => {
                                                        let beforeAmmount = 0
                                                        let newAmmount = 0;
                                                        let discountAmount = 0;

                                                        item.items.map(product => {
                                                            beforeAmmount += Number(product.beforeAmount);
                                                            newAmmount += Number(product.newAmount);
                                                            discountAmount += Number(product.discountAmount);
                                                        })
                                                        return {
                                                            ...item,
                                                            beforeAmmount: beforeAmmount,
                                                            newAmmount: newAmmount,
                                                            discountAmount: discountAmount
                                                        }
                                                    })}
                                                    params={params}
                                                    sortField={params.sortField}
                                                    sortType={params.sortType}
                                                    updateSort={this.updateSort}
                                                    updateParams={this.updateParams}
                                                    updateMultipleParams={this.updateMultipleParams}
                                                ></ListBuilder>
                                            </div>
                                        </Col>
                                        <Col lg='12'>
                                            <div className='prev-next-buttons'>
                                                <button className="next-button" onClick={() => {
                                                    this.setState({
                                                        step1: this.state.step1 - 1
                                                    })
                                                }}>Nazad</button>

                                                <button className="next-button" onClick={() => {
                                                    this.setState({
                                                        step1: this.state.step1 + 1
                                                    }, async () => {
                                                        const rabats = await this._apiAsync('get-naturalni-rabat-items', { factureNumber: this.registerGetValue('factureNumber') })
                                                        if (rabats && rabats.items) {
                                                            this.setState({
                                                                rabats: rabats && rabats.items,
                                                            }, () => {
                                                                if (this.state.rabats) {
                                                                    for (let i = 0; i < this.state.rabats.length; i++) {
                                                                        let documents = this.state.documents;
                                                                        documents.push({
                                                                            documentNumber: this.state.rabats[i].documentNumber,
                                                                            name: 'Naturalni rabat'
                                                                        })
                                                                        this.setState({
                                                                            documents
                                                                        })
                                                                    }

                                                                }
                                                            })
                                                        }
                                                    })
                                                }}>Dalje</button>
                                            </div>
                                        </Col>



                                    </Row>
                                    :
                                    null
                            }

                            {
                                this.state.step1 === 4 ?
                                    <Col lg="4" style={{ margin: 'auto' }}>
                                        <Row className="one-form-block collection-order">
                                            <div className="approval-document">
                                                <div className="approval-document-header">
                                                    <Isvg src={checklistIcon} />
                                                    <h6>Povrat kupca broj {this.state.prevOutputs} je uspiješno izvršen</h6>
                                                </div>
                                                <hr style={{ marginTop: 35 }} />
                                                <div className="approval-document-body">
                                                    <h6>Realizovani dokumenti</h6>
                                                    <ListBuilder
                                                        tabs={false}
                                                        sort={false}
                                                        hideFilters={true}
                                                        lang={this.props.lang}
                                                        total={this.state.total}
                                                        showNumeration={false}
                                                        hidePagination={true}
                                                        fields={[
                                                            { type: 'text', name: 'documentNumber', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                            { type: 'text', name: 'name', label: 'NAZIV DOKUMENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                                        ]}
                                                        items={this.state.documents.map(item => {
                                                            return {
                                                                ...item,
                                                            }
                                                        })}
                                                        params={params}
                                                        sortField={params.sortField}
                                                        sortType={params.sortType}
                                                        updateSort={this.updateSort}
                                                        updateParams={this.updateParams}
                                                        updateMultipleParams={this.updateMultipleParams}
                                                    ></ListBuilder>
                                                    <Button onClick={() => {
                                                        this.setState({
                                                            step1: this.state.step1 - 1
                                                        })
                                                    }}>Nazad</Button>

                                                    <Button onClick={() => {
                                                        this.setState({
                                                            step1: 1
                                                        }, () => {
                                                            this.setState({
                                                                documents: [],
                                                                bookNoticeItems: [],
                                                                rabats: [],
                                                                items: [],
                                                                buyerReturnForm: null
                                                            }, () => {
                                                                this.setState({
                                                                    buyerReturnForm: {}
                                                                })
                                                            })
                                                        })
                                                    }}>Zavrsi</Button>

                                                </div>
                                            </div>
                                        </Row>
                                    </Col>

                                    :
                                    null
                            }
                        </div>
                }

                {
                    this.state.loadingExport ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/loader.json"
                                style={{ height: typeof window !== 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window !== 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }
            </div >
        )
    }
}

export default Page(BuyerReturnPage);
