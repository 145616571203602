import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-business': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'check-client-exists-in-business-units': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units/check/' + query.clientId, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'get-reports': {
        onLoad: false,
        action: (query) => {
            // console.log(query, "sssssssssssss")
            return fetch(env.API_URL + '/data/reports/analysis', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,

                        checkProduct: query && query.checkProduct,
                        checkGroup: query && query.checkGroup,
                        checkRegion: query && query.checkRegion,
                        checkPlace: query && query.checkPlace,
                        checkSubGroup: query && query.checkSubGroup,

                        checkKind: query && query.checkKind,
                        checkManufacturer: query && query.checkManufacturer,
                        checkClient: query && query.checkClient,
                        checkBusiness: query && query.checkBusiness,
                        checkClientCode: query && query.checkClientCode,

                        checkBusinessUnit: query && query.checkBusinessUnit,
                        checkTypeOfChange: query && query.checkTypeOfChange,

                        region: query && query.region,
                        clientCode: query && query.clientCode,
                        place: query && query.place,

                        business: query && query.business,
                        groups: query && query.groups,
                        products: query && query.products,
                        subGroups: query && query.subGroups,

                        checkTotal: query && query.checkTotal,
                        checkTotalPerMonth: query && query.checkTotalPerMonth,


                        buttonType: query && query.typeButton
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    // console.log(result, "pppppppppppppppp")
                    return {
                        items: result.items,
                        total: result.total,
                        totalAmmount: result.totalAmmount,
                        sum: result.sum,
                        sumByMonths: result.sumByMonths,
                        ammountByMonths: result.ammountByMonths
                    }
            })
        }
    },
    'get-reports-print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/analysis/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,

                        checkProduct: query && query.checkProduct,
                        checkGroup: query && query.checkGroup,
                        checkRegion: query && query.checkRegion,
                        checkPlace: query && query.checkPlace,
                        checkSubGroup: query && query.checkSubGroup,

                        checkKind: query && query.checkKind,
                        checkManufacturer: query && query.checkManufacturer,
                        checkClient: query && query.checkClient,
                        checkBusiness: query && query.checkBusiness,
                        checkClientCode: query && query.checkClientCode,
                        checkFilters: query && query.checkFilters,

                        checkBusinessUnit: query && query.checkBusinessUnit,
                        checkTypeOfChange: query && query.checkTypeOfChange,

                        region: query && query.region,
                        clientCode: query && query.clientCode,
                        place: query && query.place,

                        business: query && query.business,
                        groups: query && query.groups,
                        products: query && query.products,
                        subGroups: query && query.subGroups,

                        checkTotal: query && query.checkTotal,
                        checkTotalPerMonth: query && query.checkTotalPerMonth,


                        buttonType: query && query.typeButton
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        message: result.message
                    }
            })
        }
    },
    'get-place': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/places', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

}