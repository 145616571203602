import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import Isvg from 'react-inlinesvg';
import arrow from '../../assets/arrow-down.svg';

class DropdownSelectNew extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            openDropdown: false
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ openDropdown: false })
        }
    }

    render() {
        return (
            <FormGroup className={`position-relative ${this.props.className ? this.props.className : ''}`} style={{ marginBottom: 0 }}>
                <div className="dropdown-select-field" ref={this.wrapperRef}>
                    { this.props.label + ' ' + this.props.content}

                </div>


            </FormGroup>
        )
    }
}

export default DropdownSelectNew;