import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    
    'set-kron-data': {
        onLoad: false,
        action: (query) => {
            // console.log(query)
            return fetch(env.API_URL + '/data/reports/icm', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    semdicno: query.sedmicnoKron,
                    mjesecno: query.mjesecnoKron,
                    dateFrom: query.datumOd,
                    dateTo: query.datumDo,
                    sedmicnoAutomatski: query.sedmicnoKronUkljuceno,
                    mjesecnoAutomatski: query.mjesecnoKronUkljuceno

                })
            }).then(parseJSON).then(({ result, status }) => {
                
            })
        }
    },

    'get-kron-data': {
        onLoad: true,
        action: (lang, match, query) => {
            // console.log(query)
            return fetch(env.API_URL + '/data/reports/icm', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

            }).then(parseJSON).then(({ result, status }) => {
                
                return {
                    items: result,
                    
                }
            })
        }
    },

}