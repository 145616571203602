import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input
} from 'reactstrap';

import error from '../assets/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>

                <ModalBody className="delete-modal">
                    <Label>{'Opis dokumenta'}</Label>
                    <Input type='textarea' rows='6' value={this.props.value} onChange={(e) => {
                        this.props.onChange(e.target.value)
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'Zatvori'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;