import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import error from '../assets/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    keypressHandler = (event) => {
        if (!this.props.isOpen){
            return;
        }
        
        if (event.key == 'Enter'){
            this.props.toggle();
        }

        if (event.key == 'Escape' ){
            this.props.toggle();
        }

    }


    componentDidMount(){
        document.addEventListener('keyup', this.keypressHandler, false);
    }

    componentWillUnmount(){
        document.removeEventListener('keyup', this.keypressHandler);
    }


    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>

                <ModalBody className="delete-modal">
                    <img src={error} />
                    <div className="message" style={{ fontSize: 18 }}>
                        {
                            this.props.error
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'Ok'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;