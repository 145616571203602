import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';

import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number } from '../../form-builder/validation';

class Funds extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            groupsList: true,
            groupsForm: false,
            total: 20,
            items: [],
            places: [],
            form: null,
            deleteModal: null,
            loading: false
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }


    render() {
        console.log('fond ', this.state.form);
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Fond</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati fond <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container toogle-top-0 row row"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    if (this.state.form && this.state.form._id) {
                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => {
                                                this._onLoad();
                                            })
                                        });
                                    } else {
                                        this._api('api2', { data: data }, () => {
                                            this.setState({ clientForm: {} }, () => {
                                                this._onLoad()
                                            })
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Dodaj fond'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        next: 'name',
                                                                        label: 'Šifra',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra mora biti unešena!'),
                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        let res = await this._apiAsync('validate', {
                                                                                            value: value,
                                                                                            id: this.state.form ? this.state.form._id : null
                                                                                        });
                                                                                        if (res.status == 400) {
                                                                                            return 'Šifra je zauzeta'
                                                                                        }
                                                                                        return undefined;
                                                                                    }
                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                            number('Mora biti broj')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 9, sm: 9, xs: 9 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        label: 'Naziv',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv mora biti unešen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            ...this.state.places.map((item) => {
                                                                return {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'places.' + item._id,
                                                                            label: <>{item.name}</>,
                                                                            key: item.code,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                async (value) => {
                                                                                    if (value) {

                                                                                        for (let i = 0; i < this.state.items.length; i++) {
                                                                                            const currentItem = this.state.items[i];

                                                                                            if (currentItem._id === this.state.form._id) {
                                                                                                continue;
                                                                                            }
                                                                                            if (currentItem.places && currentItem.places[item._id]) {
                                                                                                return 'Mjesto je već izabrano za fond ' + currentItem.name;
                                                                                            }
                                                                                        }

                                                                                        return undefined;
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            })
                                                        ]
                                                    },
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            />
                        </Col>
                        <Col lg="6">
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista fondova'}
                                    hideResults={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    ]}
                                    items={this.state.items}
                                    actions={[
                                        {
                                            component: <Isvg src={editIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }))
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Page(Funds);
