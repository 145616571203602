import React from 'react';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from './link';
import Page from '../containers/page';


class RouteComponent extends React.Component {

  
    _onLoad = () => {
        
        if (!this.props.api) {
            return;
        }
        let loading = false;
        for (var apiId in this.props.api) {
            if (this.props.api[apiId] && this.props.api[apiId].onLoad) {
                loading = true;
            }
        }
        this.setState({
            loading
        }, () => {
            for (var apiId in this.props.api) {
                if (this.props.api[apiId] && this.props.api[apiId].onLoad) {
                    this.props.api[apiId].action(this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                        this.setState({
                            ...data,
                            loading: false
                        }, () => {
                            if (this.props.updateMeta(this.props.seo(this.state, this.props.lang)));
                        })
                    })
                }
            }
        })

    }

    _api = (apiId, data, callback) => {
        if (!this.props.api) {
            return;
        }

        if (!this.props.api[apiId]) {
            return;
        }
        // console.log(this.props.api[apiId])
        this.props.api[apiId].action(data).then((response) => {
            if (callback)
                callback(response);
        })


    }

    _apiAsync = (apiId, data) => {
        return new Promise((resolve, reject) => {
            if (!this.props.api) {
                resolve(null);
            }

            if (!this.props.api[apiId]) {
                resolve(null);
            }
            // console.log(this.props.api[apiId])
            this.props.api[apiId].action(data).then((response) => {
                resolve(response);
            })
        })
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    _getParams = () => {
        let params = {};

        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        return params;
    }
}

export default RouteComponent;
