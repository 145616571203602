import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    active: query.active,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

    'article-state': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        productsItems: result.items,
                    }
            })
        }
    },

    'api2': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/products/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                })
            }).then(parseJSON).then(({ result, status }) => {
              
                if (status >= 200 && status < 300)
                    return {
                        printHtml: result.html,
                    }
            })
        }
    },
    'export': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },


    'articleTypeAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/articletypes', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        articleTypes: result,
                        // total: result.total,
                    }
            })
        }
    },
    'groupAPI': {
        onLoad: true,
        action: (query) => {
            return fetch(env.API_URL + '/data/groups', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    // filter: query.filter,
                })
            }).then(parseJSON).then(({ result, status }) => {
                // console.log(result)
                if (status >= 200 && status < 300)
                    // console.log('NA API-JU ZA CITANJE GRUPA...', result)
                    return {
                        groups: result,
                        // total: result.total,
                    }
            })
        }
    },

    'subgroupAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/subgroups', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        subgroups: result,
                        // total: result.total,
                    }
            })
        }
    },
    'manufacturerAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/manufacturs', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        manufacturers: result,
                        // total: result.total,
                    }
            })
        }
    },

    'atcAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/atc', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        atc: result,
                        // total: result.total,
                    }
            })
        }
    },
    'shapeAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/shapes', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        shapes: result,
                        // total: result.total,
                    }
            })
        }
    },
    'storageModeAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/storagemode', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        modes: result,
                        // total: result.total,
                    }
            })
        }
    },
    'suggestions': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/products/inn/suggestions', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        suggestions: result,
                        // total: result.total,
                    }
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            console.log(data)
            return fetch(env.API_URL + '/data/products/validate/' + data.value + '/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'fund-list': {
        onLoad: false,
        action: (query) => {
            // console.log(data)
            return fetch(env.API_URL + '/data/products/fund-list', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    filter: query && query.filter
                })
            }).then(parseJSON).then(({ result }) => {
                return {
                    fundList: result.items,
                    selectWithSearchItems: result.selectWithSearchItems
                };
            })
        }
    },

    'article-read' : {
        onLoad : false,
        action : (data)=>{
           
            return fetch(env.API_URL + '/data/products/' + data.articleCode, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return  { result }
            })
        }
    },
    'article-read-code' : {
        onLoad : false,
        action : (data)=>{
           
            return fetch(env.API_URL + '/data/products/code/' + data.articleCode, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return  { result }
            })
        }
    },

    // 'article-read' : {
    //     onLoad : false,
    //     action : (data)=>{
           
    //         return fetch(env.API_URL + '/data/products/barcode-check', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify({
    //                 barcode: data && data.barcode
    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             return  result
    //         })
    //     }
    // },

    'checkNextDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/products/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },

    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) => {
                return {
                    products: response.result.items
                }
            })
        }
    },

    'checkName': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/products/check/name', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                       result: result.info
                    }
            })
        }
    },


}