import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';

import moment from 'moment';
import { debounce } from "lodash";


import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import env from "react-dotenv";
import Loader from "../../components/loader";

class WriteOffPage1 extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            writeOffForm: false,
            // entryOut: true,
            buttonActive: 1,
            clients: [],
            products: [],
            listCustomFilter: true,
            items: [],
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            writeOffitems: [],
            series: [],
            seriesDeadline: [],
            knjiz: true,
            articlesSearch: [],
            loadingPage: false,
            knjiz: true,
            articlesSearch: [],
            loadingPage: false,
            printTable: false,
            exportTable: false,
            printHtml: null,
            articleAmmount: 0,
            seriesSum: 0,
        }
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    componentDidMount() {
        this._onLoad();

        let params = this._getParams();
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.description != this.state.filterDescription) {
            this.setState({ filterDescription: params.description })
        }
        if (params.user && params.user != this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.firstFreeNumber && this.state.firstFreeNumber) {
            let writeOffForm = this.state.writeOffForm;
            if (!writeOffForm || Object.keys(writeOffForm).length == 0) {
                writeOffForm = {
                    writeoffDate: this.state.dateNow
                }
            }
            writeOffForm.documentNumber = this.state.firstFreeNumber;
            if (!writeOffForm.item) {
                writeOffForm.item = { itemNumber: 1 }
            }
            this.setState({ writeOffForm: null }, () => {
                this.setState({ writeOffForm })
            })
        }
    }

    goToFormTab = () => {
        this._onLoad()
        let writeOffForm = this.state.writeOffForm;
        if (!writeOffForm) {
            writeOffForm = {
                writeoffDate: this.state.dateNow
            }
        }
        writeOffForm.documentNumber = this.state.firstFreeNumber;
        if (!writeOffForm.item) {
            writeOffForm.item = { itemNumber: 1 }
        }
        this.setState({ writeOffForm: null }, () => {
            this.setState({ writeOffForm })
        })
    }

    insertOrUpdate = (data, doNivelation = false) => {
        if (data && data.item && data.item.article) {
            data.item.article = Number(data.item.article)
        }
        if (data && data.clientCode) {
            data.clientCode = Number(data.clientCode)
        }
        if (data && data.item && data.item.kss && data.item.kss == -1) {
            delete data.item.kss;
        }

        if (doNivelation) {
            data.doNivelation = true;
        }

        if (data && data.item && this.state.facturePrice) {
            data.item.facturePrice = this.state.facturePrice
            data.item.factureValue = Number(this.state.facturePrice) * Number(data.item.ammount)
        }

        if (data._id) {
            this._apiAsync('update-document', data).then((res) => {
                if (res.res && res.res._id) {
                    this.setState({ writeOffForm: null }, () => {
                        this.setState({ writeOffForm: res.res, items: res.res.items && res.res.items.length ? res.res.items : [], products: [] })
                    })
                    if (res.res.items && res.res.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.res.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                this.remoteOnChange('item.article', '')
                                this.remoteOnChange('item.serialNumber', '')
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        } else {
            this._apiAsync('insert-new', data).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ writeOffForm: null }, () => {
                        this.setState({ writeOffForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                    })
                    if (res.result.items && res.result.items.length) {
                        // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                this.remoteOnChange('item.article', '')
                                this.remoteOnChange('item.serialNumber', '')
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        }
    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        this.setState({ articlesSearch: articleList.articlesSearch })
    }

    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.registerGetValue('item.article') ? this.registerGetValue('item.article') : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            withoutSeries: true,
            serialNumber: value,
            deadline: true
        })
        if (resultSeries && resultSeries.items.length > 0 && !this.registerGetValue('item.serialNumber')) {
            this.setState({ series: resultSeries.items })
        }
    }, 400);

    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }

    printOutput = async () => {
        const printHtml = await this._apiAsync('printOutputDocument', { documentNumber: this.state.writeOffForm.documentNumber })
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }


    render() {
        let params = this._getParams();
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive == 1 ?
                                <>Lista otpisa</>
                                :
                                <>Otpis</>
                        }
                    </h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-extrabutton">
                                <div className="title-options-group">
                                    {/* <button>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Snimi</span>
                                    </button>
                                    <button onClick={ async ()=>{this.setState({printTable: true})}}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={ async ()=>{this.setState({exportTable: true})}}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                    {/* <button>
                                        <div className="option red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <span>Otkaži</span>
                                    </button> */}
                                </div>
                            </div>

                            :
                            null
                    }
                    {/*
                    <div className="title-options-group">
                        <button>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option red">
                                <Isvg src={deleteIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>
                    */}
                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ buttonActive: 1 }, () => { this._onLoad() })}>
                            Lista otpisa
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => {
                            if (this.state.buttonActive != 2) {
                                this.setState({ buttonActive: 2, writeOffForm: null, items: [] }, () => this.goToFormTab())
                            }
                        }}>
                            Dodaj otpis
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" onChange={(e) => {
                                    if (e.target.checked) {
                                        this.updateParams('notFinished', e.target.checked)
                                    } else {
                                        this.updateParams('notFinished', null)
                                    }
                                }} />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'item.article', 'item.price', 'item.ammount', 'item.serialNumber']}
                                    fieldTracker={async (name, value) => {

                                        if (name == 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, writeOffForm: null }, () => {
                                                this.setState({ writeOffForm: {} })
                                            })
                                        }


                                        if (name == 'documentNumber' && value) {
                                            if (value) { //!= this.state.prevDocumentNumber
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                                    //ukoliko postoji rezultat citanja dokumenta
                                                    if (res && res.result && res.result._id) {

                                                        this.setState({ writeOffForm: null }, () => {
                                                            this.setState({ writeOffForm: res.result }, () => {

                                                                if (res.result.items && res.result.items.length) {
                                                                    this.setState({
                                                                        items: res.result.items,

                                                                    }, () => {
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }
                                                                        })
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        items: [],

                                                                    }, () => {
                                                                        this.remoteOnChange('item.itemNumber', this.state.itemNumber)
                                                                    })
                                                                }

                                                            })
                                                        })

                                                    } else if (this.state.prevDocumentNumber != value /*&& this.state.entranceForm._id*/) {
                                                        this.setState({ writeOffForm: null }, () => {
                                                            this.setState({ writeOffForm: { documentNumber: value } }, () => {

                                                                this.setState({ items: [], writeOffForm: {} }, () => {
                                                                    this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                        if (res && res.firstFreeItemNumber) {
                                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                        }

                                                                    })
                                                                })

                                                            })
                                                        })

                                                    }

                                                    this.setState({ prevDocumentNumber: value })
                                                })
                                            }
                                        }


                                        if (name == 'item.article' && value) {
                                            if (value) {
                                                this._apiAsync('get-articles', { filter: value }).then((products) => {
                                                    if (products && products.items) {
                                                        this.setState({ products: products && products.items }, () => {
                                                            this._apiAsync('get-price', { article: products.items[0] }).then((res) => {
                                                                if (res) {
                                                                    this.remoteOnChange('item.price', res.price.sellPrice)
                                                                    this.remoteOnChange('item.stock', (res.price.quantityIn - res.price.quantityOut))
                                                                    this.setState({ articleAmmount: Number(res.price.quantity) })
                                                                    this.setState({
                                                                        facturePrice: res.price.buyPrice
                                                                    })

                                                                }
                                                            })
                                                        })
                                                    }
                                                })

                                                let series = await this._apiAsync('get-series', { article: value, withoutSeries: true });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items }, () => {
                                                        this.setState({
                                                            seriesSum: this.state.series.reduce((sum, currentValue) => {
                                                                return sum + currentValue.ammount
                                                            }, 0)
                                                        })
                                                    })
                                                } else {
                                                    this.setState({ series: [] })
                                                }

                                            }
                                        }

                                        if (name == 'item.article' && !value) {
                                            this.setState({ series: [] }, () => {
                                                // this.setState({seriesDeadline : []})
                                                this.remoteOnChange('item.serialNumber', '')
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.stock', '')
                                                this.remoteOnChange('item.price', '')
                                            })
                                        }

                                        if (name == 'item.serialNumber' && value) {

                                            if (value) {
                                                let article = this.registerGetValue('item.article');
                                                let series = await this._apiAsync('get-series', { article: article, filter: value });
                                                if (series && series.items) {
                                                    this.setState({ series: series.items }, () => {
                                                        if (this.state.series && this.state.series.length) {
                                                            if (this.state.series.filter(item => item.serialNumber == value).length == 1) {
                                                                let item = this.state.series.filter(item => item.serialNumber == value)[0];
                                                                if (item && item.newExpirationDate) {
                                                                    // if (this.registerGetValue('item.deadline') != item.newExpirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.newExpirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                    // }
                                                                } else if (item && item.expirationDate) {
                                                                    // if (this.registerGetValue('item.deadline') != item.expirationDate) {
                                                                    this.remoteOnChange('item.deadline', item.expirationDate)
                                                                    this.remoteOnChange('item.stock', item.ammount)
                                                                    // }
                                                                }

                                                            } else {
                                                                if (this.registerGetValue('item.deadline')) {
                                                                    this.remoteOnChange('item.deadline', '')
                                                                }
                                                            }
                                                        }
                                                    })
                                                } else {
                                                    if (this.registerGetValue('item.deadline')) {
                                                        this.remoteOnChange('item.deadline', '')
                                                    }
                                                }

                                            } else {
                                                if (this.registerGetValue('item.deadline')) {
                                                    this.remoteOnChange('item.deadline', '')

                                                }
                                            }
                                        }

                                        if (name == 'item.serialNumber' && !value) {
                                            let series = await this._apiAsync('get-series', { article: Number(this.registerGetValue('item.article')), withoutSeries: true, deadline: true });
                                            if (series && series.items) {
                                                this.setState({ series: series.items })
                                            } else {
                                                this.setState({ series: [] })
                                            }
                                        }

                                        if (name == 'item.ammount' && value) {
                                            if (value) {
                                                let kolicina = parseFloat(this.registerGetValue('item.ammount'))
                                                let cijena = parseFloat(this.registerGetValue('item.price'))
                                                let zalihe = parseFloat(this.registerGetValue('item.stock'))
                                                let total = kolicina * cijena
                                                this.remoteOnChange('item.total', total)
                                                if (kolicina > zalihe) {
                                                    this.setState({ errorAmmountModal: true })
                                                    this.remoteOnChange('item.ammount', '')
                                                }
                                            }
                                        }

                                    }}

                                    onSubmit={(data, notFinished) => {
                                        data.notFinished = notFinished ? true : false;
                                        if (!notFinished) {
                                            if (data && data.item) {
                                                delete data.item;
                                            }
                                        }

                                        if (!data || !data.item || !data.item.ammount || !data.item.price) {
                                            this.setState({ errorDataModal: data })
                                            return 'open-modal';
                                        } else {
                                            this.insertOrUpdate(data)
                                        }

                                    }}
                                    initialValues={this.state.writeOffForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                                    children: [
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            className: 'lock-icon-container',
                                                                            children: <div className="lock-icon">
                                                                                <Isvg src={lockIcon} onClick={() => {
                                                                                    this.setState({
                                                                                        lockModal: true
                                                                                    })
                                                                                }} />
                                                                            </div>,
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 11, sm: 11, xs: 11 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                this.submit(true)
                                                                                                this.setState({ knjiz: false })
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.writeOffForm && this.state.writeOffForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput().then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })
                                                                                                }
                                                                                                )

                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'custom',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                            className: 'options-button',
                                                                                            onClick: () => {
                                                                                                if (this.state.writeOffForm && this.state.writeOffForm._id) {
                                                                                                    this.setState({ deleteDocumentModal: this.state.writeOffForm })
                                                                                                } else {
                                                                                                    this.setState({ writeOffForm: null }, async () => {

                                                                                                        this.setState({
                                                                                                            writeOffForm: {
                                                                                                                documentNumber: this.state.firstFreeNumber,
                                                                                                                writeoffDate: this.state.dateNow,

                                                                                                                item: {
                                                                                                                    itemNumber: 1,
                                                                                                                    article: '',
                                                                                                                    price: ''
                                                                                                                }

                                                                                                            }
                                                                                                        })

                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'writeoffDate',
                                                            label: 'Broj dokumenta',
                                                            // textType: 'number',
                                                            integer: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'writeoffDate',
                                                            next: 'item.article',
                                                            label: 'Datum otpisa',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.article',
                                                            label: 'Redni broj',
                                                            // textType: 'number',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Redni broj mora biti unesen!" },

                                                            ]
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            label: 'Artikal',
                                                            textType: 'number',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            title: 'Pregled artikala',
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code
                                                                }
                                                                return obj
                                                            })],
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Artikal mora biti unesen!" }
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 && this.state.writeOffForm?._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if ((!value || value == '') && checkValidation)
                                                                        return "Artikal mora biti unesen!"
                                                                    this.setState({ knjiz: true })
                                                                },
                                                                async (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 && this.state.writeOffForm?._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if (checkValidation) {
                                                                        let series = await this._apiAsync('get-series', { article: Number(this.registerGetValue('item.article')), withoutSeries: true, deadline: true });
                                                                        if (series && series.items) {
                                                                            this.setState({ series: series.items })
                                                                        } else {
                                                                            this.setState({ series: [] })
                                                                        }



                                                                        if (this.state.articlesSearch.length == 0 && this.state.products.filter(item => item.code == value).length == 0 && this.state.products.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!"
                                                                        }
                                                                        if (this.state.articlesSearch.length != 0 && this.state.articlesSearch.filter(item => item.code == value).length == 0 && this.state.articlesSearch.filter(item => item.name == value).length == 0) {
                                                                            return "Neispravan unos!!"
                                                                        }

                                                                        this.setState({ numberOfSeries: this.state.articlesSearch.filter(item => item.code == value)[0].series.length })
                                                                    }



                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'series',
                                                            name: 'item.serialNumber',
                                                            next: 'item.ammount',
                                                            metoda: (value) => { this.metodaSeries(value) },
                                                            dataModal: this.state.series,
                                                            title: 'Pregled serija',
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber
                                                                }
                                                                return obj
                                                            })],
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                async (value) => {
                                                                    try {
                                                                        if (value && this.registerGetValue('item.article')) {
                                                                            let res = await this._apiAsync('validate-serial-number', { value: value, code: this.registerGetValue('item.article') });
                                                                            if (res.status == 400) {
                                                                                return 'Šifra je zauzeta'
                                                                            }
                                                                            return undefined;
                                                                        }

                                                                    } catch (e) {
                                                                        return undefined;
                                                                    }
                                                                },
                                                                (value) => {
                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                    if (item && item.itemNumber) {
                                                                        delete item.itemNumber;
                                                                    }
                                                                    for (var key in item) {
                                                                        if (!item[key]) {
                                                                            delete item[key];
                                                                        }
                                                                    }
                                                                    let checkValidation = false;
                                                                    if (item && Object.keys(item).length > 0 && this.state.writeOffForm?._id) {
                                                                        checkValidation = true;
                                                                    }

                                                                    if (checkValidation) {

                                                                        if (this.state.series.length != 0 && !value && this.state.articleAmmount == this.state.seriesSum) {
                                                                            return 'Serija mora biti unešena!!'
                                                                        }

                                                                        if (this.state.series.length == 0 && this.state.numberOfSeries != 0) {
                                                                            return 'Neispravan unos!!'
                                                                        }

                                                                        if (this.state.numberOfSeries == 0 && value) {
                                                                            return 'Neispravan unos!'
                                                                        }
                                                                    }
                                                                },

                                                                async () => {

                                                                    if (this.state.series && this.state.series.length != 0) {

                                                                        this.remoteOnChange('item.stock', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.ammount)

                                                                        this.remoteOnChange('item.deadline', this.state.series.filter((seria) => {
                                                                            return seria.serialNumber == this.registerGetValue('item.serialNumber')
                                                                        })[0]?.expirationDate)

                                                                    }
                                                                }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            readOnly: true,
                                                            label: 'Rok',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.ammount',
                                                                            next: 'item.price',
                                                                            label: 'Količina',
                                                                            // textType: 'number',
                                                                            integer: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                (value) => {
                                                                                    let item = JSON.parse(JSON.stringify(this.registerGetValue('item')));
                                                                                    if (item && item.itemNumber) {
                                                                                        delete item.itemNumber;
                                                                                    }
                                                                                    for (var key in item) {
                                                                                        if (!item[key]) {
                                                                                            delete item[key];
                                                                                        }
                                                                                    }
                                                                                    let checkValidation = false;
                                                                                    if (item && Object.keys(item).length > 0 && this.state.writeOffForm?._id) {
                                                                                        checkValidation = true;
                                                                                    }
                                                                                    if ((!value || value == '') && checkValidation)
                                                                                        return "Količina mora biti unesena!"
                                                                                },
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            // next: 'price',
                                                                            label: 'Zaliha',
                                                                            textType: 'number',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'decimal',
                                                            name: 'item.price',
                                                            next: 'submitForm',
                                                            label: 'Cijena',
                                                            // textType: 'number',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Cijena mora biti unesena!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            textType: 'number',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [
                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: false },

                                    ]}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={editIcon} />,
                                                onClick: (item) => {
                                                    this.metoda(Number(item.article)).then(async () => {
                                                        let series = await this._apiAsync('get-series', { article: item.article });
                                                        if (series && series.items) {
                                                            this.setState({ series: series.items })
                                                        } else {
                                                            this.setState({ series: [] })
                                                        }
                                                    })
                                                    let writeOffForm = this.state.writeOffForm;
                                                    writeOffForm.item = item;
                                                    this.setState({ writeOffForm: null }, () => {
                                                        this.setState({ writeOffForm })
                                                    })
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {

                                    this._apiAsync('remove-item', { _id: this.state.writeOffForm._id, itemNumber: this.state.deleteModal.itemNumber }).then((res) => {
                                        if (res.result && res.result._id) {
                                            this.setState({ writeOffForm: null, deleteModal: null }, () => {
                                                this.setState({ writeOffForm: res.result, items: res.result.items && res.result.items.length ? res.result.items : [] })
                                            })
                                            if (res.result.items && res.result.items.length) {
                                                // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                    if (res && res.firstFreeItemNumber) {
                                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                    }

                                                })
                                            } else {
                                                this.remoteOnChange('item.itemNumber', 1)
                                            }

                                        }
                                    })
                                }} >
                                Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                            </DeleteModal>
                            <DeleteModal
                                isOpen={this.state.deleteDocumentModal}
                                toggle={() => this.setState({ deleteDocumentModal: null })}
                                handler={() => {
                                    if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                        this.setState({ deleteDocumentModal: null })
                                        this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                        return;
                                    } else {
                                        this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                            this.setState({ deleteDocumentModal: null, writeOffForm: null, items: [], firstFreeNumber: null }, () => {
                                                this.setState({ writeOffForm: {} }, () => this._onLoad())
                                                // this._onLoad()
                                            })

                                        })
                                    }
                                }} >
                                Obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                            </DeleteModal>

                            <div className="footer">
                                <div className="footer-left">
                                    {this.state.writeOffForm && this.state.writeOffForm._id ?
                                        <button onClick={() => { this.setState({ deleteDocumentModal: this.state.writeOffForm }) }}>
                                            <div className="box box-red">
                                                <Isvg src={garbageOpionIcon} />
                                            </div>
                                            <p>BRISANJE DOKUMENTA</p>
                                        </button>
                                        : null
                                    }

                                </div>
                                <div className="footer-right">
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>
                                                {
                                                    this.state.items.length != 0 ?
                                                        this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                            let res = sum + current.total
                                                            return res
                                                        }, 0))), true, true)
                                                        :
                                                        '00,00'
                                                }
                                                &nbsp;KM
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.article} onChange={(e) => this.setState({ article: e.target.value }, () => {
                                                    this.updateParams('article', this.state.article)
                                                })} placeholder={'Artikal'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} placeholder={'Korisnik'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                article: '',
                                                filterUser: '',
                                                filterBusiness: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null },
                                                { name: 'article', value: null }, { name: 'user', value: null }
                                                ])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'writeoffDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'total', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}

                                onClick={(item) => {
                                    if (item && item.documentNumber) {
                                        this.setState({ writeOffForm: null, buttonActive: 2 }, () => {
                                            this.setState({ writeOffForm: { documentNumber: item.documentNumber } }, () => {
                                                this.remoteOnChange('documentNumber', item.documentNumber)
                                            })
                                        })
                                    }

                                }}

                                items={this.state.writeOffitems}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/writeOff', title: 'Lista otpisa' }}
                            ></ListBuilder>
                        </>
                }
                <InfoModal
                    isOpen={this.state.errorAmmountModal}
                    toggle={() => this.setState({ errorAmmountModal: null })}
                    title={'Obavještenje'}
                    info={'Nemate dovoljnu kolicinu na zalihama.'}
                    buttons={[
                        {
                            text: 'Ok',
                            onClick: () => this.setState({ errorAmmountModal: null })
                        },

                    ]}
                >
                </InfoModal>

                <InfoModal
                    isOpen={this.state.errorDataModal}
                    toggle={() => this.setState({ errorDataModal: null })}
                    title={'Upozorenje'}
                    info={'Morate popuniti obavezna polja.'}
                    buttons={[
                        {
                            text: 'Ok',
                            onClick: () => this.setState({ errorDataModal: null })
                        },

                    ]}
                >
                </InfoModal>
            </div>
        )
    }
}

export default Page(WriteOffPage1);
