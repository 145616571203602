
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import Switch from "../../form-builder/fields/switch";

import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class DisplayContolsPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            crateDutieModal: false,
            total: 0,
            products: [],
            items: [
                {
                    _id: 1,
                    articleCode: 12312,
                    barCode: 1212132131,
                    name: 'test',
                    serialNumber: '213',
                    dateString: '21.12.2021',
                    businessUnit: 123,
                    controledBy: 'Pero Peric',
                    time: '14:15'
                }


            ],
            series: [],
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }
    submit = () => {
        if (this.state.items && this.state.items.length) {
            let stock = [];
            for (let i = 0; i < this.state.items.length; i++) {
                stock.push({
                    _id:  this.state.items[i]._id,
                    position:  this.state.items[i].position
                })
            }
            this._apiAsync('update-postions', { stock: stock }).then((res) => {
                this._onLoad()
            })
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Lokacija</h1>
                    {/* <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                        <button>
                            <div className="option red">
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Otkaži</span>
                        </button>
                    </div> */}
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                                <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                    <div className="field-strap-wrap" style={{ width: '50%', marginRight: 20 }}>
                                        <Label>Pretraga artikala</Label>
                                        <Input type='text' value={this.state.filterArticle != null ? this.state.filterArticle : params.article} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                            this.updateParams('article', this.state.filterArticle)
                                        })} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: '30%', marginRight: 20 }}>
                                        <Label>Pretraga lokacija</Label>
                                        <Input disabled={this.state.filterWithoutLocation} type='text' value={this.state.filterLocation != null ? this.state.filterLocation : params.location} onChange={(e) => this.setState({ filterLocation: e.target.value }, () => {
                                            this.updateParams('location', this.state.filterLocation)
                                        })} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: '20%' }}>
                                        <Switch
                                            onChange={(value) =>
                                                this.setState({ filterWithoutLocation: !this.state.filterWithoutLocation }, () => {
                                                    this.updateParams('withoutLocation', this.state.filterWithoutLocation)
                                                    if (this.state.filterWithoutLocation) {
                                                        this.setState({ filterLocation: '' }, () => this.updateParams('location', this.state.filterLocation))
                                                    }
                                                })
                                            }
                                            value={this.state.filterWithoutLocation}
                                            label={"Prikaži samo bez lokacije"}
                                            placeholder={""}
                                        ></Switch>
                                    </div>
                                    {/* <div className="clear-filters" style={{ top: 0, marginLeft: 25 }} onClick={() => {

                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterBusiness: '',
                                            filterTypeOfChange: -1,
                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'change', value: null }])

                                        })

                                    }}>
                                        <a><span>&times;</span> Ukloni filter</a>
                                    </div> */}

                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                maxHeight={'60vh'}
                                fields={[
                                    { type: 'text', name: 'ordinalNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'productCode', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'amount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                    { type: 'text', name: 'location', label: 'LOKACIJA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        // user: item.createdByUsername
                                        ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                        amount: item.quantity - item.quantityReserved,
                                        location: <div>
                                            <Input id={`input-${idx}`} type='text'
                                                onKeyUp={(e) => {
                                                    if (e.key == 'Enter') {
                                                        this._apiAsync('update-postion', { stock: item._id, position: item.position }).then((res) => {
                                                            this._onLoad()
                                                        })
                                                        if (typeof window != 'undefined') {
                                                            let nextIdx = idx + 1;
                                                            document.getElementById(`input-${idx}`).blur()
                                                            if (document.getElementById(`input-${nextIdx}`)) {
                                                                document.getElementById(`input-${nextIdx}`).focus()
                                                            }

                                                        }
                                                    }
                                                }}
                                                value={item.position} onChange={(e) => {
                                                    let items = this.state.items;
                                                    if (items && items[idx]) {
                                                        items[idx].position = e.target.value;
                                                    }
                                                    this.setState({ items })
                                                }} />
                                        </div>
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        // {
                                        //     component: <Isvg src={arrowDown} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ crateDutieModal: item })
                                        // },
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>

                    </Row>
                    {
                        this.state.crateDutieModal ?
                            <Modal isOpen={this.state.crateDutieModal} toggle={() => this.setState({ crateDutieModal: false })} size='lg' className="modal-wrap-cold-chain-preview">
                                {/* <ModalHeader>
                                    <div className='title'>Pregled artikala u hladnom lancu</div>
                                    <Button className="print-button"><div className="svg-box"><Isvg src={printIcon} /></div>Štampaj</Button>
                                </ModalHeader> */}
                                <ModalBody className="cold-chain-wrap">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        hidePagination={true}
                                        showNumeration={false}
                                        maxHeight={'60vh'}
                                        fields={[
                                            { type: 'text', name: 'businessName', label: 'IME POSLOVNE'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'crateCode', label: 'ŠIFRA GAJBICE'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'amount', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },


                                        ]}
                                        items={this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                businessName: 'Ime poslovne',
                                                crateCode: '21321311',
                                                amount: 213,
                                            }
                                        })}
                                        rawItems={this.state.items}
                                        actions={
                                            [
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ form: item })
                                                //     //onClick: (item) => console.log(item)
                                                // },
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ deleteModal: item })
                                                // },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>

                            </Modal>
                            :
                            null
                    }

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(DisplayContolsPage);
