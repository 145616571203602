
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import {
    Container,
} from 'reactstrap';

class BuyerReturnPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            
            items: {},
            
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.items && this.state.items != prevState.items){
            if(this.state.items.sedmicnoKronUkljuceno)
            this.remoteOnChange('sedmicnoKronUkljuceno', true)

            if(this.state.items.mjesecnoKronUkljuceno)
            this.remoteOnChange('mjesecnoKronUkljuceno', true)
        }
    }

    render() {
        // let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Izvjestaj ICM</h1>

                </div>
                <Container fluid>

                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container entrance-custom-col"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}

                                onSubmit={async (data) => {
                                    await this._apiAsync('set-kron-data', data).then((res) => {
                                        this._onLoad()
                                    });


                                }}
                                initialValues={this.state.items}
                                fields={[

                                    {
                                        type: 'col',
                                        width: { lg: 7, sm: 12, xs: 12 },
                                        offset: { lg: 2, sm: 0, xs: 0 },
                                        style: { marginTop: 'calc(50vh - 400px)' },
                                        children: [
                                            {
                                                type: 'block',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'checkbox',
                                                                name: 'sedmicnoKron',
                                                                label: 'Sedmicno',

                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'checkbox',
                                                                name: 'mjesecnoKron',
                                                                label: 'Mjesecno',

                                                            },
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: { lg: 4, sm: 4, xs: 4 },
                                                        children: [
                                                            {
                                                                type: 'date',
                                                                name: 'datumOd',
                                                                label: 'Datum od',
                                                                next: 'datumDo',
                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 4, sm: 4, xs: 4 },
                                                        children: [
                                                            {
                                                                type: 'date',
                                                                name: 'datumDo',
                                                                label: 'Datum do',

                                                            },
                                                        ]

                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'checkbox',
                                                                name: 'sedmicnoKronUkljuceno',
                                                                label: 'Sedmicno automatski',
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                        children: [
                                                            {
                                                                type: 'checkbox',
                                                                name: 'mjesecnoKronUkljuceno',
                                                                label: 'Mjesecno automatski',

                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 7, sm: 7, xs: 7 },
                                                        style: { display: 'flex', justifyContent: 'flex-end' },
                                                        children: [
                                                            {
                                                                type: 'button',
                                                                children: <button className="blue-button next-button" onClick={() => {
                                                                    this.submit()
                                                                }}>
                                                                    <p>pokreni</p>
                                                                </button>,
                                                                className: 'options-button width-100',
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                ]}
                            ></FormBuilder>

                </Container>

            </div >
        )
    }
}

export default Page(BuyerReturnPage);
