
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

import DateField from '../../form-builder/fields/date';


class DisplayContolsPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            controled: true
        }
    }

    componentDidMount() {
        // this._onLoad();
        this.setState({ filterTypeOfChange: 'output' }, () => {
            this.updateParams('type', this.state.filterTypeOfChange != -1 ? this.state.filterTypeOfChange : null)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (this.props.selectedYear != prevProps.selectedYear) {
            this._onLoad();
        }
        if (prevState.controled != this.state.controled) {
            this.updateMultipleParams([{ name: 'page', value: null }])
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Prikaz kontrole</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>
                                    <div className="list-builder-filters" style={{ marginBottom: 0, alignItems: 'flex-end' }}>
                                        {/* <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Input style={{ width: 200 }} type='date' value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                this.updateParams('dateFrom', this.state.filterDateFrom)
                                            })} disabled={!this.state.controled} />
                                        </div> */}
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })}
                                                value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                placeholder={'Datum od'}
                                                readOnly={!this.state.controled}
                                            ></DateField>
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 150 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-150'}
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo}
                                                placeholder={'Datum do'}
                                                readOnly={!this.state.controled}
                                            ></DateField>
                                        </div>
                                        {/* <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Input style={{ width: 200 }} type='date' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                this.updateParams('dateTo', this.state.filterDateTo)
                                            })} disabled={!this.state.controled}>

                                            </Input>
                                        </div> */}
                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Input type='select' style={{ width: 200 }} value={this.state.filterTypeOfChange != null ? this.state.filterTypeOfChange : params.type} onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                                this.updateParams('type', this.state.filterTypeOfChange != -1 ? this.state.filterTypeOfChange : null)
                                            })}>
                                                <option value={'output'}>Izlaz</option>
                                                <option value={'naturalniRabat'}>Naturalni rabat</option>
                                            </Input>
                                        </div>
                                    </div>

                                    <div>
                                        <button className={this.state.controled ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => { this.setState({ controled: true }) }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>KONTROLISANI</p>
                                        </button>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 15 }}>

                                    <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 0 }}>
                                        <div className="field-strap-wrap" style={{ width: 400 }}>
                                            <Input style={{ width: 400 }} type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                this.updateParams('client', this.state.filterClient)
                                            })} placeholder={'Komitent'} />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 400 }}>
                                            <Input style={{ width: 400 }} type='text' value={this.state.filterBusiness != null ? this.state.filterBusiness : params.business} onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                this.updateParams('business', this.state.filterBusiness)
                                            })} placeholder={'Poslovna'} />
                                        </div>
                                        <div className="clear-filters" style={{ top: 0, marginLeft: 25 }} onClick={() => {

                                            this.setState({
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterBusiness: '',
                                                filterTypeOfChange: -1,
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'type', value: null }])

                                            })

                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>

                                    </div>
                                    <div>
                                        <button className={!this.state.controled ? "blue-button" : "blue-button-outline"} style={{ width: 200 }} onClick={() => {
                                            this.setState({ controled: false }, () => {
                                                this.setState({
                                                    filterDateFrom: '',
                                                    filterDateTo: '',

                                                }, () => {
                                                    this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }])

                                                })
                                            })
                                        }}>
                                            <div className="box box-blue">
                                                <Isvg src={editIcon} />
                                            </div>
                                            <p>NEKONTROLISANI</p>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        {
                            this.state.controled ?
                                <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        maxHeight={'80vh'}
                                        fields={[
                                            { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'typeText', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'businessName', label: 'POSLOVNA JEDINICA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'lastControledByUsername', label: 'KONTROLISAO'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                        ]}
                                        items={this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                date: moment.unix(item.lastControledDateTs).format('DD.MM.YYYY'),
                                                time: moment.unix(item.lastControledDateTs).format('HH:mm'),
                                                typeText: this.state.filterTypeOfChange == 'output' ? 'Izlaz' : 'Naturalni rabat'
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.items}
                                        actions={
                                            [
                                                {
                                                    component: <Isvg src={arrowDown} className="delete-icon" />,
                                                    onClick: (item) => {
                                                        if ((this.state.dropdownActive && this.state.dropdownActive._id != item._id) || !this.state.dropdownActive) {
                                                            this.setState({ dropdownActive: item })
                                                        } else {
                                                            this.setState({ dropdownActive: null })
                                                        }

                                                    },
                                                    dropdownShowCondition: (item) => {
                                                        if (this.state.dropdownActive && item._id == this.state.dropdownActive._id)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    condition: (item) => {
                                                        if (item.packer || item.collector)
                                                            return true
                                                        else
                                                            return false
                                                    },
                                                    dropdownShow: <div className="drop-down-wrap">
                                                        <table>
                                                            <tr>
                                                                <th>SAKUPLJAČ</th>
                                                                <th>PAKER</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.collectorUsername}</td>
                                                                <td>{this.state.dropdownActive && this.state.dropdownActive.packerUsername}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                },
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ deleteModal: item })
                                                // },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </Col>
                                :
                                <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.totalUncontrolled}
                                        showNumeration={false}
                                        maxHeight={'80vh'}
                                        fields={[
                                            { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'typeText', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'businessName', label: 'POSLOVNA JEDINICA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },

                                        ]}
                                        items={this.state.itemsUncontrolled.map((item, idx) => {
                                            return {
                                                ...item,
                                                typeText: this.state.filterTypeOfChange == 'output' ? 'Izlaz' : 'Naturalni rabat',
                                                ordinalNumber: idx + 1
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.itemsUncontrolled}
                                        actions={
                                            [

                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </Col>
                        }


                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(DisplayContolsPage);
