import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import env from "react-dotenv";
import Isvg from 'react-inlinesvg';
import x from '../../assets/x.svg';
import plus from '../../assets/plus.svg';
import { parseJSON } from "../../helpers/json";

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        }
    }

    checkBarcode = () => {
        fetch(env.API_URL + this.props.validateApi, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
                data: this.state.value
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status != 200 && result.error) {
                this.setState({
                    error: result.error
                })
            } else {
                if (!this.state.value) {
                    return;
                }

                if (!this.state.value.trim()) {
                    return;
                }

                let value = this.props.value;
                if (!value) {
                    value = [];
                }
                value.push(this.state.value.trim());
                this.setState({ value: '' })
                this.props.onChange(value);
            }
        })

    }

    render() {
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>

                <div className="list-field" style={this.props.height ? { height: this.props.height } : {}}>
                    <div className="items">
                        {this.props.value && this.props.value.length ?
                            this.props.value.map((item, idx) => {
                                return (
                                    <div>
                                        <span>{item}</span>
                                        <button onClick={() => {
                                            let value = this.props.value;
                                            value.splice(idx, 1);
                                            this.props.onChange(value);
                                        }}><Isvg src={x} /></button>
                                    </div>
                                )
                            })

                            :
                            null
                        }
                    </div>

                    <div className="input input-type-text">
                        <input
                            onKeyUp={(e) => {
                                if (e.key == 'Enter') {


                                    if (this.props.bih) {
                                        let newValue = this.state.value.replace(/-/g, "")
                                        console.log('NOVA VRIJEDNOST.....', newValue)
                                        if (newValue.length != 16) {
                                            this.setState({ error: "Žiro-račun mora imati 16 cifara!" })
                                            return
                                        }
                                    }



                                    if (this.props.validateApi) {
                                        this.checkBarcode()
                                    } else {
                                        if (!this.state.value) {
                                            return;
                                        }

                                        if (!this.state.value.trim()) {
                                            return;
                                        }

                                        let value = this.props.value;
                                        if (!value) {
                                            value = [];
                                        }
                                        value.push(this.state.value.trim());
                                        this.setState({ value: '' })
                                        this.props.onChange(value);
                                    }

                                }
                            }}

                            value={this.state.value}
                            onChange={(e) => this.setState({ value: e.target.value, error: false })}
                            onFocus={() => this.setState({ foucs: true })}
                            onBlur={() => this.setState({ foucs: false }, () => {
                                if (!this.state.foucs && this.state.value && this.state.value.trim()) {
                                    
                                    let value = this.props.value;
                                    if (!value) {
                                        value = [];
                                    }
                                    value.push(this.state.value.trim());
                                    this.setState({ value: '' })
                                    this.props.onChange(value);
                                }
                            })}
                            type={this.props.textType ? this.props.textType : "text"}
                            className="form-control"
                            ref={(node) => {
                                if (this.props.setFieldRef)
                                    this.props.setFieldRef(node)
                            }}
                        ></input>
                        {!this.state.foucs ?
                            <Isvg src={plus}></Isvg>
                            :
                            null
                        }
                    </div>

                </div>
                {
                    this.state.error ?
                        <p style={{ color: 'red' }}>{this.state.error}</p>
                        :
                        null
                }
            </FormGroup>
        )
    }
}

export default List;
