import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    
    'get-fund-prices': {
        onLoad: true,
        action: (lang, match, query) => {
            // console.log(query)
            return fetch(env.API_URL + '/data/reports/pliva', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    print: query.page == 0 ? true : false,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        clientCode: query && query.clientCode,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        articleFrom: query && query.articleFrom,
                        group: query && query.group,
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    if (result.csv) {
                        window.location.href = env.API_URL + `${result.csv.file}`;
                    }
                return {
                    items: result.items,
                    total: result.total,
                    totalPrice: result.totalPrice,
                }
            })
        }
    },

}