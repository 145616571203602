import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import printJS from 'print-js'
export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        clientsItems: result.items,
                    }
            })
        }
    },
    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/supplier-return/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    article: query && query.article

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        articleItems: result,
                    }
            })
        }
    },
    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/supplier-return/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'validate-currency-date': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/entrance/validate-currency-date', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    'validate': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/validate/' + data.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/supplier-return/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'slobodanBroj': {
        onLoad: false,
        action: () => {

            return fetch(env.API_URL + '/data/supplier-return/slobodanBroj', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                return { result, status };
            })
        }
    },
    'insert-new': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/supplier-return/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'remove-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/supplier-return/remove/document/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    return {
                        result: result,
                    }
                }
                if(status === 405) {
                    return {
                        error: result.error,
                        status
                    }
                }
            })
        }
    },
    'checkNextItemNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/supplier-return/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    'get-entrace': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/supplier-return/entrance', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query && query.filter,
                    query: { clientName: query && query.client },
                    sortType: query && query.sortType ? query.sortType : '1',
                    sortField: query && query.sortField ? query.sortField : 'documentNumber',

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        entranceItems: result.items,
                    }
            })
        }
    },
    'get-series': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/supplier-return/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    article: query && query.article

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        seriesItems: result,
                    }
            })
        }
    },
    'remove-item': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/supplier-return/remove/item/' + data._id + '/' + data.oridnalNumber, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },
    'get-supplier-return': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/supplier-return', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'notFinished',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        accountNumber: query && query.accountNumber,
                        article: query && query.article,
                        notFinished: query && query.notFinished
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        supplierReturnItems: result.items,
                        total: result.total,

                    }
            })
        }
    },
    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/supplier-return/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        accountNumber: query && query.accountNumber,
                        article: query && query.article,
                        notFinished: query && query.notFinished
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        printHtml: result.html,
                    }
            })
        }
    },
    'export': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/supplier-return/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        user: query && query.user,
                        accountNumber: query && query.accountNumber,
                        article: query && query.article,
                        notFinished: query && query.notFinished
                    }
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'pringPovartDobavljaca': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/supplier-return/print-povrat-dobavljaca', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {

                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                            // loadingExport: false
                        });
                    }
                }).then(() => {
                    return true
                })

        }
    },

    'ariclesSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 50,
                    filter: data.value,
                    article: data.article,
                    withStock: true
                })
            }).then(parseJSON).then((response) => {
                return {
                    articlesSearch: response.result.items
                }
            })
        }
    },

    'clientsSearch': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/clients/', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 50,
                    filter: data.value
                })
            }).then(parseJSON).then((response) => {
                return {
                    clientsSearch: response.result.items
                }
            })
        }
    },

}
