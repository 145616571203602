import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Container, Row, Col, Input } from 'reactstrap';

import ProfileIcon from '../assets/profile.svg'
import ArrowDownIcon from '../assets/arrow-down.svg'
import SettingsIcon from '../assets/settings.svg'
import SearchIcon from '../assets/search.svg'
import NotificationIcon from '../assets/notification.svg'

import { Link, Redirect } from 'react-router-dom';


import SelectWithSearch from '../form-builder/fields/selectWithSearch'

import moment from 'moment';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            years: []
        }
    }

    componentDidMount() {
        let start = 2020;

        let thisYear = Number(moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY'))
        let years = [];
        for (let i = thisYear; i >= start; i--) {
            let obj = {
                name: String(i),
                value: String(i)
            }
            years.push(obj)
        }
        this.setState({ years })
        this.checkModules()
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.modulesTree) != JSON.stringify(this.props.modulesTree)) {
            this.checkModules()
        }
    }

    checkModules = () => {
        let modules = [];
        if (this.props.modulesTree) {
            let modulesTree = this.props.modulesTree;
            for (let i = 0; i < modulesTree.length; i++) {
                let check = this.checkModule(modulesTree[i], modules)
                if (check) {
                    modules.push(check)
                }
                if (modulesTree[i].modules && modulesTree[i].modules.length) {
                    for (let j = 0; j < modulesTree[i].modules.length; j++) {
                        let check1 = this.checkModule(modulesTree[i].modules[j], modules)
                        if (check1) {
                            modules.push(check1)
                        }
                        if (modulesTree[i].modules[j].modules && modulesTree[i].modules[j].modules.length) {
                            for (let k = 0; k < modulesTree[i].modules[j].modules.length; k++) {
                                let check2 = this.checkModule(modulesTree[i].modules[j].modules[k], modules)
                                if (check2) {
                                    modules.push(check2)
                                }
                                if (modulesTree[i].modules[j].modules[k].modules && modulesTree[i].modules[j].modules[k].modules.length) {
                                    for (let l = 0; l < modulesTree[i].modules[j].modules[k].modules.length; l++) {
                                        let check3 = this.checkModule(modulesTree[i].modules[j].modules[k].modules[l], modules)
                                        if (check3) {
                                            modules.push(check3)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        this.setState({ modules })
    }
    checkModule = (module, modules = []) => {
        let returnValue;
        if (module.path && module.name) {
            let obj = {
                name: module.name,
                path: module.path,
                id: module._id
            }
            if (modules.filter(el => el.id == obj.id).length == 0) {
                returnValue = obj;
            }
        }
        return returnValue;
    }


    render() {


        return (
            <header className="header">
                <Container fluid style={{ padding: 0 }}>
                    <div className="header-container">
                        <div className="search-container">
                            <div className="search">
                                <Isvg src={SearchIcon} />
                                <Input type='text' placeholder="Pretraga modula..." value={this.state.searchModule}
                                    onChange={(e) => {
                                        this.setState({ searchModule: e.target.value })
                                    }}
                                />
                                {
                                    this.state.searchModule && this.state.modules && this.state.modules.filter(el => el.name && el.name.toLowerCase().indexOf(this.state.searchModule.toLowerCase()) != -1).length ?
                                        <div className='dropdown-wrap'>
                                            {
                                                this.state.modules.map((item, idx) => {
                                                    if (item.name && item.name.toLowerCase().indexOf(this.state.searchModule.toLowerCase()) != -1)
                                                        return (
                                                            <div className={item.path == this.props[0].location.pathname ? 'dropdown-item dropdown-item-active' : 'dropdown-item'} onClick={() => {
                                                                if (item.path) {
                                                                    if (item.path == this.props[0].location.pathname) {
                                                                        this.setState({ searchModule: '' })
                                                                    } else {
                                                                        this.props[0].history.push(item.path)
                                                                    }

                                                                }

                                                            }}>
                                                                {item.name}
                                                            </div>
                                                        )
                                                })
                                            }
                                        </div>
                                        :
                                        null
                                }
                                {/* <input type="text" placeholder="Pretraga modula..." /> */}

                            </div>

                            <Input type='select' value={this.props.selectedYear} onChange={(e) => { this.props.selectYear(e.target.value) }}>
                                {
                                    this.state.years && this.state.years.map((item, idx) => {
                                        return (
                                            <option value={item.value}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        </div>
                        <div className="profile">
                            {/* <div className="notification-icon icon">
                                <Isvg src={NotificationIcon} />
                                <span className="notification-number">3</span>
                            </div> */}
                            <div className="user-icon icon">
                                <Isvg src={ProfileIcon} />
                            </div>
                            <h6>{this.props.uData && this.props.uData.username}</h6>
                            <div className="profile-settings">
                                {/* <Isvg src={ArrowDownIcon} /> */}
                                <span className="line"></span>
                                <Link to="/profile"> <Isvg src={SettingsIcon} /></Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
        )
    }
}

export default Header;
