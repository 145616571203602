import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';

import logo from '../../assets/logo.svg';
import certificat from '../../assets/certificat.svg';
import env from "react-dotenv";


class LoginPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            items: [{
                name: 'Test'
            }]
        }
    }

    componentDidMount() {
        this._onLoad();
        if (this.props.uData) {
            this.props.verifyUser(() => this.props[0].history.push('/users'));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uData != this.props.uData && this.props.uData) {
            this.props.verifyUser(() => this.props[0].history.push('/users'));
        }
    }
    login = (data) => {
        // /users/login
        console.log(env.API_URL)

        fetch(env.API_URL + "/users/login", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error
                    })
                } else {

                    localStorage.setItem("authToken", result.token);
                    this.props.verifyUser(() => this.props[0].history.push('/'));



                }
            });
    }

    render() {
        let params = this._getParams();


        return (
            <div>

                <section className="login-page">
                    <div className="login-page-box-container">
                        <div className="login-page-box">
                            <Isvg src={logo} className="logo" />
                            <FormBuilder
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    this.login(data)
                                }}
                                initialValues={{

                                }}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 8 },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'username',
                                                        autoComplete: 'off',
                                                        next: 'password',
                                                        placeholder: 'Korisničko ime',
                                                        asyncValidation: true,
                                                        validate: [
                                                            (value, values) => { if (!value || value == '') return "Pogrešno korisničko ime!" }
                                                        ]
                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'password',
                                                        textType: 'password',
                                                        autoComplete: 'off',
                                                        placeholder: 'Šifra',
                                                        next: 'remember',
                                                        asyncValidation: true,
                                                        validate: [
                                                            (value, values) => { if (!value || value == '') return "Pogrešna lozonka!" }
                                                        ]
                                                    },
                                                    {
                                                        type: 'switch',
                                                        name: 'remember',
                                                        label: 'Zapamti me',
                                                        placeholder: 'Šifra',
                                                    },

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12 },
                                                children: [
                                                    {
                                                        type: 'button',
                                                        action: 'submit',
                                                        children: 'ULOGUJ SE',
                                                        className: ''
                                                    },
                                                ]
                                            }
                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                        </div>
                        {/* <div className="certificat">
                            <Isvg src={certificat} />
                            <div className="certificat-content">
                                <h6>SSL sertifikat: ICM</h6>
                                <p>Važi do: 06.02.2031.</p>
                            </div>
                        </div> */}
                    </div>
                </section>

            </div>
        )
    }
}

export default Page(LoginPage);
