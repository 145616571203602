
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import moment from 'moment';
import Isvg from 'react-inlinesvg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import editIcon from '../../assets/edit-icon.svg';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class FinancialDifferences extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            items: [],
            stockDiff: [],
            tradeDiff: [],
            stockSum: [],
            tradeSum: [],
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
    
        }
    }

    print = async () => {
        this.setState({ loading: true })
        let params = this._getParams();
        let link = await this._apiAsync('print', params)
        // console.log(link);
        if (typeof window != 'undefined') {
            console.log(link.link);
            window.open(env.API_URL + link.link)
            this.setState({ loading: false })
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Finansijske razlike</h1>
                    <div className="title-options-group">
                         <button onClick={this.print}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button> 
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 10 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'code', label: 'SIFRA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'quantity', label: 'KOLICINA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: false },
                                        { type: 'text', name: 'sellPrice', label: 'CIJENA'.translate(this.props.lang), width: 'unset',numberWithCommas: true, multilang: false, allowSort: false },
                                        { type: 'text', name: 'stockSum', label: 'SALDO LAGERA'.translate(this.props.lang), width: 'unset',numberWithCommas: true, multilang: false, allowSort: false },
                                        { type: 'text', name: 'tradeSum', label: 'SALDO PROMETA'.translate(this.props.lang), width: 'unset',numberWithCommas: true, multilang: false, allowSort: false },
                                        { type: 'text', name: 'stockDiff', label: 'RAZLIKA LAGERA'.translate(this.props.lang), width: 'unset',numberWithCommas: true, multilang: false, allowSort: false },
                                        { type: 'text', name: 'tradeDiff', label: 'RAZLIKA PROMETA'.translate(this.props.lang), width: 'unset',numberWithCommas: true, multilang: false, allowSort: false },
                                    ]}
                                    items={this.state.items.map((item, idx) => {

                                        return {
                                            ...item,
                                            stockSum: parseFloat(item.stockSum).toFixed(2),
                                            tradeSum: parseFloat(item.tradeSum).toFixed(2),
                                            stockDiff: parseFloat(item.stockDiff).toFixed(2),
                                            tradeDiff: parseFloat(item.tradeDiff).toFixed(2),
                                        }
                                    })}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Saldo lagera</p>
                                    <span>
                                        {
                                            this.state.stockSum && this.state.stockSum != 0 ?

                                                numberWithCommas(parseFloat(this.state.stockSum).toFixed(2))

                                                :
                                                '0'
                                        }
                                        &nbsp;
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Saldo prometa</p>
                                    <span>
                                        {
                                            this.state.tradeSum && this.state.tradeSum != 0 ?
                                                numberWithCommas(parseFloat(this.state.tradeSum).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Razlika lagera</p>
                                    <span>
                                        {
                                            this.state.stockDiff && this.state.stockDiff != 0 ?
                                                numberWithCommas(parseFloat(this.state.stockDiff).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Razlika prometa</p>
                                    <span>
                                        {
                                            this.state.tradeDiff && this.state.tradeDiff != 0 ?
                                                numberWithCommas(parseFloat(this.state.tradeDiff).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div >
        )
    }
}

export default Page(FinancialDifferences);
