import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import { required, validateDate } from '../../form-builder/validation';
import {
    Container, Input
} from 'reactstrap';
import moment from "moment";
import DeleteModal from "../../components/deleteModal";
import InfoModal from "../../components/infoModal";
import { has } from "loadsh/object";
import { FAKTURA, } from "../../helpers/constants";
import PrintHelper from "../../components/printHelper";
import Checkbox from "../../form-builder/fields/checkbox";
import env from "react-dotenv";
import Loader from "../../components/loader";
import DescriptionMOdal from '../../components/descriptionModal';
import { Player, } from "@lottiefiles/react-lottie-player";
import { debounce } from "lodash";

const TAB_FAKTURA = 1;
const TAB_UNOS = 3;

class OutputPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clientList: true,
            outputForm: null,
            clients: [],
            products: [],
            series: [],
            businessUnits: [],
            clientForm: false,
            entryOut: true,
            buttonActive: null,
            readOnlyBusiness: true,
            items: [],
            outputItems: [],
            postedItems: [],
            item: { stock: null },
            stock: null,
            selectedClient: null,
            selectedProduct: null,
            disableEdit: false,
            disableArticleEdit: false,
            discountModal: null,
            nivelationModal: null,
            deleteModal: null,
            deleteDocumentModal: null,
            printHtml: null,
            exportModal: false,
            exportFieldList: [
                { name: 'documentNumber', label: 'BR. DOK:' },
                { name: 'factureDate', label: 'DATUM' },
                { name: 'clientName', label: 'KOMITENT' },
                { name: 'businessName', label: 'POSLOVNA' },
                { name: 'businessName', label: 'NAZIV PJ' },
                { name: 'item.name', label: 'NAZIV ARTIKLA' },
                { name: 'item.seriesName', label: 'SERIJA' },
                { name: 'item.deadline', label: 'ROK' },
                { name: 'ammount', label: 'KOLIČINA' },
                { name: 'price', label: 'CIJENA' },
                { name: 'discount', label: 'RABAT' },
                { name: 'total', label: 'TOTAL' },
            ],
            fields: [
                { type: 'text', name: 'documentNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'factureDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'businessName', label: 'POSLOVNA'.translate(this.props.lang), multilang: false, allowSort: true },
                { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },

            ],
            exportFields: {},
            exportType: {
                xls: false,
                pdf: false
            }
        }
    }

    setDocumentNumber() {

        this._apiAsync('checkNextDocumentNumber').then((response) => {
            console.log(response);
            let outputForm = this.state.outputForm;
            if (!outputForm) {
                outputForm = {}
            }
            outputForm.factureDate = moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
            this.setState({ outputForm: null }, () => {
                this.setState({ outputForm })
            })
            if (response && response.firstFreeNumber) {
                outputForm.documentNumber = response.firstFreeNumber;
            }

            if (!outputForm.item) {
                outputForm.item = { itemNumber: 1 }
            }
            this.setState({ outputForm: null }, () => {
                this.setState({ outputForm })
            })
            this.setState({ items: [] })
        })
    }

    prepareRequest(data) {
        let newObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (key === 'items' || key === 'totalDiscount' || key === 'totalDiscountKo') {
                continue;
            }
            newObject[key] = value;
        }
        if (has(newObject, 'item')) {
            delete newObject.item.stock;
            delete newObject.item.stockPrice;
            delete newObject.item.fundPrice;
            delete newObject.item.foundPrice;
            if (this.state.selectedProduct) {
                if (this.state.selectedProduct.manufacturer) {
                    newObject.item.manufacturer = this.state.selectedProduct.manufacturer;
                }
                if (this.state.selectedProduct.barcode) {
                    newObject.item.barcode = this.state.selectedProduct.barcode;
                }
            }
        }
        return newObject;
    }

    printOutput = async () => {
        await this._apiAsync('printFaktura', { documentNumber: this.state.outputForm.documentNumber })
    }



    articleSearchMethod = debounce(async (value) => {
        let articleList = await this._apiAsync('ariclesSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('item.article') && articleList)
            this.setState({ articlesSearch: articleList.articlesSearch })
    }, 400)

    seriesSearch = debounce(async (value) => {
        const resultSeries = await this._apiAsync('get-series', {
            article: this.state.selectedProduct ? this.state.selectedProduct.code : undefined,
            sortField: 'expirationDateTs',
            sortType: 1,
            serialNumber: value,
        })
        if (resultSeries && resultSeries.items.length > 0) {
            this.setState({ series: resultSeries.items })
        }
    }, 400);

    reloadArticle = async (item) => {
        const value = item && item.article ? item.article : null
        if (value) {
            let requestArticle = { filter: value };
            if (this.state.selectedClient && this.state.selectedClient.institutionType) {
                requestArticle = { ...requestArticle, ...{ institutionType: this.state.selectedClient.institutionType } };
            }
            const products = await this._apiAsync('get-articles', requestArticle)
            if (products && products.items.length > 0) {
                await this.setState({ products: products && products.items })
            }
            const productObject = this.state.products.find(el => el.code === this.registerGetValue('item.article'))
            this.setState({ selectedProduct: productObject })
            const quantity = has(productObject, 'stock.quantity') ? productObject.stock.quantity : null;
            const stockPrice = has(productObject, 'stock.sellPrice') ? productObject.stock.sellPrice : null;
            const fundPrice = has(productObject, 'stock.fundPrice.price') ? productObject.stock.fundPrice.price : null;
            this.remoteOnChange('item.stock', quantity);
            this.remoteOnChange('item.stockPrice', stockPrice);
            this.remoteOnChange('item.foundPrice', fundPrice);
        }
    }

    insertOrUpdate = async (data, doNivelation = false) => {
        const request = await this.prepareRequest(data);
        if (doNivelation) {
            request.doNivelation = doNivelation
        }
        if (request._id) {
            this._apiAsync('update-document', request).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ outputForm: null }, () => {
                        this.setState({
                            outputForm: res.result,
                            items: res.result.items && res.result.items.length ? res.result.items : [],
                            products: []
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        } else {
            this._apiAsync('insert-new', request).then((res) => {
                if (res.result && res.result._id) {
                    this.setState({ outputForm: null }, () => {
                        this.setState({
                            outputForm: res.result,
                            items: res.result.items && res.result.items.length ? res.result.items : []
                        })
                    })
                    if (res.result.items && res.result.items.length) {
                        this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                            if (res && res.firstFreeItemNumber) {
                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                            }

                        })
                    } else {
                        this.remoteOnChange('item.itemNumber', 1)
                    }

                }
            })
        }
    }
    resetFilter = async () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',
            filterClient: '',
            filterUser: '',
            filterBusiness: '',
            filterType: '',
            filterDocument: ''
        });
    }
    changeTabNavigation = async (value) => {
        if (value === 1) {
            await this.resetFilter();
            this._onLoad()
        } else {
            this.props[0].history.push({})
            this.setDocumentNumber();
        }

        this.setState({ buttonActive: value })

        if (value === 'TAB_UNOS') {
            this.setState({
                disableArticleEdit: false
            })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
        if (this.props[0].location && this.props[0].location.search && this.remoteOnChange) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') !== -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }
            }
        }
        if (this.state.readOnlyBusiness !== prevState.readOnlyBusiness && this.state.readOnlyBusiness === false) {
            this.metodaBusiness('')
        }
    }

    componentDidMount() {


        let params = this._getParams();
        if (params.dateFrom && params.dateFrom !== this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo !== this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client !== this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }
        if (params.user && params.user !== this.state.filterUser) {
            this.setState({ filterUser: params.user })
        }
        if (params.business && params.business !== this.state.filterBusiness) {
            this.setState({ filterBusiness: params.business })
        }
        if (params.type && params.type !== this.state.filterType) {
            this.setState({ filterType: params.type })
        }
        if (params.document && params.document !== this.state.filterDocument) {
            this.setState({ filterDocument: params.document })
        }
        this.setDocumentNumber();
        this.setState({ buttonActive: TAB_UNOS });

    }


    metoda = async (value) => {
        this.articleSearchMethod(value)
    }

    metodaClient = async (value) => {
        console.log(value, 'value client');

        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        if (this.registerGetValue && value === this.registerGetValue('clientCode'))
            this.setState({ clientsSearch: clientList.clientsSearch })
    }

    metodaBusiness = async (value) => {
        if (!(this.state.selectedClient && this.state.selectedClient._id)) {
            return;
        }
        const query = { client: this.state.selectedClient._id };
        const businessUnits = await this._apiAsync('get-business-units', { filter: value, query })
        if (this.registerGetValue && value === this.registerGetValue('business'))
            this.setState({ businessUnits: businessUnits && businessUnits.items })
    }

    metodaSeries = async (value) => {
        this.seriesSearch(value)
    }

    checkDocument() {
        this._apiAsync('checkDocumentNumber', { documentNumber: this.state.outputForm.documentNumber }).then((res) => {
            if (this.registerGetValue('documentNumber') === res.result.documentNumber) {
                if (res && res.result && res.result._id) {
                    this.setState({ selectedClient: res.result.clientInfo })
                    this.setState({ outputForm: null }, () => {
                        this.setState({ outputForm: res.result }, () => {

                            if (res.result.items && res.result.items.length) {
                                const fromConditionDiscount = res.result.items.find((item) => item.fromConditionDiscount);
                                this.setState({ fromConditionDiscount: !!fromConditionDiscount });
                                this.setState({
                                    items: res.result.items,
                                }, () => {
                                    // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                    this._apiAsync('checkNextItemNumber', { documentNumber: this.state.outputForm.documentNumber }).then((res) => {
                                        if (res && res.firstFreeItemNumber) {
                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                        }
                                    })
                                })
                            } else {
                                this.setState({
                                    items: [],
                                }, () => {
                                    this.remoteOnChange('item.itemNumber', 1)
                                })
                            }
                            if (this.state.outputForm && this.state.outputForm.status === FAKTURA) {
                                this.setState({ disableEdit: true })
                            } else {
                                this.setState({ disableEdit: false })
                            }
                        })
                    })
                }
            }
        })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                }
                <div className="section-title title-options">
                    <h1>Naturalni rabat</h1>
                    {
                        this.state.buttonActive === TAB_FAKTURA ?
                            <div className="title-options-group">
                                <button onClick={async () => {
                                    this.setState({
                                        loadingExport: true
                                    })
                                    const params = {}
                                    for (const [key, value] of Object.entries(this._getParams())) {
                                        if (key === 'entries' || key === 'page') {
                                            continue;
                                        }
                                        params[key] = value;
                                    }
                                    const request = {
                                        filter: {
                                            entries: 10000,
                                            page: 0,
                                            additionalFilters: params
                                        },
                                        methods: 'post',
                                        url: 'data/naturalni-rabat',
                                        fields: [
                                            { key: 'documentNumber', label: 'BROJ' },
                                            { key: 'factureDate', label: 'DATUM' },
                                            { key: 'clientName', label: 'KOMITENT' },
                                            { key: 'businessName', label: 'POSLOVNA' },
                                            { key: 'createdByUsername', label: 'KORISNIK' },
                                        ],
                                        title: "Naturalni rabat"
                                    }
                                    let print = await this._apiAsync('generic-print', request)
                                    if (print) {
                                        this.setState({
                                            loadingExport: false
                                        })
                                    }

                                }}>
                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={() => {
                                    this.setState({ exportModal: true })
                                    let obj = {}
                                    this.state.exportFieldList.map((item) =>
                                        obj[item.name] = true
                                    )
                                    this.setState({ exportFields: obj })

                                }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    this.state.entryOut ?
                        <div className="table-tabs">
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive === TAB_FAKTURA ? 'active' : ''}
                                    onClick={() => this.changeTabNavigation(TAB_FAKTURA)}>
                                    Lista naturalni rabat
                                </button>

                                <button className={this.state.buttonActive === TAB_UNOS ? 'active' : ''}
                                    onClick={() => this.changeTabNavigation(TAB_UNOS)}>
                                    Unos naturalnog rabata
                                </button>
                            </div>

                        </div>
                        :
                        null
                }
                {
                    this.state.buttonActive === TAB_UNOS ?
                        <>

                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'item.article', 'item.serialNumber', 'item.ammount', 'item.price', 'item.discount', 'clientCode', 'business']}
                                    fieldTracker={async (name, value) => {
                                        if (name === 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, outputForm: null }, () => {
                                                this.setState({ outputForm: {} })
                                            })
                                        }
                                        if (name === 'documentNumber') {
                                            if (value) {
                                                this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {

                                                    if (res && res.result && this.registerGetValue('documentNumber') === res.result.documentNumber) {
                                                        if (res && res.result && res.result._id) {
                                                            this.setState({ selectedClient: res.result.clientInfo })
                                                            this.setState({ outputForm: null }, () => {
                                                                this.setState({ outputForm: res.result }, () => {

                                                                    if (res.result.items && res.result.items.length) {
                                                                        const fromConditionDiscount = res.result.items.find((item) => item.fromConditionDiscount);
                                                                        this.setState({ fromConditionDiscount: !!fromConditionDiscount });
                                                                        this.setState({
                                                                            items: res.result.items,
                                                                        }, () => {
                                                                            // this.remoteOnChange('item.itemNumber', (res.result.items.length + 1))
                                                                            this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                                if (res && res.firstFreeItemNumber) {
                                                                                    this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                                }
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            items: [],
                                                                        }, () => {
                                                                            this.remoteOnChange('item.itemNumber', 1)
                                                                        })
                                                                    }
                                                                    if (this.state.outputForm && this.state.outputForm.status === FAKTURA) {
                                                                        this.setState({ disableEdit: true })
                                                                    } else {
                                                                        this.setState({ disableEdit: false })
                                                                    }
                                                                })
                                                            })
                                                        } else if (this.state.prevDocumentNumber !== value && this.state.outputForm._id) {
                                                            this.setState({ disableEdit: false })
                                                            this.setState({ outputForm: null }, () => {
                                                                this.setState({ outputForm: { documentNumber: value } }, () => {

                                                                    this.setState({ items: [], outputForm: {} }, () => {
                                                                        // this.remoteOnChange('item.itemNumber', 1)
                                                                        this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                            if (res && res.firstFreeItemNumber) {
                                                                                this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                                            }

                                                                        })
                                                                    })

                                                                })
                                                            })

                                                        }
                                                        this.setState({ prevDocumentNumber: value })
                                                    } else {
                                                        this.setState({ outputForm: null });
                                                        this.setState({ items: null });
                                                        this.setState({ selectedClient: null });
                                                    }
                                                })
                                            }


                                        }
                                        if (name === 'item.serialNumber' && value) {
                                            const seriesObject = this.state.series.find(el => el.serialNumber === value)
                                            if (seriesObject) {
                                                this.remoteOnChange('item.deadline', seriesObject.expirationDate ? seriesObject.expirationDate : null);
                                                this.remoteOnChange('item.stock', seriesObject.ammount);
                                                this.setState({ selectedSeries: seriesObject })
                                            }
                                        }
                                        if (name === 'item.deadline' && value) {
                                            const deadlineObject = this.state.deadlines.find(el => el.expirationDate === value);
                                            if (deadlineObject && deadlineObject.expirationDate) {
                                                this.remoteOnChange('item.stock', deadlineObject.ammount)
                                            }
                                        }
                                        if (name === 'item.article') {
                                            if (value) {
                                                const productObject = this.state.articlesSearch?.find(el => el.code === this.registerGetValue('item.article'))
                                                if (productObject) {
                                                    this.setState({ selectedProduct: productObject });
                                                    const quantity = productObject.stock && productObject.stock.quantity ? productObject.stock.quantity : 0;
                                                    const price = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                    const stockPrice = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                    const fundPrice = has(productObject, 'stock.fundPrice.price') ? productObject.stock.fundPrice.price : null;


                                                    this.remoteOnChange('item.price', fundPrice ? fundPrice : price);
                                                    this.remoteOnChange('item.stockPrice', stockPrice);
                                                    this.remoteOnChange('item.fundPrice', fundPrice);
                                                    this.remoteOnChange('item.stock', quantity);


                                                    const resultSeries = await this._apiAsync('get-series', {
                                                        article: productObject.code,
                                                        productId: productObject._id,
                                                        sortField: 'expirationDateTs',
                                                        sortType: 1,
                                                        withoutSeries: true
                                                    })
                                                    if (resultSeries && resultSeries.items.length > 0) {
                                                        await this.setState({ series: resultSeries.items })
                                                    } else {
                                                        this.remoteOnChange('item.stock', quantity);
                                                        await this.setState({ series: [] })
                                                        this.remoteOnChange('item.serialNumber', null)
                                                        this.remoteOnChange('item.deadline', '')
                                                        await this.setState({ deadlines: [] })
                                                    }
                                                    const resultDeadlines = await this._apiAsync('get-series', {
                                                        article: productObject.code,
                                                        productId: productObject._id,
                                                        sortField: 'expirationDateTs',
                                                        sortType: 1,
                                                        deadline: true,
                                                    })
                                                    if (resultDeadlines && resultDeadlines.items.length > 0) {
                                                        await this.setState({ deadlines: resultDeadlines.items })
                                                    } else {
                                                        await this.setState({ deadlines: [] })
                                                    }
                                                } else {
                                                    this.remoteOnChange('item.stock', null);
                                                    this.remoteOnChange('item.stockPrice', null);
                                                    this.remoteOnChange('item.price', null);
                                                    this.remoteOnChange('item.serialNumber', null)
                                                    this.remoteOnChange('item.deadline', '')
                                                    this.remoteOnChange('item.fundPrice', null);
                                                }
                                            } else {
                                                this.remoteOnChange('item.stock', null);
                                                this.remoteOnChange('item.stockPrice', null);
                                                this.remoteOnChange('item.price', null);
                                                this.remoteOnChange('item.serialNumber', null)
                                                this.remoteOnChange('item.deadline', '')
                                                this.remoteOnChange('item.fundPrice', null);
                                            }

                                        }
                                        if (name === 'item.price' && value) {
                                            let total = this.registerGetValue('item.ammount') && value ? this.registerGetValue('item.ammount') * value : null
                                            const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.ammount' && value) {
                                            let total = this.registerGetValue('item.price') && value ? this.registerGetValue('item.price') * value : null
                                            const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.discount' && value) {
                                            let total = this.registerGetValue('item.price') && this.registerGetValue('item.ammount') ? this.registerGetValue('item.price') * this.registerGetValue('item.ammount') : null;
                                            const discountValue = total && value ? Number(total) / 100 * value : 0;
                                            this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        }
                                        if (name === 'item.discount' && value) {
                                            if (this.state.selectedClient && this.state.selectedProduct) {
                                                await this._apiAsync('check-product-discount', {
                                                    productId: this.state.selectedProduct._id,
                                                    clientId: this.state.selectedClient._id
                                                }).then((response) => {
                                                    if (response && response.result && response.result.percent && Number(value) > Number(response.result.percent)) {
                                                        this.setState({ discountModal: response.result });
                                                    }
                                                })
                                            }
                                        }
                                        if (name === 'business' && value) {
                                            const businessUnits = await this._apiAsync('get-business-units', {
                                                filter: value,
                                                query: { client: this.state.selectedClient._id }
                                            })
                                            if (businessUnits && businessUnits.items) {
                                                this.setState({ businessUnits: businessUnits && businessUnits.items }, () => {
                                                    if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                        this.remoteOnChange('businessName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                                    } else {
                                                        this.remoteOnChange('businessName', '')
                                                    }
                                                })
                                            }
                                        }
                                        if (name === 'clientCode' && value) {
                                            //naturalDiscount
                                            const clients = await this._apiAsync('get-clients', { filter: value })

                                            if (clients && clients.items) {
                                                this.remoteOnChange('business', '')
                                                this.remoteOnChange('businessName', '')
                                                this.setState({ clients: clients && clients.items }, async () => {
                                                    const clientObject = this.state.clients.find(item => Number(item.code) === Number(value))
                                                    this.setState({ selectedClient: clientObject })
                                                    if (clientObject) {
                                                        this.remoteOnChange('clientName', clientObject.name)
                                                        const checkBusinessUnitForClient = await this._apiAsync('check-client-exists-in-business-units', { clientId: clientObject._id });
                                                        this.setState({ readOnlyBusiness: checkBusinessUnitForClient.status !== 200 })
                                                    } else {
                                                        this.remoteOnChange('clientName', '')
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    }
                                    onSubmit={(data) => {
                                        if (data && data.item && data.item.price && data.item.stockPrice && Number(data.item.price) !== Number(data.item.stockPrice)) {
                                            this.setState({ nivelationModal: data })
                                            return 'open-modal';
                                        } else {
                                            if (data.clientCode) {
                                                data.clientCode = Number(data.clientCode)
                                            }
                                            this.insertOrUpdate(data);
                                        }

                                        this.setState({
                                            disableArticleEdit: false
                                        })
                                    }}
                                    initialValues={this.state.outputForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: []
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand',
                                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                                    children: []
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom-sm',
                                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [

                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.outputForm && this.state.outputForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {

                                                                                                this.setState({ printHtml: null }, () => {
                                                                                                    this.setState({ loadingPage: true })
                                                                                                    this.printOutput().then(() => {
                                                                                                        this.setState({ loadingPage: false })
                                                                                                    })
                                                                                                }
                                                                                                )

                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-4",
                                                    style: { paddingRight: 0 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            integer: true,
                                                            name: 'documentNumber',
                                                            next: 'factureDate',
                                                            label: 'Broj dok.',
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'factureDate',
                                                            next: 'deliveryDate',
                                                            label: 'Datum fakturisanja',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value === '') return "Datum izlaza mora biti unesen!"
                                                                },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'deliveryDate',
                                                            next: 'clientCode',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value === '') return "Datum isporuke mora biti unesen!"

                                                                    if (moment(value, 'DD.MM.YYYY').unix() < moment(this.registerGetValue('deliveryDate'), 'DD.MM.YYYY').unix()) {
                                                                        return "Mora biti veći ili jednak od datuma fakturisanja";
                                                                    }
                                                                },
                                                                validateDate('Datum isporuke nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'client',
                                                                            name: 'clientCode',
                                                                            next: !(this.state.readOnlyBusiness || this.state.disableEdit) ? 'business' : 'facture',
                                                                            textType: 'number',
                                                                            metoda: (value) => { this.metodaClient(value) },
                                                                            dataModal: this.state.clientsSearch,
                                                                            label: 'Šifra kom.',
                                                                            values: [...this.state.clients.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value === '') return "Šifra dokumenta mora biti unesena!"

                                                                                },

                                                                                (value) => {
                                                                                    if (this.state.clientsSearch.length === 0 && this.state.clients.filter(item => item.code == value).length === 0 && this.state.clients.filter(item => item.name === value).length === 0) {
                                                                                        return "Neispravan unos ili izabrani komitent nema dozvoljen rabat!"
                                                                                    }

                                                                                    if (this.state.clientsSearch.length !== 0 && this.state.clientsSearch.filter(item => item.code == value).length === 0 && this.state.clientsSearch.filter(item => item.name == value).length === 0) {
                                                                                        return "Neispravan unos ili izabrani komitent nema dozvoljen rabat!"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'clientName',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'businessUnit',
                                                                            name: 'business',
                                                                            label: 'Poslovna',
                                                                            next: 'facture',
                                                                            metoda: (value) => { this.metodaBusiness(value) },
                                                                            dataModal: this.state.businessUnits,
                                                                            readOnly: this.state.readOnlyBusiness || this.state.disableEdit,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            values: [...this.state.businessUnits.map(item => {
                                                                                let obj = {
                                                                                    name: item.name,
                                                                                    value: item.code
                                                                                }
                                                                                return obj
                                                                            })],
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (value) {
                                                                                        if (this.state.businessUnits.length === 0 && this.state.businessUnits.filter(item => item.code == value).length === 0 && this.state.businessUnits.filter(item => item.name === value).length === 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.businessUnits.length !== 0 && this.state.businessUnits.filter(item => item.code == value).length === 0 && this.state.businessUnits.filter(item => item.name === value).length === 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }

                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'businessName',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'width-default',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'facture',
                                                                            next: 'item.article',
                                                                            label: 'Faktura',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value === '') return "Datum isporuke mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-3",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            label: 'R. br.',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.article',
                                                            next: 'item.serialNumber',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit || this.state.disableArticleEdit,
                                                            asyncValidation: true,
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code,
                                                                    disabled: item.disabled ? true : false,
                                                                }
                                                                return obj
                                                            })],
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value === '') return "Artikal mora biti unesen!"
                                                                },
                                                                (value) => {

                                                                    if (this.state.articlesSearch && this.state.articlesSearch?.length === 0 && this.state.products.filter(item => item.code === value).length === 0 && this.state.products.filter(item => item.name === value).length === 0) {
                                                                        return "Neispravan unos!"
                                                                    }
                                                                    if (this.state.articlesSearch && this.state.articlesSearch?.length !== 0 && this.state.articlesSearch.filter(item => item.code === value).length === 0 && this.state.articlesSearch.filter(item => item.name === value).length === 0) {
                                                                        return "Neispravan unos!!"
                                                                    }

                                                                    this.setState({ numberOfSeries: this.state.articlesSearch && this.state.articlesSearch.filter(item => item.code === value)[0].series.length })
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'item.serialNumber',
                                                            next: 'item.ammount',
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit || this.state.disableArticleEdit,
                                                            asyncValidation: true,
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber,
                                                                }
                                                                return obj
                                                            })],
                                                            validate: [
                                                                (value) => {

                                                                    if (this.state.series.length !== 0 && !value) {
                                                                        return 'Serija mora biti unešena!!'
                                                                    }
                                                                    if (this.state.series.length === 0 && this.state.numberOfSeries !== 0) {
                                                                        return 'Neispravan unos!!'
                                                                    }

                                                                    if (this.state.numberOfSeries === 0 && value) {
                                                                        return 'Neispravan unos!'
                                                                    }

                                                                }
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            label: 'Rok',
                                                            finishField: true,
                                                            // readOnly: this.state.disableEdit || this.state.disableArticleEdit,
                                                            readOnly: true,
                                                            asyncValidation: true,

                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.ammount',
                                                                            next: 'item.price',
                                                                            label: 'Količina',
                                                                            integer: true,
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value === '') return "Količina mora biti unesena!";
                                                                                    if (value > this.registerGetValue('item.stock')) {
                                                                                        return "Količina mora biti manja od stanja zaliha";
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'decimal',
                                                                            name: 'item.price',
                                                                            next: 'submitForm',
                                                                            label: 'Cijena',
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value === '') return "Cijena mora biti unesena!"
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stockPrice',
                                                                            label: 'Lager c.',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,

                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.fundPrice',
                                                                            label: 'Fond cijena',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'item.total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            readOnly: true,
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                />
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        this.state.disableEdit === false ?
                                            [
                                                {
                                                    component: <Isvg src={editIcon} />,
                                                    onClick: (item) => {
                                                        this.metoda(Number(item.article)).then(async () => {
                                                            let series = await this._apiAsync('get-series', { article: item.article });
                                                            if (series && series.items) {
                                                                this.setState({ series: series.items })
                                                            } else {
                                                                this.setState({ series: [] })
                                                            }
                                                        })
                                                        let outputForm = this.state.outputForm;
                                                        outputForm.item = item;
                                                        this.setState({ outputForm: null }, () => {
                                                            this.setState({ outputForm })
                                                        })

                                                        this.reloadArticle(item)

                                                        //disable serial number, article and deadline for edit item
                                                        this.setState({
                                                            disableArticleEdit: true
                                                        })
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={garbageIcon} />,
                                                    onClick: (item) => this.setState({ deleteModal: item })
                                                }
                                            ]
                                            : []
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-item', {
                                            _id: this.state.outputForm._id,
                                            itemNumber: this.state.deleteModal.itemNumber
                                        }).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ outputForm: null, deleteModal: null }, () => {
                                                    this.setState({
                                                        outputForm: res.result,
                                                        items: res.result.items && res.result.items.length ? res.result.items : []
                                                    })
                                                })
                                                if (res.result.items && res.result.items.length) {
                                                    this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                        if (res && res.firstFreeItemNumber) {
                                                            this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                        }

                                                    })
                                                } else {
                                                    this.remoteOnChange('item.itemNumber', 1)
                                                }

                                            }
                                        })
                                    }}>
                                    Obrisati
                                    stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                                </DeleteModal>
                                <DeleteModal
                                    isOpen={this.state.deleteDocumentModal}
                                    toggle={() => this.setState({ deleteDocumentModal: null })}
                                    handler={() => {
                                        if (this.state.deleteDocumentModal && this.state.deleteDocumentModal.isBooked) {
                                            this.setState({ deleteDocumentModal: null })
                                            this.setState({ error: 'Dokument je knjižen i ne može se obrisati!' })
                                            return;
                                        } else {
                                            this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                                this.setState({
                                                    deleteDocumentModal: null,
                                                    outputForm: null,
                                                    items: null,
                                                    firstFreeNumber: null
                                                }, () => {
                                                    this.setState({ outputForm: {} }, () => this._onLoad())
                                                })

                                            })
                                        }
                                    }}>
                                    Obrisati
                                    dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                                </DeleteModal>
                                <InfoModal
                                    isOpen={this.state.nivelationModal}
                                    toggle={() => this.setState({ nivelationModal: null })}
                                    title={'Obavještenje'}
                                    info={'Došlo je do promjene cijene i izvršiće se nivelacija. Da li želite da uradite NIVELACIJU KALKULACIJE ili NIVELACIJU LAGERA'}
                                    buttons={[
                                        {
                                            text: 'NIVELACIJA KALKULACIJE',
                                            onClick: () => {
                                                let outputForm = this.state.nivelationModal;
                                                if (outputForm && outputForm.item && outputForm.item.stockPrice) {
                                                    outputForm.item.price = outputForm.item.stockPrice;
                                                }
                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(outputForm))
                                            }
                                        },
                                        {
                                            text: 'NIVELACIJA LAGERA',
                                            onClick: () => {
                                                let outputForm = this.state.nivelationModal;
                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(outputForm, true))
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                                <InfoModal
                                    isOpen={this.state.discountModal}
                                    toggle={() => this.setState({ discountModal: null })}
                                    title={'Obavještenje'}
                                    info={'Unijeli ste veći rabat nego na uslovima. Da li želite da nastavite ?'}
                                    buttons={[
                                        {
                                            text: 'DA',
                                            onClick: () => {

                                                this.setState({ discountModal: null })
                                            }
                                        },
                                        {
                                            text: 'NE',
                                            onClick: () => {
                                                this.remoteOnChange('item.discount', null)
                                                this.setState({ discountModal: null });
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                            </div>

                            <div className="footer">
                                <div className="footer-left">
                                    <button className="blue-button" onClick={() => {
                                        this.setState({
                                            opisModal: true
                                        })
                                    }}>
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>OPIS</p>
                                    </button>
                                    {this.state.outputForm && this.state.outputForm._id ?
                                        <button
                                            onClick={() => this.setState({ deleteDocumentModal: this.state.outputForm })}>
                                            <div className="box box-red">
                                                <Isvg src={garbageOpionIcon} />
                                            </div>
                                            <p>BRISANJE DOKUMENTA</p>
                                        </button>
                                        : null
                                    }


                                </div>
                                {/* <div className="modified-div-wrap">
                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                        this.props.getLastChange({
                                            itemId: this.state.outputForm && this.state.outputForm._id,
                                            collection: 'naturalniRabat'
                                        })
                                    }}>Poslednja izmjena</div>
                                </div> */}
                                <div className="footer-right">

                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>{this.state.outputForm && this.state.outputForm.total ? (Number(this.state.outputForm.total)).toFixed(2) : 0} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>{this.state.outputForm && this.state.outputForm.total && this.state.outputForm.totalWithPdv ? (this.state.outputForm.totalWithPdv - this.state.outputForm.total).toFixed(2) : 0} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>{this.state.outputForm && this.state.outputForm.totalWithPdv ? this.state.outputForm.totalWithPdv : 0} KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        this.state.buttonActive === TAB_FAKTURA ?
                            <>
                                <div className="list-builder-filters-wrap-output">
                                    <div className="list-builder-filters">
                                        <div className="field-strap-wrap">
                                            <Input type='date'
                                                value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='date'
                                                value={this.state.filterDateTo !== null ? this.state.filterDateTo : params.dateTo}
                                                onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterClient !== null ? this.state.filterClient : params.client}
                                                onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterBusiness !== null ? this.state.filterBusiness : params.business}
                                                onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                                    this.updateParams('business', this.state.filterBusiness)
                                                })} placeholder={'Poslovna'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterUser !== null ? this.state.filterUser : params.user}
                                                onChange={(e) => this.setState({ filterUser: e.target.value }, () => {
                                                    this.updateParams('user', this.state.filterUser)
                                                })} placeholder={'Korisnik'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterType !== null ? this.state.filterType : params.type}
                                                onChange={(e) => this.setState({ filterType: e.target.value }, () => {
                                                    this.updateParams('type', this.state.filterType)
                                                })} placeholder={'Vrsta'} />
                                        </div>
                                        <div className="field-strap-wrap">
                                            <Input type='text'
                                                value={this.state.filterDocument !== null ? this.state.filterDocument : params.document}
                                                onChange={(e) => this.setState({ filterDocument: e.target.value }, () => {
                                                    this.updateParams('document', this.state.filterDocument)
                                                })} placeholder={'Dokument'} />
                                        </div>
                                    </div>
                                    <div className="clear-filters" onClick={() => {
                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterUser: '',
                                            filterBusiness: '',
                                            filterType: '',
                                            filterDocument: '',
                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, {
                                                name: 'dateTo',
                                                value: null
                                            }, { name: 'client', value: null }, {
                                                name: 'user',
                                                value: null
                                            }, { name: 'business', value: null }, {
                                                name: 'type',
                                                value: null
                                            }, { name: 'document', value: null }])
                                        })
                                    }}>
                                        <span>&times;</span> Ukloni filter
                                    </div>

                                </div>
                                <div className="mt-15">
                                    <ListBuilder
                                        // showCheckBox={true}
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        fields={this.state.fields}
                                        items={this.state.outputItems}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    />
                                </div>
                            </>
                            :

                            <>
                            </>
                }
                <InfoModal
                    isOpen={this.state.exportModal}
                    toggle={() => this.setState({ exportModal: false })}
                    title={'Export'}
                    info={
                        <div className={'container'}>
                            <div className="row">
                                {
                                    this.state.exportFieldList.map((item, idx) => {
                                        return (
                                            <div key={idx} className={'col-md-6 mb-2 '}>
                                                <Checkbox checked={this.state.exportFields[item.name]}
                                                    className={'mr-2'} onChange={(e) => {
                                                        const value = !this.state.exportFields[item.name]
                                                        this.setState({
                                                            exportFields: { ...this.state.exportFields, [item.name]: value }
                                                        })
                                                    }} label={item.label} />

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    }

                    buttons={[

                        {
                            text: 'Export',
                            onClick: async () => {
                                let fields = [];
                                for (const [key, value] of Object.entries(this.state.exportFields)) {
                                    if (value) {
                                        const obj = this.state.exportFieldList.find((el) => el.name === key)
                                        fields.push({ key, label: obj.label })
                                    }
                                }
                                const request = {
                                    filter: {
                                        entries: 100,
                                        page: 0
                                    },
                                    methods: 'post',
                                    url: 'data/naturalni-rabat',
                                    fields,
                                }
                                const exportResult = await this._apiAsync('generic-export', request)
                                if (exportResult && exportResult) {
                                    if (exportResult.success) {
                                        window.location.href = env.API_URL + `${exportResult.file}`;
                                    }
                                }
                            }
                        }
                    ]}

                >
                </InfoModal>
                <iframe name="my-frame" id="my-frame" title="my-frame" style={{ display: 'none' }} ></iframe>
                <DescriptionMOdal
                    isOpen={this.state.opisModal}
                    toggle={() => this.setState({ opisModal: null })}
                    value={this.state.outputForm && this.state.outputForm.description ? this.state.outputForm.description : ''}
                    onChange={(description) => {
                        this.setState({
                            outputForm: {
                                description: description
                            },
                        })
                    }}
                />
                {
                    this.state.loadingExport ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/loader.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                }

                <InfoModal
                    isOpen={this.state.naturalDiscountModal}
                    toggle={() => this.setState({ naturalDiscountModal: null })}
                    title={'Upozorenje'}
                    info={this.state.naturalDiscountModal && this.state.naturalDiscountModal.name ? this.state.naturalDiscountModal.name + ' nije dozvoljen naturalni rabat!' : 'Izabranom komitentu nije dozvoljen naturalni rabat'}
                    buttons={[
                        {
                            text: 'Ok',
                            onClick: () => this.setState({ naturalDiscountModal: null })
                        },

                    ]}
                >
                </InfoModal>
            </div>
        )
    }
}

export default Page(OutputPage);
