import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import env from "react-dotenv";
import PrintHelper from '../../components/printHelper';
import AlmbihModal from '../../components/almbihModal';
import Checkbox from '../../form-builder/fields/checkbox';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            overlayWhole: false,
            total: 20,
            unitsList: true,
            unitsForm: false,
            items: [],
            clients: [],
            places: [],
            businessUnits: [],
            clientExists: true,
            clientsSearch: [],
            almbihData: {},
            articleTypes: [],
            modalAlmbih: false,
            institutionType: [],
            iqvia: [],
            countries: [],
            almbihButton: false,
            error: '',
            printTable: false,
            exportTable: false,
            entity: null
        }
    }

    changeState = (key) => {
        this.state[key] = false;
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (this.state.unitsForm && this.state.unitsForm != prevState.unitsForm) {
            if (this.state.unitsForm.place) {
                
                this._api('countriesAPI', { data: this.state.unitsForm.place }, (result) => {
                    
                    this.setState({
                        entity: result?.places?.entity
                    })
                });
            }
            
        }
    }
    
    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    print = async () => {
        let params = this._getParams();
        let printHtml = await this._apiAsync('print', params)
        if (printHtml && printHtml.printHtml) {
            this.setState({ printHtml: printHtml.printHtml })
        }
    }

    export = async () => {
        let params = this._getParams();
        let exportResult = await this._apiAsync('export', params)
        if (exportResult && exportResult.response) {
            if (exportResult.response.success) {
                window.location.href = env.API_URL + `${exportResult.response.file}`;
            }
        }
    }


    render() {
        let params = this._getParams();
        return (
            <div>

                <div className={this.state.modal ? 'overlay-whole shown' : 'overlay-shown'} onClick={() => {
                    this.setState({ modal: false })
                }}></div>
                <div className="section-title title-options">
                    <h1>Poslovne jedinice</h1>
                    {
                        !this.state.unitsForm ?
                            <div className="title-options-group">
                                <button onClick={async () => { this.setState({ printTable: true }) }}>

                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={async () => { this.setState({ exportTable: true }) }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                                {
                                    this.state.printHtml ?
                                        <PrintHelper html={this.state.printHtml} />
                                        :
                                        null
                                }

                            </div>
                            :
                            <div className="design-helper" style={{ height: 52, opacity: 0 }}></div>
                    }
                </div>

                {
                    this.state.unitsForm ?
                        <div className="table-tabs">
                            <button onClick={() => this.setState({ unitsForm: false })}>
                                Lista poslovnih jedinica
                            </button>
                            <button className="active" onClick={() => this.setState({ unitsForm: true })}>
                                Dodaj poslovnu jedinicu
                            </button>

                        </div>
                        :
                        null
                }
                {
                    this.state.unitsForm ?

                        <div className="ml-15">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['client', 'code', 'place']}
                                fieldTracker={async (name, value) => {

                                    if (name == 'client' && value) {

                                        let clients = await this._apiAsync('get-clients', { filter: value })
                                        if (clients && clients.items && clients.items.length != 0) {

                                            if (clients && clients.items && clients.items.filter(element => element.code == value).length != 0) {
                                                this.setState({ clientExists: true })

                                                let tempClient
                                                if (!isNaN(value)) {
                                                    tempClient = clients.items.filter(item => {
                                                        if (item.code == Number(value)) {
                                                            return item
                                                        }
                                                    })
                                                    if (tempClient && tempClient.length == 0) {
                                                        this.setState({ clientExists: false })
                                                    }

                                                }

                                                this.setState({ clients: clients.items }, async () => {

                                                    this._apiAsync('businessUnitsRead', { clientId: clients.items.filter(element => element.code == value)[0]._id }).then((businessUnits) => {
                                                        if (businessUnits && businessUnits.result.length != 0) {
                                                            this.setState({ businessUnits: businessUnits.result })
                                                        } else {
                                                            this.setState({ businessUnits: [] })
                                                        }

                                                        this._apiAsync('checkNextDocumentNumber', { clientId: clients.items.filter(element => element.code == value)[0]._id }).then((broj) => {

                                                            if (broj) {
                                                                this.remoteOnChange('code', broj.firstFreeNumber)
                                                            }

                                                        })
                                                    })
                                                })
                                            }

                                        } else if (clients.items.length == 0 || clients.items.filter(element => element.code == value).length == 0) {
                                            this.setState({ clientExists: false })
                                        }
                                    }

                                    if (name == 'client' && !value) {
                                        this.setState({ unitsForm: null }, () => {
                                            this.setState({ unitsForm: [] })
                                        })
                                    }

                                    if (name == 'code' && value) {

                                        let businessUnit = this.state.businessUnits.filter((item) => {
                                            if (item.code == Number(value)) {
                                                return item
                                            }
                                        })

                                        if (businessUnit && businessUnit.length != 0) {
                           
                                            this.remoteOnChange('name', businessUnit[0].name)
                                            this.remoteOnChange('place', businessUnit[0].place)
                                            this.remoteOnChange('address', businessUnit[0].address)
                                            this.remoteOnChange('phone', businessUnit[0].phone)
                                            this.remoteOnChange('email', businessUnit[0].email)
                                            this.remoteOnChange('jib', businessUnit[0].jib)
                                            this.remoteOnChange('iqvia', businessUnit[0].iqvia)
                                            this.remoteOnChange('institutionType', businessUnit[0].institutionType)
                                            this.remoteOnChange('description', businessUnit[0].description)
                                            this.remoteOnChange('active', businessUnit[0].active)

                                            if (businessUnit[0].institutionType && this.state.institutionType.filter(item => item.code.toString() == businessUnit[0].institutionType)[0].name == 'Veleprodaja') {
                                                if (businessUnit[0].countryName = 'BIH') {
                                                    this.setState({ almbihButton: true })
                                                }
                                            } else {
                                                this.setState({ almbihButton: false })
                                            }

                                            if (businessUnit[0].modifiedByUsername) {
                                                this.setState({ userNameModified: businessUnit[0].modifiedByUsername })
                                            }

                                            if (businessUnit[0].tsModified) {

                                                let dan = new Date(businessUnit[0].tsModified * 1000).getDate()
                                                let mjesec = new Date(businessUnit[0].tsModified * 1000).getMonth() + 1
                                                let godina = new Date(businessUnit[0].tsModified * 1000).getFullYear()
                                                let sati = new Date(businessUnit[0].tsModified * 1000).getHours()
                                                let minuti = new Date(businessUnit[0].tsModified * 1000).getMinutes()

                                                if (dan < 10) {
                                                    dan = '0' + dan
                                                }

                                                if (mjesec < 10) {
                                                    mjesec = '0' + mjesec
                                                }

                                                if (sati < 10) {
                                                    sati = '0' + sati
                                                }

                                                if (minuti < 10) {
                                                    minuti = '0' + minuti
                                                }

                                                this.setState({ timeModified: { dan: dan, mjesec: mjesec, godina: godina, sati: sati, minuti: minuti } }, () => {
                                                    this.setState({ userString: this.state.timeModified.dan + '.' + this.state.timeModified.mjesec + '.' + this.state.timeModified.godina + ' - ' + this.state.timeModified.sati + ':' + this.state.timeModified.minuti })
                                                })


                                            }
                                            this.setState({ unitsForm: businessUnit[0] })
                                            this.setState({ unitForm: businessUnit[0] })

                                        } else {
                                            this.remoteOnChange('name', '')
                                            this.remoteOnChange('place', '')
                                            this.remoteOnChange('address', '')
                                            this.remoteOnChange('phone', '')
                                            this.remoteOnChange('email', '')
                                            this.remoteOnChange('jib', '')
                                            this.remoteOnChange('iqvia', '')
                                            this.remoteOnChange('institutionType', '')
                                            this.remoteOnChange('description', '')
                                            this.setState({ almbihButton: false })

                                            this.setState({ userNameModified: '' })
                                            this.setState({ timeModified: null })
                                        }
                                    }

                                    if (name == 'code' && !value) {
                                        this.remoteOnChange('name', '')
                                        this.remoteOnChange('place', '')
                                        this.remoteOnChange('address', '')
                                        this.remoteOnChange('phone', '')
                                        this.remoteOnChange('email', '')
                                        this.remoteOnChange('jib', '')
                                        this.remoteOnChange('iqvia', '')
                                        this.remoteOnChange('institutionType', '')
                                        this.remoteOnChange('description', '')

                                        this.setState({ userNameModified: '' })
                                        this.setState({ timeModified: null })
                                    }

                                    if (name == 'place' && value) {
                                        this._api('countriesAPI', { data: value }, (result) => {
                                            this.setState({
                                                entity: result.places.entity
                                            })
                                        });
                                    }

                                }}

                                onSubmit={(data) => {
                                    data = { ...data, ...this.state.almbihData }

                                    if (this.state.unitsForm && this.state.unitsForm._id) {
                                        data = { ...data, _id: this.state.unitsForm._id }

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ unitsForm: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {
                                        this._api('api2', { data: data }, () => {
                                            this.setState({ unitsForm: {} }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.unitsForm && this.state.unitsForm._id ? this.state.unitsForm : {}}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up',
                                                width: { lg: 5, sm: 5, xs: 5 },
                                                children: [

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                className: 'col-up col-up-right buttons-position',
                                                width: { lg: 7, sm: 7, xs: 7 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 3, xs: 3 },
                                                                children: [
                                                                    {
                                                                        type: 'switch',
                                                                        name: 'active',
                                                                        next: 'stock',
                                                                        label: 'Aktivan',
                                                                        className: 'test123',
                                                                        asyncValidation: true,
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 9, sm: 9, xs: 9 },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        className: 'option-header',
                                                                        children: [
                                                                            {
                                                                                type: 'block',
                                                                                children: [
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                        className: 'options-button',
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'custom',
                                                                                        children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                        className: 'options-button',
                                                                                        onClick: () => {
                                                                                            if(this.state.unitsForm && this.state.unitsForm._id){
                                                                                                this.setState({ deleteModal: this.state.unitsForm })
                                                                                            }else {
                                                                                                this.remoteOnChange('client', '')
                                                                                                this.remoteOnChange('code', '')
                                                                                                this.remoteOnChange('name', '')
                                                                                                this.remoteOnChange('place', '')
                                                                                                this.remoteOnChange('address', '')
                                                                                                this.remoteOnChange('phone', '')
                                                                                                this.remoteOnChange('email', '')
                                                                                                this.remoteOnChange('jib', '')
                                                                                                this.remoteOnChange('iqvia', '')
                                                                                                this.remoteOnChange('institutionType', '')
                                                                                                this.remoteOnChange('description', '')

                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Osnovni podaci'.translate(this.props.lang),
                                                        referent: true,
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'client',
                                                                        name: 'client',
                                                                        next: 'code',
                                                                        label: 'Šifra komitenta',
                                                                        textType: 'number',
                                                                        // showName : true,
                                                                        metoda: (value) => { this.metodaClient(value) },
                                                                        dataModal: this.state.clientsSearch,
                                                                        title: 'Pregled komitenata',
                                                                        values: [...this.state.clients.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item.code
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra komitenta mora biti unešena!'),
                                                                            (value) => {
                                                                                let tempClient = null
                                                                                let pom = ''
                                                                                if (!isNaN(value)) {

                                                                                    if (this.registerGetValue('client')) {
                                                                                        pom = Number(this.registerGetValue('client'))
                                                                                    } else {
                                                                                        pom = Number(value)
                                                                                    }

                                                                                    tempClient = this.state.clients.filter(item => {
                                                                                        if (item.code == pom) {
                                                                                            return item
                                                                                        }
                                                                                    })

                                                                                    if (tempClient && tempClient.length == 0) {
                                                                                        tempClient = null
                                                                                        return "Komitent pod šifrom " + value + " ne postoji!"
                                                                                    }

                                                                                } else {
                                                                                    if (!this.state.clientExists && typeof (this.registerGetValue('client')) != 'number') {
                                                                                        return "Komitent pod šifrom " + value + " ne postoji!"
                                                                                    }
                                                                                }



                                                                            },


                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'code',
                                                                        next: 'name',
                                                                        label: 'Šifra poslovne jedinice',
                                                                        // textType: 'number',
                                                                        integer: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Šifra poslovne jedinice mora biti unešena!'),
                                                                            async () => {
                                                                                if (!this.registerGetValue('client')) {
                                                                                    return 'Mora se unijeti komitent!'
                                                                                }

                                                                            },
                                                                            number('Mora biti broj'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 8, sm: 8, xs: 8 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'name',
                                                                        next: 'place',
                                                                        label: 'Naziv',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Naziv poslovne jedinice mora biti unešen.'),
                                                                            async (value) => {

                                                                                let businessUnitId = ''
                                                                                if (this.state.unitsForm && this.state.unitsForm._id) {
                                                                                    businessUnitId = this.state.unitsForm._id
                                                                                }

                                                                                let provjera = await this._apiAsync('checkName', { value: value, id: businessUnitId })

                                                                                if (provjera && provjera.result) return 'Poslovna jedinica pod tim nazovom vec postoji!'
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'place',
                                                                        next: 'address',
                                                                        label: 'Mjesto',
                                                                        values: [{ name: 'Izaberite mjesto ', value: -1 }, ...this.state.places.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Mjesto poslovne jedinice mora biti unešeno.'),
                                                                            async (value) => {
                                                                                if (value) {
                                                                                    let red = this.state.places.map(item => {
                                                                                        
                                                                                        return item._id
                                                                                    })

                                                                                    if (!red.includes(value.toLowerCase().trim())) return 'Neispravan unos!'
                                                                                }

                                                                            },
                                                                            // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'address',
                                                                        next: 'phone',
                                                                        label: 'Adresa',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Adresa poslovne jedinice mora biti unešena.')
                                                                            // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 2, xs: 2 },

                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'phone',
                                                                        next: 'email',
                                                                        label: 'Telefon',
                                                                        asyncValidation: true,
                                                                        validate: [

                                                                            // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },

                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'email',
                                                                        label: 'E-mail',
                                                                        next: 'jib',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            email
                                                                            // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'block',
                                                                header: 'Poreski podaci'.translate(this.props.lang),
                                                                style: { marginTop: 30, display: 'block', width: '100%', marginLeft: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 8, sm: 8, xs: 8 },
                                                                        children: [
                                                                            {
                                                                                type: 'row',
                                                                                children: [
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: { lg: 6, sm: 6, xs: 6 },
                                                                                        children: [
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'jib',
                                                                                                next: 'iqvia',
                                                                                                label: 'JIB',
                                                                                                integer: true,
                                                                                                asyncValidation: true,
                                                                                                validate: [
                                                                                                    required('JIB mora biti unešen.'),
                                                                                                    (value) => {
                                                                                                        if ((this.state.entity && this.state.entity !== null) ) {
                                                                                                            if (this.state.entity == 'RS' || this.state.entity == 'FBiH' || this.state.entity == 'BD') {
                                                                                                                if (value && value.toString().length != 13) {
                                                                                                                    return "Broja karaktera mora biti 13!!"
                                                                                                                }
                                                                                                            } else {
                                                                                                                if (value && value.toString().length > 6) {
                                                                                                                    return "Broja karaktera mora biti veci od 6 karaktera!"
                                                                                                                }
                                                                                                            }
                                                                                                        } else {
                                                                                                            return "Morate popuniti predhodna polja!"
                                                                                                        }
                                                                                                    }
                                                                                                ]
                                                                                            },

                                                                                        ]
                                                                                    },
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'block',
                                                                        header: 'Ostali podaci'.translate(this.props.lang),
                                                                        style: { marginTop: 30, marginRight: 5 },
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'select',
                                                                                        name: 'iqvia',
                                                                                        next: 'institutionType',
                                                                                        label: 'IQVIA',
                                                                                        asyncValidation: true,
                                                                                        values: [...this.state.iqvia.map(item => {
                                                                                            let obj = {
                                                                                                name: item.name,
                                                                                                value: item.code.toString()
                                                                                            }
                                                                                            return obj;
                                                                                        })],
                                                                                        validate: [
                                                                                            required('IQVIA mora biti unešen.'),
                                                                                            (value) => {
                                                                                                if (value) {
                                                                                                    let red = this.state.iqvia.map((item) => {

                                                                                                        return item.code.toString()
                                                                                                    })

                                                                                                    if (!red.includes(value.toString())) return 'Neispravan unos!'
                                                                                                }

                                                                                            }

                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                children: [
                                                                                    {
                                                                                        type: 'select',
                                                                                        name: 'institutionType',
                                                                                        label: 'Vrsta ustanove',
                                                                                        next: 'description',
                                                                                        asyncValidation: true,
                                                                                        values: [...this.state.institutionType.map(item => {
                                                                                            let obj = {
                                                                                                name: item.name,
                                                                                                value: item.code.toString()
                                                                                            }
                                                                                            return obj;
                                                                                        })],
                                                                                        validate: [
                                                                                            required('Vrsta ustanonve mora biti unešena.'),
                                                                                            (value) => {
                                                                                                if (value) {
                                                                                                    let red = this.state.institutionType.map((item) => {

                                                                                                        return item.code.toString()
                                                                                                    })


                                                                                                    if (!red.includes(value.toString())) return 'Neispravan unos!'
                                                                                                }

                                                                                            },
                                                                                            (value) => {
                                                                                                if (value) {


                                                                                                    if (this.state.institutionType.filter(item => item.code == value)[0].name == 'Veleprodaja') {

                                                                                                        //pronalazenje countryId
                                                                                                        if (this.registerGetValue && this.registerGetValue('place')) {
                                                                                                            let countryId = this.state.places.filter(item => item._id == this.registerGetValue('place').toString())[0].country
                                                                                                            let idCountry = this.state.countries.filter(item => item.name == 'BIH')[0]._id
                                                                                                            if (countryId == idCountry) {
                                                                                                                this.setState({ almbihButton: true })
                                                                                                            } else {
                                                                                                                this.setState({ almbihButton: false })
                                                                                                            }
                                                                                                        } else {
                                                                                                            this.setState({ almbihButton: false })
                                                                                                        }
                                                                                                    } else {
                                                                                                        this.setState({ almbihButton: false })
                                                                                                    }

                                                                                                }

                                                                                            }
                                                                                        ]
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 6, xs: 6 },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'block',
                                                                                header: 'Opis'.translate(this.props.lang),
                                                                                style: { marginTop: 30, marginLeft: 20, marginRight: 2, display: 'block', width: '100%' },
                                                                                children: [
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                                        children: [
                                                                                            {
                                                                                                type: 'text',
                                                                                                name: 'description',
                                                                                                label: 'Opis',
                                                                                                asyncValidation: true,
                                                                                                validate: [
                                                                                                    // (value) => { if (value !== "Milan") return "Greska! Ime mora biti 'Milan'" }
                                                                                                ]
                                                                                            }
                                                                                        ]
                                                                                    },
                                                                                ]
                                                                            },
                                                                        ]
                                                                    }
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                            <AlmbihModal
                                isOpen={this.state.modalAlmbih}
                                toggle={() => { this.setState({ modalAlmbih: null }) }}
                                title={"ALMBIH"}
                                items={this.state.articleTypes}
                                data={this.state.businessUnits}
                                saveData={(data) => {
                                    this.setState({ almbihData: { ...this.state.unitsForm, data } }, () => {

                                    })
                                }}

                            ></AlmbihModal>
                            <div className="last-change-footer">
                                <div>
                                    <p className="last-change" /**  onClick={() => this.setState({
                                    overlayWhole: true,
                                    modalAlmbih: true
                                })}*/><span>Poslednja izmjena</span>: {this.state.userNameModified} {this.state.timeModified ? this.state.userString : ''}</p>
                                </div>
                                <div>
                                    {
                                        this.state.almbihButton ?
                                        <button className="almbih" onClick={() => {
                                            this.setState({
                                                modalAlmbih: true
                                            })
                                        }}>ALMBIH</button>
                                        :
                                        null


                                    }
                                </div>

                            </div>
                        </div>


                        :

                        <div >
                            <div className='active-check-button active-check-button-2'>
                                <Checkbox
                                    key={1}
                                    onChange={
                                        () => {
                                            this.setState({ aktivniCheck: !this.state.aktivniCheck }, () => {
                                                this.updateParams('active', this.state.aktivniCheck)
                                            })
                                        }
                                    }
                                    checked={this.state.aktivniCheck}
                                    label={"Neaktivni"}
                                ></Checkbox>
                            </div>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Lista poslovnih jedinica'.translate(this.props.lang)}
                                tab2Name={"Dodaj poslovnu jedinicu".translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.setState({
                                        unitsList: true,
                                        unitsForm: false,
                                    })
                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        unitsForm: true,
                                        unitsList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'address', label: 'ADRESA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'placeName', label: 'GRAD'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'countryName', label: 'DRŽAVA'.translate(this.props.lang), multilang: false, allowSort: false },
                                ]}
                                items={this.state.items}

                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} className="edit-icon" />,
                                            onClick: (item) => {

                                                let selectedClient = this.state.clients.filter((it) => {
                                                    if (item.client == it._id) {
                                                        return it
                                                    }
                                                })
                                                item.client = selectedClient[0].code
                                                this.setState({ unitsForm: item }, () => {

                                                })
                                            }
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} className="delete-icon" />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}

                                printTable={this.state.printTable}
                                exportTable={this.state.exportTable}
                                genericPrint={this.props.genericPrint}
                                genericExport={this.props.genericExport}
                                changeState={this.changeState}
                                genericData={{ url: 'data/business-units', title: 'Poslovne jedinice' }}
                            ></ListBuilder>
                        </div>

                }

                <div className={this.state.modal ? 'modal-clasic modal-with-list-builder shown' : 'modal-clasic'}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, (data) => {
                                if (data.result && data.result.error) {
                                    this.setState({
                                        error: data.result.error
                                    })
                                } else {
                                    this.setState({ unitsForm: undefined }, () => { this._onLoad(); this.setState({ deleteModal: null }) })

                                }
                            });
                        }} >
                        Obrisati poslovnu jedinicu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <ListBuilder
                        tabs={false}
                        sort={false}
                        lang={this.props.lang}
                        loading={this.state.loading}
                        total={this.state.total}
                        showNumeration={false}
                        fields={[
                            { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'name', label: 'NAZIV'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'address', label: 'ADRESA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'city', label: 'GRAD'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'country', label: 'DRŽAVA'.translate(this.props.lang), multilang: false, allowSort: true },
                        ]}
                        items={this.state.items}
                        actions={
                            [
                                {
                                    component: <Isvg src={editIcon} className="edit-icon" />,
                                    onClick: (item) => this.setState({ deleteModal: item })
                                },
                                {
                                    component: <Isvg src={garbageIcon} className="delete-icon" />,
                                    onClick: (item) => this.setState({ deleteModal: item })
                                },
                            ]
                        }
                        params={params}
                        sortField={params.sortField}
                        sortType={params.sortType}
                        updateSort={this.updateSort}
                        updateParams={this.updateParams}
                        updateMultipleParams={this.updateMultipleParams}
                    ></ListBuilder>
                </div>
                {
                    this.state.error && this.state.error != '' ?
                        <ErrorModal isOpen={this.state.error} toggle={() => this.setState({ error: '', deleteModal: null })} error={this.state.error}>

                        </ErrorModal>
                        :
                        null
                }
            </div >
        )
    }
}

export default Page(Home);
