import Screen from './screen';
import API from './api';

export default {
    path: '/course',
    auth: false,
    component: Screen,
    api: API,
    seo: (data, lang) => {
        return {
            'Course': 'ICM d.o.o',
            'description': 'Country...'
        }
    }
}