import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';

import { validateDate } from '../../form-builder/validation';
import { getSearchParams } from '../../helpers/link';


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function fieldsList(type) {
    if (Number(type) === OUTPUT || Number(type) === INVENTORY || Number(type) === BUYERRETURN) {
        return [

            {
                type: 'text',
                name: 'documentNumber',
                label: 'BR. DOK.',

            },
            {
                type: 'text',
                name: Number(type) === INVENTORY ? 'inventoryDate' : 'factureDate',
                label: 'DATUM FAKTURE',
            },
            {
                type: 'text',
                name: 'clientCode',
                label: 'Komitent',

            },
            {
                type: 'text',
                name: 'items.article',
                label: 'Artikal',

            },
            {
                type: 'text',
                name: Number(type) === INVENTORY ? 'items.sellPrice' : 'items.price',
                label: 'Cijena',
            },
            {
                type: 'text',
                name: 'items.ammount',
                label: 'IZNOS',
            },

            {
                type: 'text',
                name: Number(type) === INVENTORY ? 'items.factureValue' : 'items.total',
                label: 'UKUPNO',
            },

        ]
    }
    if (Number(type) === ENTRANCE || Number(type) === SUPPLIERRETURN) {
        return [

            {
                type: 'text',
                name: 'documentNumber',
                label: 'BR. DOK.',

            },
            {
                type: 'text',
                name: Number(type) === SUPPLIERRETURN ? 'returnDate' : 'factureDate',
                label: 'DATUM FAKTURE',
            },
            {
                type: 'text',
                name: 'clientCode',
                label: 'Komitent',

            },
            {
                type: 'text',
                name: 'items.article',
                label: 'Artikal',

            },
            {
                type: 'text',
                name: 'items.ammount',
                label: 'IZNOS',
            },
            {
                type: 'text',
                name: Number(type) === SUPPLIERRETURN ? 'items.price' : 'items.facturePrice',
                label: 'Cijena na fakturi',
            },

        ]
    }

    if (Number(type) === NIVELATION) {
        return [
            {
                type: 'text',
                name: 'documentNumber',
                label: 'BR. DOK.',

            },
            {
                type: 'text',
                name: 'nivelationDate',
                label: 'DATUM FAKTURE',
            },
            {
                type: 'text',
                name: 'items.article',
                label: 'Artikal',

            },
            {
                type: 'text',
                name: 'items.oldPrice',
                label: 'STARA CIJENA',
            },
            {
                type: 'text',
                name: 'items.newPrice',
                label: 'NOVA CIJENA',
            },
        ]

    }
    return [];
}
const OUTPUT = 1;
const ENTRANCE = 2;
const NIVELATION = 3;
const BUYERRETURN = 4;
const SUPPLIERRETURN = 5;
const INVENTORY = 6;

class ReportClientArticle extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            form: {},
            typeList: [
                { id: '1', name: 'Izlaz' },
                { id: '2', name: 'Ulaz' },
                { id: '3', name: 'Nivelacija' },
                { id: '4', name: 'Povrat kupca' },
                { id: '5', name: 'Povrat dobavljaču' },
                { id: '6', name: 'Inventura' },
                // { id: '7', name: 'Sve vrste promjene' },
            ],
            readOnlyBusiness: true,
            totalAmount: 0,
            clientsSearch: [],
            businessSearch: [],
            exportTable: false,
        }
    }

    stampa = () => {
        // let params = this._getParams()
        let title = "Promet po komitentima i artiklima"
        this._apiAsync('stampaApi', { title: title, ...this.state.form }).then((response) => {
            const file = new Blob([response], {
                type: "application/pdf"
            })

            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)

        })
    }

    changeState = (key) => {
        this.state[key] = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._apiAsync('get-reports', { ...this.state.form, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }) }).then((response) => {
                this.setState({
                    items: response.items,
                    total: response.total,
                    totalAmount: response.totalAmount

                })
            })
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }
    metodaBusiness = async (value) => {
        let businessList = []
        businessList = await this._apiAsync('businessSearch', { value: value, clientCode: this.registerGetValue ? this.registerGetValue('clientCode') : '' })
        if (this.registerGetValue && (value === this.registerGetValue('businessUnit') || !this.registerGetValue('businessUnit')))
            this.setState({ businessSearch: businessList.businessSearch })
    }
    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet po komitentima i artiklima</h1>
                    <div className="title-options-group">
                        <button onClick={this.stampa}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={() => {
                            this.setState({
                                exportTable: true
                            })
                        }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                initialValues={this.state.form}
                                fieldsToTrack={['article', 'clientCode', 'businessUnit']}
                                fieldTracker={async (name, value) => {
                                    if (name === 'article' && value) {
                                        const products = await this._apiAsync('get-articles', { filter: value })
                                        if (products && products.items.length > 0) {
                                            await this.setState({ products: products && products.items })
                                        }
                                    }
                                    if (name === 'clientCode' && value) {
                                        const clients = await this._apiAsync('get-clients', { filter: value })
                                        if (clients && clients.items) {
                                            this.setState({ clients: clients && clients.items }, () => {

                                                this.metodaBusiness('')

                                                const clientObject = this.state.clients.find(item => item.code === value)
                                                if (clientObject) {
                                                    this.remoteOnChange('clientName', clientObject.name)
                                                    this.setState({ readOnlyBusiness: false })
                                                } else {
                                                    this.remoteOnChange('clientName', '')
                                                }
                                            })
                                        }
                                    }
                                    if (name === 'businessUnit' && value) {
                                        const clientObject = this.state.clients.find(el => el.code === this.registerGetValue('clientCode'))
                                        const businessUnits = await this._apiAsync('get-business-units', {
                                            filter: value,
                                            query: { client: clientObject._id }
                                        })
                                        if (businessUnits && businessUnits.items) {
                                            this.setState({ businessUnits: businessUnits && businessUnits.items }, () => {
                                                const businessUnitObject = this.state.businessUnits.find(item => item.code === value)
                                                if (businessUnitObject) {
                                                    this.remoteOnChange('businessName', businessUnitObject.name)
                                                } else {
                                                    this.remoteOnChange('businessName', '')
                                                }
                                            })
                                        }

                                    }
                                }}
                                classNameForm={"add-form-container"}
                                onSubmit={(data) => {
                                    this.setState({
                                        form: data
                                    })
                                    this._apiAsync('get-reports', data).then((response) => {
                                        this.setState({
                                            items: response?.items,
                                            total: response?.total,
                                            totalAmount: response?.totalAmount
                                        })
                                    })
                                    return "open-modal"
                                }}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 10, sm: 10, xs: 10 },
                                                        className: "custom-input-row categories-manufacturers-row reports-client-list",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'client',
                                                                                name: 'clientCode',
                                                                                next: 'businessUnit',
                                                                                label: 'Šifra komitenta',
                                                                                textType: 'number',
                                                                                finishField: true,
                                                                                metoda: (value) => { this.metodaClient(value) },
                                                                                dataModal: this.state.clientsSearch,
                                                                                title: 'Pregled komitenata',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    (value) => { if (!value || value === '') return "Šifra komitenta mora biti unesena!" },
                                                                                    (value) => {
                                                                                        if (this.state.clientsSearch.length === 0 && this.state.clients.filter(item => item.code === value).length === 0 && this.state.clients.filter(item => item.name === value).length === 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.clientsSearch.length !== 0 && this.state.clientsSearch.filter(item => item.code === value).length === 0 && this.state.clientsSearch.filter(item => item.name === value).length === 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'businessUnit',
                                                                                label: 'Naziv komitenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => {
                                                                                    //     if ((!value || value == '') && (!this.registerGetValue('article') || this.registerGetValue('article') === '')) {
                                                                                    //         return "Klijent ili artikal mora biti unešen!"
                                                                                    //     }
                                                                                    // }
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'businessUnit',
                                                                                name: 'businessUnit',
                                                                                next: 'dateFrom',
                                                                                textType: 'number',
                                                                                metoda: (value) => { this.metodaBusiness(value) },
                                                                                dataModal: this.state.businessSearch,
                                                                                values: [...this.state.businessUnits.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                label: 'Poslovna jedinica',
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                readOnly: this.state.readOnlyBusiness,
                                                                                validate: [
                                                                                    (value) => {
                                                                                        if (this.state.businessUnits.length !== 0) {
                                                                                            if (!value) {
                                                                                                return "Unesite poslovnu jedinicu!"
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    (value) => {
                                                                                        if (value) {
                                                                                            if (this.state.businessSearch.length === 0 && this.state.businessUnits.filter(item => item.code === value).length === 0 && this.state.businessUnits.filter(item => item.name === value).length === 0) {
                                                                                                return "Neispravan unos!"
                                                                                            }
                                                                                            if (this.state.businessSearch.length !== 0 && this.state.businessSearch.filter(item => item.code === value).length === 0 && this.state.businessSearch.filter(item => item.name === value).length === 0) {
                                                                                                return "Neispravan unos!!"
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'businessName',
                                                                                next: 'dateFrom',
                                                                                label: 'Naziv poslovne jedinice',
                                                                                readOnly: true,
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: 'dateTo',
                                                                label: 'Datum od',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => {
                                                                        if (!value || value === '') return "Datum od mora biti unesen!"
                                                                    },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateTo',
                                                                next: 'articleName',
                                                                label: 'Datum do',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => {
                                                                        if (!value || value === '') return "Datum od mora biti unesen!"
                                                                    },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                style: { marginLeft: 2, marginRight: 7 },
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'article',
                                                                                metoda: async (value) => {
                                                                                    const products = await this._apiAsync('ariclesSearch', { value: value })
                                                                                    if (products && products.products && this.registerGetValue && value === this.registerGetValue('articleName')) {
                                                                                        this.setState({ products: products && products.products }, () => {
                                                                                        })
                                                                                    }

                                                                                },
                                                                                dataModal: this.state.products,
                                                                                label: 'Artikal',
                                                                                finishField: true,
                                                                                readOnly: this.state.disableEdit,
                                                                                asyncValidation: true,

                                                                                name: 'articleName',
                                                                                filterOnlyByName: false,
                                                                                next: 'type',
                                                                                values: [...this.state.products.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'select',
                                                                                name: 'type',
                                                                                label: 'Vrsta promjene',
                                                                                values: [...this.state.typeList.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.id
                                                                                    }
                                                                                    return obj;
                                                                                })],
                                                                                validate: [
                                                                                    // required('Vrsta promjene mora biti unsešena'),
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },


                                                                ]
                                                            },

                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <div
                                                                                    className="blue-button width-100 mt17"
                                                                                    onClick={() => { this.submit() }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>PROMET</p>
                                                                                </div>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    hidePagination={false}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}

                                    exportTable={this.state.exportTable}
                                    genericExport={this.props.genericExport}
                                    changeState={this.changeState}
                                    genericData={{ url: 'data/reports/client', title: 'Promet po komitentima i artiklima' }}

                                    fields={fieldsList(this.state.form.type)}
                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount !== 0 ?

                                                numberWithCommas(parseFloat(this.state.totalAmount).toFixed(2))

                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(ReportClientArticle);
