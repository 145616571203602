
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class KSSPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            funds: [],
            total: 10,
            buttonActive: 2,
            foundsNames: [],
            checkOut: [],
            checkOutFields: [],
            fundsItems: [],
            products: [],
            articleSearch: [],
            articlesSearch: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();

        // setTimeout(() => {
        //     if (this.state.checkOut) {
        //         console.log(this.state.checkOut)

        //         let arr = []
        //         for (let i = 0; i < this.state.checkOut.length; i++) {
        //             if (this.state.checkOut[i].fund) {
        //                 console.log(this.state.checkOut[i].fund, "funds")
        //                 for (var key in this.state.checkOut[i].fund) {
        //                     console.log(key, "key")
        //                     let objName = {}
        //                     let objApproved = {}
        //                     let name = `fund.${key}.name`;
        //                     let approved = `fund.${key}.approved`
        //                     // console.log(name)
        //                     objName.type = 'text';
        //                     objName.name = name;
        //                     objApproved.name = approved;
        //                     objApproved.label = 'Odobreno';
        //                     objName.label = `${this.state.funds[this.state.funds.findIndex(item => item._id == key)].name}`;
        //                     objName.multilang = false;
        //                     objName.allowSort = false;

        //                     // arr.push(objName)

        //                     objApproved.type = 'text';
        //                     objApproved.name = approved;
        //                     objApproved.label = 'Odobreno';
        //                     objApproved.multilang = false;
        //                     objApproved.allowSort = false;

        //                     arr.push(objName,objApproved)
        //                 }
        //             }
        //         }
        //         this.setState({
        //             checkOutFields: arr,
        //         }, () => {
        //             console.log(this.state.checkOutFields)
        //         })

        //     }
        // }, 100)

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.funds.length && this.state.funds.length) {
            let arr = []
            for (let i = 0; i < this.state.funds.length; i++) {
                let objName = {}
                let objApproved = {}
                let name = `fund.${this.state.funds[i]._id}.name`;
                let approved = `fund.${this.state.funds[i]._id}.approved`
                // console.log(name)
                objName.type = 'text';
                objName.name = name;
                objApproved.name = approved;
                objApproved.label = 'Odobreno';
                objName.label = `${this.state.funds[i].name}`;
                objName.multilang = false;
                objName.allowSort = false;

                // arr.push(objName)

                objApproved.type = 'text';
                objApproved.name = approved;
                objApproved.label = 'Odobreno';
                objApproved.multilang = false;
                objApproved.allowSort = false;

                arr.push(objName, objApproved)

            }
            // console.log(arr)
            this.setState({
                checkOutFields: arr
            })
        }
    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        this.setState({ articlesSearch: articleList.articlesSearch })
    }
    changeState = (key) => {
        this.state[key] = false;
    }
    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Fond odjave</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={async () => { this.setState({ printTable: true }) }}>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button onClick={async () => { this.setState({ exportTable: true }) }}>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                afterSubmitFocusField="article"
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article']}
                                fieldTracker={async (name, value) => {
                                    let arr = []
                                    for (let i = 0; i < this.state.funds.length; i++) {
                                        let item = this.state.funds[i]._id
                                        arr.push(item)

                                    }
                                    if (name == 'article' && value) {


                                        let fundsArticle = await this._apiAsync('get-fund-articles', { code: value })
                                        if (fundsArticle && fundsArticle.fundsItems) {
                                            this.setState({ form: null }, () => {
                                                this.setState({
                                                    form: fundsArticle.fundsItems
                                                })
                                            })

                                        } else {
                                            let products = await this._apiAsync('get-articles-filter', { filter: value })
                                            console.log(products)
                                            if (products && products.items) {
                                                this.setState({ products: products && products.items })
                                            }


                                            let check = false
                                            for (let i = 0; i < this.state.checkOut.length; i++) {
                                                if (value == this.state.checkOut[i].article) {
                                                    check = true
                                                }

                                            }

                                            if (!check) {
                                                for (let i = 0; i < arr.length; i++) {
                                                    this.remoteOnChange(`fund.${arr[i]}.approved`, '')
                                                    this.remoteOnChange(`fund.${arr[i]}.name`, '')
                                                }
                                            }

                                            products = await this._apiAsync('get-articles', { code: value })
                                            if (products && products.items) {
                                                this.remoteOnChange('articleName', products.items.name)
                                            }
                                            let stock = await this._apiAsync('get-stock', { article: value, funds: arr });
                                            if (stock) {
                                                // console.log(stock)
                                                for (let i = 0; i < arr.length; i++) {
                                                    if (stock[arr[i]]) {
                                                        // this.remoteOnChange(`fund.${arr[i]}.name`, stock[arr[i]])
                                                        this.remoteOnChange(`fund.${arr[i]}.approved`, stock[arr[i]])
                                                    }
                                                }
                                            } else {
                                                this.setState({ stock: null })
                                            }
                                        }

                                    } else {
                                        // console.log("aaaaaaaaaaaaaaaaa")
                                        this.remoteOnChange('articleName', '');
                                        this.remoteOnChange('dateFrom', '');

                                        if (!value) {
                                            this.setState({ form: null }, () => {
                                                this.setState({ form: {} })
                                            })
                                        }
                                    }
                                }}

                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    if (data._id) {
                                        console.log(data)
                                        this._apiAsync('update', data).then((res) => {
                                            this._onLoad()

                                        })
                                    } else {
                                        this._apiAsync('insert-new', data).then((res) => {


                                            if (res.result && res.result.id) {
                                                this._onLoad();
                                                this.setState({ form: null }, () => {
                                                    // this._onLoad();
                                                    this.setState({
                                                        form: {
                                                            // _id: res.result.id,
                                                            // ...res.result.result
                                                        }
                                                    })
                                                })

                                            }
                                        })
                                    }


                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        header: 'Artikli',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        className: "custom-input-row checkoutfond-row-1",
                                                        children: [
                                                            {
                                                                type: 'article',
                                                                maxLength: 6,
                                                                name: 'article',
                                                                next: 'dateFrom',
                                                                metoda: (value) => { this.metoda(value) },
                                                                dataModal: this.state.articlesSearch,
                                                                label: 'Šifra artikla',
                                                                values: [...this.state.products.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item.code
                                                                    }
                                                                    return obj
                                                                })],
                                                                textType: 'number',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    maxLength(8, 'Mora maksimalno 8 karaktera'),
                                                                    (value) => { if (!value || value == '') return "Šifra artika mora biti unesen!" }
                                                                ]
                                                            },

                                                            {
                                                                type: 'text',
                                                                name: 'articleName',
                                                                next: 'dateFrom',
                                                                label: 'Naziv artikla',
                                                                finishField: true,
                                                                readOnly: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Naziv artikla mora biti unesen!" }
                                                                ]
                                                            },
                                                            {

                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: `fund.${this.state.funds && this.state.funds[0] && this.state.funds[0]._id ? this.state.funds[0]._id : ''}.approved`,
                                                                label: 'Datum od',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },

                                                            ...this.state.funds.map((item, idx) => {
                                                                // console.log(idx)
                                                                if (idx < 3 && item._id) {
                                                                    return {
                                                                        type: 'row',
                                                                        className: 'input-group-custom-2-container',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                                className: 'input-group-custom input-group-custom-2',
                                                                                children: [
                                                                                    {
                                                                                        type: 'decimal',
                                                                                        name: `fund.${item._id}.name`,
                                                                                        next: `fund.${item._id}.approved`,
                                                                                        label: item.name,
                                                                                        readOnly: true,
                                                                                        textType: 'number',
                                                                                        finishField: true,
                                                                                        asyncValidation: true,
                                                                                        validate: [
                                                                                            number('Mora biti broj')
                                                                                            // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                        ]
                                                                                    },
                                                                                    this.state.funds[idx + 1] && this.state.funds[idx + 1]._id ?
                                                                                        {
                                                                                            type: 'decimal',
                                                                                            name: `fund.${item._id}.approved`,
                                                                                            next: `fund.${this.state.funds && this.state.funds[idx] && this.state.funds[idx]._id ? this.state.funds[idx + 1] && this.state.funds[idx + 1]._id ? this.state.funds[idx + 1]._id : 'submitForm' : ''}.approved`,
                                                                                            label: 'Odobreno',
                                                                                            // readOnly: true,
                                                                                            textType: 'number',
                                                                                            finishField: true,
                                                                                            asyncValidation: true,
                                                                                            validate: [
                                                                                                number('Mora biti broj')
                                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                            ]
                                                                                        }
                                                                                        :
                                                                                        {
                                                                                            type: 'decimal',
                                                                                            name: `fund.${item._id}.approved`,
                                                                                            next: 'submitForm',
                                                                                            label: 'Odobreno',
                                                                                            // readOnly: true,
                                                                                            finishField: true,
                                                                                            asyncValidation: true,
                                                                                            validate: [
                                                                                                number('Mora biti broj')
                                                                                                // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                            ]
                                                                                        },
                                                                                ]
                                                                            },
                                                                        ]
                                                                    }
                                                                }

                                                            })
                                                        ]
                                                    },
                                                    ...this.state.funds.map((item, idx) => {
                                                        // console.log(item)
                                                        if (idx && idx >= 3) {
                                                            return {

                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        className: "custom-input-row checkout-fond",
                                                                        children: [
                                                                            {
                                                                                type: 'row',

                                                                                className: 'input-group-custom-2-container',
                                                                                children: [
                                                                                    {
                                                                                        type: 'col',
                                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                                        className: 'input-group-custom input-group-custom-2',
                                                                                        children: [
                                                                                            {
                                                                                                type: 'decimal',
                                                                                                name: `fund.${item._id}.name`,
                                                                                                next: `fund.${item._id}.approved`,
                                                                                                label: item.name,
                                                                                                readOnly: true,
                                                                                                finishField: true,
                                                                                                asyncValidation: true,
                                                                                                validate: [
                                                                                                    number('Mora biti broj')
                                                                                                    // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                                ]
                                                                                            },
                                                                                            this.state.funds[idx + 1] && this.state.funds[idx + 1]._id ?
                                                                                                {
                                                                                                    type: 'decimal',
                                                                                                    name: `fund.${item._id}.approved`,
                                                                                                    next: `fund.${this.state.funds && this.state.funds[idx] && this.state.funds[idx]._id ? this.state.funds[idx + 1] && this.state.funds[idx + 1]._id ? this.state.funds[idx + 1]._id : 'submitForm' : ''}.approved`,
                                                                                                    label: 'Odobreno',
                                                                                                    // readOnly: true,
                                                                                                    finishField: true,
                                                                                                    textType: 'number',
                                                                                                    asyncValidation: true,
                                                                                                    validate: [
                                                                                                        number('Mora biti broj')
                                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                                    ]
                                                                                                }
                                                                                                :
                                                                                                {
                                                                                                    type: 'decimal',
                                                                                                    name: `fund.${item._id}.approved`,
                                                                                                    next: 'submitForm',
                                                                                                    label: 'Odobreno',
                                                                                                    // readOnly: true,
                                                                                                    finishField: true,
                                                                                                    textType: 'number',
                                                                                                    asyncValidation: true,
                                                                                                    validate: [
                                                                                                        // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                                    ]
                                                                                                },
                                                                                        ]
                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    }
                                                                ],
                                                            }
                                                        }
                                                    })
                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={false}
                                    fields={[
                                        { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'dateFrom', label: 'DATUM OD'.translate(this.props.lang), multilang: false, allowSort: true },
                                        ...this.state.checkOutFields

                                    ]}
                                    items={this.state.checkOut.map((item, idx) => {
                                        // console.log(...this.state.checkOutFields)
                                        return {
                                            ...item,

                                        }
                                    })}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                    printTable={this.state.printTable}
                                    exportTable={this.state.exportTable}
                                    genericPrint={this.props.genericPrint}
                                    genericExport={this.props.genericExport}
                                    changeState={this.changeState}
                                    genericData={{ url: 'data/checkout/fund', title: 'Fond odjave' }}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(KSSPage);
