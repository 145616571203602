import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {

    'update-profile': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/users/update/user', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
}