import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
class Entry extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clientList: true,
            clientForm: false,
            entryOut: true,
            items: [
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
            ]

        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }




    render() {
        let params = this._getParams();
        console.log(this.state.items)
        return (
            <div>

                <div className="section-title title-options">
                    <h1>Unos izlaznog prometa</h1>
                </div>

                {
                    this.state.entryOut?
                        <div className="table-tabs tabs-three-buttons">
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ articleState: true, articleList: false, articleForm: false, buttonActive: 1 })}>
                                    Lista izlaznog prometa
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ articleList: true, articleState: false, articleForm: false, buttonActive: 2 })}>
                                    Otpremnica
                                </button>
                                <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={() => this.setState({ articleForm: true, articleList: false, articleState: false, buttonActive: 3 })}>
                                    Unos izlaza
                                </button>
                            </div>
                        </div>
                        :
                        null
                }
            

                <Container fluid>
                    <FormBuilder
                        registerSubmitAction={(submit) => this.submit = submit}
                        registerChangeValue={(changeValue) => this.changeValue = changeValue}

                        classNameForm={"add-form-container"}
                        addButtonText={'Dodaj'.translate(this.props.lang)}
                        onSubmit={(data) => {
                            console.log(data)
                            console.log(this.state.entryForm)

                            if (this.state.idDokumenta) {
                                console.log('dodavanje stavke', data)
                                this._api('api4', { data: data }, () => {
                                    this.setState({ entryForm: Object.assign(data, { ordinalNumber: '', article: '', serialNumber: '', deadline: '', supply: '', ammount: '', price: '', discount: '' }) }, () => { console.log(this.state.entryForm) })
                                })

                            } else {
                                console.log('dodavanje dokumentas', data)
                                this._api('api2', { data: data }, (resp) => {
                                    this.setState({ idDokumenta: resp.result })
                                    this.setState({ entryForm: Object.assign(data, { ordinalNumber: '', article: '', serialNumber: '', deadline: '', supply: '', ammount: '', price: '', discount: '' }) }, () => { console.log(this.state.entryForm) })
                                })

                            }



                        }}
                        initialValues={this.state.entryForm ? this.state.entryForm : {}}
                        fields={[
                            {
                                type: 'block',
                                children: [
                                    {
                                        type: 'col',
                                        className: 'col-up',
                                        width: { lg: 9, sm: 9, xs: 9 },
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up',
                                                width: { lg: 7, sm: 7, xs: 7 },
                                                children: [


                                        ]
                                    },
                                    {
                                        type: 'col',
                                        className: 'col-up col-up-right-top',
                                        width: { lg: 3, sm: 3, xs: 3 },
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                width: { lg: 5, sm: 5, xs: 5 },

                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        className: 'option-header',
                                                                        children: [
                                                                            {
                                                                                type: 'block',
                                                                                children: [
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                        className: 'options-button',
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                        className: 'options-button'
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Export</span></div>,
                                                                                        className: 'options-button'
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                        className: 'options-button'
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'storage',
                                                next: 'trafficType',
                                                label: 'Skladište',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    required('Šifra mora biti unešena!'),
                                                    number('Mora biti broj'),
                                                    async (value) => {
                                                        try {
                                                            if (value) {
                                                                let res = await this._apiAsync('validateStorage', { value: value });
                                                                if (res.status == 400) {
                                                                    return 'Greska! Pogresno skladiste.'
                                                                }
                                                                this.changeValue('name', res.result.name)
                                                                return undefined;
                                                            }

                                                        } catch (e) {
                                                            return undefined;
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 4, sm: 4, xs: 4 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name',
                                                label: 'Naziv',
                                                finishField: true,
                                                asyncValidation: true,
                                                readOnly: true,
                                                validate: [
                                                    //(value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 3, xs: 3 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'trafficType',
                                                next: 'document',
                                                label: 'Vrsta prometa',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    required('Naziv mora biti unešen!'),
                                                    // async () => {
                                                    //     try {
                                                    //         console.log("DOKUMENT")
                                                    //         let res = await this._apiAsync('validateDoc', {});
                                                    //         console.log(res)
                                                    //         if (res.status == 400) {
                                                    //             return 'Greska! Pogresno skladiste.'
                                                    //         }
                                                    //         if(!this.state.entryForm){
                                                    //             this.changeValue('document', res.result.prviSlBroj)
                                                    //         }

                                                    //         return undefined;

                                                    //     } catch (e) {
                                                    //         return undefined;
                                                    //     }
                                                    // },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 3, xs: 3 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'document',
                                                next: 'invoicingDate',
                                                label: 'Dokumnet',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    required('Dokument mora biti unešen!'),
                                                    // async (value)=>{
                                                    //     console.log(value)
                                                    //     //provjera da li postoji document sa datim brojem
                                                    //     try{

                                                    //         if (value) {
                                                    //             //iz baze procitati da li postoji dati broj dokumenta
                                                    //             let res = await this._apiAsync('validateDocNumber', { value: value });
                                                    //             if (res.status == 400) {
                                                    //                 return 'Greska! Dokument pod navedenim brojem vec postoji.'
                                                    //             }
                                                    //             //this.changeValue('name', res.result.name)
                                                    //             return undefined;
                                                    //         }


                                                    //         //uporediti upisanu vrijednost i procitanu vrijednost iz baze

                                                    //     }catch(e){
                                                    //         return undefined
                                                    //     }
                                                    // },
                                                    number('Mora biti broj'),

                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'switch',
                                                name: 'fastEntry',
                                                next: 'invoicingDate',
                                                label: 'Brzi unos',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    //(value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'invoicingDate',
                                                next: 'deliveryDate',
                                                label: 'Datum fakturisanja',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'deliveryDate',
                                                next: 'client',
                                                label: 'Datum isporuke',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {    
                                                
                                                type: 'text',
                                                name: 'client',
                                                next: 'value',
                                                label: 'Komintent',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    //(value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                    required('Klijent mora biti unešen!'),
                                                    number('Mora biti broj'),
                                                    async (value) => {
                                                        try {
                                                            if (value) {
                                                                let res = await this._apiAsync('validateClient', { value: value });
                                                                if (res.status == 400) {
                                                                    return 'Greska! Pogrešan komitent.'
                                                                }

                                                                //this.changeValue('name', res.result.name)
                                                                return undefined;
                                                            }

                                                        } catch (e) {
                                                            return undefined;
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'value',
                                                next: 'account',
                                                label: 'Valuta',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'account',
                                                next: 'oridnalNumber',
                                                label: 'Račun',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'switch',
                                                name: 'compensation',
                                                //next: 'oridnalNumber',
                                                label: 'Kompenzacija',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    //(value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'oridnalNumber',
                                                next: 'article',
                                                label: 'Redni broj',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'article',
                                                next: 'serialNumber',
                                                label: 'Artikal',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                    async (value) => {
                                                        try {
                                                            if (value) {
                                                                let res = await this._apiAsync('validateArticle', { value: value });
                                                                console.log(res.status)
                                                                if (res.status == 400) {
                                                                    return 'Greska! Pogresn artikal.'
                                                                }

                                                                //this.changeValue('name', res.result.name)
                                                                return undefined;
                                                            }

                                                        } catch (e) {
                                                            return undefined;
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'serialNumber',
                                                next: 'deadline',
                                                label: 'Serijski broj',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'deadline',
                                                next: 'supply',
                                                label: 'Rok',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 1, sm: 1, xs: 1 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'supply',
                                                next: 'ammount',
                                                label: 'Zaliha',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 1, sm: 1, xs: 1 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'ammount',
                                                next: 'price',
                                                label: 'Količina',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 1, sm: 1, xs: 1 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'price',
                                                next: 'discount',
                                                label: 'Cijena',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 1, sm: 1, xs: 1 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'discount',
                                                label: 'Rabat',
                                                finishField: true,
                                                asyncValidation: true,
                                                validate: [
                                                    (value) => { if (!value || value == '') return "Naziv mora biti unesen!" },
                                                    number('Mora biti broj'),
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            }],
                        },
                    ]}
                    
        
                    ></FormBuilder>
                    
                </Container>


                <div className="v-scroll mt-15">
                    <ListBuilder
                        tabs={false}
                        sort={false}
                        hideFilters={true}
                        lang={this.props.lang}
                        loading={this.state.loading}
                        total={this.state.total}
                        showNumeration={false}
                        fields={[
                            { type: 'text', name: 'oridnalNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'name', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },

                        ]}
                        items={this.state.items}

                        actions={
                            [
                                {
                                    component: <Isvg src={editIcon} className="delete-icon" />,
                                    onClick: (item) => this.setState({ entryForm: item })
                                    //onClick: (item) => console.log(item)
                                },
                                {
                                    component: <Isvg src={garbageIcon} className="delete-icon" />,
                                    onClick: (item) => this.setState({ deleteModal: item })
                                },
                            ]
                        }


                        params={params}
                        sortField={params.sortField}
                        sortType={params.sortType}
                        updateSort={this.updateSort}
                        updateParams={this.updateParams}
                        updateMultipleParams={this.updateMultipleParams}
                    ></ListBuilder>
                </div>

                <div className="footer">
                    <div className="footer-left">
                        <button>
                            <div className="box box-red">
                                <Isvg src={precentIcon} />
                            </div>
                            <p>UPIS</p>
                        </button>
                        <button>
                            <div className="box box-red">
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <p>BRISANJE DOKUMENTA</p>
                        </button>
                        <button>
                            <div className="box box-red">
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <p>ANALITIKA</p>
                        </button>
                        <button>
                            <div className="box box-red">
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <p>RABAT NA FAKTURI</p>
                        </button>
                    </div>
                    <div className="info-colored">
                        <div className="col-info">
                            <p>Osnovica</p>
                            <span>505,00 KM</span>
                        </div>
                        <div className="col-info">
                            <p>PDV</p>
                            <span>42,00 KM</span>
                        </div>
                        <div className="col-info">
                            <p>Za plaćanje</p>
                            <span>543,00 KM</span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Page(Entry);
