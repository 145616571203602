import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-series': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/series/deadlines', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries:null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    numberOfDays: query.numberOfDays

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     

}