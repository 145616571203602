import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import moment from 'moment';

import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';

class WriteOffPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clientList: true,
            clientForm: false,
            entryOut: true,
            buttonActive: 2,
            clients: [],
            products: [],
            listCustomFilter: true,
            items: [
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
                {
                    number: '1',
                    code: 'nova media. s.p. Bijeljina',
                    name: 'Bijeljina',
                    series: 'Komad',
                    deadline: '17%',
                    ammount: '175',
                    discount: '175',
                    price: '175',
                    total: '175',
                },
            ]
        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>

                <div className="section-title title-options">
                    <h1>
                        {
                            this.state.buttonActive == 1 ?
                                <>Lista otpisa</>
                                :
                                <>Otpis</>
                        }
                    </h1>
                    {
                        this.state.buttonActive == 1 ?
                            <div className="title-options-extrabutton">
                                <div className="title-options-group">
                                    <button>
                                        <div className="option green">
                                            <Isvg src={saveIcon} />
                                        </div>
                                        <span>Snimi</span>
                                    </button>
                                    <button>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                    <button>
                                        <div className="option red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <span>Otkaži</span>
                                    </button>
                                </div>
                            </div>

                            :
                            null
                    }
                    {/*
                    <div className="title-options-group">
                        <button>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option red">
                                <Isvg src={deleteIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                    </div>         
                    */}
                </div>

                        <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="table-tabs-small">
                                <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ articleState: true, articleList: false, articleForm: false, buttonActive: 1 })}>
                                    Lista otpisa
                                </button>
                                <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ articleList: true, articleState: false, articleForm: false, buttonActive: 2 })}>
                                    Otpis
                                </button>
                            </div>
                            {
                                this.state.buttonActive == 1 ?

                                <div className="show-unfinished">
                                    <Input type="checkbox" />
                                    <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                                </div>
                                :
                                null
                            }
                        </div>
                {
                    this.state.buttonActive == 2 ?
                        <>
                            <Container fluid>
                            <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    classNameForm={"add-form-container"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    onSubmit={(data) => {
                                        console.log('data', data);
                                    }}
                                    initialValues={this.state.articleForm ? this.state.articleForm : { /*firstName:'Milan' */ }}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [

                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 1, sm: 1, xs: 1 },
                                                                    children: [
                                                                        {
                                                                            type: 'button',
                                                                            name: 'fond',
                                                                            next: 'stock',
                                                                            className: 'lock-icon-container',
                                                                            children: <div className="lock-icon">
                                                                                <Isvg src={lockIcon} onClick={() => {
                                                                                    this.setState({
                                                                                        lockModal: true
                                                                                    })
                                                                                }} />
                                                                            </div>,
                                                                            label: 'Kompenzacija',
                                                                            asyncValidation: true,
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 11, sm: 11, xs: 11 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                            className: 'options-button',
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                            className: 'options-button'
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Export</span></div>,
                                                                                            className: 'options-button'
                                                                                        },
                                                                                        {
                                                                                            type: 'button',
                                                                                            width: { lg: 4, sm: 6, xs: 6 },
                                                                                            action: 'submit',
                                                                                            children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Otkaži</span></div>,
                                                                                            className: 'options-button'
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'documentNumber',
                                                            next: 'wiretoffDate',
                                                            label: 'Broj dokumenta',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 2, xs: 2 },
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'wiretoffDate',
                                                            next: 'oridnalNumber',
                                                            label: 'Datum otpisa',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                            ]
                                                        },
                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-row reservation-row-2",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'oridnalNumber',
                                                            next: 'article',
                                                            label: 'Redni broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Redni broj mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'article',
                                                            next: 'serialNumber',
                                                            label: 'Artikal',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Artikal mora biti unesen!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'select',
                                                            name: 'serialNumber',
                                                            next: 'kss',
                                                            values: [{ name: '', value: -1 }],
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'select',
                                                            name: 'deadline',
                                                            next: 'ammount',
                                                            values: [{ name: '', value: -1 }],
                                                            label: 'Rok',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Cijena mora biti unesena!" }
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            className: 'input-group-custom-1-container',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom input-group-custom-1',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'ammount',
                                                                            next: 'facturePrice',
                                                                            label: 'Količina',
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'stock',
                                                                            next: 'price',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'price',
                                                            next: 'total',
                                                            label: 'Cijena',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'total',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            asyncValidation: true,
                                                            readOnly: true,
                                                            validate: [
                                                                (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                            ]
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                ></FormBuilder>
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'number', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'code', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'series', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'price', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    actions={
                                        [

                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            <div className="footer">
                                <div className="footer-left">
                                    <button className="blue-button">
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>OPIS</p>
                                    </button>
                                    <button className="blue-button">
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>ZAVISNI TROŠKOVI</p>
                                    </button>
                                    <button >
                                        <div className="box box-red">
                                            <Isvg src={garbageOpionIcon} />
                                        </div>
                                        <p>BRISANJE DOKUMENTA</p>
                                    </button>
                                </div>
                                <div className="footer-right">
                                    {/*
                            <div className="info-transparent">
                                <div className="col-info">
                                    <p>Vrijednost</p>
                                    <span>543,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    <span>45,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>RUC</p>
                                    <span>45,00 KM</span>
                                </div>
                            </div>
                            */}
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>505,00 KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>42,00 KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>543,00 KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                        {
                            this.state.listCustomFilter ?

                            <div className="list-builder-filters-wrap">
                                <div className="list-builder-filters">
                                    <div className="field-strap-wrap">
                                        <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value })} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value })} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterDescription} onChange={(e) => this.setState({ filterDescription: e.target.value })} placeholder={'Opis'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Input type='text' value={this.state.filterUser} onChange={(e) => this.setState({ filterUser: e.target.value })} placeholder={'Korisnik'} />
                                    </div>
                                </div>
                                <div className="clear-filters" onClick={() => {
                                    this.setState({
                                        filterDateFrom: '',
                                        filterDateTo: '',
                                        filterDescription: '',
                                        filterUser: '',
                                        filterBusiness: ''
                                    })
                                }}>
                                    <a><span>&times;</span> Ukloni filter</a>
                                </div>
                            </div>
                             :

                             null
                         }
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    // hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'number', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'code', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'name', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'series', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'deadline', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'ammount', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                    ]}


                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                        </>
                }



            </div>
        )
    }
}

export default Page(WriteOffPage);
