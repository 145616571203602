import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'check-uploaded-data': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/postingOfStatements/check-uploaded-data', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    'to-post-data': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/postingOfStatements/to-post', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },


    // 'check-uploaded-data': {
    //     onLoad: false,
    //     action: (lang, match, query) => {
    //         return fetch(env.API_URL + '/data/bookkeeping', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //             body: JSON.stringify({
    //                 page: query.page,
    //                 entries: query.entries,
    //                 filter: query.filter,
    //                 sortType: query.sortType,
    //                 sortField: query.sortField,
    //                 additionalFilters: {
    //                     dateFrom: query && query.dateFrom,
    //                     dateTo: query && query.dateTo,
    //                     change: query && query.change
    //                 }
    //             })
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     items: result.items,
    //                     total: result.total,
    //                     sumOwes: result.sumOwes ? result.sumOwes.toFixed(2) : 0,
    //                     sumAsking: result.sumAsking ? result.sumAsking.toFixed(2) : 0,
    //                 }
    //         })
    //     }
    // },
    // 'checkNextDocumentNumber': {
    //     onLoad: true,
    //     action: (data) => {

    //         return fetch(env.API_URL + '/data/financial-accounts/check/next/documentNumber', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //             body: JSON.stringify(data)
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     accountNumber: result.firstFreeNumber,
    //                 }
    //         })
    //     }
    // },

    // 'new-account-probook': {
    //     onLoad: false,
    //     action: (data) => {

    //         return fetch(env.API_URL + '/data/new-account-probook/new', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify(data.data)
    //         }).then(parseJSON).then(({ result, status }) => {
    //             return {result, status};
    //         })
    //     }
    // },




}