
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import InfoModal from '../../components/infoModal';


class ColdChainPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [

            ],
            series: [],
        }
    }

    componentDidMount() {
        this._onLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Vraćanje gajbica</h1>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <div className="lock-icon" style={{ marginRight: 32, top: 0 }}>
                            <Isvg src={lockIcon} onClick={() => {
                                this.setState({
                                    lockModal: true
                                })
                            }} />
                        </div> */}
                        <div className="title-options-group">

                            <button onClick={() => this.submit()}>
                                <div className="option green" >
                                    <Isvg src={saveIcon} />
                                </div>
                                <span>Snimi</span>
                            </button>
                            <button>
                                <div className="option blue">
                                    <Isvg src={printIcon} />
                                </div>
                                <span>Štampaj</span>
                            </button>
                            <button>
                                <div className="option blue">
                                    <Isvg src={exportIcon} />
                                </div>
                                <span>Export</span>
                            </button>
                        </div>
                    </div>

                </div>

                <Container fluid>
                    <FormBuilder
                        registerSubmitAction={(submit) => this.submit = submit}
                        registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                        registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                        registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                        classNameForm={"add-form-container entrance-custom-col"}
                        addButtonText={'Dodaj'.translate(this.props.lang)}
                        fieldsToTrack={[]}
                        fieldTracker={async (name, value) => { }}

                        onSubmit={(data, notFinished) => {
                            if (data && data.barCode) {
                                this._apiAsync('return-crate', { barCode: data.barCode }).then((res) => {
                                    if (res && res.error) {
                                        this.setState({ message: `Gajbica nije zadužena (${data.barCode})` })

                                    } else {
                                        this.setState({ message: `Gajbica je vraćena (${data.barCode})` })
                                    }
                                })

                            }


                        }}
                        initialValues={this.state.entranceForm}
                        fields={[

                            {
                                type: 'col',
                                width: { lg: 4, sm: 12, xs: 12 },
                                offset: { lg: 4, sm: 0, xs: 0 },
                                style: { marginTop: 100 },
                                children: [
                                    {
                                        type: 'block',
                                        children: [


                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'barCode',
                                                        // textType: 'number',
                                                        next: 'submitForm',
                                                        label: 'Bar kod',
                                                        asyncValidation: true,
                                                        validate: [
                                                            required("Broj dokumenta mora biti unešen!"),
                                                        ]
                                                    },



                                                ]

                                            },

                                        ]
                                    },



                                ]

                            },


                        ]}
                    ></FormBuilder>
                </Container>
                <InfoModal
                    isOpen={this.state.message}
                    toggle={() => this.setState({ message: null })}
                    title={'Obavještenje'}
                    info={this.state.message}
                    buttons={[
                        {
                            text: 'Ok',
                            onClick: () => {
                                this.setState({ message: null })
                            }
                        }
                    ]}
                >
                </InfoModal>

            </div >
        )
    }
}

export default Page(ColdChainPage);
