
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';
import lockIcon from '../../assets/lock.svg';
import Checkbox from '../../form-builder/fields/checkbox';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import moment from 'moment';
import env from "react-dotenv";
import Loader from "../../components/loader";

class BookDebitPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            nootBooked: true,
            specification: false,
            listCustomFilter: true,
            total: 20,
            buttonActive: 1,
            items: [
            ],
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            clients: [],
            checkedItem: [],
            checkedItem1: false,
            clientFacture: [],
            checked: false,
            checkBox:[],
            clientsSearch : [],
            loadingPage: false
        }
    }

    componentDidMount() {
        this._onLoad();

        let params = this._getParams();

        if (params.ktNumber && params.ktNumber != this.state.filterKtNumber) {
            this.setState({ filterKtNumber: params.ktNumber })
        }
        if (params.dateFrom && params.dateFrom != this.state.filterDateFrom) {
            this.setState({ filterDateFrom: params.dateFrom })
        }
        if (params.dateTo && params.dateTo != this.state.filterDateTo) {
            this.setState({ filterDateTo: params.dateTo })
        }
        if (params.client && params.client != this.state.filterClient) {
            this.setState({ filterClient: params.client })
        }

        if (params.class && params.class != this.state.filterClass) {
            this.setState({ filterClass: params.class })
        }
        if (params.subClass && params.subClass != this.state.filterSubClass) {
            this.setState({ filterSubClass: params.subClass })
        }
        if (params.basic && params.facture != this.state.filterFacture) {
            this.setState({ filterFacture: params.facture })
        }




    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

        if (!prevState.firstFreeNumber && this.state.firstFreeNumber) {
            let form = this.state.form;
            if (!form) {
                form = {
                    date: this.state.dateNow
                }
            }

            this.setState({ form: null }, () => {
                this.setState({ form })
            })
        }
    }


    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    render() {
        let params = this._getParams();
    
        // this.state.checkBox = ({checkBox: new Array(this.state.items.length).fill(false) })
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <h1>Knjižno terećenje</h1>
                    <div className="title-options-extrabutton">
                        {
                           
                            this.state.booked || this.state.addKo ?
                                null
                                :
                               this.state.checkBox.length != 0 && this.state.checkBox.filter(item=> item==true).length !=0 ?
                                <button className="posting-button" onClick={() => {
                                    this.state.items.map((element) => {
                                        if (element.checked && element.proknjizeno == false) {
                                            element.proknjizeno = true
                                            delete element.checked
                                            // update u bazi
                                            this._apiAsync('updateKt', element).then((result) => {
                                                this.setState({ items: result.result })
                                            })
                                        }
                                    })

                                    this.setState({ nootBooked: false })
                                    this.setState({ booked: true })
                                    this.setState({ buttonActive: 2 }, ()=>{
                                        this.setState({checkBox : []})
                                    })
                                }}>
                                    <span>Proknjiži</span>
                                </button>
                                :
                                null
                              
                        }
                        {
                            this.state.addKo ?
                                null
                                :
                                <div className="title-options-group">
                                    <button onClick={ async () => {
                                        this.setState({loadingPage: true})
                                        const params = {}
                                        for (const [key, value] of Object.entries(this._getParams())) {
                                            if (key === 'entries' || key === 'page') {
                                                continue;
                                            }
                                            params[key] = value;
                                        }
                                        const request = {
                                            filter: {
                                                entries: 10000,
                                                page: 0,
                                                additionalFilters: params
                                            },
                                            methods: 'post',
                                            url: 'data/bookDebit',
                                            fields:[
                                                { key: 'ktNumber', label: 'BROJ'},
                                                { key: 'date', label: 'DATUM'},
                                                { key: 'clientName', label: 'KOMITENT'},
                                                { key: 'factureNumber', label: 'BROJ FAKTURE'},
                                                { key: 'createdByUsername', label: 'KORISNIK'},
                                            ],
                                            title: "Knjižno terećenje"
                                        }
                                        await this._apiAsync('generic-print', request)
                                        this.setState({loadingPage: false})

                                    }}>
                                        <div className="option blue">
                                            <Isvg src={printIcon} />
                                        </div>
                                        <span>Štampaj</span>
                                    </button>
                                    <button onClick={ async () => {
                                        this.setState({loadingPage: true})
                                        const params = {}
                                        for (const [key, value] of Object.entries(this._getParams())) {
                                            if (key === 'entries' || key === 'page') {
                                                continue;
                                            }
                                            params[key] = value;
                                        }
                                        const request = {
                                            filter: {
                                                entries: 10000,
                                                page: 0,
                                                additionalFilters: params
                                            },
                                            methods: 'post',
                                            url: 'data/bookDebit',
                                            fields: [
                                                { key: 'ktNumber', label: 'BROJ'},
                                                { key: 'date', label: 'DATUM'},
                                                { key: 'clientName', label: 'KOMITENT'},
                                                { key: 'factureNumber', label: 'BROJ FAKTURE'},
                                                { key: 'createdByUsername', label: 'KORISNIK'},
                                            ],
                                            title: "Knjižno terećenje"
                                        }
                                        await this._apiAsync('generic-export', request)
                                        this.setState({loadingPage: false})
                                        const exportResult = await this._apiAsync('generic-export', request)
                                        if (exportResult && exportResult) {
                                            if (exportResult.success) {
                                                window.location.href = env.API_URL + `${exportResult.file}`;
                                            }
                                        }

                                    }}>
                                        <div className="option blue">
                                            <Isvg src={exportIcon} />
                                        </div>
                                        <span>Export</span>
                                    </button>
                                </div>
                        }
                    </div>
                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ nootBooked: true, addKo: false, booked: false, buttonActive: 1 })}>
                            Neproknjiženo
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ booked: true, addKo: false, nootBooked: false, buttonActive: 2 })}>
                            Proknjiženo
                        </button>
                        <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={() => this.setState({ addKo: true, booked: false, nootBooked: false, buttonActive: 3 }, () => {
                            this._apiAsync('checkNextDocumentNumber').then((res) => {
                                this.remoteOnChange('ktNumber', res.firstFreeNumber)
                            })
                        })}>
                            Dodaj K.T
                        </button>
                    </div>
                    {
                        this.state.buttonActive == 1 || this.state.buttonActive == 2 ?

                            <div className="show-unfinished">
                                <Input type="checkbox" />
                                <Label style={{ marginBottom: 0 }}>Prikaži nezavršene</Label>
                            </div>
                            :
                            null
                    }
                </div>

                {
                    this.state.nootBooked ?
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKtNumber} onChange={(e) => this.setState({ filterKtNumber: e.target.value }, () => {
                                                    this.updateParams('ktNumber', this.state.filterKtNumber)
                                                })} placeholder={'KT broj'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKind} onChange={(e) => this.setState({ filterKind: e.target.value }, () => {
                                                    this.updateParams('class', this.state.filterClass)
                                                })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterSubKind} onChange={(e) => this.setState({ filterSubKind: e.target.value }, () => {
                                                    this.updateParams('subClass', this.state.filterSubClass)
                                                })} placeholder={'Podvsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterFacture} onChange={(e) => this.setState({ filterFacture: e.target.value }, () => {
                                                    this.updateParams('facture', this.state.filterFacture)
                                                })} placeholder={'Faktura'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterKtNumber: '',
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterSubKind: '',
                                                filterKind: '',
                                                filterFacture: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'ktNumber', value: null }, { name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'class', value: null }, { name: 'subClass', value: null }, { name: 'facture', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'ktNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'name', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammountKo', label: 'BR. FAKTURE'.translate(this.props.lang), multilang: false, allowSort: false },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: false },
                                    // { type: 'text', name: 'kom', label: 'Nesto'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}


                                actions={
                                    [
                                        {

                                        }
                                    ]
                                }

                                // items={this.state.items.filter((element) => {
                                //     return element.proknjizeno == false
                                // })}
                                items={this.state.items.filter((element) => {
                                    return element.proknjizeno == false
                                }).map((item, idx) => {

                                    return {
                                        ...item,
                                        ammountKo: <div style={{ cursor: 'pointer' }} onClick={() => {
                                            this.props[0].history.push({
                                                pathname: '/reservation',
                                                state: { documentNumber: item.factureNumber }
                                            })
                                        }}>{item.factureNumber}</div>,
                                        kom: <Input type="checkbox" checked={this.state.checkBox[idx]}  onChange={() => {

                                            let kliknuto = this.state.items.filter((element) => {
                                                return element.ktNumber == item.ktNumber
                                            })

                                            this.state.checkBox[idx] = !this.state.checkBox[idx]

                                            kliknuto[0].checked = this.state.checkBox[idx]
                                           
                                            this.state.items[idx] = kliknuto[0]
                                          
                                            // console.log(this.state.items.filter((item)=>{
                                            //     if(item.checked && item.checked == true){
                                            //         return item
                                            //     }
                                            // }))
                                            // delete this.state.checkBox.checkBox

                                            if(this.state.items.filter((item)=>{if(item.checked && item.checked == true){return item}}).length !=0){
                                                this.setState({checkBox : this.state.checkBox})
                                             
                                            }else{
                                                this.setState({checkBox : this.state.checkBox})
                                            }

                                        }} />


                                    }
                                })}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </>

                        :

                        null

                }

                {
                    this.state.booked ?
                        <>
                            {
                                this.state.listCustomFilter ?

                                    <div className="list-builder-filters-wrap">
                                        <div className="list-builder-filters">
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterKtNumber} onChange={(e) => this.setState({ filterKtNumber: e.target.value }, () => {
                                                    this.updateParams('ktNumber', this.state.filterKtNumber)
                                                })} placeholder={'KT broj'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='date' value={this.state.filterDateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClient} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                                    this.updateParams('client', this.state.filterClient)
                                                })} placeholder={'Komitent'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterClass} onChange={(e) => this.setState({ filterClass: e.target.value }, () => {
                                                    this.updateParams('class', this.state.filterClass)
                                                })} placeholder={'Vrsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterSubKind} onChange={(e) => this.setState({ filterSubKind: e.target.value }, () => {
                                                    this.updateParams('subClass', this.state.filterSubClass)
                                                })} placeholder={'Podvsta'} />
                                            </div>
                                            <div className="field-strap-wrap">
                                                <Input type='text' value={this.state.filterFacture} onChange={(e) => this.setState({ filterFacture: e.target.value }, () => {
                                                    this.updateParams('basic', this.state.filterFacture)
                                                })} placeholder={'Faktura'} />
                                            </div>
                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.setState({
                                                filterKtNumber: '',
                                                filterDateFrom: '',
                                                filterDateTo: '',
                                                filterClient: '',
                                                filterSubClass: '',
                                                filterClass: '',
                                                filterFacture: ''
                                            }, () => {
                                                this.updateMultipleParams([{ name: 'ktNumber', value: null }, { name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'class', value: null }, { name: 'subClass', value: null }, { name: 'facture', value: null }])
                                            })
                                        }}>
                                            <a><span>&times;</span> Ukloni filter</a>
                                        </div>
                                    </div>
                                    :

                                    null
                            }
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'ktNumber', label: 'BR. DOK:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'name', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammountKo', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}


                                actions={
                                    [
                                        // {
                                        //     component: <Input type="checkbox" checked={this.state.checkedItem1} />,
                                        //     onClick: () => {
                                        //         this.setState({ checkedItem1: !this.state.checkedItem1 })
                                        //     }
                                        // }
                                    ]
                                }


                                items={this.state.items.filter((element) => {
                                    return element.proknjizeno == true
                                })}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </>

                        :

                        null

                }

                {
                    this.state.addKo ?

                        <div>
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                classNameForm={"add-form-container"}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                fieldsToTrack={['ktNumber', 'clientCode', 'factureNumber', 'ktAmmount']}
                                fieldTracker={(name, value) => {
                                    if (name == 'ktNumber' && value) {


                                    }

                                    if (name == 'clientCode' && value) {

                                        if (value) {
                                            this._apiAsync('get-clients', { filter: value }).then((clients) => {
                                                if (clients && clients.items) {
                                                    this.setState({ clients: clients && clients.items }, () => {
                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                            this._apiAsync('get-factureClient', { filter: this.registerGetValue('clientCode') }).then((result) => {
                                                                if (result.items.length != 0) {
                                                                    this.setState({ clientFacture: result.items })
                                                                } else {
                                                                    console.log('Upozorenje da komitent nema izlaznih faktura...')
                                                                    this.setState({ clientFacture: [] })
                                                                }
                                                            })
                                                        } else {
                                                            this.remoteOnChange('clientName', '')
                                                        }
                                                    })

                                                }
                                            })

                                        }
                                    }

                                    if (name == 'factureNumber' && !value) {

                                    }

                                    if (name == 'factureNumber' && value) {
                                        console.log('BROJ FAKTURE %%%%%%%%')
                                        if (value) {
                                            let total = 0

                                            this._apiAsync('get-facture', { filter: value }).then((result) => {
                                                console.log('Procitani broj fakture...', result)

                                                if (!result.items == false) {
                                                    total = result.items.reduce((sum, current) => {
                                                        sum = sum + current.total
                                                        return sum
                                                    }, 0)
                                                    console.log('Ukupna vrijednost na fakturii...', total)
                                                    // this.remoteOnChange('item.beforeAmount', total)
                                                }


                                            })
                                        }
                                    }


                                }}
                                onSubmit={(data) => {
                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._apiAsync('insert-new', data).then((res) => {
                                            if (res.result && res.result._id) {
                                                this.setState({ form: null }, () => {
                                                    this.remoteOnChange('date', this.state.dateNow)
                                                    this._apiAsync('checkNextDocumentNumber').then((res1) => {
                                                        console.log('ODGOVOR ZA PRVI SLOBODAN BROJ...', res1)
                                                        this.remoteOnChange('ktNumber', res1.firstFreeNumber)
                                                    })

                                                    this._onLoad()
                                                })
                                                // if (res.result.items && res.result.items.length) {
                                                //     this._apiAsync('checkNextItemNumber', { documentNumber: res.result.documentNumber }).then((res) => {
                                                //         if (res && res.firstFreeItemNumber) {
                                                //             this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                                //         }

                                                //     })
                                                // } else {
                                                //     this.remoteOnChange('item.itemNumber', 1)
                                                // }

                                            }
                                            console.log('pokusaj citanja forma...', this.state.form)
                                        })

                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        header: 'Artikli',
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up',
                                                width: { lg: 7, sm: 7, xs: 7 },
                                                children: [

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                className: 'col-up col-up-right-top col-up-right-top-max-3 flex-end',
                                                width: { lg: 5, sm: 5, xs: 5 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 1, xs: 1 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        name: 'fond',
                                                                        next: 'stock',
                                                                        className: 'lock-icon-container',
                                                                        children: <div className="lock-icon">
                                                                            <Isvg src={lockIcon} onClick={() => {
                                                                                this.setState({
                                                                                    lockModal: true
                                                                                })
                                                                            }} />
                                                                        </div>,
                                                                        label: 'Kompenzacija',
                                                                        asyncValidation: true,
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 11, sm: 11, xs: 11 },
                                                                children: [
                                                                    {
                                                                        type: 'row',
                                                                        className: 'option-header',
                                                                        children: [
                                                                            {
                                                                                type: 'block',
                                                                                children: [
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option green"><Isvg src={saveIcon} /></div> <span>Snimi</span></div>,
                                                                                        className: 'options-button',
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'submit',
                                                                                        children: <div className="option-buttons-container"><div className="option blue"><Isvg src={printIcon} /></div> <span>Štampaj</span></div>,
                                                                                        className: 'options-button'
                                                                                    },
                                                                                    {
                                                                                        type: 'button',
                                                                                        width: { lg: 4, sm: 6, xs: 6 },
                                                                                        action: 'custom',
                                                                                        children: <div className="option-buttons-container"><div className="option red"><Isvg src={garbageOpionIcon} /></div> <span>Izbriši</span></div>,
                                                                                        className: 'options-button',
                                                                                        onClick: () => {
                                                                                            this.remoteOnChange('clientCode', '')
                                                                                            this.remoteOnChange('clientName', '')
                                                                                            this.remoteOnChange('factureNumber', '')
                                                                                            this.remoteOnChange('ktAmmount', '')

                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'row',
                                                children: [

                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        className: "custom-input-row book-debit-row",
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'ktNumber',
                                                                next: 'date',
                                                                label: 'K.T. broj',
                                                                // textType: 'number',
                                                                integer: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    // (value) => { if (!value || value == '') return "Broj dokumenta mora biti unesen!" }
                                                                ]
                                                            },
                                                            {
                                                                type: 'text',
                                                                name: 'date',
                                                                next: 'clientCode',
                                                                label: 'Datum',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }
                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-2-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        className: 'input-group-custom input-group-custom-2',
                                                                        children: [
                                                                            {
                                                                                type: 'client',
                                                                                name: 'clientCode',
                                                                                next: 'factureNumber',
                                                                                label: 'Šifra komitenta',
                                                                                textType: 'number',
                                                                                finishField: true,
                                                                                metoda: (value) => { this.metodaClient(value) },
                                                                                dataModal: this.state.clientsSearch,
                                                                                title: 'Pregled komitenata',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                    (value) => {
                                                                                        if (this.state.clientsSearch.length == 0 && this.state.clients.filter(item => item.code == value).length == 0 && this.state.clients.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!"
                                                                                        }
                                                                                        if (this.state.clientsSearch.length != 0 && this.state.clientsSearch.filter(item => item.code == value).length == 0 && this.state.clientsSearch.filter(item => item.name == value).length == 0) {
                                                                                            return "Neispravan unos!!"
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'factureNumber',
                                                                                label: 'Komitent',
                                                                                finishField: true,
                                                                                readOnly: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Naziv mora biti unesen!" }
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'selectWithSearch',
                                                                name: 'factureNumber',
                                                                next: 'ktAmmount',
                                                                label: 'Faktura broj',
                                                                textType: 'number',
                                                                asyncValidation: true,
                                                                values: [...this.state.clientFacture.map(item => {
                                                                    let obj = {
                                                                        name: item.documentNumber + ' - ' + item.reservationDate,
                                                                        value: item.documentNumber
                                                                    }
                                                                    return obj
                                                                })],
                                                                validate: [
                                                                    // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }

                                                                ]
                                                            },
                                                            {
                                                                type: 'decimal',
                                                                name: 'ktAmmount',
                                                                finishField: true,
                                                                label: 'Iznos K.T.',
                                                                // textType: 'number',
                                                                next: 'submitForm',
                                                                asyncValidation: true,
                                                                validate: [
                                                                    // (value) => { if (!value || value == '') return "Datum fakturisanja mora biti unesen!" }
                                                                ]
                                                            },
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </div>

                        :

                        null
                }

                {
                    this.state.specification ?
                        <>
                            <div className="list-builder-filters-wrap">
                                <div className="list-builder-filters">
                                    <div className="field-strap-wrap">
                                        <Label>Broj fakture</Label>
                                        <Input type='text' value={this.state.zaSpec ? this.state.zaSpec[0].factureNumber : ''} placeholder={'Broj fakture'} />
                                    </div>
                                    <div className="field-strap-wrap">
                                        <Label>Šifra komitenta</Label>
                                        <Input type='text' value={this.state.zaSpec ? this.state.zaSpec[0].clientCode : ''} placeholder={'Komitent'} />
                                    </div>
                                </div>
                            </div>





                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                // hidePagination={true}
                                fields={[
                                    { type: 'text', name: 'article', label: 'ŠIFRA ARTIKLA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA:'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'beforeAmount', label: 'STARA VRIJEDNOST'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'newAmount', label: 'NOVA VRIJEDNOST'.translate(this.props.lang), multilang: false, allowSort: true },
                                    // { type: 'text', name: 'clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'ammountKo', label: 'IZNOS KO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'createdByUsername', label: 'KORISNIK'.translate(this.props.lang), multilang: false, allowSort: true },


                                ]}


                                items={this.state.zaSpec ? this.state.zaSpec[0].items : []}
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>

                        </>
                        :
                        null

                }

            </div>
        )
    }
}

export default Page(BookDebitPage);
