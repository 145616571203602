import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    active: query.active

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },

     'api2': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/clients/new', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },


    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/clients/' + data.data, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            console.log(data.data)
            return fetch(env.API_URL + '/data/clients/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'countriesAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/countries', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                
                if (status >= 200 && status < 300)
                    return {
                        countries: result,
                        // total: result.total,
                    }
            })
        }
    },
    'iqvias-api': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/iqvias', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                
                if (status >= 200 && status < 300)
                    return {
                        iqvias: result,
                        // total: result.total,
                    }
            })
        }
    },
    'institutionTypes-api': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/institutionTypes', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                
                if (status >= 200 && status < 300)
                    return {
                        institutionTypes: result,
                        // total: result.total,
                    }
            })
        }
    },


    'placesAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/places', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        places: result,
                        // total: result.total,
                    }
            })
        }
    },

    'conditionsAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/conditions', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                
                if (status >= 200 && status < 300)
                    return {
                        conditions: result,
                        // total: result.total,
                    }
            })
        }
    },
    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                })
            }).then(parseJSON).then(({ result, status }) => {
              
                if (status >= 200 && status < 300)
                    return {
                        printHtml: result.html,
                    }
            })
        }
    },
    'export': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients/export', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        response: result,
                    }
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/clients/validate/' + data.value  + '/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return {result, status};
            })
        }
    },

    'client-read' : {
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/clients/' + data.clientCode, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return  { result }
            })
        }
    },

    'checkNextDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/check/next/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        firstFreeNumber: result.firstFreeNumber,
                    }
            })
        }
    },

    'articleTypeAPI': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/articletypes', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                        articleTypes: result,
                        // total: result.total,
                    }
            })
        }
    },

    'checkName': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/clients/check/name', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                       result: result.info
                    }
            })
        }
    },

    'checkJibNumber': {
        onLoad: false,
        action: (data) => {
            console.log('slamke na server.....', data)
            return fetch(env.API_URL + '/data/clients/check/jib', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {

                if (status >= 200 && status < 300)
                    return {
                       result: result.info
                    }
            })
        }
    },

}