
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';
import arrowDown from '../../assets/arrowDown.svg';

import Switch from "../../form-builder/fields/switch";
import Select from "../../form-builder/fields/select";

import InfoModal from '../../components/infoModal';

import env from "react-dotenv";
import { output } from "react-paginate/demo/webpack.config";
import { FAKTURA, OTPREMNICA, PAKOVANJE, PROKNJIZENA_OTPREMNICA } from "../../helpers/constants";
import garbageOpionIcon from "../../assets/garbage-option.svg";

import Loader from '../../components/loader';


class PackingPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            products: [],
            items: [],
            itemsPacked: [],
            storekeepers: [],
            quantityCorrection: false,
            series: [],
            typeOfChange: 'output',
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY')
        }
    }

    componentDidMount() {
        this._onLoad();

        this.resetForm()

        this.checkInterval = setInterval(() => {
            this.checkVersion()
        }, 1000);
    }
    componentWillUnmount() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }

    }
    resetForm = () => {
        this.setState({ form: null, items: [], itemsPacked: [] }, () => {
            this.setState({
                form: {
                    date: this.state.dateNow, ammount: 1
                },
                quantityCorrection: false
            })
        })
    }
    setVersion = () => {
        let obj = {}
        let outputObj = this.state.outputObj;
        if (outputObj?.documentNumber) {
            obj.documentNumber = outputObj.documentNumber;
            obj.version = outputObj.version ? outputObj.version : 'n';
        }
        this.setState({ version: obj }, () => {
            this.pauseInterval = new Date().getTime()
        })
    }
    checkVersion = () => {
        let version = this.state.version;
        if (version && version?.documentNumber && (new Date().getTime() - this.pauseInterval) > 3000 && !this.state.versionChangedModal) {
            let data = {
                typeOfChange: this.state.typeOfChange,
                documentNumber: version.documentNumber,
            }

            this._apiAsync('check-document-version', data).then((res) => {
                if (res?.documentNumber === version?.documentNumber && version.version !== res.version) {
                    this.setState({ versionChangedModal: true })
                }
            })
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div style={{ position: 'relative' }}>
                <Loader loading={this.state.loadingPage} />
                <div className="section-title title-options">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h1>Pakovanje</h1>
                        <div style={{ marginLeft: 15 }}>
                            <Input type='select' value={this.state.typeOfChange} onChange={(e) => {
                                if (this.state.typeOfChange != e.target.value)
                                    this.setState({ typeOfChange: e.target.value }, () => this.resetForm())
                            }}>
                                <option value='output'>Izlaz</option>
                                <option value='naturalniRabat'>Naturalni rabat</option>
                            </Input>
                        </div>
                    </div>

                    <div className='display-flex-center'>


                        {
                            this.state.outputObj && this.state.outputObj.status && Number(this.state.outputObj.status) === PAKOVANJE ?
                                <div style={{ marginRight: 15 }}>
                                    <Switch
                                        onChange={(value) =>
                                            this.setState({ quantityCorrection: !this.state.quantityCorrection }, () => {
                                                if (!this.state.quantityCorrection) {
                                                    this.remoteOnChange('ammount', 1)
                                                }
                                            })

                                        }
                                        value={this.state.quantityCorrection}
                                        label={"Ispravka količine"}
                                    ></Switch>
                                </div>
                                : null
                        }
                        {
                            // !((this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj) && this.state.outputObj && this.state.outputObj.status && Number(this.state.outputObj.status) === PAKOVANJE
                            !((this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj || (this.state.outputObj && !this.state.outputObj.inputCrates)) && this.state.outputObj.status && Number(this.state.outputObj.status) === PAKOVANJE
                                ?

                                <button
                                    // disabled={!(this.state.outputObj && this.state.outputObj.inputCrates && Object.entries(this.state.outputObj.inputCrates).length && this.state.items.find(item => Number(item.controledAmount) === Number(item.ammount)))}
                                    disabled={(this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj ? true : false}
                                    className={`posting-button mr-4 ${(this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj ? 'disabled-button' : ''}`}
                                    onClick={() => {
                                        this._apiAsync('update-output-status', { outputId: this.state.outputObj._id, nextStatus: FAKTURA, typeOfChange: this.state.typeOfChange }).then((res) => {
                                            // this.setState({ inputCratesModal: null })
                                            // if (res) {
                                            //     this.setState({ outputObj: res })
                                            // }
                                            let value = this.registerGetValue('documentNumber');
                                            if (value) {
                                                this.setState({ loadingPage: true }, () => {
                                                    this._apiAsync('get-output', { documentNumber: value, status: [2, 3, 4] }).then((res) => {

                                                        if (res && res._id && this.registerGetValue('documentNumber')) {
                                                            if (res.documentNumber && String(res.documentNumber) === String(this.registerGetValue('documentNumber'))) {
                                                                this.setState({ outputObj: res, loadingPage: false }, () => {
                                                                    this.setVersion()
                                                                })
                                                                if (res && res.items) {
                                                                    let itemsPacked = [];
                                                                    for (let i = 0; i < res.items.length; i++) {
                                                                        if (res.items[i].controledAmount && res.items[i].controledAmount > 0) {
                                                                            itemsPacked.push(res.items[i])
                                                                        }
                                                                    }
                                                                    this.setState({ items: res.items, itemsPacked: itemsPacked })
                                                                }
                                                            }
                                                        } else {
                                                            this.setState({ outputObj: null, loadingPage: false, items: [], itemsPacked: [] }, () => {
                                                                this.setVersion()
                                                            })
                                                        }
                                                    })
                                                })


                                            }
                                        })
                                    }}
                                >Završi kontrolu </button>
                                : null
                        }
                        <div className="title-options-group">
                            <button disabled={!(this.state.outputObj && this.state.outputObj.status && Number(this.state.outputObj.status) === FAKTURA)} onClick={async () => {
                                if (this.state.typeOfChange === 'output') {
                                    if (this.state.outputObj && this.state.outputObj.status && Number(this.state.outputObj.status) === FAKTURA) {
                                        await this._apiAsync('printFaktura', { documentNumber: this.state.outputObj.documentNumber })

                                    }
                                } else if (this.state.typeOfChange === 'naturalniRabat') {
                                    if (this.state.outputObj && this.state.outputObj.status && Number(this.state.outputObj.status) === FAKTURA) {
                                        await this._apiAsync('printFakturaNaturalniRabat', { documentNumber: this.state.outputObj.documentNumber })
                                    }
                                }
                            }}>
                                <div className="option blue">
                                    <Isvg src={printIcon} />
                                </div>
                                <span>Štampaj</span>
                            </button>
                        </div>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerGetData={(registerGetData) => this.registerGetData = registerGetData}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    if (data && data.packingItem) {
                                        let item = data.packingItem;
                                        if (item) {
                                            let obj = {}
                                            if (this.registerGetValue('documentNumber')) {
                                                obj.documentNumber = Number(this.registerGetValue('documentNumber'));
                                            }
                                            if (this.registerGetValue('barCode')) {
                                                obj.barCode = this.registerGetValue('barCode');
                                            }
                                            if (this.registerGetValue('date')) {
                                                obj.date = this.registerGetValue('date');
                                            }
                                            obj.incrementAmount = data.ammount;
                                            if (this.registerGetValue('packingItem.itemNumber')) {
                                                obj.itemNumber = Number(this.registerGetValue('packingItem.itemNumber'));
                                            }
                                            obj.typeOfChange = this.state.typeOfChange;
                                            if (obj && obj.incrementAmount && obj.incrementAmount != 0) {
                                                this.setState({ loadingPage: true }, () => {
                                                    this._apiAsync('update-controled-articles', obj).then((res) => {
                                                        this.setState({ loadingPage: false })
                                                        if (res) {
                                                            if (res && res._id) {
                                                                this.setState({ outputObj: res }, () => {
                                                                    this.setVersion()
                                                                })
                                                                if (res && res.items) {
                                                                    let itemsPacked = [];
                                                                    for (let i = 0; i < res.items.length; i++) {
                                                                        if (res.items[i].controledAmount && res.items[i].controledAmount != 0) {
                                                                            itemsPacked.push(res.items[i])
                                                                        }
                                                                    }

                                                                    this.setState({ items: res.items, itemsPacked }, () => {
                                                                        if (this.state.items && this.state.items.filter(item => item.barcode && item.barcode.filter(code => code == String(obj.barCode)).length).length) {
                                                                            item = this.state.items.filter(item => item.barcode && item.barcode.filter(code => code == String(obj.barCode)).length)[0];
                                                                        }
                                                                        this.remoteOnChange('packingItem', item)
                                                                    })
                                                                }
                                                            } else {
                                                                this.setState({ outputObj: null, items: [], itemsPacked: [] }, () => {
                                                                    this.setVersion()
                                                                })
                                                            }
                                                        }

                                                    })
                                                })
                                            }




                                        }
                                    }


                                    // if (data && data.numberOfDays) {
                                    //     this._apiAsync('get-series', { ...data, ...params }).then((res) => {
                                    //         this.setState({ ...res })
                                    //     })
                                    // }


                                    return 'open-modal';
                                }}
                                fieldsToTrack={['documentNumber', 'barCode']}
                                fieldTracker={async (name, value) => {
                                    if (name == 'documentNumber') {
                                        if (value) {
                                            this.setState({ loadingPage: true }, () => {
                                                this._apiAsync('get-output', { documentNumber: value, status: [2, 3, 4], typeOfChange: this.state.typeOfChange }).then((res) => {
                                                    if (res && res._id && this.registerGetValue('documentNumber')) {
                                                        if (res.documentNumber && String(res.documentNumber) === String(this.registerGetValue('documentNumber'))) {
                                                            this.setState({ outputObj: res, loadingPage: false }, () => {
                                                                this.setVersion()
                                                            })
                                                            if (res && res.items) {
                                                                let itemsPacked = [];
                                                                for (let i = 0; i < res.items.length; i++) {
                                                                    if (res.items[i].controledAmount && res.items[i].controledAmount > 0) {
                                                                        itemsPacked.push(res.items[i])
                                                                    }
                                                                }
                                                                this.setState({ items: res.items, itemsPacked: itemsPacked })
                                                            }
                                                        }
                                                    } else {
                                                        this.setState({ outputObj: null, loadingPage: false, items: [], itemsPacked: [] }, () => {
                                                            this.setVersion()
                                                        })
                                                    }
                                                })
                                            })
                                        } else {
                                            this.setState({ outputObj: null, items: [], itemsPacked: [] }, () => {
                                                this.resetForm()
                                                this.setVersion()
                                            })
                                        }
                                    }
                                    if (name == 'barCode') {
                                        if (value) {
                                            let item;
                                            if (this.state.items && this.state.items.filter(item => item.barcode && item.barcode.filter(code => code == String(value)).length).length) {
                                                item = this.state.items.filter(item => item.barcode && item.barcode.filter(code => code == String(value)).length)[0];
                                            }
                                            if (item) {
                                                if (!this.state.quantityCorrection) {
                                                    this.remoteOnChange('ammount', 1)
                                                    let obj = {}
                                                    if (this.registerGetValue('documentNumber')) {
                                                        obj.documentNumber = Number(this.registerGetValue('documentNumber'));
                                                    }
                                                    if (this.registerGetValue('barCode')) {
                                                        obj.barCode = Number(this.registerGetValue('barCode'));
                                                    }
                                                    obj.incrementAmount = 1;
                                                    // this._apiAsync('update-controled-articles', obj).then((res) => {
                                                    //     if (res) {
                                                    //         if (res && res._id) {
                                                    //             this.setState({ outputObj: res })
                                                    //             if (res && res.items) {
                                                    //                 let itemsPacked = [];
                                                    //                 for (let i = 0; i < res.items.length; i++) {
                                                    //                     if (res.items[i].controledAmount && res.items[i].controledAmount != 0) {
                                                    //                         itemsPacked.push(res.items[i])
                                                    //                     }
                                                    //                 }

                                                    //                 this.setState({ items: res.items, itemsPacked }, () => {
                                                    //                     if (this.state.items && this.state.items.filter(item => item.barCode == Number(value)).length) {
                                                    //                         item = this.state.items.filter(item => item.barCode == Number(value))[0];
                                                    //                     }
                                                    //                 })
                                                    //             }
                                                    //         } else {
                                                    //             this.setState({ outputObj: null })
                                                    //         }
                                                    //     }

                                                    // })
                                                }
                                                this.remoteOnChange('packingItem', item)
                                            } else {
                                                if (this.registerGetValue('packingItem'))
                                                    this.remoteOnChange('packingItem', null)
                                            }
                                        } else {
                                            if (this.registerGetValue('packingItem'))
                                                this.remoteOnChange('packingItem', null)
                                        }
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {

                                        type: 'block',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 4, sm: 12, xs: 12 },
                                                // style: { padding: 0 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'documentNumber',
                                                                        textType: 'number',
                                                                        next: 'date',
                                                                        label: 'Dokument',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required("Broj dokumenta mora biti unešen!"),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'date',
                                                                        // textType: 'number',
                                                                        next: 'barCode',
                                                                        label: 'Datum',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required("Broj dokumenta mora biti unešen!"),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'barCode',
                                                                        // textType: 'number',
                                                                        next: !this.state.quantityCorrection ? 'submitForm' : 'ammount',
                                                                        label: 'Bar kod',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // required("Broj dokumenta mora biti unešen!"),
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'ammount',
                                                                        next: 'submitForm',
                                                                        textType: 'number',
                                                                        label: 'Količina',
                                                                        asyncValidation: true,
                                                                        readOnly: !this.state.quantityCorrection,
                                                                        validate: [
                                                                            // required("Broj dokumenta mora biti unešen!"),
                                                                        ]
                                                                    },

                                                                ]
                                                            },


                                                        ]
                                                    }

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 4, sm: 12, xs: 12 },
                                                style: { paddingLeft: 0 },
                                                children: [
                                                    {
                                                        type: 'div',
                                                        children: <div className="square-info-container square-info-container-form-builder">
                                                            <div className="square-info square-info-form-builder" >
                                                                <span className="square-info-title" style={{ backgroundColor: 'white' }}>Info</span>
                                                                <div className="square-info-content">
                                                                    <h4>{this.state.outputObj && this.state.outputObj.clientInfo && this.state.outputObj.clientInfo.name}</h4>
                                                                    <h6><span> {this.state.outputObj && this.state.outputObj.clientInfo && this.state.outputObj.clientInfo.address}</span></h6>
                                                                    <h6><span> {this.state.outputObj && this.state.outputObj.clientInfo && this.state.outputObj.clientInfo.city}</span></h6>
                                                                    {
                                                                        this.state.outputObj && this.state.outputObj.clientInfo ?
                                                                            <h6 style={{ marginTop: 20 }}>Paleta: <span> 4</span></h6>
                                                                            :
                                                                            null
                                                                    }


                                                                </div>
                                                            </div>
                                                        </div>,
                                                    }


                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 4, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 4, sm: 6, xs: 6 },

                                                                        children: <button className="blue-button width-100" onClick={() => { this.props[0].history.push('/display-control') }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>PREGLED KONTROLE</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 4, sm: 6, xs: 6 },

                                                                        children: <button
                                                                            className={`blue-button width-100 ${(this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj ? 'blue-button-disabeld' : ''}`}
                                                                            disabled={(this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj ? true : false}
                                                                            onClick={() => {
                                                                                let inputCratesModal = {
                                                                                    barCodes: [],
                                                                                    packer: this.props.uData._id,
                                                                                    cardboardBox: false
                                                                                }

                                                                                if (this.state.outputObj.inputCrates) {
                                                                                    inputCratesModal = this.state.outputObj.inputCrates;
                                                                                    inputCratesModal.cardboardBox = false;
                                                                                }
                                                                                this.setState({
                                                                                    inputCratesModal: inputCratesModal
                                                                                })
                                                                            }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>UPIS GAJBICA</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 4, sm: 6, xs: 6 },

                                                                        children: <button
                                                                            className={`blue-button width-100 ${((this.state.items && !this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj) ? 'blue-button-disabeld' : ''}`}
                                                                            disabled={((this.state.items && !this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj) ? true : false}
                                                                            onClick={() => { this.setState({ notValid: true }) }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>PREGLED NEISPRAVNIH</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 4, sm: 6, xs: 6 },

                                                                        children: <button
                                                                            disabled={((this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj) || !this.state.items.filter(item => item.coldChain).length ? true : false}
                                                                            className={`blue-button width-100 ${((this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj) || !this.state.items.filter(item => item.coldChain).length ? 'blue-button-disabeld' : ''}`}
                                                                            onClick={() => {
                                                                                this.props[0].history.push({
                                                                                    pathname: '/hladni-lanac',
                                                                                    state: { output: this.registerGetValue('documentNumber') }
                                                                                })
                                                                            }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>HLADNI LANAC</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                style: { padding: '0 10px' },
                                                                children: [
                                                                    {
                                                                        type: 'div',
                                                                        children: <div className={this.state.items && this.state.items.length == 0 ? "" : ((this.state.items && this.state.items.filter(item => item.controledAmount != item.ammount).length) || !this.state.outputObj || (this.state.outputObj && !this.state.outputObj.inputCrates)) ? 'red-line' : 'green-line'}></div>,
                                                                    },
                                                                ]
                                                            },


                                                        ]
                                                    }

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                className: "custom-input-width-6",
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.article',
                                                        // textType: 'number',
                                                        label: 'Šifra',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.articleName',
                                                        label: 'Naziv',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.serialNumber',
                                                        label: 'Serija',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.deadline',
                                                        label: 'Rok',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.ammount',
                                                        label: 'Fakturisana količina',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.controledAmount',
                                                        label: 'Kontrolisana količina',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },
                                                    {
                                                        type: 'text',
                                                        name: 'packingItem.location',
                                                        label: 'Lokacija',
                                                        readOnly: true,
                                                        finishField: true,
                                                        asyncValidation: true,

                                                    },


                                                ]


                                            },
                                        ]

                                    }



                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="7" style={{ paddingLeft: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                hidePagination={true}
                                maxHeight={'50vh'}
                                fields={[
                                    { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'barCode', label: 'BAR KOD'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                ]}
                                coloredCells={[
                                    { name: 'deadline', backgroundColor: '#FBF55F', condition: 'expiresIn180Days' },
                                    { name: 'articleName', backgroundColor: '#138DB9', color: '#fff', condition: 'coldChain' }
                                ]}
                                items={this.state.items.filter(item => !item.controledAmount || (item.controledAmount < item.ammount)).map((item, idx) => {
                                    return {
                                        ...item,
                                        barCode: item.barcode ? item.barcode.map((el, i) => {
                                            let barcode = el;
                                            if (i + 1 != item.barcode.length) {
                                                barcode += ', '
                                            }
                                            return barcode
                                        }) : ''

                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}

                                // onClick={(item) => {
                                //     let form = this.registerGetData();
                                //     form.packingItem = item;
                                //     console.log(form)
                                //     this.setState({ form: null }, () => this.setState({ form }))
                                // }}

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                        <Col lg="5" style={{ paddingLeft: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                showNumeration={false}
                                hidePagination={true}
                                maxHeight={'50vh'}
                                fields={[
                                    { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'barCode', label: 'BAR KOD'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    // { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                    { type: 'text', name: 'controledAmount', label: 'K. KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                ]}
                                items={this.state.itemsPacked.map((item, idx) => {
                                    return {
                                        ...item,
                                        backgroundClassname: item.ammount < item.controledAmount ? 'red-row' : item.ammount > item.controledAmount ? 'orange-row' : item.ammount == item.controledAmount ? 'green-row' : '',
                                        barCode: item.barcode ? item.barcode.map((el, i) => {
                                            let barcode = el;
                                            if (i + 1 != item.barcode.length) {
                                                barcode += ', '
                                            }
                                            return barcode
                                        }) : ''

                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.itemsPacked}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={arrowDown} className="delete-icon" />,
                                            onClick: (item) => {
                                                if ((this.state.dropdownActive && this.state.dropdownActive.itemNumber != item.itemNumber) || !this.state.dropdownActive) {
                                                    this.setState({ dropdownActive: item })
                                                } else {
                                                    this.setState({ dropdownActive: null })
                                                }

                                            },
                                            dropdownShowCondition: (item) => {
                                                if (this.state.dropdownActive && item.itemNumber == this.state.dropdownActive.itemNumber)
                                                    return true
                                                else
                                                    return false
                                            },
                                            condition: (item) => {
                                                if (item.serialNumber || item.deadline || item.ammount)
                                                    return true
                                                else
                                                    return false
                                            },
                                            dropdownShow: <div className="drop-down-wrap">
                                                <table>
                                                    <tr>
                                                        <th>SERIJA</th>
                                                        <th>ROK</th>
                                                        <th>KOLIČINA</th>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-color-dark-black">{this.state.dropdownActive && this.state.dropdownActive.serialNumber}</td>
                                                        <td className="td-color-dark-black">{this.state.dropdownActive && this.state.dropdownActive.deadline}</td>
                                                        <td className="td-color-dark-black">{this.state.dropdownActive && this.state.dropdownActive.ammount}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        },

                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>
                    </Row>
                    {
                        this.state.notValid ?
                            <Modal isOpen={this.state.notValid} toggle={() => this.setState({ notValid: false })} size='lg' className="modal-wrap-input-crates">
                                <ModalBody>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        showNumeration={false}
                                        hidePagination={true}
                                        maxHeight={'60vh'}
                                        fields={[
                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'barCode', label: 'BAR KOD'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                        ]}
                                        items={this.state.items.filter(item => !item.controledAmount || (Number(item.controledAmount) != Number(item.ammount))).map((item, idx) => {
                                            return {
                                                ...item,
                                                backgroundClassname: item.ammount < item.controledAmount && item.controledAmount ? 'red-row' : item.ammount > item.controledAmount && item.controledAmount ? 'orange-row' : item.ammount == item.controledAmount && item.controledAmount ? 'green-row' : '',
                                                barCode: item.barcode ? item.barcode.map((el, i) => {
                                                    let barcode = el;
                                                    if (i + 1 != item.barcode.length) {
                                                        barcode += ', '
                                                    }
                                                    return barcode
                                                }) : ''

                                            }


                                        })}
                                        rawItems={this.state.items.filter(item => !item.controledAmount || (Number(item.controledAmount) != Number(item.ammount)))}
                                        actions={
                                            [

                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>
                            </Modal>
                            :
                            null
                    }
                    {
                        this.state.inputCratesModal ?
                            <Modal isOpen={this.state.inputCratesModal} toggle={() => this.setState({ inputCratesModal: false })} size='lg' className="modal-wrap-input-crates">

                                <ModalBody className="input-crates-wrap">
                                    <Row>
                                        <Col lg='8'>
                                            <div className="section-title">
                                                <h4>Upis gajbica</h4>
                                                <div className="inputs-wrap">
                                                    <div className="input-field-wrap">
                                                        {/* <Label>Sakupljač</Label> */}
                                                        {/* <div className='input-wrap'>
                                                            <Input type='text' value={this.state.inputCratesModal.collector} onChange={(e) => {
                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                inputCratesModal.collector = e.target.value;
                                                                this.setState({ inputCratesModal })
                                                            }} />
                                                            <Input type='text' value={this.state.inputCratesModal.collectorName} disabled={true} />
                                                        </div> */}
                                                        <Select
                                                            focusNext={() => {
                                                                if (typeof window !== 'undefined') {
                                                                    document.getElementById('select-with-search-packer').focus()
                                                                }
                                                            }}
                                                            finishKey={"Enter"}
                                                            onChange={(e) => {
                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                inputCratesModal.collector = e;
                                                                this.setState({ inputCratesModal })
                                                            }}
                                                            value={this.state.inputCratesModal.collector}
                                                            label={'Sakupljač'}
                                                            filterOnlyByName={true}
                                                            values={this.state.storekeepers.map((item, idx) => {
                                                                let obj = { value: item._id, name: item.username }
                                                                return obj
                                                            })}
                                                        ></Select>

                                                    </div>
                                                    <div className="input-field-wrap">
                                                        <Select
                                                            focusNext={() => {
                                                                document.getElementById('bar-code-input').focus()
                                                            }}
                                                            inputID={'select-with-search-packer'}
                                                            finishKey={"Enter"}
                                                            onChange={(e) => {
                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                inputCratesModal.packer = e;
                                                                this.setState({ inputCratesModal })
                                                            }}
                                                            value={this.state.inputCratesModal.packer}
                                                            label={'Paker'}
                                                            filterOnlyByName={true}
                                                            values={this.state.packer.map((item, idx) => {
                                                                let obj = { value: item._id, name: item.username }
                                                                return obj
                                                            })}
                                                        ></Select>

                                                        {/* <Label>Paker</Label> */}
                                                        {/* <div className='input-wrap'>
                                                            <Input type='text' value={this.state.inputCratesModal.packer} onChange={(e) => {
                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                inputCratesModal.packer = e.target.value;
                                                                this.setState({ inputCratesModal })
                                                            }} />
                                                            <Input type='text' value={this.state.inputCratesModal.packerName} disabled={true} />
                                                        </div> */}
                                                    </div>
                                                    <div className="input-field-wrap">
                                                        <Label>Bar kod</Label>
                                                        <Input id="bar-code-input" type='text' value={this.state.inputCratesModal.barCode} onChange={(e) => {
                                                            let inputCratesModal = this.state.inputCratesModal;
                                                            inputCratesModal.barCode = e.target.value;
                                                            this.setState({ inputCratesModal })
                                                        }}
                                                            onKeyUp={(e) => {
                                                                if (e.key == "Enter") {
                                                                    if (this.state.inputCratesModal.barCode) {
                                                                        this._apiAsync('check-crate-bar-code', { barCode: this.state.inputCratesModal.barCode, typeOfChange: this.state.typeOfChange }).then((res) => {

                                                                            if (res && res._id) {
                                                                                let message = `Gajbica je već iskorištena za fakturu ${res.documentNumber}. Molimo Vas vratite gajbicu kroz modul Vraćanje gajbice.`
                                                                                this.setState({
                                                                                    message: message
                                                                                })
                                                                            } else if (this.state.inputCratesModal && this.state.inputCratesModal.barCodes.filter(el => el.barCode == this.state.inputCratesModal.barCode && !el.cardboardBox).length) {
                                                                                let message = `Gajbica je već iskorištena za fakturu ${this.registerGetValue('documentNumber')}. Molimo Vas vratite gajbicu kroz modul Vraćanje gajbice.`
                                                                                this.setState({
                                                                                    message: message
                                                                                })
                                                                            } else {
                                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                                inputCratesModal.barCodes.push({
                                                                                    barCode: this.state.inputCratesModal.barCode,
                                                                                    cardboardBox: env.CARDBOARD_BOX == this.state.inputCratesModal.barCode ? true : false
                                                                                })
                                                                                inputCratesModal.barCode = '';
                                                                                this.setState({ inputCratesModal })
                                                                            }



                                                                        })
                                                                    }


                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <div className="input-field-wrap">
                                                        <Switch
                                                            onChange={(value) => {
                                                                let inputCratesModal = this.state.inputCratesModal;
                                                                inputCratesModal.cardboardBox = !inputCratesModal.cardboardBox;
                                                                this.setState({ inputCratesModal })
                                                            }}
                                                            value={this.state.inputCratesModal.cardboardBox}
                                                            label={"Kartonska kutija"}
                                                        ></Switch>
                                                    </div> */}
                                                    <div className="button-wrap">
                                                        <Button
                                                            className={this.state.inputCratesModal.collector && this.state.inputCratesModal.packer && this.state.inputCratesModal.barCodes && this.state.inputCratesModal.barCodes.length ? "blue-button" : "blue-button blue-button-disabeld"}
                                                            disabled={this.state.inputCratesModal.collector && this.state.inputCratesModal.packer && this.state.inputCratesModal.barCodes && this.state.inputCratesModal.barCodes.length ? false : true}
                                                            onClick={() => {
                                                                let data = {};


                                                                if (this.state.inputCratesModal.barCode) {
                                                                    let inputCratesModal = this.state.inputCratesModal;
                                                                    inputCratesModal.barCodes.push({
                                                                        barCode: this.state.inputCratesModal.barCode,
                                                                        cardboardBox: env.CARDBOARD_BOX == this.state.inputCratesModal.barCode ? true : false
                                                                    })
                                                                    inputCratesModal.barCode = '';
                                                                    this.setState({ inputCratesModal }, () => {
                                                                        if (this.state.inputCratesModal.packer) {
                                                                            data.packer = this.state.inputCratesModal.packer;
                                                                        }
                                                                        if (this.state.inputCratesModal.collector) {
                                                                            data.packer = this.state.inputCratesModal.collector;
                                                                        }
                                                                        if (this.state.inputCratesModal.barCodes) {
                                                                            data.barCodes = this.state.inputCratesModal.barCodes;
                                                                        }
                                                                        data.documentNumber = this.registerGetValue('documentNumber')
                                                                        data.typeOfChange = this.state.typeOfChange;

                                                                        if (data.packer && data.collector && data.barCodes && data.barCodes.length) {
                                                                            this._apiAsync('update-crates', data).then((res) => {
                                                                                this.setState({ inputCratesModal: null })
                                                                                if (res) {
                                                                                    let outputObj = this.state.outputObj;
                                                                                    outputObj.inputCrates = res;
                                                                                    this.setState({ outputObj })
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                } else {
                                                                    if (this.state.inputCratesModal.packer) {
                                                                        data.packer = this.state.inputCratesModal.packer;
                                                                    }
                                                                    if (this.state.inputCratesModal.collector) {
                                                                        data.collector = this.state.inputCratesModal.collector;
                                                                    }
                                                                    if (this.state.inputCratesModal.barCodes) {
                                                                        data.barCodes = this.state.inputCratesModal.barCodes;
                                                                    }
                                                                    data.documentNumber = this.registerGetValue('documentNumber')
                                                                    data.typeOfChange = this.state.typeOfChange;

                                                                    if (data.packer && data.collector && data.barCodes && data.barCodes.length) {
                                                                        this._apiAsync('update-crates', data).then((res) => {
                                                                            this.setState({ inputCratesModal: null })
                                                                            if (res) {
                                                                                let outputObj = this.state.outputObj;
                                                                                outputObj.inputCrates = res;
                                                                                this.setState({ outputObj })
                                                                            }
                                                                        })
                                                                    }

                                                                }

                                                            }}>&gt; Snimi</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg='4'>
                                            <div className="section-title">
                                                <h4>Bar kodovi</h4>
                                            </div>
                                            <h6>Kodovi</h6>
                                            <div className="codes-wrap">
                                                {
                                                    this.state.inputCratesModal.barCodes && this.state.inputCratesModal.barCodes.map((item, idx) => {
                                                        return (
                                                            <div className="code-item">
                                                                {item.barCode}
                                                                <div onClick={() => {
                                                                    let inputCratesModal = this.state.inputCratesModal;
                                                                    inputCratesModal.barCodes.splice(idx, 1)
                                                                    this.setState({ inputCratesModal })
                                                                }}>
                                                                    <Isvg src={garbageIcon} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>

                            </Modal>
                            :
                            null
                    }
                    <InfoModal
                        isOpen={this.state.message}
                        toggle={() => this.setState({ message: null })}
                        title={'Obavještenje'}
                        info={this.state.message}
                        buttons={[
                            {
                                text: 'OK',
                                onClick: () => {
                                    this.setState({ message: null })
                                }
                            }
                        ]}
                    >
                    </InfoModal>
                    <InfoModal
                        isOpen={this.state.versionChangedModal}
                        toggle={() => this.setState({ versionChangedModal: null })}
                        title={'Obavještenje'}
                        info={"Došlo je do promjene verzije dokumenta!"}
                        buttons={[
                            {
                                text: 'OK',
                                onClick: () => {
                                    this.setState({ versionChangedModal: null })

                                    let value = this.state.version?.documentNumber;
                                    if (value) {
                                        this.setState({ loadingPage: true }, () => {
                                            this._apiAsync('get-output', { documentNumber: value, status: [2, 3, 4] }).then((res) => {

                                                if (res && res._id && this.registerGetValue('documentNumber')) {
                                                    if (res.documentNumber && String(res.documentNumber) === String(this.registerGetValue('documentNumber'))) {
                                                        this.setState({ outputObj: res, loadingPage: false }, () => {
                                                            this.setVersion()
                                                        })
                                                        if (res && res.items) {
                                                            let itemsPacked = [];
                                                            for (let i = 0; i < res.items.length; i++) {
                                                                if (res.items[i].controledAmount && res.items[i].controledAmount > 0) {
                                                                    itemsPacked.push(res.items[i])
                                                                }
                                                            }
                                                            this.setState({ items: res.items, itemsPacked: itemsPacked })
                                                        }
                                                    }
                                                } else {
                                                    this.setState({ outputObj: null, loadingPage: false, items: [], itemsPacked: [] }, () => {
                                                        this.setVersion()
                                                    })
                                                }
                                            })
                                        })


                                    }
                                }
                            }
                        ]}
                    >
                    </InfoModal>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(PackingPage);
