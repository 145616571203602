
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

import DateField from '../../form-builder/fields/date';


class ColdChainPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            openDocument: null
        }
    }

    componentDidMount() {
        this._onLoad();

        if (this.props[0].location.state && this.props[0].location.state.output) {
            this.setState({ openDocument: this.props[0].location.state.output }, () => {
                this.props[0].history.push({
                    state: { output: null }
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (!prevState.items.length && this.state.items.length && this.state.openDocument) {
            console.log('ssss')
            console.log(this.state.items.filter(item => item && item.output && String(item.output.documentNumber) == String(this.state.openDocument)))
            if (this.state.items.filter(item => item && item.output && String(item.output.documentNumber) == String(this.state.openDocument)).length) {
                this.setState({
                    coldChainPreview: this.state.items.filter(item => item && item.output && String(item.output.documentNumber) == String(this.state.openDocument))[0]
                }, () => this.setState({ openDocument: null }))
            }
        }
        if (this.props.selectedYear != prevProps.selectedYear) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Hladni lanac</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati grupu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal> */}
                    <Row>
                        <Col lg="12" className="panel-backgound">
                            <div className="list-builder-filters-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <div className="list-builder-filters" style={{ alignItems: 'flex-end' }}>
                                    <div className="field-strap-wrap" style={{ width: 130 }}>
                                        <Input style={{ width: 130 }} type='text' value={this.state.filterDocumentNumber != null ? this.state.filterDocumentNumber : params.documentNumber} onChange={(e) => this.setState({ filterDocumentNumber: e.target.value }, () => {
                                            this.updateParams('documentNumber', this.state.filterDocumentNumber)
                                        })} placeholder={'Br. dok.'} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 210 }}>
                                        <Input style={{ width: 210 }} type='text' value={this.state.filterColdChainNumber != null ? this.state.filterColdChainNumber : params.coldChainNumber} onChange={(e) => this.setState({ filterColdChainNumber: e.target.value }, () => {
                                            this.updateParams('coldChainNumber', this.state.filterColdChainNumber)
                                        })} placeholder={'Br. hladnog lanca'} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 150 }}>
                                        <DateField
                                            additionalClassName={'date-input-width-150'}
                                            onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                this.updateParams('dateFrom', this.state.filterDateFrom)
                                            })}
                                            value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                            placeholder={'Datum od'}
                                        ></DateField>
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 150 }}>
                                        <DateField
                                            additionalClassName={'date-input-width-150'}
                                            onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                this.updateParams('dateTo', this.state.filterDateTo)
                                            })}
                                            value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo}
                                            placeholder={'Datum do'}
                                        ></DateField>
                                    </div>

                                    {/* <div className="field-strap-wrap" style={{ width: 200 }}>
                                        <Input style={{ width: 200 }} type='date' value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom} onChange={(e) => this.setState({ filterDateFrom: e.target.value }, () => {
                                            this.updateParams('dateFrom', this.state.filterDateFrom)
                                        })} />
                                    </div> */}
                                    {/* <div className="field-strap-wrap" style={{ width: 200 }}>
                                        <Input style={{ width: 200 }} type='date' value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo} onChange={(e) => this.setState({ filterDateTo: e.target.value }, () => {
                                            this.updateParams('dateTo', this.state.filterDateTo)
                                        })}>

                                        </Input>
                                    </div> */}
                                    <div className="field-strap-wrap" style={{ width: 200 }}>
                                        <Input type='select' style={{ width: 200 }} value={this.state.filterTypeOfChange != null ? this.state.filterTypeOfChange : params.change} onChange={(e) => this.setState({ filterTypeOfChange: e.target.value }, () => {
                                            this.updateParams('change', this.state.filterTypeOfChange != -1 ? this.state.filterTypeOfChange : null)
                                        })}>
                                            <option value={-1}>Vrsta promjene</option>
                                            <option value={1}>Izlaz</option>
                                            <option value={2}>Naturalni rabat</option>
                                        </Input>
                                    </div>

                                </div>
                                <div className="list-builder-filters" style={{ display: 'flex', alignItems: 'flex-end' }}>

                                    <div className="field-strap-wrap" style={{ width: 400 }}>
                                        <Input style={{ width: 400 }} type='text' value={this.state.filterClient != null ? this.state.filterClient : params.client} onChange={(e) => this.setState({ filterClient: e.target.value }, () => {
                                            this.updateParams('client', this.state.filterClient)
                                        })} placeholder={'Komitent'} />
                                    </div>
                                    <div className="field-strap-wrap" style={{ width: 400 }}>
                                        <Input style={{ width: 400 }} type='text' value={this.state.filterBusiness != null ? this.state.filterBusiness : params.business} onChange={(e) => this.setState({ filterBusiness: e.target.value }, () => {
                                            this.updateParams('business', this.state.filterBusiness)
                                        })} placeholder={'Poslovna'} />
                                    </div>
                                    <div className="clear-filters" style={{ top: 0, marginLeft: 25 }} onClick={() => {

                                        this.setState({
                                            filterDateFrom: '',
                                            filterDateTo: '',
                                            filterClient: '',
                                            filterBusiness: '',
                                            filterDocumentNumber: '',
                                            filterTypeOfChange: -1,
                                            filterColdChainNumber: '',
                                        }, () => {
                                            this.updateMultipleParams([{ name: 'dateFrom', value: null }, { name: 'dateTo', value: null }, { name: 'client', value: null }, { name: 'business', value: null }, { name: 'documentNumber', value: null }, { name: 'change', value: null }, { name: 'coldChainNumber', value: null }])

                                        })

                                    }}>
                                        <a><span>&times;</span> Ukloni filter</a>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 25 }}>
                            <ListBuilder
                                tabs={false}
                                sort={false}
                                hideFilters={true}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'documentNumber', label: 'BR. HLADNOG LANCA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'output.documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'typeText', label: 'VRSTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'output.clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'output.businessName', label: 'POSLOVNA JEDINICA'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'controledByUsername', label: 'KONTROLISAO'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'controledDate', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true },
                                ]}
                                items={this.state.items.map((item, idx) => {
                                    return {
                                        ...item,
                                        ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                        typeText: 'Izlaz',
                                        time: moment.unix(item.controledDateTs).format('HH:mm')
                                        // user: item.createdByUsername
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={arrowDown} className="delete-icon" />,
                                            onClick: (item) => this.setState({ coldChainPreview: item })
                                        },
                                        // {
                                        //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]
                                }
                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </Col>

                    </Row>
                    {
                        this.state.coldChainPreview ?
                            <Modal isOpen={this.state.coldChainPreview} toggle={() => this.setState({ coldChainPreview: false })} size='lg' className="modal-wrap-cold-chain-preview">
                                <ModalHeader>
                                    <div className='title'>Pregled artikala u hladnom lancu {this.state.coldChainPreview && this.state.coldChainPreview.documentNumber ? `(${this.state.coldChainPreview.documentNumber})` : null}</div>
                                    <Button className="print-button"><div className="svg-box"><Isvg src={printIcon} /></div>Štampaj</Button>
                                </ModalHeader>
                                <ModalBody className="cold-chain-wrap">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        // total={this.state.total}
                                        hidePagination={true}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'itemNumber', label: 'BROJ'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'article', label: 'ŠIFRA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'articleName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'serialNumber', label: 'SERIJA'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false },
                                            { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false },

                                        ]}
                                        items={this.state.coldChainPreview && this.state.coldChainPreview.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                ordinalNumber: idx + 1
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.coldChainPreview && this.state.coldChainPreview.items ? this.state.coldChainPreview.items : []}
                                        actions={
                                            [
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ form: item })
                                                //     //onClick: (item) => console.log(item)
                                                // },
                                                // {
                                                //     component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                //     onClick: (item) => this.setState({ deleteModal: item })
                                                // },
                                            ]
                                        }
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </ModalBody>

                            </Modal>
                            :
                            null
                    }
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal>
                </Container>

            </div >
        )
    }
}

export default Page(ColdChainPage);
