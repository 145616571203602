import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';


import env from "react-dotenv";




class Banners extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            banner: {}
            // pomocna: true
        }
    }

    componentDidMount() {
        this.get()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this.get();
        }

    }

    get = () => {
        this._apiAsync('get-banners', {}).then((res) => {
            let banner = {}
            if (res && res.banner) {
                banner = res.banner;
            }
            this.setState({ banner: null }, () => {
                this.setState({ banner })
            })
        })
    }


    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1></h1>
                    <div className="title-options-group">
                        <button onClick={() => {
                            this.submit()
                        }}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>

                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    let banners = {}
                                    if (data && data.banners) {
                                        banners = data.banners;
                                        for (var key in banners) {
                                            if (!banners[key] || (banners[key] && !banners[key].image)) {
                                                delete banners[key]
                                            }
                                        }
                                    }

                                    this._apiAsync('save-banners', { banners: banners }).then((res) => {
                                        this.get();
                                    })

                                }}
                                fieldsToTrack={[]}
                                fieldTracker={async (name, value) => {

                                }}
                                initialValues={this.state.banner}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        // header: 'Izmjena serije'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'banners.bannerSidebar1.image',
                                                                        endpoint: '/data/image/upload',
                                                                        label: 'Sidebar 1',
                                                                        // className: 'file-field-without-margin-bottom',
                                                                        finishField: true,
                                                                        validate: [
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'banners.bannerSidebar1.link',
                                                                        label: 'Link (Sidebar 1)',

                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'banners.bannerSidebar2.image',
                                                                        endpoint: '/data/image/upload',
                                                                        label: 'Sidebar 2',
                                                                        // className: 'file-field-without-margin-bottom',
                                                                        finishField: true,
                                                                        validate: [
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'banners.bannerSidebar2.link',
                                                                        label: 'Link (Sidebar 2)',

                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'banners.bannerHeader1.image',
                                                                        endpoint: '/data/image/upload',
                                                                        label: 'Header 1',
                                                                        // className: 'file-field-without-margin-bottom',
                                                                        finishField: true,
                                                                        validate: [
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'banners.bannerHeader1.link',
                                                                        label: 'Link (Header 1)',

                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'image',
                                                                        name: 'banners.bannerHeader2.image',
                                                                        endpoint: '/data/image/upload',
                                                                        label: 'Header 2',
                                                                        // className: 'file-field-without-margin-bottom',
                                                                        finishField: true,
                                                                        validate: [
                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'text',
                                                                        name: 'banners.bannerHeader2.link',
                                                                        label: 'Link (Header 2)',

                                                                    },
                                                                ]
                                                            },




                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>

                    </Row>
                    {/* <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            this._apiAsync('remove-series', { _id: this.state.deleteModal._id }).then((res) => {
                                this.setState({ deleteModal: null })
                                this._onLoad()
                            })
                        }} >
                        Obrisati seriju <strong>{this.state.deleteModal ? this.state.deleteModal.serialNumber : ''}</strong> ?
                    </DeleteModal> */}
                </Container>

            </div >
        )
    }
}

export default Page(Banners);

/*

*/