import { Component } from "react";

import {
  Row,
  Col,
  Table,
  FormGroup, Label, Input
} from 'reactstrap';
import Text from "./fields/text";
import Date from "./fields/date";

import TextTransparent from "./fields/text-transparent";
import Button from "./fields/button";
import Textarea from "./fields/textarea";
import Switch from "./fields/switch";
import List from "./fields/list";
import Select from "./fields/select";
import SelectWithSearch from "./fields/selectWithSearch";
import Tags from "./fields/tags";
import TagsSearch from "./fields/tagsSearch";
import File from "./fields/file";
import Image from "./fields/image";
import RadioGroup from "./fields/radioGroup";

import DropdownSelect from "./fields/dropdown";
import ReferentLabel from "./fields/referentLabel";
import Checkbox from "./fields/checkbox";
import Article from "./fields/article";
import Client from "./fields/client";

import BusinessUnit from "./fields/businessUnit";
import Decimal from "./fields/decimal";
import Series from "./fields/series";


// import RadioGroup from "./fields/radioGroup";


var _ = require("lodash");

const FIN_KEY = "Enter";

class FormBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      touched: {},
    };

    this.fieldRefs = {};
  }

  getFieldsDict = (fields, dict = {}) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i] && fields[i].name) {
        dict[fields[i].name] = fields[i];
      }

      if (fields[i] && fields[i].children) {
        dict = {
          ...dict,
          ...this.getFieldsDict(fields[i].children, dict),
        };
      }
    }
    return dict;
  };


  getAsyncFieldValidations = (fields, validations = {}) => {
    for (let i = 0; i < fields.length; i++) {
      if (fields[i] && fields[i].validate && fields[i].asyncValidation) {
        validations[fields[i].name] = fields[i].validate;
      }

      if (fields[i] && fields[i].children) {
        validations = {
          ...validations,
          ...this.getAsyncFieldValidations(fields[i].children, validations),
        };
      }
    }
    return validations;
  };

  getFieldValidations = (fields, validations = {}) => {
    for (let i = 0; i < fields.length; i++) {
      // if (fields[i].type == 'select') {
      //   if (fields[i].validate && fields[i].validate.length) {
      //     let values = fields[i].values;
      //     fields[i].validate.push(
      //       (value) => {
      //         if (values && values.length && values.filter(el => el.value == value).length == 0) {
      //           return "Nije selektovana ispravna vrijednost"
      //         }
      //       }
      //     )

      //   }


      // }
      if (fields[i] && fields[i].validate) {
        validations[fields[i].name] = fields[i].validate;
      }

      if (fields[i] && fields[i].children) {
        validations = {
          ...validations,
          ...this.getFieldValidations(fields[i].children, validations),
        };
      }
    }
    return validations;
  };

  processValidation = async (value, validate) => {
    for (let i = 0; i < validate.length; i++) {
      let error = await validate[i](value, this.state.data);
      if (error) {
        return error;
      }
    }

    return null;
  };



  onChange = async (name, value, validate, callback = null) => {
    let data = { ...this.state.data };
    _.set(data, name, value);
    this.setState({
      data,
    }, async () => {
      if (this.props.fieldsToTrack) {
        let idx = this.props.fieldsToTrack.indexOf(name);
        if (idx !== -1) {
          await this.props.fieldTracker(this.props.fieldsToTrack[idx], _.get(this.state.data, this.props.fieldsToTrack[idx]))
        }
      }


      if (callback) {
        callback()
      }

      // let touched = this.state.touched;
      // let errors = this.state.errors;
      // if (validate) {
      //   touched[name] = true;
      //   let validations = this.getAsyncFieldValidations(this.props.fields);
      //   for (var fieldName in touched) {
      //     if (validations[fieldName]) {
      //       errors[fieldName] = await this.processValidation(
      //         _.get(this.state.data, fieldName),
      //         validations[fieldName]
      //       );
      //     }
      //   }
      // }

    });




  };

  remoteOnChange = (name, value) => {
    let data = this.state.data;
    let touched = this.state.touched;
    let errors = this.state.errors;

    _.set(data, name, value);

    this.setState({
      data,
      touched,
      errors,
    }, () => {
      if (this.props.fieldsToTrack) {
        let idx = this.props.fieldsToTrack.indexOf(name);
        if (idx !== -1) {
          this.props.fieldTracker(this.props.fieldsToTrack[idx], _.get(this.state.data, this.props.fieldsToTrack[idx]))
        }
      }

    });
  };


  getValue = (name) => {
    return _.get(this.state.data, name);
  };
  getDataValue = () => {
    return this.state.data;
  };

  setFieldRef = (name, node) => {
    this.fieldRefs[name] = node;
  };

  focusNext = async (from, to, afterSubmitFocus = null) => {
    let dict = this.getFieldsDict(this.props.fields, {});

    let touched = this.state.touched;
    let errors = this.state.errors;
    if (dict[from].validate) {
      touched[from] = true;
      let validations = this.getAsyncFieldValidations(this.props.fields);
      console.log(validations)
      for (var fieldName in touched) {
        if (validations[fieldName]) {
          errors[fieldName] = await this.processValidation(
            _.get(this.state.data, fieldName),
            validations[fieldName]
          );
        }
      }
    }

    this.setState({ errors: { ...errors }, touched: { ...touched } })

    if (errors[from]) {
      return;
    }

    if (to == 'submitForm') {
      this.submit(true);
      if (afterSubmitFocus) {
        this.fieldRefs[afterSubmitFocus].focus();
        this.fieldRefs[afterSubmitFocus].select();
      }
      return;
    }
    if (!this.fieldRefs[from]) {
      return;
    }
    if (!this.fieldRefs[to]) {
      return;
    }
    if (this.fieldRefs[to].focus) {
      this.fieldRefs[to].focus();
    } else if (!this.fieldRefs[to].focus && this.fieldRefs[to].input) {
      document.getElementById('react-tags-' + to).children[1].children[1].children[0].children[0].focus()
      // this.fieldRefs[to].current.input = true;
    }

  };


  validateNonFocusField = async (field) => {
    let dict = this.getFieldsDict(this.props.fields, {});

    let touched = this.state.touched;
    let errors = this.state.errors;
    if (dict[field].validate) {
      touched[field] = true;
      let validations = this.getAsyncFieldValidations(this.props.fields);
      for (var fieldName in touched) {
        if (validations[fieldName]) {
          errors[fieldName] = await this.processValidation(
            _.get(this.state.data, fieldName),
            validations[fieldName]
          );
        }
      }
    }

    this.setState({ errors: { ...errors }, touched: { ...touched } })

    if (errors[field]) {
      return true;
    }

  };


  registerFocusedField = (name) => {
    if (name == this.state.focusedField) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    // if (this.props.initialValues) {
    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });
    // }
    if (this.props.initialValues) {
      let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
      let obj = {};
      for (var key in data1) {
        _.set(obj, key, data1[key]);
      }
      this.setState({
        data: obj,
      });
    }

    if (this.props.registerSubmitAction) {
      this.props.registerSubmitAction(this.submit);
    }

    if (this.props.registerChangeValue) {
      this.props.registerChangeValue(this.remoteOnChange);
    }

    if (this.props.registerGetValue) {
      this.props.registerGetValue(this.getValue);
    }
    if (this.props.registerGetData) {
      this.props.registerGetData(this.getDataValue);
    }
    if (this.props.registerFocusedField) {
      this.props.registerFocusedField(this.registerFocusedField);
    }


  }


  componentDidUpdate(prevProps, prevState) {




    // if (!prevProps.initialValues && this.props.initialValues) {

    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });
    // }

    // if (prevProps.initialValues != this.props.initialValues && this.props.initialValues) {


    //   this.setState({
    //     data: JSON.parse(JSON.stringify(this.props.initialValues)),
    //   });

    // }
    // // if(JSON.stringify(prevState.data) != JSON.stringify(this.state.data)){
    // //   console.log(this.state.data)
    // // }

    if (!prevProps.initialValues && this.props.initialValues) {
      let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
      let obj = {};
      for (var key in data1) {
        _.set(obj, key, data1[key]);
      }
      this.setState({
        data: obj,
        errors: {},
        touched: {}
      });
    }

    // if (prevProps.initialValues != this.props.initialValues && this.props.initialValues) {
    //   let data1 = JSON.parse(JSON.stringify(this.props.initialValues));
    //   let obj = {};
    //   for (var key in data1) {
    //     _.set(obj, key, data1[key]);
    //   }
    //   this.setState({
    //     data: obj,
    //   });
    // }



    // if (this.props.fieldsToTrack && this.props.fieldsToTrack.length) {
    //   for (let i = 0; i < this.props.fieldsToTrack.length; i++) {
    //     if (_.get({ ...prevState.data }, this.props.fieldsToTrack[i]) != _.get({ ...this.state.data }, this.props.fieldsToTrack[i])) {
    //       this.props.fieldTracker(this.props.fieldsToTrack[i], _.get(this.state.data, this.props.fieldsToTrack[i]))
    //     }
    //   }
    // }

  }

  // componentWillReceiveProps(nextProps, nextState) {

  //   if (this.props.fieldsToTrack && this.props.fieldsToTrack.length) {
  //     for (let i = 0; i < this.props.fieldsToTrack.length; i++) {
  //       if (_.get({ ...nextState.data }, this.props.fieldsToTrack[i]) != _.get({ ...this.state.data }, this.props.fieldsToTrack[i])) {
  //         this.props.fieldTracker(this.props.fieldsToTrack[i], _.get(this.state.data, this.props.fieldsToTrack[i]))
  //       }
  //     }
  //   }

  // }


  submit = async (notFinished) => {
    let data = JSON.parse(JSON.stringify(this.state.data));

    let errors = this.state.errors;
    let touched = this.state.touched;



    let validations = this.getFieldValidations(this.props.fields);

    let foundError = false;

    for (var fieldName in validations) {
      if (validations[fieldName]) {
        errors[fieldName] = await this.processValidation(
          _.get(data, fieldName),
          validations[fieldName]
        );
        if (errors[fieldName]) {
          touched[fieldName] = true;
          foundError = true;
        }
      }
    }

    this.setState({
      errors,
      touched,
    });

    if (foundError) {
      return;
    }

    let check = this.props.onSubmit(data, notFinished);
    if (check && check == 'open-modal') {
      return
    }

    data = {};
    errors = {};
    touched = {};
    this.setState({ data, errors, touched }, () => {
      if (this.props.afterSubmitFocusField) {
        if (!this.fieldRefs[this.props.afterSubmitFocusField]) {
          return;
        }

        this.fieldRefs[this.props.afterSubmitFocusField].focus();
      }
    });

  }

  handleAction = async (button) => {
    if (button.action == "submit") {
      this.submit()

    } else if (button.action == "clear") {
      let data = {};
      let errors = {};
      let touched = {};
      this.setState({ data, errors, touched });
      if (this.props.onClear) {
        this.props.onClear()
      }
    } else if (button.action == 'custom') {
      button.onClick()

    }
  };


  renderFields = (fields) => {
    return fields.map((item, idx) => {
      if (!item)
        return null;

      if (item.type == 'row') {
        return <Row key={idx} className={item.className} style={item.style ? item.style : {}}> {this.renderFields(item.children)}</Row>;
      }
      else if (item.type == "block") {
        return <Row style={item.style ? item.style : {}} className={this.props.classNameForm} key={idx}>
          {/* {
            item.header ?
              <div className="custom-header">
                <h6 style={{ display: 'block', width: '100%' }}>{item.header}</h6>
                {
                  item.referent ?
                    <div className={this.state.referentDropdown ? 'special-dropdown opened' : 'special-dropdown'}>
                      <span onClick={() => {
                        this.setState({
                          referentDropdown: !this.state.referentDropdown,
                          bookNoticeDropdown: false
                        })
                      }}>Referent</span>
                      <ul>
                        <li>Test 1</li>
                        <li>Test 2</li>
                        <li>Test 3</li>
                      </ul>
                    </div>
                    :
                    null
                }
                {
                  item.bookNotification ?
                    <div className={this.state.bookNoticeDropdown ? 'special-dropdown opened' : 'special-dropdown'}>
                      <span onClick={() => {
                        this.setState({
                          bookNoticeDropdown: !this.state.bookNoticeDropdown, referentDropdown: false
                        })
                      }}>Knjižne obavjesti</span>
                      <ul >
                        <li data-id="period" onClick={(e) => { this.setState({ knjiznaObavjest: e.target.dataset.id }, () => { console.log('Kliknutooooooo....', this.state.knjiznaObavjest); this.setState({ bookNoticeDropdown: false }) }) }}>Po periodu</li>
                        <li data-id="racun" onClick={(e) => { this.setState({ knjiznaObavjest: e.target.dataset.id }, () => { this.setState({ bookNoticeDropdown: false }) }) }}>Po računu</li>
                        <li data-id="test" onClick={(e) => { this.setState({ knjiznaObavjest: e.target.dataset.id }, () => { this.setState({ bookNoticeDropdown: false }) }) }}>Test 3</li>
                      </ul>
                    </div>
                    :
                    null
                }
              </div>
              :
              null
          } */}
          {this.renderFields(item.children)}</Row>;
      } else if (item.type == "col") {
        return (
          <Col
            key={idx}
            className={item.className}
            style={item.style ? item.style : {}}
            lg={{ size: item.width.lg, offset: item.offset && item.offset.lg ? item.offset.lg : 0 }}
            sm={{ size: item.width.sm, offset: item.offset && item.offset.sm ? item.offset.sm : 0 }}
            xs={{ size: item.width.xs, offset: item.offset && item.offset.xs ? item.offset.xs : 0 }}
          >
            {this.renderFields(item.children)}
          </Col>
        );
      } else if (item.type == "text") {
        return (
          <Text
            key={idx}
            maxLength={item.maxLength}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            textType={item.textType}
            autoComplete={item.autoComplete}
            decimal={item.decimal}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            enterCalFunction={item.enterCalFunction}
            enterCall={item.enterCall}
            onChange={(value) => {
              if (!value) {
                value = null;
              } else if (item.textType == 'number') {
                if (value != '0') {
                  value = Number(value)
                }
              }
              if (item.integer && value) {
                value = String(value).replace(/[^\d]/g, '')
                if (value && Number(value)) {
                  value = Number(value);
                }
              }

              this.onChange(item.name, value, item.asyncValidation)
            }
            }
            onFocus={() => {
              this.setState({ focusedField: item.name })
            }}
            // onBlur={() => {
            //   this.setState({ focusedField: null })
            // }}
            readOnly={item.readOnly ? item.readOnly : false}
            value={this.getValue(item.name)}
            label={item.label}
            additionalClassName={item.className}
            placeholder={item.placeholder}
          ></Text>
        );
      } else if (item.type == "decimal") {
        return (
          <Decimal
            key={idx}
            maxLength={item.maxLength}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            onFinishKey={() => {
              if (item.onFinishKey) {
                item.onFinishKey();
              }
            }}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            textType={item.textType}
            autoComplete={item.autoComplete}
            decimal={item.decimal}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) => {
              if (!value) {
                value = null;
              } else if (item.textType == 'number') {
                if (value != '0') {
                  value = Number(value)
                }
              }
              if (item.integer && value) {
                value = String(value).replace(/[^\d]/g, '')
                if (value && Number(value)) {
                  value = Number(value);
                }
              }

              this.onChange(item.name, value, item.asyncValidation)
            }
            }
            onFocus={() => {
              this.setState({ focusedField: item.name })
            }}

            // onBlur={() => {
            //   this.setState({ focusedField: null })
            // }}
            readOnly={item.readOnly ? item.readOnly : false}
            value={this.getValue(item.name)}
            label={item.label}
            additionalClassName={item.className}
            placeholder={item.placeholder}
          ></Decimal>
        );
      } else if (item.type == "date") {
        return (
          <Date
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            textType={item.textType}
            autoCompleteYearOff={item.autoCompleteYearOff}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) => {
              if (!value) {
                value = null;
              }
              this.onChange(item.name, value, item.asyncValidation)
            }
            }
            onFocus={() => {
              this.setState({ focusedField: item.name })
            }}
            // onBlur={() => {
            //   this.setState({ focusedField: null })
            // }}
            readOnly={item.readOnly ? item.readOnly : false}
            value={this.getValue(item.name)}
            label={item.label}
            additionalClassName={item.className}
            placeholder={item.placeholder}

          ></Date>
        );
      }
      else if (item.type == "select") {
        return (
          <Select
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            onFinishKey={() => {
              if (item.onFinishKey) {
                item.onFinishKey();
              }
            }}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            values={item.values}
            readOnly={item.readOnly ? item.readOnly : false}
            arrow={item.arrow ? true : false}
          // disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
          ></Select>
        );
      } else if (item.type == "dropdown") {
        return (
          <DropdownSelect
            key={idx}
            // touched={this.state.touched[item.name]}
            // error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            className={item.className}
            values={item.values}
            readOnly={item.readOnly ? item.readOnly : false}
            headerLabel={item.headerLabel}
          // disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
          ></DropdownSelect>
        );

      } else if (item.type == "referentLabel") {
        return (
          <ReferentLabel
            key={idx}
            // touched={this.state.touched[item.name]}
            // error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            className={item.className}
            values={item.values}
            content={item.content}
            readOnly={item.readOnly ? item.readOnly : false}
          // disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
          ></ReferentLabel>
        );
      } else if (item.type == "selectWithSearch") {
        return (
          <SelectWithSearch
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            readOnly={item.readOnly}
            value={this.getValue(item.name)}
            label={item.label}
            showName={item.showName}
            placeholder={item.placeholder}
            values={item.values}
            dataModal={item.dataModal}
            title={item.title}
            disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
            metoda={item.metoda}
          ></SelectWithSearch>
        );
      } else if (item.type == "article") {
        return (
          <Article
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            loading={item.loading}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            readOnly={item.readOnly}
            value={this.getValue(item.name)}
            label={item.label}
            showName={item.showName}
            placeholder={item.placeholder}
            values={item.values}
            dataModal={item.dataModal}
            title={item.title}
            disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
            metoda={item.metoda}
          ></Article>
        );
      } else if (item.type == 'series') {
        return (
          <Series
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            readOnly={item.readOnly}
            value={this.getValue(item.name)}
            label={item.label}
            showName={item.showName}
            placeholder={item.placeholder}
            values={item.values}
            dataModal={item.dataModal}
            title={item.title}
            metoda={item.metoda}
          >

          </Series>
        );
      } else if (item.type == "client") {
        return (
          <Client
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            readOnly={item.readOnly}
            value={this.getValue(item.name)}
            label={item.label}
            showName={item.showName}
            placeholder={item.placeholder}
            values={item.values}
            dataModal={item.dataModal}
            title={item.title}
            disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
            metoda={item.metoda}
            entrance={item.entrance}
            estimation={item.estimation}
            jciRequired={item.jciRequired}
            control={item.control}
            enableEdit={item.enableEdit}
            changeState={(callback) => {
              if (item.changeState) {
                item.changeState(callback)
              }
            }}

          ></Client>
        );
      } else if (item.type == 'businessUnit') {
        return (
          <BusinessUnit
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value, callback) =>
              this.onChange(item.name, value, item.asyncValidation, callback)
            }
            readOnly={item.readOnly}
            value={this.getValue(item.name)}
            label={item.label}
            showName={item.showName}
            placeholder={item.placeholder}
            values={item.values}
            dataModal={item.dataModal}
            title={item.title}
            disableValue={!this.props.pomocnaVarijabla && (item.label == 'Entitet' || item.label == 'Kanton')}
            metoda={item.metoda}
          ></BusinessUnit>
        )

      } else if (item.type == "tags") {
        return (
          <Tags
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            readOnly={item.readOnly ? item.readOnly : false}
          ></Tags>
        );
      } else if (item.type == "tagsSearch") {
        return (
          <TagsSearch
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            suggestions={item.suggestions}
            finishKey={FIN_KEY}
            name={item.name}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            // onFocus={() => {
            //   this.setState({ focusedField: item.name })
            // }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
            readOnly={item.readOnly ? item.readOnly : false}
          ></TagsSearch>
        );
      } else if (item.type == "textarea") {
        return (
          <Textarea
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Textarea>
        );

      } else if (item.type == "switch") {
        return (
          <Switch
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) => {
              if (item.changeOnParent) {
                item.onChange(item.name, value, item.asyncValidation)
              }
              this.onChange(item.name, value, item.asyncValidation)

              this.validateNonFocusField(item.name)
            }}
            value={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Switch>
        );
      } else if (item.type == "checkbox") {
        return (
          <Checkbox
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.asyncValidation);
            }}
            onChange={(value) => {
              let val = false;
              if (value && value.target && value.target.checked) {
                val = true
              } this.onChange(item.name, val)
            }}
            checked={this.getValue(item.name)}
            label={item.label}
            placeholder={item.placeholder}
          ></Checkbox>
        );
      } else if (item.type == "file") {
        return (
          <File
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            endpoint={item.endpoint}
            value={this.getValue(item.name)}
            label={item.label}
            className={item.className}
          ></File>
        );
      } else if (item.type == "image") {
        return (
          <Image
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            endpoint={item.endpoint}
            value={this.getValue(item.name)}
            label={item.label}
            className={item.className}
          ></Image>
        );
      } else if (item.type == "list") {
        return (
          <List
            key={idx}
            touched={this.state.touched[item.name]}
            error={this.state.errors[item.name]}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            focusNext={() => {
              if (item.next) this.focusNext(item.name, item.next, item.afterSubmitFocus);
            }}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            value={this.getValue(item.name)}
            validateApi={item.validateApi}
            label={item.label}
            placeholder={item.placeholder}
            height={item.height}
            textType={item.textType}
            bih={item.bih}
          ></List>
        );
      } else if (item.type == "button") {
        return (
          <Button
            key={idx}
            className={item.className}
            onClick={() => this.handleAction(item)}
            disabled={item.disabled}
          >
            {item.children}
          </Button>
        );
      } else if (item.type == 'radioButtons') {
        return (
          <RadioGroup
            key={idx}
            finishKey={FIN_KEY}
            setFieldRef={(node) => this.setFieldRef(item.name, node)}
            onChange={(value) =>
              this.onChange(item.name, value, item.asyncValidation)
            }
            items={item.items}
            value={this.getValue(item.name)}
            // onChange={(e)=>{item.onChange(e)}}
            selectedOption={item.selectedOption}
          // finishKey={FIN_KEY}
          // setFieldRef={(node) => this.setFieldRef(item.name, node)}
          ></RadioGroup>
        )

      } else if (item.type == "div") {
        return (
          item.children
        );
      } else if (item.type == "h4") {
        return (
          <h4 className={`form-builder-h4 ${item.className ? item.className : ''}`}>{item.text}</h4>
        );
      }
    });
  };


  render() {
    // console.log('Inicijalne vrijednosti.......', this.props.initialValues);
    return (
      <div>
        <div>
          <div className={`form`}>
            {this.renderFields(this.props.fields)}
          </div>
        </div>
      </div>
    );
  }
}

export default FormBuilder;
