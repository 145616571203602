import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'get-fund-prices': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/reports/return/total/articles', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        sellPrice: query && query.sellPrice,
                        purchasePrice: query && query.purchasePrice,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        articleFrom: query && query.articleFrom,
                        serialNumber: query && query.serialNumber,
                        changeType: query && query.changeType
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                        totalPriceIn: result.totalPriceIn, 
                        totalPriceOut: result.totalPriceOut, 
                        totalAmmountIn: result.totalAmmountIn, 
                        totalAmmountOut: result.totalAmmountOut
                    }
            })
        }
    },
    'print': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/return/total/articles/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        sellPrice: query && query.sellPrice,
                        purchasePrice: query && query.purchasePrice,
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        articleFrom: query && query.articleFrom,
                        serialNumber: query && query.serialNumber,
                        changeType: query && query.changeType
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        link: result.link,
                       
                    }
            })
        }
    },

}