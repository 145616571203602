
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';

import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import { validateDate } from '../../form-builder/validation';


class Funds extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            groupsList: true,
            groupsForm: false,
            total: 20,
            funds: [],
            items: [

            ]
        }
    }

    componentDidMount() {
        this._onLoad();
        //this._api('api2', {data: {name: 'demo collection'}}, (data) => {console.log(data)})
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>Fond XML</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        {/* <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button> */}
                    </div>
                </div>

                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('api3', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati XML <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="6">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    if (this.state.form && this.state.form._id) {

                                        this._api('api4', { data: data }, () => {
                                            this.setState({ form: undefined }, () => { this._onLoad(); })
                                        });
                                    } else {

                                        this._api('api2', { data: data }, () => {
                                            this.setState({ clientForm: {} }, () => { this._onLoad() })
                                        });
                                    }
                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: { lg: 12, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'block',
                                                        header: 'Ubaci XML'.translate(this.props.lang),
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'dropdown',
                                                                        name: 'fundId',
                                                                        next: 'validFrom',
                                                                        label: 'Fond',
                                                                        headerLabel: 'Fond',
                                                                        className: 'fond-dropdown',
                                                                        values: [{ name: 'Izaberite', value: -1 }, ...this.state.funds.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // (value) => { if (!value || value == '') return "Šifra mora biti unesena!" }
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'validFrom',
                                                                        next: 'xml',
                                                                        label: 'Datum od',
                                                                        finishField: true,
                                                                        readOnly: this.state.disableEdit,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => {
                                                                                if (!value || value == '') return "Datum od mora biti unesen!"

                                                                            },
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 12, sm: 12, xs: 12 },
                                                                children: [
                                                                    {
                                                                        type: 'file',
                                                                        name: 'xml',
                                                                        endpoint: '/data/funds-xml/upload',
                                                                        label: 'IZABERITE FAJL',
                                                                        finishField: true,
                                                                        validate: [
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },


                                ]}
                            ></FormBuilder>
                        </Col>
                        <Col lg="6">
                            {/*<button onClick={() => this.submit()}>dodaj</button>*/}
                            <div className="table-modifed ml-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    header={'Lista fondova'}
                                    hideResults={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'fundName', label: 'FOND'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'validFrom', label: 'DATUM OD'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ form: null }, () => this.setState({ form: item }))
                                                //onClick: (item) => console.log(item)
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(Funds);
