import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-crates': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/crates', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,
                    additionalFilters: {
                        client: query && query.client,
                        business: query && query.business,
                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },



}