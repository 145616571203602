import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

import { uuid } from 'uuidv4'

class Decimal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: uuid()
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.value && Number(this.props.value) != Number(this.state.value)) {
            this.setState({
                value: this.props.value
            })
        }

        if (!this.props.value && prevProps.value != this.props.value) {
            this.setState({
                value: ''
            })
        }

    }

    render() {
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')


        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName} input-type-text`}>
                <Label>{this.props.label}</Label>
                <input autoComplete={this.props.autoComplete ? this.props.autoComplete : "new-password"} maxLength={this.props.maxLength}
                    id={`input-type-text-${this.state.uid}`}
                    className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={this.props.textType ? this.props.textType : "text"}
                    ref={(node) => this.props.setFieldRef(node)}
                    onWheel={() => {
                        document.getElementById(`input-type-text-${this.state.uid}`).blur()
                    }}
                    onKeyDown={(e) => {
                        if (this.props.textType == 'number' && (e.key == 'ArrowDown' || e.key == 'ArrowUp')) {
                            e.preventDefault();
                        }
                    }}
                    onKeyUp={(e) => {

                        if (e.key == this.props.finishKey) {
                            this.props.focusNext();

                            if (this.props.onFinishKey) {
                                this.props.onFinishKey()
                            }
                        }

                    }}
                    onChange={(e) => {
                        e.preventDefault();

                        // console.log(e.target.value);
                        if (e.target.value && String(e.target.value).split('.').length > 2) {
                            return;
                        }
                        e.target.value = String(e.target.value).replace(/[^\d.]/g, '');

                        //e.target.value = Number(e.target.value);




                        this.setState({
                            value: e.target.value
                        })
                        // if(!this.props.disabled){
                        this.props.onChange(e.target.value ? Number(e.target.value) : e.target.value)
                        // }
                    }}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    disabled={this.props.readOnly}
                    value={this.state.value}
                    //value={!this.props.readOnly ? this.props.value ? this.props.value : '' : ''}
                    placeholder={this.props.placeholder}
                ></input>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default Decimal;
