
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';

import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class HomePage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 0,
            products: [],
            items: [],
            series: [],
        }
    }

    componentDidMount() {
        this._onLoad();

        if (this.props.uData) {
            let form = {
                _id: this.props.uData._id
            }
            if (this.props.uData.username) {
                form.username = this.props.uData.username;
            }
            if (this.props.uData.firstName) {
                form.firstName = this.props.uData.firstName;
            }
            if (this.props.uData.lastName) {
                form.lastName = this.props.uData.lastName;
            }
            if (this.props.uData.userLevelName) {
                form.userLevelName = this.props.uData.userLevelName;
            }
            this.setState({ form })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if(JSON.stringify(prevProps.uData) != JSON.stringify(this.props.uData) && this.props.uData){
            let form = {
                _id: this.props.uData._id
            }
            if (this.props.uData.username) {
                form.username = this.props.uData.username;
            }
            if (this.props.uData.firstName) {
                form.firstName = this.props.uData.firstName;
            }
            if (this.props.uData.lastName) {
                form.lastName = this.props.uData.lastName;
            }
            if (this.props.uData.userLevelName) {
                form.userLevelName = this.props.uData.userLevelName;
            }
            this.setState({ form })
        }
    }

    updateProfile = (data) => {
        let obj = { ...data }
        let error = false;

        if (obj.newPassword && obj.repeatPassword) {

            if (obj.newPassword != obj.repeatPassword) {
                this.setState({ error: 'Lozinke se ne poklapaju.' })
                error = true;
            } else {
                obj.password = String(obj.newPassword);
            }
        }

        if (obj.newPassword) {
            delete obj.newPassword;
        }
        if (obj.repeatPassword) {
            delete obj.repeatPassword;
        }
        if (obj.userLevelName) {
            delete obj.userLevelName;
        }
        if (obj.username) {
            delete obj.username;
        }

        if (!error) {
            this.setState({ form: null }, () => {
                this._apiAsync('update-profile', obj).then((res) => {
                    this.props.verifyUser()
                })
            })
        }

    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Profil</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.updateProfile(this.registerGetData())}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>

                    </div>
                </div>

                <Container fluid>
                    {/* <DeleteModal
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => {
                        this._api('api3', { data: this.state.deleteModal._id }, () => {
                            this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                        });
                    }} >
                    Obrisati tip artikla <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                </DeleteModal> */}
                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}
                        error={this.state.error}
                    />
                    <Row>
                        <Col lg="12">
                            {
                                this.state.form ?
                                    <FormBuilder
                                        registerSubmitAction={(submit) => this.submit = submit}
                                        registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                        registerGetData={(registerGetData) => this.registerGetData = registerGetData}
                                        classNameForm={"add-form-container"}
                                        // headerForm={"Artikal".translate(this.props.lang)}
                                        addButtonText={'Dodaj'.translate(this.props.lang)}
                                        fieldsToTrack={['code']}
                                        fieldTracker={async (name, value) => {
                                            // if (name == 'code' && value) {
                                            //     let res = await this._apiAsync('validate1', { value: value });

                                            //     if (res.status == 210) {
                                            //         this.setState({ form: null })
                                            //         this.remoteOnChange('name', '')
                                            //         this.remoteOnChange('maxProfit', '')
                                            //     }

                                            //     if (res.status == 200) {
                                            //         this.setState({ form: null }, () => {
                                            //             this.setState({ form: res.result })
                                            //         })
                                            //     }

                                            // }

                                            // if (name == 'code' && !value) {
                                            //     this.remoteOnChange('name', '')
                                            //     this.remoteOnChange('maxProfit', '')
                                            // }

                                        }}

                                        onSubmit={(data) => {

                                            // if (this.state.form && this.state.form._id) {

                                            //     this._api('api4', { data: data }, () => {
                                            //         this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            //     });
                                            // } else {
                                            //     if (data._id) {
                                            //         delete data._id
                                            //     }
                                            //     this._api('api2', { data: data }, () => {
                                            //         this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            //     });
                                            // }
                                        }}
                                        initialValues={this.state.form}
                                        fields={[
                                            {
                                                type: 'row',
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        children: [
                                                            {
                                                                type: 'block',
                                                                header: 'Dodaj vrstu artikla'.translate(this.props.lang),
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'username',
                                                                                next: 'firstName',
                                                                                label: 'Korisničko ime',
                                                                                // asyncValidation: true,
                                                                                validate: [],
                                                                                readOnly: true
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'userLevelName',
                                                                                // next: 'firstName',
                                                                                label: 'Korisnički nivo',
                                                                                // asyncValidation: true,
                                                                                validate: [],
                                                                                readOnly: true
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'firstName',
                                                                                next: 'lastName',
                                                                                label: 'Ime',
                                                                                // asyncValidation: true,
                                                                                validate: [],
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'lastName',
                                                                                next: 'oldPassword',
                                                                                label: 'Prezime',
                                                                                // asyncValidation: true,
                                                                                validate: [],
                                                                            },

                                                                        ]
                                                                    },

                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'newPassword',
                                                                                next: 'repeatPassword',
                                                                                label: 'Nova lozinka',
                                                                                textType: 'password',
                                                                                // asyncValidation: true,
                                                                                validate: [],
                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 6, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'text',
                                                                                name: 'repeatPassword',
                                                                                // next: 'repeatPassword',
                                                                                label: 'Ponovi novu lozinku',
                                                                                // asyncValidation: true,
                                                                                textType: 'password',
                                                                                validate: [],
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    }
                                                ]
                                            },


                                        ]}
                                    ></FormBuilder>
                                    :
                                    null
                            }
                        </Col>

                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(HomePage);
