import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { saveAs } from "file-saver";
import printJS from 'print-js'

export default {
    'get-clients': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/clients', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'get-business-units': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/business-units', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    // query: query.query,
                    clientCode: query.clientCode ? query.clientCode : null

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },

    'get-articles': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    filter: query.filter,
                    institutionType: query && query.institutionType ? query.institutionType : null,
                    withStock: true,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                    }
            })
        }
    },
    'update-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/online-demand/' + data._id, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },

    'remove-item': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/online-demand/remove/item/' + data._id + '/' + data.itemNumber, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result,
                    }
            })
        }
    },
    'remove-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/online-demand/remove/document/' + data._id, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                // body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        result: result,
                    }
            })
        }
    },

    'get-unbooked-online-demand': {
        onLoad: false,
        action: (lang, match, query) => {

            return fetch(env.API_URL + '/data/online-demand', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries ? query.entries : 10,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'finishedTs',
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    type: 'unprocessed',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        business: query && query.business,
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        unprocessedItems: result.items,
                        unprocessedTotal: result.total,
                    }
            })
        }
    },
    'get-booked-online-demand': {
        onLoad: true,
        action: (lang, match, query) => {

            return fetch(env.API_URL + '/data/online-demand', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: query && query.page,
                    entries: query && query.entries ? query.entries : 10,
                    filter: query && query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'finishedTs',
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    type: 'processed',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        business: query && query.business,
                    },

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        processedItems: result.items,
                        processedTotal: result.total,
                    }
            })
        }
    },
    'export-online-demand': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/online-demand', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'finishedTs',
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    type: query.type,
                    action: 'export',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        business: query && query.business,
                    },
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'print-online-demand': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/online-demand', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify({
                    page: null,
                    entries: null,
                    filter: query.filter,
                    sortType: query && query.sortType ? query.sortType : '-1',
                    sortField: query && query.sortField ? query.sortField : 'finishedTs',
                    selectedYear: typeof window != 'undefined' && localStorage.year ? localStorage.year : null,
                    type: query.type,
                    action: 'print',
                    additionalFilters: {
                        dateFrom: query && query.dateFrom,
                        dateTo: query && query.dateTo,
                        client: query && query.client,
                        article: query && query.article,
                        business: query && query.business,
                    },
                })
            }).then((response) => {
                if (response.ok) { // checks if the response is with status 200 (successful)
                    return response.blob().then(blob => {
                        let pdfFile = new Blob([blob], {
                            type: "application/pdf"
                        });
                        let pdfUrl = URL.createObjectURL(pdfFile);
                        // window.open(pdfUrl);
                        printJS(pdfUrl);
                    });
                }
            })

        }
    },
    'print-online-demand-document': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/online-demand/pdf', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let pdfFile = new Blob([blob], {
                                type: "application/pdf"
                            });
                            let pdfUrl = URL.createObjectURL(pdfFile);
                            // window.open(pdfUrl);
                            printJS(pdfUrl);
                        });
                    }
                })

        }
    },
    'process-online-demand': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/online-demand/process', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    _id: data._id

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },




    'get-series': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page: 0,
                    entries: 20,
                    productId: data.productId,
                    sortField: data.sortField,
                    sortType: data.sortType

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        oldestSeries: result.oldestSeries
                    }
            })
        }
    },
    'checkDocumentNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/online-demand/check/documentNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    // 'checkNextDocumentNumber' : {
    //     onLoad: false,
    //     action: (data) => {

    //         return fetch(env.API_URL + '/data/output/check/next/documentNumber', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //             body: JSON.stringify(data)
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     firstFreeNumber: result.firstFreeNumber,
    //                 }
    //         })
    //     }
    // },
    'checkNextItemNumber': {
        onLoad: false,
        action: (data) => {

            return fetch(env.API_URL + '/data/online-demand/check/next/itemNumber', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        firstFreeItemNumber: result.firstFreeNumber,
                    }
            })
        }
    },
    // 'check-product-discount': {
    //     onLoad: false,
    //     action: (data) => {

    //         return fetch(env.API_URL + '/data/products/check/discount', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //             body: JSON.stringify(data)
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     result: result,
    //                 }
    //         })
    //     }
    // },
    // 'printOutputDocument': {
    //     onLoad: false,
    //     action: (data) => {
    //         return fetch(env.API_URL + '/data/output/pdf', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //          },
    //             responseType: 'blob',
    //             body: JSON.stringify(data)

    //         })
    //             .then((response) => {
    //                 if (response.ok) { // checks if the response is with status 200 (successful)
    //                     return response.blob().then(blob => {
    //                         let pdfFile = new Blob([blob], {
    //                             type: "application/pdf"
    //                         });
    //                         let pdfUrl = URL.createObjectURL(pdfFile);
    //                        // window.open(pdfUrl);
    //                         printJS(pdfUrl);
    //                     });
    //                 }
    //             })

    //     }
    // },
    // 'generic-export':{
    //     onLoad: false,
    //     action: (request) => {
    //         return fetch(env.API_URL + '/data/generic/export', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },
    //             body: JSON.stringify( request)
    //         }).then(parseJSON).then(({ result }) => {
    //             return result;
    //         })
    //     }
    // },

    // 'checkDocumentNumberKO' : {
    //     onLoad: false,
    //     action: (data) => {
    //         return fetch(env.API_URL + '/data/output/checkKO/documentNumberKO', {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    //             },

    //             body: JSON.stringify(data)
    //         }).then(parseJSON).then(({ result, status }) => {
    //             if (status >= 200 && status < 300)
    //                 return {
    //                     result: result,
    //                 }
    //         })
    //     }
    // },
    'ariclesSearch':{
        onLoad : false,
        action : (data)=>{
            return fetch(env.API_URL + '/data/products/', {
                method : 'POST',
                headers : {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({
                    page : 0,
                    entries : 50,
                    filter : data.value,
                    withStock : true
                })
            }).then(parseJSON).then((response) =>{
                return {
                    articlesSearch : response.result.items
                }
            })
        }
    },


}