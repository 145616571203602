/**
* Validation
* @author   Milan Stanojevic
*/
export const maxLength = (max, message) => value =>
  value && value.length > max ? message : undefined
export const minLength = (min, message) => value =>
  value && value.length < min ? message : undefined
export const length = (len, message) => value =>
  value && value.length != len ? message : undefined
export const number = (message) => value =>
  value && isNaN(Number(value)) ? message : undefined
export const minValue = (min, message) => value =>
  value && value < min ? message : undefined
export const maxValue = (max, message) => value =>
  value && value > max ? message : undefined
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i.test(value) ?
    'Email adresa nije validna' : undefined
export const required = (message = 'Obavezno polje!') => {
  return (value) => value ? undefined : message;
}

export const validateDate = (message = 'Datum nije validan!') => {
  return (value) =>
    !value || (value && /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/.test(value)) ?
      undefined
      :
      message;
}



export const date = value =>
  value && !/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value) ?
    'Datum nije validna' : undefined