import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {

    'get-groups': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/groups', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        groups: result,
                        // total: result.total,
                    }
            })
        }
    },

    'get-subgroups': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/subgroups', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        subgroups: result,
                        // total: result.total,
                    }
            })
        }
    },

    'get-products': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/products', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body:JSON.stringify({})
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        products: result,
                        // total: result.total,
                    }
            })
        }
    },

    'get-series': {
        onLoad: true,
        action: () => {
            return fetch(env.API_URL + '/data/series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body:JSON.stringify({})
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        series: result,
                        // total: result.total,
                    }
            })
        }
    },

    'getData': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/series-report', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        // odgovor: result,
                        // total: result.total,
                        ...result
                    }
            })
        }
    },

    'getDataAll': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/series-reportAll', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then(parseJSON).then(({ result, status }) => {
               
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },

   
    'stampaApi': {
        onLoad: false,
        action: (query) => {
            return fetch(env.API_URL + '/data/reports/series-reportStampa', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(query)
            }).then((res) => res.blob()).then((response) => {
               
                return response
            })
        }
    },

}