
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import env from "react-dotenv";

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class ReportSupplierAndManufacturers extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            entranceItems: [],
            clientsSearch: [],
            manufacturers: [],
            loading: false
        }
    }

    componentDidMount() {
        this._onLoad();

    }

    stampa = () => {
        let params = this._getParams()
        // console.log('PARAAMETRI......', params);
        let title = "Promet po dobavljačima i komitentima"
        this._apiAsync('stampaApi', { title: title, ...this.state.form }).then((response) => {
            const file = new Blob([response], {
                type: "application/pdf"
            })

            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)

        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
            this._apiAsync('get-reports', { ...this.state.form, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }), }).then((response) => {
                this.setState({ entranceItems: response.entranceItems, total: response.total })
                // this.setState({ total: response.total })
            })
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        if (!value && this.registerGetValue && this.registerGetValue('clientCode')) {
            value = this.registerGetValue('clientCode');
        }
        clientList = await this._apiAsync('clientsSearch', { value: value })

        if (this.registerGetValue && this.registerGetValue('clientCode')) {
            if (this.registerGetValue('clientCode') == value) {
                this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
            }
        } else {
            this.setState({ clientsSearch: clientList.clientsSearch, clients: clientList.clientsSearch })
        }

    }

    print = () => {

        this.setState({loading: true})
        this._apiAsync('print', this.state.form).then((response) => {
            if (typeof window != 'undefined') {
                window.open(env.API_URL + response.link)
                this.setState({ loading: false })
            }
        })
        return "open-modal"
    }

    render() {
        let params = this._getParams();
        console.log(this.state.entranceItems)
        console.log(this.state.form)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet po dobavljaču i proizvođaču</h1>
                    <div className="title-options-group">
                    <button disabled={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? false : true} onClick={this.print}>
                            <div className={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? "option blue": "option disabled"}> 
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button disabled={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? false : true} onClick={this.export}>
                        <div className={this.state.form && this.state.form.dateFrom && this.state.form.dateTo ? "option blue": "option disabled"} > 
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article']}
                                fieldTracker={async (name, value) => {


                                }}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {
                                    this.setState({
                                        form: data
                                    })
                                    this._apiAsync('get-reports', data).then((response) => {
                                        console.log(response)
                                        this.setState({ entranceItems: response.entranceItems, total: response.total, totalAmount: response.totalAmount, discount: response.discount, totalPrice: response.totalPrice, loading: false })
                                        // this.setState({ total: response.total })
                                    })
                                    return "open-modal"

                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 10, sm: 10, xs: 10 },
                                                        className: "custom-input-row supplier-manufacturers-row",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'client',
                                                                                name: 'clientCode',
                                                                                next: 'dateFrom',
                                                                                label: 'Šifra',
                                                                                metoda: (value) => { this.metodaClient(value) },
                                                                                dataModal: this.state.clientsSearch,
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clients = await this._apiAsync('get-clients', { filter: value })
                                                                                                if (clients && clients.items) {
                                                                                                    this.setState({ clients: clients && clients.items }, () => {
                                                                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                                                                            console.log(this.state.clients, 'name');
                                                                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('clientName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                    // required('Dobavljač mora biti unešen!'),
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'dateFrom',
                                                                                label: 'Naziv komitenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: 'dateTo',
                                                                label: 'Datum od',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateTo',
                                                                next: 'manufacturer',
                                                                label: 'Datum do',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 4, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'manufacturer',
                                                                        next: 'percent',
                                                                        label: 'Proizvođač',
                                                                        asyncValidation: true,
                                                                        values: [{ name: 'Izaberite proizvođača', value: -1 }, ...this.state.manufacturers.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item._id
                                                                            }
                                                                            return obj;
                                                                        })],
                                                                        validate: [
                                                                            // required('Proizvođač mora biti unešen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        className: 'button-centered-fix',
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.setState({
                                                                                        loading: true
                                                                                    })
                                                                                    this.submit()
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>IZVJEŠTAJ</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div /*className="v-scroll mt-15"*/>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false} 
                                    hidePagination={false}
                                    fields={[
                                        { type: 'text', name: 'clientCode', label: 'ŠIFRA DOBAVLJAČA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'clientName', label: ' DOBAVLJAČ'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'manufacturerCode', label: 'ŠIFRA PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'manufacturerName', label: 'NAZIV PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'discountsPercent', label: 'PROCENAT'.translate(this.props.lang), multilang: false, allowSort: false },
                                        { type: 'text', name: 'factureValue', label: 'FAKTURNA CIJENA'.translate(this.props.lang), multilang: false, numberWithCommas: true, allowSort: false },
                                        { type: 'text', name: 'percentsValue', label: 'UMANJENJE'.translate(this.props.lang), multilang: false, numberWithCommas: true, allowSort: false },
                                        { type: 'text', name: 'totalAll', label: 'IZNOS'.translate(this.props.lang), multilang: false, numberWithCommas: true, allowSort: false },


                                    ]}
                                    items={this.state.entranceItems.map((item, idx) => {
                                        console.log(item)
                                        return {
                                            ...item,
                                            factureValue: item && item.factureValue && item.factureValue.toFixed ? item.factureValue.toFixed(2) : 0,
                                            totalAll: item && item.factureValue && item.percentsValue != 0 && item.percentsValue != NaN  && item.percentsValue != "NaN" ? (item.factureValue - item.percentsValue).toFixed(2) : item.factureValue && item.factureValue.toFixed ? item.factureValue.toFixed(2) : null

                                        }
                                    })}
                                    // actions={
                                    //     [

                                    //         {
                                    //             component: <Isvg src={garbageIcon} />,
                                    //             onClick: (item) => this.setState({ deleteModal: item })
                                    //         },
                                    //     ]
                                    // }

                                    // items={this.state.entranceItems}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                {<div className="col-info">
                                    <p>Iznos</p>
                                    <span>
                                        {
                                            this.state.totalPrice && this.state.totalPrice != 0 ?
                                                numberWithCommas(this.state.totalPrice)
                                                : '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>}
                                {<div className="col-info">
                                    <p>Umanjenje</p>
                                    <span>
                                        {
                                            this.state.discount && this.state.discount != 0 ?
                                                numberWithCommas(this.state.discount)
                                                : '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>}
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.totalAmount && this.state.totalAmount != 0 ?
                                                numberWithCommas(this.state.totalAmount)
                                                : '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(ReportSupplierAndManufacturers);


{/* <div className="info-colored">
                                <div className="col-info">
                                    <p>Osnovica</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>PDV</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Za plaćanje</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div> */}