import React, { Component, useEffect } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import precentIcon from '../../assets/precent.svg';
import lockIcon from '../../assets/lock.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import garbageIcon from '../../assets/garbage.svg';

import { number, required, validateDate } from '../../form-builder/validation';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import moment from "moment";
import { value } from "loadsh/seq";
import DeleteModal from "../../components/deleteModal";
import InfoModal from "../../components/infoModal";
import { has } from "loadsh/object";
import { cloneDeep } from "loadsh/lang";
import { FAKTURA, OTPREMNICA, PROKNJIZENA_OTPREMNICA, PAKOVANJE, STATUS_LABEL } from "../../helpers/constants";
import PrintHelper from "../../components/printHelper";
import sortUp from "../../assets/sortup.svg";
import sortDown from "../../assets/sortdown.svg";

import infoIcon from "../../assets/infoIcon.svg";

import Checkbox from "../../form-builder/fields/checkbox";
import env from "react-dotenv";

import DateField from '../../form-builder/fields/date';

import SelectWithSearch from '../../form-builder/fields/selectWithSearch';

import Loader from '../../components/loader';


function numberWithCommas(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}


const TAB_LISTA_TREBOVANJA = 1;
const TAB_U_PRIPREMI = 2;
const TAB_PREGLED = 3;

class OutputPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            total: 20,
            clientList: true,
            outputForm: null,
            clients: [],
            products: [],
            series: [],
            businessUnits: [],
            clientForm: false,
            buttonActive: null,
            readOnlyBusiness: true,
            items: [],
            outputItems: [],
            postedItems: [],
            item: { stock: null },
            stock: null,
            selectedClient: null,
            selectedProduct: null,
            disableEdit: true,
            discountModal: null,
            nivelationModal: null,
            deleteModal: null,
            deleteDocumentModal: null,
            postedModal: null,
            printHtml: null,
            exportModal: false,
            unprocessedItems: [],
            unprocessedTotal: 0,
            processedItems: [],
            processedTotal: 0,

            exportFields: {},
            exportType: {
                xls: false,
                pdf: false
            }
        }
    }







    reloadArticle = async (item) => {
        // const value = item && item.productCode ? item.productCode : null
        // if (value) {
        //     let requestArticle = { filter: value };
        //     if (this.state.selectedClient && this.state.selectedClient.institutionType) {
        //         requestArticle = { ...requestArticle, ...{ institutionType: this.state.selectedClient.institutionType } };
        //     }
        //     const products = await this._apiAsync('get-articles', requestArticle)
        //     if (products && products.items.length > 0) {
        //         await this.setState({ products: products && products.items })
        //     }
        //     const productObject = this.state.products.find(el => el.code === this.registerGetValue('item.productCode'))
        //     this.setState({ selectedProduct: productObject })
        //     const quantity = has(productObject, 'stock.quantity') ? productObject.stock.quantity : null;
        //     const stockPrice = has(productObject, 'stock.sellPrice') ? productObject.stock.sellPrice : null;
        //     const fundPrice = has(productObject, 'stock.fundPrice.price') ? productObject.stock.fundPrice.price : null;
        //     this.remoteOnChange('item.stock', quantity);
        //     this.remoteOnChange('item.stockPrice', stockPrice);
        //     this.remoteOnChange('item.foundPrice', fundPrice);
        // }
    }
    prepareRequest(data) {
        let newObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (key === 'items') {
                continue;
            }
            newObject[key] = value;
        }
        if (has(newObject, 'item')) {
            delete newObject.item.stock;
            delete newObject.item.stockPrice;
            delete newObject.item.fundPrice;
            delete newObject.item.foundPrice;
            if (this.state.selectedProduct) {
                if (this.state.selectedProduct.manufacturer) {
                    newObject.item.manufacturer = this.state.selectedProduct.manufacturer;
                }
                if (this.state.selectedProduct.barcode) {
                    newObject.item.barcode = this.state.selectedProduct.barcode;
                }
            }
        }
        return newObject;
    }

    insertOrUpdate = async (data, doNivelation = false) => {
        const request = await this.prepareRequest(data);

        if (request._id) {
            this._apiAsync('update-document', request).then((res) => {
                if (res && res.documentNumber) {
                    this.clearForm(() => {
                        this.openDocument(res.documentNumber)
                    })

                }
            })
        }
    }
    resetFilter = async () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',
            filterClient: '',
            filterUser: '',
            filterBusiness: '',
            filterType: '',
            filterDocument: ''
        });
    }
    changeTabNavigation = async (value) => {
        if (value != 3) {
            this.clearForm()
            this._onLoad()
        }

        this.clearFilters();

        this.setState({ buttonActive: value })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
        if (this.props[0].location && this.props[0].location.search && this.remoteOnChange) {
            let search = this.props[0].location.search;
            if (search.indexOf('redirect') != -1) {
                let split = search.split('=')

                let checkDocNumber = split[1];
                this.props[0].history.push({
                    search: ''
                })
                if (this.remoteOnChange) {
                    this.remoteOnChange('documentNumber', Number(checkDocNumber))
                }

            }

        }

        if (this.props.selectedYear != prevProps.selectedYear) {
            this._onLoad();
        }

        if (JSON.stringify(prevState.outputForm) != JSON.stringify(this.state.outputForm)) {
            if (this.state.outputForm && this.state.outputForm._id) {
                if (this.state.outputForm.processed) {
                    this.changeEditDisabledField(true)
                } else {
                    this.changeEditDisabledField(false)
                }
            } else {
                this.changeEditDisabledField(true)
            }

        }
    }
    changeEditDisabledField = (value) => {
        if (this.state.disableEdit != value) {
            this.setState({ disableEdit: value })
        }
    }

    componentDidMount() {





        this.setState({ buttonActive: TAB_U_PRIPREMI });

        this.clearFilters()

        this._onLoad();



    }

    clearForm = (callback) => {

        this.setState({
            outputForm: null,
            products: [],
            series: [],
            businessUnits: [],
            items: []
        }, () => {
            this.setState({ outputForm: {} }, () => {
                if (callback) {
                    callback()
                }
            })
        })
    }


    clearFilters = () => {
        this.setState({
            filterDateFrom: '',
            filterDateTo: '',
            filterClient: '',
            filterArticle: '',
            filterBusiness: '',
            businessUnits: [],
            clients: []

        }, () => {
            this.updateMultipleParams([
                { name: 'dateFrom', value: null },
                { name: 'dateTo', value: null },
                { name: 'client', value: null },
                { name: 'article', value: null },
                { name: 'business', value: null }
            ])
        })
    }

    openDocument = (documentNumber) => {
        if (documentNumber) {
            this.clearFilters()
            this.setState({ buttonActive: 3 }, () => {
                this.checkDocumentNumber(documentNumber)
            })
            // this.setState({ outputForm: null })
        }

    }
    checkDocumentNumber = (documentNumber) => {
        this._apiAsync('checkDocumentNumber', { documentNumber: documentNumber }).then((result) => {
            if (result && result._id) {
                this.setState({ outputForm: null }, () => {
                    this.setState({ outputForm: result }, () => {

                        if (result.items && result.items.length) {
                            this.setState({
                                items: result.items,
                            }, () => {
                                this._apiAsync('checkNextItemNumber', { documentNumber: documentNumber }).then((res) => {
                                    if (res && res.firstFreeItemNumber) {
                                        this.remoteOnChange('item.itemNumber', Number(res.firstFreeItemNumber))
                                    }
                                })
                            })
                        } else {
                            this.setState({
                                items: [],
                            }, () => {
                                this.remoteOnChange('item.itemNumber', 1)
                            })
                        }

                    })
                })
            } else if (this.state.prevDocumentNumber != documentNumber) {
                this.setState({
                    items: [],
                    outputForm: null
                }, () => {
                    this.setState({
                        outputForm: {
                            documentNumber: documentNumber
                        }
                    })
                })
            }

            this.setState({ prevDocumentNumber: documentNumber })
        })
    }

    processDocument = () => {
        this._apiAsync('process-online-demand', { _id: this.state.outputForm._id }).then((res) => {
            if (res && res._id) {
                this._onLoad()
                this.changeTabNavigation(TAB_LISTA_TREBOVANJA)
            }
        })
    }
    export = () => {
        let type = this.state.buttonActive == TAB_LISTA_TREBOVANJA ? 'processed' : this.state.buttonActive == TAB_U_PRIPREMI ? 'unprocessed' : null;
        if (type) {
            this.setState({ loadingPage: true }, () => {
                let params = this._getParams();
                this._apiAsync('export-online-demand', { ...params, type: type }).then((res) => {
                    this.setState({ loadingPage: false })
                    if (res && res) {
                        if (res.success) {
                            window.location.href = env.API_URL + `${res.file}`;
                        }
                    }
                })
            })
        }


    }
    print = async () => {
        let type = this.state.buttonActive == TAB_LISTA_TREBOVANJA ? 'processed' : this.state.buttonActive == TAB_U_PRIPREMI ? 'unprocessed' : null;
        if (type) {
            let params = this._getParams();
            this.setState({ loadingPage: true }, async () => {
                await this._apiAsync('print-online-demand', { ...params, type: type });
                this.setState({ loadingPage: false })
            })
        }
        if (this.state.buttonActive == TAB_PREGLED && this.state.outputForm && this.state.outputForm._id) {
            this.setState({ loadingPage: true }, async () => {
                await this._apiAsync('print-online-demand-document', { _id: this.state.outputForm._id });
                this.setState({ loadingPage: false })
            })
        }

    }

    metoda = async (value) => {
        let articleList = []
        articleList = await this._apiAsync('ariclesSearch', { value: value })
        this.setState({ articlesSearch: articleList.articlesSearch })
    }


    render() {
        let params = this._getParams();
        return (
            <div>
                <Loader loading={this.state.loadingPage} />
                {
                    this.state.printHtml ?
                        <PrintHelper html={this.state.printHtml} />
                        :
                        null
                }
                <div className="section-title title-options">
                    {
                        this.state.buttonActive == TAB_LISTA_TREBOVANJA ?
                            <h1>Lista online trebovanja</h1>
                            :
                            this.state.buttonActive == TAB_U_PRIPREMI ?
                                <h1>Lista online trebovanja</h1>
                                :
                                this.state.buttonActive == TAB_PREGLED ?
                                    <h1>Online trebovanje</h1>
                                    :
                                    null
                    }



                    {
                        this.state.buttonActive == TAB_LISTA_TREBOVANJA || this.state.buttonActive == TAB_U_PRIPREMI ?
                            <div className="title-options-group">
                                <button onClick={() => this.print()}>
                                    <div className="option blue">
                                        <Isvg src={printIcon} />
                                    </div>
                                    <span>Štampaj</span>
                                </button>
                                <button onClick={() => {
                                    this.export()

                                }}>
                                    <div className="option blue">
                                        <Isvg src={exportIcon} />
                                    </div>
                                    <span>Export</span>
                                </button>
                            </div>
                            :
                            null
                    }
                </div>

                <div className="table-tabs">
                    <div className="table-tabs-small">
                        <button className={this.state.buttonActive == TAB_LISTA_TREBOVANJA ? 'active' : ''}
                            onClick={() => this.changeTabNavigation(TAB_LISTA_TREBOVANJA)}>
                            Lista online trebovanja
                        </button>
                        <button className={this.state.buttonActive == TAB_U_PRIPREMI ? 'active' : ''}
                            onClick={() => this.changeTabNavigation(TAB_U_PRIPREMI)}>
                            U pripremi
                        </button>
                        <button className={this.state.buttonActive == TAB_PREGLED ? 'active' : ''}
                            onClick={() => this.changeTabNavigation(TAB_PREGLED)}>
                            Pregled online trebovanja
                        </button>
                    </div>
                    {this.state.outputForm && this.state.outputForm._id && this.state.outputForm.processed && this.state.outputForm.processedTs ?
                        <div className="status">
                            <p>Proknjiženo <span>({this.props.getDateStringFromTs(this.state.outputForm.processedTs, 'DD.MM.YYYY HH:mm')}h)</span></p>
                        </div>
                        : null
                    }

                </div>
                {
                    this.state.buttonActive == TAB_PREGLED ?
                        <>

                            <Container fluid>
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                    registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                    registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                    classNameForm={"add-form-container entrance-custom-col"}
                                    addButtonText={'Dodaj'.translate(this.props.lang)}
                                    fieldsToTrack={['documentNumber', 'item.productCode', 'item.serialNumber', 'item.ammount', 'item.price', 'item.discount', 'clientCode', 'business']}
                                    fieldTracker={async (name, value) => {
                                        if (name === 'documentNumber' && !value) {
                                            this.setState({ items: [], totalItems: 0, outputForm: null }, () => {
                                                this.setState({ outputForm: {} })
                                            })
                                        }
                                        if (name === 'documentNumber' && value) {
                                            this.checkDocumentNumber(value)
                                        }
                                        // if (name === 'item.serialNumber' && value) {
                                        //     const seriesObject = this.state.series.find(el => el.serialNumber === value)
                                        //     if (seriesObject && seriesObject.expirationDate) {
                                        //         this.remoteOnChange('item.deadline', seriesObject.expirationDate)
                                        //     }
                                        // }
                                        if (name === 'item.productCode' && value) {
                                            let requestArticle = { filter: value };
                                            if (this.state.selectedClient && this.state.selectedClient.institutionType) {
                                                requestArticle = { ...requestArticle, ...{ institutionType: this.state.selectedClient.institutionType } };
                                            }
                                            let products = await this._apiAsync('get-articles', requestArticle)
                                            if (products && products.items.length > 0) {
                                                await this.setState({ products: products && products.items })
                                            }

                                            if (this.state.products.filter(el => el.code === this.registerGetValue('item.productCode')).length) {
                                                const productObject = this.state.products.find(el => el.code === this.registerGetValue('item.productCode'))
                                                this.setState({ selectedProduct: productObject });
                                                const quantity = productObject.stock && productObject.stock.quantity ? productObject.stock.quantity : 0;
                                                const price = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                const stockPrice = productObject.stock && productObject.stock.sellPrice ? productObject.stock.sellPrice : null;
                                                const fundPrice = has(productObject, 'stock.fundPrice.price') ? productObject.stock.fundPrice.price : null;

                                                this.remoteOnChange('item.stock', quantity);
                                                this.remoteOnChange('item.price', fundPrice ? fundPrice : price);
                                                this.remoteOnChange('item.stockPrice', stockPrice);
                                                this.remoteOnChange('item.fundPrice', fundPrice);


                                                const result = await this._apiAsync('get-series', {
                                                    productId: productObject._id,
                                                    sortField: 'expirationDateTs',
                                                    sortType: 1
                                                })
                                                if (result && result.items.length > 0) {
                                                    await this.setState({ series: result.items })
                                                    if (result.oldestSeries && result.oldestSeries.serialNumber)
                                                        this.remoteOnChange('item.serialNumber', result.oldestSeries.serialNumber)
                                                    if (result.oldestSeries && result.oldestSeries.expirationDate)
                                                        this.remoteOnChange('item.deadline', result.oldestSeries.expirationDate)
                                                } else {
                                                    await this.setState({ series: [] })
                                                    this.remoteOnChange('item.serialNumber', null)
                                                    this.remoteOnChange('item.deadline', '')
                                                }
                                            } else {
                                                this.remoteOnChange('item.stock', null);
                                                this.remoteOnChange('item.serialNumber', null)
                                                this.remoteOnChange('item.deadline', '')
                                            }
                                        }
                                        // if (name === 'item.price' && value) {
                                        //     let total = this.registerGetValue('item.ammount') && value ? this.registerGetValue('item.ammount') * value : null
                                        //     const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                        //     this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        // }
                                        if (name === 'item.ammount' && value) {
                                            let total = this.registerGetValue('item.price') && value ? this.registerGetValue('item.price') * value : null
                                            // const discountValue = total && this.registerGetValue('item.discount') ? Number(total) / 100 * this.registerGetValue('item.discount') : 0;
                                            this.remoteOnChange('item.sum', this.props.roundToTwoDecimals(total))
                                        } else if (name === 'item.ammount' && !value) {
                                            this.remoteOnChange('item.sum', 0)
                                        }
                                        // if (name === 'item.discount' && value) {
                                        //     let total = this.registerGetValue('item.price') && this.registerGetValue('item.ammount') ? this.registerGetValue('item.price') * this.registerGetValue('item.ammount') : null;
                                        //     const discountValue = total && value ? Number(total) / 100 * value : 0;
                                        //     this.remoteOnChange('item.total', this.props.roundToTwoDecimals(total - discountValue))
                                        // }
                                        // if (name === 'item.discount' && value) {
                                        //     if (this.state.selectedClient && this.state.selectedProduct) {
                                        //         await this._apiAsync('check-product-discount', {
                                        //             productId: this.state.selectedProduct._id,
                                        //             clientId: this.state.selectedClient._id
                                        //         }).then((response) => {
                                        //             if (response && response.result && response.result.percent && Number(value) > Number(response.result.percent)) {
                                        //                 this.setState({ discountModal: response.result });
                                        //             }
                                        //         })
                                        //     }
                                        // }

                                    }
                                    }
                                    onSubmit={(data) => {
                                        // data.status = OTPREMNICA;
                                        // if (data && data.item && data.item.price && data.item.stockPrice && Number(data.item.price) !== Number(data.item.stockPrice)) {
                                        //     this.setState({ nivelationModal: data })
                                        //     return 'open-modal';
                                        // } else {
                                        //     this.insertOrUpdate(data);

                                        // }
                                        this.insertOrUpdate(data);


                                    }}
                                    initialValues={this.state.outputForm}
                                    fields={[
                                        {
                                            type: 'block',
                                            children: [
                                                {
                                                    type: 'col',
                                                    className: 'col-up',
                                                    width: { lg: 5, sm: 5, xs: 5 },
                                                    children: []
                                                },
                                                {
                                                    type: 'col',
                                                    className: 'col-up col-up-right-top col-up-right-top-max flex-end',
                                                    width: { lg: 7, sm: 7, xs: 7 },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-toggle-custom list-toggle-custom-expand',
                                                                    width: { lg: 8, sm: 5, xs: 5 },
                                                                    children: [
                                                                        this.state.outputForm && this.state.outputForm._id && !this.state.outputForm.processed ?
                                                                            {
                                                                                type: 'button',
                                                                                children: <span>Proknjiži</span>,
                                                                                action: 'custom',
                                                                                className: 'posting-button',
                                                                                onClick: () => {

                                                                                    this.setState({ processDocumentModal: true })
                                                                                }
                                                                            }
                                                                            :
                                                                            {},
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    className: 'list-buttons-custom-sm',
                                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                                    children: [
                                                                        {
                                                                            type: 'row',
                                                                            className: 'option-header',
                                                                            children: [
                                                                                {
                                                                                    type: 'block',
                                                                                    children: [

                                                                                        {
                                                                                            type: 'button',
                                                                                            disabled: this.state.outputForm && this.state.outputForm._id ? false : true,
                                                                                            width: {
                                                                                                lg: 4,
                                                                                                sm: 6,
                                                                                                xs: 6
                                                                                            },
                                                                                            children: <div className="option-buttons-container">
                                                                                                <div className="option blue">
                                                                                                    <Isvg src={printIcon} />
                                                                                                </div>
                                                                                                <span>Štampaj</span>
                                                                                            </div>,
                                                                                            className: 'options-button',
                                                                                            action: 'custom',
                                                                                            onClick: () => {
                                                                                                this.setState({ printHtml: null }, this.print)
                                                                                                return 'open-modal';
                                                                                            }
                                                                                        },
                                                                                        // {
                                                                                        //     type: 'button',
                                                                                        //     width: {
                                                                                        //         lg: 4,
                                                                                        //         sm: 6,
                                                                                        //         xs: 6
                                                                                        //     },
                                                                                        //     children: <div
                                                                                        //         className="option-buttons-container">
                                                                                        //         <div
                                                                                        //             className="option blue">
                                                                                        //             <Isvg
                                                                                        //                 src={printIcon} />
                                                                                        //         </div>
                                                                                        //         <span>Export</span>
                                                                                        //     </div>,
                                                                                        //     className: 'options-button'
                                                                                        // },
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-4",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            // textType: 'number',
                                                            name: 'documentNumber',
                                                            next: 'factureDate',
                                                            label: 'Broj dok.',
                                                            integer: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                required("Broj dokumenta mora biti unešen!"),
                                                            ]
                                                        },

                                                        {
                                                            type: 'date',
                                                            name: 'finishedTsConverted',
                                                            next: 'currency',
                                                            label: 'Datum trebovanja',
                                                            finishField: true,
                                                            readOnly: true,
                                                            asyncValidation: true,
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Datum ulaza mora biti unesen!"
                                                                },
                                                                validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'date',
                                                            name: 'deliveryDate',
                                                            next: 'item.productCode',
                                                            label: 'Datum isporuke',
                                                            finishField: true,
                                                            readOnly: true/*this.state.disableEdit*/,
                                                            asyncValidation: true,
                                                            validate: [
                                                                // (value) => {
                                                                //     if (!value || value == '') return "Datum ulaza mora biti unesen!"
                                                                // },
                                                                // validateDate('Datum nije validan')
                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [

                                                                        {
                                                                            type: 'text',
                                                                            name: 'userData.clientCode',
                                                                            next: 'clientName',
                                                                            label: 'Šifra kom.',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'userData.clientName',
                                                                            next: 'ordinalNumber',
                                                                            label: 'Naziv komitenta',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    className: 'input-group-custom-2',
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'userData.businessUnitCode',
                                                                            next: 'jci',
                                                                            label: 'Poslovna',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'userData.businessUnitName',
                                                                            next: 'jci',
                                                                            label: 'Naziv poslovne jedinice',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        {
                                                            type: 'block',
                                                            style: {
                                                                background: '#F8F9FA',

                                                                width: '18%',
                                                                height: '110%',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomRightRadius: 0,
                                                                boxShadow: 'none',
                                                                margin: 0,
                                                                position: 'absolute',
                                                                top: -25,
                                                                right: -15
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'div',
                                                                    children: <div className="note-limits-info-div">
                                                                        <div className="limit-info-item">
                                                                            <h6>NAPOMENA</h6>
                                                                            <p>{this.state.outputForm && this.state.outputForm.note ? this.state.outputForm.note : 'Nema napomene'}</p>
                                                                        </div>

                                                                    </div>,
                                                                    label: 'LIMIT U VALUTI',
                                                                }

                                                            ]
                                                        }


                                                    ]

                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    className: "custom-input-width-3",
                                                    children: [
                                                        {
                                                            type: 'text',
                                                            name: 'item.itemNumber',
                                                            next: 'item.productCode',
                                                            label: 'R. br.',
                                                            readOnly: true,
                                                            finishField: true,
                                                            asyncValidation: true,
                                                        },
                                                        {
                                                            type: 'article',
                                                            name: 'item.productCode',
                                                            next: 'item.serialNumber',
                                                            label: 'Artikal',
                                                            metoda: (value) => { this.metoda(value) },
                                                            dataModal: this.state.articlesSearch,
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            values: [...this.state.products.map(item => {
                                                                let obj = {
                                                                    name: item.name,
                                                                    value: item.code,
                                                                    disabled: item.disabled ? true : false,
                                                                }
                                                                return obj
                                                            })],
                                                            validate: [
                                                                (value) => {
                                                                    if (!value || value == '') return "Artikal mora biti unesen!"
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'selectWithSearch',
                                                            name: 'item.serialNumber',
                                                            next: 'item.deadline',
                                                            label: 'Serijski broj',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,
                                                            values: [...this.state.series.map(item => {
                                                                let obj = {
                                                                    name: item.serialNumber,
                                                                    value: item.serialNumber,
                                                                }
                                                                return obj
                                                            })],
                                                        },

                                                        {
                                                            type: 'text',
                                                            name: 'item.deadline',
                                                            next: 'item.ammount',
                                                            label: 'Rok',
                                                            finishField: true,
                                                            readOnly: this.state.disableEdit,
                                                            asyncValidation: true,

                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.ammount',
                                                                            next: 'submitForm',
                                                                            label: 'Količina',
                                                                            finishField: true,
                                                                            readOnly: this.state.disableEdit,
                                                                            asyncValidation: true,
                                                                            integer: true,
                                                                            validate: [
                                                                                (value) => {
                                                                                    if (!value || value == '') return "Količina mora biti unesena!";
                                                                                    if (value > this.registerGetValue('item.stock')) {
                                                                                        return "Količina mora biti manja od stanja zaliha";
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.stock',
                                                                            next: 'item.discount',
                                                                            label: 'Zaliha',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                            asyncValidation: false,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    className: 'input-group-custom-2',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'item.price',
                                                                            next: 'item.discount',
                                                                            label: 'Cijena',
                                                                            finishField: true,
                                                                            readOnly: true,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // (value) => {
                                                                                //     if (!value || value == '') return "Cijena mora biti unesena!"
                                                                                // }
                                                                            ]
                                                                        },

                                                                        {
                                                                            type: 'text',
                                                                            name: 'submitForm',
                                                                            label: 'Fond cijena',
                                                                            readOnly: true,
                                                                            finishField: true,
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },
                                                        // {
                                                        //     type: 'text',
                                                        //     name: 'item.discount',
                                                        //     label: 'Rabat',
                                                        //     readOnly: this.state.disableEdit,
                                                        //     next: 'submitForm',
                                                        //     finishField: false,

                                                        // },

                                                        {
                                                            type: 'text',
                                                            name: 'item.sum',
                                                            label: 'Ukupno',
                                                            finishField: true,
                                                            readOnly: true,
                                                        },

                                                    ]


                                                },
                                            ]
                                        },
                                    ]}
                                />
                            </Container>

                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    maxHeight={'50vh'}
                                    fields={[
                                        {
                                            type: 'text',
                                            name: 'itemNumber',
                                            label: 'BROJ'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'productCode',
                                            label: 'ŠIFRA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'productName',
                                            label: 'NAZIV ARTIKLA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'serialNumber',
                                            label: 'SERIJA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'deadline',
                                            label: 'ROK'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'ammount',
                                            label: 'KOLIČINA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset'
                                        },
                                        {
                                            type: 'text',
                                            name: 'price',
                                            label: 'CIJENA'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset',
                                            numberWithCommas: true
                                        },
                                        {
                                            type: 'text',
                                            name: 'sum',
                                            label: 'UKUPNO'.translate(this.props.lang),
                                            multilang: false,
                                            allowSort: true,
                                            width: 'unset',
                                            numberWithCommas: true
                                        },

                                    ]}
                                    actions={
                                        this.state.disableEdit == false ?
                                            [
                                                {
                                                    component: <Isvg src={editIcon} />,
                                                    onClick: (item) => {
                                                        let outputForm = this.state.outputForm;
                                                        outputForm.item = item;
                                                        this.setState({ outputForm: null }, () => {
                                                            this.setState({ outputForm })
                                                        })

                                                        this.reloadArticle(item)
                                                    }
                                                },
                                                {
                                                    component: <Isvg src={garbageIcon} />,
                                                    onClick: (item) => this.setState({ deleteModal: item })
                                                }
                                            ]
                                            : []
                                    }

                                    items={this.state.items}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-item', {
                                            _id: this.state.outputForm._id,
                                            itemNumber: this.state.deleteModal.itemNumber
                                        }).then((res) => {
                                            if (res && res.documentNumber) {
                                                this.setState({ deleteModal: null })
                                                this.clearForm(() => {
                                                    this.openDocument(res.documentNumber)
                                                })

                                            }
                                        })
                                    }}>
                                    Obrisati stavku <strong>{this.state.deleteModal ? this.state.deleteModal.itemNumber : ''}</strong> ?
                                </DeleteModal>
                                <DeleteModal
                                    isOpen={this.state.deleteDocumentModal}
                                    toggle={() => this.setState({ deleteDocumentModal: null })}
                                    handler={() => {

                                        this._apiAsync('remove-document', { _id: this.state.deleteDocumentModal._id }).then((res) => {
                                            this.setState({
                                                deleteDocumentModal: null,
                                                outputForm: null,
                                                items: null,
                                                firstFreeNumber: null
                                            }, () => {
                                                this.setState({ outputForm: {} }, () => this._onLoad())
                                                // this._onLoad()
                                            })

                                        })
                                    }}>
                                    Da li želite obrisati dokument <strong>{this.state.deleteDocumentModal ? this.state.deleteDocumentModal.documentNumber : ''}</strong> ?
                                </DeleteModal>
                                <InfoModal
                                    isOpen={this.state.nivelationModal}
                                    toggle={() => this.setState({ nivelationModal: null })}
                                    title={'Obavještenje'}
                                    info={'Došlo je do promjene cijene i izvršiće se nivelacija. Da li želite da uradite NIVELACIJU KALKULACIJE ili NIVELACIJU LAGERA'}
                                    buttons={[
                                        {
                                            text: 'NIVELACIJA KALKULACIJE',
                                            onClick: () => {
                                                let outputForm = this.state.nivelationModal;
                                                if (outputForm && outputForm.item && outputForm.item.stockPrice) {
                                                    outputForm.item.price = outputForm.item.stockPrice;
                                                }
                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(outputForm))
                                            }
                                        },
                                        {
                                            text: 'NIVELACIJA LAGERA',
                                            onClick: () => {
                                                let outputForm = this.state.nivelationModal;
                                                this.setState({ nivelationModal: null }, () => this.insertOrUpdate(outputForm, true))
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                                <InfoModal
                                    isOpen={this.state.postedModal}
                                    toggle={() => this.setState({ postedModal: null })}
                                    title={'Obavještenje'}
                                    info={'Da li ste sigurni da želite proknjižiti ovaj dokument?'}
                                    buttons={[
                                        {
                                            text: 'DA',
                                            onClick: () => {
                                                const requestPosted = {
                                                    _id: this.state.postedModal._id,
                                                    status: PROKNJIZENA_OTPREMNICA
                                                };
                                                this.setState({ postedModal: null }, () => this.insertOrUpdate(requestPosted))
                                            }
                                        },
                                        {
                                            text: 'NE',
                                            onClick: () => {
                                                this.setState({ postedModal: null })
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                                <InfoModal
                                    isOpen={this.state.discountModal}
                                    toggle={() => this.setState({ discountModal: null })}
                                    title={'Obavještenje'}
                                    info={'Unijeli ste veći rabat nego na uslovima. Da li želite da nastavite ?'}
                                    buttons={[
                                        {
                                            text: 'DA',
                                            onClick: () => {

                                                this.setState({ discountModal: null })
                                            }
                                        },
                                        {
                                            text: 'NE',
                                            onClick: () => {
                                                this.remoteOnChange('item.discount', null)
                                                this.setState({ discountModal: null });
                                            }
                                        }
                                    ]}
                                >
                                </InfoModal>
                            </div>

                            <div className="footer">
                                <div className="footer-left">

                                    {this.state.outputForm && this.state.outputForm._id ?
                                        <button
                                            onClick={() => this.setState({ deleteDocumentModal: this.state.outputForm })}>
                                            <div className="box box-red">
                                                <Isvg src={garbageOpionIcon} />
                                            </div>
                                            <p>BRISANJE DOKUMENTA</p>
                                        </button>
                                        : null
                                    }
                                    {/* <button className="gray-button" style={{ marginLeft: 20 }}
                                        onClick={() => {
                                            if (this.state.outputForm) {
                                                //TODO need implement generic, then call insert posted
                                            }
                                        }}
                                    >
                                        <div className="box box-blue">
                                            <Isvg src={editIcon} />
                                        </div>
                                        <p>Rabat na fakture</p>
                                    </button> */}

                                    {/* <div className="modified-div-wrap">
                                        <h6>Poslednja izmjena:</h6>
                                        <p>Tanja Novak</p>
                                        <p>24.08.2021. - 14:28h</p>
                                    </div>
                                    <div className="discount-wrap">
                                        <div>
                                            <h6>K.O.</h6>
                                            <p>43,00 KM</p>
                                        </div>
                                        <div>
                                            <h6>RABAT</h6>
                                            <p>45,00 KM</p>
                                        </div>
                                    </div> */}

                                </div>
                                <div className="footer-right">
                                    {/*
                            <div className="info-transparent">
                                <div className="col-info">
                                    <p>Vrijednost</p>
                                    <span>543,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    <span>45,00 KM</span>
                                </div>
                                <div className="col-info">
                                    <p>RUC</p>
                                    <span>45,00 KM</span>
                                </div>
                            </div>
                            */}
                                    <div className="info-colored">
                                        <div className="col-info">
                                            <p>Osnovica</p>
                                            <span>{this.state.outputForm && this.state.outputForm.sum ? (numberWithCommas(this.state.outputForm.sum)) : 0} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>PDV</p>
                                            <span>{this.state.outputForm && this.state.outputForm.sum ? (numberWithCommas(this.state.outputForm.sum * 0.17)) : 0} KM</span>
                                        </div>
                                        <div className="col-info">
                                            <p>Za plaćanje</p>
                                            <span>{this.state.outputForm && this.state.outputForm.sum ? (numberWithCommas(this.state.outputForm.sum * 1.17)) : 0} KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        this.state.buttonActive === TAB_LISTA_TREBOVANJA ?
                            <>
                                <div className="list-builder-filters-wrap online-demand-filters">
                                    <div className="list-builder-filters" style={{ alignItems: 'flex-end', marginTop: -25 }}>
                                        <div className="field-strap-wrap" style={{ width: 100 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-100'}
                                                onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                    this.updateParams('dateFrom', this.state.filterDateFrom)
                                                })}
                                                value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                placeholder={'Datum od'}
                                            ></DateField>
                                        </div>


                                        <div className="field-strap-wrap" style={{ width: 100 }}>
                                            <DateField
                                                additionalClassName={'date-input-width-100'}
                                                onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                    this.updateParams('dateTo', this.state.filterDateTo)
                                                })}
                                                value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo}
                                                placeholder={'Datum do'}
                                            ></DateField>
                                        </div>

                                        <div className="field-strap-wrap">

                                            <SelectWithSearch placeholder={'Komitent'}
                                                value={this.state.filterClient !== null ? this.state.filterClient : params.client}
                                                showName={true}
                                                finishKey={'Enter'}
                                                onChange={(e) => this.setState({ filterClient: e }, async () => {
                                                    let clients = await this._apiAsync('get-clients', { filter: this.state.filterClient })
                                                    if (clients && clients.items) {
                                                        this.setState({ clients: clients && clients.items }, () => {
                                                            if (this.state.clients.filter(item => item.code == this.state.filterClient).length) {
                                                                this.updateParams('client', this.state.filterClient)
                                                            } else {
                                                                this.updateParams('client', null)
                                                            }
                                                        })
                                                    } else {
                                                        this.updateParams('client', null)
                                                    }
                                                })}
                                                values={[...this.state.clients.map(item => {
                                                    let obj = {
                                                        name: item.name,
                                                        value: item.code
                                                    }
                                                    return obj
                                                })]}
                                                additionalClassName={'width-100-percent'}

                                            />

                                        </div>
                                        <div className="field-strap-wrap">

                                            <SelectWithSearch placeholder={'Poslovna'}
                                                value={this.state.filterBusiness !== null ? this.state.filterBusiness : params.business}
                                                showName={true}
                                                readOnly={params.client && this.state.clients && this.state.clients.filter(item => item.code == this.state.filterClient)[0] && this.state.clients.filter(item => item.code == this.state.filterClient)[0].units && this.state.clients.filter(item => item.code == this.state.filterClient)[0].units.length ? false : true}
                                                finishKey={'Enter'}
                                                onChange={(e) => this.setState({ filterBusiness: e }, async () => {
                                                    let businessUnits = await this._apiAsync('get-business-units', { clientCode: Number(params.client), filter: this.state.filterBusiness })
                                                    if (businessUnits && businessUnits.items) {
                                                        this.setState({ businessUnits: businessUnits.items }, () => {
                                                            if (this.state.businessUnits.filter(item => item.code == this.state.filterBusiness).length) {
                                                                this.updateParams('business', this.state.filterBusiness)
                                                            } else {
                                                                this.updateParams('business', null)
                                                            }
                                                        })
                                                    } else {
                                                        this.updateParams('business', null)
                                                    }
                                                })}
                                                values={[...this.state.businessUnits.map(item => {
                                                    let obj = {
                                                        name: item.name,
                                                        value: item.code
                                                    }
                                                    return obj
                                                })]}
                                                additionalClassName={'width-100-percent'}

                                            />
                                        </div>
                                        <div className="field-strap-wrap" style={{ width: 200 }}>
                                            <Input style={{ width: 200 }} type='text'
                                                value={this.state.filterArticle !== null ? this.state.filterArticle : params.article}
                                                onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                    this.updateParams('article', this.state.filterArticle)
                                                })} placeholder={'Artikal'} />
                                        </div>

                                    </div>
                                    <div className="clear-filters" onClick={() => {
                                        this.clearFilters()
                                    }}>
                                        <span>&times;</span> Ukloni filter
                                    </div>

                                </div>
                                <div className="mt-15">
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        maxHeight={'63vh'}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.processedTotal}
                                        showNumeration={false}
                                        fields={[
                                            { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'outputDocumentNumber', label: 'BR. IZL. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'finishedTs', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                            { type: 'text', name: 'userData.clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'userData.businessUnitCode', label: 'POSLOVNA JEDINICA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                            { type: 'text', name: 'note', label: 'NAPOMENA'.translate(this.props.lang), multilang: false, allowSort: true, substr: 25, width: 'unset' },
                                            { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },

                                        ]}
                                        items={this.state.processedItems.map((item, idx) => {
                                            return {
                                                ...item,
                                                ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                                time: item.finishedTs ? this.props.getDateStringFromTs(item.finishedTs, 'HH:mm') : '',
                                                finishedTs: item.finishedTs ? this.props.getDateStringFromTs(item.finishedTs) : '',
                                                outputDocumentNumber:
                                                    item.outputDocumentNumber ?
                                                        <div className='list-builder-item-href' onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (typeof window != 'undefined') {
                                                                window.open(`/output?redirect=${item.outputDocumentNumber}`, "_blank");
                                                            }
                                                        }}>
                                                            <p> {item.outputDocumentNumber}</p>
                                                        </div>

                                                        :
                                                        null
                                            }
                                        })}
                                        rawItems={this.state.processedItems}

                                        onClick={(item) => {
                                            this.openDocument(item.documentNumber)
                                        }}

                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>
                            </>
                            :
                            this.state.buttonActive === TAB_U_PRIPREMI ?
                                <>
                                    <div className="list-builder-filters-wrap online-demand-filters">
                                        <div className="list-builder-filters" style={{ alignItems: 'flex-end', marginTop: -25 }}>
                                            <div className="field-strap-wrap" style={{ width: 100 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-100'}
                                                    onChange={(value) => this.setState({ filterDateFrom: value }, () => {
                                                        this.updateParams('dateFrom', this.state.filterDateFrom)
                                                    })}
                                                    value={this.state.filterDateFrom !== null ? this.state.filterDateFrom : params.dateFrom}
                                                    placeholder={'Datum od'}
                                                ></DateField>
                                            </div>


                                            <div className="field-strap-wrap" style={{ width: 100 }}>
                                                <DateField
                                                    additionalClassName={'date-input-width-100'}
                                                    onChange={(value) => this.setState({ filterDateTo: value }, () => {
                                                        this.updateParams('dateTo', this.state.filterDateTo)
                                                    })}
                                                    value={this.state.filterDateTo != null ? this.state.filterDateTo : params.dateTo}
                                                    placeholder={'Datum do'}
                                                ></DateField>
                                            </div>

                                            <div className="field-strap-wrap">

                                                <SelectWithSearch placeholder={'Komitent'}
                                                    value={this.state.filterClient !== null ? this.state.filterClient : params.client}
                                                    showName={true}
                                                    finishKey={'Enter'}
                                                    onChange={(e) => this.setState({ filterClient: e }, async () => {
                                                        let clients = await this._apiAsync('get-clients', { filter: this.state.filterClient })
                                                        if (clients && clients.items) {
                                                            this.setState({ clients: clients && clients.items }, () => {
                                                                if (this.state.clients.filter(item => item.code == this.state.filterClient).length) {
                                                                    this.updateParams('client', this.state.filterClient)
                                                                } else {
                                                                    this.updateParams('client', null)
                                                                }
                                                            })
                                                        } else {
                                                            this.updateParams('client', null)
                                                        }
                                                    })}
                                                    values={[...this.state.clients.map(item => {
                                                        let obj = {
                                                            name: item.name,
                                                            value: item.code
                                                        }
                                                        return obj
                                                    })]}
                                                    additionalClassName={'width-100-percent'}

                                                />

                                            </div>
                                            <div className="field-strap-wrap">

                                                <SelectWithSearch placeholder={'Poslovna'}
                                                    value={this.state.filterBusiness !== null ? this.state.filterBusiness : params.business}
                                                    showName={true}
                                                    readOnly={params.client && this.state.clients && this.state.clients.filter(item => item.code == this.state.filterClient)[0] && this.state.clients.filter(item => item.code == this.state.filterClient)[0].units && this.state.clients.filter(item => item.code == this.state.filterClient)[0].units.length ? false : true}
                                                    finishKey={'Enter'}
                                                    onChange={(e) => this.setState({ filterBusiness: e }, async () => {
                                                        let businessUnits = await this._apiAsync('get-business-units', { clientCode: Number(params.client), filter: this.state.filterBusiness })
                                                        if (businessUnits && businessUnits.items) {
                                                            this.setState({ businessUnits: businessUnits.items }, () => {
                                                                if (this.state.businessUnits.filter(item => item.code == this.state.filterBusiness).length) {
                                                                    this.updateParams('business', this.state.filterBusiness)
                                                                } else {
                                                                    this.updateParams('business', null)
                                                                }
                                                            })
                                                        } else {
                                                            this.updateParams('business', null)
                                                        }
                                                    })}
                                                    values={[...this.state.businessUnits.map(item => {
                                                        let obj = {
                                                            name: item.name,
                                                            value: item.code
                                                        }
                                                        return obj
                                                    })]}
                                                    additionalClassName={'width-100-percent'}

                                                />
                                            </div>
                                            <div className="field-strap-wrap" style={{ width: 200 }}>
                                                <Input style={{ width: 200 }} type='text'
                                                    value={this.state.filterArticle !== null ? this.state.filterArticle : params.article}
                                                    onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                        this.updateParams('article', this.state.filterArticle)
                                                    })} placeholder={'Artikal'} />
                                            </div>

                                        </div>
                                        <div className="clear-filters" onClick={() => {
                                            this.clearFilters()
                                        }}>
                                            <span>&times;</span> Ukloni filter
                                        </div>

                                    </div>
                                    <div className="mt-15">
                                        <ListBuilder
                                            tabs={false}
                                            sort={false}
                                            hideFilters={true}
                                            maxHeight={'63vh'}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            total={this.state.unprocessedTotal}
                                            showNumeration={false}
                                            fields={[
                                                { type: 'text', name: 'documentNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'finishedTs', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'time', label: 'VRIJEME'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },
                                                { type: 'text', name: 'userData.clientName', label: 'KOMITENT'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'userData.businessUnitCode', label: 'POSLOVNA JEDINICA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset' },
                                                { type: 'text', name: 'note', label: 'NAPOMENA'.translate(this.props.lang), multilang: false, allowSort: true, substr: 25, width: 'unset' },
                                                { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, numberWithCommas: true, width: 'unset' },

                                            ]}
                                            items={this.state.unprocessedItems.map((item, idx) => {
                                                return {
                                                    ...item,
                                                    ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                                    time: item.finishedTs ? this.props.getDateStringFromTs(item.finishedTs, 'HH:mm') : '',
                                                    finishedTs: item.finishedTs ? this.props.getDateStringFromTs(item.finishedTs) : '',
                                                }
                                            })}
                                            rawItems={this.state.unprocessedItems}

                                            onClick={(item) => {
                                                this.openDocument(item.documentNumber)
                                            }}

                                            params={params}
                                            sortField={params.sortField}
                                            sortType={params.sortType}
                                            updateSort={this.updateSort}
                                            updateParams={this.updateParams}
                                            updateMultipleParams={this.updateMultipleParams}
                                        ></ListBuilder>
                                    </div>
                                </>
                                :
                                <>
                                </>
                }
                {
                    this.state.processDocumentModal ?
                        <InfoModal
                            isOpen={this.state.processDocumentModal}
                            toggle={() => this.setState({ processDocumentModal: false })}
                            info={<div className='info-modal-body-wrap'>
                                <Isvg src={infoIcon} />
                                {`Da li želite proknjižiti dokument ${this.state.outputForm && this.state.outputForm.documentNumber}?`}
                            </div>}

                            buttons={[

                                {
                                    text: 'Da',
                                    onClick: async () => {
                                        this.setState({ processDocumentModal: false }, () => {
                                            this.processDocument()
                                        })
                                    }
                                },
                                {
                                    text: 'Ne',
                                    onClick: async () => {
                                        this.setState({ processDocumentModal: false })

                                    }
                                }
                            ]}

                        >
                        </InfoModal>
                        :
                        null
                }

                {/* <InfoModal
                    isOpen={this.state.exportModal}
                    toggle={() => this.setState({ exportModal: false })}
                    title={'Export'}
                    info={
                        <div className={'container'}>
                            <div className="row">
                                {
                                    this.state.exportFieldList.map((item, idx) => {
                                        return (
                                            <div key={idx} className={'col-md-6 mb-2 '}>
                                                <Checkbox checked={this.state.exportFields[item.name]}
                                                    className={'mr-2'} onChange={(e) => {
                                                        const value = !this.state.exportFields[item.name]
                                                        this.setState({
                                                            exportFields: { ...this.state.exportFields, [item.name]: value }
                                                        })
                                                    }} label={item.label} />

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    }

                    buttons={[

                        {
                            text: 'Export',
                            onClick: async () => {
                                let fields = [];
                                for (const [key, value] of Object.entries(this.state.exportFields)) {
                                    if (value) {
                                        const obj = this.state.exportFieldList.find((el) => el.name === key)
                                        fields.push({ key, label: obj.label })
                                    }
                                }
                                const request = {
                                    filter: {
                                        entries: 100,
                                        page: 0
                                    },
                                    methods: 'post',
                                    url: 'data/output',
                                    fields,
                                }
                                const exportResult = await this._apiAsync('generic-export', request)
                                if (exportResult && exportResult) {
                                    if (exportResult.success) {
                                        window.location.href = env.API_URL + `${exportResult.file}`;
                                    }
                                }
                            }
                        }
                    ]}

                >
                </InfoModal> */}
                <iframe name="my-frame" id="my-frame" title="my-frame" style={{ display: 'none' }} ></iframe>
            </div>
        )
    }
}

export default Page(OutputPage);
