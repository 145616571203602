
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class ReportCustomersAndItems extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            outputItems: [],
            manufacturers: []
            
        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        console.log(this.state.outputItems)
        console.log(this.state.total)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Promet po komitentima i artiklima</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article']}
                                fieldTracker={async (name, value) => {


                                }}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data, typeButton) => {
                                    console.log(typeButton)
                                    if (typeButton) {
                                        data.typeButton = typeButton
                                    }
                                    console.log(data)

                                    this._apiAsync('get-reports', data).then((response) => {
                                        console.log(response)
                                        this.setState({ outputItems: response })
                                        // this.setState({ total: response.total })
                                    })
                                    return "open-modal"

                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 10, sm: 10, xs: 10 },
                                                        className: "custom-input-row categories-manufacturers-row",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'clientCode',
                                                                                next: 'business',
                                                                                label: 'Šifra komitenta',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [
                                                                                    // (value) => { if (!value || value == '') return "Šifra dokumenta mora biti unesena!" },
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clients = await this._apiAsync('get-clients', { filter: value })
                                                                                                if (clients && clients.items) {
                                                                                                    this.setState({ clients: clients && clients.items }, () => {
                                                                                                        if (this.state.clients.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('clientName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                next: 'business',
                                                                                label: 'Naziv komitenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [


                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'business',
                                                                                next: 'dateFrom',
                                                                                label: 'Poslovna',
                                                                                readOnly: this.state.readOnlyBusiness,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                values: [...this.state.businessUnits.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                validate: [
                                                                                    async (value) => {
                                                                                        try {
                                                                                            if (value) {
                                                                                                const clientObject = this.state.clients.find(el => el.code == this.registerGetValue('clientCode'))
                                                                                                const businessUnits = await this._apiAsync('get-business-units', { filter: value, query: { client: clientObject._id } })
                                                                                                if (businessUnits && businessUnits.items) {
                                                                                                    this.setState({ businessUnits: businessUnits && businessUnits.items }, () => {
                                                                                                        if (this.state.businessUnits.filter(item => item.code == value).length) {
                                                                                                            this.remoteOnChange('businessName', this.state.businessUnits.filter(item => item.code == value)[0].name)
                                                                                                        } else {
                                                                                                            this.remoteOnChange('businessName', '')
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        } catch (e) {
                                                                                            return undefined;
                                                                                        }
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'businessName',
                                                                                next: 'dateFrom',
                                                                                label: 'Naziv poslovne jedinice',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [


                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: 'dateTo',
                                                                label: 'Datum od',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateTo',
                                                                next: 'articleFrom',
                                                                label: 'Datum do',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'selectWithSearch',
                                                                name: 'articleFrom',
                                                                next: 'articleTo',
                                                                label: 'Artikal od',
                                                                finishField: true,
                                                                readOnly: this.state.disableEdit,
                                                                asyncValidation: true,
                                                                values: [...this.state.products.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item.code,
                                                                        disabled: item.disabled ? true : false,
                                                                    }
                                                                    return obj
                                                                })],
                                                                validate: [
                                                                    // (value) => {
                                                                    //     if (!value || value == '') return "Artikal mora biti unesen!"
                                                                    // },
                                                                ]
                                                            },
                                                            {
                                                                type: 'selectWithSearch',
                                                                name: 'articleTo',
                                                                next: 'button',
                                                                label: 'Artikal do',
                                                                finishField: true,
                                                                readOnly: this.state.disableEdit,
                                                                asyncValidation: true,
                                                                values: [...this.state.products.map(item => {
                                                                    let obj = {
                                                                        name: item.name,
                                                                        value: item.code,
                                                                        disabled: item.disabled ? true : false,
                                                                    }
                                                                    return obj
                                                                })],
                                                                validate: [
                                                                    // (value) => {
                                                                    //     if (!value || value == '') return "Artikal mora biti unesen!"
                                                                    // },
                                                                ]
                                                            },
                                                     
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                name: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => this.submit('izvjestaj')}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>PROMET</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                         
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div className="v-scroll mt-15">
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hidePagination={true}
                                    fields={[
                                        { type: 'text', name: 'manufacturer', label: 'ŠIFRA PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'manufacturerName', label: 'NAZIV PROIZVODJACA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsDateFrom', label: 'DATUM OD'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsDateTo', label: 'DATUM DO'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discountsPercent', label: 'PROCENAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'conditions', label: 'KATEGORIJA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'conditionsNumberOfDays', label: 'BROJ DANA'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'total', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'RABAT'.translate(this.props.lang), multilang: false, allowSort: true },
                                        { type: 'text', name: 'totalAll', label: 'UKUPNO'.translate(this.props.lang), multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.outputItems.map((item, idx) => {
                                        console.log(item)
                                        return {
                                            ...item,
                                            // totalAll: item && item.total ? item.total - item.discount : item.total

                                        }
                                    })}
                                    // actions={
                                    //     [

                                    //         {
                                    //             component: <Isvg src={garbageIcon} />,
                                    //             onClick: (item) => this.setState({ deleteModal: item })
                                    //         },
                                    //     ]
                                    // }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Iznos</p>
                                    <span>
                                        {
                                            this.state.outputItems.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.outputItems.reduce((sum, current) => {
                                                    let res = sum + Number(current.total)
                                                    return res
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    <span>
                                        {
                                            this.state.outputItems.length != 0 ?
                                                this.props.roundToTwoDecimals(((this.state.outputItems.reduce((sum, current) => {
                                                    let res = sum + Number(current.discount)
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.outputItems.length != 0 ?
                                                this.props.roundToTwoDecimals(((this.state.outputItems.reduce((sum, current) => {

                                                    let res = sum + Number(current.totalAll)
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(ReportCustomersAndItems);

