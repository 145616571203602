import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-output': {
        onLoad: false,
        action: (data) => {

            if (data.typeOfChange === 'naturalniRabat') {
                return fetch(env.API_URL + '/data/naturalni-rabat/check/documentNumber', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })
            } else {
                return fetch(env.API_URL + '/data/output/check/documentNumber', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })
            }

        }
    },

    'check-document-version': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/packing/check/document-version', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    ...data
                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })

        }
    },
    'update-controled-articles': {
        onLoad: false,
        action: (data) => {
            if (data.typeOfChange === 'naturalniRabat') {
                return fetch(env.API_URL + '/data/naturalni-rabat/update/controled/articles', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify({
                        documentNumber: data.documentNumber,
                        barCode: data.barCode,
                        incrementAmount: data.incrementAmount,
                        date: data.date,
                        itemNumber: data.itemNumber,
                        // typeOfChange: data.typeOfChange

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })
            } else {
                return fetch(env.API_URL + '/data/output/update/controled/articles', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify({
                        documentNumber: data.documentNumber,
                        barCode: data.barCode,
                        incrementAmount: data.incrementAmount,
                        date: data.date,
                        itemNumber: data.itemNumber,
                        // typeOfChange: data.typeOfChange

                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            ...result
                        }
                })
            }

        }
    },
    'update-crates': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/output/update/crates', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })

        }
    },
    'check-crate-bar-code': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/check/crates/bar-code', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },


    'get-storekeepers': {
        onLoad: true,
        action: (data) => {
            return fetch(env.API_URL + '/data/users/by/level', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    level: 10

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        storekeepers: result
                    }
            })
        }
    },
    'get-packer': {
        onLoad: true,
        action: (data) => {
            return fetch(env.API_URL + '/data/users/by/level', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    level: 10,
                    notEqual: true

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        packer: result
                    }
            })
        }
    },
    'update-output-status': {
        onLoad: false,
        action: (data) => {
            if (data.typeOfChange === 'naturalniRabat') {
                return fetch(env.API_URL + '/data/naturalni-rabat/status', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify({
                        outputId: data.outputId,
                        nextStatus: data.nextStatus,
                        // typeOfChange: data.typeOfChange
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            outputObj: result
                        }
                })
            } else {
                return fetch(env.API_URL + '/data/output/status', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },

                    body: JSON.stringify({
                        outputId: data.outputId,
                        nextStatus: data.nextStatus,
                        // typeOfChange: data.typeOfChange
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            outputObj: result
                        }
                })
            }

        }
    },
    'printFaktura': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/output/print-faktura', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                        });
                    }
                })

        }
    },

    'printFakturaNaturalniRabat': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/naturalni-rabat/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)

            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };

                        });
                    }
                })

        }
    },


}