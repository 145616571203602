import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import Switch from '../../form-builder/fields/switch';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import editIcon from '../../assets/edit-icon.svg';
import deleteIcon from '../../assets/garbage2.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import DeleteModal from '../../components/deleteModal';
import env from "react-dotenv";
import PrintHelper from '../../components/printHelper';
import moment from 'moment';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import DateField from '../../form-builder/fields/date';

class FiscalCashQueue extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            overlayWhole: false,
            total: 20,
            accountsList: true,
            fiscalReportForm: false,
            listOfFiscalAccounts: false,
            buttonActive: 1,
            items: [],
            clients: [],
            places: [],
            periodReport: false
        }
    }

    componentDidMount() {
        this._onLoad();
        //    this._api('clientsAPI', {})
        //    this._api('placesAPI', {})

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    printFiscal = (type, dateFrom, dateTo) => {
        let obj = {
            type: type,
            dateFrom: dateFrom,
            dateTo: dateTo
        }
        this._apiAsync('printFiscalDocument', obj).then((res)=>{
            if(type == 'periodicReport'){
                this.setState({periodReport: null, periodReportFrom: null, periodReportTo: null})
            }
        })
    }

    render() {
        let params = this._getParams();


        return (
            <div>

                <div className={this.state.modal ? 'overlay-whole shown' : 'overlay-shown'} onClick={() => {
                    this.setState({ modal: false })
                }}></div>
                <div className="section-title title-options">
                    <h1>Fiskalna kasa</h1>


                </div>

                <div className="table-tabs" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="table-tabs-small">

                        <button className={this.state.buttonActive == 1 ? 'active' : ''} onClick={() => this.setState({ accountsList: true, fiscalReportForm: false, listOfFiscalAccounts: false, buttonActive: 1 })}>
                            Računi
                        </button>
                        <button className={this.state.buttonActive == 2 ? 'active' : ''} onClick={() => this.setState({ accountsList: false, fiscalReportForm: true, listOfFiscalAccounts: false, buttonActive: 2 })}>
                            Izvještaj
                        </button>
                        {/* <button className={this.state.buttonActive == 3 ? 'active' : ''} onClick={() => this.setState({ accountsList: false, fiscalReportForm: false, listOfFiscalAccounts: true, buttonActive: 3 }, () => {
                             this._apiAsync('get-fiscal-period').then((res) => {
                                this.setState({
                                    listOfFiscalAccounts: res
                                })
                            }) })}>
                            Lista računa
                        </button> */}

                    </div>
                </div>


                {
                    this.state.fiscalReportForm ?

                        <div className="ml-15">
                            <FormBuilder
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                // headerForm={"Artikal".translate(this.props.lang)}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    // if (this.state.unitsForm && this.state.unitsForm._id) {

                                    //     this._api('api4', { data: data }, () => {
                                    //         this.setState({ unitsForm: undefined }, () => { this._onLoad(); })
                                    //     });
                                    // } else {

                                    //     this._api('api2', { data: data }, () => {
                                    //         this.setState({ unitsForm: {} }, () => { this._onLoad() })
                                    //     });
                                    // }
                                }}
                                initialValues={this.state.unitsForm && this.state.unitsForm._id ? this.state.unitsForm : {}}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                className: 'col-up',
                                                width: { lg: 12, sm: 5, xs: 5 },
                                                children: [

                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: { lg: 6, sm: 12, xs: 12 },
                                                children: [
                                                    {
                                                        type: 'row',
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 6, sm: 6, xs: 6 },

                                                                        children: <button className="blue-button width-100" onClick={() => { this.printFiscal('zReport') }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>Dnevni Izvještaj</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 6, sm: 6, xs: 6 },

                                                                        children: <button className="blue-button width-100" onClick={() => { this.printFiscal('xReport') }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>Presjek stanja</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },

                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 6, sm: 12, xs: 12 },
                                                                style: { padding: 0 },
                                                                children: [
                                                                    {
                                                                        type: 'button',
                                                                        width: { lg: 6, sm: 6, xs: 6 },

                                                                        children: <button className="blue-button width-100" onClick={() => { this.setState({ periodReport: true }) }}>
                                                                            <div className="box box-blue">
                                                                                <Isvg src={editIcon} />
                                                                            </div>
                                                                            <p>Periodični Izvještaj</p>
                                                                        </button>,
                                                                        className: 'options-button width-100',
                                                                    },

                                                                ]
                                                            },

                                                        ]
                                                    }

                                                ]
                                            },

                                        ]
                                    },


                                ]}
                            ></FormBuilder>

                        </div>

                        :
                        this.state.listOfFiscalAccounts ?

                            <div>

                                <ListBuilder
                                    tabs={false}
                                    sort={true}
                                    tab1Name={'Računi'.translate(this.props.lang)}
                                    tab2Name={"Izvještaj".translate(this.props.lang)}
                                    tab3Name={'Lista računa'.translate(this.props.lang)}
                                    onClickTab1={() => {
                                        this.setState({
                                            accountsList: true,
                                            fiscalReportForm: false,
                                            listOfFiscalAccounts: false
                                        })
                                    }}
                                    onClickTab2={() => {
                                        this.setState({
                                            accountsList: false,
                                            fiscalReportForm: true,
                                            listOfFiscalAccounts: false
                                        })
                                    }}
                                    onClickTab3={() => {
                                        this.setState({
                                            accountsList: false,
                                            fiscalReportForm: false,
                                            listOfFiscalAccounts: true
                                        })
                                    }}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hideFilters={true}
                                    fields={[
                                        { type: 'text', name: 'documentNumber', label: 'Račun'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'newStatus', label: 'Status'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'date', label: 'Datum'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'error', label: 'Greska'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'fileContent', label: 'Opis'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            date: moment.unix(item.tsCreated).format('DD.MM.YYYY'),
                                            newStatus: item.status == 'Successful' ? 'Uspješno' : item.status == 'notFinished' ? 'Ne završeno' : "Greška"
                                        }
                                    })}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ clientForm: item }),
                                                condition: (item) => {
                                                    if (item.status == 'Error' || item.status == 'notFinished')
                                                        return true;
                                                    else
                                                        return false;
                                                }
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                            :

                            <div>

                                <ListBuilder
                                    tabs={false}
                                    sort={true}
                                    tab1Name={'Računi'.translate(this.props.lang)}
                                    tab2Name={"Izvještaj".translate(this.props.lang)}
                                    tab3Name={'Lista računa'.translate(this.props.lang)}
                                    onClickTab1={() => {
                                        this.setState({
                                            accountsList: true,
                                            fiscalReportForm: false,
                                            listOfFiscalAccounts: false
                                        })
                                    }}
                                    onClickTab2={() => {
                                        this.setState({
                                            accountsList: false,
                                            fiscalReportForm: true,
                                            listOfFiscalAccounts: false
                                        })
                                    }}
                                    onClickTab3={() => {
                                        this.setState({
                                            accountsList: false,
                                            fiscalReportForm: false,
                                            listOfFiscalAccounts: true
                                        })
                                    }}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    hideFilters={true}
                                    fields={[
                                        { type: 'text', name: 'documentNumber', label: 'Račun'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'newStatus', label: 'Status'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'date', label: 'Datum'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'error', label: 'Greska'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'fileContent', label: 'Opis'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            date: moment.unix(item.tsCreated).format('DD.MM.YYYY'),
                                            newStatus: item.status == 'Successful' ? 'Uspješno' : item.status == 'notFinished' ? 'Ne završeno' : "Greška"
                                        }
                                    })}

                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="delete-icon" />,
                                                onClick: (item) => this.setState({ clientForm: item }),
                                                condition: (item) => {
                                                    if (item.status == 'Error' || item.status == 'notFinished')
                                                        return true;
                                                    else
                                                        return false;
                                                }
                                            },
                                        ]
                                    }

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>


                }
                {this.state.periodReport ?

                    <Modal isOpen={this.state.periodReport} toggle={() => this.setState({ periodReport: false })} size='lg' className="modal-wrap fiscal-modal-report">
                        <ModalHeader>
                            <h4>Periodični Izvještaj</h4>
                            <div onClick={() => this.setState({ periodReport: false })}>&times;</div>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <div className="field-strap-wrap" style={{ width: 150 }}>
                                    <DateField
                                        additionalClassName={'date-input-width-150'}
                                        onChange={(value) => this.setState({ periodReportFrom: value }, () => {
                                        })}
                                        value={this.state.periodReportFrom}
                                        label={'Datum od'}
                                    ></DateField>
                                </div>
                                <div className="field-strap-wrap" style={{ width: 150 }}>
                                    <DateField
                                        additionalClassName={'date-input-width-150'}
                                        onChange={(value) => this.setState({ periodReportTo: value }, () => {
                                        })}
                                        value={this.state.periodReportTo}
                                        label={'do'}
                                    ></DateField>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {
                               this.printFiscal('periodicReport',this.state.periodReportFrom, this.state.periodReportTo)
                            }}>Izvještaj</Button>

                        </ModalFooter>
                    </Modal>

                    : null}
            </div>
        )
    }
}

export default Page(FiscalCashQueue);
