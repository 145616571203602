import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import logo from '../assets/logo.svg';
import menuIcon from '../assets/hamburger.svg';
import micIcon from '../assets/mic.svg';
import testIcon from '../assets/sidebarTestIcon.svg';
import logoutIcon from '../assets/logout.svg';

import barsIcon from '../assets/hamburger-icon.svg';




import env from "react-dotenv";


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownMenu: false,
            subDropdownMenu: false,
            subSubDropdownMenu: false,
            rotateSubArrow: false
        }
    }
    componentDidMount() {
        this.checkOpenedModules()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.modulesTree != this.props.modulesTree) {
            this.checkOpenedModules()
        }
    }
    checkOpenedModules = () => {
        let mainModule;
        let subModule;
        let subSubModule;
        let subSubSubModule;
        let pathFound = null;
        for (let i = 0; i < this.props.modulesTree.length; i++) {
            if (!pathFound) {
                mainModule = this.props.modulesTree[i]._id;
                if (this.props.modulesTree[i].path && this.props.modulesTree[i].path == this.props[0].location.pathname) {
                    pathFound = this.props.modulesTree[i]._id;
                    break;
                } else if (this.props.modulesTree[i].modules && this.props.modulesTree[i].modules.length) {
                    for (let j = 0; j < this.props.modulesTree[i].modules.length; j++) {
                        if (!pathFound)
                            subModule = this.props.modulesTree[i].modules[j]._id;
                        if (this.props.modulesTree[i].modules[j].path && this.props.modulesTree[i].modules[j].path == this.props[0].location.pathname) {
                            pathFound = this.props.modulesTree[i].modules[j]._id;
                            break;
                        } else if (this.props.modulesTree[i].modules[j].modules && this.props.modulesTree[i].modules[j].modules.length) {
                            for (let k = 0; k < this.props.modulesTree[i].modules[j].modules.length; k++) {
                                if (!pathFound)
                                    subSubModule = this.props.modulesTree[i].modules[j].modules[k]._id;
                                if (this.props.modulesTree[i].modules[j].modules[k].path && this.props.modulesTree[i].modules[j].modules[k].path == this.props[0].location.pathname) {
                                    pathFound = this.props.modulesTree[i].modules[j].modules[k]._id;
                                    break;
                                } else if (this.props.modulesTree[i].modules[j].modules[k].modules && this.props.modulesTree[i].modules[j].modules[k].modules.length) {
                                    for (let l = 0; l < this.props.modulesTree[i].modules[j].modules[k].modules.length; l++) {
                                        if (!pathFound)
                                            subSubSubModule = this.props.modulesTree[i].modules[j].modules[k].modules[l]._id;
                                        if (this.props.modulesTree[i].modules[j].modules[k].modules[l].path && this.props.modulesTree[i].modules[j].modules[k].modules[l].path == this.props[0].location.pathname) {
                                            pathFound = this.props.modulesTree[i].modules[j].modules[k].modules[l]._id;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (pathFound) {
            if (pathFound == mainModule) {
                this.setState({ dropdownMenu: mainModule, subDropdownMenu: null, subSubDropdownMenu: null, subSubSubModule: null })
            } else if (pathFound == subModule && mainModule) {
                this.setState({ dropdownMenu: mainModule, subDropdownMenu: subModule, subSubDropdownMenu: null, subSubSubModule: null })
            } else if (pathFound == subSubModule && mainModule && subModule) {
                this.setState({ dropdownMenu: mainModule, subDropdownMenu: subModule, subSubDropdownMenu: subSubModule, subSubSubModule: null })
            } else if (pathFound == subSubSubModule && mainModule && subModule && subSubModule) {
                this.setState({ dropdownMenu: mainModule, subDropdownMenu: subModule, subSubDropdownMenu: subSubModule, subSubSubModule: subSubSubModule })
            }
        }


    }



    render() {

        return (
            <div className={this.props.sidebarShort ? 'sidebar sidebar-short' : 'sidebar'}>
                <div className="sidebar-scroll-area">
                    <div className="sidebar-header">
                        <Isvg src={logo} className="logo" />
                        <div className="menu-icon-box" onClick={() => this.props.sidebarChange()} >
                            <Isvg src={menuIcon} className="menu-icon" />
                        </div>
                    </div>
                    <div className="sidebar-body">
                        <ul className="main-menu">
                            {
                                this.props.modulesTree.map((item, idx) => {
                                    if (item.path != '/profile') {
                                        if (item.modules && item.modules.length == 0)
                                            return (
                                                <li>
                                                    <div className={this.state.dropdownMenu == item._id ? "main-menu-block main-menu-block-without-arrow main-menu-block-active" : "main-menu-block main-menu-block-without-arrow"}>
                                                        <Link to={item.path ? item.path : this.props[0].location.pathname}>
                                                            <div className="icon-container">
                                                                <Isvg src={item.icon ? (env.API_URL + item.icon) : testIcon} />
                                                            </div>
                                                            <span className="link-text">{item.name}</span>
                                                        </Link>
                                                    </div>
                                                </li>

                                            )
                                        else
                                            return (
                                                <li>
                                                    <div className={this.state.dropdownMenu == item._id ? 'main-menu-block main-menu-block-active rotate' : 'main-menu-block'} onClick={() => {

                                                        if (this.props.sidebarShort) {
                                                            this.props.sidebarChange()
                                                        }
                                                        if (this.state.dropdownMenu == item._id) {
                                                            this.setState({
                                                                dropdownMenu: null
                                                            })
                                                        } else if (this.state.dropdownMenu != item._id && this.state.dropdownMenu) {
                                                            this.setState({
                                                                dropdownMenu: item._id
                                                            })
                                                        } else {
                                                            this.setState({
                                                                dropdownMenu: item._id
                                                            })
                                                        }

                                                    }}>
                                                        <Link to={item.path ? item.path : this.props[0].location.pathname}>
                                                            <div className="icon-container">
                                                                <Isvg src={item.icon ? (env.API_URL + item.icon) : testIcon} />
                                                            </div>
                                                            <span className="link-text">{item.name}</span>
                                                        </Link>
                                                    </div>
                                                    {
                                                        !this.props.sidebarShort ?
                                                            <ul className={this.state.dropdownMenu == item._id ? 'main-menu-dropdown open main-menu-dropdown-without-arrow' : 'main-menu-dropdown main-menu-dropdown-without-arrow'}>
                                                                {
                                                                    item.modules && item.modules.map((submodule, sidx) => {
                                                                        if (submodule.modules && submodule.modules.length == 0) {

                                                                            return (
                                                                                <li>
                                                                                    <div className={this.state.subDropdownMenu == submodule._id ? 'main-menu-block-no-hover-box main-menu-block-no-hover-box-active main-menu-block-without-arrow' : 'main-menu-block-no-hover-box main-menu-block-without-arrow'} >
                                                                                        <Link to={submodule.path ? submodule.path : item.path ? item.path : this.props[0].location.pathname}>{submodule.name}</Link>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                        else {

                                                                            return (
                                                                                <li>
                                                                                    <div className={this.state.subDropdownMenu == submodule._id ? 'main-menu-block-no-hover-box main-menu-block-no-hover-box-active rotate' : 'main-menu-block-no-hover-box'} onClick={() => {
                                                                                        if (this.state.subDropdownMenu == submodule._id) {
                                                                                            this.setState({
                                                                                                subDropdownMenu: null
                                                                                            })
                                                                                        } else if (this.state.subDropdownMenu != submodule._id && this.state.subDropdownMenu) {
                                                                                            this.setState({
                                                                                                subDropdownMenu: submodule._id
                                                                                            })
                                                                                        } else {
                                                                                            this.setState({
                                                                                                subDropdownMenu: submodule._id
                                                                                            })
                                                                                        }

                                                                                    }}>
                                                                                        <Link to={submodule.path ? submodule.path : this.props[0].location.pathname}>
                                                                                            {submodule.name}
                                                                                        </Link>
                                                                                    </div>
                                                                                    <ul className={this.state.subDropdownMenu == submodule._id ? 'main-menu-dropdown open main-menu-dropdown-without-arrow padding-left-20' : 'main-menu-dropdown main-menu-dropdown-without-arrow padding-left-20'}>
                                                                                        {
                                                                                            submodule.modules && submodule.modules.map((submodule2, sidx) => {
                                                                                                if (submodule2.modules && submodule2.modules.length == 0) {
                                                                                                    return (
                                                                                                        <li className="padding-left-5">
                                                                                                            <div className={this.state.subSubDropdownMenu == submodule2._id ? 'main-menu-block-no-hover-box main-menu-block-no-hover-box-active main-menu-block-without-arrow' : 'main-menu-block-no-hover-box main-menu-block-without-arrow'}>
                                                                                                                <Link to={submodule2.path ? submodule2.path : item.path ? item.path : this.props[0].location.pathname}>{submodule2.name}</Link>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    )
                                                                                                } else {
                                                                                                    return (
                                                                                                        <li className="padding-left-5">
                                                                                                            <div className={this.state.subSubDropdownMenu == submodule2._id ? 'main-menu-block-no-hover-box main-menu-block-no-hover-box-active rotate' : 'main-menu-block-no-hover-box'} onClick={() => {
                                                                                                                if (this.state.subSubDropdownMenu == submodule2._id) {
                                                                                                                    this.setState({
                                                                                                                        subSubDropdownMenu: null
                                                                                                                    })
                                                                                                                } else if (this.state.subSubDropdownMenu != submodule2._id && this.state.subSubDropdownMenu) {
                                                                                                                    this.setState({
                                                                                                                        subSubDropdownMenu: submodule2._id
                                                                                                                    })
                                                                                                                } else {
                                                                                                                    this.setState({
                                                                                                                        subSubDropdownMenu: submodule2._id
                                                                                                                    })
                                                                                                                }

                                                                                                            }}>
                                                                                                                <Link to={submodule2.path ? submodule2.path : this.props[0].location.pathname}>
                                                                                                                    {submodule2.name}
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <ul className={this.state.subSubDropdownMenu == submodule2._id ? 'sub-dropdown open main-menu-dropdown-without-arrow padding-left-20' : 'sub-dropdown main-menu-dropdown-without-arrow padding-left-20'}>
                                                                                                                {
                                                                                                                    submodule2.modules && submodule2.modules.map((submodule2, sidx) => {
                                                                                                                        return (
                                                                                                                            <li className="padding-left-5">
                                                                                                                                <div className={this.state.subSubSubModule == submodule2._id ? 'main-menu-block-no-hover-box main-menu-block-no-hover-box-active main-menu-block-without-arrow' : 'main-menu-block-no-hover-box main-menu-block-without-arrow'}>
                                                                                                                                    <Link to={submodule2.path ? submodule2.path : item.path ? item.path : this.props[0].location.pathname}>{submodule2.name}</Link>
                                                                                                                                </div>
                                                                                                                            </li>
                                                                                                                        )

                                                                                                                    })
                                                                                                                }
                                                                                                            </ul>



                                                                                                        </li>
                                                                                                    )
                                                                                                }

                                                                                            })
                                                                                        }
                                                                                    </ul>



                                                                                </li>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </ul>
                                                            :
                                                            null
                                                    }




                                                </li>
                                            )
                                    }

                                })
                            }
                            {/* <span className="section-divider">ADMINISTRACIJA</span>
                            <li>
                                <div className="main-menu-block">
                                    <Link to="/users">
                                        <Isvg src={testIcon} />
                                        <span className="link-text">Administratori</span>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="main-menu-block">
                                    <Link to="/modules">
                                        <Isvg src={testIcon} />
                                        <span className="link-text">Moduli</span>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="main-menu-block">
                                    <Link to="/user-levels">
                                        <Isvg src={testIcon} />
                                        <span className="link-text">User levels</span>
                                    </Link>
                                </div>
                            </li>
                            <li style={{ marginTop: 50 }}>
                                <div className="main-menu-block main-menu-block-without-arrow" onClick={() => this.props.signOut(() => this.props[0].history.push('/login'))}>
                                    <a >
                                        <Isvg src={testIcon} />
                                        <span className="link-text">{'Logout'.translate(this.props.lang)}</span>
                                    </a>
                                </div>
                            </li> */}
                            <li style={{ marginTop: 50 }}>
                                <div className="main-menu-block main-menu-block-without-arrow" onClick={() => this.props.signOut(() => this.props[0].history.push('/login'))}>
                                    <a>
                                        <div className="icon-container">
                                            <Isvg src={logoutIcon} />
                                        </div>
                                        <span className="link-text">{'Logout'.translate(this.props.lang)}</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="sidebar-footer">
                    <div className="fast-help">
                        <Isvg src={micIcon} />
                        <h6>Potrebna Vam je pomoć?</h6>
                        <p>Slobodno nas kontaktirate</p>
                        <button>POKRENI POZIV</button>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Sidebar;
