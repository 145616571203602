import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input
} from 'reactstrap';

import ListBuilder from '../list-builder/index.js';
import editIcon from '../assets/edit-icon.svg';
import garbageIcon from '../assets/garbage.svg';
import Isvg from 'react-inlinesvg';
import saveIcon from '../assets/save.svg';

import error from '../assets/error.png';
import FormBuilder from '../form-builder/index.js';
import { required, validateDate } from '../form-builder/validation.js';
// import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';

class AlmbihModal extends React.Component {

    constructor(props) {
        super(props);
        console.log('KONSTRUKTOR, KONSTRUKTOR....')
        this.state = {
            form: {},
        }
        this.childRef = React.createRef()
    }

    componentDidMount() {
        // console.log('U DID MOUNT...')
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log('U DID UPDEJTU...')
    }


    render() {
        
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} style={{ width: 1200 }}>
                {
                    this.props.title ?
                        <ModalHeader>{this.props.title}</ModalHeader>
                        :
                        null
                }

                <ModalBody className="modalALMBIH" style={{ textAlign: 'left' }}>
                    <FormBuilder
                        registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                        registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                        registerSubmitAction={(submit) => this.submit = submit}

                        onSubmit={(data) => {
                            this.props.saveData(data)
                        }}
                        initialValues={this.props.data ? this.props.data.data : {}}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children: [
                                            {
                                                type: 'h4',
                                                text: 'Medicinska sredstva',
                                                className: 'margine-left-0'
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children: [
                                            {
                                                type: 'h4',
                                                text: 'Lijekovi',
                                                className: 'margine-left-0'
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'div',
                                                children:
                                                    <div>
                                                        <div className=''>
                                                            <p>Ima dozvolu</p>
                                                        </div>
                                                    </div>
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 4, sm: 4, xs: 4 },
                                        children: [
                                            {
                                                type: 'switch',
                                                name: 'sredstva',
                                                next: 'deviceNumberLicence',
                                                label: 'DA',
                                                className: 'test123',
                                                asyncValidation: true,
                                                validate: [

                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'div',
                                                children: <div>
                                                    <div className=''>
                                                        <p className='paraf-DA-almbiih-modal'>Ima dozvolu</p>
                                                    </div>

                                                </div>
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 4, sm: 4, xs: 4 },
                                        children: [
                                            {
                                                type: 'switch',
                                                name: 'lijekovi',
                                                className: 'modalLabel',
                                                next: 'drugNumberLicence',
                                                label: 'DA',
                                                className: 'test123',
                                                asyncValidation: true,
                                            }
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'deviceNumberLicence',
                                                className: 'modalLabel',
                                                next: 'dateFrom1',
                                                label: 'Broj dozvole',
                                                asyncValidation: true,
                                                validate: [
                                                    // required('Broj dozvole mora biti upisan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('sredstva')) {
                                                                return "Morate odobriti medicinsko sredstvo!"
                                                            }
                                                        } else {
                                                            if (this.registerGetValue('sredstva')) {
                                                                return "Morate unijeti broj dozvole!"
                                                            }

                                                        }

                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'drugNumberLicence',
                                                className: 'modalLabel',
                                                next: 'dateFrom2',
                                                label: 'Broj dozvole',
                                                asyncValidation: true,
                                                validate: [
                                                    // required('Broj dozvole mora biti upisan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('lijekovi')) {
                                                                return "Morate odobriti lijek!"
                                                            }
                                                        }

                                                    }
                                                ]
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'dateFrom1',
                                                className: 'modalLabel',
                                                next: 'dateTo1',
                                                label: 'Dozvola važi od:',
                                                asyncValidation: true,
                                                validate: [
                                                    // required('Datum mora biti upisan!'),
                                                    validateDate('Datum nije ispravan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('sredstva')) {
                                                                return "Morate odobriti medicinsko sredstvo!"
                                                            }
                                                        } else {
                                                            if (this.registerGetValue('sredstva')) {
                                                                return "Morate unijeti datum!"
                                                            }

                                                        }

                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'dateTo1',
                                                className: 'modalLabel',
                                                next: 'drugNumberLicence',
                                                label: 'Dozvola važi do:',
                                                asyncValidation: true,
                                                validate: [
                                                    // required('Datum mora biti upisan!'),
                                                    validateDate('Datum nije ispravan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('sredstva')) {
                                                                return "Morate odobriti medicinsko sredstvo!"
                                                            }
                                                        } else {
                                                            if (this.registerGetValue('sredstva')) {
                                                                return "Morate unijeti datum!"
                                                            }
                                                        }

                                                    },

                                                    (value) => {
                                                        if (value) {
                                                            console.log('POSLOVNA JEDIINICA....',value)
                                                            let godinaOD = Number(this.registerGetValue('dateFrom1').split(".")[2])
                                                            let mjesecOD = Number(this.registerGetValue('dateFrom1').split(".")[1])
                                                            let danOD = Number(this.registerGetValue('dateFrom1').split(".")[0])

                                                            

                                                            let godinaDO = Number(value.split(".")[2])
                                                            let mjesecDO = Number(value.split(".")[1])
                                                            let danDO = Number(value.split(".")[0])

                                                            console.log('GODINA....',godinaOD,'...........',godinaDO )
                                                            console.log('MJESEC....',mjesecOD,'...........',mjesecDO )
                                                            console.log('DAN....',danOD,'...........',danDO )


                                                            if (godinaOD > godinaDO) {
                                                                return "Prvi datum je veci od drugog!"
                                                            }else if(mjesecOD > mjesecDO){
                                                                return "Prvi datum je veci od drugog!"
                                                            }else if(danOD > danDO){
                                                                return "Prvi datum je veci od drugog!"
                                                            }

                                                            

                                                            
                                                        }
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [

                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'dateFrom2',
                                                className: 'modalLabel',
                                                label: 'Dozvola važi od:',
                                                next: 'dateTo2',
                                                asyncValidation: true,
                                                validate: [
                                                    // required('Datum mora biti upisan!'),
                                                    validateDate('Datum nije ispravan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('lijekovi')) {
                                                                return "Morate odobriti lijek!"
                                                            }
                                                        } else {
                                                            if (this.registerGetValue('lijekovi')) {
                                                                return "Morate unijeti datum!"
                                                            }

                                                        }

                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 2, sm: 2, xs: 2 },
                                        children: [
                                            {
                                                type: 'date',
                                                name: 'dateTo2',
                                                className: 'modalLabel',
                                                label: 'Dozvola važi do:',
                                                asyncValidation: true,
                                                next: 'lijekovi',
                                                validate: [
                                                    // required('Datum mora biti upisan!'),
                                                    validateDate('Datum nije ispravan!'),
                                                    (value) => {
                                                        if (value) {
                                                            if (!this.registerGetValue('lijekovi')) {
                                                                return "Morate odobriti lijek!"
                                                            }
                                                        } else {
                                                            if (this.registerGetValue('lijekovi')) {
                                                                return "Morate unijeti datum!"
                                                            }

                                                        }

                                                    },

                                                    (value) => {
                                                        if (value) {
                                                            let godinaOD = Number(this.registerGetValue('dateFrom2').split(".")[2])
                                                            let mjesecOD = Number(this.registerGetValue('dateFrom2').split(".")[1])
                                                            let danOD = Number(this.registerGetValue('dateFrom2').split(".")[0])

                                                            let godinaDO = Number(value.split(".")[2])
                                                            let mjesecDO = Number(value.split(".")[1])
                                                            let danDO = Number(value.split(".")[0])

                                                            if (godinaOD > godinaDO) {
                                                                return "Prvi datum je veci od drugog!"
                                                            }

                                                            if (mjesecOD > mjesecDO) {
                                                                return "Prvi datum je veci od drugog!"
                                                            }

                                                            if (danOD > danDO) {
                                                                return "Prvi datum je veci od drugog!"
                                                            }
                                                        }
                                                    }

                                                ]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children:

                                            this.props.items.filter((item) => {
                                                if (item.articleType == 2) {
                                                    return item
                                                }
                                            }).map((item) => {
                                                let obj = {
                                                    type: 'checkbox',
                                                    name: item.code,
                                                    label: item.name,

                                                }
                                                return obj
                                            })

                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 6, sm: 6, xs: 6 },
                                        children:

                                            this.props.items.filter((item) => {
                                                if (item.articleType == 1) {
                                                    return item
                                                }
                                            }).map((item) => {
                                                let obj = {
                                                    type: 'checkbox',
                                                    name: item.code,
                                                    label: item.name,
                                                }
                                                return obj
                                            })

                                    },
                                    {
                                        type: 'col',
                                        width: { lg: 12, sm: 12, xs: 12 },
                                        style: { textAlign: 'right' },
                                        children: [
                                            {
                                                type: 'div',
                                                width: { lg: 3, sm: 3, xs: 3 },
                                                action: 'custom',
                                                children: <button onClick={() => {
                                                    this.submit()
                                                    this.props.toggle()
                                                }} className="info-modal-button btn bnt-primary almb-button"><span>Zatvori</span></button>,
                                                // className: 'options-button',


                                            },

                                        ]
                                    },
                                    // {
                                    //     type: 'col',
                                    //     width: { lg: 1, sm: 1, xs: 1 },
                                    //     children: [
                                    //         {
                                    //             type: 'button',
                                    //             width: { lg: 3, sm: 3, xs: 3 },
                                    //             action: 'custom',
                                    //             children: <div className="info-modal-button btn bnt-primary"><span>Izlaz</span></div>,
                                    //             onClick: () => { this.props.toggle() }
                                    //             // className: 'options-button',

                                    //         },
                                    //     ]
                                    // },

                                ]
                            }
                        ]}
                    >
                    </FormBuilder>
                </ModalBody>
            </Modal>
        )
    }
}

export default AlmbihModal;