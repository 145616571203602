
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate, date } from '../../form-builder/validation';

class ReportKO extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            form: {},
            outputItems: [],
            manufacturers: [],
            documents: [],
            documents1: [],
            ko: [],
            tyButton: 'izvjestaj'

        }
    }

    componentDidMount() {
        this._onLoad();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();
        // console.log(this.state.outputItems)
        // console.log('INICIJALNE VRIJEDNOSTI.....', this.state.form)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Izvještaj KO (Knjizne Obavijesti)</h1>
                    <div className="title-options-group">
                        {/* <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button> */}
                        <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button>
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12">
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerFocusedField={(registerFocusedField) => this.registerFocusedField = registerFocusedField}
                                registerSubmitAction={(submit) => this.submit = submit}
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['clientCode']}
                                fieldTracker={async (name, value) => {

                                    if (name == 'clientCode' && value) {
                                        console.log('Ulazak u dio za klijentaaaaaa .....', (Object.keys(this.state.form)))
                                        if (value) {
                                            const clients = await this._apiAsync('get-clients', { filter: value })
                                            if (clients && clients.items) {
                                                this.setState({ clients: clients && clients.items }, () => {
                                                    if (this.state.clients.filter(item => item.code == value).length) {
                                                        this.remoteOnChange('clientName', this.state.clients.filter(item => item.code == value)[0].name)
                                                        if (Object.keys(this.state.form).length == 0) {
                                                            this.remoteOnChange('dateFrom', '01.01.' + new Date().getFullYear())
                                                            this.remoteOnChange('dateTo', '31.12.' + new Date().getFullYear())
                                                        } else {
                                                            this.remoteOnChange('dateFrom', this.state.form.dateFrom)
                                                            this.remoteOnChange('dateTo', this.state.form.dateTo)
                                                        }

                                                    } else {
                                                        this.remoteOnChange('clientName', '')
                                                    }
                                                })
                                            }
                                            // this.setState({documents1:[]})

                                        }
                                    }




                                }}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={async (data, typeButton) => {

                                    if (typeButton) {
                                        data.typeButton = typeButton
                                        this.setState({ tyButton: data.typeButton })
                                    }
                                    // console.log('podaci za backend....', data)
                                    if (data.typeButton == 'izvjestaj') {
                                        this.setState({ documents1: [] })
                                        let odgovor = await this._apiAsync('getKO', data)
                                        console.log('ODGOVOR ZA PRIGOVOR...', odgovor)
                                        this.setState({ documents: odgovor }, () => { this.setState({ form: data }) })

                                        this.remoteOnChange('clientCode', data.clientCode)
                                        this.remoteOnChange('clientName', data.clientName)
                                        this.remoteOnChange('dateFrom', data.dateFrom)
                                        this.remoteOnChange('dateTo', data.dateTo)
                                        if (data.documentNumber) {
                                            this.remoteOnChange('documentNumber', data.documentNumber)
                                        }


                                        return "open-modal"
                                    }

                                    if (data.typeButton == 'total') {

                                        this.setState({ documents: [] })
                                        //lista svih KO u kolekciji 
                                        let odgovorTotal = await this._apiAsync('getKO', data)

                                        //lista svih sifri komitenata iz svih KO(jedna sifra moze se pojaviti vise puta)
                                        let totalKomitenti = []
                                        let totKomitenti = {}

                                        odgovorTotal.map((element) => {
                                            totalKomitenti.push(element.clientCode)

                                        })


                                        //lista svih sifri komitenata (samo po jedna sifra komitenta)
                                        let totalKomitentiSkraceno = totalKomitenti.filter((number, index) => {
                                            return totalKomitenti.indexOf(number) === index
                                        })


                                        //citanje vrijednosti KO za svakog komitenta
                                        for (let i = 0; i < totalKomitentiSkraceno.length; i++) {


                                            let filterKO = odgovorTotal.filter((element) => {
                                                if (element.clientCode == totalKomitentiSkraceno[i]) {
                                                    return element
                                                }
                                            })

                                            let prije = filterKO.reduce((sum, current) => {

                                                sum = sum + current.items.reduce((sum1, current1) => {
                                                    sum1 = sum1 + current1.beforeAmount
                                                    return sum1
                                                }, 0)
                                                return sum
                                            }, 0)

                                            let posle = filterKO.reduce((sum, current) => {

                                                sum = sum + current.items.reduce((sum1, current1) => {
                                                    sum1 = sum1 + current1.newAmount
                                                    return sum1
                                                }, 0)
                                                return sum
                                            }, 0)

                                            let ko = filterKO.reduce((sum, current) => {

                                                sum = sum + current.items.reduce((sum1, current1) => {
                                                    sum1 = sum1 + current1.ammountKo
                                                    return sum1
                                                }, 0)
                                                return sum
                                            }, 0)


                                            let naziv = odgovorTotal.filter((elem) => {

                                                return elem.clientCode == totalKomitentiSkraceno[i]
                                            })[0].clientName



                                            this.setState({ documents1: [...this.state.documents1, { clientCode: totalKomitentiSkraceno[i], clientName: naziv, iznos: prije, iznosNovi: posle, ko: ko }] })
                                            this.setState({ documents: [] })


                                        }

                                        this.remoteOnChange('clientCode', data.clientCode)
                                        this.remoteOnChange('clientName', data.clientName)
                                        this.remoteOnChange('dateFrom', data.dateFrom)
                                        this.remoteOnChange('dateTo', data.dateTo)
                                        if (data.documentNumber) {
                                            this.remoteOnChange('documentNumber', data.documentNumber)
                                        }

                                    }


                                }}
                                initialValues={this.state.form}
                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 10, sm: 10, xs: 10 },
                                                        className: "custom-input-row categories-manufacturers-row",
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                className: 'input-group-custom-1-container',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        className: 'input-group-custom input-group-custom-1',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        children: [
                                                                            {
                                                                                type: 'selectWithSearch',
                                                                                name: 'clientCode',
                                                                                next: 'dateFrom',
                                                                                label: 'Šifra komitenta',
                                                                                values: [...this.state.clients.map(item => {
                                                                                    let obj = {
                                                                                        name: item.name,
                                                                                        value: item.code
                                                                                    }
                                                                                    return obj
                                                                                })],
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'text',
                                                                                name: 'clientName',
                                                                                // next: 'businessUnit',
                                                                                label: 'Naziv komitenta',
                                                                                readOnly: true,
                                                                                finishField: true,
                                                                                asyncValidation: true,
                                                                                validate: [


                                                                                ]
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'date',
                                                                name: 'dateFrom',
                                                                next: 'dateTo',
                                                                label: 'Datum od',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'date',
                                                                name: 'dateTo',
                                                                next: 'documentNumber',
                                                                label: 'Datum do',
                                                                finishField: true,
                                                                asyncValidation: true,
                                                                validate: [
                                                                    (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                    validateDate('Datum nije validan')
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 4, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'selectWithSearch',
                                                                        name: 'documentNumber',
                                                                        // next: 'percent',
                                                                        label: 'Broj računa',
                                                                        // readOnly: this.state.ko.length == 0 ? true : false,
                                                                        asyncValidation: true,
                                                                        values: [
                                                                            ...this.state.ko.map(item => {
                                                                                let obj = {
                                                                                    name: item.factureNumber,
                                                                                    value: item.factureNumber
                                                                                }
                                                                                return obj;
                                                                            })
                                                                        ],
                                                                        validate: [
                                                                            // required('Proizvođač mora biti unešen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 2, sm: 2, xs: 2 },
                                                        children: [
                                                            {
                                                                type: 'row',
                                                                children: [
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => this.submit('izvjestaj')}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>KO PO KOMITENTU</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',

                                                                            },

                                                                        ]
                                                                    },
                                                                    {
                                                                        type: 'col',
                                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                                        style: { padding: 0 },
                                                                        children: [
                                                                            {
                                                                                type: 'button',
                                                                                width: { lg: 4, sm: 6, xs: 6 },

                                                                                children: <button className="blue-button width-100" onClick={() => {
                                                                                    this.submit('total')
                                                                                }}>
                                                                                    <div className="box box-blue">
                                                                                        <Isvg src={editIcon} />
                                                                                    </div>
                                                                                    <p>TOTAL PO KOMITENTU</p>
                                                                                </button>,
                                                                                className: 'options-button width-100',
                                                                            },

                                                                        ]
                                                                    },
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                ]}
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div className="v-scroll mt-15">
                                {this.state.tyButton == 'izvjestaj' ?
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'koNumber', label: 'BROJ DOK.'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'clientCode', label: 'ŠIFRA KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'clientName', label: 'NAZIV KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'factureNumber', label: 'BROJ RAČUNA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'date', label: 'DATUM'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'iznos', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'iznosNovi', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ko', label: 'IZNOS KO'.translate(this.props.lang), multilang: false, allowSort: true },

                                        ]}
                                        // items={this.state.documents}
                                        items={this.state.documents.map((item, idx) => {
                                            return {
                                                ...item,
                                                iznos: item.items.reduce((sum, current) => {
                                                    sum = sum + current.beforeAmount
                                                    return sum
                                                }, 0),
                                                iznosNovi: item.items.reduce((sum, current) => {
                                                    sum = sum + current.newAmount
                                                    return sum
                                                }, 0),
                                                ko: item.items.reduce((sum, current) => {
                                                    sum = sum + current.ammountKo
                                                    return sum
                                                }, 0),


                                            }
                                        })}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                    :

                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        total={this.state.total}
                                        showNumeration={false}
                                        hidePagination={true}
                                        fields={[
                                            { type: 'text', name: 'clientCode', label: 'ŠIFRA KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'clientName', label: 'NAZIV KOMITENTA'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'iznos', label: 'STARI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'iznosNovi', label: 'NOVI IZNOS'.translate(this.props.lang), multilang: false, allowSort: true },
                                            { type: 'text', name: 'ko', label: 'IZNOS KO'.translate(this.props.lang), multilang: false, allowSort: true },

                                        ]}
                                        items={this.state.documents1}
                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                }

                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Iznos</p>
                                    {this.state.tyButton == 'izvjestaj' ?
                                        <span>
                                            {
                                                this.state.documents.length != 0 ?
                                                    this.props.roundToTwoDecimals(this.state.documents.reduce((sum, current) => {
                                                        sum = sum + current.items.reduce((sum1, current1) => {
                                                            sum1 = sum1 + current1.beforeAmount
                                                            return sum1
                                                        }, 0)
                                                        return sum
                                                    }, 0), true, true)

                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                        :
                                        <span>
                                            {
                                                this.state.documents1.length != 0 ?
                                                    this.props.roundToTwoDecimals(this.state.documents1.reduce((sum, current) => {
                                                        sum = sum + current.iznos
                                                        return sum
                                                    }, 0), true, true)

                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                    }

                                </div>
                                <div className="col-info">
                                    <p>Rabat</p>
                                    {this.state.tyButton == 'izvjestaj' ?
                                        <span>
                                            {
                                                this.state.documents.length != 0 ?
                                                    this.props.roundToTwoDecimals(this.state.documents.reduce((sum, current) => {
                                                        sum = sum + current.items.reduce((sum1, current1) => {
                                                            sum1 = sum1 + current1.ammountKo
                                                            return sum1
                                                        }, 0)
                                                        return sum
                                                    }, 0), true, true)
                                                    :
                                                    '00,00'
                                            }
                                            &nbsp;KM
                                        </span>
                                        :
                                        <span>
                                        {
                                            this.state.documents1.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.documents1.reduce((sum, current) => {
                                                    sum = sum + current.ko
                                                    return sum
                                                }, 0), true, true)

                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                }

                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    {this.state.tyButton=='izvjestaj'? 
                                    <span>
                                        {
                                            this.state.documents.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.documents.reduce((sum, current) => {
                                                    sum = sum + current.items.reduce((sum1, current1) => {
                                                        sum1 = sum1 + current1.newAmount
                                                        return sum1
                                                    }, 0)
                                                    return sum
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                    :
                                    <span>
                                    {
                                        this.state.documents1.length != 0 ?
                                            this.props.roundToTwoDecimals(this.state.documents1.reduce((sum, current) => {
                                                sum = sum + current.iznosNovi
                                                return sum
                                            }, 0), true, true)

                                            :
                                            '00,00'
                                    }
                                    &nbsp;KM
                                </span>
                                    }
                                    {/* <span>
                                        {
                                            this.state.documents.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.documents.reduce((sum, current) => {
                                                    sum = sum + current.items.reduce((sum1, current1) => {
                                                        sum1 = sum1 + current1.newAmount
                                                        return sum1
                                                    }, 0)
                                                    return sum
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        )
    }
}

export default Page(ReportKO);


{/* <div className="info-colored">
                                <div className="col-info">
                                    <p>Osnovica</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals(this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>PDV</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((0.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Za plaćanje</p>
                                    <span>
                                        {
                                            this.state.items.length != 0 ?
                                                this.props.roundToTwoDecimals((1.17 * (this.state.items.reduce((sum, current) => {
                                                    let res = sum + current.total
                                                    return res
                                                }, 0))), true, true)
                                                :
                                                '00,00'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div> */}