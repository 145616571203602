
import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import ErrorModal from '../../components/errorModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/link';
import moment from 'moment';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class FinancialAccounts extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            articleSearch: [],
            clients: [],
            businessUnits: [],
            items: [],
            financialForm: {},
            financialItems: [],
            manufacturers: [],
            financialItemType: [],
            financialAccountNumber: [],
            total: [],
            loading: false,
            dateNow: moment.unix(new Date().getTime() / 1000).format('DD.MM.YYYY'),
            totalAsking: [],
            totalOwes: [],
            prevFinancialFrom: []

        }
    }

    componentDidMount() {
        this._onLoad();
        (async () => {
            let broj = await this._apiAsync('checkNextDocumentNumber', {})
            if (broj && broj.firstFreeNumber) {
                this.remoteOnChange('documentNumber', broj.firstFreeNumber)
            }
            if (this.state.financialForm && !this.state.financialForm.accountDate) {
                // let date = moment.unix(Math.floor(new Date().getTime() / 1000)).format('DD.MM.YYYY')
                this.remoteOnChange('accountDate', this.state.dateNow)
            }
        })()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
            this._apiAsync('get-financial-accounts-items', { financialAccount: this.state.financialForm ? this.state.financialForm._id : this.state.prevFinancialFrom._id, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }), }).then((response) => {
                this.setState({ items: response.financialItems, total: response.total })
            })
        }
    }

    metodaClient = async (value) => {
        let clientList = []
        clientList = await this._apiAsync('clientsSearch', { value: value })
        this.setState({ clientsSearch: clientList.clientsSearch })
    }

    render() {
        let params = this._getParams();
        return (
            <div>
                <div className="section-title title-options">
                    <h1>Nalog</h1>
                    <div className="title-options-group">
                        <button onClick={() => this.submit()}>
                            <div className="option green">
                                <Isvg src={saveIcon} />
                            </div>
                            <span>Snimi</span>
                        </button>
                        <button onClick={() => this.clearFilters()}>
                            <div className="option red" >
                                <Isvg src={garbageOpionIcon} />
                            </div>
                            <span>Izbriši</span>
                        </button>
                        {/* <button>
                            <div className="option blue">
                                <Isvg src={printIcon} />
                            </div>
                            <span>Štampaj</span>
                        </button>
                        <button>
                            <div className="option blue">
                                <Isvg src={exportIcon} />
                            </div>
                            <span>Export</span>
                        </button> */}
                    </div>
                </div>


                <Container fluid style={{ paddingRight: 0 }}>
                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {
                            this._api('delete', { data: this.state.deleteModal._id }, () => {
                                this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                            });
                        }} >
                        Obrisati odjavu <strong>{this.state.deleteModal ? this.state.deleteModal.name : ''}</strong> ?
                    </DeleteModal>
                    <Row>
                        <Col lg="12" style={{ marginTop: 10 }}>
                            <FormBuilder
                                registerChangeValue={(remoteOnChange) => this.remoteOnChange = remoteOnChange}
                                registerGetValue={(registerGetValue) => this.registerGetValue = registerGetValue}
                                registerSubmitAction={(submit) => this.submit = submit}
                                afterSubmitFocusField="itemNumber"
                                classNameForm={"add-form-container"}
                                fieldsToTrack={['article', 'clientCode', 'documentNumber', 'accountDate', 'itemNumber']}
                                fieldTracker={async (name, value) => {
                                    if (name === 'documentNumber' && !value) {
                                        this.setState({ items: [], totalItems: 0, financialForm: null }, () => {
                                            this.setState({ financialForm: {} })
                                        })
                                    }
                                    if (name == 'documentNumber' && value) {
                                        this._apiAsync('checkDocumentNumber', { documentNumber: value }).then((res) => {
                                            if (res && res.result && res.result._id) {
                                                this.setState({ financialForm: null, prevFinancialFrom: null, totalAsking: null, totalOwes: null }, () => {
                                                    this.setState({ financialForm: res.result }, () => {
                                                        if (res.result && res.result._id) {
                                                            if (res.result && res.result.accountDate) {
                                                                this.remoteOnChange('accountDate', res.result.accountDate)
                                                            }

                                                            this._apiAsync('get-financial-accounts-items', { financialAccount: res.result._id, ...getSearchParams(this.props[0].location.search, { entries: 10, page: 0 }), }).then((res) => {
                                                                this.setState({ items: res.financialItems, total: res.total, totalAsking: res.totalAsking, totalOwes: res.totalOwes })
                                                            })

                                                            this._apiAsync('checkNextItemNumber', { financialAccount: res.result._id }).then((res) => {
                                                                if (res && res.firstFreeItemNumber) {
                                                                    this.setState({
                                                                        financialItems: res.result,
                                                                    }, () => {
                                                                        this.remoteOnChange('itemNumber', Number(res.firstFreeItemNumber))
                                                                    })
                                                                }
                                                            })
                                                        } else {
                                                            this.setState({
                                                                items: [],
                                                            }, () => {
                                                                this.remoteOnChange('itemNumber', 1)
                                                            })
                                                        }

                                                    })
                                                })

                                            } else if (this.state.prevDocumentNumber != value) {
                                                this.setState({ financialForm: null }, () => {
                                                    this.setState({ financialForm: { documentNumber: value, accountDate: this.state.dateNow } }, () => {

                                                        this.setState({ items: [], financialForm: {} }, () => {
                                                            this._apiAsync('checkNextItemNumber', { documentNumber: value }).then((res) => {
                                                                if (res && res.firstFreeItemNumber) {
                                                                    this.remoteOnChange('itemNumber', Number(res.firstFreeItemNumber))
                                                                }
                                                            })
                                                        })
                                                    })
                                                })
                                            }
                                            this.setState({ prevDocumentNumber: value, prevFinancialFrom: this.state.financialForm })
                                        })

                                    }

                                    if (name == 'itemNumber' && value) {
                                        if (value) {
                                            this.setState({
                                                financialForm: null
                                            })
                                            this._apiAsync('itemNumberItems', { itemNumber: value, financialAccount: this.state.prevFinancialFrom._id }).then((res) => {
                                                if (res && res.items && this.state.prevFinancialFrom) {
                                                    this.setState({
                                                        financialForm: { ...this.state.prevFinancialFrom, ...res.items }
                                                    })
                                                } else {
                                                    this.remoteOnChange('itemType', '')
                                                    this.remoteOnChange('documentDate', '')
                                                    this.remoteOnChange('clientCode', '')
                                                    this.remoteOnChange('clientName', '')
                                                    this.remoteOnChange('description', '')
                                                    this.remoteOnChange('accountNumberCode', '')
                                                    this.remoteOnChange('owes', '')
                                                    this.remoteOnChange('asking', '')
                                                    this.remoteOnChange('inPdv', '')
                                                    this.remoteOnChange('kifKuf', '')
                                                    this.remoteOnChange('compensation', '')
                                                    this.remoteOnChange('month', '')
                                                    this.remoteOnChange('valueDate', '')
                                                    this.remoteOnChange('billDate', '')
                                                    this.remoteOnChange('billNumber', '')

                                                }
                                            })
                                        } else {
                                            if (this.state.financialForm) {
                                                this.setState({
                                                    financialForm: { documentNumber: this.state.financialForm.documentNumber }
                                                })
                                            } else if (this.state.prevFinancialFrom) {
                                                this.setState({
                                                    financialForm: this.state.prevFinancialFrom
                                                })
                                            }
                                        }
                                    }

                                }}
                                initialValues={this.state.financialForm}
                                addButtonText={'Dodaj'.translate(this.props.lang)}
                                onSubmit={(data) => {

                                    if (data.owes  || data.asking ) {
                                        this._api('insert-new', data, () => {
                                            this.setState({ financialForm: {} }, () => {
                                                this._onLoad()
                                            })
                                        });

                                    } else {
                                        this.setState({
                                            errorModal: 'Duguje ili potražuje mora biti uneseno!'
                                        })
                                    }



                                }}

                                fields={[
                                    {
                                        type: 'block',
                                        children: [
                                            {
                                                type: 'row',
                                                className: "row-pl-fix",
                                                children: [
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 10, xs: 10 },
                                                        className: "custom-input-row financial-accounts-row",
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        integer: true,
                                                                        name: 'documentNumber',
                                                                        next: 'accountDate',
                                                                        label: 'Nalog',
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required("Broj naloga mora biti unešen!"),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [

                                                                    {
                                                                        type: 'date',
                                                                        name: 'accountDate',
                                                                        next: 'itemNumber',
                                                                        label: 'Datum naloga',
                                                                        finishField: true,
                                                                        readOnly: this.state.disableEdit,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => {
                                                                                if (!value || value == '') return "Datum naloga mora biti unesen!"
                                                                            },
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        integer: true, name: 'itemNumber',
                                                                        next: 'itemType',
                                                                        label: 'Red',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            required('Red mora biti unesen')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'selectWithSearch',
                                                                        name: 'itemType',
                                                                        next: 'documentDate',
                                                                        label: 'Vrsta knjiženja',
                                                                        asyncValidation: true,
                                                                        values: [...this.state.financialItemType.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item.code
                                                                            }
                                                                            return obj
                                                                        })],
                                                                        validate: [

                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        const financialItemType = await this._apiAsync('get-financial-item-type', { filter: value })
                                                                                        if (financialItemType && financialItemType.items) {
                                                                                            this.setState({ financialItemType: financialItemType && financialItemType.items }, () => {
                                                                                                if (this.state.financialItemType.filter(item => item.code == value).length) {
                                                                                                    this.remoteOnChange('itemTypeName', this.state.financialItemType.filter(item => item.code == value)[0].name)
                                                                                                } else {
                                                                                                    this.remoteOnChange('itemTypeName', '')
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                            (value) => { if (!value || value == '') return "Vrsta knjiženja mora biti unesen!" },
                                                                            required('Vrsta knjiženja mora biti unešen!'),
                                                                        ]


                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [

                                                                    {
                                                                        type: 'date',
                                                                        name: 'documentDate',
                                                                        next: 'clientCode',
                                                                        label: 'Datum dokumenta',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => { if (!value || value == '') return "Datum od mora biti unesen!" },
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 4, xs: 4 },
                                                                className: 'input-group-custom input-group-custom-1',
                                                                children: [
                                                                    {
                                                                        type: 'client',
                                                                        name: 'clientCode',
                                                                        next: 'description',
                                                                        label: 'Komitent',
                                                                        metoda: (value) => { this.metodaClient(value) },
                                                                        dataModal: this.state.clientsSearch,
                                                                        title: 'Pregled komitenata',

                                                                        finishField: true,

                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            // (value) => { if (!value || value == '') return "Šifra komitenta mora biti unesen!" },
                                                                            required('Šifra komitenta mora biti unesen!'),
                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        if (this.state.clientsSearch && this.state.clientsSearch.length && this.state.clientsSearch.filter(el => String(el.code) == String(value)).length) {
                                                                                            this.remoteOnChange('clientName', this.state.clientsSearch.filter(el => String(el.code) == String(value))[0].name)
                                                                                            return undefined;
                                                                                        } else {
                                                                                            return 'Unesena šifra komitenta nije ispravna!';
                                                                                        }
                                                                                    } else {
                                                                                        return undefined;
                                                                                    }


                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },
                                                                        ]
                                                                    },

                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'clientName',
                                                                        label: 'Naziv komitenta',
                                                                        readOnly: true,
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [


                                                                        ]
                                                                    },
                                                                ]
                                                            },


                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 12, xs: 12 },
                                                        className: "custom-input-row financial-accounts-row",
                                                        children: [
                                                            {
                                                                type: 'col',
                                                                width: { lg: 5, sm: 4, xs: 4 },
                                                                children: [
                                                                    {

                                                                        type: 'text',
                                                                        name: 'description',
                                                                        next: 'accountNumberCode',
                                                                        label: 'Opis',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [

                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 3, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'selectWithSearch',
                                                                        name: 'accountNumberCode',
                                                                        next: 'owes',
                                                                        label: 'Konto',
                                                                        asyncValidation: true,
                                                                        values: [...this.state.financialAccountNumber.map(item => {
                                                                            let obj = {
                                                                                name: item.name,
                                                                                value: item.code
                                                                            }
                                                                            return obj
                                                                        })],
                                                                        validate: [

                                                                            async (value) => {
                                                                                try {
                                                                                    if (value) {
                                                                                        const financialAccountNumber = await this._apiAsync('get-financial-accounts-numbers', { filter: value })
                                                                                        if (financialAccountNumber && financialAccountNumber.items) {
                                                                                            this.setState({ financialAccountNumber: financialAccountNumber && financialAccountNumber.items }, () => {
                                                                                                if (this.state.financialAccountNumber.filter(item => item.code == value).length) {
                                                                                                    this.remoteOnChange('accountNumberCodeName', this.state.financialAccountNumber.filter(item => item.code == value)[0].name)
                                                                                                } else {
                                                                                                    this.remoteOnChange('accountNumberCodeName', '')
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                } catch (e) {
                                                                                    return undefined;
                                                                                }
                                                                            },

                                                                            required('Konto mora biti unešen!'),
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'decimal',
                                                                        name: 'owes',
                                                                        next: 'asking',
                                                                        label: 'Duguje',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            number('Mora biti broj!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'decimal',
                                                                        name: 'asking',
                                                                        next: 'kifKuf',
                                                                        label: 'Potražuje',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            number('Mora biti broj!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: { lg: 12, sm: 10, xs: 10 },
                                                        className: "custom-input-row categories-manufacturers-row",
                                                        children: [
                                                            // {
                                                            //     type: 'col',
                                                            //     width: { lg: 1, sm: 4, xs: 4 },
                                                            //     children: [
                                                            //         {
                                                            //             type: 'select',
                                                            //             arrow: true,
                                                            //             name: 'inPdv',
                                                            //             next: 'kifKuf',
                                                            //             label: 'Ulazni pdv',
                                                            //             values: [{ value: '', name: '' }, { value: true, name: 'Da' }, { value: false, name: 'Ne' }],
                                                            //             validate: []
                                                            //         }
                                                            //     ]
                                                            // },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'kifKuf',
                                                                        next: 'compensation',
                                                                        label: 'UF/IF',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        arrow: true,
                                                                        values: [{ value: '', name: '' }, { value: 'uf', name: 'UF' }, { value: 'if', name: 'IF' }],
                                                                        validate: [

                                                                        ]
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 1, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'select',
                                                                        name: 'compensation',
                                                                        next: 'valueDate',
                                                                        label: 'Kompezacija',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        arrow: true,
                                                                        values: [{ value: '', name: '' }, { value: true, name: 'Da' }, { value: false, name: 'Ne' }],
                                                                        validate: [

                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            // {
                                                            //     type: 'col',
                                                            //     width: { lg: 1, sm: 4, xs: 4 },
                                                            //     children: [
                                                            //         {
                                                            //             type: 'select',
                                                            //             name: 'month',
                                                            //             next: 'valueDate',
                                                            //             label: 'Mjesec',
                                                            //             asyncValidation: true,
                                                            //             // values: [{ name: 'Select month', value: -1 }, ...this.state.month.map(item => {
                                                            //             //     let obj = {
                                                            //             //         name: item.name,
                                                            //             //         value: item._id
                                                            //             //     }
                                                            //             //     return obj;
                                                            //             // })],
                                                            //             validate: [
                                                            //                 (value) => { if (!value || value == '') return "Mjesec mora biti unesen!" },
                                                            //                 required()
                                                            //             ]
                                                            //         },
                                                            //     ]
                                                            // },
                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'valueDate',
                                                                        next: 'billDate',
                                                                        label: 'Valuta',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => { if (!value || value == '') return "Valuta mora biti unesen!" },
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 2, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'date',
                                                                        name: 'billDate',
                                                                        next: 'billNumber',
                                                                        label: 'Datum računa',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => { if (!value || value == '') return "Datum računa mora biti unesen!" },
                                                                            validateDate('Datum nije validan')
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                            {
                                                                type: 'col',
                                                                width: { lg: 5, sm: 4, xs: 4 },
                                                                children: [
                                                                    {
                                                                        type: 'text',
                                                                        name: 'billNumber',
                                                                        next: 'submitForm',
                                                                        label: 'Račun',
                                                                        finishField: true,
                                                                        asyncValidation: true,
                                                                        validate: [
                                                                            (value) => { if (!value || value == '') return "Račun mora biti unesen!" },
                                                                            required('Račun mora biti unesen!')
                                                                        ]
                                                                    },
                                                                ]
                                                            },

                                                        ]
                                                    },

                                                ]
                                            }
                                        ]
                                    },
                                ]
                                }
                            ></FormBuilder>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12" style={{ padding: 0 }}>
                            <div style={{ marginTop: 10 }}>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'itemNumber', label: 'RED'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'itemType', label: 'VRSTA KNJIŽENJA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'documentDate', label: 'DATUM DOKUMENTA'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'clientCode', label: 'KOMITENT'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'description', label: 'OPIS'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'accountNumberCode', label: 'KONTO'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'kifKuf', label: 'UF/IF'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'billNumber', label: 'RAČUN'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true },
                                        { type: 'text', name: 'owes', label: 'DUGUJE'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true, numberWithCommas: true },
                                        { type: 'text', name: 'asking', label: 'POTRAŽUJE'.translate(this.props.lang), width: 'unset', multilang: false, allowSort: true, numberWithCommas: true },
                                    ]}
                                    items={this.state.items.map((item, idx) => {

                                        return {
                                            ...item,
                                            clientCode: item.clientLinks ? item.clientCode : '',
                                        }
                                    })}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>
                        </Col>
                    </Row>

                    <div className="footer">
                        <div className="footer-left">

                        </div>
                        <div className="footer-right">
                            <div className="info-colored">
                                <div className="col-info">
                                    <p>Duguje</p>
                                    <span>
                                        {
                                            this.state.totalOwes && this.state.totalOwes != 0 ?

                                                numberWithCommas(parseFloat(this.state.totalOwes).toFixed(2))

                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Potrazuje</p>
                                    <span>
                                        {
                                            this.state.totalAsking && this.state.totalAsking != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalAsking).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                                <div className="col-info">
                                    <p>Ukupno</p>
                                    <span>
                                        {
                                            this.state.totalOwes && this.state.totalAsking != 0 ?
                                                numberWithCommas(parseFloat(this.state.totalOwes - this.state.totalAsking).toFixed(2))
                                                :
                                                '0'
                                        }
                                        &nbsp;KM
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>


                <ErrorModal
                    isOpen={this.state.errorModal}
                    toggle={() => this.setState({ errorModal: null })}
                    error={this.state.errorModal}
                />
            </div >
        )
    }
}

export default Page(FinancialAccounts);
